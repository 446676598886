import React, { useMemo } from 'react';
import { OBJECT_OF_ANY_TYPE, REQUEST_FILTER_GROUP_TYPE } from '../../../constants/propTypes';
import { getUserFullName } from '@bfg-frontend/utils/lib/stringBuilders/entity';
import { NoDataLabelTrans, NotSelectedLabelTrans } from '../../../utils/commonTransComponents';
import PropTypes from 'prop-types';
import { AutocompleteContainer } from '../../common/Autocomplete/AutocompleteContainer';
import {
  createCaUsersAutocompleteLoadOptionsActionCreator,
  USERS_AUTOCOMPLETE_FIELDS,
  USERS_AUTOCOMPLETE_STATUSES,
} from './constants';


export const DEFAULT_REACT_SELECT_PROPS = {
  /**
   * Отключает проверку соответствия того что ввел пользователь в поле ввода
   * с тем, какие варианты есть в селекторе. Необходимо, т.к. то что ввел юзер
   * в поле не совпадает посимвольно с теми вариантами, которые отдал сервер.
   */
  filterOption: () => true,
};

export const UsersAutocomplete = props => {

  const {
    shouldLoadOptions,
    prepareOptions,
    fields,
    getOptionLabel,
    placeholder,
    noOptionsMessage,
    reactSelectProps,
    baseAutocompleteContainer: BaseAutocompleteContainer,
    usersStatus,
  } = props;

  const loadOptionsActionCreator = useMemo(
    () => {
      if (!shouldLoadOptions) {
        return undefined;
      }

      return createCaUsersAutocompleteLoadOptionsActionCreator({
        prepareOptions,
        fields,
        usersStatus,
      });
    },
    [shouldLoadOptions, prepareOptions, fields, usersStatus],
  );

  return (
    <BaseAutocompleteContainer
        {...props}
        getOptionLabel={getOptionLabel}
        loadOptionsActionCreator={loadOptionsActionCreator}
        placeholder={placeholder}
        noOptionsMessage={noOptionsMessage}
        reactSelectProps={!!reactSelectProps ? { ...DEFAULT_REACT_SELECT_PROPS, ...reactSelectProps  } : DEFAULT_REACT_SELECT_PROPS}
    />
  );
};

UsersAutocomplete.propTypes = {
  shouldLoadOptions: PropTypes.bool,
  prepareOptions: PropTypes.func,
  withDisabled: PropTypes.bool,
  fields: PropTypes.arrayOf(PropTypes.oneOf(Object.values(USERS_AUTOCOMPLETE_FIELDS))),
  getOptionLabel: PropTypes.func,
  placeholder: PropTypes.element,
  noOptionsMessage: PropTypes.element,
  predefinedFilter: REQUEST_FILTER_GROUP_TYPE,
  reactSelectProps: OBJECT_OF_ANY_TYPE,
  baseAutocompleteContainer: PropTypes.elementType,
  usersStatus: PropTypes.oneOf(Object.values(USERS_AUTOCOMPLETE_STATUSES)),
};

UsersAutocomplete.defaultProps = {
  placeholder: NotSelectedLabelTrans,
  getOptionLabel: getUserFullName,
  noOptionsMessage: NoDataLabelTrans,
  fields: [
    USERS_AUTOCOMPLETE_FIELDS.LAST_NAME,
    USERS_AUTOCOMPLETE_FIELDS.NAME,
    USERS_AUTOCOMPLETE_FIELDS.PATRONYMIC_NAME,
  ],
  baseAutocompleteContainer: AutocompleteContainer,
  usersStatus: USERS_AUTOCOMPLETE_STATUSES.ACTIVE,
};
