import _isNil from 'lodash/isNil';
import React from 'react';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';

import cn from 'classnames';
import './style.css';
import { MATERIAL_UI_ORIENTATION, MATERIAL_UI_STYLE_COLOR, MATERIAL_UI_VARIANT } from '../../../constants/materialUI';
import _get from 'lodash/get';


export const SimpleTabs = props => {
  const {
    tabs,
    className,
    orientation,
    elevation,
    variant,
    defaultSelectedTabId,
  } = props;

  const [selectedTabId, setSelectedTabId] = React.useState(
    () =>
      _isNil(defaultSelectedTabId) ?
        _get(tabs, [0, 'tabId'], null) :
        defaultSelectedTabId,
  );

  const selectedTab = tabs.find(({ tabId }) => tabId === selectedTabId);

  const selectedTabContent = _get(selectedTab, 'tabContent', null);

  const orientationClassName = orientation === MATERIAL_UI_ORIENTATION.VERTICAL ?
    'simple-tabs--vertical' :
    'simple-tabs--horizontal';

  return (
    <div className={cn('simple-tabs', className, orientationClassName)}>
      <AppBar
          className="simple-tabs__tabs"
          position="static"
          color={MATERIAL_UI_STYLE_COLOR.INHERIT}
          elevation={elevation}
      >
        <Tabs
            classes={{
              indicator: 'simple-tabs__tabs-indicator',
            }}
            indicatorColor={MATERIAL_UI_STYLE_COLOR.PRIMARY}
            textColor={MATERIAL_UI_STYLE_COLOR.INHERIT}
            value={selectedTabId}
            onChange={(_, tabId) => setSelectedTabId(tabId)}
            orientation={orientation}
            variant={variant}
        >
          {
            tabs
              .map(({ tabTitle, tabId, tabIcon, tabIconPosition }) => (
                <Tab
                    classes={{
                      root: 'simple-tabs__tab-label',
                      selected: 'simple-tabs__tab-selected',
                    }}
                    label={tabTitle}
                    value={tabId}
                    key={tabId}
                    icon={tabIcon}
                    iconPosition={tabIconPosition}
                />
              ))
          }
        </Tabs>
      </AppBar>
      <div className="simple-tabs__tab-content">
        {selectedTabContent}
      </div>
    </div>
  );
};

export const SIMPLE_TABS_TYPE = PropTypes.arrayOf(PropTypes.shape({
  tabId: PropTypes.string.isRequired,
  tabTitle: PropTypes.node.isRequired,
  tabContent: PropTypes.node.isRequired,
})).isRequired;

SimpleTabs.propTypes = {
  className: PropTypes.string,
  tabs: SIMPLE_TABS_TYPE,
  orientation: PropTypes.oneOf(Object.values(MATERIAL_UI_ORIENTATION)),
  elevation: PropTypes.number,
  variant: PropTypes.oneOf(Object.values(MATERIAL_UI_VARIANT)),
  defaultSelectedTabId: PropTypes.string,
};

SimpleTabs.defaultProps = {
  tabs: [],
  orientation: MATERIAL_UI_ORIENTATION.HORIZONTAL,
  variant: MATERIAL_UI_VARIANT.STANDARD,
};
