import React from 'react';

import { Trans } from '@lingui/macro';

export const IdentityShortLabelTrans = (
  <Trans id="label@identity_short">
    ИД
  </Trans>
);

export const IdentityLabelTrans = (
  <Trans id="label@identity">
    Идентификатор
  </Trans>
);

export const SheetIdentityLabelTrans = (
  <Trans id="label@sheet_identity">
    ИД маршрутного листа партии
  </Trans>
);

export const EntityBatchIdentityLabelTrans = (
  <Trans id="label@entity_batch_identity">
    ИД Партии
  </Trans>
);

export const DepartmentIdentityLabelTrans = (
  <Trans id="label@department_identity">
    ИД Подразделения
  </Trans>
);

export const EquipmentClassIdentityLabelTrans = (
  <Trans id="label@equipment_class_identity">
    ИД Класса РЦ
  </Trans>
);

export const OperationLabelTrans = (
  <Trans id="label@operation">
    Операция
  </Trans>
);

export const OperationNumberLabelTrans = (
  <Trans id="label@operation_number">
    № Операции
  </Trans>
);

export const DefectStateDateLabelTrans = (
  <Trans id="label@defect_state_date">
    Дата отбраковки
  </Trans>
);

export const UserNotedDefectLabelTrans = (
  <Trans id="label@user_noted_defect">
    Сотрудник, отметивший брак
  </Trans>
);

export const UserConfirmedDefectLabelTrans = (
  <Trans id="label@user_confirmed_defect">
    Сотрудник, подтвердивший брак
  </Trans>
);

export const DefectReasonLabelTrans = (
  <Trans id="label@defect_reason">
    Причина брака
  </Trans>
);

export const DefectConfirmationDateLabelTrans = (
  <Trans id="label@defect_confirmation_date">
    Дата подтверждения брака
  </Trans>
);

export const OperationIdentityLabelTrans = (
  <Trans id="label@operation_identity">
    ИД Операции
  </Trans>
);


export const ChooseDepartmentLabelTrans = (
  <Trans id="label@choose_department">
    Выберите подразделение
  </Trans>
);

export const ChooseEquipmentClassLabelTrans = (
  <Trans id="label@choose_equipment_class">
    Выберите класс РЦ
  </Trans>
);

export const TasksLabelTrans = (
  <Trans id="label@tasks">
    Задания
  </Trans>
);

export const NoDataLabelTrans = (
  <Trans id="label@no_data">
    Нет данных
  </Trans>
);

export const WithoutOrderLabelTrans = (
  <Trans id="label@without_order">
    Без заказа
  </Trans>
);

export const AmountLabelTrans = (
  <Trans id="label@amount">
    Количество
  </Trans>
);

export const EntityLabelTrans = (
  <Trans id="label@entity">
    ДСЕ
  </Trans>
);

export const EntityBatchLabelTrans = (
  <Trans id="label@entity_batch">
    Партия
  </Trans>
);

export const DepartmentLabelTrans = (
  <Trans id="label@department">
    Подразделение
  </Trans>
);

export const EquipmentClassLabelTrans = (
  <Trans id="label@equipment_class">
    Класс РЦ
  </Trans>
);

export const CreateLabelTrans = (
  <Trans id="label@create">
    Создать
  </Trans>
);

export const EditLabelTrans = (
  <Trans id="label@edit">
    Редактировать
  </Trans>
);

export const DeleteLabelTrans = (
  <Trans id="label@delete">
    Удалить
  </Trans>
);

export const SaveLabelTrans = (
  <Trans id="label@save">
    Сохранить
  </Trans>
);

export const ResetLabelTrans = (
  <Trans id="label@reset">
    Сбросить
  </Trans>
);

export const AcceptLabelTrans = (
  <Trans id="label@accept">
    Применить
  </Trans>
);

export const CancelLabelTrans = (
  <Trans id="label@cancel">
    Отменить
  </Trans>
);

export const SettingsLabelTrans = (
  <Trans id="label@settings">
    Настройки
  </Trans>
);

export const NotSelectedLabelTrans = (
  <Trans id="label@not_selected">
    Не выбрано
  </Trans>
);

export const PlannerLabelTrans = (
  <Trans id="label@planner">
    Плановик
  </Trans>
);

export const QualityControlLabelTrans = (
  <Trans id="label@quality_control">
    Контроль
  </Trans>
);

export const InappropriateSheetsLabelTrans = (
  <Trans id="label@inappropriate_sheets">
    Несоответствующая продукция
  </Trans>
);

export const MasterLabelTrans = (
  <Trans id="label@master">
    Мастер
  </Trans>
);

export const WorkerLabelTrans = (
  <Trans id="label@worker">
    Рабочий
  </Trans>
);

export const AdministratorLabelTrans = (
  <Trans id="label@administrator">
    Администратор
  </Trans>
);

export const StorageManagerLabelTrans = (
  <Trans id="label@storage_manager">
    Комплектовщик
  </Trans>
);

export const AdministrationLabelTans = (
  <Trans id="label@administration">
    Администрирование
  </Trans>
);

export const PartsAndMaterialsConsumingLabelTrans = (
  <Trans id="label@parts_and_materials_consuming">
    Комплектование
  </Trans>
);

export const InProductionLabelTrans = (
  <Trans id="label@in_production">
    В производстве
  </Trans>
);

export const ToStartLabelTrans = (
  <Trans id="label@to_start">
    На запуск
  </Trans>
);

export const PausedLabelTrans = (
  <Trans id="label@paused">
    Остановленные
  </Trans>
);

export const SinglePausedLabelTrans = (
  <Trans id="label@single_paused">
    Остановлен
  </Trans>
);

export const CompletedLabelTrans = (
  <Trans id="label@completed">
    Завершенные
  </Trans>
);

export const AssemblySheetsLabelTrans = (
  <Trans id="label@assembly_sheets">
    Сборочные
  </Trans>
);

export const DefaultSheetsLabelTrans = (
  <Trans id="label@default_sheets">
    Стандартные
  </Trans>
);

export const ToDoLabelTrans = (
  <Trans id="label@to_do">
    Требуется выполнить
  </Trans>
);

export const OrderLabelTrans = (
  <Trans id="label@order">
    Заказ
  </Trans>
);

export const EntitiesInBatchAmountLabelTrans = (
  <Trans id="label@entities_in_batch_amount">
    Размер партии
  </Trans>
);

export const SheetLabelTrans = (
  <Trans id="label@sheet">
    Маршрутный лист
  </Trans>
);

export const SheetsLabelTrans = (
  <Trans id="label@sheets">
    Маршрутные листы
  </Trans>
);

export const SheetOperationProgressLabelTrans = (
  <Trans id="label@sheet_operation_progress">
    Прогресс выполнения
  </Trans>
);

export const SheetOperationStatusLabelTrans = (
  <Trans id="label@sheet_operation_status">
    Статус операции
  </Trans>
);

export const SheetPlannedStartDateLabelTrans = (
  <Trans id="label@sheet_planned_start_date">
    Плановая дата начала работ
  </Trans>
);

export const SheetPlannedStopDateLabelTrans = (
  <Trans id="label@sheet_planned_stop_date">
    Плановая дата окончания работ
  </Trans>
);

export const SheetOperationPlannedStartDateLabelTrans = (
  <Trans id="label@sheet_operation_planned_start_date">
    Плановая дата начала
  </Trans>
);

export const SheetOperationPlannedStopDateLabelTrans = (
  <Trans id="label@sheet_operation_planned_stop_date">
    Плановая дата окончания
  </Trans>
);

export const GoBackToListLabelTrans = (
  <Trans id="label@go_back_to_list">
    Вернуться к списку
  </Trans>
);

export const StartSheetInProductionLabelTrans = (
  <Trans id="label@start_sheet_in_production">
    Запуск маршрутного листа в производство
  </Trans>
);

export const StartInProductionLabelTrans = (
  <Trans id="label@start_in_production">
    Запустить в производство
  </Trans>
);

export const StartAllSelectedSheetsLabelTrans = (
  <Trans id="label@start_all_selected_sheets">
    Запустить выбранные маршрутные листы в производство
  </Trans>
);

export const GroupSheetsStartInProgressTooltipLabelTrans = (
  <Trans id="all_sheets_to_start@group_sheets_start_in_progress_tooltip">
    В текущий момент выполняется массовый запуск маршрутных листов, пожалуйста дождитесь окончания процесса
  </Trans>
);

export const ResumeToProductionLabelTrans = (
  <Trans id="label@resume_to_production">
    Вернуть в производство
  </Trans>
);

export const FinishSheetLabelTrans = (
  <Trans id="label@finish_sheet">
    Завершить маршрутный лист
  </Trans>
);

export const markSheetInappropriateStateUnconfirmedLabelTrans = (
  <Trans id="label@mark_sheet_inappropriate_state_unconfirmed">
    Вернуть в работу
  </Trans>
);

export const markSheetFinalDefectiveStateConfirmedLabelTrans = (
  <Trans id="label@mark_sheet_final_defective_state_confirmed">
    Списать
  </Trans>
);

export const ChooseFiltersLabelTrans = (
  <Trans id="label@choose_filters">
    Выберите фильтры
  </Trans>
);

export const ChooseTableColumnToFilterLabelTrans = (
  <Trans id="label@choose_table_column_to_filter">
    Выберите колонку таблицы для фильтрации
  </Trans>
);

export const AddFilterLabelTrans = (
  <Trans id="label@add_filter">
    Добавить фильтр
  </Trans>
);

export const AddLabelTrans = (
  <Trans id="label@add">
    Добавить
  </Trans>
);

export const FilterNotSelectedLabelTrans = (
  <Trans id="label@filter_not_selected">
    Фильтр не выбран
  </Trans>
);

export const ApplyFilterLabelTrans = (
  <Trans id="label@apply_filter">
    Применить
  </Trans>
);

export const RemoveAllFiltersLabelTrans = (
  <Trans id="label@remove_all_filters">
    Удалить все фильтры
  </Trans>
);

export const NoMatchesFoundLabelTrans = (
  <Trans id="label@no_matches_found">
    Совпадений не найдено
  </Trans>
);

export const AllAvailableFiltersSetLabelTrans = (
  <Trans id="label@all_available_filters_set">
    Все доступные фильтры установлены
  </Trans>
);

export const RowsPerPageLabelTrans = (
  <Trans id="label@rows_per_page">
    Количество строк на странице
  </Trans>
);

export const OutOfLabelTrans = (
  <Trans id="label@out_of">
    из
  </Trans>
);

export const SummaryInfoLabelTrans = (
  <Trans id="label@summary_info">
    Сводная информация
  </Trans>
);

export const AdditionalLabelTrans = (
  <Trans id="label@additional">
    Дополнительно
  </Trans>
);

export const CreateNewUserLabelTrans = (
  <Trans id="label@create_new_user">
    Создать нового пользователя
  </Trans>
);

export const DeactivateLabelTrans = (
  <Trans id="label@deactivate">
    Деактивировать
  </Trans>
);

export const ActivateLabelTrans = (
  <Trans id="label@activate">
    Активировать
  </Trans>
);

export const ActiveLabelTrans = (
  <Trans id="label@active">
    Активные
  </Trans>
);

export const AllAvailableUserRolesSelectedLabelTrans = (
  <Trans id="label@all_available_user_roles_selected">
    Выбраны все доступные роли пользователя
  </Trans>
);

export const UsersListLabelTrans = (
  <Trans id="label@users_list">
    Список пользователей
  </Trans>
);

export const LogoutLabelTrans = (
  <Trans id="label@logout">
    Выйти из системы
  </Trans>
);

export const ActionNeedsConfirmationLabelTrans = (
  <Trans id="label@action_needs_confirmation">
    Действие требует подтверждения
  </Trans>
);

export const AreYouSureLabelTans = (
  <Trans id="label@are_you_sure">
    Вы уверены?
  </Trans>
);

export const YesLabelTrans = (
  <Trans id="label@yes">
    Дa
  </Trans>
);

export const NoLabelTrans = (
  <Trans id="label@no">
    Нет
  </Trans>
);

export const RoleLabelTrans = (
  <Trans id="label@role">
    Роль
  </Trans>
);

export const DetailsLabelTrans = (
  <Trans id="label@details">
    Детали
  </Trans>
);

export const CommentLabelTrans = (
  <Trans id="label@comment">
    Комментарий
  </Trans>
);

export const SheetOperationFeaturesLabelTrans = (
  <Trans id="label@sheet_operation_features">
    Дополнительные характеристики
  </Trans>
);

export const HistoryLabelTrans = (
  <Trans id="label@history">
    История
  </Trans>
);

export const AssigneeLabelTrans = (
  <Trans id="label@assignee">
    Исполнитель
  </Trans>
);

export const AssigneesLabelTrans = (
  <Trans id="label@assignees">
    Исполнители
  </Trans>
);

export const EquipmentLabelTrans = (
  <Trans id="label@equipment">
    Оборудование
  </Trans>
);

export const NameLabelTrans = (
  <Trans id="label@name">
    Наименование
  </Trans>
);

export const CurrentDateLabelTrans = (
  <Trans id="label@current_date">
    Текущая дата
  </Trans>
);

export const ReadyToCompleteLabelTrans = (
  <Trans id="label@ready_to_complete">
    Готовые к завершению
  </Trans>
);

export const ArchivedLabelTrans = (
  <Trans id="label@archived">
    Архивный
  </Trans>
);

export const CompleteOrderLabelTrans = (
  <Trans id="label@complete_order">
    Завершить заказ
  </Trans>
);

export const OrderNameLabelTrans = (
  <Trans id="label@order_name">
    Заказ
  </Trans>
);

export const OrdersLabelTrans = (
  <Trans id="label@orders">
    Заказы
  </Trans>
);

export const AllLabelTrans = (
  <Trans id="label@all">
    Все
  </Trans>
);

export const AssemblyLabelTrans = (
  <Trans id="label@assembly">
    Сборка
  </Trans>
);

export const BeforeEditingLabelTrans = (
  <Trans id="label@before_editing">
    До изменения
  </Trans>
);

export const ApplyChangesLabelTrans = (
  <Trans id="label@apply_changes">
    Применить изменения
  </Trans>
);

export const CancelChangesLabelTrans = (
  <Trans id="label@cancel_change">
    Отменить изменения
  </Trans>
);

export const AbortLaunchLabelTrans = (
  <Trans id="label@abort_launch">
    Прервать запуск
  </Trans>
);

export const FillAllValuesLabelTrans = (
  <Trans id="label@fill_all_values">
    Выбрать всё доступное
  </Trans>
);

export const SelectAllValuesLabelTrans = (
  <Trans id="label@select_all">
    Выбрать все
  </Trans>
);

export const UnselectAllValuesLabelTrans = (
  <Trans id="label@unselect_all">
    Снять все
  </Trans>
);


export const NotAssignedLabelTrans = (
  <Trans id="label@not_assigned">
    Не назначено
  </Trans>
);

export const NotAssignedPluralLabelTrans = (
  <Trans id="label@not_assigned_plural">
    Не назначены
  </Trans>
);

export const SheetDoesNotHavePartsAndMaterialsLabelTrans = (
  <Trans id="label@sheet_does_not_have_parts_and_materials">
    Не укомплектован
  </Trans>
);

export const SheetHasPartiallyPartsAndMaterialsLabelTrans = (
  <Trans id="label@has_partially_parts_and_materials">
    Частично укомплектован
  </Trans>
);

export const HasPartsAndMaterialsLabelTrans = (
  <Trans id="label@has_parts_and_materials">
    Укомплектован
  </Trans>
);

export const PartsAndMaterialsAreAvailableForSheetLabelTrans = (
  <Trans id="label@parts_and_materials_are_available">
    ДСЕ доступны
  </Trans>
);

export const PartsPartsAndMaterialsAreNotAvailableForSheetLabelTrans = (
  <Trans id="label@parts_and_materials_are_not_available">
    ДСЕ недоступны
  </Trans>
);

export const SheetsWaitingPartsAndMaterialsLabelTrans = (
  <Trans id="label@sheets_waiting_parts_and_materials">
    Неукомплектованные маршрутные листы
  </Trans>
);

export const CommonServerErrorLabelTrans = (
  <Trans id="label@common_server_error">
    Ошибка сервера. Обратитесь к системному администратору
  </Trans>
);

export const AllEntitiesNotSavedServerErrorLabelTrans = (
  <Trans id="label@all_entities_not_saved">
    Не удалось сохранить данные, пожалуйста повторите попытку
  </Trans>
);

export const NoPermissionForRequestLabelTrans = (
  <Trans id="label@no_permission_for_request_error">
    Недостаточно прав для выполнения этого запроса
  </Trans>
);

export const LogoutCommonErrorLabelTrans = (
  <Trans id="label@logout_common_error">
    Не удалось выйти из системы. Сервер ответил с ошибкой
  </Trans>
);

export const RequestBodyFieldUnknownErrorLabelTrans = (
  <Trans id="label@request_body_field_unknown_error">
    Неизвестная ошибка
  </Trans>
);

export const FirstNameLabelTrans = (
  <Trans id="label@first_name">
    Имя
  </Trans>
);

export const LastNameLabelTrans = (
  <Trans id="label@last_name">
    Фамилия
  </Trans>
);

export const PatronymicNameLabelTrans = (
  <Trans id="label@patronymic_name">
    Отчество
  </Trans>
);

export const PasswordLabelTrans = (
  <Trans id="label@password">
    Пароль
  </Trans>
);

export const UserFullNameLabelTrans = (
  <Trans id="label@user_full_name">
    ФИО пользователя
  </Trans>
);

export const UserIdentityLabelTrans = (
  <Trans id="label@user_identity">
    Табельный №
  </Trans>
);

export const UserRolesListLabelTrans = (
  <Trans id="label@user_roles_list">
    Список ролей
  </Trans>
);

export const TableColumnsVisibilityLabelTrans = (
  <Trans id="label@column_visibility">
    Видимость колонок
  </Trans>
);

export const OrderPlannedStartDateLabelTrans = (
  <Trans id="label@order_planned_start_date">
    Плановая дата запуска заказа
  </Trans>
);

export const OrderPlannedStopDateLabelTrans = (
  <Trans id="label@order_planned_stop_date">
    Плановая дата выпуска заказа
  </Trans>
);

/*
* Константа сразу проработана с предположением, что экспортироваться будет когда-то "состояние производства",
* т.е. НЗП, состояние наладок, а, также, возможно, состояние складов и т.п., но на текущий момент это только
* экспорт НЗП, ПОЭТОМУ В ПЕРЕВОДАХ ДЛЯ ЭТОГО КЛЮЧА УКАЗАНО В ДАННЫЙ МОМЕНТ ПРОСТО "ЭКСПОРТ НЗП"
* */
export const ProductionStateExportLabelTrans = (
  <Trans id="label@production_state_export">
    Экспорт НЗП
  </Trans>
);

export const OperationLaborLabelTrans = (
  <Trans id="label@operation_labor">
    Трудоемкость, ч.
  </Trans>
);

export const CurrentOperationLaborLabelTrans = (
  <Trans id="label@operation_labor_with_progress">
    Остаточная трудоемкость, ч.
  </Trans>
);

export const OperationProdTimeLabelTrans = (
  <Trans id="label@operation_prod_time">
    Тшт., ч.
  </Trans>
);

export const CloseLabelTrans = (
  <Trans id="label@close">
    Закрыть
  </Trans>
);

export const HoursLabelTrans = (
  <Trans id="label@short_hours">
    ч.
  </Trans>
);

export const MinutesLabelTrans = (
  <Trans id="label@short_minutes">
    мин.
  </Trans>
);

export const SecondsLabelTrans = (
  <Trans id="label@short_seconds">
    с.
  </Trans>
);

export const DefectLabelTrans = (
  <Trans id="label@defect">
    Окончательный брак
  </Trans>
);

export const ChangesSavedSuccessfullyLabelTrans = (
  <Trans id="label@chaneges_saved_successfully">
    Изменения успешно сохранены
  </Trans>
);

export const NoChangesOnFormLabelTrans = (
  <Trans id="label@no_changes_on_form">
    На форме нет изменений
  </Trans>
);
export const SettingsSavedLabelTrans = (
  <Trans id="label@settings_saved">
    Настройки успешно сохранены
  </Trans>
);

export const ShortAmountLabelTrans = (
  <Trans id="label@short_amount">
    шт.
  </Trans>
);

export const ShortPercentLabelTrans = (
  <Trans id="label@shoert_percent">
    %
  </Trans>
);

export const ReadyToStartOnlyLabel = (
  <Trans id="label@ready_to_start_only">
    Только доступные для выполнения
  </Trans>
);


export const WorkerTasksSettingsColumnsOrderTableTitleTrans = (
  <Trans id="worker_tasks_table_settings@columns_order_title">
    Порядок и видимость колонок отображаемой таблицы заданий
  </Trans>
);

export const DeleteSettingsConfirmLabelTrans = (
  <Trans id="worker_tasks_table_settings@delete_settings_confirm">
    Выбранные настройки будут удалены, вы уверены?
  </Trans>
);



export const SettingsDeletingLabelTrans = (
  <Trans id="worker_tasks_table_settings@settingsDeleting">
    Удаление настроек
  </Trans>
);
export const SearchLabel = (
  <Trans id="label@seatch_label">
    Поиск
  </Trans>
);

export const ShowMoreLabelTrans = (
  <Trans id="label@show_more">
    Показать больше
  </Trans>
);

export const PleaseDontCloseWindowLabelTrans = (
  <Trans id="label@please_dont_close_window">
    Пожалуйста, не закрывайте окно до окончания процесса
  </Trans>
);

export const RemainderLabelTrans = (
  <Trans id="label@remainder">
    остаток
  </Trans>
);