import { AutocompleteContainer } from '../../common/Autocomplete/AutocompleteContainer';
import { getResourceCombinedName } from '@bfg-frontend/utils/lib/stringBuilders/entity';
import { NoDataLabelTrans, NotSelectedLabelTrans } from '../../../utils/commonTransComponents';
import { createCaEntitiesAutocompleteLoadOptionsActionCreator } from '../../../reducers/autocomplete/actions';
import { EQUIPMENT_MODEL } from '../../../constants/models';
import { FILTER_GROUP_TYPES, FILTER_TYPES } from '../../../api/restCollectionApi';
import React, { useMemo } from 'react';
import { NUMBER_OR_STRING_TYPE } from '../../../constants/propTypes';
import PropTypes from 'prop-types';
import _isNil from 'lodash/isNil';
import { NAME_FIELD_DEFAULT_SORT_BY } from '../../../constants/entities';
import _size from 'lodash/size';


const _getEquipmentsAutocompleteLoadOptionsActionCreator = (departmentId, equipmentClassId, prepareOptions) =>
  createCaEntitiesAutocompleteLoadOptionsActionCreator({
    requestModel: EQUIPMENT_MODEL,
    prepareOptions,
    getRequestQuery: inputValue => {

      let filters = [];

      if(!_isNil(departmentId)) {
        filters.push({
          column: 'departmentId',
          filterType: FILTER_TYPES.EQUALS,
          filterValue: departmentId,
        });
      }

      if(!_isNil(equipmentClassId)) {
        filters.push({
          column: 'equipmentClassId',
          filterType: FILTER_TYPES.EQUALS,
          filterValue: equipmentClassId,
        });
      }

      if(!inputValue) {

        let query = {
          sortBy: NAME_FIELD_DEFAULT_SORT_BY,
        };

        if(_size(filters) > 0) {
          query.filter = {
            filterGroupType: FILTER_GROUP_TYPES.AND,
            filters,
          };
        }

        return query;
      }

      const equipmentFieldsFilterGroup = {
        filterGroupType: FILTER_GROUP_TYPES.OR,
        filters: [
          {
            column: 'name',
            filterType: FILTER_TYPES.CONTAINS,
            filterValue: inputValue,
          },
          {
            column: 'identity',
            filterType: FILTER_TYPES.CONTAINS,
            filterValue: inputValue,
          },
        ],
      };

      return {
        filter: _size(filters) > 0 ?
          ({
            filterGroupType: FILTER_GROUP_TYPES.AND,
            filters: [
              ...filters,
              equipmentFieldsFilterGroup,
            ],
          }) :
          equipmentFieldsFilterGroup,
        sortBy: NAME_FIELD_DEFAULT_SORT_BY,
      };
    },
  });

export const EquipmentsAutocomplete = props =>  {

  const {
    departmentId,
    equipmentClassId,
    shouldLoadOptions,
    prepareOptions,
  } = props;

  const loadOptionsActionCreator = useMemo(
    () => {
      if(!shouldLoadOptions) {
        return undefined;
      }

      return _getEquipmentsAutocompleteLoadOptionsActionCreator(departmentId, equipmentClassId, prepareOptions);
    },
    [departmentId, equipmentClassId, shouldLoadOptions, prepareOptions],
  );

  return (
    <AutocompleteContainer
        {...props}
        getOptionLabel={getResourceCombinedName}
        loadOptionsActionCreator={loadOptionsActionCreator}
        placeholder={NotSelectedLabelTrans}
        noOptionsMessage={NoDataLabelTrans}
    />
  );
};

EquipmentsAutocomplete.propTypes = {
  departmentId: NUMBER_OR_STRING_TYPE,
  equipmentClassId: NUMBER_OR_STRING_TYPE,
  shouldLoadOptions: PropTypes.bool,
  prepareOptions: PropTypes.func,
};