import { FILTER_COMPONENTS_MAP } from '../../common/Filters/FilterComponents/index';
import { createCaEntitiesAutocompleteLoadOptionsActionCreator } from '../../../reducers/autocomplete/actions';
import { ENTITY_BATCH_MODEL, ENTITY_MODEL, ORDER_MODEL, SHEET_MODEL } from '../../../constants/models';
import { FILTER_GROUP_TYPES, FILTER_TYPES } from '../../../api/restCollectionApi/index';
import React from 'react';
import {
  SHEET_TYPE_COMPLETED_REQUEST_FILTERS,
  SHEET_TYPE,
  SHEETS_DEFAULT_REQUEST_OPTIONS,
  SHEET_TYPE_IN_PRODUCTION_REQUEST_FILTERS,
  SHEET_TYPE_DEFAULT_WAITING_PARTS_AND_MATERIALS_REQUEST_FILTERS,
  SHEET_TYPE_PAUSED_REQUEST_FILTERS,
  SHEET_TYPE_ASSEMBLY_WAITING_PARTS_AND_MATERIALS_REQUEST_FILTERS,
  SHEET_TYPE_INAPPROPRIATE_REQUEST_FILTERS,
  SHEET_TYPE_DEFECT_REQUEST_FILTERS,
} from '../../../constants/sheets';
import {
  EntityBatchLabelTrans,
  EntityLabelTrans,
  FilterNotSelectedLabelTrans,
  NoMatchesFoundLabelTrans,
  OrderLabelTrans,
  SheetLabelTrans,
} from '../../../utils/commonTransComponents';
import { getEntityCombinedName } from '@bfg-frontend/utils/lib/stringBuilders/entity';


export const createSheetsFiltersSchema = (sheetTypeIdentity, sheetTypePredefinedFilters) => ({
  entityBatch__entityId: _createEntityIdFilterSchema(sheetTypeIdentity, sheetTypePredefinedFilters),
  entityBatch__orderId: _createOrderIdFilterSchema(sheetTypeIdentity, sheetTypePredefinedFilters),
  id: _createSheetIdFilterSchema(sheetTypeIdentity, sheetTypePredefinedFilters),
});

const _createEntityIdFilterSchema = (sheetTypeIdentity, sheetTypePredefinedFilters) =>
  _createAutocompleteFilterSchemaItem({
    sheetTypeIdentity,
    autocompleteEntitiesModel: ENTITY_MODEL,
    getFilterTitle: () => EntityLabelTrans,
    getOptionLabel: getEntityCombinedName,
    sheetTypePredefinedFilters,
    getRequestFiltersFromAutocompleteInputValue: inputValue => ({
      filterGroupType: FILTER_GROUP_TYPES.OR,
      filters: [
        {
          column: 'entity__identity',
          filterType: FILTER_TYPES.CONTAINS,
          filterValue: inputValue,
        },
        {
          column: 'entity__code',
          filterType: FILTER_TYPES.CONTAINS,
          filterValue: inputValue,
        },
        {
          column: 'entity__name',
          filterType: FILTER_TYPES.CONTAINS,
          filterValue: inputValue,
        },
      ],
    }),
  });

const _createOrderIdFilterSchema = (sheetTypeIdentity, sheetTypePredefinedFilters) =>
  _createAutocompleteFilterSchemaItem({
    sheetTypeIdentity,
    autocompleteEntitiesModel: ORDER_MODEL,
    getFilterTitle: () => OrderLabelTrans,
    getOptionLabel: ({ name }) => name,
    sheetTypePredefinedFilters,
    getRequestFiltersFromAutocompleteInputValue: inputValue => ({
      column: 'order__name',
      filterType: FILTER_TYPES.CONTAINS,
      filterValue: inputValue,
    }),
  });

const _createSheetIdFilterSchema = (sheetTypeIdentity, sheetTypePredefinedFilters) =>
  _createAutocompleteFilterSchemaItem({
    sheetTypeIdentity,
    autocompleteEntitiesModel: SHEET_MODEL,
    getFilterTitle: () => SheetLabelTrans,
    getOptionLabel: ({ identity }) => identity,
    sheetTypePredefinedFilters,
    getRequestFiltersFromAutocompleteInputValue: inputValue => ({
      column: 'identity',
      filterType: FILTER_TYPES.CONTAINS,
      filterValue: inputValue,
    }),
  });

const _createAutocompleteFilterSchemaItem = ({
  sheetTypeIdentity,
  autocompleteEntitiesModel,
  getFilterTitle,
  getOptionValue,
  getOptionLabel,
  sheetTypePredefinedFilters,
  getRequestFiltersFromAutocompleteInputValue,
  options,
  isMulti,
}) => ({
  getFilterTitle,
  //eslint-disable-next-line react/prop-types
  getFilterChipContent: ({ filterAdditionalData }, title) => {
    const optionLabel = !Array.isArray(filterAdditionalData) ?
      getOptionLabel(filterAdditionalData) :
      filterAdditionalData.map(filterData => getOptionLabel(filterData)).join(', ');

    return (
      <span>
        {title}
        {': '}
        <b>{optionLabel}</b>
      </span>
    );
  },
  filterComponent: FILTER_COMPONENTS_MAP.AUTOCOMPLETE,
  filterComponentProps: {
    autocompleteId: [sheetTypeIdentity, autocompleteEntitiesModel].join('__'),
    getOptionValue,
    getOptionLabel,
    placeholder: FilterNotSelectedLabelTrans,
    noOptionsMessage: NoMatchesFoundLabelTrans,
    loadOptionsActionCreator: sheetTypePredefinedFilters && getRequestFiltersFromAutocompleteInputValue ?
      createCaEntitiesAutocompleteLoadOptionsActionCreator({
        requestModel: SHEET_MODEL,
        getRequestQuery: inputValue => {

          if(!inputValue) return {
            filter: {
              filterGroupType: FILTER_GROUP_TYPES.AND,
              filters: sheetTypePredefinedFilters,
            },
          };

          return {
            filter: {
              filterGroupType: FILTER_GROUP_TYPES.AND,
              filters: sheetTypePredefinedFilters
                .concat(getRequestFiltersFromAutocompleteInputValue(inputValue)),
            },
          };
        },
        getRequestOptions: () => SHEETS_DEFAULT_REQUEST_OPTIONS,
        optionsModel: autocompleteEntitiesModel,
      }) :
      undefined,
    options,
    isMulti,
  },
});


export const SHEETS_IN_PRODUCTION_FILTERS_SCHEMA = createSheetsFiltersSchema(
  SHEET_TYPE.IN_PRODUCTION,
  SHEET_TYPE_IN_PRODUCTION_REQUEST_FILTERS,
);

export const PAUSED_SHEETS_FILTERS_SCHEMA = createSheetsFiltersSchema(
  SHEET_TYPE.PAUSED,
  SHEET_TYPE_PAUSED_REQUEST_FILTERS,
);

export const COMPLETED_SHEETS_FILTERS_SCHEMA = createSheetsFiltersSchema(
  SHEET_TYPE.COMPLETED,
  SHEET_TYPE_COMPLETED_REQUEST_FILTERS,
);

export const DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_FILTERS_SCHEMA = createSheetsFiltersSchema(
  SHEET_TYPE.DEFAULT_WAITING_PARTS_AND_MATERIALS,
  SHEET_TYPE_DEFAULT_WAITING_PARTS_AND_MATERIALS_REQUEST_FILTERS,
);

export const ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_FILTERS_SCHEMA = createSheetsFiltersSchema(
  SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
  SHEET_TYPE_ASSEMBLY_WAITING_PARTS_AND_MATERIALS_REQUEST_FILTERS,
);

export const INAPPROPRIATE_SHEETS_FILTERS_SCHEMA = createSheetsFiltersSchema(
  SHEET_TYPE.INAPPROPRIATE,
  SHEET_TYPE_INAPPROPRIATE_REQUEST_FILTERS,
);


export const DEFECT_SHEETS_FILTERS_SCHEMA = createSheetsFiltersSchema(
  SHEET_TYPE.DEFECTIVE,
  SHEET_TYPE_DEFECT_REQUEST_FILTERS,
);

/*
* Фильтры МЛ на запуск в данный момент работают по логике отличной от других типов МЛ:
* - Сама таблица МЛ на запуск не серверная и кастомная в плане колонок в отличии от всех других типов МЛ, поэтому и
* ключи схем фильтров отличаются от ключей схемы других типов МЛ
* - Получать опции для фильтров МЛ на запуск не получается при помощи серверного авктокомлита, т.к. данные таблицы
* приходят с data-точки. Т.к. таблица не серверная и с data точки запрашиваются сразу все данные таблицы, то в этом
* случае мы можем высчитать и самостоятельно сразу задать все опции автокомплиту, т.е. здесь автокомплиты не серверные.
*
* Некоторые вещи, типа тайтлов и принципа формирования лейблов, конечно, повторяются со схемами в
* createSheetsFiltersSchema, но решил тут ничего не перемешивать и сделать параметризацию формирования схем для МЛ
* на старт отдельно, т.к. это частность и, вероятно, когда-то будет реализовано тоже через createSheetsFiltersSchema,
* если эта таблица тоже станет серверной
*
* */
export const createSheetsToStartFiltersSchema = (entityFilterOptions, orderFilterOptions, entityBatchFilterOptions, sheetType) => ({
  entityIdentity: _createAutocompleteFilterSchemaItem({
    sheetTypeIdentity: sheetType,
    autocompleteEntitiesModel: ENTITY_MODEL,
    getFilterTitle: () => EntityLabelTrans,
    getOptionValue: ({ entityIdentity }) => entityIdentity,
    getOptionLabel: ({ entityIdentity, entityCode, entityName }) =>
      getEntityCombinedName({
        identity: entityIdentity,
        code: entityCode,
        name: entityName,
      }),
    options: entityFilterOptions,
  }),
  orderName: _createAutocompleteFilterSchemaItem({
    sheetTypeIdentity: sheetType,
    autocompleteEntitiesModel: ORDER_MODEL,
    getFilterTitle: () => OrderLabelTrans,
    getOptionValue: ({ orderName }) => orderName,
    getOptionLabel: ({ orderName }) => orderName,
    options: orderFilterOptions,
  }),
  entityBatchIdentity: _createAutocompleteFilterSchemaItem({
    sheetTypeIdentity: sheetType,
    autocompleteEntitiesModel: ENTITY_BATCH_MODEL,
    getFilterTitle: () => EntityBatchLabelTrans,
    getOptionValue: ({ entityBatchIdentity }) => entityBatchIdentity,
    getOptionLabel: ({ entityBatchIdentity }) => entityBatchIdentity,
    options: entityBatchFilterOptions,
    isMulti: true,
  }),
});
