import React from 'react';
import PropTypes from 'prop-types';
import { AppTypeTopBarContainer } from '../../AppTypeTopBar/AppTypeTopBarContainer';
import { WorkerLabelTrans } from '../../../utils/commonTransComponents';
import cn from 'classnames';


export const WorkerAppTypeTopBar = ({ titleComponent, appTitle, className }) => (
  <AppTypeTopBarContainer
      className={
        cn(
          'worker-app-type-top-bar',
          className,
        )
      }
      position="fixed"
      showReloadButtonOnlyOnFullscreen={false}
      TitleComponent={titleComponent}
      appTitle={appTitle}
      renderButtons={_renderWorkerAppTypeTopBarButtons}
  />
);

const _renderWorkerAppTypeTopBarButtons = (userMenuButton, reloadButton, exitFromAppButton) => (
  <React.Fragment>
    {userMenuButton}
    {reloadButton}
    {exitFromAppButton}
  </React.Fragment>
);

WorkerAppTypeTopBar.propTypes = {
  className: PropTypes.string,
  titleComponent: PropTypes.elementType,
  appTitle: PropTypes.node,
};

WorkerAppTypeTopBar.defaultProps = {
  appTitle: WorkerLabelTrans,
};
