import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { minSymbolsValidatorFabric } from '../../../../utils/formValidators';
import { SimpleTabs } from '../../../common/SimpleTabs/SimpleTabs';
import {
  MATERIAL_UI_DIALOG_MAX_WIDTH,
  MATERIAL_UI_LABEL_PLACEMENT,
  MATERIAL_UI_ORIENTATION,
  MATERIAL_UI_STYLE_COLOR,
  MATERIAL_UI_VARIANT,
} from '../../../../constants/materialUI';
import { Chip } from '@mui/material';
import './style.css';
import _get from 'lodash/get';
import { FUNC_IS_REQUIRED_TYPE, TABLE_FILTER_PARAMS_TYPE } from '../../../../constants/propTypes';
import {
  AddFilterLabelTrans,
  AllAvailableFiltersSetLabelTrans,
  ApplyFilterLabelTrans,
  AssigneeLabelTrans,
  CancelLabelTrans,
  ChooseFiltersLabelTrans,
  ChooseTableColumnToFilterLabelTrans,
  DepartmentLabelTrans,
  EntityLabelTrans,
  EquipmentClassLabelTrans,
  EquipmentLabelTrans,
  NoMatchesFoundLabelTrans,
  OperationLabelTrans,
  OrderLabelTrans,
  SheetLabelTrans,
  RemoveAllFiltersLabelTrans,
} from '../../../../utils/commonTransComponents';
import { useTasksSearchHistory } from './useTasksSearchHistory';
import _size from 'lodash/size';
import { Trans } from '@lingui/macro';
import {
  EquipmentClassesInDepartmentsAutocomplete,
  equipmentClassInDepartmentAutocompleteGetOptionLabel,
} from '../../../entitiesAutocomplete/EquipmentClassesInDepartmentsAutocomplete/EquipmentClassesInDepartmentsAutocomplete';
import {
  getSheetsAutocompleteOptionLabel,
  SheetsAutocomplete,
} from '../../../entitiesAutocomplete/SheetsAutocomplete/SheetsAutocomplete';
import { ENTITY_BATCH_STATUS, SHEET_STATUS } from '../../../../constants/sheets';
import { EntityModelAutocomplete } from '../../../entitiesAutocomplete/EntityModelAutocomplete/EntityModelAutocomplete';
import { UsersAutocomplete } from '../../../entitiesAutocomplete/UsersAutocomplete/UsersAutocomplete';
import { EquipmentsAutocomplete } from '../../../entitiesAutocomplete/EquipmentAutocomplete/EquipmentAutocomplete';
import {
  getOrdersAutocompleteOptionLabel,
  OrdersAutocomplete,
} from '../../../entitiesAutocomplete/OrdersAutocomplete/OrdersAutocomplete';
import { SERVER_ORDER_STATUS } from '../../../../constants/orders';
import Paper from '@mui/material/Paper';
import { Filters } from '../../../common/Filters/Filters';
import {
  getEntityCombinedName,
  getResourceCombinedName,
  getUserFullName,
} from '@bfg-frontend/utils/lib/stringBuilders/entity';
import _last from 'lodash/last';
import { FILTER_TYPES } from '../../../../api/restCollectionApi/index';
import _isEmpty from 'lodash/isEmpty';
import {
  AutocompleteMenuListWithScrollButtons,
} from '../../../common/Autocomplete/AutocompleteMenuListWithScrollBtns/AutocompleteMenuListWithScrollButtons';
import { FILTER_COMPONENTS_MAP } from '../../../common/Filters/FilterComponents';


const defaultFilterComponentProps = {
  isMulti: true,
  isClearable: true,
  shouldPreloadData: true,
};

const USE_SCROLL_BUTTONS_FOR_AUTOCOMPLETE = _get(window, ['config', 'USE_SCROLL_BUTTONS_FOR_AUTOCOMPLETE'], false);

const defaultAdditionalAutocompleteProps = USE_SCROLL_BUTTONS_FOR_AUTOCOMPLETE ?
  ({
    reactSelectProps: {
      autoFocus: true,
    },
    shouldLoadOptions: true,
    components: { MenuList: AutocompleteMenuListWithScrollButtons },
  }) :
  ({
    reactSelectProps: {
      autoFocus: true,
    },
    shouldLoadOptions: true,
    autoFocus: true,
  });


const TASKS_FILTER_EQ_CLASS_IN_DEP_AUTOCOMPLETE_ID = 'TASKS_FILTER_EQ_CLASS_IN_DEP_AUTOCOMPLETE_ID';
const TASKS_FILTER_SHEETS_AUTOCOMPLETE_ID = 'TASKS_FILTER_SHEETS_AUTOCOMPLETE_ID';
const TASKS_FILTER_ENTITY_MODEL_AUTOCOMPLETE_ID = 'TASKS_FILTER_ENTITY_MODEL_AUTOCOMPLETE_ID';
const TASKS_FILTER_USER_AUTOCOMPLETE_ID = 'TASKS_FILTER_USER_AUTOCOMPLETE_ID';
const TASKS_FILTER_EQUIPMENT_AUTOCOMPLETE_ID = 'TASKS_FILTER_EQUIPMENT_AUTOCOMPLETE_ID';
const TASKS_FILTER_ORDER_AUTOCOMPLETE_ID = 'TASKS_FILTER_ORDER_AUTOCOMPLETE_ID';

export const TASKS_FILTER_SCHEMA_FILTER_KEYS = {
  EQ_CLASS_IN_DEP: 'EQ_CLASS_IN_DEP',
  SHEET: 'SHEET',
  ENTITY: 'ENTITY',
  OPERATION: 'OPERATION',
  USER: 'USER',
  EQUIPMENT: 'EQUIPMENT',
  ORDER: 'ORDER',
};

const tasksTableGetFilterChipContent = (title, value) => (
  <span>
    {title}
    {': '}
    <b>{value}</b>
  </span>
);

export const createTasksTableGetFilterChipContent = getAutocompleteOptionLabel =>
  (filterData, filterTitle) => {
    const {
      filterAdditionalData = [],
    } = filterData;

    const filterValuesString = filterAdditionalData
      .map(option => getAutocompleteOptionLabel(option))
      .join(', ');

    return tasksTableGetFilterChipContent(filterTitle, filterValuesString);
  };

const createTasksTableGetFilterChipContentForOperations = getAutocompleteOptionLabel =>
  (filterData, filterTitle) => {
    const filterValuesString = getAutocompleteOptionLabel(filterData);

    return tasksTableGetFilterChipContent(filterTitle, filterValuesString);
  };

const getOperationFilterValue = filterValue => {
  if (typeof filterValue !== 'string') {
    return undefined;
  }
  return filterValue;
};

const defaultGetHistoryDataForStore = (newFilterData, prevFilterData) => {

  const newFilterAdditionalData = _get(newFilterData, 'filterAdditionalData', []);

  const prevFilterAdditionalData = _get(prevFilterData, 'filterAdditionalData', []);

  /*
  * Этим условие проверяем, что в мультиавтокомплит (дефолтный defaultGetHistoryDataForStore именно для таких фильтров)
  * была добавлена новая опции, т.е. их количество стало больше, чем было. Во всех остальных случаях возвращаем null,
  * если записи удаляются, то сохранять что-то в историю не нужно
  * */
  if (
    newFilterAdditionalData.length <= prevFilterAdditionalData.length
  ) {
    return null;
  }

  /*
  * На этом участке кода в newFilterAdditionalData уже точно есть хотя бы 1 элемент, потому что если бы было 0 элементов,
  * то прошлое условие точно бы сработало для любого возможного prevFilterAdditionalData (даже если там тоже 0
  * элементов)
  *
  * Дефолтный обработчик defaultGetHistoryDataForStore написан для мультиавтокомплитов, в который опции добавляются
  * по одной, поэтому, в случае, если опций стали больше, то значит была добавлена 1 новая опция. Вроде как,
  * логично добавить только её в историю
  * */

  return _last(newFilterAdditionalData);
};

const defaultGetHistoryItemId = ({ id }) => id;

/*
* Дефолтный обработчик по восстановлению фильтров из истории для фильтров автокомплитов
* */
const defaultGetNewFilterDataIfHistoryItemClicked = (historyItemData, historyItemId, currentFilterForKey) => {

  if (_isEmpty(currentFilterForKey)) {
    return {
      filterValue: [historyItemId],
      filterType: FILTER_TYPES.ONE_OF,
      filterAdditionalData: [historyItemData],
    };
  }

  /*
  * Когда уже установлен фильтр, то, если опция из истории уже установлена, то ничего не делаем, если её нет,
  * то добавляем к текущим фильтрам.
  * На самом деле, пока не до конца понятно, какое поведение будет более логичным, вероятно, можно было бы
  * полностью заменять фильтры. Но, кажется, что такой кейс с полной заменой легко можно достичь 2 кликами -
  * удалив все фильтры и нажав на историю. А вот, если, такое поведение с полной заменой не ожидаемо для пользователя,
  * то он случайно может удалить предыдущие записи, которые он старался и устанавливал
  * */

  const {
    filterValue,
    filterType,
    filterAdditionalData,
  } = currentFilterForKey;

  if (filterValue.includes(historyItemId)) {
    return;
  }

  return {
    filterValue: filterValue.concat(historyItemId),
    filterType,
    filterAdditionalData: filterAdditionalData.concat(historyItemData),
  };
};

const defaultTabCounter = filterData => _get(filterData, ['filterValue', 'length'], 0);

const min3SymbolsValidator = minSymbolsValidatorFabric(2, true);

export const TASKS_TABLE_FILTER_SCHEMA = [
  {
    filterKey: TASKS_FILTER_SCHEMA_FILTER_KEYS.SHEET,
    getFilterTitle: () => SheetLabelTrans,
    filterComponent: FILTER_COMPONENTS_MAP.AUTOCOMPLETE,
    filterComponentProps: {
      ...defaultFilterComponentProps,
      autocompleteId: TASKS_FILTER_SHEETS_AUTOCOMPLETE_ID,
      autocompleteComponent: SheetsAutocomplete,
      additionalAutocompleteProps: {
        ...defaultAdditionalAutocompleteProps,
        sheetStatus: SHEET_STATUS.ACTIVE,
        entityBatchStatus: ENTITY_BATCH_STATUS.IN_PRODUCTION,
      },
    },
    getFilterChipContent: createTasksTableGetFilterChipContent(getSheetsAutocompleteOptionLabel),
    getHistoryItemLabel: getSheetsAutocompleteOptionLabel,
    tabCounter: defaultTabCounter,
    saveOnlyOnSubmit: false,
  },

  {
    filterKey: TASKS_FILTER_SCHEMA_FILTER_KEYS.ENTITY,
    getFilterTitle: () => EntityLabelTrans,
    filterComponent: FILTER_COMPONENTS_MAP.AUTOCOMPLETE,
    filterComponentProps: {
      ...defaultFilterComponentProps,
      autocompleteId: TASKS_FILTER_ENTITY_MODEL_AUTOCOMPLETE_ID,
      autocompleteComponent: EntityModelAutocomplete,
      additionalAutocompleteProps: defaultAdditionalAutocompleteProps,
    },
    getFilterChipContent: createTasksTableGetFilterChipContent(getEntityCombinedName),
    getHistoryItemLabel: getEntityCombinedName,
    tabCounter: defaultTabCounter,
    saveOnlyOnSubmit: false,
  },

  {
    filterKey: TASKS_FILTER_SCHEMA_FILTER_KEYS.OPERATION,
    getFilterTitle: () => OperationLabelTrans,
    filterComponent: FILTER_COMPONENTS_MAP.TEXT_INPUT,
    filterComponentProps: {
      minSymbols: 3,
    },
    getFilterChipContent: createTasksTableGetFilterChipContentForOperations(({ filterValue }) => filterValue),
    getHistoryItemLabel: getOperationFilterValue,
    getHistoryItemId: getOperationFilterValue,
    getNewFilterDataIfHistoryItemClicked: (_, historyItemId) => (
      {
        filterValue: historyItemId,
        filterType: FILTER_TYPES.CONTAINS,
      }
    ),
    tabCounter: filterData => filterData === null ? 0 : 1,
    saveOnlyOnSubmit: true,
    errorValidator: filterData => {
      if (_isEmpty(filterData)) {
        return undefined;
      }

      return min3SymbolsValidator(filterData.filterValue);
    },
  },

  {
    filterKey: TASKS_FILTER_SCHEMA_FILTER_KEYS.USER,
    getFilterTitle: () => AssigneeLabelTrans,
    filterComponent: FILTER_COMPONENTS_MAP.AUTOCOMPLETE,
    filterComponentProps: {
      ...defaultFilterComponentProps,
      autocompleteId: TASKS_FILTER_USER_AUTOCOMPLETE_ID,
      autocompleteComponent: UsersAutocomplete,
      additionalAutocompleteProps: defaultAdditionalAutocompleteProps,
    },
    getFilterChipContent: createTasksTableGetFilterChipContent(getUserFullName),
    getHistoryItemLabel: getUserFullName,
    tabCounter: defaultTabCounter,
    saveOnlyOnSubmit: false,
  },

  {
    filterKey: TASKS_FILTER_SCHEMA_FILTER_KEYS.EQUIPMENT,
    getFilterTitle: () => EquipmentLabelTrans,
    filterComponent: FILTER_COMPONENTS_MAP.AUTOCOMPLETE,
    filterComponentProps: {
      ...defaultFilterComponentProps,
      autocompleteId: TASKS_FILTER_EQUIPMENT_AUTOCOMPLETE_ID,
      autocompleteComponent: EquipmentsAutocomplete,
      additionalAutocompleteProps: defaultAdditionalAutocompleteProps,
    },
    getFilterChipContent: createTasksTableGetFilterChipContent(getResourceCombinedName),
    getHistoryItemLabel: getResourceCombinedName,
    tabCounter: defaultTabCounter,
    saveOnlyOnSubmit: false,
  },

  {
    filterKey: TASKS_FILTER_SCHEMA_FILTER_KEYS.ORDER,
    getFilterTitle: () => OrderLabelTrans,
    filterComponent: FILTER_COMPONENTS_MAP.AUTOCOMPLETE,
    filterComponentProps: {
      ...defaultFilterComponentProps,
      autocompleteId: TASKS_FILTER_ORDER_AUTOCOMPLETE_ID,
      autocompleteComponent: OrdersAutocomplete,
      additionalAutocompleteProps: {
        ...defaultAdditionalAutocompleteProps,
        serverOrderStatuses: [SERVER_ORDER_STATUS.IN_PRODUCTION, SERVER_ORDER_STATUS.READY_TO_COMPLETE],
      },
    },
    getFilterChipContent: createTasksTableGetFilterChipContent(getOrdersAutocompleteOptionLabel),
    getHistoryItemLabel: getOrdersAutocompleteOptionLabel,
    tabCounter: defaultTabCounter,
    saveOnlyOnSubmit: false,
  },

  {
    filterKey: TASKS_FILTER_SCHEMA_FILTER_KEYS.EQ_CLASS_IN_DEP,
    getFilterTitle: () => <span>{EquipmentClassLabelTrans} {' / '} {DepartmentLabelTrans}</span>,
    filterComponent: FILTER_COMPONENTS_MAP.AUTOCOMPLETE,
    filterComponentProps: {
      ...defaultFilterComponentProps,
      autocompleteId: TASKS_FILTER_EQ_CLASS_IN_DEP_AUTOCOMPLETE_ID,
      autocompleteComponent: EquipmentClassesInDepartmentsAutocomplete,
      additionalAutocompleteProps: defaultAdditionalAutocompleteProps,
    },
    getFilterChipContent: createTasksTableGetFilterChipContent(equipmentClassInDepartmentAutocompleteGetOptionLabel),
    getHistoryItemLabel: equipmentClassInDepartmentAutocompleteGetOptionLabel,
    tabCounter: defaultTabCounter,
    saveOnlyOnSubmit: false,
  },
];

export const TasksTableFilters = props => {
  const {
    filterParams,
    changeFilters,
    defaultSelectedFilterTabId,
    onFiltersModalClose,
    filtersModalOpened,
    saveWorkerTasksTableFiltersAndSearchHistory,
  } = props;

  const {
    searchHistory,
    setSearchHistory,
  } = useTasksSearchHistory();

  const handleApplyFilters = useCallback(newFilters => {
    const isFilterHasOperationFilter = TASKS_FILTER_SCHEMA_FILTER_KEYS.OPERATION in newFilters;

    if (!isFilterHasOperationFilter) {
      return changeFilters(newFilters)
        .then(() => saveWorkerTasksTableFiltersAndSearchHistory(newFilters, searchHistory));
    }

    // Фильтр операций -- отдельный случай: это текстовое поле, поэтому нужно запоминать историю
    // в момент применения фильтров, а не на изменении значения поля
    const newOperationSearchHistoryItem = newFilters[TASKS_FILTER_SCHEMA_FILTER_KEYS.OPERATION].filterValue;

    const updatedSearchHistory = setSearchHistory(
      TASKS_FILTER_SCHEMA_FILTER_KEYS.OPERATION,
      newOperationSearchHistoryItem,
      getOperationFilterValue,
    );

    return changeFilters(newFilters)
      .then(() => saveWorkerTasksTableFiltersAndSearchHistory(newFilters, updatedSearchHistory));
  }, [saveWorkerTasksTableFiltersAndSearchHistory, changeFilters, setSearchHistory, searchHistory]);


  return (
    <div className="tasks-table-filters">
      <Paper className="tasks-table-filters__paper" elevation={0}>
        <Filters
            filterSchema={TASKS_TABLE_FILTER_SCHEMA}
            filters={filterParams}
            onApplyFilters={handleApplyFilters}
            filtersModalTitle={ChooseFiltersLabelTrans}
            applyFiltersBtnTitle={ApplyFilterLabelTrans}
            cancelFiltersBtnTitle={CancelLabelTrans}
            addFiltersBtnTitle={AddFilterLabelTrans}
            removeAllFiltersBtnTitle={RemoveAllFiltersLabelTrans}
            chooseFilterToAddPlaceholderTitle={ChooseTableColumnToFilterLabelTrans}
            allAvailableFiltersAddedPlaceholderTitle={AllAvailableFiltersSetLabelTrans}
            createFilterControlNoOptionsTitle={NoMatchesFoundLabelTrans}
            renderFiltersContentComponent={(currentFilters, setCurrentFilters, currentFiltersErrors) =>
              renderFiltersContentComponent({
                currentFilters,
                setCurrentFilters,
                searchHistory,
                setSearchHistory,
                defaultSelectedFilterTabId,
                currentFiltersErrors,
              })}
            dialogMaxWidth={MATERIAL_UI_DIALOG_MAX_WIDTH.LG}
            modalOpened={filtersModalOpened}
            onModalClose={onFiltersModalClose}
        />
      </Paper>
    </div>
  );
};

const renderFiltersContentComponent = ({
  currentFilters,
  setCurrentFilters,
  searchHistory,
  setSearchHistory,
  defaultSelectedFilterTabId,
  currentFiltersErrors,
}) => {
  const tabs = TASKS_TABLE_FILTER_SCHEMA
    .map(schemaItem => {
      const {
        filterKey,
        getFilterTitle,
      } = schemaItem;

      const filterData = _get(currentFilters, [filterKey], null);
      const error = currentFiltersErrors[filterKey];
      const hasError = !!error;

      const filterTitle = getFilterTitle(filterData);

      const filtersAmount = _get(filterData, ['filterValue', 'length'], 0);

      return {
        tabId: filterKey,
        tabTitle: filterTitle,
        tabIcon: filtersAmount === 0 ?
          undefined :
          (
            <Chip
                className="tasks-table-filters__filters-amount-chip"
                key={filterKey}
                label={schemaItem.tabCounter(filterData)}
                color={hasError ? MATERIAL_UI_STYLE_COLOR.SECONDARY : MATERIAL_UI_STYLE_COLOR.PRIMARY}
            />
          ),
        tabIconPosition: MATERIAL_UI_LABEL_PLACEMENT.START,
        tabContent: renderTabContent({
          filterData,
          schemaItem,
          filterTitle,
          currentFilters,
          setCurrentFilters,
          error,
          searchHistory,
          setSearchHistory,
        }),
      };
    });

  return (
    <div className="tasks-table-filters">
      <SimpleTabs
          tabs={tabs}
          orientation={MATERIAL_UI_ORIENTATION.VERTICAL}
          variant={MATERIAL_UI_VARIANT.SCROLLABLE}
          elevation={0}
          defaultSelectedTabId={defaultSelectedFilterTabId}
      />
    </div>
  );
};

const renderTabContent = ({
  filterData,
  schemaItem,
  filterTitle,
  currentFilters,
  setCurrentFilters,
  error,
  searchHistory,
  setSearchHistory,
}) => {
  const {
    filterKey,
    filterComponent: FilterComponent,
    filterComponentProps = {},
    getHistoryItemForStore = defaultGetHistoryDataForStore,
    getHistoryItemId = defaultGetHistoryItemId,
    getHistoryItemLabel,
    getNewFilterDataIfHistoryItemClicked = defaultGetNewFilterDataIfHistoryItemClicked,
    saveOnlyOnSubmit,
  } = schemaItem;

  const historyDataForKey = searchHistory[filterKey];

  /**
   * После изменения формата хранения истории, может возникнуть ситуация, что у пользователей
   * в localStorage всё ещё будет храниться информация в старом формате, и в таком случае, вместо
   * текста, ему будет отображаться "undefined".
   * Понять, подходит ли его формат нам, мы можем, посмотрев на label (предварительно его вычислив).
   * Если он не будет undefined, то всё ок. Если у всех историй будет неверный формат, то помимо
   * самих чипов не нужно отображать ещё и надпись "Вы недавно искали", поэтому вычислить
   * все label-ы стоит заранее.
   */
  const historyDataWithLabel = !_size(historyDataForKey) ?
    null :
    historyDataForKey.map(data => ({
      data,
      label: getHistoryItemLabel(data),
    }
  ));

  const hasHistoryToShow = _size(historyDataWithLabel) && historyDataWithLabel.some(({ label }) => label !== undefined);

  return (
    <div key={filterKey} className="tasks-table-filters__tab-content">
      <div className="tasks-table-filters__filter-component-wrapper">
        <h3>{filterTitle}</h3>
        <FilterComponent
            {...filterComponentProps}
            filterKey={filterKey}
            filterData={filterData}
            onChange={newFilterDataByFilterKey => {
              const historyItemForStore = getHistoryItemForStore(newFilterDataByFilterKey[filterKey], filterData);

              if (!saveOnlyOnSubmit && !!historyItemForStore) {
                setSearchHistory(filterKey, historyItemForStore, getHistoryItemId);
              }

              return setCurrentFilters(newFilterDataByFilterKey);
            }}
            error={error}
        />
      </div>
      {
        !hasHistoryToShow ?
          null :
          <div className="tasks-table-filters__search-suggestions">
            <h4>
              <Trans id="label@recent_search">
                Вы недавно искали
              </Trans>
              {': '}
            </h4>
            {
              historyDataWithLabel
                .map(({
                  data,
                  label,
                }) => renderHistoryChip({
                  filterKey,
                  historyItemData: data,
                  getHistoryItemId,
                  label,
                  getNewFilterDataIfHistoryItemClicked,
                  currentFilters,
                  setCurrentFilters,
                }))
            }
          </div>
      }
    </div>
  );
};

const renderHistoryChip = ({
  filterKey,
  historyItemData,
  getHistoryItemId,
  label,
  getNewFilterDataIfHistoryItemClicked,
  currentFilters,
  setCurrentFilters,
}) => {

  if (!label) {
    return null;
  }

  const historyItemId = getHistoryItemId(historyItemData);

  return (
    <Chip
        key={historyItemId}
        className="tasks-table-filters__search-suggestions-chip"
        label={label}
        onClick={() => {

          const currentFilterForKey = currentFilters[filterKey];

          const updatedCurrentFilterForKey = getNewFilterDataIfHistoryItemClicked(
            historyItemData,
            historyItemId,
            currentFilterForKey,
          );

          if (updatedCurrentFilterForKey === undefined) {
            return;
          }

          return setCurrentFilters({
            [filterKey]: updatedCurrentFilterForKey,
          });
        }}
    />
  );
};

TasksTableFilters.propTypes = {
  changeFilters: FUNC_IS_REQUIRED_TYPE,
  filterParams: TABLE_FILTER_PARAMS_TYPE,
  saveWorkerTasksTableFiltersAndSearchHistory: FUNC_IS_REQUIRED_TYPE,
  defaultSelectedFilterTabId: PropTypes.string,
  onFiltersModalClose: PropTypes.func,
  filtersModalOpened: PropTypes.bool,
};
