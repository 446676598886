import { allTasksTablesIdsSelector } from '../../../../selectors/taskView';
import { routerPathnameSelector } from '../../../../reducers/router/selectors';
import { matchPath } from 'react-router-dom';
import {
  MASTER_APP_TASKS_TO_DO_ROUTE,
  PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE,
  WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE,
  WORKER_APP_TASKS_ROUTE,
} from '../../../../constants/routes';
import {
  clearTableRemoteData,
  reFetchRemoteTableData,
} from '../../../../reducers/table/actions';
import {
  createSheetTypeOperationsTableId,
  getEquipmentClassInDepartmentTasksTableId,
} from '../../../../utils/tables';
import { SHEET_TYPE } from '../../../../constants/sheets';
import { MASTER_TASKS_TO_DO_TABLE_ID } from '../../../../components/MasterApp/MasterTasksToDo/constants';
import { fetchReviewedSheetOperationsRemoteTableEntities } from '../../../../operations/sheets';
import { fetchMasterDepartmentsTasksToDoRemoteTableData } from '../../../../operations/masterWorkspace/index';
import {
  fetchEquipmentClassInDepartmentTasksRemoteTableData,
  fetchTasksRemoteTableData,
} from '../../../../operations/tasks';
import { getMasterAppDepartmentIdsFromRouteParam } from '../../../../components/MasterApp/MasterWorkspace/masterAppDepartmentIdsRouteParam';
import { masterTasksToDoAdditionalFiltersSelector } from '../../../../reducers/masterApp/tasksToDoAdditionalFilters/selectors';
import { TASKS_MODEL } from '../../../../reducers/schemaModel/models/tasksSchema';
import { MASTER_TASKS_TO_DO_TABLE_MODEL } from '../../../../reducers/schemaModel/models/masterTasksSchema';


/*
* Подробное описание о влиянии события изменения данных операции МЛ представлено в комментарииях к функции
* handleSheetOperationDataChanged в SheetInProductionReviewContentContainer, MasterTasksToDoContainer,
* TasksViewScreenContainer и TasksContainer
*/
export const handleSheetOperationDataChange = message =>
  (dispatch, getState) => {
    const {
      sheetId,
    } = message;

    const state = getState();

    /*
    * получаем массив идентификаторов всех таблиц просмотра заданий в приложении "Рабочий"
    * */
    const allTasksTablesIds = allTasksTablesIdsSelector(state);

    const currentPathname = routerPathnameSelector(state);

    /*
    * Если находимся в разделе "Плановик. МЛ в производстве" при просмотре МЛ у операции которого были изменены данные
    * */
    const sheetInProductionReviewRouteMatch = matchPath(currentPathname, {
      path: `${PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE}/:sheetId`,
    });

    if(
      sheetInProductionReviewRouteMatch !== null &&
      sheetInProductionReviewRouteMatch.params.sheetId === sheetId.toString()
    ) {
      return dispatch(_updateIfOnSheetInProductionWithChangedOperationScreen(sheetId, allTasksTablesIds));
    }

    /*
    * Если находимся  в разделе "Мастер. Требуется выполнить"
    * */
    const masterTasksTodoRouteMatch = matchPath(currentPathname, {
      path: MASTER_APP_TASKS_TO_DO_ROUTE,
    });
    if(masterTasksTodoRouteMatch !== null)
      return dispatch(_updateIfOnMasterTasksTodoScreen(
        sheetId,
        getMasterAppDepartmentIdsFromRouteParam(masterTasksTodoRouteMatch.params.departmentIds),
        allTasksTablesIds,
      ));

    /*
    * Если находимся в разделе "Рабочий. Просмотр задания для класса РЦ в подразделении"
    * */
    const tasksForEquipmentClassInDepartmentRouteMatch = matchPath(currentPathname, {
      path: `${WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE}/:departmentId/:equipmentClassId`,
    });
    if(tasksForEquipmentClassInDepartmentRouteMatch !== null) {
      const {
        departmentId,
        equipmentClassId,
      } = tasksForEquipmentClassInDepartmentRouteMatch.params;

      return dispatch(_updateIfOnTasksViewScreen(
        sheetId,
        departmentId,
        equipmentClassId,
        allTasksTablesIds,
      ));
    }

    //Если находимся в разделе "Рабочий. Просмотр ВСЕХ заданий"
    const workerAppTasksRouteMatch = matchPath(currentPathname, {
      path: WORKER_APP_TASKS_ROUTE,
    });
    if(workerAppTasksRouteMatch !== null) {
      return dispatch(_updateIfOnWorkerAppTasksScreen(
        sheetId,
        allTasksTablesIds,
      ));
    }

    /*
    * Для всех остальных разделов не нужна какая-то дополнительная обработка, но и для этого случая необходимо
    * очистить данные всех интерфейсов, где должны быть обновления из-за редактирования данных операции МЛ, чтобы при
    * следующем входе в эти разделы данные были запрошены заново.
    *
    * Очищаем данные для таблиц:
    * - просмотра операций МЛ в производстве в приложении "Плановик"
    * - просмотра заданий в поразделениях в приложении "Мастер", раздел "Требуется выполнить"
    * - всех таблиц заданий в приложении "Рабочий"
    * */
    dispatch(clearTableRemoteData([
      createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId),
      MASTER_TASKS_TO_DO_TABLE_ID,
      TASKS_MODEL,
      ...allTasksTablesIds,
    ]));
  };

const _updateIfOnSheetInProductionWithChangedOperationScreen = (sheetId, allTasksTablesIds) =>
  dispatch => {

    /*
    * Очищаем данные для таблиц:
    * - просмотра заданий в поразделениях в приложении "Мастер", раздел "Требуется выполнить"
    * - всех таблиц заданий в приложении "Рабочий"
    * */
    dispatch(clearTableRemoteData([
      MASTER_TASKS_TO_DO_TABLE_ID,
      TASKS_MODEL,
      ...allTasksTablesIds,
    ]));

    /*
    * Формируем идентификатор таблицы операций для просматриваемого МЛ
    * */
    const sheetOperationsTableId = createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId);

    /*
    * Формируем экшн запроса данных для таблицы операций просматриваемого МЛ
    * */
    const fetchSheetOperations = ({ tableParams }) =>
      dispatch(fetchReviewedSheetOperationsRemoteTableEntities(sheetId, tableParams, { isBlockingRequest: false }));

    /*
    * Выполняем перезапрос табличных данных
    * */
    dispatch(reFetchRemoteTableData(sheetOperationsTableId, null, fetchSheetOperations));
  };

const _updateIfOnMasterTasksTodoScreen = (
  sheetId,
  departmentIds,
  allTasksTablesIds,
) =>
  (dispatch, getState) => {
    /*
    * Очищаем данные для таблиц:
    * - просмотра операций МЛ в производстве в приложении "Плановик"
    * - всех таблиц заданий в приложении "Рабочий"
    * */
    dispatch(clearTableRemoteData([
      createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId),
      TASKS_MODEL,
      ...allTasksTablesIds,
    ]));

    const masterTasksToDoAdditionalFilters = masterTasksToDoAdditionalFiltersSelector(getState());

    /*
    * Перезапрашиваем данные таблицы по заданиям для выбранных подразделений
    * */
    dispatch(reFetchRemoteTableData(
      MASTER_TASKS_TO_DO_TABLE_ID,
      MASTER_TASKS_TO_DO_TABLE_MODEL,
      ({ tableParams }) =>
        dispatch(fetchMasterDepartmentsTasksToDoRemoteTableData(
          departmentIds,
          masterTasksToDoAdditionalFilters,
          tableParams,
          { isBlockingRequest: false },
        )),
    ));
  };

const _updateIfOnTasksViewScreen = (
  sheetId,
  departmentIdFromRoute,
  equipmentClassIdFromRoute,
  allTasksTablesIds,
) =>
  dispatch => {
    /*
    * Формируем идентификатор просматриваемой таблицы заданий для выбранных подразделения и класса РЦ
    * */
    const currentTasksTableId = getEquipmentClassInDepartmentTasksTableId(
      departmentIdFromRoute,
      equipmentClassIdFromRoute,
    );

    /*
    * Очищаем данные для таблиц:
    * - просмотра операций МЛ в производстве в приложении "Плановик"
    * - просмотра заданий в поразделениях в приложении "Мастер", раздел "Требуется выполнить"
    * - всех таблиц заданий в приложении "Рабочий", кроме просматриваемой в данный момент, для неё данные будут
    * просто перезапрошены, чтобы не было скачков в интерфейсе
    * */
    dispatch(clearTableRemoteData([
      createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId),
      MASTER_TASKS_TO_DO_TABLE_ID,
      TASKS_MODEL,
      ...allTasksTablesIds.filter(tableId => tableId !== currentTasksTableId),
    ]));


    /*
    * Формируем экшн запроса данных для таблицы просмотра заданий
    * */
    const fetchTasksRemoteTableData = ({ tableParams }) =>
      dispatch(fetchEquipmentClassInDepartmentTasksRemoteTableData(
        {
          departmentIdsArray: [departmentIdFromRoute],
          equipmentClassIdsArray: [equipmentClassIdFromRoute],
        },
        tableParams,
        { isBlockingRequest: false },
      ));

    /*
    * Выполняем перезапрос табличных данных для текущей таблицы просмотра заданий
    * */
    dispatch(reFetchRemoteTableData(
      currentTasksTableId,
      currentTasksTableId,
      fetchTasksRemoteTableData,
    ));
  };

/*
 * Если находимся в разделе "Рабочий. Просмотр ВСЕХ заданий", то очищаем все данные других интерфейсов кроме этого, где
 * должны быть обновления из-за изменения данных операции МЛ, а для этого раздела перезапрашиваем данные для таблицы заданий,
 *  с учетом текущих параметров таблицы и внешних фильтров
 * */
const _updateIfOnWorkerAppTasksScreen = (sheetId, allTasksTablesIds) =>
  dispatch => {

    dispatch(clearTableRemoteData([
      createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId),
      MASTER_TASKS_TO_DO_TABLE_ID,
      ...allTasksTablesIds,
    ]));

    dispatch(reFetchRemoteTableData(
      TASKS_MODEL,
      TASKS_MODEL,
      ({ tableParams }) =>
        dispatch(fetchTasksRemoteTableData(tableParams, { isBlockingRequest: false })),
    ));
  };
