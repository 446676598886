import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import PropTypes from 'prop-types';
import List from '@mui/material/List';

import ListItem from '@mui/material/ListItem';

import {
  FUNC_IS_REQUIRED_TYPE,
  OBJECT_OF_ANY_TYPE,
  TABLE_FILTER_PARAMS_TYPE,
} from '../../../../../constants/propTypes';

import _isFunction from 'lodash/isFunction';
import _isEmpty from 'lodash/isEmpty';
import {
  NoDataLabelTrans,
  SelectAllValuesLabelTrans,
  UnselectAllValuesLabelTrans,
} from '../../../../../utils/commonTransComponents';
import Checkbox from '@mui/material/Checkbox';
import { useTableCheckboxSelectedRows } from '../../../../../hooks/useTableCheckboxSelectedRows/useTableCheckboxSelectedRows';
import { useDispatch, useSelector } from 'react-redux';
import {
  tableActivePageSelector,
  tableSelectedRowsSelector,
} from '../../../../../reducers/table/selectors';
import { resetSelectedTableRows, setSelectedTableRows } from '../../../../../reducers/table/actions';
import _get from 'lodash/get';
import { stopPropagation } from '../../../../../constants/common';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Button from '@mui/material/Button';
import { MATERIAL_UI_VARIANT } from '../../../../../constants/materialUI';
import CloseIcon from '@mui/icons-material/Close';

/*
* Этот компонент используется как параметр в фабрике tableFactory. По сути, он представляет собой список, но
* логика работы однотипная, почему-бы не реализовать это через абстракцию
* */


export const EntitiesReviewTableComponent = props => {
  const {
    entitiesData,
    entitiesDataForAllPages,
    entityItemIdProperty,
    renderItemContent,
    withRowSelection,
    onItemClick,
    noDataText,
    filterParams,
    tableId,
    renderSelectedRowsControls,
    renderSelectedRowsActionsToast,
    pageSize,
  } = props;

  const dispatch = useDispatch();

  /*
  Сохраняем предыдущий выбранный размер страницы таблицы для оптимизации запуска пересчёта selectAllTableRows
  */
  const prevPageSize = useRef(pageSize);

  const selectedRows = useSelector(state => tableSelectedRowsSelector(state, { tableId }) || {});
  const activePage = useSelector(state => tableActivePageSelector(state, { tableId }));

  const isAllRowsSelected = useMemo(() => {
    const selectedRowsAmount = Object
      .values(selectedRows)
      .reduce((acc, selectedRowsOnPage) =>  acc + Object.keys(selectedRowsOnPage).length, 0);

    return entitiesDataForAllPages.length === selectedRowsAmount;
  }, [entitiesDataForAllPages, selectedRows]);

  const setSelectedRows = useCallback(
    newSelectedRows => dispatch(setSelectedTableRows(tableId, newSelectedRows)),
    [dispatch, tableId],
  );

  const tableRowsIdsArray = useMemo(() => {
    return entitiesData.map(({ entityBatchId }) => entityBatchId);
  }, [entitiesData]);

  const {
    onCheckboxChange,
  } = useTableCheckboxSelectedRows({
    toastId: tableId,
    tableRowsIdsArray,
    selectedRows,
    tableId,
    setSelectedRows,
    renderSelectedRowsActionsToast,
  });


  const unselectAllRows = useCallback(() => {
    dispatch(resetSelectedTableRows(tableId));

  }, [dispatch, tableId]);


  const selectRowHandler = useCallback((e, itemId, listItemData) => {
    e.stopPropagation();

    onCheckboxChange(e.target.checked, itemId, listItemData, e);
  }, [onCheckboxChange]);

  const selectAllTableRows = useCallback(pageSize => {
    dispatch(setSelectedTableRows(
      tableId,
      entitiesDataForAllPages
        .reduce((acc, { entityBatchId }, index) => {

          const currentPage = Math.ceil((index + 1) / pageSize);

          return {
            ...acc,
            [currentPage]: {
              ..._get(acc, [currentPage], {}),
              [entityBatchId]: true,
            },
          };
        }, {}),
    ));
  }, [
    dispatch,
    entitiesDataForAllPages,
    tableId,
  ]);

  const onSelectAllRowsChange = useCallback(e => {
    const checked = e.target.checked;

    if (!checked) {
      return unselectAllRows();
    }

    selectAllTableRows(pageSize);
  }, [selectAllTableRows, unselectAllRows, pageSize]);


  /*
  Выбранные строки таблицы (selectedTableRows) хранятся в разрезе страниц. Если выбраны все строки таблицы и меняется
  размер страницы таблицы (pageSize), то меняется набор данных для каждой страницы в selectedTableRows (например
  с 20 до 40 выбранных элементов на странице). Поэтому перезапускаем selectAllTableRows при смене размера страницы.
  */
  useEffect(() => {
    if (pageSize === prevPageSize.current) return;

    prevPageSize.current = pageSize;

    const selectedRowsById = Object
        .values(selectedRows)
        .reduce((acc, selectedRowsForPage) => {
          return {
            ...acc,
            ...selectedRowsForPage,
          };
        }, {});

    const selectedTableRowsByPageForNewPageSize = entitiesDataForAllPages
      .reduce((acc, { entityBatchId: rowId }, index) => {
        const isCurrentRowSelected = rowId in selectedRowsById;

        if (!isCurrentRowSelected) return acc;

        const currentPage = Math.ceil((index + 1) / pageSize);

        return {
          ...acc,
          [currentPage]: {
            ..._get(acc, [currentPage], {}),
            [rowId]: true,
          },
        };
      }, {});

    dispatch(setSelectedTableRows(
      tableId,
      selectedTableRowsByPageForNewPageSize,
    ));

  }, [
    dispatch,
    pageSize,
    tableId,
    selectedRows,
    entitiesDataForAllPages,
  ]);

  if (!entitiesData.length) {
    return (
      <div className="entities-review-table__no-data-content">
        {_isEmpty(filterParams) ? noDataText : NoDataLabelTrans}
      </div>
    );
  }

  return (
    <div className="entities-review-table">


      {
        withRowSelection ?
          (
            <div className="entities-review-table__rows-selection-controls">
              <div className="entities-review-table__left-selection-controls">
                <FormGroup>
                  <FormControlLabel
                      control={<Checkbox checked={isAllRowsSelected} onChange={onSelectAllRowsChange}/>}
                      label={SelectAllValuesLabelTrans}
                  />
                </FormGroup>

                <Button
                    variant={MATERIAL_UI_VARIANT.TEXT}
                    startIcon={<CloseIcon />}
                    onClick={unselectAllRows}
                    disabled={_isEmpty(selectedRows)}
                >
                  {UnselectAllValuesLabelTrans}
                </Button>
              </div>

              { _isFunction(renderSelectedRowsControls) ? renderSelectedRowsControls(selectedRows) : null }

            </div>
          ) : null
      }

      <List>
        {
          entitiesData
            .map(listItemData => {

              /*
              * Такого быть не должно, но на всякий случай перестраховываемся и добавляем проверку
              * */
              if (!_isFunction(renderItemContent)) return null;

              const itemId = listItemData[entityItemIdProperty];

              return (
                <ListItem
                    key={itemId}
                    className="entities-review-table__list-item"
                    button
                    divider
                    onClick={() => onItemClick(itemId, listItemData)}
                >
                  {
                    withRowSelection ? (
                        <label
                            onClick={stopPropagation}
                            onMouseDown={stopPropagation}
                            className="entities-review-table__row-select-checkbox-wrapper"
                        >
                          <Checkbox
                              onClick={e => selectRowHandler(e, itemId, listItemData)}
                              onMouseDown={stopPropagation}
                              className="entities-review-table__row-select-checkbox"
                              checked={!!_get(selectedRows, [activePage, itemId])}
                          />
                        </label>
                    ) : null
                  }
                  <div className="entities-review-table__list-item-content">
                    {renderItemContent(listItemData)}
                  </div>
                </ListItem>
              );
            })
        }
      </List>
    </div>
  );
};

EntitiesReviewTableComponent.propTypes = {
  entityItemIdProperty: PropTypes.string.isRequired,
  renderItemContent: PropTypes.func.isRequired,
  entitiesData: PropTypes.arrayOf(OBJECT_OF_ANY_TYPE),
  entitiesDataForAllPages: PropTypes.arrayOf(OBJECT_OF_ANY_TYPE),
  noDataText: PropTypes.node,
  onItemClick: FUNC_IS_REQUIRED_TYPE,
  filterParams: TABLE_FILTER_PARAMS_TYPE,
  withRowSelection: PropTypes.bool,
  tableId: PropTypes.string.isRequired,
  tableModel: PropTypes.string.isRequired,
  renderSelectedRowsControls: PropTypes.func,
  renderSelectedRowsActionsToast: PropTypes.func,
  pageSize: PropTypes.number.isRequired,
};

EntitiesReviewTableComponent.defaultProps = {
  entitiesData: [],
  noDataText: NoDataLabelTrans,
  withRowSelection: false,
};