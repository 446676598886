import { Icon } from '@mui/material';
import { Cancel } from '@mui/icons-material';
import React from 'react';
import { NotificationSystem as BFGNotificationSystem } from '@bfg-frontend/notification-system';

import 'react-toastify/dist/ReactToastify.css';
import '@bfg-frontend/notification-system/lib/assets/muiLikeStyle.min.css';

// eslint-disable-next-line react/prop-types
const CloseButton = ({ closeToast }) => (
  <Icon className="Toastify__close-icon" onClick={closeToast}>
    <Cancel/>
  </Icon>
);

export const NotificationSystem = () => (
  <BFGNotificationSystem closeButton={CloseButton} />
);
