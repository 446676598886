import React from 'react';
import { fetchDataFromServerActionPoint, sendActionToServer } from '../../api/index';
import {
  USERS_AUTOCOMPLETE_PREDEFINED_FILTERS,
  USERS_AUTOCOMPLETE_STATUSES,
} from '../../components/entitiesAutocomplete/UsersAutocomplete/constants';
import { SERVER_ACTION_POINT } from '../../constants/serverActions';
import { getErrorMessage, showError } from '../../api/requestHandlers/errorHandlers/errorHandlers';
import {
  ACTIVATE_USER_ERRORS,
  CREATE_USER_ERRORS,
  DEACTIVATE_USER_ERRORS,
  EDIT_USER_ERRORS,
  LOGIN_ERRORS,
} from '../../api/requestHandlers/errorHandlers/errorMaps';
import { handleLogout } from './handleLogout';
import { ROLE_MODEL, USER_MODEL, USER_ROLE_MODEL } from '../../constants/models';
import { FILTER_GROUP_TYPES, FILTER_TYPES } from '../../api/restCollectionApi/index';
import {
  fetchEntitiesFromServer,
  fetchEntitiesFromServerAndAddToStore,
  fetchRemoteTableEntities,
  saveEntitiesOnServer,
} from '../../reducers/entities/actions';
import _partialRight from 'lodash/partialRight';
import _isEmpty from 'lodash/isEmpty';
import _flatten from 'lodash/flatten';
import { reFetchRemoteTableData } from '../../reducers/table/actions';
import {
  ADMIN_APP_ACTIVE_USERS_TABLE_ID,
  ADMIN_APP_DEACTIVATED_USERS_TABLE_ID,
} from '../../components/AdminApp/UsersTable/constants';
import { SERVER_ERROR_IDENTITY, SERVER_ERROR_SOURCE } from '../../constants/serverErrors/index';
import { transformRequestBodyFieldsServerErrors } from '../../constants/serverErrors/requestBodyFieldsServerErrors';
import { NOTIFICATION_LEVEL, sendNotification } from '../../constants/notification';
import { roleEntitiesSelector } from '../../reducers/entities/selectors';
import { USER_FULL_NAME_TABLE_COLUMN, USER_ROLES_TABLE_COLUMN } from '../../reducers/schemaModel/models/userSchema';
import { RELATED_MODEL_FIELD_DELIMITER } from '../../constants/magics';
import { LogoutCommonErrorLabelTrans } from '../../utils/commonTransComponents';
import { Trans } from '@lingui/macro';
import _get from 'lodash/get';


export const authenticateUser = () =>
  dispatch =>
    dispatch(fetchDataFromServerActionPoint(SERVER_ACTION_POINT.LOGIN))
      .then(({ data: userData }) => userData);


export const login = loginData =>
  dispatch => {
    const {
      userIdentity,
      password,
    } = loginData;

    const requestBody = {
      data: {
        login: userIdentity,
        password,
      },
    };

    return dispatch(sendActionToServer(SERVER_ACTION_POINT.LOGIN, requestBody, { showServerError: false }))
      .then(({ data: userData }) => userData)
      .catch(
        ({ response, status }) => {
          if(!response) return Promise.reject(status);

          const errorMsg = getErrorMessage(response, LOGIN_ERRORS);
          showError(errorMsg);
          return Promise.reject({ response, status });
        },
      );
  };


export const logout = () =>
  dispatch =>
    dispatch(fetchDataFromServerActionPoint(SERVER_ACTION_POINT.LOGOUT))
      .then(() => dispatch(handleLogout()))
      .catch(response => {
        /*
        * Ошибка при запросе на логаут, по идее, не должна происходить, но, в общем случае, мы от этого не
        * застрахованы, поэтому обрабатываем - просто выводим общую ошибку, что выйти из системы не удалось.
        * Может показаться, что в этом случае мы могли бы сами очистить куки и тем самым "выйти из системы", но
        * при кроссдоменной работе мы не может управлять куками в браузере для другого домена, поэтому здесь ничего
        * больше сделать пока не можем.
        * */
        showError(LogoutCommonErrorLabelTrans);
        return Promise.reject(response);
      });

const USER_REQUEST_ALL_WITH_PARAMS = [
  ROLE_MODEL,
  USER_ROLE_MODEL,
];

const USER_REQUEST_ALL_MODEL_RELATIONS = {
  [USER_ROLE_MODEL]: {
    level: 1,
  },
  [ROLE_MODEL]: {
    level: 2,
    relates: USER_ROLE_MODEL,
  },
};



const _getUserFullNameCustomSortByData = asc => ([
  {
    column: 'lastName',
    params: [{ key: 'asc', value: asc }],
  },
  {
    column: 'name',
    params: [{ key: 'asc', value: asc }],
  },
  {
    column: 'patronymicName',
    params: [{ key: 'asc', value: asc }],
  },
]);

/*
* Если задана сортировка по комбинированной колонке ФИО - USER_FULL_NAME_TABLE_COLUMN, то преобразуем её в кастомную
* множественную сортировку поочередно сначала по фамилии, потом, по имени, потом, по отчеству. Для остальных колонок
* осуществляем стандартное преобразование данных о табличной сортировке к АПИ экшена запроса.
*
* В случае преобразования USER_FULL_NAME_TABLE_COLUMN  в transformedSortParams появится вложенный массив:
* [ [{фильтрПоФамилии}, {ФильтрПоИмени}, {ФильтрПоОтчестку}], ещёКакойТоФильтр1, ещёКакойТоФильтр2 ].
* Приводим его к "плоскому" массиву при помощи flatten у lodash. Если массив уже был "плоским", то flatten вернет его
* же.
*
* В данный момент, в sortParams всегда 1 элемент, и если будет сортировка по USER_FULL_NAME_TABLE_COLUMN, которая
* трансформируется в множественную, то других элементов в массиве не будет. Но мы всегда обрабатываем sortParams у
* таблиц, как массив, в котором может быть несколько элементов т.к. предполагаем, что теоретически у нас может появиться
* множественная сортировка у таблиц и её сможет задавать пользователь. Поэтому тут обработка всех элементов массива +
* flatten
* */
const _prepareUsersRemoteTableDataSortParams = tableSortParams => {

  const transformedSortParams = tableSortParams
    .map(({ column, asc }) => {

      if(column === USER_FULL_NAME_TABLE_COLUMN) {
        return _getUserFullNameCustomSortByData(asc);
      }

      return {
        column,
        params: [{ key: 'asc', value: asc }],
      };
    });

  return _flatten(transformedSortParams);
};


/*
* Если задана фильтрация по комбинированной колонке ФИО - USER_FULL_NAME_TABLE_COLUMN, то преобразуем её в
* фильтрацию по полю id, т.к. для этой колонки в интерфейсе кастомный фильтр и храним мы в значении фильтра именно
* идентификаторы выбранных пользователей с уже нужным типом фильтра ONE_OF, т.е. преобразуем только наименование колонки
*
* Если задана фильтрация по колонке списка ролей - USER_ROLES_TABLE_COLUMN, то преобразуем её в фильтрацию
* по полю связанной модели колонке userRole__roleId, т.к. для этой колонки в интерфейсе кастомный фильтр и храним мы
* в значении фильтра идентификатор выбранной роли с уже нужным типом фильтра EQUALS, т.е. преобразуем только
* наименование колонки
*
* Для остальных колонок осуществляем стандартное преобразование данных о табличной фильтрации к АПИ экшена запроса.
* После преобразований, добавлем к итоговым параметрам фильтрации предустановленные фильтры, если они заданы
 */

const USER_TABLES_CUSTOM_FILTER_COLUMN_TRANSFORM_MAP = {
  [USER_FULL_NAME_TABLE_COLUMN]: 'id',
  [USER_ROLES_TABLE_COLUMN]: [USER_ROLE_MODEL, 'roleId'].join(RELATED_MODEL_FIELD_DELIMITER),
};
const _prepareUsersRemoteTableDataFilterParams = (tableFilterParams, predefinedFilters) => {

  const filtersArray = Object
    .keys(tableFilterParams)
    .map(column => {
      const {
        filterValue,
        filterType,
      } = tableFilterParams[column];

      return {
        column: USER_TABLES_CUSTOM_FILTER_COLUMN_TRANSFORM_MAP[column] || column,
        filterType,
        filterValue,
      };
    });

  return {
    filterGroupType: FILTER_GROUP_TYPES.AND,
    filters: predefinedFilters ? filtersArray.concat(predefinedFilters) : filtersArray,
  };
};

/*
* Особенности запроса:
*
* Т.к. запрашиваем данные для серверной таблицы, то не можем в общем случае включать в основной запрос модель связи
* пользователей с ролями - USER_ROLE, т.к. у одного пользователя может быть несколько ролей и сущностей модели
* связей может стать больше, чем сущностей самих пользователей, а в этом случае параметр limit начинает
* действовать на модель с бОльшим количеством сущностей и это приводит к табличному коллапсу на клиенте.
* Поэтому получение постраничных данных осуществляем в 2 запроса: сначала получаем данные по самим пользователям с
* нужной фильтрацией и сортировкой по этой модели, затем, получив идентификаторы пользователей на странице, запрашиваем
* отдельно модель связей этих пользователей с ролями и сами роли.
*
* Единственный случай, когда мы можем в первый запрос включить связанную модель USER_ROLE, это если обеспечим, что
* для каждого из запрашиваемых пользователей запросится только одна его роль. ИМЕННО ПОЭТОМУ по колонке списка ролей
* реализован фильтр с выбором одной роли из селекта, множественный фильтр тут работать не будет по причинам, описанным
* выше. В случае фильтрации по какой-то одной конкретной роли, за счет указания поля связанной модели userRole__roleId,
* в ответ включится модель USER_ROLE, но количество записей модели в случае такой фильтрации будет равно количеству
* записей модели USER, поэтому это будет работать корректно и поэтому такая фильтрация для этой колонки и реализована.
* При этом, основные данные по модели USER_ROLE, всё равно будут получены при втором запросе, данные полученные при
* первом запросе по модели USER_ROLE учитываться не будут. Таким образом, в _prepareUsersRemoteTableDataFilterParams
* можно увидеть описанное преобразование фильтрации, если задан фильтр по Роли.
*
* Также, в запросе есть дополнительная обработка при сортировке и фильтрации по комбинированной колонке
* USER_FULL_NAME_TABLE_COLUMN, т.к. её не существует на сервере и нужно преобразование к логике сервера. Подробнее о
* том, что делается, в комментариях к _prepareUsersRemoteTableDataSortParams и _prepareUsersRemoteTableDataFilterParams.
* */
export const fetchUsersRemoteTableData = (tableParams, predefinedFilters) =>
  dispatch => {
    const {
      activePage,
      pageSize,
      sortParams = [],
      filterParams = {},
    } = tableParams;

    const usersRequestQuery = {
      filter: _prepareUsersRemoteTableDataFilterParams(filterParams, predefinedFilters),
      sortBy: _prepareUsersRemoteTableDataSortParams(sortParams),
      limit: pageSize,
      page: activePage,
    };

    return dispatch(fetchRemoteTableEntities(
      USER_MODEL,
      usersRequestQuery,
      {
        modelRelations: USER_REQUEST_ALL_MODEL_RELATIONS,
      },
    ))
      .then(usersResponse => {

        if(usersResponse.totalItemsAmount === 0) {
          return usersResponse;
        }

        const {
          itemsIds: usersResponseItemsIds,
          itemsById: usersResponseItemsById,
          totalItemsAmount: usersResponseTotalItemsAmount,
        } = usersResponse;

        const userRolesRequestQuery = {
          filter: {
            filterGroupType: FILTER_GROUP_TYPES.AND,
            filters: [
              {
                column: 'userId',
                filterType: FILTER_TYPES.ONE_OF,
                filterValue: usersResponseItemsIds[USER_MODEL],
              },
            ],
          },
          with: [ROLE_MODEL],
        };

        return dispatch(fetchRemoteTableEntities(USER_ROLE_MODEL, userRolesRequestQuery))
          .then(({
            itemsIds: userRolesResponseItemsIds,
            itemsById: userRolesResponseItemsById,
          }) => ({
            itemsIds: {
              ...usersResponseItemsIds,
              ...userRolesResponseItemsIds,
            },
            itemsById: {
              ...usersResponseItemsById,
              ...userRolesResponseItemsById,
            },
            totalItemsAmount: usersResponseTotalItemsAmount,
          }));
      });
  };

export const fetchActiveUsersRemoteTableData = _partialRight(
  fetchUsersRemoteTableData,
  USERS_AUTOCOMPLETE_PREDEFINED_FILTERS[USERS_AUTOCOMPLETE_STATUSES.ACTIVE],
);

export const fetchDeactivatedUsersRemoteTableData = _partialRight(
  fetchUsersRemoteTableData,
  USERS_AUTOCOMPLETE_PREDEFINED_FILTERS[USERS_AUTOCOMPLETE_STATUSES.DEACTIVATED],
);

export const reFetchActiveUsersRemoteTableData = () =>
  dispatch => dispatch(reFetchRemoteTableData(
    ADMIN_APP_ACTIVE_USERS_TABLE_ID,
    USER_MODEL,
    ({ tableParams }) => dispatch(fetchActiveUsersRemoteTableData(tableParams)),
  ));

export const reFetchDeactivatedUsersRemoteTableData = () =>
  dispatch => dispatch(reFetchRemoteTableData(
    ADMIN_APP_DEACTIVATED_USERS_TABLE_ID,
    USER_MODEL,
    ({ tableParams }) => dispatch(fetchDeactivatedUsersRemoteTableData(tableParams)),
  ));


export const fetchUserById = userId =>
  dispatch =>
    dispatch(fetchEntitiesFromServer(
      USER_MODEL,
      {
        filter: {
          filterGroupType: FILTER_GROUP_TYPES.AND,
          filters: [
            {
              column: 'id',
              filterType: FILTER_TYPES.EQUALS,
              filterValue: userId,
            },
          ],
        },
        with: USER_REQUEST_ALL_WITH_PARAMS,
      },
      {
        modelRelations: USER_REQUEST_ALL_MODEL_RELATIONS,
      },
    ));

const RESPONSE_ERROR_FIELDS_TRANSFORM_MAP = {
  roleIds: 'userRoles',
};

const getUserEditingErrorsHandler = (dispatch, errorsMap) =>
  ({ response }) => {
    const {
      source,
      identity,
    } = response;

    if(source === SERVER_ERROR_SOURCE.WEBSERVER && identity === SERVER_ERROR_IDENTITY.REQUEST_JSON_STRUCTURE)
      return Promise.resolve({
        validationErrors: transformRequestBodyFieldsServerErrors(response, RESPONSE_ERROR_FIELDS_TRANSFORM_MAP),
      });

    if(source === SERVER_ERROR_SOURCE.IO && identity === SERVER_ERROR_IDENTITY.USER_IDENTITY_ALREADY_EXISTS) {
      return Promise.resolve({
        validationErrors: {
          identity: [
            {
              message: SERVER_ERROR_IDENTITY.USER_IDENTITY_ALREADY_EXISTS,
              data: null,
            },
          ],
        },
      });
    }

    const errorMsg = getErrorMessage(response, errorsMap);
    showError(errorMsg);
    return Promise.reject(response);
  };


const USER_ACTIONS_DEFAULT_REQUEST_OPTIONS = {
  isBlockingRequest: true,
  showServerError: false,
};

export const createUser = (userData, requestOptions = USER_ACTIONS_DEFAULT_REQUEST_OPTIONS) =>
  dispatch =>
    dispatch(sendActionToServer(
      SERVER_ACTION_POINT.CREATE_USER,
      {
        data: userData,
      },
      { ...requestOptions },
    )).then(
      response => {
        const { data } = response;

        sendNotification(
          <Trans id="user_creating@user_created">
            Новый пользователь успешно создан
          </Trans>,
          NOTIFICATION_LEVEL.SUCCESS,
        );

        return { userId: data };
      },
      getUserEditingErrorsHandler(dispatch, CREATE_USER_ERRORS),
    );

export const editUser = (userData, requestOptions = USER_ACTIONS_DEFAULT_REQUEST_OPTIONS) =>
  dispatch =>
    dispatch(sendActionToServer(
      SERVER_ACTION_POINT.EDIT_USER,
      {
        data: userData,
      },
      { ...requestOptions },
    )).then(
      () => {
        sendNotification(
          <Trans id="user_editing@user_edited">
            Данные пользователя успешно отредактированы
          </Trans>,
          NOTIFICATION_LEVEL.SUCCESS,
        );

        return { userId: userData.id };
      },
      getUserEditingErrorsHandler(dispatch, EDIT_USER_ERRORS),
    );

export const deactivateUser = (userId, requestOptions = USER_ACTIONS_DEFAULT_REQUEST_OPTIONS) =>
  dispatch =>
    dispatch(sendActionToServer(
      SERVER_ACTION_POINT.DEACTIVATE_USER,
      {
        data: { id: userId },
      },
      { ...requestOptions },
    )).then(
      () => {
        sendNotification(
          <Trans id="user_deactivating@user_deactivated">
            Статус пользователя успешно изменен на "Неактивный"
          </Trans>,
          NOTIFICATION_LEVEL.SUCCESS,
        );

        return { userId };
      },
      ({ response }) => {
        const errorMsg = getErrorMessage(response, DEACTIVATE_USER_ERRORS);
        showError(errorMsg);
        return Promise.reject(response);
      },
    );

export const activateUser = ({ id, note, patronymicName }, requestOptions = USER_ACTIONS_DEFAULT_REQUEST_OPTIONS) =>
  dispatch => {
    return dispatch(
      saveEntitiesOnServer(
        USER_MODEL,
        /*
        При редактировании пользователя put запросом поля note, patronymicName сбрасываются на null, если их не
        передать
        */
        [{
          disabled: false,
          id,
          note,
          patronymicName,
        }],
        false,
        requestOptions,
      ),
    )
      .then(() => {
        sendNotification(
          <Trans id="user_activating@user_activated">
            Статус пользователя успешно изменен на "Активный"
          </Trans>,
          NOTIFICATION_LEVEL.SUCCESS,
        );

        return { userId: id };
      })
      .catch(({ response }) => {
        const userModelError = _get(response, [USER_MODEL, 0, '_error']);

        const errorMessage = getErrorMessage(userModelError, ACTIVATE_USER_ERRORS);

        showError(errorMessage);
      });
  };

export const fetchUserRoles = () =>
  (dispatch, getState) => {
    const roleEntities = roleEntitiesSelector(getState());

    if(!_isEmpty(roleEntities)) return Promise.resolve();

    return dispatch(fetchEntitiesFromServerAndAddToStore(ROLE_MODEL));
  };
