import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { FUNC_IS_REQUIRED_TYPE, OBJECT_OF_ANY_TYPE } from '../../../constants/propTypes';
import './style.css';
import _constant from 'lodash/constant';
import _isFunction from 'lodash/isFunction';
import {
  CancelLabelTrans, DeleteLabelTrans,
  SaveLabelTrans,
} from '../../../utils/commonTransComponents';
import Button from '@mui/material/Button';
import { MATERIAL_UI_STYLE_COLOR, MATERIAL_UI_VARIANT } from '../../../constants/materialUI';
import { Check, Close, Delete } from '@mui/icons-material';

export const SimpleTableWithDraggableRows = props => {
  const {
    draggableItemsData,
    onDragEnd,
    getItemId,
    itemOrderKey,
    isDragDisabled,
    getDraggableRowClassNames,
    getDraggableContainerClassNames,
    rowItemsSchema,
    tableTitle,

    onSave,
    onCancel,
    onDelete,

    isSaveBtnDisabled,
    isCancelBtnDisabled,
    isDeleteBtnDisabled,
  } = props;


  const dragEndHandler = useCallback(dragEndData => {
    const {
      source: dragSourceData,
      destination: dragDestinationData,
    } = dragEndData;

    /* если дропнули за пределами контейнера или на тот же индекс, то ничего не делаем */
    if (!dragDestinationData)  return;

    const { index: dragSourceIndex } = dragSourceData;
    const { index: dragDestinationIndex } = dragDestinationData;

    if(dragSourceIndex === dragDestinationIndex) return;

    const draggableItemsClone = draggableItemsData.slice();

    /* получаем данные строки, которую дропнули */
    const [draggedItem] = draggableItemsClone.splice(dragSourceIndex, 1);

    /* вставляем данные строки, которую дропнули в клон массива исходных данных по новому индексу */
    draggableItemsClone.splice(dragDestinationIndex, 0, draggedItem);


    /* Обновляем значение поля, которое отвечает за последовательность строк */
    const draggableItemsCloneWithUpdatedOrder = draggableItemsClone
      .reduce(
        (reorderedItemsData, itemData, newItemOrder) => {
          const itemId = getItemId(itemData);

          // eslint-disable-next-line no-param-reassign
          reorderedItemsData[itemId] = {
            ...itemData,
            [itemOrderKey]: newItemOrder,
          };

          return reorderedItemsData;
        },
        {},
      );


    onDragEnd(draggableItemsCloneWithUpdatedOrder);
  }, [onDragEnd, draggableItemsData, getItemId, itemOrderKey]);



  const headerMarkup = useMemo(() => (
    <div className="simple-table-with-draggable-rows__header">
      {renderDragIcon()}

      {
        /* Описание rowItemsSchema в propTypes */
        rowItemsSchema
          .map((schemaData, index) => {
            const {
              headerTitle,
              id,
            } = schemaData;

            const columnClassName = cn(
              'simple-table-with-draggable-rows__column simple-table-with-draggable-rows__header-title',
              /* добавляем к классу index, чтобы стилизовать столбцы по разному */
              `simple-table-with-draggable-rows__column-${index}`,
            );

            return (
              <div key={id} className={columnClassName}>
                {headerTitle}
              </div>
            );

          }, [])
      }
    </div>
  ), [rowItemsSchema]);

  const controlPanelMarkup = useMemo(() => (
    <div className="simple-table-with-draggable-rows__control-panel">
      <div className="simple-table-with-draggable-rows__control-panel-title">
        {tableTitle}
      </div>
      <div className="simple-table-with-draggable-rows__buttons">
        {
          _isFunction(onSave) ? (
            <Button
                color={MATERIAL_UI_STYLE_COLOR.PRIMARY}
                variant={MATERIAL_UI_VARIANT.CONTAINED}
                startIcon={<Check/>}
                type="submit"
                disabled={isSaveBtnDisabled}
                onClick={onSave}
            >
              {SaveLabelTrans}
            </Button>
          ) : null
        }
        {
          _isFunction(onCancel) ? (
            <Button
                color={MATERIAL_UI_STYLE_COLOR.INHERIT}
                variant={MATERIAL_UI_VARIANT.OUTLINED}
                startIcon={<Close/>}
                onClick={onCancel}
                disabled={isCancelBtnDisabled}
            >
              {CancelLabelTrans}
            </Button>
          ) : null
        }
        {
          _isFunction(onDelete) ? (
            <Button
                color={MATERIAL_UI_STYLE_COLOR.SECONDARY}
                variant={MATERIAL_UI_VARIANT.OUTLINED}
                onClick={onDelete}
                startIcon={<Delete/>}
                disabled={isDeleteBtnDisabled}
            >
              {DeleteLabelTrans}
            </Button>
          ) : null
        }
      </div>
    </div>
  ), [
    onSave,
    onCancel,
    onDelete,
    isSaveBtnDisabled,
    isCancelBtnDisabled,
    isDeleteBtnDisabled,
    tableTitle,
  ]);

  return (
    <div className="simple-table-with-draggable-rows">

      {controlPanelMarkup}

      <DragDropContext onDragEnd={dragEndHandler}>
        <Droppable
            droppableId="simple-table-with-draggable-rows-id"
            direction="vertical"
        >
          {
            ({ innerRef, droppableProps, placeholder }, snapshot) => {

              const { isDraggingOver } = snapshot;

              return (
                <div
                    ref={innerRef}
                    className={cn('simple-table-with-draggable-rows__draggable-container', {
                      'simple-table-with-draggable-rows__draggable-container--is-dragging-over': isDraggingOver,
                      ...getDraggableContainerClassNames(snapshot),
                    })}
                    {...droppableProps}
                >
                  <div className="simple-table-with-draggable-rows__rows">

                    {headerMarkup}

                    {
                      draggableItemsData
                        .map((itemData, index) => {

                          const id = getItemId(itemData);

                          return (
                            <Draggable
                                key={id}
                                draggableId={id}
                                index={index}
                                isDragDisabled={isDragDisabled}
                            >
                              {
                                ({ draggableProps, dragHandleProps, innerRef }, snapshot) => {

                                  const { isDragging } = snapshot;

                                  const className = cn('simple-table-with-draggable-rows__draggable-row', {
                                    'simple-table-with-draggable-rows__draggable-row--is-dragging': isDragging,
                                    ...getDraggableRowClassNames(itemData, snapshot),
                                  });

                                  return (
                                    <div
                                        ref={innerRef}
                                        {...draggableProps}
                                        {...dragHandleProps}
                                        className={className}
                                        style={{ ...draggableProps.style }}
                                    >
                                      <div className="simple-table-with-draggable-rows__row-content">

                                        {renderDragIcon()}

                                        {
                                          /* Описание rowItemsSchema в propTypes */
                                          rowItemsSchema
                                            .map((schemaData, index) => {
                                              const {
                                                renderRowItem,
                                                dataKey,
                                                id,
                                              } = schemaData;

                                              const rowItemMarkup = _isFunction(renderRowItem) ?
                                                renderRowItem(itemData) : itemData[dataKey];

                                              const columnClassName = cn(
                                                'simple-table-with-draggable-rows__column',
                                                /* добавляем к классу index, чтобы стилизовать столбцы по разному */
                                                `simple-table-with-draggable-rows__column-${index}`,
                                              );

                                              return (
                                                <div key={id} className={columnClassName}>
                                                  {rowItemMarkup}
                                                </div>
                                              );

                                            }, [])
                                         }
                                      </div>
                                    </div>
                                  );
                                }
                              }
                            </Draggable>
                          );
                        })
                    }
                  </div>
                  {placeholder}
                </div>
              );
            }
          }
        </Droppable>
      </DragDropContext>
    </div>
  );
};

const renderDragIcon = () => (
  <div className="simple-table-with-draggable-rows__column simple-table-with-draggable-rows__column--icon">
    <div className="simple-table-with-draggable-rows__column-icon">
      <div className="simple-table-with-draggable-rows__column-icon-line" />
      <div className="simple-table-with-draggable-rows__column-icon-line" />
    </div>
  </div>
);

SimpleTableWithDraggableRows.propTypes = {
  draggableItemsData: PropTypes.arrayOf(OBJECT_OF_ANY_TYPE),
  onDragEnd: FUNC_IS_REQUIRED_TYPE,
  getItemId: PropTypes.func,
  itemOrderKey: PropTypes.string,
  isDragDisabled: PropTypes.bool,
  getDraggableRowClassNames: PropTypes.func,
  getDraggableContainerClassNames: PropTypes.func,
  /*
  rowItemsSchema - отвечает за внешний вид и рендеринг "столбцов таблицы".
  Можно задать только dataKey, который будет врать данные из draggableItemsData и отображать текст. Или использовать
  renderRowItem, который получает все данные элемента массива draggableItemsData.
  */
  rowItemsSchema: PropTypes.arrayOf(
    PropTypes.shape({
      headerTitle: PropTypes.node,
      renderRowItem: PropTypes.func,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      /* ключ элемента массива draggableItemsData */
      dataKey: PropTypes.string,
    }).isRequired,
  ).isRequired,
  tableTitle: PropTypes.node,

  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,

  isSaveBtnDisabled: PropTypes.bool,
  isCancelBtnDisabled: PropTypes.bool,
  isDeleteBtnDisabled: PropTypes.bool,
};

const emptyObjectCb = _constant({});

SimpleTableWithDraggableRows.defaultProps = {
  getItemId: ({ id }) => id,
  itemOrderKey: 'order',
  isDragDisabled: false,
  getDraggableRowClassNames: emptyObjectCb,
  getDraggableContainerClassNames: emptyObjectCb,

  isSaveBtnDisabled: false,
  isCancelBtnDisabled: false,
  isDeleteBtnDisabled: false,
};