import _pick from 'lodash/pick';
import {
  createTasksTableGetFilterChipContent,
  TASKS_FILTER_SCHEMA_FILTER_KEYS,
  TASKS_TABLE_FILTER_SCHEMA,
} from '../../Table/tableComponents/TasksTableFilters/TasksTableFilters';
import _keyBy from 'lodash/keyBy';
import { FILTER_COMPONENTS_MAP } from '../../common/Filters/FilterComponents/index';
import {
  EquipmentClassesAutocomplete,
} from '../../entitiesAutocomplete/EquipmentClassesAutocomplete/EquipmentClassesAutocomplete';
import { getResourceCombinedName } from '@bfg-frontend/utils/lib/stringBuilders/entity';
import _size from 'lodash/size';
import { MASTER_TASKS_COLUMNS } from '../../../reducers/schemaModel/models/masterTasksSchema';

export const MASTER_TASKS_TO_DO_TABLE_ID = 'MASTER_TASKS_TO_DO_TABLE_ID';

const MASTER_TASKS_FILTER_EQUIPMENT_CLASS_AUTOCOMPLETE_ID = 'MASTER_TASKS_FILTER_EQUIPMENT_CLASS_AUTOCOMPLETE_ID';

const {
  ORDER_NAME,
  SHEET_IDENTITY,
  EQUIPMENT_CLASS_NAME,
  ENTITY_NAME,
  OPERATION_NAME,
  ASSIGNEES,
  EQUIPMENT,
} = MASTER_TASKS_COLUMNS;


const TASKS_TABLE_FILTER_SCHEMA_BY_FILTER_KEYS = _keyBy(TASKS_TABLE_FILTER_SCHEMA, 'filterKey');

const KEYS_FROM_TASKS_TABLE_FILTER_SCHEMA_TO_PICK = [
  'filterComponent',
  'filterComponentProps',
  'getFilterChipContent',
];

/*
* Решено брать данные из TASKS_TABLE_FILTER_SCHEMA, включая идентификаторы фильтров автокомплитов, потому что,
* кейс, когда пользуются эти двумя таблица одновременно маловероятен. И, даже, если используется, общие
* загруженные опции, в целом, не должны помешать, т.к. в любой момент можно начать новый поиск в фильтре и
* подгрузить остальные опции
* */
export const getMasterTasksTodoCustomFilters = departmentIds => ({
  [ORDER_NAME]: _pick(
    TASKS_TABLE_FILTER_SCHEMA_BY_FILTER_KEYS[TASKS_FILTER_SCHEMA_FILTER_KEYS.ORDER],
    KEYS_FROM_TASKS_TABLE_FILTER_SCHEMA_TO_PICK,
  ),

  [SHEET_IDENTITY]: _pick(
    TASKS_TABLE_FILTER_SCHEMA_BY_FILTER_KEYS[TASKS_FILTER_SCHEMA_FILTER_KEYS.SHEET],
    KEYS_FROM_TASKS_TABLE_FILTER_SCHEMA_TO_PICK,
  ),

  [EQUIPMENT_CLASS_NAME]: {
    filterComponent: FILTER_COMPONENTS_MAP.AUTOCOMPLETE,
    filterComponentProps: {
      isMulti: true,
      isClearable: true,
      shouldPreloadData: true,
      autocompleteId: MASTER_TASKS_FILTER_EQUIPMENT_CLASS_AUTOCOMPLETE_ID,
      autocompleteComponent: EquipmentClassesAutocomplete,
      additionalAutocompleteProps: {
        shouldLoadOptions: true,
        departmentIds: _size(departmentIds) > 0 ?
          departmentIds :
          undefined,
      },
    },
    getFilterChipContent: createTasksTableGetFilterChipContent(getResourceCombinedName),
  },

  [ENTITY_NAME]: _pick(
    TASKS_TABLE_FILTER_SCHEMA_BY_FILTER_KEYS[TASKS_FILTER_SCHEMA_FILTER_KEYS.ENTITY],
    KEYS_FROM_TASKS_TABLE_FILTER_SCHEMA_TO_PICK,
  ),

  [OPERATION_NAME]: _pick(
    TASKS_TABLE_FILTER_SCHEMA_BY_FILTER_KEYS[TASKS_FILTER_SCHEMA_FILTER_KEYS.OPERATION],
    KEYS_FROM_TASKS_TABLE_FILTER_SCHEMA_TO_PICK,
  ),

  [ASSIGNEES]: _pick(
    TASKS_TABLE_FILTER_SCHEMA_BY_FILTER_KEYS[TASKS_FILTER_SCHEMA_FILTER_KEYS.USER],
    KEYS_FROM_TASKS_TABLE_FILTER_SCHEMA_TO_PICK,
  ),

  [EQUIPMENT]: _pick(
    TASKS_TABLE_FILTER_SCHEMA_BY_FILTER_KEYS[TASKS_FILTER_SCHEMA_FILTER_KEYS.EQUIPMENT],
    KEYS_FROM_TASKS_TABLE_FILTER_SCHEMA_TO_PICK,
  ),
});