import React from 'react';
import { ChangesSavedSuccessfullyLabelTrans } from '../utils/commonTransComponents';

import { FUNC_IS_REQUIRED_TYPE } from './propTypes';
import PropTypes from 'prop-types';
import {
  clearNotifications as _clearNotifications,
  NOTIFICATION_LEVEL as _NOTIFICATION_LEVEL,
  sendNotification as _sendNotification,
} from '@bfg-frontend/notification-system';
import { Transition } from 'react-transition-group';


export const NOTIFICATION_LEVEL = _NOTIFICATION_LEVEL;

export const DEPARTMENT_CHOOSE_SCREEN_NOTIFICATION_ID = 'DEPARTMENT_CHOOSE_SCREEN_NOTIFICATION_ID';
export const EQUIPMENT_CLASS_CHOOSE_SCREEN_NOTIFICATION_ID = 'EQUIPMENT_CLASS_CHOOSE_SCREEN_NOTIFICATION_ID';

const DEFAULT_NOTIFICATION_CLOSE_TIMEOUT_IN_MS = 5000;
const DEFAULT_NOTIFICATION_POSITION = 'bottom-right';
const TOP_CENTER_NOTIFICATION_POSITION = 'top-center';
const TOP_CENTER_NOTIFICATION_POSITION_TOAST_CLASSNAME = 'top-center-notification';


export const sendNotification = (message, level, options) =>
  _sendNotification(
    message,
    level,
    {
      timeOut: DEFAULT_NOTIFICATION_CLOSE_TIMEOUT_IN_MS,
      position: DEFAULT_NOTIFICATION_POSITION,
      theme: 'colored',
      ...options,
    },
  );

export const sendTopCenterNotification = (message, level, options) => {
  const updatedOptions = {
    ...options,
    className: TOP_CENTER_NOTIFICATION_POSITION_TOAST_CLASSNAME,
    position: TOP_CENTER_NOTIFICATION_POSITION,
  };

  return sendNotification(message, level, updatedOptions);
};

export const clearNotifications = _clearNotifications;


export const sendNonClosingNotification = (message, level, options) => {
  _sendNotification(
    message,
    level,
    {
      closeButton: false,
      timeOut: 0,
      closeOnClick: false,
      ...options,
    },
  );
};

export const sendChangesSavedSuccessfullyNotification = () =>
  sendNotification(
    ChangesSavedSuccessfullyLabelTrans,
    NOTIFICATION_LEVEL.SUCCESS,
  );


export const PreventToastTransition = props => {
  const {
    done,
    isIn: inProp,
    children,
  } = props;

  return (
    <Transition
        timeout={0}
        in={inProp}
        unmountOnExit
        onExited={done}
    >
      {children}
    </Transition>
  );
};

PreventToastTransition.propTypes = {
  done: FUNC_IS_REQUIRED_TYPE,
  isIn: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};