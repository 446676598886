import { closeAllSocketConnections } from '../../api/socketApi/index';
import { reInitReduxState } from '../../reducers/actions';


/*
* Общая обработка события логаута пользователя. Событие может происходить, когда пользователь вышел из системы
* самостоятельно или когда это произошло по какой-то другой причине (например, истекли куки).
* Т.к. пользователь более не авторизован, то нужно закрыть все сокеты, т.к. доступа к этой информации у него больше нет.
* Кроме того, нужно сбросить все текущие данные приложения, т.к. к ним у него тоже нет доступа, а также, т.к. от сокетов
* мы его отключили, то эти данные могут стать неактульными, т.к. не будет никаких обновлений. Данные локальных state у
* у компонентов тоже очистятся, т.к. все компоненты в этом случае размонтируются.
* При повторной авторизации, т.к. все данные были очищены, они будут запрашиваться по новой, что и нужно.
* Т.к. сбрасываются все данные приложения, то сбросятся и все данные текущего пользователя, что и приведет к редиректу
* на форму авторизации.
* В экшене очистки указывается, что не нужно очищать данные по ключю router, т.к. отсутствие пользователя не влияет на
* данные роутера в state. В принципе, ключ можно было бы не указывать, т.к. есть определенная логика с редиректом
* после каждой новой авторизации и данные после авторизации будут в state корректные. Но логичнее, всё же, не
* очищать данные по этому ключу, т.к. они сбрасываются на некоторое начальное состояние и начинают не соответствовать
* реальным данным по роутингу в момент отрисовки формы авторизации. Такая рассинхронизация, в общем случае, нежелательна,
* ну и, как опичывалось выше, в целом, действительно, логично не очищать этот ключ.
* Также сюда будут добавляться и другие ключи, которые нужно сохранить при логауте
*
* СПЕЦИАЛЬНО ВЫНЕСЕН В ОТДЕЛЬНЫЙ ФАЙЛ! Экшен криетор используется в нескольких местах по проекту, и, в том числе,
* в абстрактных обработчиках ошибок запросов (errorHandlerCa и т.д.), которые являются зависимостями для всех
* функций\экшен криетеров запросов в приложении. Поэтому, зависимости, которые используют абстрактные обработчики
* ошибок не должны находиться в файлах, в которых есть зависимости на любые функции\экшены запросов, иначе появляются
* циклические зависимости. Т.е. в operation/users/index расположить этот экшен криетор нельзя, там есть зависимости
* на функции запросов данных, поэтому он вынесен а файл рядом
* */
export const handleLogout = () =>
  dispatch => {
    closeAllSocketConnections();
    dispatch(reInitReduxState({
      stateKeysToPreservePaths: [
        'router',
        ['workerApp', 'tasksSearchHistory'],
      ],
    }));
  };

