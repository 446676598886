import {
  SAVE_SHEET_TO_START_OPERATIONS_DATA,
  SAVE_SHEETS_TO_START_DATA,
  REMOVE_SHEET_TO_START,
  SET_SELECTED_SHEETS_TO_START_TYPE,
  SAVE_ASSEMBLY_SHEETS_TO_START_DATA,
} from './actions';

import _omit from 'lodash/omit';

import { SHEET_TYPE } from '../../../constants/sheets';

/*
* Эта область store хранит партии, которые необходимо запустить в производтво.
*
*  - selectedType. Выбранный тип для которого отображается списки МЛ на запуск. Принято решение хранить эти данные в
* стор, а не в локальном стейт т.к. не хочется, чтобы приходилось постоянно выбирать тип при навигации между интерфейсами.
*  - sheetsData - Данные для списка всех МЛ на запуск с ограничением по периоду запроса. Помимо самих данных хранятся
* периоды и id основной сессии планирования, т.к. это входные параметры запроса и по ним можно проверять необходимость
* перезапроса данных
*  - assemblySheetsData - Данные для списка МЛ с воркфлоу "Сборочные". Они запрашиваются и хранятся все без
* ограничения по периоду, т.к. предполагается, что их в системе бывает немного.
*  - sheetOperationsData - Данные по операциям маршрутного листа на запуск. Пока решено не хранить данных больше, чем
* для одного МЛ (для партии), хранилище перезаписывается при просмотре разных МЛ на запуск.
* */

const SHEETS_TO_START_INITIAL_STATE = {
  selectedType: SHEET_TYPE.ALL_TO_START,
  sheetsData: {
    mainPlanningSessionId: null,
    startDate: null,
    stopDate: null,
    dataEntities: null,
  },
  assemblySheetsData: {
    mainPlanningSessionId: null,
    dataEntities: null,
  },
  sheetOperationsData: {
    mainPlanningSessionId: null,
    entityBatchFromIaId: null,
    dataEntities: null,
  },
};

export const sheetsToStart = (state = { ...SHEETS_TO_START_INITIAL_STATE }, action) => {
  switch(action.type) {
  case SET_SELECTED_SHEETS_TO_START_TYPE: {
    return {
      ...state,
      selectedType: action.selectedType,
    };
  }
  case SAVE_SHEETS_TO_START_DATA: {
    const {
      mainPlanningSessionId,
      startDate,
      stopDate,
      dataEntities,
    } = action;
    return {
      ...state,
      sheetsData : {
        mainPlanningSessionId,
        startDate,
        stopDate,
        dataEntities,
      },
    };
  }
  case REMOVE_SHEET_TO_START: {
    const { entityBatchFromIaIds } = action;

    const { dataEntities: allSheetsEntities } = state.sheetsData;
    const { dataEntities: assemblySheetsEntities } = state.assemblySheetsData;

    return {
      ...state,
      sheetsData: {
        ...state.sheetsData,
        dataEntities: _omit(allSheetsEntities, entityBatchFromIaIds),
      },
      assemblySheetsData: {
        ...state.assemblySheetsData,
        dataEntities: _omit(assemblySheetsEntities, entityBatchFromIaIds),
      },
    };
  }
  case SAVE_ASSEMBLY_SHEETS_TO_START_DATA: {
    const {
      mainPlanningSessionId,
      dataEntities,
    } = action;
    return {
      ...state,
      assemblySheetsData : {
        mainPlanningSessionId,
        dataEntities,
      },
    };
  }
  case SAVE_SHEET_TO_START_OPERATIONS_DATA: {
    const {
      mainPlanningSessionId,
      entityBatchFromIaId,
      dataEntities,
    } = action;
    return {
      ...state,
      sheetOperationsData: {
        mainPlanningSessionId,
        entityBatchFromIaId,
        dataEntities,
      },
    };
  }
  default:
    return state;
  }
};