export const TASK_TYPES = {
  OPERATION: 0,
  PREPARING: 1,
  SETUP: 2,
};

export const MASTER_TASKS_TO_DO_TABLE_ADDITIONAL_FILTER_IDENTITY = {
  ALL_TASKS: 'ALL_TASKS',
  TASKS_WITHOUT_ASSIGNEES: 'TASKS_WITHOUT_ASSIGNEES',
  TASKS_WITHOUT_EQUIPMENT: 'TASKS_WITHOUT_EQUIPMENT',
  READY_TO_START_TASKS: 'READY_TO_START_TASKS',
};