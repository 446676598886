import { ENTITY_BATCH_MODEL, ENTITY_MODEL, ORDER_MODEL } from '../../../../constants/models';
import { WITH_PARAMS } from '../../../../api/restCollectionApi';

export const SHEETS_WAITING_PARTS_AND_MATERIALS_REQUEST_WITH_PARAMS = [
  ENTITY_BATCH_MODEL,
  ENTITY_MODEL,
  {
    column: ORDER_MODEL,
    params: [{ key: WITH_PARAMS.STRICT, value: false }],
  },
];