import PropTypes from 'prop-types';
import {
  getEquipmentClassesForDepartmentIdsAutocompleteLoadOptionsActionCreator,
} from '../../../operations/equipmentClasses';
import {
  NoDataLabelTrans,
  NotSelectedLabelTrans,
} from '../../../utils/commonTransComponents';
import { AutocompleteContainer } from '../../common/Autocomplete/AutocompleteContainer';
import React, { useMemo } from 'react';
import { NUMBER_OR_STRING_TYPE } from '../../../constants/propTypes';
import { getResourceCombinedName } from '@bfg-frontend/utils/lib/stringBuilders/entity';


export const EquipmentClassesAutocomplete = props => {

  const {
    departmentIds,
    shouldLoadOptions,
    prepareOptions,
    AutocompleteComponent = AutocompleteContainer,
    placeholder,
    noOptionsMessage,
    getOptionLabel,
  } = props;

  const loadOptionsActionCreator = useMemo(
    () => {
      if (!shouldLoadOptions) {
        return undefined;
      }

      return getEquipmentClassesForDepartmentIdsAutocompleteLoadOptionsActionCreator(departmentIds, prepareOptions);
    },
    [departmentIds, prepareOptions, shouldLoadOptions],
  );

  return (
    <AutocompleteComponent
        {...props}
        getOptionLabel={getOptionLabel}
        loadOptionsActionCreator={loadOptionsActionCreator}
        placeholder={placeholder}
        noOptionsMessage={noOptionsMessage}
    />
  );
};

EquipmentClassesAutocomplete.propTypes = {
  departmentIds: PropTypes.arrayOf(NUMBER_OR_STRING_TYPE),
  shouldLoadOptions: PropTypes.bool,
  prepareOptions: PropTypes.func,
  AutocompleteComponent: PropTypes.elementType,
  placeholder: PropTypes.node,
  noOptionsMessage: PropTypes.node,
  getOptionLabel: PropTypes.func,
};

EquipmentClassesAutocomplete.defaultProps = {
  placeholder: NotSelectedLabelTrans,
  noOptionsMessage: NoDataLabelTrans,
  getOptionLabel: getResourceCombinedName,
};
