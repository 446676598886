import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { SimpleInfoRow } from '../../common/SimpleInfoRow/SimpleInfoRow';

import _partialRight from 'lodash/partialRight';
import _isFunction from 'lodash/isFunction';
import _isNil from 'lodash/isNil';

import cn from 'classnames';

import { OBJECT_OF_ANY_TYPE } from '../../../constants/propTypes';
import { SIMPLE_SUMMARY_ROW_SCHEMA_TYPE } from './SimpleSummary.types';

import './style.css';
import { GridViewInfoRow } from '../../GridViewInfoRow/GridViewInfoRow';
import { GRID_VIEW_INFO_ROW_GRID_PROPS_TYPE } from '../../GridViewInfoRow/GridViewInfoRow.types';


/*
* SimpleSummary состоит из информационных рядов, отображающих отдельные параметры, каждый их которых имеет вид
* <wrapper>
*   <label> </label>
*   {': '}
*   <value> </value>
* </wrapper>
*
* Чтобы различать ряды и назначать им различные стили в случае необходимости, каждому из них назначается идентификатор.
* Этот идентификатор ряда входит в имена классов каждого из преставленных выше элементов (wrapper, label value).
* Чтобы не дублировать много кода, ниже проработаны функции помощники, которые генерируют однотипные имена классов для
* wrapper, label value в зависимости от идентификатора ряда.
* */

const SIMPLE_SUMMARY_CLASS_NAME = 'simple-summary';

const WRAPPER_ELEMENT_POSTFIX = 'wrapper';
const LABEL_ELEMENT_POSTFIX = 'label';
const VALUE_ELEMENT_POSTFIX = 'value';

const getSimpleSummaryElementClassName = elementClassName => [
  SIMPLE_SUMMARY_CLASS_NAME,
  elementClassName,
].join('__');

const getSummaryRowElementClassName = (summaryRowIdentity, elementPostfix) => {
  const elementClassName = [summaryRowIdentity, elementPostfix].join('-');
  return getSimpleSummaryElementClassName(elementClassName);
};

const getSummaryRowWrapperElementClassName = _partialRight(getSummaryRowElementClassName, WRAPPER_ELEMENT_POSTFIX);
const getSummaryRowLabelElementClassName = _partialRight(getSummaryRowElementClassName, LABEL_ELEMENT_POSTFIX);
const getSummaryRowValueElementClassName = _partialRight(getSummaryRowElementClassName, VALUE_ELEMENT_POSTFIX);

/*
* Исходя из идентификатора ряда функция генерирует однотипные свойства имен классов элементов ряда для wrapper, label,
* и value, т.е. "simple-summary__${elementClassName}-${wrapper | label | value}"
* */
const getSummaryRowElementsClassNames = summaryRowIdentity => ({
  wrapperClassName: getSummaryRowWrapperElementClassName(summaryRowIdentity),
  labelClassName: getSummaryRowLabelElementClassName(summaryRowIdentity),
  valueClassName: getSummaryRowValueElementClassName(summaryRowIdentity),
});

export class SimpleSummary extends Component {

  static propTypes = {
    className: PropTypes.string,
    summaryData: OBJECT_OF_ANY_TYPE.isRequired,
    primarySummarySchema: PropTypes.arrayOf(SIMPLE_SUMMARY_ROW_SCHEMA_TYPE),
    secondarySummarySchema: PropTypes.arrayOf(SIMPLE_SUMMARY_ROW_SCHEMA_TYPE),
    isGridView: PropTypes.bool,
    gridViewProps: GRID_VIEW_INFO_ROW_GRID_PROPS_TYPE,
    delimiter: PropTypes.string,
  };

  static defaultProps = {
    isGridView: false,
  };

  _renderPrimaryContent = () => {
    const { primarySummarySchema } = this.props;
    if(!primarySummarySchema) return null;

    return(
      <div className={getSimpleSummaryElementClassName('primary-content-wrapper')}>
        {this._renderSummaryRowsBySchema(primarySummarySchema)}
      </div>
    );
  };

  _renderSecondaryContent = () => {
    const { secondarySummarySchema } = this.props;
    if(!secondarySummarySchema) return null;

    return(
      <div className={getSimpleSummaryElementClassName('secondary-content-wrapper')}>
        {this._renderSummaryRowsBySchema(secondarySummarySchema)}
      </div>
    );
  };

  _renderSummaryRowsBySchema = schema => schema.map(this._renderSummaryRowBySchema);

  _renderSummaryRowBySchema = schema => {
    const { summaryData, delimiter, isGridView, gridViewProps } = this.props;
    const { key, label, rowIdentity, getCustomValue, getCustomLabel, getTooltipContent, tooltipPlacement } = schema;

    const currentSummaryLabel = _isFunction(getCustomLabel) ?
      getCustomLabel(summaryData) :
      label;

    const currentSummaryRowValue = _isFunction(getCustomValue) ?
      getCustomValue(summaryData) :
      summaryData[key];

    if(_isNil(currentSummaryRowValue)) return null;

    const summaryRowElementsClassNames = getSummaryRowElementsClassNames(rowIdentity);

    const infoRowComponentCommonProps = {
      ...summaryRowElementsClassNames,
      key,
      label: currentSummaryLabel,
      value: currentSummaryRowValue,
      delimiter,
      tooltipContent: _isFunction(getTooltipContent) ? getTooltipContent(currentSummaryRowValue) :
        null,
      tooltipPlacement,
    };

    if(isGridView) {
      return <GridViewInfoRow {...infoRowComponentCommonProps} gridProps={gridViewProps} />;
    }

    return(
      <SimpleInfoRow {...infoRowComponentCommonProps} />
    );
  };

  render() {
    return (
      <div
          className={
            cn(
              SIMPLE_SUMMARY_CLASS_NAME,
              this.props.className,
            )
          }
      >
        {this._renderPrimaryContent()}
        {this._renderSecondaryContent()}
      </div>
    );
  }
}
