import { Trans } from '@lingui/macro';
import { MATERIAL_UI_DIALOG_MAX_WIDTH } from '../../../../constants/materialUI';
import { NOTIFICATION_LEVEL, sendNotification } from '../../../../constants/notification';
import React from 'react';
import { broadcastEventMessage } from '../../../../api/socketApi/broadcastApi/broadcast/broadcastEventMessage';
import {
  resetGroupSheetsStartProcessState,
  setGroupSheetsStartProcessData,
} from '../../../../reducers/plannerApp/groupSheetsStart/actions';
import { GROUP_SHEETS_START_PROCESS_EVENT_TYPE } from '../../../../constants/sockets';

export const abortGroupSheetsStartConfirmOptions = {
  confirmModalTitle: (
    <Trans id="all_sheets_to_start.group_sheets_to_start_dialog@abort_launch_confirm_title">
      Остановка запуска маршрутных листов
    </Trans>
  ),
  confirmText: (
    <Trans id="all_sheets_to_start.group_sheets_to_start_dialog@abort_launch_confirm_text">
      Процесс запуска МЛ будет остановлен. Запуск маршрутных листов, которые уже успели запуститься, отменён не будет.
      Вы уверены?
    </Trans>
  ),
  confirmDialogInnerProps: {
    dialogMaxWidth: MATERIAL_UI_DIALOG_MAX_WIDTH.XS,
  },
};

export const sendPartOfSheetsStartedNotification = (startedSheetsAmount, allSelectedSheetsAmount) => {

  sendNotification(
    <Trans id="all_sheets_to_start.group_sheets_to_start_dialog@part_of_sheets_started_notification">
      {startedSheetsAmount} маршрутных листов запущено успешно.
      {allSelectedSheetsAmount - startedSheetsAmount} маршрутных листов не были запущены,
      повторите запуск.
    </Trans>,
    NOTIFICATION_LEVEL.WARNING,
  );
};


export const GROUP_SHEETS_START_BROADCAST_STATUS = {
  STARTED: 'STARTED',
  FINISHED: 'FINISHED',
  IN_PROGRESS: 'IN_PROGRESS',
  NOT_STARTED: 'NOT_STARTED',
};

export const broadcastGroupSheetsProcessAndAddToStore = ({ sheetsToStartAmount, startedSheetsAmount, status }) =>
  dispatch => {

    broadcastEventMessage(
      GROUP_SHEETS_START_PROCESS_EVENT_TYPE,
      {
        status,
        startedSheetsAmount,
        sheetsToStartAmount,
      },
      true,
    );

    if (status === GROUP_SHEETS_START_BROADCAST_STATUS.FINISHED) {
      return dispatch(resetGroupSheetsStartProcessState());
    }

    dispatch(setGroupSheetsStartProcessData({
      sheetsToStartAmount,
      startedSheetsAmount,
      status,
    }));
  };
