import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Switch, Route, Redirect } from 'react-router-dom';

import { AppChoosingScreenContainer } from '../AppChoosingScreen/AppChoosingScreenContainer';
import { AppErrorScreenContainer } from '../AppErrorScreen/AppErrorScreenContainer';
import { LoginScreenContainer } from '../LoginScreen/LoginScreenContainer';
import { MasterApp } from '../MasterApp/MasterApp';
import { StorageManagementApp } from '../StorageManagementApp/StorageManagementApp';
import { PlannerApp } from '../PlannerApp/PlannerApp';
import { WorkerAppContainer } from '../WorkerApp/WorkerAppContainer';
import { AdminAppContainer } from '../AdminApp/AdminAppContainer';

import {
  LOGIN_ROUTE,
  ADMIN_APP_MAIN_ROUTE,
  ADMIN_APP_USERS_ROUTE,
  MASTER_APP_MAIN_ROUTE,
  PLANNER_APP_MAIN_ROUTE,
  PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE,
  STORAGE_MANAGEMENT_APP_MAIN_ROUTE,
  STORAGE_MANAGEMENT_APP_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
  WORKER_APP_MAIN_ROUTE,
  QUALITY_CONTROL_APP_MAIN_ROUTE,
} from '../../constants/routes';

import './style.css';
import { transformQueryParamsToQueryString } from '../../utils/url';
import { PERMISSION, PERMISSIONS_MANAGER_TYPE } from '../../hoc/withPermissionsManager/constants';
import { QualityControlApp } from '../QualityControlApp/QualityControlApp';


export class App extends Component {

  static propTypes = {
    isAppUserAuthenticated: PropTypes.bool.isRequired,
    hasAppCriticalError: PropTypes.bool.isRequired,
    PermissionsManager: PERMISSIONS_MANAGER_TYPE,
  };

  _renderContent = () => {
    const {
      isAppUserAuthenticated,
      hasAppCriticalError,
    } = this.props;

    if(hasAppCriticalError)
      return <AppErrorScreenContainer />;

    /*
    * Если не авторизованы и находимся не на роуте формы авторизации, то редиректимся на этот роут, в гет параметры
    * записываем текущий роут, чтобы после авторизации вернуться на него же
    * */
    if(!isAppUserAuthenticated) {
      return(
        <Switch>
          {this._renderLoginRoute()}
          <Route render={this._redirectToLoginRouteRenderProp} />
        </Switch>
      );
    }

    return(
      <Switch>
        <Route path="/" exact component={AppChoosingScreenContainer} />
        {this._renderLoginRoute()}
        {
          this._renderIfPermissionGranted(
            this._renderPlannerAppMainRoute,
            PERMISSION.REVIEW_PLANNER_APP,
          )
        }
        {
          this._renderIfPermissionGranted(
            this._renderStorageManagementAppMainRoute,
            PERMISSION.REVIEW_STORAGE_MANAGEMENT_APP,
          )
        }
        {
          this._renderIfPermissionGranted(
            this._renderMasterAppMainRoute,
            PERMISSION.REVIEW_MASTER_APP,
          )
        }
        {
          this._renderIfPermissionGranted(
            this._renderWorkerAppMainRoute,
            PERMISSION.REVIEW_WORKER_APP,
          )
        }
        {
          this._renderIfPermissionGranted(
            this._renderQualityControlAppMainRoute,
            /*
            Пока нет отдельного разрешения для просмотра приложения "Контроль", поэтому используем
            разрешение на запрос транзакций по партиям - данные этой точки используются для таблицы
            этого приложения
            */
            PERMISSION.GET_ENTITY_BATCH_TRANSACTIONS,
          )
        }
        {
          this._renderIfPermissionGranted(
            this._renderAdminAppMainRoute,
            PERMISSION.REVIEW_ADMIN_APP,
          )
        }
      </Switch>
    );
  };

  _renderLoginRoute = () =>
    <Route path={LOGIN_ROUTE} component={LoginScreenContainer} />;

  _renderIfPermissionGranted = (renderCb, permission) =>
    this.props.PermissionsManager.isGranted(permission) ?
      renderCb() :
      null;

  _redirectToLoginRouteRenderProp = ({ location: { pathname } }) =>
    <Redirect to={`${LOGIN_ROUTE}?${transformQueryParamsToQueryString({ returnTo: pathname })}`} />;

  _renderPlannerAppMainRoute = () =>
    <Route path={PLANNER_APP_MAIN_ROUTE} render={this._plannerAppMainRouteRenderProp} />;

  _plannerAppMainRouteRenderProp = ({ match: { isExact } }) => isExact ?
    <Redirect to={PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE} /> :
    <PlannerApp />;

  _renderStorageManagementAppMainRoute = () =>
    <Route path={STORAGE_MANAGEMENT_APP_MAIN_ROUTE} render={this._storageManagementAppRenderProp} />;

  _storageManagementAppRenderProp = ({ match: { isExact } }) => isExact ?
    <Redirect to={STORAGE_MANAGEMENT_APP_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE} /> :
    <StorageManagementApp />;

  _renderMasterAppMainRoute = () =>
    <Route path={MASTER_APP_MAIN_ROUTE} component={MasterApp} />;

  _renderWorkerAppMainRoute = () =>
    <Route path={WORKER_APP_MAIN_ROUTE} component={WorkerAppContainer} />;

  _renderQualityControlAppMainRoute = () =>
    <Route path={QUALITY_CONTROL_APP_MAIN_ROUTE} component={QualityControlApp} />;

  _renderAdminAppMainRoute = () =>
    <Route path={ADMIN_APP_MAIN_ROUTE} render={this._adminAppMainRouteRenderProp} />;

  _adminAppMainRouteRenderProp = ({ match: { isExact } }) => isExact ?
    <Redirect to={ADMIN_APP_USERS_ROUTE} /> :
    <AdminAppContainer/>;

  render() {
    return (
      <div className="cyber-assistant-app">
        {
          this._renderContent()
        }
      </div>
    );
  }
}
