
export const MATERIAL_UI_STYLE_COLOR = {
  INHERIT: 'inherit',
  DEFAULT: 'default',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SUCCESS: 'success',
};

export const MATERIAL_UI_THEME_COLOR_CODES = {
  DEFAULT: '#e0e0e0',
  PRIMARY: '#2196f3',
  SECONDARY: '#dc004e',
  WARNING: '#ff9800',
  SUCCESS: '#4caf50',
};

export const MATERIAL_UI_SIZE = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

export const MATERIAL_UI_VARIANT = {
  STANDARD: 'standard',
  CONTAINED: 'contained',
  OUTLINED: 'outlined',
  SCROLLABLE: 'scrollable',
  FULL_WIDTH: 'fullWidth',
  TEXT: 'text',
  DETERMINATE: 'determinate',
};

export const MATERIAL_UI_DIALOG_MAX_WIDTH = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl',
};

export const MATERIAL_UI_LABEL_PLACEMENT = {
  START: 'start',
  END: 'end',
  TOP: 'top',
  BOTTOM: 'bottom',
};

export const MATERIAL_UI_ORIENTATION = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
};

export const MATERIAL_UI_SEVERITY = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
};
export const MATERIAL_UI_TOOLTIP_PLACEMENT = {
  BOTTOM_END: 'bottom-end',
  BOTTOM_START: 'bottom-start',
  BOTTOM: 'bottom',
  LEFT_END: 'left-end',
  LEFT_START: 'left-start',
  LEFT: 'left',
  RIGHT_END: 'right-end',
  RIGHT_START: 'right-start',
  RIGHT: 'right',
  TOP_END: 'top-end',
  TOP_START: 'top-start',
  TOP: 'top',
};
