import { useCallback, useEffect, useRef } from 'react';


const DEFAULT_ABORT_MESSAGE = 'Search cancelled by user';
const UNMOUNT_ABORT_MESSAGE = 'The component that made the request has been unmounted. Request was cancelled.';

/**
 * Хук, позволяющий отменять запрос после его вызова.
 * @param requestFunction {function({signal})} - функция-запрос, должна принимать объект signal, который передается в fetch в options.
 * Запоминается при первом вызове хука и больше не изменяется
 * @returns {[(function(*): *), (function(): void)]}
 */
export const useAbortRequest = requestFunction => {
  const abortControllerRef = useRef(null);

  const requestRef = useRef(null);

  useEffect(() => {
    requestRef.current = requestFunction;
  }, [requestFunction]);

  const handleRequest = useCallback(props => {
    if (!requestRef.current) {
      return;
    }

    abortControllerRef.current = new AbortController();

    return requestRef.current({ ...props, signal: abortControllerRef.current.signal });
  }, [requestRef]);

  const abortRequest = useCallback((abortReason = DEFAULT_ABORT_MESSAGE) => {
    if (!abortControllerRef.current) {
      return;
    }

    abortControllerRef.current.abort(abortReason);
    abortControllerRef.current = null;
  }, []);

  useEffect(() => {
    // Если у нас не пустой abortControllerRef при размонтировании, то запрос всё ещё идет.
    // Скорее всего в таком случае его результаты нам не будут нужны, так что отменяем.
    return () => {
      if (abortControllerRef.current) {
        abortRequest(UNMOUNT_ABORT_MESSAGE);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [handleRequest, abortRequest];
};
