import {
  MATERIAL_UI_DIALOG_MAX_WIDTH,
  MATERIAL_UI_STYLE_COLOR,
  MATERIAL_UI_VARIANT,
} from '../../../../../constants/materialUI';
import DialogTitle from '@mui/material/DialogTitle';
import {
  AbortLaunchLabelTrans,
  AreYouSureLabelTans,
  CancelLabelTrans,
  GroupSheetsStartInProgressTooltipLabelTrans,
  PleaseDontCloseWindowLabelTrans,
  ShortAmountLabelTrans,
  StartInProductionLabelTrans,
  YesLabelTrans,
} from '../../../../../utils/commonTransComponents';
import DialogContent from '@mui/material/DialogContent';
import { Trans } from '@lingui/macro';
import React, { useCallback } from 'react';
import { LinearProgress } from '@mui/material';
import { ChipList } from '../../../../common/ChipList/ChipList';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import Dialog from '@mui/material/Dialog';
import { FUNC_IS_REQUIRED_TYPE, OBJECT_OF_ANY_TYPE } from '../../../../../constants/propTypes';
import PropTypes from 'prop-types';
import { TooltipWithForwardedRef } from '../../../../common/TooltipWrapper/TooltipWithForwardedRef';


export const GroupSheetsStartDialog = props => {
  const {
    onClose,
    isDialogInProgressMode,
    startedSheetsAmount,
    sheetsToStartAmount,
    entitiesInSheetsToStart,
    onAbortSheetsStartProcessClick,
    onSheetsStartClick,
    isGroupSheetsStartInProgress,
  } = props;


  const getChipId = useCallback(({ entityCombinedName }) => entityCombinedName, []);
  const getChipLabel = useCallback(({ entityCombinedName, entitiesInBatchAmount }) => {
    return (
      <React.Fragment>
        {entityCombinedName}
        {' - '}
        {entitiesInBatchAmount}
        {' '}
        {ShortAmountLabelTrans}
      </React.Fragment>
    );
  }, []);

  return (
    <Dialog
        className="group-sheets-start-dialog"
        open
        onClose={isDialogInProgressMode ? undefined : onClose}
        maxWidth={MATERIAL_UI_DIALOG_MAX_WIDTH.SM}
        fullWidth
        disableEscapeKeyDown={isDialogInProgressMode}
    >
      <DialogTitle className="group-sheets-start-dialog__title">
        {StartInProductionLabelTrans}
      </DialogTitle>
      <DialogContent className="group-sheets-start-dialog__content">

        {
          isDialogInProgressMode ?
            (
              <React.Fragment>
                <div className="group-sheets-start-dialog__description">
                  <p>
                    <Trans id="all_sheets_to_start.group_sheets_to_start_dialog@started_sheets_amount">
                      Запущено {startedSheetsAmount} из {sheetsToStartAmount}
                    </Trans>
                  </p>
                </div>

                <div className="group-sheets-start-dialog__progress-container">
                  <LinearProgress
                      variant={MATERIAL_UI_VARIANT.DETERMINATE}
                      value={startedSheetsAmount * (100 / sheetsToStartAmount)}
                  />
                  <p>
                    {PleaseDontCloseWindowLabelTrans}
                  </p>
                </div>

                <div className="group-sheets-start-dialog__buttons">
                  <Button
                      className="group-sheets-start-dialog__button"
                      variant={MATERIAL_UI_VARIANT.CONTAINED}
                      color={MATERIAL_UI_STYLE_COLOR.SECONDARY}
                      startIcon={<CloseIcon />}
                      onClick={onAbortSheetsStartProcessClick}
                  >
                    {AbortLaunchLabelTrans}
                  </Button>
                </div>
              </React.Fragment>
            ) :
            (
              <React.Fragment>
                <div className="group-sheets-start-dialog__description">
                  <p>
                    <Trans id="all_sheets_to_start.group_sheets_to_start_dialog@description">
                      В производство будет запущено {sheetsToStartAmount} маршрутных листов.
                    </Trans>
                  </p>
                  <p>
                    <b>{AreYouSureLabelTans}</b>
                  </p>
                </div>

                <ChipList
                    className="group-sheets-start-dialog__chip-list"
                    items={entitiesInSheetsToStart}
                    getItemId={getChipId}
                    getItemLabel={getChipLabel}
                />


                <div className="group-sheets-start-dialog__buttons">
                  <Button
                      className="group-sheets-start-dialog__button"
                      variant={MATERIAL_UI_VARIANT.CONTAINED}
                      color={MATERIAL_UI_STYLE_COLOR.SECONDARY}
                      startIcon={<CloseIcon />}
                      onClick={onClose}
                  >
                    {CancelLabelTrans}
                  </Button>

                  <TooltipWithForwardedRef
                      title={isGroupSheetsStartInProgress ? GroupSheetsStartInProgressTooltipLabelTrans : null}
                      refElementClassName="group-sheets-start-dialog__button"
                  >
                    <Button
                        className="group-sheets-start-dialog__button"
                        variant={MATERIAL_UI_VARIANT.CONTAINED}
                        color={MATERIAL_UI_STYLE_COLOR.SUCCESS}
                        startIcon={<CheckIcon />}
                        onClick={onSheetsStartClick}
                        disabled={isGroupSheetsStartInProgress}
                    >
                      {YesLabelTrans}
                    </Button>
                  </TooltipWithForwardedRef>
                </div>
              </React.Fragment>
            )
        }
      </DialogContent>
    </Dialog>
  );
};

GroupSheetsStartDialog.propTypes = {
  onClose: FUNC_IS_REQUIRED_TYPE,
  isDialogInProgressMode: PropTypes.bool.isRequired,
  isGroupSheetsStartInProgress: PropTypes.bool.isRequired,
  startedSheetsAmount: PropTypes.number.isRequired,
  sheetsToStartAmount: PropTypes.number.isRequired,
  entitiesInSheetsToStart: PropTypes.arrayOf(OBJECT_OF_ANY_TYPE),
  onAbortSheetsStartProcessClick: FUNC_IS_REQUIRED_TYPE,
  onSheetsStartClick: FUNC_IS_REQUIRED_TYPE,
};