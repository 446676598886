import {
  greaterThanValidatorFabric,
  isInRangeValidatorFabric,
  requiredValidatorFabric,
} from '@bfg-frontend/validators';

import { Trans } from '@lingui/macro';
import React from 'react';

import _constant from 'lodash/constant';


export const required = requiredValidatorFabric({
  errorIdentityCreator: _constant(
    <Trans id="common_form_validator@required_field">
      Поле обязательно для заполнения
    </Trans>,
  ),
});

export const validPercentValueValidator = isInRangeValidatorFabric({
  minValue: 0,
  maxValue: 100,
  errorIdentityCreator: _constant(
    <Trans id="common_form_validator@valid_percent_value">
      Значение процентов должно быть в промежутке от 0 до 100
    </Trans>,
  ),
});

export const minSymbolsValidatorFabric = (minSymbols, orEqual) => {
  const greaterThanValidator = greaterThanValidatorFabric({
    minValue: minSymbols,
    orEqual: orEqual,
    errorIdentityCreator: _constant(
      orEqual ?
        <Trans id="common_form_validator@min_symbols_validator_or_equal">
          В строке должно быть не менее {minSymbols} символов
        </Trans> :
        <Trans id="common_form_validator@min_symbols_validator">
          В строке должно быть не более {minSymbols} символов
        </Trans>,
    ),
  });
  return value => greaterThanValidator(value.length);
};