import React, { Component } from 'react';

import PropTypes from 'prop-types';

import {
  SHEET_SUMMARY_TYPE,
  FUNC_IS_REQUIRED_TYPE,
  SHEET_OPERATION_STATUS_TYPE,
} from '../../../../constants/propTypes';

import { sheetOperationStatusRowStyle } from '../../../../tableProperties/rowStyles/sheetOperationRowStyles';
import { SheetOperationReviewDialogContainer } from '../../../common/SheetOperationReviewDialog/SheetOperationReviewDialogContainer';
import { PERMISSIONS_MANAGER_TYPE } from '../../../../hoc/withPermissionsManager/constants';

import { isAlwaysTrueCb } from '../../../../constants/common';
import { SheetOperationsTable } from '../../../PlannerApp/SheetOperationsTable/SheetOperationsTable';
import {
  SHEET_OPERATION_TABLE_ROW_DATA_SHAPE_OBJ,
} from '../../../PlannerApp/SheetOperationsTable/SheetOperationsTable.types';
import _get from 'lodash/get';
import { ENTITY_BATCH_TRANSACTION_TYPE } from '../../../../constants/sheets';
import { SimpleSummary } from '../../../common/SimpleSummary/SimpleSummary';
import { INAPPROPRIATE_SHEET_SECONDARY_SUMMARY_SCHEMA } from '../../InappropriateSheets/constants';
import { DEFECTIVE_SHEET_SECONDARY_SUMMARY_SCHEMA } from '../constants';
import './style.css';
import { SimpleHelpAlert } from '../../../common/SimpleHelpAlert/SimpleHelpAlert';


export class DefectiveSheetsReviewContent extends Component {

  static propTypes = {
    sheetOperationsTableId: PropTypes.string.isRequired,
    sheetOperationsTableData: PropTypes.arrayOf(
      PropTypes.shape({
        ...SHEET_OPERATION_TABLE_ROW_DATA_SHAPE_OBJ,
        status: SHEET_OPERATION_STATUS_TYPE.isRequired,
        progress: PropTypes.number.isRequired,
        isLastSheetOperation: PropTypes.bool.isRequired,
        isPreviousSheetOperationFinished: PropTypes.bool.isRequired,
        isLastAssemblySheetOperation: PropTypes.bool.isRequired,
      }),
    ),
    sheetToReviewData: SHEET_SUMMARY_TYPE,
    fetchSheetOperations: FUNC_IS_REQUIRED_TYPE,
    PermissionsManager: PERMISSIONS_MANAGER_TYPE,
  };


  constructor(props) {
    super(props);
    this.state = {
      selectedSheetOperationData: null,
    };
  };


  _renderSheetOperationsTable = () => {
    const {
      sheetOperationsTableId,
      sheetOperationsTableData,
      fetchSheetOperations,
    } = this.props;

    return(
      <SheetOperationsTable
          sheetOperationsTableId={sheetOperationsTableId}
          sheetOperationsTableData={sheetOperationsTableData}
          fetchSheetOperations={fetchSheetOperations}
          onSheetOperationRowClick={this._handleSheetOperationRowClick}
          getSheetOperationsTableRowStyle={sheetOperationStatusRowStyle}
      />
    );
  };

  _handleSheetOperationRowClick = (_, sheetOperationData) => {

    const {
      sheetToReviewData: {
        entityIdentity,
        entityCode,
        entityName,
        sheetIdentity,
        entityBatchId,
        entitiesInBatchAmount,
        orderId,
        orderName,
        providingState,
      },
    } = this.props;

    this.setState({
      selectedSheetOperationData: {
        ...sheetOperationData,
        entityIdentity,
        entityCode,
        entityName,
        entityBatchId,
        sheetIdentity,
        entitiesInBatchAmount,
        orderId,
        orderName,
        providingState,
      },
    });
  };

  _renderSheetSummary = () => (
    <SimpleHelpAlert
        className="defective-sheet-review-content__sheet_summary"
        content={this._renderTransactionsInfo()}
    />
  );

  _renderTransactionsInfo = () => {
    const {
      sheetToReviewData,
    } = this.props;

    const markedAsInappropriateEntityBatchTransactionEntity = _get(
      sheetToReviewData,
      [
        'entityBatchTransactionsByType',
        ENTITY_BATCH_TRANSACTION_TYPE.MARKED_AS_INAPPROPRIATE,
      ],
      {},
    );

    const markedAsDefectiveEntityBatchTransactionEntity = _get(
      sheetToReviewData,
      [
        'entityBatchTransactionsByType',
        ENTITY_BATCH_TRANSACTION_TYPE.MARKED_AS_DEFECTIVE,
      ],
      {},
    );

    const {
      userName,
      defectReason,
      entityBatchTransactionCreateStamp,
    } = markedAsInappropriateEntityBatchTransactionEntity;

    const {
      userName: markedAsDefectiveTransactionUserName,
      entityBatchTransactionCreateStamp: markedAsDefectiveEntityBatchTransactionCreateStamp,
    } = markedAsDefectiveEntityBatchTransactionEntity;

    return (
      <div className="transactions-info__inner-wrapper">
        <SimpleSummary
            className="transactions-info__simple-summary"
            summaryData={{
              userName,
              defectReason,
              entityBatchTransactionCreateStamp,
            }}
            secondarySummarySchema={INAPPROPRIATE_SHEET_SECONDARY_SUMMARY_SCHEMA}
        />

        <SimpleSummary
            className="transactions-info__simple-summary"
            summaryData={{
              userName: markedAsDefectiveTransactionUserName,
              entityBatchTransactionCreateStamp: markedAsDefectiveEntityBatchTransactionCreateStamp,
            }}
            secondarySummarySchema={DEFECTIVE_SHEET_SECONDARY_SUMMARY_SCHEMA}
        />
      </div>
    );
  }


  _renderSheetOperationReviewDialog = () => {
    const {
      selectedSheetOperationData,
    } = this.state;

    if(selectedSheetOperationData === null) return;

    return(
      <SheetOperationReviewDialogContainer
          closeDialog={this._handleSheetOperationReviewDialogClose}
          sheetOperationData={selectedSheetOperationData}
          areStatusChangeButtonsHidden={isAlwaysTrueCb}
          isPauseSheetAdditionalActionHidden={isAlwaysTrueCb}
          isSplitEntityBatchAdditionalActionHidden={isAlwaysTrueCb}
          isAssigneesChangeDisabled={isAlwaysTrueCb}
          isEquipmentChangeDisabled={isAlwaysTrueCb}
          isNoteChangeDisabled={isAlwaysTrueCb}
          isConsumeEntitiesActionHidden={isAlwaysTrueCb}
      />
    );
  };

  _handleSheetOperationReviewDialogClose = () => this.setState({ selectedSheetOperationData: null });

  render() {
    return(
      <div className="defective-sheet-review-content">
        {this._renderSheetSummary()}
        {this._renderSheetOperationsTable()}
        {this._renderSheetOperationReviewDialog()}
      </div>
    );
  }
}
