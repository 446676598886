import { bindActionCreators, compose } from 'redux';
import { withPermissionsManager } from '../../../../hoc/withPermissionsManager/withPermissionsManager';
import { connect } from 'react-redux';
import { InappropriateSheetReviewContent } from './InappropriateSheetReviewContent';
import { createSheetTypeOperationsTableId } from '../../../../utils/tables';
import { SHEET_TYPE } from '../../../../constants/sheets';
import { sheetOperationsRemoteTableDataSelector } from '../../../../selectors/sheets';
import { sheetOperationStatusRowStyle } from '../../../../tableProperties/rowStyles/sheetOperationRowStyles';
import {
  broadcastSheetInappropriateStateUnconfirmed,
  broadcastSheetFinalDefectiveStateConfirmed,
  fetchReviewedSheetOperationsRemoteTableEntities,
  markSheetInappropriateStateUnconfirmed,
  sendSheetInappropriateStateUnconfirmedNotification,
  sendSheetFinalDefectiveStateConfirmedNotification,
  markSheetFinalDefectiveStateConfirmed,
  fetchInappropriateSheets,
} from '../../../../operations/sheets';
import { clearTableData, clearTableRemoteData, reFetchRemoteTableData } from '../../../../reducers/table/actions';
import { allTasksTablesIdsSelector } from '../../../../selectors/taskView';
import { MASTER_TASKS_TO_DO_TABLE_ID } from '../../../MasterApp/MasterTasksToDo/constants';
import {
  deleteAllAssemblySheetsReserveData,
} from '../../../../reducers/storageManagementApp/assemblySheets/reserveData/actions';
import { deleteAssemblySheetConsumeData } from '../../../../reducers/workerApp/assemblySheets/consumeData/actions';
import {
  clearAllDefaultSheetsPartsAndMaterialsToConsume,
} from '../../../../reducers/storageManagementApp/defaultSheets/actions';
import { fetchSheetTypeRemoteTableDataCbFactory } from '../../../Sheets/SheetsContainer';
import { TASKS_MODEL } from '../../../../reducers/schemaModel/models/tasksSchema';
import {
  getOrderCompletedRelatedActions,
} from '../../../../api/socketApi/socketMessageHandlers/caClientMessageHandler/sheetFinish';
import {
  clearAllDepartmentsWithTasks,
  clearAllEquipmentClassesInDepartmentWithTask,
} from '../../../../reducers/workerApp/tasksOwners/actions';
import { sendOrderIsReadyToCompleteNotification } from '../../../../operations/orders';


const mapStateToProps = (state, { sheetToReviewData }) => {

  const {
    sheetId,
    entitiesInBatchAmount,
  } = sheetToReviewData;

  const sheetOperationsTableId = createSheetTypeOperationsTableId(SHEET_TYPE.INAPPROPRIATE, sheetId);

  return {
    sheetOperationsTableId,
    sheetOperationsTableData: sheetOperationsRemoteTableDataSelector(
      state,
      { sheetOperationsTableId, entitiesInBatchAmount },
    ),
    getSheetOperationsTableRowStyle: sheetOperationStatusRowStyle,
    sheetToReviewData,
    allTasksTablesIds: allTasksTablesIdsSelector(state),
  };
};

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      fetchReviewedSheetOperationsRemoteTableEntities,
      markSheetInappropriateStateUnconfirmed,
      markSheetFinalDefectiveStateConfirmed,
    },
    dispatch,
  ),


  /*
  * Влияние события возвращения партии в работу на разные типы приложения и обработка этого с учетом того,
  * что возвращение в работу происходит на экране просмотра партии в разделе "Контроль. Несоответствующая продукция".
  *
  * 1. Плановик.
  * 1.1. Раздел "МЛ в производстве". Если в работу возвращается незавершённая партия, то она должна появится в таблице
  * "В производстве", поэтому очищаем данные этой таблицы, чтобы при следующем входе были запрошена актуальные данные.
  * Если в работу возвращается завершённая партия, то очистки данных для этой таблицы не требуется.
  *
  * 1.2. Раздел "МЛ на запуск". Возвращение партии в работу не влияет на этот раздел.
  *
  * 1.3. Раздел "МЛ приостановленные". Возвращение партии в работу не влияет на этот раздел.
  *
  * 1.4. Раздел "МЛ завершенные". Если в работу возвращается завершенная партия,то очищаем данные таблицы
  * "Завершённые МЛ", чтобы при следующем входе на этот экран были запрошены обновлённые данные.
  * Если в работу возвращается незавершённая партия, то ничего не делаем.
  *
  *
  * 2. Комплектование.
  *
  * 2.1. Раздел "Комплектование. Стандартные". Стандартную неукомплектованную партию нельзя взять в работу,
  * соответственно и брак по ней отметить тоже нельзя. Поэтому при возвращении партии в работу, она не может попасть в
  * таблицу "Комплектование. Стандартные". Никакие данные очищать не нужно. К тому же, эта таблица не серверная и её
  * данные запрашиваются при каждом входе на этот экран, они в любом случае обновятся.
  * Если в работу возвращается завершённая партия, то нужно очистить все данные потребления.
  *
  * 2.2 Раздел "Комплектование. Сборочные". Если в работу возвращается незавершённая, сборочная и неукомплектованная
  * партия, то она должна появиться в таблицу "Комплектование. Сборочные", поэтому очищаем данные этой таблицы,
  * чтобы при следующем входе на этот экран были запрошены актуальные данные.
  * Если в работу возвращается завершенная партия, то очищаем все данные потребления и резервирования для сборочных МЛ,
  * очищаем данные таблицы "Сборочные", т.к. на складе появляются новые материалы и статус "Укомплектован/не укомплектован"
  * для некоторых МЛ может измениться.
  *
  *
  * 3. Мастер.
  * 3.1. Раздел "Мастер. Нужно сделать". Если в работу возвращается незавершённая партия, то в таблице "Нужно сделать"
  * должны появиться новые задания, поэтому очищаем данные этой таблицы, чтобы при следующем входе на этот экран были
  * запрошены актуальные данные.
  * Если в работу возвращается завершённая, то ничего не делаем.
  *
  * 3.2 Раздел "Мастер. Завершённые". Если в работу возвращается завершенная партия, то в этой таблице должны появиться
  * новые задания, поэтому очищаем данные таблицы "Завершенные". Но очищать данные таблицы не нужно, потому что
  * данные таблицы "Завершённые" уже были очищены при отметке брака по партии.
  * Если в работу возвращается незавершённая партия, то ничего не делаем.
  *
  *
  * 4. Рабочий.
  * 4.1. Раздел "Выбора подразделений". Если в работу возвращается незавершённая партия, то в списке должны появиться
  * новые задания, поэтому очищаем данные всех таблиц заданий рабочего.
  * Если же в работу возвращается завершённая партия, то ничего не делаем.
  *
  * 4.2. Раздел "Выбора класса РЦ в подразделении". Если в работу возвращается незавершённая партия, то в списке должны
  * появиться новые задания и, возможно, новые классы РЦ. Поэтому очищаем данные выбора классов РЦ.
  * Если же в работу возвращается завершённая партия, то ничего не делаем.
  *
  * 4.3. Раздел "Просмотра заданий для класса РЦ в подразделении". Если в работу возвращается незавершённая партия, то
  * в списке должны появиться новые задания и, возможно, новые подразделения. Поэтому очищаем данные выбора подразделений.
  * Если же в работу возвращается завершённая партия, то ничего не делаем.
  *
  * 4.4 Раздел просмотра ВСЕХ заданий в отдельном разделе приложения "Рабочий". Если в работу возвращается незавершённая
  * партия, то в списке должны появиться новые задания, поэтому очищаем данные этой таблицы.
  * Если в работу возвращается завершённая партия, то ничего не делаем.
  *
  *
  * 5. Контроль
  * 5.1 Раздел "Контроль. Несоответствующая продукция". После возвращения партии в работу, она должна пропасть из таблицы
  * "Несоответствующая продукция", поэтому выполняем перезапрос данных этой таблицы.
  *
  * 5.2 Раздел "Контроль. Несоответствующая продукция. Экран просмотра партии". При возвращении партии в работу
  * нужно очистить данные этой таблицы
  *
  * 5.3 Раздел "Контроль. Окончательный брак". Возвращение партии в работу не влияет на этот раздел.
  * */
  handleSheetInappropriateStateUnconfirmed: (
    unconfirmedSheetId,
    sheetIdentity,
    changedOrderId,
    orderName,
    entityBatchId,
    entityBatchWasFinished,
    allTasksTablesIds,
  ) => {
    sendSheetInappropriateStateUnconfirmedNotification(sheetIdentity);

    const isOrderCompleted = !!changedOrderId;

    // 5.1
    dispatch(reFetchRemoteTableData(
      SHEET_TYPE.INAPPROPRIATE,
      SHEET_TYPE.INAPPROPRIATE,
      fetchSheetTypeRemoteTableDataCbFactory(dispatch, fetchInappropriateSheets),
    ));


    // 5.2
    dispatch(clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.INAPPROPRIATE, unconfirmedSheetId)));


    // Если партия была завершена
    if (entityBatchWasFinished) {

      if (isOrderCompleted) {
        sendOrderIsReadyToCompleteNotification(orderName);
      }

      return dispatch([
        clearTableRemoteData([
          // 1.4
          SHEET_TYPE.COMPLETED,
          // 2.2
          SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        ]),
        // 4.1, 4.2
        deleteAllAssemblySheetsReserveData(),
        deleteAssemblySheetConsumeData({ sheetId: unconfirmedSheetId }),
        clearAllDefaultSheetsPartsAndMaterialsToConsume(),
        ...getOrderCompletedRelatedActions(isOrderCompleted, changedOrderId),
      ]);
    }

    // Если партия не была завершена
    dispatch([
      clearTableRemoteData([
        // 1.1
        SHEET_TYPE.IN_PRODUCTION,
        // 2.2
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        // 3.1
        MASTER_TASKS_TO_DO_TABLE_ID,
        // 4.4
        TASKS_MODEL,
        // 4.3
        ...allTasksTablesIds,
      ]),
      // 4.1, 4.2
      clearAllDepartmentsWithTasks(),
      clearAllEquipmentClassesInDepartmentWithTask(),
    ]);
  },


  /*
  * Влияние события отметки окончательного брака на разные типы приложения и обработка этого с учетом того,
  * что возвращение в работу происходит на экране просмотра партии в разделе "Контроль. Несоответствующая продукция".
  *
  * 1. Плановик.
  * 1.1. Раздел "МЛ в производстве". Отметка окончательного брака не влияет на этот раздел.
  *
  * 1.2. Раздел "МЛ на запуск". Отметка окончательного брака не влияет на этот раздел.
  *
  * 1.3. Раздел "МЛ приостановленные". Отметка окончательного брака не влияет на этот раздел.
  *
  * 1.4. Раздел "МЛ завершенные". После отметки окончательного брака партия должна появиться в таблице "Завершенные",
  * поэтому очищаем данные этой таблицы.
  *
  *
  * 2. Комплектование.
  *
  * 2.1. Раздел "Комплектование. Стандартные". Отметка окончательного брака не влияет на этот раздел.
  *
  * 2.2 Раздел "Комплектование. Сборочные". Отметка окончательного брака не влияет на этот раздел.
  *
  *
  * 3. Мастер.
  * 3.1. Раздел "Мастер. Нужно сделать". Отметка окончательного брака не влияет на этот раздел.
  *
  * 3.2 Раздел "Мастер. Завершённые". Отметка окончательного брака не влияет на этот раздел.
  *
  *
  * 4. Рабочий.
  * 4.1. Раздел "Выбора подразделений". Отметка окончательного брака не влияет на этот раздел.
  *
  * 4.2. Раздел "Выбора класса РЦ в подразделении". Отметка окончательного брака не влияет на этот раздел.
  *
  * 4.3. Раздел "Просмотра заданий для класса РЦ в подразделении". Отметка окончательного брака не влияет на этот раздел.
  *
  * 4.4 Раздел просмотра ВСЕХ заданий в отдельном разделе приложения "Рабочий". Отметка окончательного брака не влияет
  * на этот раздел.
  *
  *
  * 5. Контроль
  * 5.1 Раздел "Контроль. Несоответствующая продукция". После подтверждения окончательного брака партия удаляется из
  * таблицы "Несоответствующая продукция", поэтому выполняем перезапрос данных таблицы.
  *
  * 5.2 Раздел "Контроль. Несоответствующая продукция. Экран просмотра партии". После отметки окончательного брака партия
  * удаляется из таблицы "Несоответствующая продукция" и становится недоступной для просмотра, поэтому очищаем данные
  * экрана просмотра этой партии
  *
  * 5.3 Раздел "Контроль. Окончательный брак". После отметки окончательного брака партия должна появиться в таблице
  * "Окончательный брак", поэтому очищаем данные этой таблицы, чтобы при следующем входе были запрошены актуальные данные.
  * */
  handleSheetFinalDefectiveStateConfirmed: (sheetId, sheetIdentity) => {
    sendSheetFinalDefectiveStateConfirmedNotification(sheetIdentity);

    // 5.1
    dispatch(reFetchRemoteTableData(
      SHEET_TYPE.INAPPROPRIATE,
      SHEET_TYPE.INAPPROPRIATE,
      fetchSheetTypeRemoteTableDataCbFactory(dispatch, fetchInappropriateSheets),
    ));

    dispatch([
      // 5.2
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.INAPPROPRIATE, sheetId)),
      clearTableRemoteData([
      // 5.3
        SHEET_TYPE.DEFECTIVE,
      // 1.4
        SHEET_TYPE.COMPLETED,
      ]),
    ]);

  },
});


const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {
    sheetOperationsTableId,
    sheetOperationsTableData,
    getSheetOperationsTableRowStyle,
    sheetToReviewData,
    allTasksTablesIds,
  } = stateProps;

  const {
    fetchReviewedSheetOperationsRemoteTableEntities,
    markSheetInappropriateStateUnconfirmed: markSheetInappropriateStateUnconfirmedFromDispatchProps,
    markSheetFinalDefectiveStateConfirmed: markSheetFinalDefectiveStateConfirmedFromDispatchProps,
    handleSheetInappropriateStateUnconfirmed: handleSheetInappropriateStateUnconfirmedFromDispatchProps,
    handleSheetFinalDefectiveStateConfirmed:handleSheetFinalDefectiveStateConfirmedFromDispatchProps,
  } = dispatchProps;

  const {
    closeReviewDialog,
    PermissionsManager,
  } = ownProps;

  const {
    sheetId,
    sheetIdentity,
    entityBatchId,
    orderName,
  } = sheetToReviewData;
  
  const fetchSheetOperations = ({ tableParams }) =>
    fetchReviewedSheetOperationsRemoteTableEntities(sheetToReviewData.sheetId, tableParams);

  const markSheetInappropriateStateUnconfirmed = () =>
    markSheetInappropriateStateUnconfirmedFromDispatchProps(entityBatchId)
      .then(response => {

        const {
          data: {
            changedOrderId,
            entityBatchId,
            entityBatchWasFinished,
            entityRouteSheetId: unconfirmedSheetId,
          },
        } = response;

        const isOrderCompleted = !!changedOrderId;

        broadcastSheetInappropriateStateUnconfirmed({
          isOrderCompleted,
          changedOrderId,
          orderName,
          sheetId,
          sheetIdentity,
          entityBatchWasFinished,
        });

        handleSheetInappropriateStateUnconfirmedFromDispatchProps(
          unconfirmedSheetId,
          sheetIdentity,
          changedOrderId,
          orderName,
          entityBatchId,
          entityBatchWasFinished,
          allTasksTablesIds,
        );
        closeReviewDialog();
      });

  const markSheetFinalDefectiveStateConfirmed = () => markSheetFinalDefectiveStateConfirmedFromDispatchProps(entityBatchId)
    .then(() => {

      broadcastSheetFinalDefectiveStateConfirmed(sheetId, sheetIdentity);
      handleSheetFinalDefectiveStateConfirmedFromDispatchProps(sheetId, sheetIdentity);
      closeReviewDialog();
    });

  return {
    sheetOperationsTableId,
    sheetOperationsTableData,
    getSheetOperationsTableRowStyle,
    sheetToReviewData,
    PermissionsManager,
    fetchSheetOperations,
    markSheetInappropriateStateUnconfirmed,
    markSheetFinalDefectiveStateConfirmed,
  };
};



export const InappropriateSheetReviewContentContainer = compose(
  withPermissionsManager,
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
  ),
)(InappropriateSheetReviewContent);

InappropriateSheetReviewContentContainer.displayName = 'InappropriateSheetReviewContentContainer';
