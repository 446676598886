import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  DepartmentsAutocomplete,
} from '../../entitiesAutocomplete/DepartmentsAutocomplete/DepartmentsAutocomplete';
import {
  DepartmentLabelTrans,
  EquipmentClassLabelTrans,
  OperationLabelTrans,
} from '../../../utils/commonTransComponents';
import {
  EquipmentClassesAutocomplete,
} from '../../entitiesAutocomplete/EquipmentClassesAutocomplete/EquipmentClassesAutocomplete';
import _get from 'lodash/get';
import {
  SHEET_OPERATION_FEATURES_DEPARTMENT_CLASS_AUTOCOMPLETE_ID,
  SHEET_OPERATION_FEATURES_EQUIPMENT_CLASS_AUTOCOMPLETE_ID,
  SHEET_OPERATION_FEATURES_OPERATION_AUTOCOMPLETE_ID,
  SheetOperationFeatureScopeFormEquipmentClassTooltipTrans, SheetOperationFeatureScopeFormOperationTooltipTrans,
} from './constants';
import { OperationsAutocomplete } from '../../entitiesAutocomplete/OperationsAutocomplete/OperationsAutocomplete';

import './style.css';
import { FUNC_IS_REQUIRED_TYPE } from '../../../constants/propTypes';
import Button from '@mui/material/Button';
import { TooltipWithForwardedRef } from '../../common/TooltipWrapper/TooltipWithForwardedRef';
import { clearAutocompleteData } from '../../../reducers/autocomplete/actions';
import { useDispatch } from 'react-redux';
import { DEPARTMENT_MODEL, EQUIPMENT_CLASS_MODEL, OPERATION_MODEL } from '../../../constants/models';
import { getCombinedSheetOperationFeatureScopeId } from '../SheetOperationFeatureReviewScreen/constants';
import { Trans } from '@lingui/macro';

const OPERATIONS_AUTOCOMPLETE_OPTIONS_REQUEST_LIMIT = 70;

export const SheetOperationFeatureScopeForm = props => {

  const {
    onSubmit,
    isFormDisabled,
  } = props;

  const dispatch = useDispatch();

  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedEquipmentClass, setSelectedEquipmentClass] = useState(null);
  const [selectedOperation, setSelectedOperation] = useState(null);

  const selectedDepartmentId = useMemo(() => _get(selectedDepartment, 'id'), [selectedDepartment]);
  const selectedEquipmentClassId = useMemo(() => _get(selectedEquipmentClass, 'id'), [selectedEquipmentClass]);
  const selectedOperationId = useMemo(() => _get(selectedOperation, 'id'), [selectedOperation]);

  // обработчики событий
  const departmentChangeHandler = useCallback(departmentEntity => {
    /*
    При выборе подразделения очищаем автокомплиты операции и класса РЦ, т.к. они нне могут быть выбраны
    без подразделения
    */
    setSelectedEquipmentClass(null);
    setSelectedOperation(null);

    dispatch([
      clearAutocompleteData(SHEET_OPERATION_FEATURES_EQUIPMENT_CLASS_AUTOCOMPLETE_ID),
      clearAutocompleteData(SHEET_OPERATION_FEATURES_OPERATION_AUTOCOMPLETE_ID),
    ]);

    setSelectedDepartment(departmentEntity);
  }, [dispatch, setSelectedOperation, setSelectedEquipmentClass, setSelectedDepartment]);

  const equipmentClassChangeHandler = useCallback(equipmentClassEntity => {
    /*
    При выборе класса РЦ очищаем автокомплиты операции, т.к. нельзя выбрать операцию без класса РЦ
    */
    setSelectedOperation(null);

    dispatch(clearAutocompleteData(SHEET_OPERATION_FEATURES_OPERATION_AUTOCOMPLETE_ID));

    setSelectedEquipmentClass(equipmentClassEntity);
  }, [dispatch, setSelectedEquipmentClass, setSelectedOperation]);

  const submitHandler = e => {
    e.preventDefault();

    const dataToSubmit = {
      id: getCombinedSheetOperationFeatureScopeId(selectedDepartmentId, selectedEquipmentClassId, selectedOperationId),
      [DEPARTMENT_MODEL]: selectedDepartment || {},
      [EQUIPMENT_CLASS_MODEL]: selectedEquipmentClass || {},
      [OPERATION_MODEL]: selectedOperation || {},
    };

    onSubmit(dataToSubmit);
  };

  const isEquipmentClassAutocompleteDisabled = !selectedDepartmentId || isFormDisabled;
  const isOperationAutocompleteDisabled = isEquipmentClassAutocompleteDisabled || !selectedEquipmentClassId || isFormDisabled;
  const isSubmitBtnDisabled = !selectedDepartmentId || isFormDisabled;

  return (
    <div className="sheet-operation-feature-scope-form">
      <form  onSubmit={submitHandler} className="sheet-operation-feature-scope-form__form">

        <DepartmentsAutocomplete
            id={SHEET_OPERATION_FEATURES_DEPARTMENT_CLASS_AUTOCOMPLETE_ID}
            onChange={departmentChangeHandler}
            value={selectedDepartment}
            title={DepartmentLabelTrans}
            wrapperClassName="sheet-operation-feature-scope-form__autocomplete-wrapper"
            isDisabled={isFormDisabled}
            shouldPreloadData
        />

        {_renderAutocompleteWithTooltip(
          <EquipmentClassesAutocomplete
              id={SHEET_OPERATION_FEATURES_EQUIPMENT_CLASS_AUTOCOMPLETE_ID}
              title={EquipmentClassLabelTrans}
              onChange={equipmentClassChangeHandler}
              value={selectedEquipmentClass}
              isDisabled={isEquipmentClassAutocompleteDisabled}
              departmentIds={
                !!selectedDepartmentId ?
                  [selectedDepartmentId] :
                  undefined
              }
              shouldLoadOptions={!!selectedDepartmentId}
              shouldPreloadData
              /*
              В key добавлен идентификатор выбранного подразделения, чтобы при смене подразделения для этого
              автокомплита сразу запрашивались опции
              */
              key={selectedDepartmentId}
          />,
          isEquipmentClassAutocompleteDisabled ? SheetOperationFeatureScopeFormEquipmentClassTooltipTrans : '',
        )}

        {_renderAutocompleteWithTooltip(
          <OperationsAutocomplete
              id={SHEET_OPERATION_FEATURES_OPERATION_AUTOCOMPLETE_ID}
              title={OperationLabelTrans}
              onChange={setSelectedOperation}
              value={selectedOperation}
              isDisabled={isOperationAutocompleteDisabled}
              departmentId={selectedDepartmentId}
              equipmentClassId={selectedEquipmentClassId}
              shouldLoadOptions={!!selectedDepartmentId && !!selectedEquipmentClassId}
              shouldPreloadData
              optionsRequestLimit={OPERATIONS_AUTOCOMPLETE_OPTIONS_REQUEST_LIMIT}
              /*
              В key добавлен идентификатор выбранного класса РЦ, чтобы при смене подразделения для этого
              автокомплита сразу запрашивались опции
              */
              key={selectedEquipmentClassId}
          />,
          isOperationAutocompleteDisabled ? SheetOperationFeatureScopeFormOperationTooltipTrans : '',
        )}

        <Button
            className="sheet-operation-feature-scope-form__submit-btn"
            type="submit"
            disabled={isSubmitBtnDisabled}
        >
          <Trans id="sheet_operation_settings.sheet_operation_features@add_feature_scope">
            Добавить область действия
          </Trans>
        </Button>

      </form>

    </div>
  );
};

const _renderAutocompleteWithTooltip = (autoCompleteElement, tooltip) => (
  <TooltipWithForwardedRef
      placement="top"
      title={tooltip}
      classes={{
        popper: 'sheet-operation-feature-scope-form__tooltip-popper',
        tooltip: 'sheet-operation-feature-scope-form__tooltip',
      }}
      refElementClassName="sheet-operation-feature-scope-form__autocomplete-wrapper"
  >
    {autoCompleteElement}
  </TooltipWithForwardedRef>
);

SheetOperationFeatureScopeForm.propTypes = {
  onSubmit: FUNC_IS_REQUIRED_TYPE,
  isFormDisabled: PropTypes.bool.isRequired,
};