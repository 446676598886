import React, { Component } from 'react';

import { Route } from 'react-router-dom';

import { STORAGE_MANAGEMENT_APP_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE } from '../../constants/routes';
import { AppTypeTopBarContainer } from '../AppTypeTopBar/AppTypeTopBarContainer';
import { SheetsWaitingPartsAndMaterials } from './SheetsWaitingPartsAndMaterials/SheetsWaitingPartsAndMaterials';
import { PartsAndMaterialsConsumingLabelTrans } from '../../utils/commonTransComponents';


/*
* Пока до конца непонятно, как будет развиваться приложение, но есть предположение, что функционал работы со складом
* будет расширяться. Именно поэтому весь раздел было принято называть абстрактно StorageManagementApp, но, сейчас, внутри,
* по сути, находится 1 раздел, отвечающий за комплектование МЛ. Поэтому, в данный момент, в интерфейсах везде фразы
* только про комлектование, а не про управление складами
* */
export class StorageManagementApp extends Component {

  _renderStorageManagementMainAppBar = () =>
    <AppTypeTopBarContainer
        className="storage-management-app__main-app-bar"
        appTitle={PartsAndMaterialsConsumingLabelTrans}
    />;

  _renderStorageManagementContent = () =>
    <div className="storage-management-app__content app-container">
      <Route
          path={STORAGE_MANAGEMENT_APP_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE}
          component={SheetsWaitingPartsAndMaterials}
      />
    </div>;

  render() {
    return (
      <div className="storage-management-app">
        {this._renderStorageManagementMainAppBar()}
        {this._renderStorageManagementContent()}
      </div>
    );
  }
}