import { AutocompleteContainer } from '../../common/Autocomplete/AutocompleteContainer';
import { NoDataLabelTrans, NotSelectedLabelTrans } from '../../../utils/commonTransComponents';
import { createCaEntitiesAutocompleteLoadOptionsActionCreator } from '../../../reducers/autocomplete/actions';
import {
  ENTITY_BATCH_MODEL,
  SHEET_MODEL,
} from '../../../constants/models';
import { FILTER_GROUP_TYPES, FILTER_TYPES } from '../../../api/restCollectionApi';
import React, { useMemo } from 'react';
import { ENTITY_BATCH_STATUS, SHEET_STATUS } from '../../../constants/sheets';
import { RELATED_MODEL_FIELD_DELIMITER } from '../../../constants/magics';
import PropTypes from 'prop-types';
import { IDENTITY_FIELD_DEFAULT_SORT_BY } from '../../../constants/entities';
import _size from 'lodash/size';
import _isNil from 'lodash/isNil';

export const getSheetsAutocompleteOptionLabel = ({ identity }) => identity;

const _getSheetsAutocompleteLoadOptionsActionCreator = (sheetStatus, entityBatchStatus, prepareOptions) =>
  createCaEntitiesAutocompleteLoadOptionsActionCreator({
    requestModel: SHEET_MODEL,
    prepareOptions,
    getRequestQuery: inputValue => {

      let filters = [];

      if(!_isNil(sheetStatus)) {
        filters.push({
          column: 'type',
          filterType: FILTER_TYPES.EQUALS,
          filterValue: sheetStatus,
        });
      }

      if(!_isNil(entityBatchStatus)) {
        filters.push({
          column: [ENTITY_BATCH_MODEL, 'status'].join(RELATED_MODEL_FIELD_DELIMITER),
          filterType: FILTER_TYPES.EQUALS,
          filterValue: entityBatchStatus,
        });
      }

      if (!inputValue) {

        let query = {
          sortBy: IDENTITY_FIELD_DEFAULT_SORT_BY,
        };

        if(_size(filters) > 0) {
          query.filter = {
            filterGroupType: FILTER_GROUP_TYPES.AND,
            filters,
          };
        }

        return query;
      }

      return {
        filter: {
          filterGroupType: FILTER_GROUP_TYPES.AND,
          filters: [
            ...filters,
            {
              column: 'identity',
              filterType: FILTER_TYPES.CONTAINS,
              filterValue: inputValue,
            },
          ],
        },
        sortBy: IDENTITY_FIELD_DEFAULT_SORT_BY,
      };
    },
    getRequestOptions: () => ({
      modelRelations: {
        [ENTITY_BATCH_MODEL]: {
          level: 1,
        },
      },
    }),
  });

export const SheetsAutocomplete = props => {
  const {
    sheetStatus,
    entityBatchStatus,
    shouldLoadOptions,
    prepareOptions,
  } = props;

  const loadOptionsActionCreator = useMemo(
    () => {
      if(!shouldLoadOptions) {
        return undefined;
      }

      return _getSheetsAutocompleteLoadOptionsActionCreator(sheetStatus, entityBatchStatus, prepareOptions);
    },
    [shouldLoadOptions, sheetStatus, entityBatchStatus, prepareOptions],
  );

  return (
    <AutocompleteContainer
        {...props}
        getOptionLabel={getSheetsAutocompleteOptionLabel}
        loadOptionsActionCreator={loadOptionsActionCreator}
        placeholder={NotSelectedLabelTrans}
        noOptionsMessage={NoDataLabelTrans}
    />
  );
};

SheetsAutocomplete.propTypes = {
  sheetStatus: PropTypes.oneOf(Object.values(SHEET_STATUS)),
  entityBatchStatus: PropTypes.oneOf(Object.values(ENTITY_BATCH_STATUS)),
  shouldLoadOptions: PropTypes.bool,
  prepareOptions: PropTypes.func,
};