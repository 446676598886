import React, { Component } from 'react';
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { MasterTasksTable } from '../MasterTasksTable/MasterTasksTable';
import {
  FUNC_IS_REQUIRED_TYPE,
  MASTER_TASKS_TABLE_ROW_DATA_TYPE,
} from '../../../constants/propTypes';
import { getMasterTasksTodoCustomFilters, MASTER_TASKS_TO_DO_TABLE_ID } from './constants';
import { getTaskToDoTableRowStyle } from '../../../tableProperties/rowStyles/taskRowStyles';
import { SheetOperationReviewDialogContainer } from '../../common/SheetOperationReviewDialog/SheetOperationReviewDialogContainer';
import {
  areTaskStartOrContinueActionButtonsHidden,
  getSheetOperationReviewDataFromMasterTask,
  getTaskCanNotStartOrContinueHelpInfoContent,
  isConsumeEntitiesOnTaskActionHidden,
} from '../../../utils/tasks';
import _isEqual from 'lodash/isEqual';

import {
  MASTER_TASKS_TO_DO_TABLE_ADDITIONAL_FILTER_IDENTITY,
} from '../../../constants/tasks';
import { CheckBoxFiltersPanel } from '../../common/CheckboxFiltersPanel/CheckboxFiltersPanel';
import { ReadyToStartOnlyLabel } from '../../../utils/commonTransComponents';
import { MASTER_TASKS_TO_DO_TABLE_MODEL } from '../../../reducers/schemaModel/models/masterTasksSchema';


/*
* Дефолтный обработчик дополнительных фильтров, используется для всех обычных фильтров, кроме фильтра "все задания":
*  - Если фильтр сейчас выбран, т.е. его снимают, то удаляем его из списка текущих фильтров и проверяем не были ли это
* последний из обычных фильтров. Если был последний, то устанавливаем фильтр "все задания". Если был не последний, то
* устанавливаем оставшиеся фильтры без него.
* - Если фильтр был не выбран, т.е. сейас его выбирают, то к текущим обычным фильтрам нужно добавить новый фильтр.
* Если же это первый из обычных фильтров, то фильтр "все задания" нужно удалить из списка фильтров. Для удобства
* реализации мы фильтруем в этом случае опцию "все задания" в любом случае, т.е. не проверяем отдельно была ли
* она установлена, так меньше заифлений, а массив тут небольшой, на производительность не повлияет
* */
const defaultAdditionalFilterChangeCbFactory =  (currentFilterKey, isCurrentFilterChecked, allFilters, setFilters) =>
  () => {
    if(isCurrentFilterChecked) {
      const newFilters = allFilters.filter(filterKey => filterKey !== currentFilterKey);

      return newFilters.length > 0 ?
        setFilters(newFilters) :
        setFilters([MASTER_TASKS_TO_DO_TABLE_ADDITIONAL_FILTER_IDENTITY.ALL_TASKS]);
    }

    const newFilters = allFilters
      .filter(filterKey => filterKey !== MASTER_TASKS_TO_DO_TABLE_ADDITIONAL_FILTER_IDENTITY.ALL_TASKS)
      .concat(currentFilterKey);

    setFilters(newFilters);
  };

export const MASTER_TASKS_TO_DO_TABLE_ADDITIONAL_FILTERS_SCHEMA = [
  {
    filterKey: MASTER_TASKS_TO_DO_TABLE_ADDITIONAL_FILTER_IDENTITY.ALL_TASKS,
    filterLabel: (
      <Trans id="master_tasks_to_do@all_tasks_additional_filter">
        Все задания
      </Trans>
    ),

    /*
    * Для фильтра "все задания" особая логика. Если он выбран, это значит, что все остальные фильтры не выбраны и
    * при повторном клике по нему ничего не должно происходить, т.к. если он перестанет быть выбран, то отображаться
    * будет нечему. Если же он не выбран и его выбирают, то должны сброситься все остальные фильтры, т.е. установиться
    * только этот фильтр
    * */
    filterChangeCbFactory: (currentFilterKey, isCurrentFilterChecked, allFilters, setFilters) =>
      () => {
        if(isCurrentFilterChecked) {
          return;
        }

        setFilters([MASTER_TASKS_TO_DO_TABLE_ADDITIONAL_FILTER_IDENTITY.ALL_TASKS]);
      },
  },
  {
    filterKey: MASTER_TASKS_TO_DO_TABLE_ADDITIONAL_FILTER_IDENTITY.TASKS_WITHOUT_ASSIGNEES,
    filterLabel: (
      <Trans id="master_tasks_to_do@tasks_without_assignees_additional_filter">
        Задания без назначенных исполнителей
      </Trans>
    ),
    filterChangeCbFactory: defaultAdditionalFilterChangeCbFactory,
  },
  {
    filterKey: MASTER_TASKS_TO_DO_TABLE_ADDITIONAL_FILTER_IDENTITY.TASKS_WITHOUT_EQUIPMENT,
    filterLabel: (
      <Trans id="master_tasks_to_do@tasks_without_equipment_additional_filter">
        Задания без назначенного оборудования
      </Trans>
    ),
    filterChangeCbFactory: defaultAdditionalFilterChangeCbFactory,
  },
  {
    filterKey: MASTER_TASKS_TO_DO_TABLE_ADDITIONAL_FILTER_IDENTITY.READY_TO_START_TASKS,
    filterLabel: ReadyToStartOnlyLabel,
    filterChangeCbFactory: defaultAdditionalFilterChangeCbFactory,
  },
];

export class MasterTasksToDo extends Component {

  static propTypes = {
    departmentIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    masterTasksToDoAdditionalFilters: PropTypes.arrayOf(
      PropTypes.oneOf(Object.values(MASTER_TASKS_TO_DO_TABLE_ADDITIONAL_FILTER_IDENTITY)),
    ).isRequired,
    masterTasksToDoTableData: PropTypes.arrayOf(MASTER_TASKS_TABLE_ROW_DATA_TYPE),
    fetchMasterTasksToDoRemoteTableData: FUNC_IS_REQUIRED_TYPE,
    reFetchMasterTasksToDoRemoteTableData: FUNC_IS_REQUIRED_TYPE,
    clearMasterAppDepartmentsRelatedRemoteTablesData: FUNC_IS_REQUIRED_TYPE,
    setMasterTasksToDoAdditionalFilters: FUNC_IS_REQUIRED_TYPE,
    handleTaskStatusChanged: FUNC_IS_REQUIRED_TYPE,
    handleSheetFinished: FUNC_IS_REQUIRED_TYPE,
    handleSheetOperationDataChanged: FUNC_IS_REQUIRED_TYPE,
    handleSheetPaused: FUNC_IS_REQUIRED_TYPE,
    handleEntityBatchSplit: FUNC_IS_REQUIRED_TYPE,
    handleDefectiveEntitiesMarked: FUNC_IS_REQUIRED_TYPE,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedTaskData: null,
    };
  }

  /*
  * В случае с таблицами заданий в разделе мастера есть особенность реализации:
  * Таблица является серверной и для неё задается функция запроса данных. Эта функция вызывается в логике табличной
  * абстракции при любом из событий взаимодействия с таблицей, например, смена сортировки, фильтрация и т.д. Но, в
  * этом случае есть ещё внешний для таблицы фильтр, который влияет на данные таблицы - это селект выбора подразделений,
  * который находится вне это компонента выше по иерарихии. Соотвественно, в этом случае, при смене подразделений в этом
  * внешнем для таблицы фильтре, тоже должен выполниться запрос данных для серверной таблицы, учитывающий текущие
  * параметры таблицы. Далее идёт ещё одна особенность - данные по выбранным в фильтре подразделениям хранятся в
  * параметрах роутинга и именно это должно являться "источником правды" о выбранных подразделениях. Поэтому мы не
  * может вызывать запрос на событие смены фильтра, это некорректно т.к. в этот момент данные ещё не установлены в
  * параметры роутера и мало ли что может случиться, мы должны реагировать только на изменение параметра роутинга.
  * Кроме того, что даже более важно, только реагируя именно на изменения параметра роутинга мы можем корректно
  * обрабатывать навигацию стрелочками браузера - т.е., например, нажимаем стрелочку браузера назад, параметр в
  * роутинге меняется на предыдущий, а там может быть были выбраны другие подразделения и мы должны это сдетектировать
  * и выполнить другой запрос. Т.е., как и говорилось, "источник правды" в таких случаях это именно параметры роутинга,
  * и только с ними и нужно работать для корректной работы.
  *
  * С учетом всего описанного реализация следующая:
  *  1. На маунт компонента, монтируется и сам компонент таблицы, стартовый запрос будет выполнен там, текущие параметры
  * роутинга, т.е. выбранные подразделения, будут учтены при генерации функции fetchMasterTasksToDoRemoteTableData в
  * контейнере этого компонента. Запрос на маунт не будет выполнен, если он уже был закэширован, что и нужно. При этом
  * с учетом внешнего фильтра очень важно правильно сбрасывать этот кэш (см. п.4 и комментарий к componentDidUpdate
  * в MasterWorkSpace)
  *  2. При изменении параметров таблицы всё как обычно, будет вызываться та же функция
  * fetchMasterTasksToDoRemoteTableData, которая учитываети новые измененные параметры таблицы и выбранные подразделения,
  * т.к., как указывалось в прошлом пункте, при генерации fetchMasterTasksToDoRemoteTableData они учитываются. В этом
  * случае всё отрабатывает в рамках табличной абстракции ничего делать не надо.
  * 3. В случае изменения параметра роутинга, связанного с выбранными подразделениям, что происходит либо при изменении
  * во внешнем фильтре подразделениий (в этом случае просто pushаться новые параметры подразделений в роут), либо при
  * навигации стрелочками браузера (когда происходит изменение параметров роутера на предыдущие и следующие), мы
  * выполняем запрос новых данных для текущей таблицы. Для запроса используем пропс
  * reFetchMasterTasksToDoRemoteTableData, который при генерации в контейнере при изменении параметров подразделений,
  * попадая в компонент уже учитывает и текущие параметры таблицы, и выбранные подразделения, поэтому эта функция
  * здесь вызывается без каких дополнительных параметров (они уже "внутри" функции). То, что параметры в роуте
  * изменились мы понимаем, сравнивая уже обработанные в компоненте по иерархии выше и получаемые здесь в виде пропсов -
  * прошлый и текущий пропс departmentIds
  * 4. Кроме того, т.к. фильтр по подразделениям является общим для нескольких таблиц в разделе мастера, то необходимо
  * сбросить кэш для всех таблиц, которые сейчас не на экране, т.к. при изменении общего фильтра по подразделениям,
  * данные становятся неактуальными и при входе в раздел  с этой таблицей нужно чтобы выполнился новый запрос.
  * Иначе, если кэш не сбросить, как указывалось в п.1., запрос при входе в раздел с этой таблицей выполнен не будет.
  * Аналогичные очистки по этой же причине нужно производить и компонентах, где находятся другие таблицы.
  * */
  componentDidUpdate(prevProps) {
    if(_isEqual(prevProps.departmentIds, this.props.departmentIds)) {
      return;
    }

    const {
      clearMasterAppDepartmentsRelatedRemoteTablesData,
      reFetchMasterTasksToDoRemoteTableData,
    } = this.props;

    //4 из комментария выше, очищаем все таблицы (кроме этой), зависящие от выбранных подразделений
    clearMasterAppDepartmentsRelatedRemoteTablesData();

    //3 из комментария выше, в случае, если параметры выбранных подразделений в роуте изменились
    reFetchMasterTasksToDoRemoteTableData();
  }

  _renderAdditionalTasksToDoTableFilters = () => {
    const {
      masterTasksToDoAdditionalFilters,
      setMasterTasksToDoAdditionalFilters,
    } = this.props;

    return(
      <CheckBoxFiltersPanel
          className="master-tasks-to-do__additional-tasks-to-do-table-filters"
          filters={masterTasksToDoAdditionalFilters}
          setFilters={setMasterTasksToDoAdditionalFilters}
          schema={MASTER_TASKS_TO_DO_TABLE_ADDITIONAL_FILTERS_SCHEMA}
      />
    );
  };

  _renderTasksToDoTable = () => {

    const {
      departmentIds,
      masterTasksToDoTableData,
      fetchMasterTasksToDoRemoteTableData,
    } = this.props;

    return(
      <MasterTasksTable
          tableId={MASTER_TASKS_TO_DO_TABLE_ID}
          tableModel={MASTER_TASKS_TO_DO_TABLE_MODEL}
          fetchRemoteTableData={fetchMasterTasksToDoRemoteTableData}
          tableData={masterTasksToDoTableData}
          noDataContent={
            <Trans id="master_tasks_to_do@no_tasks_to_do">
              Нет заданий для выполнения
            </Trans>
          }
          getRowStyle={getTaskToDoTableRowStyle}
          onRowClick={this._handleTaskRowClick}
          customFilters={getMasterTasksTodoCustomFilters(departmentIds)}
      />
    );
  };

  _handleTaskRowClick = (_, taskData) => {
    this.setState({
      selectedTaskData: getSheetOperationReviewDataFromMasterTask(taskData),
    });
  };

  _renderSheetOperationReviewDialog = () => {

    const {
      handleSheetOperationDataChanged,
    } = this.props;
    const {
      selectedTaskData,
    } = this.state;

    if(selectedTaskData == null) {
      return null;
    }

    return(
      <SheetOperationReviewDialogContainer
          closeDialog={this._handleTaskReviewDialogClose}
          sheetOperationData={selectedTaskData}
          handleSheetOperationStatusChanged={this._handleTaskStatusChanged}
          handleSheetFinished={this._handleSheetFinished}
          handleSheetOperationDataChanged={handleSheetOperationDataChanged}
          handleSheetPaused={this._handleSheetPaused}
          handleEntityBatchSplit={this._handleEntityBatchSplit}
          handleDefectiveEntitiesMarked={this._handleDefectiveEntitiesMarked}
          getHelpAlertContent={getTaskCanNotStartOrContinueHelpInfoContent}
          areStatusChangeButtonsHidden={areTaskStartOrContinueActionButtonsHidden}
          isConsumeEntitiesActionHidden={isConsumeEntitiesOnTaskActionHidden}
      />
    );
  };

  _handleTaskReviewDialogClose = () => this.setState({ selectedTaskData: null });

  _handleTaskStatusChanged = selectedTaskData => {
    this.props.handleTaskStatusChanged(selectedTaskData);
    this._handleTaskReviewDialogClose();
  };

  _handleSheetFinished = selectedTaskData => {
    this.props.handleSheetFinished(selectedTaskData);
    this._handleTaskReviewDialogClose();
  };

  _handleSheetPaused = selectedTaskData => {
    this.props.handleSheetPaused(selectedTaskData);
    this._handleTaskReviewDialogClose();
  };

  _handleEntityBatchSplit = selectedTaskData => {
    this.props.handleEntityBatchSplit(selectedTaskData);
    this._handleTaskReviewDialogClose();
  };

  _handleDefectiveEntitiesMarked = data => {
    this.props.handleDefectiveEntitiesMarked(data);
    this._handleTaskReviewDialogClose();
  };

  render() {
    return (
      <div className="master-tasks-to-do">
        {this._renderAdditionalTasksToDoTableFilters()}
        {this._renderTasksToDoTable()}
        {this._renderSheetOperationReviewDialog()}
      </div>
    );
  }
}
