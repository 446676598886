import { allTasksTablesIdsSelector } from '../../../../selectors/taskView';
import { routerPathnameSelector } from '../../../../reducers/router/selectors';
import { matchPath } from 'react-router-dom';
import {
  MASTER_APP_TASKS_TO_DO_ROUTE,
  PLANNER_APP_COMPLETED_SHEETS_ROUTE,
  PLANNER_APP_ORDERS_IN_PRODUCTION_ROUTE,
  PLANNER_APP_ORDERS_READY_TO_COMPLETE_ROUTE,
  PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE,
  QUALITY_CONTROL_INAPPROPRIATE_SHEETS_ROUTE,
  STORAGE_MANAGEMENT_APP_ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
  STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
  WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE,
  WORKER_APP_TASKS_ROUTE,
} from '../../../../constants/routes';
import { clearTableData, clearTableRemoteData, reFetchRemoteTableData } from '../../../../reducers/table/actions';
import {
  createOrderEntriesTableId,
  createSheetTypeOperationsTableId,
  getEquipmentClassInDepartmentTasksTableId,
} from '../../../../utils/tables';
import { SHEET_TYPE } from '../../../../constants/sheets';
import { MASTER_TASKS_TO_DO_TABLE_ID } from '../../../../components/MasterApp/MasterTasksToDo/constants';
import { TASKS_MODEL } from '../../../../reducers/schemaModel/models/tasksSchema';
import {
  deleteAllAssemblySheetsReserveData,
} from '../../../../reducers/storageManagementApp/assemblySheets/reserveData/actions';
import { deleteAssemblySheetConsumeData } from '../../../../reducers/workerApp/assemblySheets/consumeData/actions';
import {
  clearAllDefaultSheetsPartsAndMaterialsToConsume,
  clearDefaultSheetsPartsAndMaterialsToConsumeWithExceptionOfGivenIds,
} from '../../../../reducers/storageManagementApp/defaultSheets/actions';
import {
  fetchSheetTypeRemoteTableDataCbFactory, fetchSheetTypeToReviewCbFactory,
} from '../../../../components/Sheets/SheetsContainer';
import {
  fetchCompletedSheets,
  fetchDefaultSheetsWithEnoughPartsAndMaterialsInStorage,
  fetchInappropriateSheets,
  fetchPossiblePartsAndMaterialsToConsumeForDefaultSheet,
  fetchSheetsInProduction,
  sendSheetInappropriateStateUnconfirmedNotification,
} from '../../../../operations/sheets';
import { getOrderCompletedRelatedActions } from './sheetFinish';
import { ORDER_TYPE } from '../../../../constants/orders';
import { fetchOrderRemoteTableDataCbFactory } from '../../../../components/Orders/OrdersContainer';
import {
  fetchOrdersInProduction,
  fetchOrdersReadyToComplete,
  sendOrderIsReadyToCompleteNotification,
} from '../../../../operations/orders';
import { push } from 'connected-react-router';
import {
  fetchFullAssemblySheetsPartsAndMaterials,
  reFetchReserveDataForAssemblySheetWaitingPartAndMaterialsReviewScreen,
} from '../../../../components/StorageManagementApp/SheetsWaitingPartsAndMaterials/AssemblySheetsWaitingPartsAndMaterials/AssemblySheetsWaitingPartsAndMaterials';
import {
  getMasterAppDepartmentIdsFromRouteParam,
} from '../../../../components/MasterApp/MasterWorkspace/masterAppDepartmentIdsRouteParam';
import {
  masterTasksToDoAdditionalFiltersSelector,
} from '../../../../reducers/masterApp/tasksToDoAdditionalFilters/selectors';
import {
  MASTER_TASKS_TO_DO_TABLE_MODEL,
} from '../../../../reducers/schemaModel/models/masterTasksSchema';
import {
  fetchMasterDepartmentsTasksToDoRemoteTableData,
} from '../../../../operations/masterWorkspace';
import {
  fetchEquipmentClassInDepartmentTasksRemoteTableData,
  fetchTasksRemoteTableData,
} from '../../../../operations/tasks';
import _get from 'lodash/get';
import {
  clearAllDepartmentsWithTasks,
  clearAllEquipmentClassesInDepartmentWithTask,
} from '../../../../reducers/workerApp/tasksOwners/actions';
import { SHEET_MODEL } from '../../../../constants/models';

export const handleSheetInappropriateStateUnconfirmed = message =>
  (dispatch, getState) => {

    const state = getState();

    const {
      isOrderCompleted,
      changedOrderId,
      orderName,
      sheetId,
      sheetIdentity,
      entityBatchWasFinished,
    } = message;

    const allTasksTablesIds = allTasksTablesIdsSelector(state);

    const currentPathname = routerPathnameSelector(state);

    //Если находимся в разделе "Контроль. Несоответствующая продукция"
    const inappropriateSheetsRouteMatch = matchPath(currentPathname, {
      path: QUALITY_CONTROL_INAPPROPRIATE_SHEETS_ROUTE,
    });
    if (inappropriateSheetsRouteMatch !== null) {
      return dispatch(_updateIfOnInappropriateSheetsScreen(
        sheetId,
        allTasksTablesIds,
        changedOrderId,
        orderName,
        isOrderCompleted,
        currentPathname,
        sheetIdentity,
        entityBatchWasFinished,
      ));
    }

    //Если находимся в разделе "Плановик. МЛ в производстве"
    const sheetsInProductionRouteMatch = matchPath(currentPathname, {
      path: PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE,
    });
    if (sheetsInProductionRouteMatch !== null) {
      return dispatch(_updateIfOnSheetsInProductionScreen(
        sheetId,
        allTasksTablesIds,
        changedOrderId,
        orderName,
        isOrderCompleted,
        entityBatchWasFinished,
      ));
    }


    //Если находимся в разделе "Плановик. Завершенные МЛ"
    const completedSheetsRouteMatch = matchPath(currentPathname, {
      path: PLANNER_APP_COMPLETED_SHEETS_ROUTE,
    });
    if (completedSheetsRouteMatch !== null) {
      return dispatch(_updateIfOnCompletedSheetsScreen(
        sheetId,
        allTasksTablesIds,
        changedOrderId,
        orderName,
        isOrderCompleted,
        entityBatchWasFinished,
      ));
    }


    //Если находимся в разделе "Плановик. Заказы. В производстве"
    const ordersInProductionRouteMatch = matchPath(currentPathname, {
      path: PLANNER_APP_ORDERS_IN_PRODUCTION_ROUTE,
    });
    if (ordersInProductionRouteMatch !== null) {
      return dispatch(_updateIfOnOrdersInProductionScreen(
        sheetId,
        currentPathname,
        allTasksTablesIds,
        changedOrderId,
        orderName,
        isOrderCompleted,
        entityBatchWasFinished,
      ));
    }


    //Если находимся в разделе "Плановик. Заказы. Готовые к завершению"
    const ordersReadyToCompleteRouteMatch = matchPath(currentPathname, {
      path: PLANNER_APP_ORDERS_READY_TO_COMPLETE_ROUTE,
    });
    if (ordersReadyToCompleteRouteMatch !== null) {
      return dispatch(_updateIfOnOrdersReadyToCompleteScreen(
        sheetId,
        currentPathname,
        allTasksTablesIds,
        changedOrderId,
        orderName,
        isOrderCompleted,
        entityBatchWasFinished,
      ));
    }


    //Если находимся в разделе "Комплектование - "стандартные" неукомплектованные маршрутные листы"
    const defaultSheetsWaitingPartsAndMaterialsRouteMatch = matchPath(currentPathname, {
      path: STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
    });
    if (defaultSheetsWaitingPartsAndMaterialsRouteMatch !== null) {
      return dispatch(_updateIfOnDefaultSheetsWaitingPartsAndMaterialsScreen(
        sheetId,
        currentPathname,
        allTasksTablesIds,
        changedOrderId,
        orderName,
        isOrderCompleted,
        entityBatchWasFinished,
      ));
    }


    //Если находимся в разделе "Комплектование" - "сборки"
    const assemblySheetsWaitingPartsAndMaterialsRouteMatch = matchPath(currentPathname, {
      path: STORAGE_MANAGEMENT_APP_ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
    });
    if (assemblySheetsWaitingPartsAndMaterialsRouteMatch !== null) {
      return dispatch(_updateIfOnAssemblySheetsWaitingPartsAndMaterialsScreen(
        sheetId,
        currentPathname,
        allTasksTablesIds,
        changedOrderId,
        orderName,
        isOrderCompleted,
        entityBatchWasFinished,
      ));
    }

    //Если находимся  в разделе "Мастер. Требуется выполнить"
    const masterTasksTodoRouteMatch = matchPath(currentPathname, {
      path: MASTER_APP_TASKS_TO_DO_ROUTE,
    });
    if(masterTasksTodoRouteMatch !== null)
      return dispatch(_updateIfOnMasterTasksTodoScreen(
        sheetId,
        getMasterAppDepartmentIdsFromRouteParam(masterTasksTodoRouteMatch.params.departmentIds),
        allTasksTablesIds,
        changedOrderId,
        orderName,
        isOrderCompleted,
        currentPathname,
        entityBatchWasFinished,
      ));


    //Если находимся в разделе "Рабочий. Просмотр задания для класса РЦ в подразделении"
    const tasksForEquipmentClassInDepartmentRouteMatch = matchPath(currentPathname, {
      path: `${WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE}/:departmentId/:equipmentClassId`,
    });
    if(tasksForEquipmentClassInDepartmentRouteMatch !== null) {
      const {
        departmentId,
        equipmentClassId,
      } = tasksForEquipmentClassInDepartmentRouteMatch.params;

      return dispatch(_updateIfOnTasksViewScreen(
        sheetId,
        departmentId,
        equipmentClassId,
        allTasksTablesIds,
        changedOrderId,
        orderName,
        isOrderCompleted,
        entityBatchWasFinished,
      ));
    }

    //Если находимся в разделе "Рабочий. Просмотр ВСЕХ заданий"
    const workerAppTasksRouteMatch = matchPath(currentPathname, {
      path: WORKER_APP_TASKS_ROUTE,
    });
    if(workerAppTasksRouteMatch !== null) {
      return dispatch(_updateIfOnWorkerAppTasksScreen(
        sheetId,
        allTasksTablesIds,
        changedOrderId,
        orderName,
        isOrderCompleted,
        entityBatchWasFinished,
      ));
    }


    /*
    * Для всех остальных разделов не нужна какая-то дополнительная обработка, но и для этого случая необходимо
    * очистить данные всех интерфейсов, где должны быть обновления из-за возвращения МЛ в работу, чтобы при следующем
    * входе в эти разделы данные были запрошены заново
    * */

    const commonActionsToDispatch = [
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.INAPPROPRIATE, sheetId)),
    ];

    if (entityBatchWasFinished) {
      return dispatch([
        clearTableRemoteData([
          SHEET_TYPE.COMPLETED,
          SHEET_TYPE.INAPPROPRIATE,
          SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        ]),
        deleteAllAssemblySheetsReserveData(),
        deleteAssemblySheetConsumeData({ sheetId }),
        clearAllDefaultSheetsPartsAndMaterialsToConsume(),
        ...getOrderCompletedRelatedActions(isOrderCompleted, changedOrderId),
        ...commonActionsToDispatch,
      ]);
    }

    dispatch([
      clearTableRemoteData([
        SHEET_TYPE.INAPPROPRIATE,
        SHEET_TYPE.IN_PRODUCTION,
        MASTER_TASKS_TO_DO_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),
      clearAllDepartmentsWithTasks(),
      clearAllEquipmentClassesInDepartmentWithTask(),
      ...commonActionsToDispatch,
    ]);
  };

/*
* Если находимся в разделе "Контроль. Несоответствующая продукция", то очищаем все данные других интерфейсов кроме этого, где должны
* быть обновления из-за возвращения МЛ в работу, а для этого раздела перезапрашиваем данные списка для текущей
* страницы.
* Дополнительно, если находимся на экране просмотра МЛ, который был возвращён в работу, то закрываем окно просмотра этого
* МЛ и выполняем редирект на экран "Несоответствующая продукция"
* */
const _updateIfOnInappropriateSheetsScreen = (
  sheetId,
  allTasksTablesIds,
  changedOrderId,
  orderName,
  isOrderCompleted,
  currentPathname,
  sheetIdentity,
  entityBatchWasFinished,
) =>
  dispatch => {

    dispatch(reFetchRemoteTableData(
      SHEET_TYPE.INAPPROPRIATE,
      SHEET_TYPE.INAPPROPRIATE,
      fetchSheetTypeRemoteTableDataCbFactory(
        dispatch,
        query => fetchInappropriateSheets(query, { isBlockingRequest: false }),
      ),
    ));

    const commonActionsToDispatch = [
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.INAPPROPRIATE, sheetId)),
    ];
    const inappropriateSheetsSheetReviewRouteMatch = matchPath(currentPathname, {
      path: `${QUALITY_CONTROL_INAPPROPRIATE_SHEETS_ROUTE}/:sheetId`,
    });

    // если находимся на экране просмотра партии, которую вернули в работу, то закрываем окно просмотра партии
    if (inappropriateSheetsSheetReviewRouteMatch !== null) {
      const sheetIdFromRoute = _get(inappropriateSheetsSheetReviewRouteMatch, ['params', 'sheetId']);

      if (String(sheetIdFromRoute) === String(sheetId)) {
        sendSheetInappropriateStateUnconfirmedNotification(sheetIdentity);
        dispatch(push(QUALITY_CONTROL_INAPPROPRIATE_SHEETS_ROUTE));
      }
    }

    if (entityBatchWasFinished) {
      return dispatch([
        clearTableRemoteData([
          SHEET_TYPE.COMPLETED,
          SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        ]),
        deleteAllAssemblySheetsReserveData(),
        deleteAssemblySheetConsumeData({ sheetId }),
        clearAllDefaultSheetsPartsAndMaterialsToConsume(),
        ...getOrderCompletedRelatedActions(isOrderCompleted, changedOrderId, orderName),
        ...commonActionsToDispatch,
      ]);
    }

    dispatch([
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        MASTER_TASKS_TO_DO_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),
      clearAllDepartmentsWithTasks(),
      clearAllEquipmentClassesInDepartmentWithTask(),
      ...commonActionsToDispatch,
    ]);
  };


/*
* Если находимся в разделе "Плановик. В производстве", то очищаем все данные других интерфейсов кроме этого, где должны
* быть обновления из-за возвращения МЛ в работу, а для этого раздела перезапрашиваем данные списка для текущей
* страницы
* */
const _updateIfOnSheetsInProductionScreen = (
  sheetId,
  allTasksTablesIds,
  changedOrderId,
  orderName,
  isOrderCompleted,
  entityBatchWasFinished,
) => dispatch => {

  const commonActionsToDispatch = [
    clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.INAPPROPRIATE, sheetId)),
  ];

  if (entityBatchWasFinished) {
    return dispatch([
      clearTableRemoteData([
        SHEET_TYPE.COMPLETED,
        SHEET_TYPE.INAPPROPRIATE,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      ]),
      deleteAllAssemblySheetsReserveData(),
      deleteAssemblySheetConsumeData({ sheetId }),
      clearAllDefaultSheetsPartsAndMaterialsToConsume(),
      ...getOrderCompletedRelatedActions(isOrderCompleted, changedOrderId),
      ...commonActionsToDispatch,
    ]);
  }

  // Перезапрашиваем данные таблицы "В производстве", только если партия не завершена
  dispatch(reFetchRemoteTableData(
    SHEET_TYPE.IN_PRODUCTION,
    SHEET_TYPE.IN_PRODUCTION,
    fetchSheetTypeRemoteTableDataCbFactory(
      dispatch,
      query => fetchSheetsInProduction(query, { isBlockingRequest: false }),
    ),
  ));

  dispatch([
    clearTableRemoteData([
      SHEET_TYPE.INAPPROPRIATE,
      MASTER_TASKS_TO_DO_TABLE_ID,
      SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      TASKS_MODEL,
      ...allTasksTablesIds,
    ]),
    clearAllDepartmentsWithTasks(),
    clearAllEquipmentClassesInDepartmentWithTask(),
    ...commonActionsToDispatch,
  ]);
};


/*
* Если находимся в разделе "Плановик. Завершенные МЛ", то очищаем все данные других интерфейсов кроме этого, где должны
* быть обновления из-за возвращения МЛ в работу, а для этого раздела перезапрашиваем данные списка для текущей
* страницы
* */
const _updateIfOnCompletedSheetsScreen = (
  sheetId,
  allTasksTablesIds,
  changedOrderId,
  orderName,
  isOrderCompleted,
  entityBatchWasFinished,
) => dispatch => {

  const commonActionsToDispatch = [
    clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.INAPPROPRIATE, sheetId)),
  ];

  if (entityBatchWasFinished) {

    // Выполняем перезапрос данных таблицы "Завершённые МЛ", только если партия была завершена
    dispatch(reFetchRemoteTableData(
      SHEET_TYPE.COMPLETED,
      SHEET_TYPE.COMPLETED,
      fetchSheetTypeRemoteTableDataCbFactory(
        dispatch,
        query => fetchCompletedSheets(query, { isBlockingRequest: false }),
      ),
    ));

    return dispatch([
      clearTableRemoteData([
        SHEET_TYPE.INAPPROPRIATE,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      ]),
      deleteAllAssemblySheetsReserveData(),
      deleteAssemblySheetConsumeData({ sheetId }),
      clearAllDefaultSheetsPartsAndMaterialsToConsume(),
      ...getOrderCompletedRelatedActions(isOrderCompleted, changedOrderId),
      ...commonActionsToDispatch,
    ]);
  }

  dispatch([
    clearTableRemoteData([
      SHEET_TYPE.INAPPROPRIATE,
      SHEET_TYPE.IN_PRODUCTION,
      MASTER_TASKS_TO_DO_TABLE_ID,
      SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      TASKS_MODEL,
      ...allTasksTablesIds,
    ]),
    clearAllDepartmentsWithTasks(),
    clearAllEquipmentClassesInDepartmentWithTask(),
    ...commonActionsToDispatch,
  ]);
};


/*
* Если находимся в разделе "Плановик. Заказы в производстве", то очищаем все данные других интерфейсов кроме этого, где должны
* быть обновления из-за возвращения МЛ в работу, а для этого раздела перезапрашиваем данные списка для текущей
* страницы
* */
const _updateIfOnOrdersInProductionScreen = (
  sheetId,
  currentPathname,
  allTasksTablesIds,
  changedOrderId,
  orderName,
  isOrderCompleted,
  entityBatchWasFinished,
) => dispatch => {

  const commonActionsToDispatch = [
    clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.INAPPROPRIATE, sheetId)),
  ];


  dispatch(clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.INAPPROPRIATE, sheetId)));

  if (!entityBatchWasFinished) {
    return dispatch([
      clearTableRemoteData([
        SHEET_TYPE.INAPPROPRIATE,
        SHEET_TYPE.IN_PRODUCTION,
        MASTER_TASKS_TO_DO_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),
      clearAllDepartmentsWithTasks(),
      clearAllEquipmentClassesInDepartmentWithTask(),
      ...commonActionsToDispatch,
    ]);
  }


  dispatch([
    clearTableRemoteData([
      SHEET_TYPE.COMPLETED,
      SHEET_TYPE.INAPPROPRIATE,
      SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
    ]),
    deleteAllAssemblySheetsReserveData(),
    deleteAssemblySheetConsumeData({ sheetId }),
    clearAllDefaultSheetsPartsAndMaterialsToConsume(),
    ...commonActionsToDispatch,
  ]);

  if (!isOrderCompleted) return;

  // если заказ завершился, то:
  // перезапрашиваем данные таблицы заказов в производстве
  dispatch(reFetchRemoteTableData(
    ORDER_TYPE.IN_PRODUCTION,
    ORDER_TYPE.IN_PRODUCTION,
    fetchOrderRemoteTableDataCbFactory(
      dispatch,
      query => fetchOrdersInProduction(query, { isBlockingRequest: false }),
    ),
  ));

  dispatch([
    // сбрасываем кэш таблицы заказов готовых к завершению
    clearTableRemoteData([ORDER_TYPE.READY_TO_COMPLETE]),
  ]);

  // вызываем нотификейшн с предупреждением о том, что заказ готов к завершению
  sendOrderIsReadyToCompleteNotification(orderName);

  /*
  * Для случая, когда находимся на роуте просмотра заказа, который изменил свой статус на "готов к завершению",
  * выполняем редирект к списку заказов в производстве
  * */
  const plannerAppOrderInProductionReviewRouteMatch = matchPath(currentPathname, {
    path: `${PLANNER_APP_ORDERS_IN_PRODUCTION_ROUTE}/:orderId`,
  });

  if(
    plannerAppOrderInProductionReviewRouteMatch !== null &&
    plannerAppOrderInProductionReviewRouteMatch.params.orderId === changedOrderId.toString()
  ) {
    dispatch(push(PLANNER_APP_ORDERS_IN_PRODUCTION_ROUTE));
    // сбрасываем кэш таблицы просмотра заказа в списке заказов в производстве
    return dispatch(clearTableData(createOrderEntriesTableId(ORDER_TYPE.IN_PRODUCTION, changedOrderId)));
  }

  dispatch(clearTableData(createOrderEntriesTableId(ORDER_TYPE.IN_PRODUCTION, changedOrderId)));
};


/*
* Если находимся в разделе "Плановик. Заказы готовые к завершению", то очищаем все данные других интерфейсов кроме
* этого, где должны быть обновления из-за возвращения МЛ в работу, а для этого раздела перезапрашиваем данные списка
* для текущей страницы
* */
const _updateIfOnOrdersReadyToCompleteScreen = (
  sheetId,
  currentPathname,
  allTasksTablesIds,
  changedOrderId,
  orderName,
  isOrderCompleted,
  entityBatchWasFinished,
) =>
  dispatch => {

    const commonActionsToDispatch = [
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.INAPPROPRIATE, sheetId)),
    ];

    if (!entityBatchWasFinished) {
      return dispatch([
        clearTableRemoteData([
          SHEET_TYPE.INAPPROPRIATE,
          SHEET_TYPE.IN_PRODUCTION,
          MASTER_TASKS_TO_DO_TABLE_ID,
          SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
          TASKS_MODEL,
          ...allTasksTablesIds,
        ]),
        clearAllDepartmentsWithTasks(),
        clearAllEquipmentClassesInDepartmentWithTask(),
        ...commonActionsToDispatch,
      ]);
    }

    dispatch([
      clearTableRemoteData([
        SHEET_TYPE.COMPLETED,
        SHEET_TYPE.INAPPROPRIATE,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      ]),
      deleteAllAssemblySheetsReserveData(),
      deleteAssemblySheetConsumeData({ sheetId }),
      clearAllDefaultSheetsPartsAndMaterialsToConsume(),
      ...commonActionsToDispatch,
    ]);

    if(isOrderCompleted) {
      sendOrderIsReadyToCompleteNotification(orderName);

      // перезапрашиваем данные таблицы заказов готовых к завершению
      dispatch(reFetchRemoteTableData(
        ORDER_TYPE.READY_TO_COMPLETE,
        ORDER_TYPE.READY_TO_COMPLETE,
        fetchOrderRemoteTableDataCbFactory(
          dispatch,
          query => fetchOrdersReadyToComplete(query, { isBlockingRequest: false }),
        ),
      ));

      dispatch([
        // сбрасываем кэш таблицы просмотра содержимого заказа в производстве
        clearTableData(createOrderEntriesTableId(ORDER_TYPE.IN_PRODUCTION, changedOrderId)),
        // сбрасываем кэш таблицы заказов в производстве
        clearTableRemoteData([ORDER_TYPE.IN_PRODUCTION]),
      ]);
    }

  };


/*
* Если находимся в разделе "Комлектование - стандартные МЛ", то очищаем данные других интерфейсов кроме этого, где должны
* быть обновления из-за завершения МЛ. А для текущего интерфейса выполняем перезапрос данныех таблицы, только если партия
* при возвращении в работу не была завершена.
* Данные комплектации обновляем, только если партия была завершена
* */
const _updateIfOnDefaultSheetsWaitingPartsAndMaterialsScreen = (
  sheetId,
  currentPathname,
  allTasksTablesIds,
  changedOrderId,
  orderName,
  isOrderCompleted,
  entityBatchWasFinished,
) =>
  dispatch => {

    const commonActionsToDispatch = [
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.INAPPROPRIATE, sheetId)),
    ];

    if (!entityBatchWasFinished) {
      return dispatch([
        clearTableRemoteData([
          SHEET_TYPE.INAPPROPRIATE,
          SHEET_TYPE.IN_PRODUCTION,
          MASTER_TASKS_TO_DO_TABLE_ID,
          SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
          TASKS_MODEL,
          ...allTasksTablesIds,
        ]),
        clearAllDepartmentsWithTasks(),
        clearAllEquipmentClassesInDepartmentWithTask(),
        ...commonActionsToDispatch,
      ]);
    }

    // перезапрашиваем данные комплектации, только если партия была завершена
    dispatch(fetchDefaultSheetsWithEnoughPartsAndMaterialsInStorage({ isBlockingRequest: false }));


    dispatch([
      clearTableRemoteData([
        SHEET_TYPE.COMPLETED,
        SHEET_TYPE.INAPPROPRIATE,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      ]),
      deleteAllAssemblySheetsReserveData(),
      deleteAssemblySheetConsumeData({ sheetId }),
      ...getOrderCompletedRelatedActions(isOrderCompleted, changedOrderId),
      ...commonActionsToDispatch,
    ]);

    const defaultSheetWaitingPartsAndMaterialsReviewRouteMatch = matchPath(currentPathname, {
      path: `${STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE}/:sheetId`,
    });

    if(defaultSheetWaitingPartsAndMaterialsReviewRouteMatch === null) {
      // если не находимся на экране резервирования, то очищаем все данные резервирования для стандартных МЛ
      return dispatch(clearAllDefaultSheetsPartsAndMaterialsToConsume());
    }

    const {
      sheetId: sheetIdFromRoute,
    } = defaultSheetWaitingPartsAndMaterialsReviewRouteMatch.params;

    // Если находимся на экране резервирования, то очищаем все данные резервирования, кроме данных МЛ, который
    // просмотриваем, а для текущего делаем перезапрос
    dispatch(clearDefaultSheetsPartsAndMaterialsToConsumeWithExceptionOfGivenIds([sheetIdFromRoute]));

    //Т.к. при броадкастинге нам, в общем случае, в обработчике неизвестны параметры просматриваемого МЛ (сейчас
    //они в entities, но таблица должна стать серверной и данные по просматриваемому МЛ будут храниться в локальном
    //state), то придется запросить его, чтобы получить идентификатор партии для перезапроса детальной комплектации
    const fetchSheet = fetchSheetTypeToReviewCbFactory(
      dispatch,
      query => fetchSheetsInProduction(query, { isBlockingRequest: false }),
    );

    fetchSheet(sheetIdFromRoute)
      .then(response => {

        if(!response || response.responseMeta.count === 0) return;

        const { entityBatchId } = response.entities[SHEET_MODEL][sheetIdFromRoute];

        dispatch(fetchPossiblePartsAndMaterialsToConsumeForDefaultSheet(
          entityBatchId,
          sheetIdFromRoute,
          { isBlockingRequest: false },
        ));
      });
  };


const _updateIfOnAssemblySheetsWaitingPartsAndMaterialsScreen = (
  sheetId,
  currentPathname,
  allTasksTablesIds,
  changedOrderId,
  orderName,
  isOrderCompleted,
  entityBatchWasFinished,
) =>
  dispatch => {

    dispatch(reFetchRemoteTableData(
      SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      fetchSheetTypeRemoteTableDataCbFactory(
        dispatch,
        query => fetchFullAssemblySheetsPartsAndMaterials(query, { isBlockingRequest: false }),
      ),
    ));

    const commonActionsToDispatch = [
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.INAPPROPRIATE, sheetId)),
    ];

    // если партия не была авершена, то просто выполняем очистку данных нужных интерфейсов
    if (!entityBatchWasFinished) {
      return dispatch([
        clearTableRemoteData([
          SHEET_TYPE.INAPPROPRIATE,
          SHEET_TYPE.IN_PRODUCTION,
          MASTER_TASKS_TO_DO_TABLE_ID,
          TASKS_MODEL,
          ...allTasksTablesIds,
        ]),
        clearAllDepartmentsWithTasks(),
        clearAllEquipmentClassesInDepartmentWithTask(),
        ...commonActionsToDispatch,
      ]);
    }

    // если же партия была завершена, то выполняем необходимые очистки и делаем перезапрос данных
    // экрана резервирования, если находимся на нём
    dispatch([
      clearTableRemoteData([
        SHEET_TYPE.COMPLETED,
        SHEET_TYPE.INAPPROPRIATE,
      ]),
      deleteAssemblySheetConsumeData({ sheetId }),
      clearAllDefaultSheetsPartsAndMaterialsToConsume(),
      ...getOrderCompletedRelatedActions(isOrderCompleted, changedOrderId),
      ...commonActionsToDispatch,
    ]);

    const partsAndMaterialsReserveRouteMatch = matchPath(currentPathname, {
      path: `${STORAGE_MANAGEMENT_APP_ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE}/:sheetId`,
    });

    // если находимся НЕ на экране резервирвоания, то удаляем все данные резервирования из store
    if (partsAndMaterialsReserveRouteMatch === null) {
      return dispatch(deleteAllAssemblySheetsReserveData());
    }

    const currentSheetId = partsAndMaterialsReserveRouteMatch.params.sheetId;

    dispatch(reFetchReserveDataForAssemblySheetWaitingPartAndMaterialsReviewScreen(currentSheetId, { isBlockingRequest: false }))
      .then(() => dispatch(deleteAllAssemblySheetsReserveData([currentSheetId])));
  };



/*
* Если находимся в разделе просмотра заданий "Мастер. Требуется выполнить", то очищаем все данные других интерфейсов
* кроме этого, где должны быть обновления из-за возвращения МЛ в работу, а для этого раздела перезапрашиваем данные для
* текущей страницы таблицы
* */
const _updateIfOnMasterTasksTodoScreen = (
  sheetId,
  departmentIds,
  allTasksTablesIds,
  changedOrderId,
  orderName,
  isOrderCompleted,
  currentPathname,
  entityBatchWasFinished,
) =>
  (dispatch, getState) => {

    const masterTasksToDoAdditionalFilters = masterTasksToDoAdditionalFiltersSelector(getState());

    const commonActionsToDispatch = [
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.INAPPROPRIATE, sheetId)),
    ];

    if (entityBatchWasFinished) {
      return dispatch([
        clearTableRemoteData([
          SHEET_TYPE.COMPLETED,
          SHEET_TYPE.INAPPROPRIATE,
          SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        ]),
        deleteAllAssemblySheetsReserveData(),
        deleteAssemblySheetConsumeData({ sheetId }),
        clearAllDefaultSheetsPartsAndMaterialsToConsume(),
        ...getOrderCompletedRelatedActions(isOrderCompleted, changedOrderId),
        ...commonActionsToDispatch,
      ]);
    }

    // Выполняем перезапрос таблицы "Мастер. Нужно сделать", только если партия не была завершена
    dispatch(reFetchRemoteTableData(
      MASTER_TASKS_TO_DO_TABLE_ID,
      MASTER_TASKS_TO_DO_TABLE_MODEL,
      ({ tableParams }) =>
        dispatch(fetchMasterDepartmentsTasksToDoRemoteTableData(
          departmentIds,
          masterTasksToDoAdditionalFilters,
          tableParams,
          { isBlockingRequest: false },
        )),
    ));

    dispatch([
      clearTableRemoteData([
        SHEET_TYPE.INAPPROPRIATE,
        SHEET_TYPE.IN_PRODUCTION,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),
      clearAllDepartmentsWithTasks(),
      clearAllEquipmentClassesInDepartmentWithTask(),
      ...commonActionsToDispatch,
    ]);
  };



/*
* Если находимся в разделе "Рабочий. Просмотр задания для класса РЦ в подразделении", то очищаем все данные других
* интерфейсов кроме этого, где должны быть обновления из-за возвращения МЛ в работу, а для этого раздела перезапрашиваем
* задания для текущих параметров просматриваемой таблицы, чтобы информация обновилась
* */
const _updateIfOnTasksViewScreen = (
  sheetId,
  departmentIdFromRoute,
  equipmentClassIdFromRoute,
  allTasksTablesIds,
  changedOrderId,
  orderName,
  isOrderCompleted,
  entityBatchWasFinished,
) =>
  dispatch => {

    const currentTableId = getEquipmentClassInDepartmentTasksTableId(
      departmentIdFromRoute,
      equipmentClassIdFromRoute,
    );

    //Текущую таблицу не очищаем, чтобы не было скачков в интерфейсе, данные в ней обновятся при перезапросе данных
    const allTasksTablesIdsWithoutCurrentTableId = allTasksTablesIds
      .filter(tableId => tableId !== currentTableId);

    const commonActionsToDispatch = [
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.INAPPROPRIATE, sheetId)),
    ];

    if (entityBatchWasFinished) {
      return dispatch([
        clearTableRemoteData([
          SHEET_TYPE.COMPLETED,
          SHEET_TYPE.INAPPROPRIATE,
          SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        ]),
        deleteAllAssemblySheetsReserveData(),
        deleteAssemblySheetConsumeData({ sheetId }),
        clearAllDefaultSheetsPartsAndMaterialsToConsume(),
        ...getOrderCompletedRelatedActions(isOrderCompleted, changedOrderId),
        ...commonActionsToDispatch,
      ]);
    }

    // Выполняем перезапрос данных таблицы заданий, только если партия не была завершена
    dispatch(reFetchRemoteTableData(
      currentTableId,
      currentTableId,
      ({ tableParams }) =>
        dispatch(fetchEquipmentClassInDepartmentTasksRemoteTableData(
          {
            departmentIdsArray: [departmentIdFromRoute],
            equipmentClassIdsArray: [equipmentClassIdFromRoute],
          },
          tableParams,
          { isBlockingRequest: false },
        )),
    ));

    dispatch([
      clearTableRemoteData([
        SHEET_TYPE.INAPPROPRIATE,
        SHEET_TYPE.IN_PRODUCTION,
        MASTER_TASKS_TO_DO_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        TASKS_MODEL,
        ...allTasksTablesIdsWithoutCurrentTableId,
      ]),
      clearAllDepartmentsWithTasks(),
      clearAllEquipmentClassesInDepartmentWithTask(),
      ...commonActionsToDispatch,
    ]);
  };


/*
* Если находимся в разделе просмотра заданий "Рабочий. Просмотр всех заданий", то очищаем все данные других интерфейсов
* кроме этого, где должны быть обновления из-за возвращения МЛ в работу, а для этого раздела перезапрашиваем данные для
* текущей страницы таблицы
* */
const _updateIfOnWorkerAppTasksScreen = (
  sheetId,
  allTasksTablesIds,
  changedOrderId,
  orderName,
  isOrderCompleted,
  entityBatchWasFinished,
) =>
  dispatch => {

    const commonActionsToDispatch = [
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.INAPPROPRIATE, sheetId)),
    ];

    if (entityBatchWasFinished) {
      return dispatch([
        clearTableRemoteData([
          SHEET_TYPE.COMPLETED,
          SHEET_TYPE.INAPPROPRIATE,
          SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        ]),
        deleteAllAssemblySheetsReserveData(),
        deleteAssemblySheetConsumeData({ sheetId }),
        clearAllDefaultSheetsPartsAndMaterialsToConsume(),
        ...getOrderCompletedRelatedActions(isOrderCompleted, changedOrderId),
        ...commonActionsToDispatch,
      ]);
    }

    // Делаем перезапрос данных таблицы, только если возвращаемая в работу партия не завершена
    dispatch(reFetchRemoteTableData(
      TASKS_MODEL,
      TASKS_MODEL,
      ({ tableParams }) =>
        dispatch(fetchTasksRemoteTableData(tableParams, { isBlockingRequest: false })),
    ));

    dispatch([
      clearTableRemoteData([
        SHEET_TYPE.INAPPROPRIATE,
        SHEET_TYPE.IN_PRODUCTION,
        MASTER_TASKS_TO_DO_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        ...allTasksTablesIds,
      ]),
      clearAllDepartmentsWithTasks(),
      clearAllEquipmentClassesInDepartmentWithTask(),
      ...commonActionsToDispatch,
    ]);
  };