import { SET_TASKS_SEARCH_HISTORY } from './actions';


export const TASKS_SEARCH_HISTORY_INITIAL_STATE = {};

export const tasksSearchHistory = (
  state = { ...TASKS_SEARCH_HISTORY_INITIAL_STATE },
  action,
) => {
  switch(action.type) {
  case SET_TASKS_SEARCH_HISTORY:
    return action.tasksSearchHistory;
  default:
    return state;
  }
};
