import React, { Component } from 'react';
import { Trans } from '@lingui/macro';
import {
  ADDITIONAL_ACTIONS_MENU_OPTIONS,
  CONSUME_ENTITIES_BUTTON,
  OPERATION_DETAILS_FIRST_BLOCK_SUMMARY_SCHEMA,
  OPERATION_DETAILS_INFO_GRID_PROPS,
  OPERATION_DETAILS_SECOND_BLOCK_SUMMARY_SCHEMA,
  SHEET_OPERATION_STATUS_CHANGE_DIALOG_VIEW_MODES,
  STATUS_CHANGE_ACTION_BUTTONS_FOR_CURRENT_STATUS_MAP,
} from './constants';
import { SimpleSummary } from '../SimpleSummary/SimpleSummary';

import { SheetOperationTransactionsTableContainer }
  from '../../Sheets/SheetOperationTransactionsTable/SheetOperationTransactionsTableContainer';
import cn from 'classnames';
import Dialog from '@mui/material/Dialog';

import Grid from '@mui/material/Grid';

import {
  MATERIAL_UI_DIALOG_MAX_WIDTH,
  MATERIAL_UI_SIZE,
  MATERIAL_UI_STYLE_COLOR,
  MATERIAL_UI_VARIANT,
} from '../../../constants/materialUI';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

import './style.css';
import { PERMISSION } from '../../../hoc/withPermissionsManager/constants';

import _isFunction from 'lodash/isFunction';
import _isNil from 'lodash/isNil';
import { SimpleHelpAlert } from '../SimpleHelpAlert/SimpleHelpAlert';
import { SheetOperationStatusChangeDialog } from './SheetOperationStatusChangeDialog/SheetOperationStatusChangeDialog';
import { PauseSheetDialog } from './PauseSheetDialog/PauseSheetDialog';
import { SheetOperationAssigneesSection } from './SheetOperationAssigneesSection/SheetOperationAssigneesSection';
import { SheetOperationEquipmentSection } from './SheetOperationEquipmentSection/SheetOperationEquipmentSection';
import { SheetOperationNoteSection } from './SheetOperationNoteSection/SheetOperationNoteSection';
import { addSocketMessageListener } from '../../../api/socketApi/index';
import {
  CA_CLIENT_SOCKET_MESSAGE_TYPE, DEFECTIVE_ENTITIES_MARKED_EVENT_TYPE,
  ENTITY_BATCH_SPLIT_EVENT_TYPE,
  PARTS_AND_MATERIALS_FOR_ASSEMBLY_SHEET_CONSUMED_EVENT_TYPE,
  PARTS_AND_MATERIALS_FOR_ASSEMBLY_SHEET_RESERVED_EVENT_TYPE,
  SHEET_FINISHED_EVENT_TYPE,
  SHEET_OPERATION_DATA_CHANGED_EVENT_TYPE,
  SHEET_OPERATION_FEATURES_VALUES_CHANGED_EVENT_TYPE,
  SHEET_OPERATION_STATUS_CHANGED_EVENT_TYPE,
  SHEET_PAUSED_EVENT_TYPE,
  SYSTEM_MESSAGES_SOCKET_IDENTITY,
} from '../../../constants/sockets';

import { SplitEntityBatchDialog } from './SplitEntityBatchDialog/SplitEntityBatchDialog';
import { ConsumeEntitiesDialog } from './ConsumeEntitiesDialog/ConsumeEntitiesDialog';
import { SHEET_OPERATION_REVIEW_DIALOG_PROP_TYPES } from './propTypes';
import { AdditionalActionsDropdown } from './AdditionalActionsDropdown/AdditionalActionsDropdown';
import {
  SHEET_OPERATION_STATUS,
} from '../../../constants/sheets';

import { CollapsibleContainer } from '../Collapsible/CollapsibleContainer';
import { NOTIFICATION_LEVEL, sendNotification } from '../../../constants/notification';
import { getSheetOperationCombinedName } from '../../../utils/stringBuilder';
import { AppConfirmContext } from '../../AppConfirm/AppConfirmContext';
import Chip from '@mui/material/Chip';
import { isAlwaysFalseCb } from '../../../constants/common';
import {
  AssigneesLabelTrans,
  CommentLabelTrans,
  DetailsLabelTrans,
  EquipmentLabelTrans,
  HistoryLabelTrans,
  SheetOperationFeaturesLabelTrans,
} from '../../../utils/commonTransComponents';
import {
  SheetOperationFeaturesValuesFormContainer,
} from './SheetOperationFeaturesValuesForm/SheetOperationFeaturesValuesFormContainer';
import _isEmpty from 'lodash/isEmpty';


const SUMMARY_INFO_ROWS_DELIMITER = '';
const SHEET_OPERATION_REVIEW_COLLAPSIBLE_IDS = {
  DETAILS: 'sheetOperationReviewDetails',
  ASSIGNEES: 'sheetOperationReviewAssignees',
  EQUIPMENT: 'sheetOperationReviewEquipment',
  NOTE: 'sheetOperationReviewNote',
  HISTORY: 'sheetOperationReviewHistory',
  FEATURES: 'sheetOperationFeatures',
};


export class SheetOperationReviewDialog extends Component {

  static propTypes = SHEET_OPERATION_REVIEW_DIALOG_PROP_TYPES

  constructor(props) {
    super(props);

    const {
      sheetOperationData: {
        status,
        assignees,
        equipmentId,
        equipmentIdentity,
        equipmentName,
        note,
        progress,
      },
    } = props;

    /*
    * Подробный комментарий почему состояние модальника потребления isConsumeEntitiesDialogOpen находится в store
    * тогда, как все остальные параметры в локальном state, представлен в reducers/sheetOperationReview
    * */
    this.state = {
      sheetOperationStatusChangeDialogViewMode: null,
      additionalActionsMenuAnchorEl: null,

      /*
      * Пока что до конца не понятно, как правильней будет реализовать редактирование данных в текущем окне просмотра
      * операции. Ранее мы могли только менять статус операци, что приводило к закрытию модальника просмотра
      * операции, поэтому после повторного открытия в модальник просмотра передавался новый пропс sheetOperationData
      * и мы видил обновленные данные. С появлением функционала назначения исполнителей, оборудования и комментария,
      * при редактировании данных модальное окно не закрывается, поэтому измененные данные нужно где-то фиксировать.
      * Наверно, по хорошему, данные sheetOperationData должны все храниться здесь в локальном стейте и просто
      * обновляться, когда нужно. С другой стороны нам важно обновить не только локальные данные, но и некоторые
      * другие после обновления данных на сервере (например, табличные данные). Что делать в этом случае не очень
      * понятно, т.к. у нас и текущие данные, которые поступают сюда пропсом sheetOperationData в компоненте по иерархии
      * выше сейчас тоже хранятся в локальном стейте, т.к. мы должны как-то зафиксировать, что модальное окно
      * просмотра операции открыто и туда передать эти пропсы из локльного стейта. Получается много локальных стейтов,
      * которые начинают дублировать друг друга, нет одного источникаи есть понятные проблемы с их обновлением, т.к.
      * тогда весь локальный стейт нужно обновлять. При этом, как указывалось ранее ещё и нужно обновлять другие
      * данные при изменении локальных данных в модальнике.
      *
      * В таких случаях стейт логично переносить вверх по дереву и, видимо, в этом случае придется выносить его,
      * вообще, в самый верх, т.е. в редакс store. Возможно нужен будет даже какой-то HOC или контекст, а модальник
      * должен стать общим, в который просто нужно передавать начальные данные формы и колбэки для обновления данных,
      * тогда будет проще. При этом, есть ещё идеи перенести в будущем просмотр операции совсем из модальника на
      * отдельный роут или что-то типа того. До конца реализация этого тоже непонятна. ПОЭТОМУ,сейчас для быстроты
      * реализации, здесь создается ещё один локальный стейт для данных, которые изменяются в модальнике
      * (статус, прогресс, исполнитель, оборудования, комментарий - хотя про комментарий хз, какая будет реализация,
      * в итоге).
      * В случае изменения мы сохраняем данные в этом локальном стейте для обновления в модальнике + вызываем все
      * обработчики по которым должны обновляться все остальные данные. Различные промежуточные локальные стейты в
      * данный момент обновлены не будут, что нужно иметь в виду. Когда-то это нужно будет переделать на более
      * правильный вариант
      * */
      sheetOperationEditableFieldsData: {
        status,
        assignees,
        equipment: equipmentId === null ?
          null :
          ({
            id: equipmentId,
            identity: equipmentIdentity,
            name: equipmentName,
          }),
        note,
        progress,
      },

      isSheetPausing: false,
      isEntityBatchSplitting: false,
    };
  }

  /*
  * Общий комментарий для componentDidMount, componentWillUnmount и _sheetOperationUpdatedEventHandler:
  * Из-за текущей реализации с множеством локальных стейтов, про которые подробно описано в большом комментарии в
  * конструкторе проблематично реализовывать обновление данных при броадкастинге. У нас глобальные обработчики
  * для событий броадкастинга, в которых нет данных о локальных стейтах компонента. Поэтому пока нашлось решение для
  * таких компонентов добавлять для сокета дополнительный обработчик прямо в компоненте (ВАЖНО УДАЛЯТЬ ОБРАБОТЧИК
  * НА WILL UNMOUNT). Этот обработчик выполняет только свою частную функцию для текущего компонента - если пришло
  * событие SHEET_OPERATION_DATA_CHANGED_EVENT_TYPE и идентификатор операции равен текущему идентификатору
  * просматриваемой операции, то обновляем данные локального стейта данными из полученного сообщения, т.к. они
  * изменились.
  * Решение временное, просмотр операции МЛ предполагается в будущем выносить на отдельный роут с параметром в роутинге.
  * В этом случае в общих обработчиках будет информация о том, какую операцию просматривают и эту обработку, судя
  * по всему, нужно будет удалить (не забыть удалить и getClientMessagesDataArray, которая сюда пробрасывается из
  * контейнера)
  * */
  componentDidMount() {

    const systemMessageSocketUrl = [
      window.config.WEBSOCKET_SERVER_HOST,
      SYSTEM_MESSAGES_SOCKET_IDENTITY,
    ].join('/');

    this.removeSheetOperationUpdateEventListener = addSocketMessageListener(
      systemMessageSocketUrl,
      this._sheetOperationUpdatedEventHandler,
    );
  }

  componentWillUnmount() {
    _isFunction(this.removeSheetOperationUpdateEventListener) && this.removeSheetOperationUpdateEventListener();
    this._handleConsumeEntitiesDialogClose();
  }

  static contextType = AppConfirmContext

  //см. комментарий к componentDidMount
  _sheetOperationUpdatedEventHandler = message => {
    if(message.type !== CA_CLIENT_SOCKET_MESSAGE_TYPE) return;

    const {
      getClientMessagesDataArray,
      sheetOperationData: {
        sheetOperationId,
      },
    } = this.props;

    getClientMessagesDataArray(message)
      .then(messageDataArr =>
        messageDataArr
          .forEach(({ data, event }) => {
            if(event === SHEET_OPERATION_DATA_CHANGED_EVENT_TYPE && data.sheetOperationId === sheetOperationId) {
              return this._changeSheetOperationEditableFieldsData(data.sheetOperationChangedData);
            }
            if (
              event === PARTS_AND_MATERIALS_FOR_ASSEMBLY_SHEET_RESERVED_EVENT_TYPE ||
              event === PARTS_AND_MATERIALS_FOR_ASSEMBLY_SHEET_CONSUMED_EVENT_TYPE
            ) {
              return this._handleConsumeEntitiesUpdated(event, data);
            }
            if (event === ENTITY_BATCH_SPLIT_EVENT_TYPE) {
              return this._handleEntityBatchSplit(data);
            }
            if (event === SHEET_FINISHED_EVENT_TYPE) {
              return this._handleSheetFinished(data);
            }
            if (event === SHEET_OPERATION_STATUS_CHANGED_EVENT_TYPE) {
              return this._handleSheetOperationStatusChange(data.sheetOperationDataAfterStatusChange);
            }
            if (event === SHEET_PAUSED_EVENT_TYPE) {
              return this._handleSheetPaused(data);
            }
            if (event === DEFECTIVE_ENTITIES_MARKED_EVENT_TYPE) {
              return this._handleDefectiveEntitiesMarked(data);
            }
            if (event === SHEET_OPERATION_FEATURES_VALUES_CHANGED_EVENT_TYPE) {
              return this._handleSheetOperationFeaturesValuesChanged(data);
            }
          }));
  };

  _changeSheetOperationEditableFieldsData = changedEditableFieldsData =>
    this.setState({
      sheetOperationEditableFieldsData: {
        ...this.state.sheetOperationEditableFieldsData,
        ...changedEditableFieldsData,
      },
    });

  /*
  TODO после переноса окна на отдельный роут можно вынести это в общий обработчик, т.к. там будет доступен идентификатор
   просматриваемой операции
  */
  _handleSheetOperationFeaturesValuesChanged = socketMessageData => {
    const {
      sheetOperationId: sheetOperationIdFromSocketMessage,
    } = socketMessageData;
    const {
      sheetOperationData: {
        sheetOperationId,
      },
      fetchSheetOperationFeaturesValuesAndAddToStore,
    } = this.props;

    if(sheetOperationIdFromSocketMessage === sheetOperationId) {
      fetchSheetOperationFeaturesValuesAndAddToStore(sheetOperationId);
    }
  }

  _handleConsumeEntitiesUpdated = (event, socketMessageData) => {

    if(!this.props.isConsumeEntitiesDialogOpen) {
      return;
    }

    const {
      fetchPartAndMaterialsConsumeDataForAssemblyEntityBatch,
      deleteAssemblySheetConsumeData,
      sheetOperationData: {
        entityBatchId,
      },
    } = this.props;

    const {
      sheetId: sheetIdFromSocketMessage,
      sheetIdentity: sheetIdentityFromSocketMessage,
      entityBatchId: entityBatchIdFromSocketMessage,
    } = socketMessageData;

    if (entityBatchId !== entityBatchIdFromSocketMessage) {
      return deleteAssemblySheetConsumeData(sheetIdFromSocketMessage);
    }

    sendNotification(
      this._getConsumeEntitiesUpdatedMessage(event, sheetIdentityFromSocketMessage),
      NOTIFICATION_LEVEL.INFO,
    );
    return fetchPartAndMaterialsConsumeDataForAssemblyEntityBatch(entityBatchIdFromSocketMessage, sheetIdFromSocketMessage);
  }

  _getConsumeEntitiesUpdatedMessage = (event, sheetIdentity) => {

    if(event === PARTS_AND_MATERIALS_FOR_ASSEMBLY_SHEET_RESERVED_EVENT_TYPE) {
      return (
        <Trans id="sheet_operation_consume_entities_dialog@reserve_data_was_updated">
          Для МЛ "{sheetIdentity}" было выполнено резервирование, данные обновились, пожалуйста, проверьте и
          скорректируйте введённые вами количества ДСЕ для потребления
        </Trans>
      );
    }

    if(event === PARTS_AND_MATERIALS_FOR_ASSEMBLY_SHEET_CONSUMED_EVENT_TYPE) {
      return (
        <Trans id="sheet_operation_consume_entities_dialog@consume_data_was_updated">
          Для МЛ "{sheetIdentity}" были потреблены ДСЕ, данные обновились, пожалуйста, проверьте и
          скорректируйте введённые вами количества ДСЕ для потребления
        </Trans>
      );
    }

  }

  _handleEntityBatchSplit = socketMessageData => {
    const {
      closeDialog,
    } = this.props;

    const {
      parentSheetId: parentSheetIdFromSocketMessage,
      sheetIdentity: sheetIdentityFromSocketMessage,
    } = socketMessageData;

    const {
      sheetOperationData: {
        sheetId,
      },
    } = this.props;

    if (sheetId === parentSheetIdFromSocketMessage) {
      closeDialog();
      this._sendSheetSplittedByAnotherUserNotification(sheetIdentityFromSocketMessage);
    }
  }

  _sendSheetSplittedByAnotherUserNotification = sheetIdentity =>
    sendNotification(
      <Trans id="sheet_operation_review@sheet_splitted_by_another_user">
        Партия МЛ "{sheetIdentity}", операцию которого Вы просматривали, была разделена
      </Trans>,
      NOTIFICATION_LEVEL.INFO,
    );

  _handleSheetFinished = socketMessageData => {
    const {
      closeDialog,
    } = this.props;

    const {
      sheetId: sheetIdFromSocketMessage,
      sheetIdentity: sheetIdentityFromSocketMessage,
    } = socketMessageData;

    const {
      sheetOperationData: {
        sheetId,
      },
    } = this.props;

    if (sheetId === sheetIdFromSocketMessage) {
      closeDialog();
      this._sendSheetFinishedByAnotherUserNotification(sheetIdentityFromSocketMessage);
    }
  }

  _sendSheetFinishedByAnotherUserNotification = sheetIdentity =>
    sendNotification(
      <Trans id="sheet_operation_review@sheet_finished_by_another_user">
        МЛ "{sheetIdentity}", операцию которого Вы просматривали, был завершен другим пользователем
      </Trans>,
      NOTIFICATION_LEVEL.INFO,
    );


  _handleSheetOperationStatusChange = sheetOperationDataAfterStatusChange => {
    const {
      sheetId: sheetIdFromSocketMessage,
      operationNumber,
      operationName,
      operationIdentity,
    } = sheetOperationDataAfterStatusChange;

    const {
      sheetOperationData: {
        sheetId,
        sheetOperationId,
      },
      closeDialog,
      fetchOperationData,
      isConsumeEntitiesDialogOpen,
    } = this.props;

    // Если операция из другого МЛ, то ничего не делаем
    if (sheetId !== sheetIdFromSocketMessage) return;

    /*
    * Если операция из того же самого МЛ, то сейчас происходит или изменение её статуса, или изменения статуса
    * одной из следующих операций. Проверяем статус текущей операции запросом на сервер и
    * - закрываем модальник просмотра операция, если её новый статус "Завершена"
    * - закрываем модальник потребления, если её новый статус "Остановлена"
    * */
    return fetchOperationData(sheetOperationId)
      .then(sheetOperation => {

        /*
        * Это какой-то экстренный случай, когда  удалось получить данные по операции запросом - просто закрываем
        * модальник
        * */
        if(sheetOperation === null) {
          closeDialog();
          return;
        }

        const { status, progress } = sheetOperation;

        if(status === SHEET_OPERATION_STATUS.FINISHED) {
          closeDialog();
          this._sendOperationFinishedByAnotherUserNotification(
            getSheetOperationCombinedName(operationNumber, operationName, operationIdentity),
          );
          return;
        }

        if(status === SHEET_OPERATION_STATUS.PAUSED) {

          this._sendOperationPausedByAnotherUserNotification(
            getSheetOperationCombinedName(operationNumber, operationName, operationIdentity),
          );

          if(isConsumeEntitiesDialogOpen) {
            this._handleConsumeEntitiesDialogClose();
          }
        }

        this.setState({
          sheetOperationEditableFieldsData: {
            ...this.state.sheetOperationEditableFieldsData,
            status,
            progress,
          },
        });
      });
  }

  _sendOperationFinishedByAnotherUserNotification = sheetOperationCombinedName =>
    sendNotification(
      <Trans id="sheet_operation_review@sheet_operation_finished_by_another_user">
        Просматриваемая Вами операция "{sheetOperationCombinedName}" была завершена другим пользователем
      </Trans>,
      NOTIFICATION_LEVEL.INFO,
    );

  _sendOperationPausedByAnotherUserNotification = sheetOperationCombinedName =>
    sendNotification(
      <Trans id="sheet_operation_review@sheet_operation_paused_by_another_user">
        Просматриваемая Вами операция "{sheetOperationCombinedName}" была остановлена другим пользователем
      </Trans>,
      NOTIFICATION_LEVEL.INFO,
    );

  _handleSheetPaused = socketMessageData => {
    const {
      closeDialog,
    } = this.props;

    const {
      sheetId: sheetIdFromSocketMessage,
      sheetIdentity: sheetIdentityFromSocketMessage,
    } = socketMessageData;

    const {
      sheetOperationData: {
        sheetId,
      },
    } = this.props;

    if (sheetId === sheetIdFromSocketMessage) {
      closeDialog();
      this._sendSheetPausedByAnotherUserNotification(sheetIdentityFromSocketMessage);
    }
  }

  _sendSheetPausedByAnotherUserNotification = sheetIdentity =>
    sendNotification(
      <Trans id="sheet_operation_review@sheet_paused_by_another_user">
        МЛ "{sheetIdentity}", операцию которого Вы просматривали, был остановлен другим пользователем
      </Trans>,
      NOTIFICATION_LEVEL.INFO,
    );

  _handleDefectiveEntitiesMarked = socketMessageData => {
    const {
      closeDialog,
    } = this.props;

    const {
      sheetId: sheetIdFromSocketMessage,
      sheetIdentity: sheetIdentityFromSocketMessage,
    } = socketMessageData;

    const {
      sheetOperationData: {
        sheetId,
      },
    } = this.props;

    if (sheetId === sheetIdFromSocketMessage) {
      closeDialog();
      this._sendDefectiveEntitiesMarkedByAnotherUserNotification(sheetIdentityFromSocketMessage);
    }
  }

  _sendDefectiveEntitiesMarkedByAnotherUserNotification = sheetIdentity =>
    sendNotification(
      <Trans id="sheet_operation_review@defective_entities_marked_by_another_user">
        По партии МЛ "{sheetIdentity}", операцию которого Вы просматривали, была сделана отметка о браке другим пользователем
      </Trans>,
      NOTIFICATION_LEVEL.INFO,
    );

  _renderDialogHeader = () =>
    <div className="sheet-operation-review-dialog__header">
      {this._renderTitle()}
      {this._renderActionButtons()}
    </div>;

  _renderTitle = () =>
    <div className="sheet-operation-review-dialog__title">
      <Trans id="sheet_operation_review@title">
        Операция
      </Trans>
    </div>;

  _renderActionButtons = () => {
    const consumeEntitiesButton = this._renderConsumeEntitiesActionButton();
    const statusChangeButtons = this._renderStatusChangeButtons();
    const additionalActionsDropdownButton = this._renderAdditionalActionsDropdown();

    if(
      statusChangeButtons === null
      && additionalActionsDropdownButton === null
      && consumeEntitiesButton === null
    ) {
      return null;
    }

    return(
      <div className="sheet-operation-review-dialog__action-buttons">
        {consumeEntitiesButton}
        {statusChangeButtons}
        {additionalActionsDropdownButton}
      </div>
    );
  };

  _renderActionButton = ({
    btnIdentity,
    onBtnClick,
    btnTitle,
    isDisabled = isAlwaysFalseCb,
  }) => {
    const confirm = this.context;

    return (
      <Button
          key={btnIdentity}
          onClick={() => onBtnClick(this.props, this.state, this.setState.bind(this), confirm)}
          variant={MATERIAL_UI_VARIANT.CONTAINED}
          disableElevation
          disabled={isDisabled(this.props, this.state)}
          color={MATERIAL_UI_STYLE_COLOR.INHERIT}
      >
        {btnTitle}
      </Button>
    );
  }


  _renderConsumeEntitiesActionButton = () => {

    const {
      isConsumeEntitiesActionHidden,
      sheetOperationData,
      PermissionsManager,
    } = this.props;

    const {
      isAssembly,
    } = sheetOperationData;

    const {
      sheetOperationEditableFieldsData,
    } = this.state;

    const { status } = sheetOperationEditableFieldsData;

    if (PermissionsManager.isDenied(PERMISSION.CONSUME_ENTITIES_FOR_ASSEMBLY_SHEET)) return null;

    // кнопка потребить ДСЕ доступна только для операций сборки
    if (!isAssembly) return null;

    if (status !== SHEET_OPERATION_STATUS.IN_PRODUCTION) return null;

    if(
      _isFunction(isConsumeEntitiesActionHidden) &&
      isConsumeEntitiesActionHidden(sheetOperationData, sheetOperationEditableFieldsData)
    ) {
      return null;
    }

    const {
      btnIdentity,
      onBtnClick,
      btnTitle,
    } = CONSUME_ENTITIES_BUTTON;

    return (
      this._renderActionButton({
        btnIdentity,
        onBtnClick,
        btnTitle,
      })
    );
  }

  _renderStatusChangeButtons = () => {
    const {
      PermissionsManager,
      areStatusChangeButtonsHidden,
      sheetOperationData,
    } = this.props;

    if(PermissionsManager.isDenied(PERMISSION.CHANGE_SHEET_OPERATION_STATUS)) {
      return null;
    }

    const {
      sheetOperationEditableFieldsData,
    } = this.state;

    if(
      _isFunction(areStatusChangeButtonsHidden) &&
      areStatusChangeButtonsHidden(sheetOperationData, sheetOperationEditableFieldsData)
    ) {
      return null;
    }

    const statusChangeButtons = STATUS_CHANGE_ACTION_BUTTONS_FOR_CURRENT_STATUS_MAP[sheetOperationEditableFieldsData.status];

    if(!statusChangeButtons) {
      return null;
    }

    return(
      <div className="sheet-operation-review-dialog__status-change-buttons">
        {
          statusChangeButtons
            .map(({
              btnIdentity,
              btnTitle,
              onBtnClick,
              isDisabled,
            }) =>
              this._renderActionButton({
                btnIdentity,
                onBtnClick,
                btnTitle,
                isDisabled,
              }))
        }
      </div>
    );
  };

  _getAdditionalActionsMenuOptions = () => {
    const callbacksArgumentsArray = [this.props, this.state, this.setState.bind(this)];

    return ADDITIONAL_ACTIONS_MENU_OPTIONS
      // удаляются скрытые опции
      .filter(({ isMenuItemVisible }) => isMenuItemVisible(...callbacksArgumentsArray))
      // формируются финальные калбеки и пропсы
      .map(menuOption => {

        const {
          getDisabledMenuItemTooltip,
          onMenuItemClick,
        } = menuOption;

        return {
          ...menuOption,
          disabledMenuItemTooltip: getDisabledMenuItemTooltip(...callbacksArgumentsArray),
          onMenuItemClick: () => onMenuItemClick(...callbacksArgumentsArray),
        };
      });
  }

  _renderAdditionalActionsDropdown = () => {
    const { additionalActionsMenuAnchorEl } = this.state;

    return (
      <AdditionalActionsDropdown
          menuOptions={this._getAdditionalActionsMenuOptions()}
          menuAnchorEl={additionalActionsMenuAnchorEl}
          onOpen={this._openAdditionalActionsMenu}
          onClose={this._closeAdditionalActionsMenu}
      />
    );
  };

  _openAdditionalActionsMenu = event => this.setState({ additionalActionsMenuAnchorEl: event.currentTarget });

  _closeAdditionalActionsMenu = () => this.setState({ additionalActionsMenuAnchorEl: null });

  _renderHelpAlertSection = () => {

    if(!_isFunction(this.props.getHelpAlertContent)) {
      return null;
    }

    const {
      getHelpAlertContent,
      sheetOperationData,
    } = this.props;

    const {
      sheetOperationEditableFieldsData,
    } = this.state;

    const helpAlertContent = getHelpAlertContent(sheetOperationData, sheetOperationEditableFieldsData);

    if(_isNil(helpAlertContent)) {
      return null;
    }

    return(
      <div className="sheet-operation-review-dialog__help-alert-section">
        <SimpleHelpAlert
            content={helpAlertContent}
        />
      </div>
    );
  };

  _renderDetailsSection = () => {
    const resultSheetOperationData = {
      ...this.props.sheetOperationData,
      ...this.state.sheetOperationEditableFieldsData,
    };

    return(
      <CollapsibleContainer
          header={this._renderSectionTitle(DetailsLabelTrans)}
          id={SHEET_OPERATION_REVIEW_COLLAPSIBLE_IDS.DETAILS}
      >
        <div className="sheet-operation-review-dialog__details-section">
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <SimpleSummary
                  summaryData={resultSheetOperationData}
                  secondarySummarySchema={OPERATION_DETAILS_FIRST_BLOCK_SUMMARY_SCHEMA}
                  isGridView
                  gridViewProps={OPERATION_DETAILS_INFO_GRID_PROPS}
                  delimiter={SUMMARY_INFO_ROWS_DELIMITER}
              />
            </Grid>
            <Grid item xs={6}>
              <SimpleSummary
                  summaryData={resultSheetOperationData}
                  secondarySummarySchema={OPERATION_DETAILS_SECOND_BLOCK_SUMMARY_SCHEMA}
                  isGridView
                  gridViewProps={OPERATION_DETAILS_INFO_GRID_PROPS}
                  delimiter={SUMMARY_INFO_ROWS_DELIMITER}
              />
            </Grid>
          </Grid>
        </div>
      </CollapsibleContainer>
    );
  };

  _renderSectionTitle = (titleTrans, additionalLabel, isRequired) =>
    <div className="sheet-operation-review-dialog__section-title">
      { titleTrans }

      { isRequired ? <sup className="sheet-operation-review-dialog__is-required-mark">*</sup> : null }

      { additionalLabel ?
        <Chip
            label={additionalLabel}
            color={MATERIAL_UI_STYLE_COLOR.DEFAULT}
            className="sheet-operation-review-dialog__additional-title-content"
            size={MATERIAL_UI_SIZE.SMALL}
        /> : null }
    </div>;

  _getAdditionalEquipmentSectionLabel = () => {

    if (!this.props.isEquipmentForOperationRequired) return  null;

    const {
      sheetOperationData: {
        status,
      },
      isEquipmentForOperationRequired,
    } = this.props;

    const {
      sheetOperationEditableFieldsData: {
        equipment,
      },
    } = this.state;

    const isOperationStatusInProduction = status === SHEET_OPERATION_STATUS.IN_PRODUCTION;

    /*
    * если включена настройка обязательности выбора оборудования и поле "оборудование" не заполнено, то возвращаем текст
    * с пояснением о том, что работу над операцией нельзя производить без выбранного оборудования
    */
    if (isEquipmentForOperationRequired && !equipment) {
      return (
        <Trans id="sheet_operation_review@equipment_for_operation_required">
          Для начала выполнения операции необходимо выбрать оборудование
        </Trans>
      );
    }

    // если статус операции "в работе" и вклюбчена настройка обязательности выбора оборудования, то возвращаем
    // текст с пояснением о том, что выбор оборудования отключен, потому что во время рабты над операцией его менять
    // нельзя
    if (isOperationStatusInProduction && isEquipmentForOperationRequired) {
      return (
        <Trans id="sheet_operation_review@equipment_select_disabled_for_operation_in_production_reason">
          Во время выполнения операции нельзя менять оборудование
        </Trans>
      );
    }
  }

  _renderAssigneesAndEquipmentSection = () => {

    const {
      sheetOperationEditableFieldsData: {
        equipment,
        assignees,
      },
    } = this.state;

    const {
      sheetOperationData: {
        departmentId,
        equipmentClassId,
      },
      createEquipmentEntity,
      addAutocompleteOptions,
      isEquipmentForOperationRequired,
    } = this.props;


    return (
      <Grid container>
        <Grid item xs={6}>
          <CollapsibleContainer
              header={this._renderSectionTitle(AssigneesLabelTrans)}
              id={SHEET_OPERATION_REVIEW_COLLAPSIBLE_IDS.ASSIGNEES}
          >
            <div className="sheet-operation-review-dialog__assignees-section">
              <SheetOperationAssigneesSection
                  isEditable={!this._isAssigneesChangeDisabled()}
                  assignees={assignees}
                  setAssignees={this._setSheetOperationAssignees}
              />
            </div>
          </CollapsibleContainer>
        </Grid>
        <Grid item xs={6}>
          <CollapsibleContainer
              header={this._renderSectionTitle(
                EquipmentLabelTrans,
                this._getAdditionalEquipmentSectionLabel(),
                isEquipmentForOperationRequired,
              )}
              id={SHEET_OPERATION_REVIEW_COLLAPSIBLE_IDS.EQUIPMENT}
          >
            <div className="sheet-operation-review-dialog__equipment-section">
              <SheetOperationEquipmentSection
                  isEditable={!this._isEquipmentChangeDisabled()}
                  equipment={equipment}
                  departmentId={departmentId}
                  equipmentClassId={equipmentClassId}
                  setSheetOperationEquipment={this._setSheetOperationEquipment}
                  createEquipmentEntity={createEquipmentEntity}
                  addAutocompleteOptions={addAutocompleteOptions}
              />
            </div>
          </CollapsibleContainer>
        </Grid>
      </Grid>
    );
  }

  _isAssigneesChangeDisabled = () => {

    if(this.props.PermissionsManager.isDenied(PERMISSION.SET_SHEET_OPERATION_ASSIGNEES_OR_EQUIPMENT)) {
      return true;
    }

    const {
      isAssigneesChangeDisabled,
      sheetOperationData,
    } = this.props;

    const {
      sheetOperationEditableFieldsData,
    } = this.state;

    return (
      _isFunction(isAssigneesChangeDisabled) &&
      isAssigneesChangeDisabled(sheetOperationData, sheetOperationEditableFieldsData)
    );
  };

  _setSheetOperationAssignees = assigneesDataArray =>
    this.props.setSheetOperationAssignees(assigneesDataArray)
      .then(() => {
        this._changeSheetOperationEditableFieldsData({ assignees: assigneesDataArray });
      });

  _isEquipmentChangeDisabled = () => {

    const {
      isEquipmentChangeDisabled,
      sheetOperationData,
      isEquipmentForOperationRequired,
      shouldCheckEquipmentAvailability,
      PermissionsManager,
    } = this.props;

    const { status } = sheetOperationData;

    const isOperationStatusInProduction = status === SHEET_OPERATION_STATUS.IN_PRODUCTION;

    if(
      PermissionsManager.isDenied(PERMISSION.SET_SHEET_OPERATION_ASSIGNEES_OR_EQUIPMENT) ||
      // если операция в работе и включена настройка обязательности выбора обрудования, то дизейблим выбор оборудования
      (isEquipmentForOperationRequired && isOperationStatusInProduction) ||
      /*
      * если операция в работе и включена настройка проверки доступности оборудования, то запрещаем менять обрудование.
      * это делать не обязательно, т.е. без этого условия ничего не сломается, но если проверяем дрсутпность
      * оборудования, то логично его оставить, чтобы сохранить упорядоченный флоу - не менять оборудование во время
      * выполнения операции
      */
      (shouldCheckEquipmentAvailability && isOperationStatusInProduction)
    ) {
      return true;
    }

    const {
      sheetOperationEditableFieldsData,
    } = this.state;

    return (
      _isFunction(isEquipmentChangeDisabled) &&
      isEquipmentChangeDisabled(sheetOperationData, sheetOperationEditableFieldsData)
    );
  };

  _setSheetOperationEquipment = equipment =>
    this.props.setSheetOperationEquipment(equipment)
      .then(() => {
        this._changeSheetOperationEditableFieldsData({ equipment });
      });

  _renderNoteSection = () => {
    const {
      sheetOperationEditableFieldsData: {
        note,
      },
    } = this.state;

    return (
      <CollapsibleContainer
          header={this._renderSectionTitle(CommentLabelTrans)}
          id={SHEET_OPERATION_REVIEW_COLLAPSIBLE_IDS.NOTE}
      >
        <div className="sheet-operation-review-dialog__note-section">
          <SheetOperationNoteSection
              isEditable={!this._isNoteChangeDisabled()}
              note={note}
              setNote={this._setSheetOperationNote}
          />
        </div>
      </CollapsibleContainer>
    );
  };

  _getSheetOperationFeaturesSectionAdditionalTitle = (
    isSheetOperationFeaturesValuesFormDisabled,
    hasEditedSheetOperationFeaturesValues,
  ) => {
    const {
      sheetOperationFeaturesValues,
    } = this.props;

    if (_isEmpty(sheetOperationFeaturesValues)) return null;


    if (isSheetOperationFeaturesValuesFormDisabled) {
      return (
        <Trans id="sheet_operation_review@sheet_operation_features_values_disabled_reason">
          Значения доп. характеристик можно заполнять только если статус операции "В работе"
        </Trans>
      );
    }

    if (hasEditedSheetOperationFeaturesValues) {
      return (
        <Trans id="sheet_operation_review@can_not_edit_features_after_sheet_operation_dialog_close">
          Заполненные доп. характеристики нельзя редактировать после закрытия окна просмотра операции
        </Trans>
      );
    }

    return (
      <Trans id="sheet_operation_review@fill_sheet_operation_features_values">
        Для операции назначены доп. характеристики, заполните их в этом блоке
      </Trans>
    );
  }

  _renderSheetOperationFeaturesSection = () => {
    const {
      sheetOperationData: {
        sheetOperationId,
        status,
      },
      sheetOperationFeaturesValues,
    } = this.props;

    const isSheetOperationFeaturesValuesFormDisabled = status !== SHEET_OPERATION_STATUS.IN_PRODUCTION;

    const hasEditedSheetOperationFeaturesValues = Object
      .values(sheetOperationFeaturesValues)
      .some(({ value }) => value !== null);

    return (
      <CollapsibleContainer
          header={
            this._renderSectionTitle(
              SheetOperationFeaturesLabelTrans,
              this._getSheetOperationFeaturesSectionAdditionalTitle(
                isSheetOperationFeaturesValuesFormDisabled,
                hasEditedSheetOperationFeaturesValues,
              ),
            )
          }
          id={SHEET_OPERATION_REVIEW_COLLAPSIBLE_IDS.FEATURES}
      >
        <div className="sheet-operation-review-dialog__features-section">
          <SheetOperationFeaturesValuesFormContainer
              isFormDisabled={isSheetOperationFeaturesValuesFormDisabled}
              sheetOperationId={sheetOperationId}
              hasEditedSheetOperationFeaturesValues={hasEditedSheetOperationFeaturesValues}
          />
        </div>
      </CollapsibleContainer>
    );
  }

  _setSheetOperationNote = newNote =>
    this.props.setSheetOperationNote(newNote)
      .then(() => {
        this._changeSheetOperationEditableFieldsData({ note: newNote });
      });

  _isNoteChangeDisabled = () => {
    if(this.props.PermissionsManager.isDenied(PERMISSION.SET_ENTITY_NOTE)) {
      return true;
    }

    const {
      isNoteChangeDisabled,
      sheetOperationData,
    } = this.props;

    const {
      sheetOperationEditableFieldsData,
    } = this.state;

    return (
      _isFunction(isNoteChangeDisabled) &&
      isNoteChangeDisabled(sheetOperationData, sheetOperationEditableFieldsData)
    );
  };

  _renderHistorySection = () => {
    const {
      sheetOperationData: {
        sheetOperationId,
        entitiesInBatchAmount,
      },
    } = this.props;

    const {
      sheetOperationEditableFieldsData: {
        status,
      },
    } = this.state;

    return(
      <CollapsibleContainer
          header={this._renderSectionTitle(HistoryLabelTrans)}
          id={SHEET_OPERATION_REVIEW_COLLAPSIBLE_IDS.HISTORY}
      >
        <div className="sheet-operation-review-dialog__history-section">
          <SheetOperationTransactionsTableContainer
              sheetOperationId={sheetOperationId}
              operationStatus={status}
              entitiesInBatchAmount={entitiesInBatchAmount}
          />
        </div>
      </CollapsibleContainer>
    );
  };

  _renderAdditionalDialogs = () =>
    <React.Fragment>
      {this._renderSheetOperationStatusChangeDialog()}
      {this._renderPauseSheetDialog()}
      {this._renderEntityBatchSplitDialog()}
    </React.Fragment>;

  _renderSheetOperationStatusChangeDialog = () => {

    const {
      sheetOperationStatusChangeDialogViewMode,
    } = this.state;

    const isModalOpen = sheetOperationStatusChangeDialogViewMode !== null;

    if(!isModalOpen) {
      return null;
    }

    const {
      sheetOperationData,
      PermissionsManager,
    } = this.props;

    const {
      sheetOperationEditableFieldsData,
    } = this.state;

    const updatedSheetOperationData = {
      ...sheetOperationData,
      ...sheetOperationEditableFieldsData,
    };

    return (
      <SheetOperationStatusChangeDialog
          isOpen={isModalOpen}
          closeDialog={this._handleSheetOperationStatusChangeDialogClose}
          sheetOperationData={updatedSheetOperationData}
          viewMode={sheetOperationStatusChangeDialogViewMode}
          isDefectiveEntitiesMarkingPermissionGranted={PermissionsManager.isGranted(PERMISSION.MARK_DEFECTIVE_ENTITIES)}
          onSubmit={this._handleSheetOperationStatusChangeDialogSubmit}
      />
    );
  };

  _handleSheetOperationStatusChangeDialogSubmit = dataToSubmit => {
    const {
      pauseSheetOperation,
      markDefectiveEntities,
    } = this.props;
    const {
      sheetOperationStatusChangeDialogViewMode,
    } = this.state;

    /*
    если количество забракованной продукции не определено, то реализуем стандартную логику смены статуса
    операции
    */
    if(dataToSubmit.defectivePartAmount === null) {
      return sheetOperationStatusChangeDialogViewMode === SHEET_OPERATION_STATUS_CHANGE_DIALOG_VIEW_MODES.PAUSE ?
        pauseSheetOperation(dataToSubmit.notDefectivePartOperationProgress) :
        this._handleSheetOperationFinish();
    }


    /*
    * если задается брак, то выполняем запрос на action-точку проставления отметок о браке, которая также
      выполняет и необходимую смену статуса операции.
    **/
    return markDefectiveEntities(dataToSubmit);
  };

  _handleSheetOperationFinish = () => {
    const {
      finishSheetOperation,
      sheetOperationData: {
        isAssembly,
        isLastAssemblySheetOperation,
      },
      fetchEntityBatchReserveState,
    } = this.props;

    const confirm = this.context;

    // если не последняя сборочная, то завершаем операцию
    if (!isAssembly || !isLastAssemblySheetOperation) return finishSheetOperation();

    // если последняя сборочная, то проверяем все ли материалы зарезервированы и потреблены
    return fetchEntityBatchReserveState()
      .then(({ allEntitiesWereReserved, allReserveWasConsumed }) => {

        const areAllReservedPartsAndMaterialsWasConsumed = allEntitiesWereReserved && allReserveWasConsumed;

        // сли зарезервированы и потреблены все, то завершаем операцию
        if (areAllReservedPartsAndMaterialsWasConsumed) return finishSheetOperation();

        /*
        * при завершении сборочной операции есть 4 кейса состояния потребления зарезервированных ДСЕ:
        * 1. не все зарезервированы и не все потреблены
        * 2. все зарезервированы и не все потреблены
        * 3. не все зарезервированы и все зарезервированные потреблены
        * 4. все зарезервированы и все потреблены
        * Кейс 4 обработан выше, в этом случае просто завершаем операцию. Для кейсов 1 и 3 выводим сообщение
        * "не все зарезервированы". Для кейса 2 выводим сообщение "не все потреблены"
        */
        let confirmText = !allEntitiesWereReserved ?
          <Trans id="sheet_operation_review.finish_last_assembly_sheet_operation_dialog@not_all_warehouse_parts_and_materials_was_reserved">
            Вы завершаете последнюю сборочную операцию МЛ, не потребив все необходимые ДСЕ по его спецификации.
            Ещё не все необходимые для потребления ДСЕ были зарезервированы. Потребление возможно только
            на "сборочных" операциях в статусе "В работе", поэтому после завершения этой операции Вы не
            сможете скорректировать данные потребления. Вы уверены?
          </Trans> :

          <Trans id="sheet_operation_review.finish_last_assembly_sheet_operation_dialog@not_all_reserved_parts_and_materials_was_consumed">
            Вы завершаете последнюю сборочную операцию МЛ, не потребив все необходимые ДСЕ по его спецификации.
            Ещё не все ДСЕ из зарезервированных были потреблены. Потребление возможно только на "сборочных"
            операциях в статусе "В работе", поэтому после завершения этой операции Вы не сможете
            скорректировать данные потребления. Вы уверены?
          </Trans>;

        // если зарезервированы или потреблены не все, то выводим конфирм и завершаем операцию
        // только после подтверждения
        return confirm({
          confirmModalTitle: (
            <Trans id="sheet_operation_review.finish_last_assembly_sheet_operation_dialog@title">
              Завершение последней сборочной операции
            </Trans>
          ),
          confirmText,
          confirmDialogInnerProps: {
            dialogMaxWidth: MATERIAL_UI_DIALOG_MAX_WIDTH.SM,
          },
        })
          .then(finishSheetOperation);
      });
  }

  _handleSheetOperationStatusChangeDialogClose = () => this.setState({ sheetOperationStatusChangeDialogViewMode: null });

  _renderPauseSheetDialog = () => {
    const {
      isSheetPausing,
    } = this.state;

    if(!isSheetPausing) {
      return null;
    }

    return (
      <PauseSheetDialog
          isOpen
          closeDialog={this._handlePauseSheetDialogClose}
          onSubmit={this.props.pauseSheet}
      />
    );
  };

  _handlePauseSheetDialogClose = () => this.setState({ isSheetPausing: false });

  _renderEntityBatchSplitDialog = () => {
    const {
      isEntityBatchSplitting,
    } = this.state;

    if(!isEntityBatchSplitting) {
      return null;
    }

    const {
      splitEntityBatch,
      sheetOperationData,
    } = this.props;

    return (
      <SplitEntityBatchDialog
          isOpen
          closeDialog={this._handleEntityBatchSplitDialogClose}
          onSubmit={splitEntityBatch}
          sheetOperationData={sheetOperationData}
      />
    );
  };

  _handleEntityBatchSplitDialogClose = () => this.setState({ isEntityBatchSplitting: false });

  _handleConsumeEntitiesDialogClose = () => this.props.setIsConsumeEntitiesDialogOpen(false);

  _renderConsumeEntitiesDialog = () => {

    const {
      sheetOperationData,
      isConsumeEntitiesDialogOpen,
    } = this.props;

    return (
      <ConsumeEntitiesDialog
          className="sheet-operation-review-dialog__consume-entities-dialog"
          isOpen={isConsumeEntitiesDialogOpen}
          onClose={this._handleConsumeEntitiesDialogClose}
          sheetOperationData={sheetOperationData}
      />
    );
  }

  _renderContent = () => (
      <div className="sheet-operation-review-dialog__content">
        {this._renderDetailsSection()}
        <Divider />
        {this._renderAssigneesAndEquipmentSection()}
        <Divider />
        {this._renderSheetOperationFeaturesSection()}
        <Divider />
        {this._renderNoteSection()}
        <Divider />
        {this._renderHistorySection()}
      </div>
    )

  render() {
    const {
      closeDialog,
      className,
    } = this.props;

    const helpAlertSection = this._renderHelpAlertSection();

    return (
      <Dialog
          classes={{
            root: cn(
              'sheet-operation-review-dialog',
              className,
            ),
            paper: 'sheet-operation-review-dialog__dialog-paper',
          }}
          open
          onClose={closeDialog}
          maxWidth={MATERIAL_UI_DIALOG_MAX_WIDTH.LG}
          fullWidth
      >
        {this._renderDialogHeader()}
        <Divider />
        {
          helpAlertSection === null ?
            null :
            <React.Fragment>
              {helpAlertSection}
              <Divider />
            </React.Fragment>
        }
        {this._renderContent()}
        {this._renderAdditionalDialogs()}
        {this._renderConsumeEntitiesDialog()}
      </Dialog>
    );
  }
}
