import React, { Component } from 'react';
import { Trans } from '@lingui/macro';

import PropTypes from 'prop-types';

import { Table } from '../../Table/Table';
import { ORDER_ENTRY_MODEL } from '../../../constants/models';
import { NUMBER_OR_STRING_TYPE } from '../../../constants/propTypes';


export const ORDER_ENTRIES_TABLE_DATA_TYPE = PropTypes.arrayOf(
  PropTypes.shape({
    entityId: NUMBER_OR_STRING_TYPE.isRequired,
    entity__identity: PropTypes.string.isRequired,
    entity__name: PropTypes.string.isRequired,
    entity__code: PropTypes.string,
    id: NUMBER_OR_STRING_TYPE.isRequired,
    orderId: NUMBER_OR_STRING_TYPE.isRequired,
    priority: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
  }),
);

export class OrderEntriesTable extends Component {

  static propTypes = {
    orderEntriesTableId: PropTypes.string,
    orderEntriesTableModel: PropTypes.string,
    orderEntriesTableData: ORDER_ENTRIES_TABLE_DATA_TYPE,
    fetchOrderEntriesTableData: PropTypes.func,
    onOrderEntryRowClick: PropTypes.func,
    getOrderEntriesTableRowStyle: PropTypes.func,
  };

  static defaultProps = {
    orderEntriesTableModel: ORDER_ENTRY_MODEL,
  };

  _renderTableTranslatedNoDataText = () =>
    <Trans id="order_entries@no_order_entries_data">
      Нет данных о позициях выбранного заказа
    </Trans>;

  render() {
    const {
      orderEntriesTableId,
      orderEntriesTableModel,
      orderEntriesTableData,
      fetchOrderEntriesTableData,
      onOrderEntryRowClick,
      getOrderEntriesTableRowStyle,
    } = this.props;

    return (
      <div className="order-entries-table-wrapper">
        <Table
            tableId={orderEntriesTableId}
            tableModel={orderEntriesTableModel}
            rowsData={orderEntriesTableData}
            fetchRemoteTableData={fetchOrderEntriesTableData}
            onRowClick={onOrderEntryRowClick}
            getRowStyle={getOrderEntriesTableRowStyle}
            noDataContent={this._renderTableTranslatedNoDataText()}
            tableMenu={{
              disableMenu: true,
            }}
            disableSort
        />
      </div>
    );
  }
}