import { USERS_AUTOCOMPLETE_FIELDS } from '../../../entitiesAutocomplete/UsersAutocomplete/constants';

export const SHEET_OPERATION_ASSIGNEE_AUTOCOMPLETE_FIELDS = [
  USERS_AUTOCOMPLETE_FIELDS.LAST_NAME,
  USERS_AUTOCOMPLETE_FIELDS.NAME,
  USERS_AUTOCOMPLETE_FIELDS.PATRONYMIC_NAME,
  USERS_AUTOCOMPLETE_FIELDS.NOTE,
];

export const SHEET_OPERATION_ASSIGNEE_AUTOCOMPLETE_ID = 'sheet-operation-assignee-autocomplete';
