/*
* Константы для работы с моделью БД settings. Особенность модели в том, что данные в ней хранятся в поле
* JSON и, фактически, здесь может храниться любая нужная фронту информация, без создания дополнительных моделей.
* Для сущности модели в БД уникальным ключом является комбинация "группы настройки" (то, к какой области настройка
* относится) и "имени настройки".я
*
 * Настройки в ca_frontend разделены на группы, идентификатор каждой группы задаётся константой, например
 * WORKER_TASKS_TABLE_MAIN_SETTINGS_ID. В каждой такой группе может храниться неограниченное количество разных экземпляров группы настроек,
 * такие экземпляры хранятся по ключам id (name в бд) внутри группы настроек.
*
* */

import { NOTIFICATION_LEVEL, sendNotification } from './notification';
import { SettingsSavedLabelTrans } from '../utils/commonTransComponents';

export const WORKER_TASKS_TABLE_SETTINGS_VIEW_MODE = {
  CREATE: 'CREATE',
  EDIT: 'EDIT',
};

const SETTINGS_ID_DELIMITER = '__';
export const getSettingsEntityUniqId = (group, name) => [group, name].join(SETTINGS_ID_DELIMITER);
export const getSettingsNameFromId = id => id.split(SETTINGS_ID_DELIMITER)[1];

/*
* Значения констант WORKER_TASKS_TABLE_SETTINGS_GROUP, WORKER_TASKS_TABLE_MAIN_SETTINGS_NAME исторические. Раньше
* так назывались сами константы, но затем у нас появилось несколько интерфейсов заданий рабочего и константы были
* переименованы. При этом, значения менять нельзя, т.к. это идентификаторы в БД и они у нас не могут быть
* изменены, т.к. никакая миграция ключей сервером тут не проводится. Можно будет изменить только если мы попросим
* бэкендеров написать кастомную миграцию. В остальном, получается, что если мы их переименуем, то у нас потеряются
* значения этих настроек на production + т.к. эти значения удобны для настроек другого интерфейса заданий рабочего,
* то если их просто там применить, то, получится, в целом, путаница в настройках.
* Поэтому пока так - для настроек интерфейса "Все задания", для констант WORKER_TASKS_TABLE_SETTINGS_GROUP и
* WORKER_TASKS_TABLE_MAIN_SETTINGS_NAME, в итоге, поэтому тоже назначаются другие значения
* */
export const WORKER_EQ_CLASS_IN_DEP_TASKS_TABLE_SETTINGS_GROUP = 'WORKER_TASKS_TABLE_SETTINGS_GROUP';
export const WORKER_EQ_CLASS_IN_DEP_TASKS_TABLE_MAIN_SETTINGS_NAME = 'WORKER_TASKS_TABLE_MAIN_SETTINGS_NAME';
export const WORKER_EQ_CLASS_IN_DEP_TASKS_TABLE_MAIN_SETTINGS_ID = getSettingsEntityUniqId(
  WORKER_EQ_CLASS_IN_DEP_TASKS_TABLE_SETTINGS_GROUP,
  WORKER_EQ_CLASS_IN_DEP_TASKS_TABLE_MAIN_SETTINGS_NAME,
);

/*
* Значения констант не равны самим константам, как это часто бывает из-за особенностей, описанных в комментарии к
* WORKER_EQ_CLASS_IN_DEP_TASKS_TABLE_SETTINGS_GROUP
* */
export const WORKER_TASKS_TABLE_SETTINGS_GROUP = 'WORKER_ALL_TASKS_SCREEN_TABLE_SETTINGS_GROUP';
export const WORKER_TASKS_TABLE_MAIN_SETTINGS_NAME = 'WORKER_ALL_TASKS_SCREEN_TABLE_MAIN_SETTINGS_NAME';

export const WORKER_TASKS_TABLE_MAIN_SETTINGS_ID = getSettingsEntityUniqId(
  WORKER_TASKS_TABLE_SETTINGS_GROUP,
  WORKER_TASKS_TABLE_MAIN_SETTINGS_NAME,
);


export const sendSettingsSavedNotification = () => sendNotification(
  SettingsSavedLabelTrans,
  NOTIFICATION_LEVEL.SUCCESS,
  { timeOut: 3000 },
);