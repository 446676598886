import { combineReducers } from 'redux';


import { tasksMainFilters } from './tasksMainFilters/reducer';
import { tasksOwners } from './tasksOwners/reducer';
import { assemblySheets } from './assemblySheets';
import { tasksSearchHistory } from './tasksSearchHistory/reducer';

export const workerAppReducer = combineReducers({
  tasksMainFilters,
  tasksOwners,
  assemblySheets,
  tasksSearchHistory,
});
