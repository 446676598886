import {
  fetchCompletedSheets,
  fetchDefaultSheetsWithEnoughPartsAndMaterialsInStorage,
  fetchPartAndMaterialsReserveDataForAssemblyEntityBatch, fetchPossiblePartsAndMaterialsToConsumeForDefaultSheet,
  fetchSheetsInProduction,
} from '../../../../operations/sheets';
import { routerPathnameSelector } from '../../../../reducers/router/selectors';
import { allTasksTablesIdsSelector } from '../../../../selectors/taskView';
import {
  MASTER_APP_COMPLETED_TASKS_ROUTE,
  MASTER_APP_TASKS_TO_DO_ROUTE,
  PLANNER_APP_COMPLETED_SHEETS_ROUTE,
  PLANNER_APP_ORDERS_IN_PRODUCTION_ROUTE, PLANNER_APP_ORDERS_READY_TO_COMPLETE_ROUTE,
  PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE,
  STORAGE_MANAGEMENT_APP_ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
  STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
  WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE,
  WORKER_APP_TASKS_ROUTE,
} from '../../../../constants/routes';
import { matchPath } from 'react-router-dom';
import {
  getMasterAppDepartmentIdsFromRouteParam,
} from '../../../../components/MasterApp/MasterWorkspace/masterAppDepartmentIdsRouteParam';
import { clearTableData, clearTableRemoteData, reFetchRemoteTableData } from '../../../../reducers/table/actions';
import { ENTITY_BATCH_PROVIDING_STATE, SHEET_TYPE } from '../../../../constants/sheets';
import { MASTER_TASKS_TO_DO_TABLE_ID } from '../../../../components/MasterApp/MasterTasksToDo/constants';
import {
  fetchSheetTypeRemoteTableDataCbFactory, fetchSheetTypeToReviewCbFactory,
  SHEETS_REMOTE_TABLE_DATA_WITH_PARAMS,
} from '../../../../components/Sheets/SheetsContainer';
import {
  fetchMasterDepartmentsCompletedTasksRemoteTableData,
  fetchMasterDepartmentsTasksToDoRemoteTableData,
} from '../../../../operations/masterWorkspace/index';
import {
  createOrderEntriesTableId,
  createSheetTypeOperationsTableId,
  getEquipmentClassInDepartmentTasksTableId,
} from '../../../../utils/tables';
import {
  fetchEquipmentClassInDepartmentTasksRemoteTableData,
  fetchTasksRemoteTableData,
} from '../../../../operations/tasks';
import { push } from 'connected-react-router';
import {
  clearAllDefaultSheetsPartsAndMaterialsToConsume,
  clearDefaultSheetPartsAndMaterialsToConsume,
} from '../../../../reducers/storageManagementApp/defaultSheets/actions';
import {
  addEntitiesToStore,
  deleteEntitiesFromStore,
  fetchEntitiesFromServer,
} from '../../../../reducers/entities/actions';
import { ENTITY_BATCH_MODEL, SHEET_MODEL } from '../../../../constants/models';
import { FILTER_GROUP_TYPES, FILTER_TYPES } from '../../../restCollectionApi/index';
import {
  masterTasksToDoAdditionalFiltersSelector,
} from '../../../../reducers/masterApp/tasksToDoAdditionalFilters/selectors';
import {
  fetchFullAssemblySheetsPartsAndMaterials,
} from '../../../../components/StorageManagementApp/SheetsWaitingPartsAndMaterials/AssemblySheetsWaitingPartsAndMaterials/AssemblySheetsWaitingPartsAndMaterials';
import {
  deleteAllAssemblySheetsReserveData,
  deleteAssemblySheetReserveData,
} from '../../../../reducers/storageManagementApp/assemblySheets/reserveData/actions';
import { deleteAssemblySheetConsumeData } from '../../../../reducers/workerApp/assemblySheets/consumeData/actions';
import { TASKS_MODEL } from '../../../../reducers/schemaModel/models/tasksSchema';
import {
  MASTER_COMPLETED_TASKS_TABLE_MODEL,
  MASTER_TASKS_TO_DO_TABLE_MODEL,
} from '../../../../reducers/schemaModel/models/masterTasksSchema';
import { MASTER_COMPLETED_TASKS_TABLE_ID } from '../../../../components/MasterApp/MasterCompletedTasks/constants';
import { ORDER_TYPE } from '../../../../constants/orders';
import { getOrderCompletedRelatedActions } from './sheetFinish';
import { fetchOrderRemoteTableDataCbFactory } from '../../../../components/Orders/OrdersContainer';
import { fetchOrdersInProduction, fetchOrdersReadyToComplete } from '../../../../operations/orders';


export const getSplitEntityBatchCaseData = ({
  isParentEntityBatchWasFinished,
  isChildEntityBatchWasFinished,
  changedOrderId,
}) => {

  return {
    isOneOfBatchesFinished: isParentEntityBatchWasFinished || isChildEntityBatchWasFinished,
    isOrderFinished: !!changedOrderId,
  };
};

export const getEntityBatchSplitCaseSpecificActions = ({
  isOneOfBatchesFinished,
  parentSheetId,
}) => {

  if(isOneOfBatchesFinished) {
    return [
      /*
      * Удаляем parentSheetId из entities по условию isOneOfBatchesFinished, не проверяя
      * дополнительно, что именно родительская партия завершена, т.к. перезапрос для
      * комплектации стандартных МЛ будет выполнен в любом случае и МЛ добавится с измененным
      * количеством, даже если не он был завершен
      * */
      deleteEntitiesFromStore(SHEET_MODEL, [parentSheetId]),
      clearAllDefaultSheetsPartsAndMaterialsToConsume(),
      deleteAllAssemblySheetsReserveData(),
      deleteAssemblySheetConsumeData({ sheetId: parentSheetId }),
    ];
  }

  return [
    clearDefaultSheetPartsAndMaterialsToConsume(parentSheetId),
    deleteAssemblySheetReserveData({ sheetId: parentSheetId }),
    deleteAssemblySheetConsumeData({ sheetId: parentSheetId }),
  ];
};

/*
* Подробно о влиянии события деления партии на типы приложения описано в комментарии к handleEntityBatchSplit в
* SheetInProductionReviewContentContainer, MasterTasksTodoContainer, TasksViewContainer, TasksContainer. Т.к. эти
* обработчики вызываются в интерфейсах, где вызывается и само действие деление партии, то в них для всех остальных
* разделов, где надо обновить данные, в основном, сбрасывается "клиентский кэш" (т.е. данные из стора), чтобы, при
* повторном входе в интерфейсы, запросы выполнились по новой и данные сохранились.
* В случае с получением события деления ппартии другими пользователями нужна похожая обработка, но, с учетом, того, что
* пользователи могут находиться в этот момент в разделах, которые сразу же должны быть обновлены - в этом случае,
* сбрасывать "кэш" не нужно, а нужен именно перезапрос \ обновление данных. Если перед перезапросом данных мы сначала
* очистим "кэш" (т.е. данные текущего раздела), а только потом перезапросим новые данные, то у пользователя в интерфейсе
* случится "скачок" (данные пропадут, кое-где мелькнет сообщение о пустых данных, а потом тут же появятся новые данные),
* что нежелательно.
* Для всех остальных разделов, где нужны обновления, кроме текущего, требуется, аналогично, очистить "кэш", чтобы при
* повторном входе в интерфейсы запросы выполнились заново
* */
export const handleEntityBatchSplit = message =>
  (dispatch, getState) => {
    const {
      parentSheetId,
      parentEntityBatchId,
      childSheetId,
      isParentEntityBatchWasFinished,
      isChildEntityBatchWasFinished,
      changedOrderId,
    } = message;


    const state = getState();

    const sheetOperationsTableId = createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, parentSheetId);

    const allTasksTablesIds = allTasksTablesIdsSelector(state);

    const currentPathname = routerPathnameSelector(state);

    const {
      isOneOfBatchesFinished,
      isOrderFinished,
    } = getSplitEntityBatchCaseData({
      isParentEntityBatchWasFinished,
      isChildEntityBatchWasFinished,
      changedOrderId,
    });


    //Если находимся в разделе "МЛ в производстве"
    const sheetsInProductionRouteMatch = matchPath(currentPathname, {
      path: PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE,
    });

    if (sheetsInProductionRouteMatch !== null) {
      return dispatch(_updateIfOnSheetsInProductionScreen({
        currentPathname,
        allTasksTablesIds,
        sheetOperationsTableId,
        parentSheetId,
        changedOrderId,
        isOneOfBatchesFinished,
        isOrderFinished,
      }));
    }


    //Если находимся в разделе "Плановик. Завершённые МЛ"
    const completedSheetsRouteMatch = matchPath(currentPathname, {
      path: PLANNER_APP_COMPLETED_SHEETS_ROUTE,
    });

    if (completedSheetsRouteMatch !== null && isOneOfBatchesFinished) {
      return dispatch(_updateIfOnCompletedSheetsScreen({
        allTasksTablesIds,
        sheetOperationsTableId,
        parentSheetId,
        changedOrderId,
        isOneOfBatchesFinished,
        isOrderFinished,
      }));
    }


    //Если находимся в разделе "Плановик. Заказы. В производстве"
    const ordersInProductionRouteMatch = matchPath(currentPathname, {
      path: PLANNER_APP_ORDERS_IN_PRODUCTION_ROUTE,
    });

    if(ordersInProductionRouteMatch !== null && isOrderFinished)
      return dispatch(_updateIfOnOrdersInProductionScreen({
        currentPathname,
        allTasksTablesIds,
        sheetOperationsTableId,
        parentSheetId,
        changedOrderId,
        isOneOfBatchesFinished,
      }));

    //Если находимся в разделе "Плановик. Заказы. Готовые к завершению"
    const ordersReadyToCompleteRouteMatch = matchPath(currentPathname, {
      path: PLANNER_APP_ORDERS_READY_TO_COMPLETE_ROUTE,
    });

    if(ordersReadyToCompleteRouteMatch !== null && isOrderFinished)
      return dispatch(_updateIfOnOrdersReadyToCompleteScreen({
        allTasksTablesIds,
        sheetOperationsTableId,
        parentSheetId,
        changedOrderId,
        isOneOfBatchesFinished,
      }));


    //Если находимся в разделе "Комплектование - "стандартные" неукомплектованные маршрутные листы"
    const defaultSheetsWaitingPartsAndMaterialsRouteMatch = matchPath(currentPathname, {
      path: STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
    });

    if(defaultSheetsWaitingPartsAndMaterialsRouteMatch !== null)
      return dispatch(_updateIfOnDefaultSheetsWaitingPartsAndMaterialsScreen({
        currentPathname,
        allTasksTablesIds,
        sheetOperationsTableId,
        parentSheetId,
        changedOrderId,
        isOneOfBatchesFinished,
        isOrderFinished,
        childSheetId,
        isParentEntityBatchWasFinished,
        isChildEntityBatchWasFinished,
      }));

    //Если находимся в разделе "Комплектоване - сборочные" маршрутные листы
    const assemblySheetsWaitingPartsAndMaterialsRouteMatch = matchPath(currentPathname, {
      path: STORAGE_MANAGEMENT_APP_ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
    });

    if (assemblySheetsWaitingPartsAndMaterialsRouteMatch !== null) {
      return dispatch(_updateIfOnAssemblySheetsWaitingPartsAndMaterialsScreen({
        currentPathname,
        parentEntityBatchId,
        allTasksTablesIds,
        sheetOperationsTableId,
        parentSheetId,
        changedOrderId,
        isOneOfBatchesFinished,
        isOrderFinished,
        isParentEntityBatchWasFinished,
      }));
    }

    //Если находимся  в разделе "Мастер. Требуется выполнить"
    const masterTasksTodoRouteMatch = matchPath(currentPathname, {
      path: MASTER_APP_TASKS_TO_DO_ROUTE,
    });
    if(masterTasksTodoRouteMatch !== null)
      return dispatch(_updateIfOnMasterTasksTodoScreen({
        allTasksTablesIds,
        sheetOperationsTableId,
        parentSheetId,
        changedOrderId,
        isOneOfBatchesFinished,
        isOrderFinished,
        departmentIds: getMasterAppDepartmentIdsFromRouteParam(masterTasksTodoRouteMatch.params.departmentIds),
      }));

    //Если находимся в разделе "Мастер. Завершённые"
    const masterCompletedTasksRouteMatch = matchPath(currentPathname, {
      path: MASTER_APP_COMPLETED_TASKS_ROUTE,
    });
    if(masterCompletedTasksRouteMatch !== null)
      return dispatch(_updateIfOnMasterCompletedTasksScreen({
        allTasksTablesIds,
        sheetOperationsTableId,
        parentSheetId,
        changedOrderId,
        isOneOfBatchesFinished,
        isOrderFinished,
        departmentIds: getMasterAppDepartmentIdsFromRouteParam(masterCompletedTasksRouteMatch.params.departmentIds),
      }));


    //Если находимся в разделе "Рабочий. Просмотр задания для класса РЦ в подразделении"
    const tasksForEquipmentClassInDepartmentRouteMatch = matchPath(currentPathname, {
      path: `${WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE}/:departmentId/:equipmentClassId`,
    });
    if(tasksForEquipmentClassInDepartmentRouteMatch !== null) {
      const {
        departmentId,
        equipmentClassId,
      } = tasksForEquipmentClassInDepartmentRouteMatch.params;

      return dispatch(_updateIfOnTasksViewScreen({
        allTasksTablesIds,
        parentSheetId,
        sheetOperationsTableId,
        changedOrderId,
        isOneOfBatchesFinished,
        isOrderFinished,
        departmentIdFromRoute: departmentId,
        equipmentClassIdFromRoute: equipmentClassId,
      }));
    }

    //Если находимся в разделе "Рабочий. Просмотр ВСЕХ заданий"
    const workerAppTasksRouteMatch = matchPath(currentPathname, {
      path: WORKER_APP_TASKS_ROUTE,
    });
    if(workerAppTasksRouteMatch !== null) {
      return dispatch(_updateIfOnWorkerAppTasksScreen({
        allTasksTablesIds,
        parentSheetId,
        sheetOperationsTableId,
        changedOrderId,
        isOneOfBatchesFinished,
        isOrderFinished,
      }));
    }

    /*
    * Для всех остальных разделов не нужна какая-то дополнительная обработка, но и для этого случая необходимо
    * очистить данные всех интерфейсов, где должны быть обновления из-за разделения партии,
    * чтобы при следующем входе в эти разделы данные были запрошены заново
    * */

    const tableIdsToClear = [
      sheetOperationsTableId,
      SHEET_TYPE.IN_PRODUCTION,
      MASTER_TASKS_TO_DO_TABLE_ID,
      MASTER_COMPLETED_TASKS_TABLE_ID,
      SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      TASKS_MODEL,
      ...allTasksTablesIds,
    ];

    if(isOneOfBatchesFinished) {
      tableIdsToClear.push(SHEET_TYPE.COMPLETED);
    }

    const actionsToDispatch = [
      clearTableRemoteData(tableIdsToClear),

      ...getEntityBatchSplitCaseSpecificActions({
        isOneOfBatchesFinished,
        parentSheetId,
      }),

      ...getOrderCompletedRelatedActions(isOrderFinished, changedOrderId),
    ];

    dispatch(actionsToDispatch);
  };


const _updateIfOnSheetsInProductionScreen = ({
  currentPathname,
  allTasksTablesIds,
  sheetOperationsTableId,
  parentSheetId,
  changedOrderId,
  isOneOfBatchesFinished,
  isOrderFinished,
}) =>
  dispatch => {

    const tableIdsToClear = [
      sheetOperationsTableId,
      MASTER_TASKS_TO_DO_TABLE_ID,
      MASTER_COMPLETED_TASKS_TABLE_ID,
      SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      TASKS_MODEL,
      ...allTasksTablesIds,
    ];

    if(isOneOfBatchesFinished) {
      tableIdsToClear.push(SHEET_TYPE.COMPLETED);
    }

    const actionsToDispatch = [
      clearTableRemoteData(tableIdsToClear),

      ...getEntityBatchSplitCaseSpecificActions({
        isOneOfBatchesFinished,
        parentSheetId,
      }),

      ...getOrderCompletedRelatedActions(isOrderFinished, changedOrderId),
    ];

    dispatch(actionsToDispatch);

    dispatch(reFetchRemoteTableData(
      SHEET_TYPE.IN_PRODUCTION,
      SHEET_TYPE.IN_PRODUCTION,
      fetchSheetTypeRemoteTableDataCbFactory(
        dispatch,
        query => fetchSheetsInProduction(query, { isBlockingRequest: false }),
      ),
    ));

    const sheetInProductionReviewRouteMatch = matchPath(currentPathname, {
      path: `${PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE}/:sheetId`,
    });

    if(
      sheetInProductionReviewRouteMatch !== null &&
      sheetInProductionReviewRouteMatch.params.sheetId === parentSheetId.toString()
    ) {
      dispatch(push(PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE));
    }
  };

const _updateIfOnCompletedSheetsScreen = ({
  allTasksTablesIds,
  sheetOperationsTableId,
  parentSheetId,
  changedOrderId,
  isOneOfBatchesFinished,
  isOrderFinished,
}) =>
  dispatch => {

    const tableIdsToClear = [
      sheetOperationsTableId,
      SHEET_TYPE.IN_PRODUCTION,
      MASTER_TASKS_TO_DO_TABLE_ID,
      MASTER_COMPLETED_TASKS_TABLE_ID,
      SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      TASKS_MODEL,
      ...allTasksTablesIds,
    ];

    const actionsToDispatch = [
      clearTableRemoteData(tableIdsToClear),

      ...getEntityBatchSplitCaseSpecificActions({
        isOneOfBatchesFinished,
        parentSheetId,
      }),

      ...getOrderCompletedRelatedActions(isOrderFinished, changedOrderId),
    ];

    dispatch(actionsToDispatch);

    dispatch(reFetchRemoteTableData(
      SHEET_TYPE.COMPLETED,
      SHEET_TYPE.COMPLETED,
      fetchSheetTypeRemoteTableDataCbFactory(
        dispatch,
        query => fetchCompletedSheets(query, { isBlockingRequest: false }),
      ),
    ));

  };


const _updateIfOnOrdersInProductionScreen = ({
  currentPathname,
  allTasksTablesIds,
  sheetOperationsTableId,
  parentSheetId,
  changedOrderId,
  isOneOfBatchesFinished,
}) =>

  dispatch => {

    const tableIdsToClear = [
      sheetOperationsTableId,
      SHEET_TYPE.IN_PRODUCTION,
      MASTER_TASKS_TO_DO_TABLE_ID,
      MASTER_COMPLETED_TASKS_TABLE_ID,
      SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      TASKS_MODEL,
      ...allTasksTablesIds,
      ORDER_TYPE.READY_TO_COMPLETE,
    ];

    if(isOneOfBatchesFinished) {
      tableIdsToClear.push(SHEET_TYPE.COMPLETED);
    }


    const actionsToDispatch = [
      clearTableRemoteData(tableIdsToClear),

      ...getEntityBatchSplitCaseSpecificActions({
        isOneOfBatchesFinished,
        parentSheetId,
      }),

      clearTableData(createOrderEntriesTableId(ORDER_TYPE.IN_PRODUCTION, changedOrderId)),
    ];

    dispatch(actionsToDispatch);

    dispatch(reFetchRemoteTableData(
      ORDER_TYPE.IN_PRODUCTION,
      ORDER_TYPE.IN_PRODUCTION,
      fetchOrderRemoteTableDataCbFactory(
        dispatch,
        query => fetchOrdersInProduction(query, { isBlockingRequest: false }),
      ),
    ));


    /*
    * Для случая, когда находимся на роуте просмотра заказа, который изменил свой статус на "готов к завершению",
    * выполняем редирект к списку заказов в производстве и очищаем кеш таблицы просмотра заказа
    * */
    const plannerAppOrderInProductionReviewRouteMatch = matchPath(currentPathname, {
      path: `${PLANNER_APP_ORDERS_IN_PRODUCTION_ROUTE}/:orderId`,
    });

    if(
      plannerAppOrderInProductionReviewRouteMatch !== null &&
      plannerAppOrderInProductionReviewRouteMatch.params.orderId === changedOrderId.toString()
    ) {
      dispatch(push(PLANNER_APP_ORDERS_IN_PRODUCTION_ROUTE));
    }

  };


const _updateIfOnOrdersReadyToCompleteScreen = ({
  allTasksTablesIds,
  sheetOperationsTableId,
  parentSheetId,
  changedOrderId,
  isOneOfBatchesFinished,
}) =>
  dispatch => {

    const tableIdsToClear = [
      sheetOperationsTableId,
      SHEET_TYPE.IN_PRODUCTION,
      MASTER_TASKS_TO_DO_TABLE_ID,
      MASTER_COMPLETED_TASKS_TABLE_ID,
      SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      TASKS_MODEL,
      ...allTasksTablesIds,
      ORDER_TYPE.IN_PRODUCTION,
    ];

    if(isOneOfBatchesFinished) {
      tableIdsToClear.push(SHEET_TYPE.COMPLETED);
    }

    const actionsToDispatch = [
      clearTableRemoteData(tableIdsToClear),

      ...getEntityBatchSplitCaseSpecificActions({
        isOneOfBatchesFinished,
        parentSheetId,
      }),

      clearTableData(createOrderEntriesTableId(ORDER_TYPE.IN_PRODUCTION, changedOrderId)),
    ];

    dispatch(actionsToDispatch);

    dispatch(reFetchRemoteTableData(
      ORDER_TYPE.READY_TO_COMPLETE,
      ORDER_TYPE.READY_TO_COMPLETE,
      fetchOrderRemoteTableDataCbFactory(
        dispatch,
        query => fetchOrdersReadyToComplete(query, { isBlockingRequest: false }),
      ),
    ));
  };


const _updateIfOnDefaultSheetsWaitingPartsAndMaterialsScreen = ({
  currentPathname,
  allTasksTablesIds,
  sheetOperationsTableId,
  parentSheetId,
  changedOrderId,
  isOneOfBatchesFinished,
  isOrderFinished,
  childSheetId,
  isParentEntityBatchWasFinished,
  isChildEntityBatchWasFinished,
}) =>
  dispatch => {

    const tableIdsToClear = [
      sheetOperationsTableId,
      SHEET_TYPE.IN_PRODUCTION,
      MASTER_TASKS_TO_DO_TABLE_ID,
      MASTER_COMPLETED_TASKS_TABLE_ID,
      SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      TASKS_MODEL,
      ...allTasksTablesIds,
    ];

    if(isOneOfBatchesFinished) {
      tableIdsToClear.push(SHEET_TYPE.COMPLETED);
    }

    const actionsToDispatch = [
      clearTableRemoteData(tableIdsToClear),

      ...getOrderCompletedRelatedActions(isOrderFinished, changedOrderId),
    ];


    if(isOneOfBatchesFinished) {
      actionsToDispatch.push(
        clearAllDefaultSheetsPartsAndMaterialsToConsume(),
      );

      actionsToDispatch.push(
        deleteAllAssemblySheetsReserveData(),
      );

      actionsToDispatch.push(
        deleteAssemblySheetConsumeData({ sheetId: parentSheetId }),
      );

    } else {
      actionsToDispatch.push(
        clearDefaultSheetPartsAndMaterialsToConsume(parentSheetId),
      );

      actionsToDispatch.push(
        deleteAssemblySheetReserveData({ sheetId: parentSheetId }),
      );

      actionsToDispatch.push(
        deleteAssemblySheetConsumeData({ sheetId: parentSheetId }),
      );
    }

    if(isParentEntityBatchWasFinished) {
      actionsToDispatch.push(
        deleteEntitiesFromStore(SHEET_MODEL, [parentSheetId]),
      );
    }

    /*
    * На всякий случай, пытаемся удалить и партию потомка, если она каким-то
    * образом успела попасть в entities. Скорее всего, при этой обработке её
    * там не будет, но в таком случае экшен тоже отрабатает корректно
    * */
    if(isChildEntityBatchWasFinished) {
      actionsToDispatch.push(
        deleteEntitiesFromStore(SHEET_MODEL, [childSheetId]),
      );
    }

    dispatch(actionsToDispatch);


    const defaultSheetWaitingPartsAndMaterialsReviewRouteMatch = matchPath(currentPathname, {
      path: `${STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE}/:sheetId`,
    });

    if(
      defaultSheetWaitingPartsAndMaterialsReviewRouteMatch !== null &&
      defaultSheetWaitingPartsAndMaterialsReviewRouteMatch.params.sheetId === parentSheetId.toString()
    ) {
      dispatch(push(STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE));
    }



    let areDefaultSheetsWithEnoughPartsAndMaterialsRequested = false;

    if(isOneOfBatchesFinished) {
      areDefaultSheetsWithEnoughPartsAndMaterialsRequested = true;

      dispatch(fetchDefaultSheetsWithEnoughPartsAndMaterialsInStorage({ isBlockingRequest: false }));
    }

    if(
      isOneOfBatchesFinished &&
      defaultSheetWaitingPartsAndMaterialsReviewRouteMatch !== null &&
      defaultSheetWaitingPartsAndMaterialsReviewRouteMatch.params.sheetId !== parentSheetId.toString()
    ) {

      const {
        sheetId: sheetIdFromRoute,
      } = defaultSheetWaitingPartsAndMaterialsReviewRouteMatch.params;

      const fetchSheet = fetchSheetTypeToReviewCbFactory(
        dispatch,
        query => fetchSheetsInProduction(query, { isBlockingRequest: false }),
      );

      fetchSheet(sheetIdFromRoute)
        .then(response => {

          if(
            !!response &&
            response.responseMeta.count !== 0
          ) {
            const { entityBatchId } = response.entities[SHEET_MODEL][sheetIdFromRoute];
            dispatch(fetchPossiblePartsAndMaterialsToConsumeForDefaultSheet(
              entityBatchId,
              sheetIdFromRoute,
              { isBlockingRequest: false },
            ));
          }
        });
    }


    let sheetIdsToUpdateInList = [];

    if(!isParentEntityBatchWasFinished) {
      sheetIdsToUpdateInList.push(parentSheetId);
    }

    if(!isChildEntityBatchWasFinished) {
      sheetIdsToUpdateInList.push(childSheetId);
    }

    if(sheetIdsToUpdateInList.length > 0) {
      const query = {
        filter: {
          filterGroupType: FILTER_GROUP_TYPES.AND,
          filters: [
            {
              column: 'id',
              filterType: FILTER_TYPES.ONE_OF,
              filterValue: sheetIdsToUpdateInList,
            },
          ],
        },
        with: SHEETS_REMOTE_TABLE_DATA_WITH_PARAMS,
      };

      dispatch(fetchSheetsInProduction(query, { isBlockingRequest: false }))
        .then(response => {

          if(
            !!response &&
            response.responseMeta.count !== 0
          ) {
            const {
              entities,
              responseEntitiesIds,
            } = response;

            const firstSheetId = responseEntitiesIds[SHEET_MODEL][0];

            const { isAssembly } = entities[SHEET_MODEL][firstSheetId];

            if(!isAssembly) {
              /*
              * Новая отделенная партия копирует информацию исходной партии, поэтому не важно на основании какой из партий
              * мы будем проверять укомплектованность партий, берем первую по порядку из запрошенных (т.е. любую), флаг
              * укомплектованности у партий будет одинаков, поэтом это не важно. Либо обе партии укомплектованы, тогда
              * ничего обновлять в списке не надо, либо обе партии неукомплектованы, тогда нужно добавить новую партию в
              * список, а по старой обновить данные, т.к. количество изменилось. Не паримся и обновляем все данные, добавляя,
              * из в entities, а не только количество. После этого перезапрашиваем МЛ, которые можно укомплектовать
              * */
              const entityBatchId = responseEntitiesIds[ENTITY_BATCH_MODEL][0];

              const { providingState } = entities[ENTITY_BATCH_MODEL][entityBatchId];

              if(providingState === ENTITY_BATCH_PROVIDING_STATE.UNPROVIDED) {
                dispatch(addEntitiesToStore(entities));


                if(
                  !areDefaultSheetsWithEnoughPartsAndMaterialsRequested
                ) {
                  return dispatch(fetchDefaultSheetsWithEnoughPartsAndMaterialsInStorage({ isBlockingRequest: false }));
                }

              }

            }
          }
        });
    }
  };


const _updateIfOnAssemblySheetsWaitingPartsAndMaterialsScreen = ({
  currentPathname,
  parentEntityBatchId,
  allTasksTablesIds,
  sheetOperationsTableId,
  parentSheetId,
  changedOrderId,
  isOneOfBatchesFinished,
  isOrderFinished,
  isParentEntityBatchWasFinished,
}) =>
  dispatch => {

    const tableIdsToClear = [
      sheetOperationsTableId,
      SHEET_TYPE.IN_PRODUCTION,
      MASTER_TASKS_TO_DO_TABLE_ID,
      MASTER_COMPLETED_TASKS_TABLE_ID,
      TASKS_MODEL,
      ...allTasksTablesIds,
    ];

    if(isOneOfBatchesFinished) {
      tableIdsToClear.push(SHEET_TYPE.COMPLETED);
    }

    const actionsToDispatch = [
      clearTableRemoteData(tableIdsToClear),

      ...getOrderCompletedRelatedActions(isOrderFinished, changedOrderId),
    ];

    if(isOneOfBatchesFinished) {
      actionsToDispatch.push(
        clearAllDefaultSheetsPartsAndMaterialsToConsume(),
      );

      actionsToDispatch.push(
        deleteEntitiesFromStore(SHEET_MODEL, [parentSheetId]),
      );

      actionsToDispatch.push(
        deleteAssemblySheetConsumeData({ sheetId: parentSheetId }),
      );

    } else {
      actionsToDispatch.push(
        clearDefaultSheetPartsAndMaterialsToConsume(parentSheetId),
      );

      actionsToDispatch.push(
        deleteAssemblySheetConsumeData({ sheetId: parentSheetId }),
      );
    }

    dispatch(actionsToDispatch);

    dispatch(reFetchRemoteTableData(
      SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      fetchSheetTypeRemoteTableDataCbFactory(
        dispatch,
        query => fetchFullAssemblySheetsPartsAndMaterials(query, { isBlockingRequest: false }),
      ),
    ));

    const partsAndMaterialsReserveRouteMatch = matchPath(currentPathname, {
      path: `${STORAGE_MANAGEMENT_APP_ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE}/:sheetId`,
    });


    if (partsAndMaterialsReserveRouteMatch === null) {

      return isOneOfBatchesFinished ?
        dispatch(deleteAllAssemblySheetsReserveData()) :
        dispatch(deleteAssemblySheetReserveData({ sheetId: parentSheetId }));
    }


    const currentSheetIdFromRouteParams = partsAndMaterialsReserveRouteMatch.params.sheetId;

    if(
      currentSheetIdFromRouteParams !== parentSheetId.toString()
    ) {

      if(!isOneOfBatchesFinished) {
        return dispatch(deleteAssemblySheetReserveData({ sheetId: parentSheetId }));
      }

      const query = {
        filter: {
          filterGroupType: FILTER_GROUP_TYPES.AND,
          filters: [
            {
              column: 'id',
              filterType: FILTER_TYPES.EQUALS,
              filterValue: currentSheetIdFromRouteParams,
            },
          ],
        },
      };

      return dispatch(fetchEntitiesFromServer(
        SHEET_MODEL,
        query,
        { isBlockingRequest: false },
      ))
        .then(response => {
          const {
            responseEntitiesIds,
            responseMeta: {
              count,
            },
          } = response;

          if (count === 0) {
            return dispatch(deleteAllAssemblySheetsReserveData([currentSheetIdFromRouteParams]));
          }

          const currentEntityBatchId = responseEntitiesIds[SHEET_MODEL][0];

          dispatch(deleteAllAssemblySheetsReserveData([currentSheetIdFromRouteParams]));
          return dispatch(fetchPartAndMaterialsReserveDataForAssemblyEntityBatch(
            currentEntityBatchId,
            currentSheetIdFromRouteParams,
            undefined,
            { isBlockingRequest: false },
          ));
        });

    }


    if(!isOneOfBatchesFinished) {
      return dispatch(fetchPartAndMaterialsReserveDataForAssemblyEntityBatch(
        parentEntityBatchId,
        parentSheetId,
        undefined,
        { isBlockingRequest: false },
      ));
    }

    if(isParentEntityBatchWasFinished) {
      return dispatch([
        push(STORAGE_MANAGEMENT_APP_ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE),
        deleteAllAssemblySheetsReserveData(),
      ]);
    }

    dispatch(deleteAllAssemblySheetsReserveData([parentSheetId]));
    return dispatch(fetchPartAndMaterialsReserveDataForAssemblyEntityBatch(
      parentEntityBatchId,
      parentSheetId,
      undefined,
      { isBlockingRequest: false },
    ));
  };

/*
* Если находимся в разделе "Мастер. Требуется выполнить", то очищаем все данные других интерфейсов кроме этого, где
* должны быть обновления из-за деления партии, а для этого раздела перезапрашиваем данные для таблицы заданий,
* которые нужно выполнить в подразделениях из параметров роутинга, с учетом текущих параметров таблицы
* */
const _updateIfOnMasterTasksTodoScreen = ({
  allTasksTablesIds,
  sheetOperationsTableId,
  parentSheetId,
  changedOrderId,
  isOneOfBatchesFinished,
  isOrderFinished,
  departmentIds,
}) =>
  (dispatch, getState) => {
    const state = getState();

    const tableIdsToClear = [
      sheetOperationsTableId,
      SHEET_TYPE.IN_PRODUCTION,
      MASTER_COMPLETED_TASKS_TABLE_ID,
      SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      TASKS_MODEL,
      ...allTasksTablesIds,
    ];

    if(isOneOfBatchesFinished) {
      tableIdsToClear.push(SHEET_TYPE.COMPLETED);
    }

    const actionsToDispatch = [
      clearTableRemoteData(tableIdsToClear),

      ...getEntityBatchSplitCaseSpecificActions({
        isOneOfBatchesFinished,
        parentSheetId,
      }),

      ...getOrderCompletedRelatedActions(isOrderFinished, changedOrderId),
    ];

    dispatch(actionsToDispatch);


    const masterTasksToDoAdditionalFilters = masterTasksToDoAdditionalFiltersSelector(state);

    dispatch(reFetchRemoteTableData(
      MASTER_TASKS_TO_DO_TABLE_ID,
      MASTER_TASKS_TO_DO_TABLE_MODEL,
      ({ tableParams }) =>
        dispatch(fetchMasterDepartmentsTasksToDoRemoteTableData(
          departmentIds,
          masterTasksToDoAdditionalFilters,
          tableParams,
          { isBlockingRequest: false },
        )),
    ));
  };

const _updateIfOnMasterCompletedTasksScreen = ({
  allTasksTablesIds,
  sheetOperationsTableId,
  parentSheetId,
  changedOrderId,
  isOneOfBatchesFinished,
  isOrderFinished,
  departmentIds,
}) =>
  dispatch => {

    const tableIdsToClear = [
      sheetOperationsTableId,
      SHEET_TYPE.IN_PRODUCTION,
      MASTER_TASKS_TO_DO_TABLE_ID,
      SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      TASKS_MODEL,
      ...allTasksTablesIds,
    ];

    if(isOneOfBatchesFinished) {
      tableIdsToClear.push(SHEET_TYPE.COMPLETED);
    }

    const actionsToDispatch = [
      clearTableRemoteData(tableIdsToClear),

      ...getEntityBatchSplitCaseSpecificActions({
        isOneOfBatchesFinished,
        parentSheetId,
      }),

      ...getOrderCompletedRelatedActions(isOrderFinished, changedOrderId),
    ];

    dispatch(actionsToDispatch);

    dispatch(reFetchRemoteTableData(
      MASTER_COMPLETED_TASKS_TABLE_ID,
      MASTER_COMPLETED_TASKS_TABLE_MODEL,
      ({ tableParams }) =>
        dispatch(fetchMasterDepartmentsCompletedTasksRemoteTableData(
          departmentIds,
          tableParams,
          { isBlockingRequest: false },
        )),
    ));
  };



const _updateIfOnTasksViewScreen = ({
  allTasksTablesIds,
  parentSheetId,
  sheetOperationsTableId,
  changedOrderId,
  isOneOfBatchesFinished,
  isOrderFinished,
  departmentIdFromRoute,
  equipmentClassIdFromRoute,
}) =>
  dispatch => {

    const currentTableId = getEquipmentClassInDepartmentTasksTableId(
      departmentIdFromRoute,
      equipmentClassIdFromRoute,
    );

    //Текущую таблицу не очищаем, чтобы не было скачков в интерфейсе, данные в ней обновятся при перезапросе данных
    const allTasksTablesIdsWithoutCurrentTableId = allTasksTablesIds
      .filter(tableId => tableId !== currentTableId);

    const tableIdsToClear = [
      sheetOperationsTableId,
      SHEET_TYPE.IN_PRODUCTION,
      MASTER_TASKS_TO_DO_TABLE_ID,
      MASTER_COMPLETED_TASKS_TABLE_ID,
      SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      TASKS_MODEL,
      ...allTasksTablesIdsWithoutCurrentTableId,
    ];

    if(isOneOfBatchesFinished) {
      tableIdsToClear.push(SHEET_TYPE.COMPLETED);
    }

    const actionsToDispatch = [
      clearTableRemoteData(tableIdsToClear),

      ...getEntityBatchSplitCaseSpecificActions({
        isOneOfBatchesFinished,
        parentSheetId,
      }),

      ...getOrderCompletedRelatedActions(isOrderFinished, changedOrderId),
    ];

    dispatch(actionsToDispatch);

    dispatch(reFetchRemoteTableData(
      currentTableId,
      currentTableId,
      ({ tableParams }) =>
        dispatch(fetchEquipmentClassInDepartmentTasksRemoteTableData(
          {
            departmentIdsArray: [departmentIdFromRoute],
            equipmentClassIdsArray: [equipmentClassIdFromRoute],
          },
          tableParams,
          { isBlockingRequest: false },
        )),
    ));
  };


const _updateIfOnWorkerAppTasksScreen = ({
  allTasksTablesIds,
  parentSheetId,
  sheetOperationsTableId,
  changedOrderId,
  isOneOfBatchesFinished,
  isOrderFinished,
}) =>
  dispatch => {

    const tableIdsToClear = [
      sheetOperationsTableId,
      SHEET_TYPE.IN_PRODUCTION,
      MASTER_TASKS_TO_DO_TABLE_ID,
      MASTER_COMPLETED_TASKS_TABLE_ID,
      SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      ...allTasksTablesIds,
    ];

    const actionsToDispatch = [
      clearTableRemoteData(tableIdsToClear),

      ...getEntityBatchSplitCaseSpecificActions({
        isOneOfBatchesFinished,
        parentSheetId,
      }),

      ...getOrderCompletedRelatedActions(isOrderFinished, changedOrderId),
    ];

    dispatch(actionsToDispatch);

    dispatch(reFetchRemoteTableData(
      TASKS_MODEL,
      TASKS_MODEL,
      ({ tableParams }) =>
        dispatch(fetchTasksRemoteTableData(tableParams, { isBlockingRequest: false })),
    ));
  };
