import { humanizeTimeFromHoursFactory } from '@bfg-frontend/utils/lib/humanizeTimeFromHoursFactory/index';
import { HoursLabelTrans, MinutesLabelTrans, SecondsLabelTrans } from '../commonTransComponents';
import React from 'react';


const humanizedTimeStringBuilder = (timeUnitsArray, options) => {
  const { minutesLabel } = options;

  /*
   По умолчанию humanizeTimeFromHoursFactory обрезает секунды. В timeUnitsArray приходят только значения подходящие
   по формату. То есть, если задан параметр shouldRenderSeconds = false и значение hours, например 0.01 (меньше минуты),
   то массив timeUnitsArray будет пустым. По умолчанию для таких случаем возвращается строка "1 мин.", но можно
   переопределить эту логику в stringBuilder.
   */
  if (timeUnitsArray.length === 0) {
    return (
      <span>
        {'1 '}
        {minutesLabel}
      </span>
    );
  }

  return (
    <span>
      {
        timeUnitsArray
          .map(({ value, label, id }, i) => {
            return (
              <span key={id}>
                {value}
                {' '}
                {label}
                {i === timeUnitsArray.length - 1 ? '' : ' '}
              </span>
            );
          })
      }
    </span>
  );
};

export const humanizeTimeFromHoursWithoutSeconds = humanizeTimeFromHoursFactory({
  hoursLabel: HoursLabelTrans,
  minutesLabel: MinutesLabelTrans,
  secondsLabel: SecondsLabelTrans,
  stringBuilder: humanizedTimeStringBuilder,
});
