import { Trans } from '@lingui/macro';
import React from 'react';
import { COLUMN_TYPE } from '../../../constants/table';


export const MASTER_TASKS_TO_DO_TABLE_MODEL = 'MASTER_TASKS_TO_DO_TABLE_MODEL';
export const MASTER_COMPLETED_TASKS_TABLE_MODEL = 'MASTER_COMPLETED_TASKS_TABLE_MODEL';

export const MASTER_TASKS_COLUMNS = {
  ORDER_NAME: 'order__name',
  SHEET_IDENTITY: 'entityRouteSheet__identity',
  DEPARTMENT_IDENTITY: 'department__identity',
  DEPARTMENT_NAME: 'department__name',
  EQUIPMENT_CLASS_IDENTITY: 'equipmentClass__identity',
  EQUIPMENT_CLASS_NAME: 'equipmentClass__name',
  ENTITY_IDENTITY: 'entity__identity',
  ENTITY_CODE: 'entity__code',
  ENTITY_NAME: 'entity__name',
  OPERATION_IDENTITY: 'operation__identity',
  OPERATION_NUMBER: 'operation__nop',
  OPERATION_NAME: 'operation__name',
  ENTITIES_IN_BATCH_AMOUNT: 'entitiesInBatchAmount',
  OPERATION_START_DATE: 'startDate',
  OPERATION_STOP_DATE: 'stopDate',
  OPERATION_PROD_TIME: 'operationProdTime',
  PROGRESS: 'progress',
  OPERATION_LABOR: 'operationLabor',
  ASSIGNEES: 'assignees',
  EQUIPMENT: 'equipment',
};

export const masterTasksToDoSchema = {
  fields: {
    [MASTER_TASKS_COLUMNS.ORDER_NAME]: {
      columnName: MASTER_TASKS_COLUMNS.ORDER_NAME,
      displayName: (
        <Trans id="master_tasks.table_column@order_name">
          Заказ
        </Trans>
      ),
      order: 0,
      type: COLUMN_TYPE.STRING,
      customComponent: 'OrderNameRenderer',
    },
    [MASTER_TASKS_COLUMNS.SHEET_IDENTITY]: {
      columnName: MASTER_TASKS_COLUMNS.SHEET_IDENTITY,
      displayName: (
        <Trans id="master_tasks.table_column@sheet">
          Маршрутный лист
        </Trans>
      ),
      order: 1,
      type: COLUMN_TYPE.STRING,
    },
    [MASTER_TASKS_COLUMNS.DEPARTMENT_IDENTITY]:{
      columnName: MASTER_TASKS_COLUMNS.DEPARTMENT_IDENTITY,
      displayName: (
        <Trans id="master_tasks.table_column@department_identity">
          ИД Подразделения
        </Trans>
      ),
      order: 2,
      type: COLUMN_TYPE.STRING,
      show: false,
      disableFilter: true,
    },
    [MASTER_TASKS_COLUMNS.DEPARTMENT_NAME]: {
      columnName: MASTER_TASKS_COLUMNS.DEPARTMENT_NAME,
      displayName: (
        <Trans id="master_tasks.table_column@department_name">
          Подразделение
        </Trans>
      ),
      order: 3,
      type: COLUMN_TYPE.STRING,
      show: false,
      disableFilter: true,
    },
    [MASTER_TASKS_COLUMNS.EQUIPMENT_CLASS_IDENTITY]: {
      columnName: MASTER_TASKS_COLUMNS.EQUIPMENT_CLASS_IDENTITY,
      displayName: (
        <Trans id="master_tasks.table_column@equipment_class_identity">
          ИД Класса РЦ
        </Trans>
      ),
      order: 4,
      type: COLUMN_TYPE.STRING,
      show: false,
      disableFilter: true,
    },
    [MASTER_TASKS_COLUMNS.EQUIPMENT_CLASS_NAME]: {
      columnName: MASTER_TASKS_COLUMNS.EQUIPMENT_CLASS_NAME,
      displayName: (
        <Trans id="master_tasks.table_column@equipment_class_name">
          Класс РЦ
        </Trans>
      ),
      order: 5,
      type: COLUMN_TYPE.STRING,
    },
    [MASTER_TASKS_COLUMNS.ENTITY_IDENTITY]: {
      columnName: MASTER_TASKS_COLUMNS.ENTITY_IDENTITY,
      type: COLUMN_TYPE.STRING,
      displayName: (
        <Trans id="master_tasks.table_column@entity_identity">
          ИД ДСЕ
        </Trans>
      ),
      order: 6,
      show: false,
      disableFilter: true,
    },
    [MASTER_TASKS_COLUMNS.ENTITY_CODE]: {
      columnName: MASTER_TASKS_COLUMNS.ENTITY_CODE,
      type: COLUMN_TYPE.STRING,
      displayName: (
        <Trans id="master_tasks.table_column@entity_code">
          Шифр ДСЕ
        </Trans>
      ),
      order: 7,
      show: false,
      disableFilter: true,
    },
    [MASTER_TASKS_COLUMNS.ENTITY_NAME]: {
      columnName: MASTER_TASKS_COLUMNS.ENTITY_NAME,
      type: COLUMN_TYPE.STRING,
      displayName: (
        <Trans id="master_tasks.table_column@entity_name">
          ДСЕ
        </Trans>
      ),
      order: 8,
    },
    [MASTER_TASKS_COLUMNS.OPERATION_IDENTITY]: {
      columnName: MASTER_TASKS_COLUMNS.OPERATION_IDENTITY,
      type: COLUMN_TYPE.STRING,
      displayName: (
        <Trans id="master_tasks.table_column@operation_identity">
          ИД Операции
        </Trans>
      ),
      order: 9,
      show: false,
      disableFilter: true,
    },
    [MASTER_TASKS_COLUMNS.OPERATION_NUMBER]: {
      columnName: MASTER_TASKS_COLUMNS.OPERATION_NUMBER,
      type: COLUMN_TYPE.NUMBER,
      displayName: (
        <Trans id="master_tasks.table_column@operation_number">
          № Операции
        </Trans>
      ),
      order: 10,
      disableFilter: true,
    },
    [MASTER_TASKS_COLUMNS.OPERATION_NAME]: {
      columnName: MASTER_TASKS_COLUMNS.OPERATION_NAME,
      type: COLUMN_TYPE.STRING,
      displayName: (
        <Trans id="master_tasks.table_column@operation_name">
          Операция
        </Trans>
      ),
      order: 11,
    },
    [MASTER_TASKS_COLUMNS.ENTITIES_IN_BATCH_AMOUNT]: {
      columnName: MASTER_TASKS_COLUMNS.ENTITIES_IN_BATCH_AMOUNT,
      displayName: (
        <Trans id="master_tasks.table_column@entities_in_batch_amount">
          Размер партии
        </Trans>
      ),
      order: 12,
      type: COLUMN_TYPE.NUMBER,
      disableFilter: true,
    },
    [MASTER_TASKS_COLUMNS.OPERATION_START_DATE]: {
      columnName: MASTER_TASKS_COLUMNS.OPERATION_START_DATE,
      type: COLUMN_TYPE.DATE,
      displayName: (
        <Trans id="master_tasks.table_column@operation_start_date">
          Плановая дата начала
        </Trans>
      ),
      order: 13,
      customComponent: 'DateRenderer',
      disableFilter: true,
      show: false,
    },
    [MASTER_TASKS_COLUMNS.OPERATION_STOP_DATE]: {
      columnName: MASTER_TASKS_COLUMNS.OPERATION_STOP_DATE,
      type: COLUMN_TYPE.DATE,
      displayName: (
        <Trans id="master_tasks.table_column@operation_stop_date">
          Плановая дата окончания
        </Trans>
      ),
      order: 14,
      customComponent: 'DateRenderer',
      disableFilter: true,
      show: false,
    },
    [MASTER_TASKS_COLUMNS.OPERATION_PROD_TIME]: {
      // TODO имя колонки указано неправильно, в модели это поле называется prodTime, нужно исправить схемы и селекторы
      // на текущий момент фильтрация отключена, потому что она не работает. НО даже с правильно указанной колонкой
      // работать она всё равно не будет, потому что в запросе приходят секунды, соответственно и фльтр должен быть по
      // секундам. Для дробных значений при запросе 400 ошибка. Нужно будет реализовать кастомный фильтр, каоторый
      // будет преобразовывать введённые пользователем "1ч. 2мин. 3сек." в секунды.
      columnName: MASTER_TASKS_COLUMNS.OPERATION_PROD_TIME,
      type: COLUMN_TYPE.NUMBER,
      displayName: (
        <Trans id="master_tasks.table_column@operation_prod_time">
          Тшт., ч.
        </Trans>
      ),
      order: 15,
      customComponent: 'HumanizedTimeFromHoursRenderer',
      disableFilter: true,
    },
    [MASTER_TASKS_COLUMNS.PROGRESS]: {
      columnName: MASTER_TASKS_COLUMNS.PROGRESS,
      displayName: (
        <Trans id="master_tasks.table_column@operation_progress">
          Прогресс выполнения
        </Trans>
      ),
      order: 16,
      type: COLUMN_TYPE.NUMBER,
      customComponent: 'SheetOperationProgressRenderer',
      disableFilter: true,
    },
    [MASTER_TASKS_COLUMNS.OPERATION_LABOR]: {
      columnName: MASTER_TASKS_COLUMNS.OPERATION_LABOR,
      type: COLUMN_TYPE.NUMBER,
      displayName: (
        <Trans id="master_tasks.table_column@operation_labor">
          Трудоемкость операции, ч.
        </Trans>
      ),
      order: 17,
      customComponent: 'OperationLaborRenderer',
      disableFilter: true,
    },
    [MASTER_TASKS_COLUMNS.ASSIGNEES]: {
      columnName: MASTER_TASKS_COLUMNS.ASSIGNEES,
      displayName: (
        <Trans id="master_tasks.table_column@assignees">
          Исполнители
        </Trans>
      ),
      type: COLUMN_TYPE.STRING,
      order: 18,
      customComponent: 'AssigneesRenderer',
    },
    [MASTER_TASKS_COLUMNS.EQUIPMENT]: {
      columnName: MASTER_TASKS_COLUMNS.EQUIPMENT,
      displayName: (
        <Trans id="master_tasks.table_column@equipment">
          Оборудование
        </Trans>
      ),
      order: 19,
      type: COLUMN_TYPE.STRING,
      customComponent: 'MasterTasksEquipmentCombinedDataRenderer',
    },
  },
};

export const completedMasterTasksSchema = {
  fields: {
    ...masterTasksToDoSchema.fields,

    [MASTER_TASKS_COLUMNS.EQUIPMENT_CLASS_IDENTITY]: {
      ...masterTasksToDoSchema.fields[MASTER_TASKS_COLUMNS.EQUIPMENT_CLASS_IDENTITY],
      disableFilter: false,
    },

    [MASTER_TASKS_COLUMNS.ENTITY_IDENTITY]: {
      ...masterTasksToDoSchema.fields[MASTER_TASKS_COLUMNS.ENTITY_IDENTITY],
      disableFilter: false,
    },

    [MASTER_TASKS_COLUMNS.ENTITY_CODE]: {
      ...masterTasksToDoSchema.fields[MASTER_TASKS_COLUMNS.ENTITY_CODE],
      disableFilter: false,
    },

    [MASTER_TASKS_COLUMNS.OPERATION_IDENTITY]: {
      ...masterTasksToDoSchema.fields[MASTER_TASKS_COLUMNS.OPERATION_IDENTITY],
      disableFilter: false,
    },

    [MASTER_TASKS_COLUMNS.OPERATION_NUMBER]: {
      ...masterTasksToDoSchema.fields[MASTER_TASKS_COLUMNS.OPERATION_NUMBER],
      disableFilter: false,
    },

    [MASTER_TASKS_COLUMNS.ASSIGNEES]: {
      ...masterTasksToDoSchema.fields[MASTER_TASKS_COLUMNS.ASSIGNEES],
      disableFilter: true,
    },

    [MASTER_TASKS_COLUMNS.EQUIPMENT]: {
      ...masterTasksToDoSchema.fields[MASTER_TASKS_COLUMNS.EQUIPMENT],
      disableFilter: true,
    },
  },
};
