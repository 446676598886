import _isFunction from 'lodash/isFunction';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import MuiPagination from '@mui/material/Pagination';
import './style.css';
import { FUNC_IS_REQUIRED_TYPE } from '../../../constants/propTypes';
import { MATERIAL_UI_STYLE_COLOR } from '../../../constants/materialUI';
import { AVAILABLE_TABLE_PAGE_SIZES, DEFAULT_TABLE_PAGE_SIZE } from '../../../constants/table';
import { Autocomplete } from '../Autocomplete/Autocomplete';


const MATERIAL_UI_CLASSES = {
  root: 'common-pagination',
};

export const Pagination = props => {
  const {
    className,
    activePage,
    totalPagesAmount,
    changePage,
    withPageSizeSelect,
    pageSize = DEFAULT_TABLE_PAGE_SIZE,
    changePageSize,
    pageSizes = AVAILABLE_TABLE_PAGE_SIZES,
  } = props;

  return (
    <div className={className}>
      <MuiPagination
          classes={MATERIAL_UI_CLASSES}
          page={activePage}
          count={totalPagesAmount}
          onChange={(_, newActivePage) => changePage(newActivePage)}
          boundaryCount={2}
          color={MATERIAL_UI_STYLE_COLOR.PRIMARY}
          shape="rounded"
      />

      {withPageSizeSelect && _isFunction(changePageSize) &&
       <PageSizeSelect
           pageSize={pageSize}
           changePageSize={changePageSize}
           pageSizes={pageSizes}
       />
      }
    </div>
  );
};

Pagination.propTypes = {
  className: PropTypes.string,
  activePage: PropTypes.number.isRequired,
  totalPagesAmount: PropTypes.number.isRequired,
  changePage: FUNC_IS_REQUIRED_TYPE,

  withPageSizeSelect: PropTypes.bool,
  pageSize: PropTypes.number,
  changePageSize: PropTypes.func,
  pageSizes: PropTypes.arrayOf(PropTypes.number),
};


const PageSizeSelect = ({
  pageSize,
  pageSizes,
  changePageSize,
}) => {
  const options = useMemo(() => pageSizes
    .map(size => (
      {
        value: size,
        label: size,
      }
    )), [pageSizes]);

  const autocompleteValue = {
    value: pageSize,
    label: pageSize,
  };

  return (
    <Autocomplete
        wrapperClassName="pagination-page-size-wrapper"
        value={autocompleteValue}
        onChange={({ value }) => changePageSize(value)}
        isSearchable={false}
        options={options}
        getOptionValue={({ value }) => value}
        getOptionLabel={({ label }) => label}
        isClearable={false}
    />
  );
};

PageSizeSelect.propTypes = {
  pageSizes: PropTypes.arrayOf(PropTypes.number).isRequired,
  pageSize: PropTypes.number.isRequired,
  changePageSize: FUNC_IS_REQUIRED_TYPE,
};