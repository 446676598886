import { useCallback, useEffect, useRef, useState } from 'react';

const PARTIAL_RENDERING_STEP = 200;

/**
 * usePartialRendering Используется для случаев, когда одновременно нужно отрендерить большое количество элементов
 * (~1000+) и при их одновременной отрисовке возникает заметный лаг (0.5с - 1с). Хук вычисляет разметку этих элементов
 * порционно по step штук и сразу возвращает "отрендеренную" часть.
 * После первой отрисовки используется обычный рендеринг без requestAnimationFrame, чтобы при добавлении/удалении
 * элементов не было ступенчатой перерисовки со скачками интерфейса.
 *
 * @param itemsDataList { array } - массив данных для списка элементов
 * @param renderItem { function } - рендер-калбек, который вызывается для каждого элемента itemsDataList
 * @param step { number } - количество элементов, которые рендерятся за один раз
 * @returns {*[]}
 */
export const usePartialRendering = (itemsDataList, renderItem, step = PARTIAL_RENDERING_STEP) => {
  const renderedAmountRef = useRef(0);
  const isFirstRenderFinished = useRef(false);
  const lastRequestAnimationFrameIdRef = useRef(null);

  const [renderedList, setRenderedList] = useState([]);


  const renderItemsList = useCallback(() => {
    if (isFirstRenderFinished.current) return;

    const currentRenderedAmount = renderedAmountRef.current;

    const newItemsMarkup = itemsDataList
      .slice(currentRenderedAmount, step + currentRenderedAmount)
      .map(renderItem);

    renderedAmountRef.current = renderedAmountRef.current + newItemsMarkup.length;

    setRenderedList(prevState => prevState.concat(newItemsMarkup));

    if (renderedAmountRef.current !== itemsDataList.length) {
      lastRequestAnimationFrameIdRef.current = requestAnimationFrame(renderItemsList);
      return;
    }

    isFirstRenderFinished.current = true;

  }, [step, itemsDataList, renderItem]);


  useEffect(() => {
    lastRequestAnimationFrameIdRef.current = requestAnimationFrame(renderItemsList);
    /* Не добавляем зависимости, потому что асинхронный рендеринг нужен только в первый раз. */
    // eslint-disable-next-line
  }, []);


  /*
  отменяем выполнение последнего запущенного requestAnimationFrame для случаев, когда компонент анмаунтится во время
  рендеринга списка
  */
  useEffect(() => {
    return () => cancelAnimationFrame(lastRequestAnimationFrameIdRef.current);
  });

  if (isFirstRenderFinished.current) return itemsDataList.map(renderItem);


  return renderedList;
};