import { connect } from 'react-redux';

import { bindActionCreators, compose } from 'redux';
import { PERMISSION } from '../../../../hoc/withPermissionsManager/constants';

import {
  detailedDataToConsumeForDefaultSheetSelector,
  sheetOperationsRemoteTableDataSelector,
} from '../../../../selectors/sheets';
import {
  broadcastSheetFinished,
  consumePartsAndMaterialsForDefaultSheet,
  fetchPossiblePartsAndMaterialsToConsumeForDefaultSheet,
  fetchReviewedSheetOperationsRemoteTableEntities,
  finishSheet, sendEntityBatchSplitNotification,
  sendSheetFinishedNotification,
  sendSheetPausedNotification,
} from '../../../../operations/sheets';
import { SheetInProductionReviewContent } from './SheetInProductionReviewContent';
import { ENTITY_BATCH_PROVIDING_STATE, SHEET_TYPE } from '../../../../constants/sheets';
import { SHEET_MODEL } from '../../../../constants/models';
import { clearTableData, clearTableRemoteData, reFetchRemoteTableData } from '../../../../reducers/table/actions';
import { allTasksTablesIdsSelector } from '../../../../selectors/taskView';
import { createOrderEntriesTableId, createSheetTypeOperationsTableId } from '../../../../utils/tables';
import { deleteEntitiesFromStore } from '../../../../reducers/entities/actions';
import {
  clearAllDefaultSheetsPartsAndMaterialsToConsume,
  clearDefaultSheetPartsAndMaterialsToConsume,
} from '../../../../reducers/storageManagementApp/defaultSheets/actions';
import { withPermissionsManager } from '../../../../hoc/withPermissionsManager/withPermissionsManager';
import { MASTER_TASKS_TO_DO_TABLE_ID } from '../../../MasterApp/MasterTasksToDo/constants';
import { MASTER_COMPLETED_TASKS_TABLE_ID } from '../../../MasterApp/MasterCompletedTasks/constants';
import { PAUSED_SHEETS_TABLES_IDS_ARRAY } from '../../../../constants/table';
import { ORDER_IN_PRODUCTION_AND_READY_TO_COMPLETE_TABLES_IDS, ORDER_TYPE } from '../../../../constants/orders';
import { sendOrderIsReadyToCompleteNotification } from '../../../../operations/orders';
import {
  deleteAllAssemblySheetsReserveData,
  deleteAssemblySheetReserveData,
} from '../../../../reducers/storageManagementApp/assemblySheets/reserveData/actions';
import { deleteAssemblySheetConsumeData } from '../../../../reducers/workerApp/assemblySheets/consumeData/actions';
import { DEFECT_MARKING_USE_CASES, getDefectMarkingUseCase } from '../../../../utils/sheets';
import { TASKS_MODEL } from '../../../../reducers/schemaModel/models/tasksSchema';
import {
  getEntityBatchSplitCaseSpecificActions,
  getSplitEntityBatchCaseData,
} from '../../../../api/socketApi/socketMessageHandlers/caClientMessageHandler/entityBatchSplit';
import {
  getOrderCompletedRelatedActions,
} from '../../../../api/socketApi/socketMessageHandlers/caClientMessageHandler/sheetFinish';


const _getDetailedDataToConsumeForDefaultSheet = (state, ownProps) => {

  const { PermissionsManager, sheetToReviewData } = ownProps;

  const {
    sheetId,
    isAssembly,
    providingState,
  } = sheetToReviewData;

  if(
    PermissionsManager.isGranted(PERMISSION.CONSUME_ENTITIES_FOR_DEFAULT_SHEET) &&
    !isAssembly &&
    providingState !== ENTITY_BATCH_PROVIDING_STATE.PROVIDED
  ) {
    return detailedDataToConsumeForDefaultSheetSelector(state, { sheetId });
  }

  return undefined;
};

const mapStateToProps = (state, ownProps) => {

  const {
    sheetId,
    entitiesInBatchAmount,
  } = ownProps.sheetToReviewData;

  const sheetOperationsTableId = createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId);

  return {
    sheetOperationsTableId,
    sheetOperationsTableData: sheetOperationsRemoteTableDataSelector(
      state,
      { sheetOperationsTableId, entitiesInBatchAmount },
    ),
    allTasksTablesIds: allTasksTablesIdsSelector(state),
    detailedDataToConsumeForDefaultSheet:
      _getDetailedDataToConsumeForDefaultSheet(state, ownProps),
  };
};

const mapDispatchToProps = (dispatch, { fetchSheetsData }) => ({
  ...bindActionCreators(
    {
      finishSheet,
      fetchReviewedSheetOperationsRemoteTableEntities,
      fetchPossiblePartsAndMaterialsToConsumeForDefaultSheet,
      consumePartsAndMaterialsForDefaultSheet,
    },
    dispatch,
  ),


  /*
  * Влияние события завершения МЛ на разные типы приложения и обработка этого с учетом того, что завершение МЛ
  * происходит на экране просмотра МЛ в производстве::
  *
  * 1. Плановик.
  * 1.1. Раздел "МЛ в производстве". Завершенный МЛ должен исчезнуть из списка МЛ в производстве:
  *      - Список серверный, поэтому если элемент списка исчезает со страницы, значит в списке должно произойти
  *      "смещение", т.е. подгрузиться новый элемент, если он есть, в соответствии с имеющейся сортировкой.
  *      Чтобы упросить обработку просто перезапрашиваем данные для текущих параметров списка. Если завершенный элемент
  *      на странице последний, то логика табличной абстракции, через которую реализован список, обработает этот момент
  *      автоматически и перезапросит предыдущую страницу таблицы
  *      - Т.к. МЛ завершен, то в разделе уже нельзя будет просматривать его операции, поэтому есть смысл очистить
  *      данные серверной таблицы по операциям МЛ
  *
  *
  * 1.2. Раздел "МЛ на запуск". Завершенный МЛ не влияет на этот раздел.
  *
  * 1.3. Раздел "МЛ приостановленные". Завершенный МЛ не влияет на этот раздел, т.к. завершать можно только МЛ в
  * производстве, которых нет в этом разделе
  *
  * 1.4. Раздел "МЛ завершенные". Новый завершенный МЛ должен появиться в списке:
  *      - Очищаем хранилище этого списка (табличное хранилище по ID таблицы), чтобы при следующем входе в этот раздел
  *      был выполнен новый запрос.
  *
  * 1.5 Раздел "Заказы в производстве"
  *     Если при завершении МЛ завершаются все работы по заказу, то должен обновиться список заказов в производстве.
  *     - очищаем хранилище этого списка, чтобы при следующем входе был выполнен запрос данных списка
  *     - очищаем хранилище таблицы просмотра состава заказа из списка "в производстве"
  *
  * 1.6 Раздел "Заказы, готовые к завершению"
  *     Если при завершении МЛ завершаются все работы по заказу, то должен обновиться список готовых к завершению заказов.
  *     - очищаем хранилище этого списка, чтобы при следующем входе был выполнен запрос данных списка
  *
  * 2. Комплектование.
  * 2.1. Раздел "МЛ ожидающие комплектации". В "Плановике" можно завершить и неукомплектованные МЛ, т.е. список МЛ,
  *      ожидающих комплектацию, должен обновиться, если это произошло. Кроме того, при завершении МЛ происходит
  *      пополнение склада деталями (как деталями завершенного МЛ, так и лишними деталями, которые могут быть
  *      непотребленными и возвращаются из резерва), т.е. информация о МЛ, которые можно укомплектовать и детальная
  *      комплектация для всех МЛ, если эта информация была, должны быть обновлены:
  *      - По идее, для обновления списка, нужно бы было сбросить "кэш" по этой таблице, чтобы при следующем входе
  *      на этот экран был выполнен новый запрос. НО, в данный момент этот список осталась клиентским, т.к., сейчас
  *      перевести его на серверный не получается из-за особенностей сортировки и необходимости получать дополнительные
  *      параметры о том, могут ли МЛ быть укомплектованы. Поэтому в данный момент тут другая логика. Данные для списка
  *      МЛ хранятся в entities + в специальном хранилище в store хранятся данные о МЛ, которые можно укомплектовать.
  *      Эти данные перезапрашиваются по новой при каждом входе в этот раздел, данные entities ДОЗАПИСЫВАЮТСЯ, а данные
  *      МЛ, которые можно укомплектовать ПЕРЕЗАПИСЫВАЮТСЯ ПОЛНОСТЬЮ. Поэтому, в текущей реализации, нужно только
  *      удалить из entities завершенный МЛ, чтобы  он пропал из списка.
  *      Но, эта таблица тоже должна стать серверной, поэтому это нужно будет изменить, сейчас при таком обновлении
  *      будет запрашиваться много данных.
  *      - Для обновления детально комплектации очищаем соответствующее хранилище, чтобы при следующем входе в раздел,
  *      данные по детальной комплектации для МЛ перезапрашивались бы полностью
  * 2.2 Раздел "сборочные". При завершении МЛ на складе обновляется количество ДСЕ, а от этого зависят возможность
  * резервирвоания и лейблы в списке сборочных МЛ, поэтому очищаем кеш таблицы сборочных МЛ.
  * 2.3 Раздел "сборочные", экран резервирования. При завершении МЛ меняется количество ДСЕ на складе, поэтмоу нужно
  * удалить из store все данные резервирования, чтобы при следующем перехаоде на этот экран данные обновились.
  *
  * 3. Мастер.
  * 3.1. Раздел просмотра заданий в Мастере. Должны исчезнуть все задания по завершенному МЛ из списка заданий,
  *      которые "нужно сделать" и появиться новые задания в списке "завершенных заданий":
  *      - Очищаем кэши таблиц заданий, которые "нужно сделать" и "завершенных заданий" независимо от выбранных
  *      подразделений, т.к. не знаем какие из них именно затронуло завершение нового МЛ. При следующем входе в эти
  *      разделы будет выплонен новый запрос и данные обновятся
  * 3.2 Раздел "МЛ приостановленные". Завершенный МЛ не влияет на этот раздел, т.к. завершать можно только МЛ в
  * производстве, которых нет в этом разделе
  *
  * 4. Рабочий.
  * 4.1. Раздел "Выбора подразделений". Когда завершается МЛ, то завершаются и его операции, т.е., теоретически, в
  *      списке "подразделений с заданиями" какие-то подразделения могут остаться без заданий и, казалось бы, требуется
  *      обновление списка. НО сейчас концептуально утверждено, что мы не удаляем "подразделения с заданиями" из списка
  *      в процессе текущей сессии работы в приложении (до перезагрузки страницы), т.к. это может быть неочевидно -
  *      'Были подразделения, тут неожиданно пропали, а потом, например, снова появились через секунду, т.к. МЛ новый
  *      запустили'. Аналогичная логика должна реализовываться и при броадкастинге, т.к. в этом случае будет ещё хуже,
  *      Рабочий выбрал свой класс РЦ и подразделение, а если список будет обновлен, то его редиректнет на экран выбора
  *      подразделений и своего подразделения он уже не найдёт, что неудобно.
  *      ПОЭТОМУ, здесь ничего не делаем (в списках заданий у рабочего просто будет пустой список, это нормально)
  *
  * 4.2. Раздел "Выбора класса РЦ в подразделении". Всё аналогично пункту 4.1. Здесь ничего не делаем
  *
  * 4.3. Раздел "Просмотра заданий для класса РЦ в подразделении". Должны исчезнуть все задания по завершенному МЛ из
  *      списка заданий для классов РЦ в подразделениях:
  *      - Очищаем кэши всех таблиц просмотра заданий, т.к. не знаем какие из них именно затронуло завершение нового МЛ.
  *      При следующем входе в эти разделы будет выплонен новый запрос и данные обновятся
  * 4.5 Раздел просмотра ВСЕХ заданий в отдельном разделе приложения "Рабочий". Должны исчезнуть все задания по
  * завершенному МЛ из таблицы. Очищаем кэш в любом случае, т.к. это серверная таблица и возможны сдвиги для текущей
  * страницы даже если на ней в данный момент нет заданий завершенного МЛ
  * 4.5 Раздел "Просмотра заданий для класса РЦ в подразделении", окно просмотра операции, экран потребления ДСЕ. При
  * завершении МЛ нужно удалить его данне потребления из store, т.к. они больше не нужны
  * */
  handleSheetFinished: (sheetId, sheetIdentity, allTasksTablesIds, orderId, orderName, isOrderCompleted) => {

    //1.1 перезапрос
    dispatch(reFetchRemoteTableData(SHEET_TYPE.IN_PRODUCTION, SHEET_TYPE.IN_PRODUCTION, fetchSheetsData));

    //Выводим нотификейшен об успешном завершении МЛ
    sendSheetFinishedNotification(sheetIdentity);

    if (isOrderCompleted) {
      //Выводим нотификейшен в случае, когда завершение МЛ означает также и готовность заказа
      sendOrderIsReadyToCompleteNotification(orderName);
    }

    const orderCompletedActionsToDispatch = isOrderCompleted ?
      ([
        /*
        * 1.5, 1.6
        * */
        clearTableRemoteData(ORDER_IN_PRODUCTION_AND_READY_TO_COMPLETE_TABLES_IDS),
        clearTableData(createOrderEntriesTableId(ORDER_TYPE.IN_PRODUCTION, orderId)),
      ]) :
      [];

    dispatch([
      /*
      * 1.1 очистка всех данных таблицы операций завершенного МЛ
      * */
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId)),

      //1.4, 2.2, 3.1, 4.3, 4.4
      clearTableRemoteData([
        SHEET_TYPE.COMPLETED,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        MASTER_TASKS_TO_DO_TABLE_ID,
        MASTER_COMPLETED_TASKS_TABLE_ID,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),

      //2.1
      deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
      clearAllDefaultSheetsPartsAndMaterialsToConsume(),
      // 2.3
      deleteAllAssemblySheetsReserveData(),
      // 4.5
      deleteAssemblySheetConsumeData({ sheetId }),
      ...orderCompletedActionsToDispatch,
    ]);
  },


  /*
  * Влияние события смены статуса операции МЛ в производстве на разные типы приложения и обработка этого с учетом того,
  * что смена статуса происходит на экране просмотра операций МЛ в приложении "Плановик".
  *
  * 1. Плановик.
  * 1.1. Раздел "МЛ в производстве". Когда изменяем статус операции МЛ, список операций МЛ должен обновиться.
  *      - Плановику разрешается изменять статусы операций в любой последовательности, а не по порядку, поэтому, если
  *        изменен статус операции в середине маршрута, то все предыдущие операции будут помечены сервером, как
  *        завершенные. Поэтому перезапрашиваем данные для текущей страницы просмотра операций МЛ полностью, чтобы
  *        получить все обновления по операциям с сервера.
  *
  * 1.2. Раздел "МЛ на запуск". Изменение статуса операции МЛ в производстве не влияет на этот раздел.
  *
  * 1.3. Раздел "МЛ приостановленные". Изменение статуса операции МЛ в производстве не влияет на этот раздел.
  *
  * 1.4. Раздел "МЛ завершенные". Изменение статуса операции МЛ в производстве не влияет на этот раздел.
  *
  *
  * 2. Комплектование.
  * 2.1. Раздел "МЛ ожидающие комплектации". Изменение статуса операции МЛ в производстве не влияет на этот раздел.
  * 2.2 Раздел "сборочные". Изменение статуса операции МЛ в производстве не влияет на этот раздел.
  *
  * 3. Мастер.
  * 3.1. Раздел просмотра заданий в Мастере. Должны измениться статусы у операций в соответствии с тем, как их
  *      изменил сервер:
  *      -  Для этого пункта справедливо описание из п.1.1. этого комментария, что по событию изменения статуса одной
  *        операции могут быть изменены статусы нескольких операций. Поэтому просто очищаем "кэши" всех таблиц
  *        просмотра заданий в Мастере ("задания, которые нужно сделать" и "завершенные задания"), т.к. не знаем какие
  *        из них именно затронуло изменение статуса операции. При следующем входе в эти разделы будет выполнен новый
  *        запрос и данные обновятся
  * 3.2 Раздел "МЛ приостановленные". Изменение статуса операции МЛ в производстве не влияет на этот раздел.
  *
  *
  * 4. Рабочий.
  * 4.1. Раздел "Выбора подразделений". Изменение статуса операции МЛ в производстве не влияет на этот раздел. Может
  *      показаться, что при завершении операций, можно удалять из списка подразделения если в нём нет заданий, но,
  *      установлено, что это делать нелогично. Подробно мотивация описана в п.3.1 в комментарии к handleSheetFinished
  *      в этом же файле
  *
  * 4.2. Раздел "Выбора класса РЦ в подразделении". Изменение статуса операции МЛ в производстве не влияет на этот
  *      раздел. Всё аналогично пункту 3.1, здесь ничего не делаем
  *
  * 4.3. Раздел "Просмотра заданий для класса РЦ в подразделении". Должны измениться статусы у операций в соответсвии
  *      с тем, как их изменил сервер.
  *      - Для этого пункта справедливо описание из п.1.1. этого комментария, что по событию изменения статуса одной
  *        операции могут быть изменены статусы нескольких операций. Поэтому просто очищаем "кэши" всех таблиц
  *        просмотра заданий, т.к. не знаем какие из них именно затронуло изменение статуса операции. При
  *        следующем входе в эти разделы будет выполнен новый запрос и данные обновятся
  * 4.4 Раздел просмотра ВСЕХ заданий в отдельном разделе приложения "Рабочий". Должны изменитьс измениться статусы у
  * операций в соответсвии с тем, как их изменил сервер.
  *  - Всё аналогично 4.3, т.к. это ещё одна таблица заданий рабочего в соседнем разделе приложения "Рабочий"
  * */
  handleSheetOperationStatusChanged: (sheetOperationsTableId, fetchSheetOperations, allTasksTablesIds) => {

    //1.1.
    dispatch(reFetchRemoteTableData(sheetOperationsTableId, null, fetchSheetOperations));

    //3.1 и 4.3 и 4.4
    dispatch(clearTableRemoteData([
      MASTER_TASKS_TO_DO_TABLE_ID,
      MASTER_COMPLETED_TASKS_TABLE_ID,
      TASKS_MODEL,
      ...allTasksTablesIds,
    ]));
  },

  /*
  * Влияние события изменения редактируемых данных операции МЛ на разные типы приложения и обработка этого с учетом
  * того, что изменение выполняется на экране просмотра операций МЛ в приложении "Плановик".
  *
  * К редактируемым данным операции МЛ относятся:
  * - ИСПОЛНИТЕЛИ операции
  * - ОБОРУДОВАНИЕ для выполнения операции
  * - КОММЕНТАРИЙ к операции
  *
  * ВАЖНО! Изменение статуса операции тоже, по идее, можно отнести к "изменению редактируемых данных операции", но
  * для этого события обработка отличается от описанного выше, т.к. при изменении статуса операции она более сложная и
  * может затрагивать не только текущую операцию, но и предыдущие. Поэтому обработка события изменения статуса операции
  * производится отдельно
  *
  * 1. Плановик.
  * 1.1. Раздел "МЛ в производстве". Когда изменяем данные операции МЛ, операция в таблице должна соответствующе
  * обновиться, чтобы при повторном входе в модальник просмотра операции (при клике на эту операцию) отобразились
  * обновленные данные
  *      - Логичным кажется, обновить данные табличного хранилища, чтобы там появились обновленные данные, НО
  *      данные в табличном хранилище для операций хранятся в нормализованном виде в разрезе различных ключей
  *      (например, данные по исполнителям в ключах моделей связей юзеров с операцией и модели самих юзеров) и
  *      преобразовать имеющиеся измененные данные по операции, которые мы здесь получаем в совсем другом виде,
  *      достаточно трудоёмко. Возможно, облегчить это могло бы хранение данных в табличном стор в более упрощенном
  *      виде, но, в целом, это спорно, всё же концептуально именно селектор должен собирать данные, а данные для
  *      общего случая лучше хранить нормализованно, как сейчас и происходит.
  *      ПОЭТОМУ, по крайней мере ПОКА, аналогично обработке смены статуса операции, просто перезапрашиваем текущую
  *      таблицу с операциями, чтобы обновленные данные были получены и записаны в табличный стор заново. Теоретически,
  *      это логично, т.к. мы редактируем данные операции после вызова одной из экшн точек изменения данных, т.е.
  *      только при перезапросе получаем реальные данные из БД после того, как экшн точка отработала и, таким образом,
  *      всегда уверены, что результат получается актуальным. НО, очевидно, что мы вполне можем предполагать, что экшн
  *      точка отработала правильно, т.е. эта "проверка", по идее, необязательная + это небольшой оверхед, т.к.
  *      перезапрашиваются все операции на странице, тогда как изменялись данные только по одной, но механизмов, как то
  *      обновить данные для текущей серверной таблицы запросом (или, как указывалось выше, без запроса) - нет.
  *
  * 1.2. Раздел "МЛ на запуск". Изменение данных операции МЛ в производстве не влияет на этот раздел.
  *
  * 1.3. Раздел "МЛ приостановленные". Изменение данных операции МЛ в производстве не влияет на этот раздел.
  *
  * 1.4. Раздел "МЛ завершенные". Изменение данных операции МЛ в производстве не влияет на этот раздел.
  *
  *
  * 2. Комплектование.
  * 2.1. Раздел "МЛ ожидающие комплектации". Изменение данных операции МЛ в производстве не влияет на этот раздел,
  *   т.к. здесь не отображаются операции МЛ
  * 2.2 Раздел "сборочные". Изменение данных операции МЛ в производстве не влияет на этот раздел.
  *
  * 3. Мастер.
  * 3.1. Раздел просмотра заданий в Мастере. Должны измениться данные по заданию, чтобы при следующем просмотре этого
  * задания корректно отобразились новые данные по операции МЛ:
  *      - Т.к. неизвестно зайдёт ли пользователь в этот раздел для просмотра заданий, то как-то вычислять есть ли
  *      сейчас среди табличных данных мастера задание, в котором изменились данные операции, кажется излишним. Поэтому
  *      просто очищаем "кэш" таблицы "заданий, которые нужно сделать" в Мастере. В этом случае, когда пользователь
  *      всё же зашёл в указанный раздел мастера, то задания просто запросятся заново с новыми данными по операции.
  *      Очищать "кэш" "завершенных заданий" в мастере нет необходимости, т.к. редактировать данные операции МЛ разрешено
  *      только для заданий в производстве, поэтому при изменении данных операции МЛ, данные таблицы завершенных заданий
  *      никак не обновляются
  * 3.2 Раздел "МЛ приостановленные". Изменение статуса операции МЛ в производстве не влияет на этот раздел.
  *
  *
  * 4. Рабочий.
  * 4.1. Раздел "Выбора подразделений". Изменение данных операции МЛ в производстве не влияет на этот раздел.
  *
  * 4.2. Раздел "Выбора класса РЦ в подразделении". Изменение данных операции МЛ в производстве не влияет на этот
  * раздел.
  *
  * 4.3. Раздел "Просмотра заданий для класса РЦ в подразделении". Должны измениться данные по заданию, чтобы при
  * следующем просмотре этого задания корректно отобразились обновленные данные по операции МЛ:
  *      - Для этого пункта справедливо описание из п.3.1. про то, что излишне искать задания для обновления. Поэтому,
  *      аналогично, просто очищаем "кэши" всех таблиц просмотра заданий. При следующем входе в эти разделы будет
  *      выполнен новый запрос заданий и данные обновятся
  * 4.4. Раздел "Просмотра ВСЕХ заданий рабочего". Должны измениться данные по заданию, чтобы при
  * следующем просмотре этого задания корректно отобразились обновленные данные по операции МЛ:
  *  - Аналогично 4.3 сбрасываем кэш и этой таблицы заданий рабочего в отдельном разделе
  * */
  handleSheetOperationDataChanged: (sheetOperationsTableId, fetchSheetOperations, allTasksTablesIds) => {
    //1.1.
    dispatch(reFetchRemoteTableData(sheetOperationsTableId, null, fetchSheetOperations));

    ////3.1 и 4.3 и 4.4
    dispatch(clearTableRemoteData([
      MASTER_TASKS_TO_DO_TABLE_ID,
      TASKS_MODEL,
      ...allTasksTablesIds,
    ]));
  },

  /*
  * Влияние события приостановки МЛ на разные типы приложения и обработка этого с учетом того, что приостановка МЛ
  * происходит на экране просмотра МЛ в производстве:
  *
  * 1. Плановик.
  * 1.1. Раздел "МЛ в производстве". Приостановленный МЛ должен исчезнуть из списка МЛ в производстве:
  *      - Список серверный, поэтому если элемент списка исчезает со страницы, значит в списке должно произойти
  *      "смещение", т.е. подгрузиться новый элемент, если он есть, в соответствии с имеющейся сортировкой.
  *      Чтобы упросить обработку просто перезапрашиваем данные для текущих параметров списка. Если приостановленный
  *      элемент на странице последний, то логика табличной абстракции, через которую реализован список, обработает
  *      этот момент автоматически и перезапросит предыдущую страницу таблицы
  *      - Т.к. МЛ приостановлен, то в разделе уже нельзя будет просматривать его операции, поэтому есть смысл очистить
  *      данные серверной таблицы по операциям МЛ в производстве
  *
  *
  * 1.2. Раздел "МЛ на запуск". Приостановленный МЛ не влияет на этот раздел.
  *
  * 1.3. Раздел "МЛ приостановленные". Новый приостановленный МЛ должен появиться в списке:
  *      - Очищаем хранилище этого списка (табличное хранилище по ID таблицы), чтобы при следующем входе в этот раздел
  *      был выполнен новый запрос.
  *
  * 1.4. Раздел "МЛ завершенные". Приостановленный МЛ не влияет на этот раздел.
  *
  * 2. Комплектование.
  * 2.1. Раздел "МЛ ожидающие комплектации". В "Плановике" можно приостановить и неукомплектованные МЛ, т.е. список МЛ,
  *      ожидающих комплектацию, должен обновиться, если это произошло, приостановленный МЛ должен пропасть из этого
  *      списка. Кроме того, раз МЛ пропадает из списка то имеет смысл в очистке его детальной комплектации, т.к. она
  *      больше не нужна:
  *      - По идее, для обновления списка, нужно бы было сбросить "кэш" по этой таблице, чтобы при следующем входе
  *      на этот экран был выполнен новый запрос. НО, в данный момент этот список осталась клиентским, т.к., сейчас
  *      перевести его на серверный не получается из-за особенностей сортировки и необходимости получать дополнительные
  *      параметры о том, могут ли МЛ быть укомплектованы. Поэтому в данный момент тут другая логика. Данные для списка
  *      МЛ хранятся в entities + в специальном хранилище в store хранятся данные о МЛ, которые можно укомплектовать.
  *      Эти данные перезапрашиваются по новой при каждом входе в этот раздел, данные entities ДОЗАПИСЫВАЮТСЯ, а данные
  *      МЛ, которые можно укомплектовать ПЕРЕЗАПИСЫВАЮТСЯ ПОЛНОСТЬЮ. Поэтому, в текущей реализации, нужно только
  *      удалить из entities приостановленный МЛ, чтобы он пропал из списка.
  *      Но, эта таблица тоже должна стать серверной, поэтому это нужно будет изменить, сейчас при таком обновлении
  *      будет запрашиваться много данных.
  *      - Удаляем детальную комплектацию по МЛ в соответствующем хранилище, т.к. эти данные больше не нужны до
  *      восстановления МЛ, а после восстановления логично запросить их заново
  * 2.2 Раздел "сборочные". Остановка МЛ влияет на лейблы в списке сборочных МЛ, поэтмоу нужно сбросить кеш для таблицы.
  *
  * 3. Мастер.
  * 3.1. Раздел просмотра заданий в Мастере. Должны исчезнуть все задания по приостановленному МЛ из списка заданий,
  *      которые "нужно сделать":
  *      - Очищаем кэш таблицы заданий, которые "нужно сделать" независимо от выбранных подразделений, т.к. не знаем
  *      какие из них именно затронуло приостановка МЛ. При следующем входе в этот раздел будет выполнен новый запрос
  *      и данные обновятся
  *      - В таблице "завершенных" заданий мы видим все завершенные задания, в том числе и заврешенные задания по
  *      приостановленным МЛ, поэтому при возникновении события приостановки МЛ в этом разделе ничего не меняется,
  *      никакой обработки не требуется
  * 3.2. Раздел "МЛ приостановленные". Новый приостановленный МЛ должен появиться в списке:
  *      - Очищаем хранилище этого списка (табличное хранилище по ID таблицы), чтобы при следующем входе в этот раздел
  *      был выполнен новый запрос.
  *
  * 4. Рабочий.
  * 4.1. Раздел "Выбора подразделений". Когда приостанавливается МЛ, то задания по нему становятся недоступными для
  *      исполнения и пропадают из списков заданий в "Рабочем", т.е., теоретически, в списке "подразделений с
  *      заданиями" какие-то подразделения могут остаться без заданий и, казалось бы, требуется обновление списка. НО
  *      сейчас концептуально утверждено, что мы не удаляем "подразделения с заданиями" из списка в процессе текущей
  *      сессии работы в приложении (до перезагрузки страницы), т.к. это может быть неочевидно - 'Были подразделения,
  *      тут неожиданно пропали, а потом, например, снова появились через секунду, т.к. МЛ новый запустили или
  *      восстановили приостановленный'. Аналогичная логика должна реализовываться и при броадкастинге, т.к. в этом
  *      случае будет ещё хуже, Рабочий выбрал свой класс РЦ и подразделение, а если список будет обновлен, то его
  *      редиректнет на экран выбора подразделений и своего подразделения он уже не найдёт, что неудобно.
  *      ПОЭТОМУ, здесь ничего не делаем (в списках заданий у рабочего просто будет пустой список, это нормально)
  *
  * 4.2. Раздел "Выбора класса РЦ в подразделении". Всё аналогично пункту 3.1. Здесь ничего не делаем
  *
  * 4.3. Раздел "Просмотра заданий для класса РЦ в подразделении". Должны исчезнуть все задания по приостановленному
  *      МЛ из списка заданий для классов РЦ в подразделениях:
  *      - Очищаем кэши всех таблиц просмотра заданий, т.к. не знаем какие из них именно затронула приостановка МЛ.
  *      При следующем входе в эти разделы будет выплонен новый запрос и данные обновятся
  * 4.4. Раздел "Просмотра ВСЕХ заданий рабочего". Должны исчезнуть все задания по приостановленному
  *      МЛ:
  *      - Аналогично п.4.3, т.к. это ещё одна таблица заданий рабочего в отдельном разделе
  * 4.5 При остановке МЛ задание пропадает из списка операций, поэтому нужно удалить данные резервирования
  * остановленного МЛ.
  * */
  handleSheetPaused: (sheetId, sheetIdentity, allTasksTablesIds) => {
    //1.1 перезапрос
    dispatch(reFetchRemoteTableData(SHEET_TYPE.IN_PRODUCTION, SHEET_TYPE.IN_PRODUCTION, fetchSheetsData));

    //Выводим нотификейшен о приостановке МЛ
    sendSheetPausedNotification(sheetIdentity);

    dispatch([
      //1.1 очистка всех данных таблицы операций приостановленного МЛ
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId)),

      //1.3, 2.2, 3.1, 3.2, 4.3, 4.4
      clearTableRemoteData([
        ...PAUSED_SHEETS_TABLES_IDS_ARRAY,
        MASTER_TASKS_TO_DO_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),

      //2.1
      deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
      clearDefaultSheetPartsAndMaterialsToConsume(sheetId),
      //4.5
      deleteAssemblySheetConsumeData({ sheetId }),
    ]);
  },


  /*
 * Влияние события разделения партии просматриваемого МЛ на разные типы приложения и обработка этого с учетом
 * того, что изменение выполняется на экране просмотра операций МЛ в приложении "Плановик".
 *
 * 1. Плановик.
 * 1.1. Раздел "МЛ в производстве". При разделении партии создается новая партия и соответствующий ей МЛ. Этот МЛ должен
 * появиться в списке МЛ в производстве, поскольку сейчас концептуально принято, что МЛ новой партии сразу же запускается
 * в производство. Необходимо перезапросить данные для этого раздела. Ещё для МЛ при просмотре кэшируются его операции,
 * при делении партии отмечается прогресс операции на которой выполяется деление, то.есть меняются данные о прогрессе
 * по этой операции и её состояние может измениться на "завершена", поэтому закрываем экран просмотра МЛ и очищаем его кеш.
 * Также, бывают кейсы, когда родительская и \ или дочерняя партии после деления сразу завершаются, а значит, что они
 * тоже должны пропасть из списка МЛ в производства и кэш операций должен отчиститься
 *
 * 1.2. Раздел "МЛ на запуск". Разделение партии не влияет на этот раздел.
 *
 * 1.3. Раздел "МЛ приостановленные". Разделение партии не влияет на этот раздел.
 *
 * 1.4. Раздел "МЛ завершенные". При делении партии, могут быть завершены родительская, дочерняя или обе партии, потому
 * что при делении отмечается прогресс операции родительской и дочерней партий. Если хотя бы одна из партий была
 * завершена, то должен обновиться список завершённых МЛ, поэтому сбрасываем кеш этой таблицы и выполняем стандартные
 * очистки для остальных экранов.
 *
 * 1.5 Плановик. Заказы. В производстве. Если при делении партии завершились партии, то могзавершится и заказ,
 * выполняем перезапрос данных этой таблицы. Завершённый заказ должен удалится из таблицы
 *
 * 1.6 Плановик. Заказы. Готовые к завершению. Если при делении партии завершились партии и завершился заказ, то
 * выполняем перезапрос данных этой таблицы. Завершённый заказ должен появиться в этой таблице
 *
 * 2. Комплектование.
 * 2.1. Раздел "МЛ ожидающие комплектации". При разделении партии, если новая партия тоже требует комплектации,
 *      как и исходный, то МЛ по этой партии должен появиться в списке "ожидающий комплектации".
 *      Кроме того, изменяются данные по детальной комплектации исходного МЛ, (часть партии выделена в другой МЛ,
 *      количества изменились), значит нужно удалить данные по детальной комплектации для исходного МЛ, если она
 *      запрашивалась ранее, чтобы при повторном входе в этот раздел запрос детальной комплектации выполнился бы
 *      по новой.
 *        - Если при делении партии, была завершена родительская, дочерняя или обе партии, то очищаем все данные
 *      потребления стандартных МЛ, т.к. появляются новые ДСЕ на складе. Также,
 * удаляем завершенные партии из entities, т.к. они должны пропасть из списка
 *        - По идее, для обновления списка, нужно бы было сбросить "кэш" по таблице МЛ ожидающий комплектации, чтобы
 *      при следующем входе на этот экран был выполнен новый запрос. НО, в данный момент этот список осталась
 *      клиентским, т.к., сейчас перевести его на серверный не получается из-за особенностей сортировки и необходимости
 *      получать дополнительные параметры о том, могут ли МЛ быть укомплектованы. Поэтому в данный момент тут другая
 *      логика. Данные для списка МЛ хранятся в entities + в специальном хранилище в store хранятся данные о МЛ,
 *      которые можно укомплектовать. Эти данные перезапрашиваются по новой при каждом входе в этот раздел, данные
 *      entities ДОЗАПИСЫВАЮТСЯ, а данные МЛ, которые можно укомплектовать ПЕРЕЗАПИСЫВАЮТСЯ ПОЛНОСТЬЮ. В текущей
 *      реализации ничего не делаем для кейсов, когда нет завершенных партий,
 *  т.к. данные при входе в этот раздел запросятся заново. Если завершенные партии
 * есть, то удаляем их из entities/
 *        - Вызываем экшн очистки детальной комплектации для исходного МЛ, т.к. её надо будет обновить при повторном
 *      входе. И удаляем данные всей детальной комплектации, если есть завершенные
 * партии, т.к. обновилось состояние склада
 * 2.2 Раздел "сборочные". При делении партии, если новая партия является сборочной, то МЛ по этой партии должен
 * появиться в списке "сборочные", также при делении меняется количество ДСЕ для уже существующей партии, поэтому
 *  нужно сбросить кеш таблицы сборочных МЛ. Также, партии могут пропасть из списка, если они завершаются
 * 2.3 Раздел "сборочные", экран резервирования. При делении партий изменяется количество ДСЕ для резервирования в МЛ,
 * от которого отделяется партия, поэтому нужно удалить данные резервирования для этого МЛ, чтобы при следующем входе
 * они обновились. Если была завершена хотя бы одна партия, то вызываем экшн очистки детальной комплектации для всех МЛ,
 * потому что количество ДСЕ на складе изменяется
 *
 * 3. Мастер.
 * 3.1. Раздел просмотра заданий в Мастере. При наступлении события разделения партии, МЛ только что созданной партии
 *      переходит в раздел "в производстве", а значит, необходимо обновить данные по заданиям, доступным для выполнения
 *      в подразделениях мастера.
 *      3.1.1 подраздел "требуется выполнить": сбрасываем кэш таблицы заданий, чтобы при входе был выполнен новый запрос
 *      3.1.2 подраздел "завершенные": тоже сбрасываем кэш таблицы, т.к. при делении партии могут завершаться операции
 * 3.2 Раздел "МЛ приостановленные". Разделение партии не влияет на этот раздел.
 *
 *
 * 4. Рабочий.
 * 4.1. Раздел "Выбора подразделений". При наступлении события разделения партии, МЛ только что созданной партии
 *      переходит в раздел "в производстве", но т.к. это просто ещё одна аналогичная исходной партия с другим количеством
 *      изделий, новых подразделений в системе появиться не может. Событие разделения партии не влияет на этот подраздел.
 * В случае завершения партий, какие-то подразделения могут больше не содержать
 * заданий, но классически для событий завершения мы не обрабатываем такие кейсы,
 * т.е. считаем, что ничего страшного нет, что в списке и у пользователя будут
 * подразделения без заданий. Будет хуже, если уже существующие подразделения
 * пропадут и пользователь подумает, что приложение работает некорректно.
 *
 * 4.2. Раздел "Выбора класса РЦ в подразделении". Аналогично предыдущему пункту, событие разделения партии не влияет
 *      на этот подраздел.
 *
 * 4.3. Раздел "Просмотра заданий для класса РЦ в подразделении". Должны появиться задания по новому запущенному МЛ и
 * удалиться задания завершённых операций, если хотя бы одна операция завершена:
 *      - Очищаем кэши всех таблиц просмотра заданий, т.к. не знаем какие именно затронуло деление партии. При
 *        следующем входе в эти раздел будет выполнен новый запрос и данные обновятся
 * 4.4. Раздел "Просмотра ВСЕХ заданий рабочего". Должны появиться задания по новому запущенному МЛ и удалиться задания
 * операций, которые были завершены:
 *      - Аналогично п.4.3, т.к. это ещё одна таблица заданий рабочего в отдельном разделе
 * 4.5. Экран потребления ДСЕ при просмотре операции МЛ. При делении партий изменяется количество ДСЕ для
 *      - резервирования в МЛ, от которого отделяется партия, поэтому нужно удалить данные потребления для этого МЛ,
 *      - чтобы при следующем входе они обновились.
 * Если хотя бы одна из партий завершена, то нужно удалить данные её потребления, т.к. они больше
 * не нужны и удалить все данные резервирования, т.к. произошло пополнение склада
 * */
  handleEntityBatchSplit: ({
    allTasksTablesIds,
    parentSheetId,
    isParentEntityBatchWasFinished,
    isChildEntityBatchWasFinished,
    changedOrderId,
    sheetIdentity,
  }) => {

    // 1.1
    dispatch(reFetchRemoteTableData(SHEET_TYPE.IN_PRODUCTION, SHEET_TYPE.IN_PRODUCTION, fetchSheetsData));

    // выводим нотификейшн о наступлении события разделения партии
    sendEntityBatchSplitNotification(sheetIdentity);

    const {
      isOneOfBatchesFinished,
      isOrderFinished,
    } = getSplitEntityBatchCaseData({
      isParentEntityBatchWasFinished,
      isChildEntityBatchWasFinished,
      changedOrderId,
    });

    const sheetOperationsTableId = createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, parentSheetId);

    const tableIdsToClear = [
      sheetOperationsTableId,
      MASTER_TASKS_TO_DO_TABLE_ID,
      MASTER_COMPLETED_TASKS_TABLE_ID,
      SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      TASKS_MODEL,
      ...allTasksTablesIds,
    ];

    if(isOneOfBatchesFinished) {
      tableIdsToClear.push(SHEET_TYPE.COMPLETED);
    }

    const actionsToDispatch = [

      clearTableRemoteData(tableIdsToClear),

      ...getEntityBatchSplitCaseSpecificActions({
        isOneOfBatchesFinished,
        parentSheetId,
      }),

      ...getOrderCompletedRelatedActions(isOrderFinished, changedOrderId),
    ];

    dispatch(actionsToDispatch);
  },

  /*
  * Влияние события фиксации брака партии МЛ на разные типы приложения и обработка этого с учетом того, что событие
  * происходит на экране просмотра МЛ в производстве.
  * ВАЖНО!!! Сейчас реализуется MVP, когда при отметке брака, часть или вся партия сразу становится "окончательным
  * браком", т.е. в логике приложения становится партией со статусом "завершена" и со статусом бракованности
  * "окончательный брак". В будущем, это изменится, т.к. не в MVP, у партии должен будет меняться статус на
  * "несоответствующая продукция", поэтому будут корректировки в обработке
  *
  * Событие фиксации брака может наступать в рамках различных кейсов, для подробностей ссылка на статью с
  * техническим проектированием: https://colab.bfg-soft.ru/pages/viewpage.action?pageId=232325142 :
  *   1. В брак переводится вся партия при завершении операции. В MVP партия становится завершенной, но
  *   не попадает на склад, в истории МЛ, операция, на которой делалась отметка, является завершенной.
  *   2. В брак переводится вся партия при приостановке операции. В MVP партия становится завершенной, но
  *   не попадает на склад, в истории МЛ, операция, на которой делалась отметка, является приостановленной с прогрессом,
  *   который был указан
  *   3. В брак переводится часть партии при завершении операции и это была не последняя операция. В MVP партия остается
  *   в производстве, но от неё отделяется новая партия, которая сразу является завершенной, но не появляется на складе.
  *   И для исходной партии, и для новой, в истории, операция, на которой делалась отметка и её клон у новой партии,
  *   являются завершенными.
  *   4. В брак переводится часть партии при завершении операции и это была последняя операция. В MVP от партии
  *   отделяется новая партия, которая сразу является завершенной, но не появляется на складе. НЕбракованная часть
  *   партии становится тоже заврешенной, он она уже передается на склад. И для исходной партии, и для новой, в
  *   истории, операция, на которой делалась отметка и её клон у новой партии, являются завершенными. Считается, также,
  *   что завершение части партии может привести к тому, что заказ перейдёт в статус "готов к завершению" (это была
  *   последняя партия по заказу и количество НЕбракованных деталей достаточно для завершения заказа - кейс пока
  *   искусственный, т.к. сейчас в партиях не может быть количества больше необходимого, но это изменится в будущем,
  *   поэтому сразу прорабатывали, т.к. в будущем мы будем иметь возможность перепривязывать партии к заказам).
  *   5. В брак переводится часть партии при приостановке операции. В MVP партия остается в производстве, но от неё
  *   отделяется новая партия, которая сразу является завершенной, но не появляется на складе. И для исходной партии,
  *   и для новой, в истории, операция, на которой делалась отметка и её клон у новой партии, являются приостановленными
  *   с прогрессами, которые указаны для каждой из частей партии.
  *
  * 1. Плановик.
  *
  * 1.1. Раздел "МЛ в производстве".
  *
  *   - Для кейсов 1, 2, 4 МЛ бракованной партии должен пропасть из списка. Т.к. мы находимся именно в этом разделе,
  *   то в таких случаях принято делать перезапрос серверной таблицы. Т.к. партия завершена, то в разделе уже нельзя будет
  *   просматривать его операции, поэтому есть смысл очистить данные серверной таблицы по операциям МЛ
  *   - Для кейсов 3, 5 МЛ НЕбракованной части партии остается в производстве, но у него меняются параметры количества
  *   и активной операции, их нужно обновить. Пока это можно сделать тоже только перезапросом серверной таблицы,
  *   аналогично прошлому пункту. Аналогично процессу деления партии пока что мы будем закрывать модальник просмотра
  *   операций МЛ, чтобы не обновлять его локальный state. Поэтому для обновления данных серверной таблицы по операциям
  *   МЛ пока тоже только сбрасываем кэш, чтобы при повторном входе просмотр эти данные обновились. Это, в данный
  *   момент, также удобно сделать и для того, чтобы здесь была универсальная обработка для всех кейсов.
  *
  * [Обработка универсальна для всех кейсов]
  *
  * 1.2. Раздел "МЛ на запуск". Отметка брака партии не влияет на этот раздел.
  *
  * 1.3. Раздел "МЛ приостановленные". Отметка брака партии не влияет на этот раздел., т.к. партия не переходит в
  * статус приостановленных и для этого статуса (т.е. из интерфейса приостановленных), нельзя
  * отмечать брак, действия запрещены
  *
  * 1.4. Раздел "МЛ завершенные". Отметка брака партии не влияет на этот раздел.
  *
  * - Для всех кейсов, кроме четвёртого, один МЛ попадает в таблицу "Контроль. Несоответствующая продукция", а для
  * кейса 4 появляется 2 МЛ. Один из них - это завершенная часть партии, а второй - часть партии, которая попадает в
  * таблицу "Несоответствующая продукция". Поэтому для кейса 4 очищаем хранилище табличное хранилище по ID таблицы,
  * чтобы при следующем входе в этот раздел был выполнен новый запрос.
  *
  *
  * [Обработка универсальна для всех кейсов]
  *
  * 1.5 Раздел "Заказы в производстве"
  *     В этом разделе может быть изменение только в кейсе 4, если при завершении НЕбракованной части партии, завершаются
  *     все работы по заказу, то должен обновиться список заказов в производстве. Т.е., в этом случае:
  *     - очищаем хранилище этого списка, чтобы при следующем входе был выполнен запрос данных списка
  *     - очищаем хранилище таблицы просмотра состава заказа из списка "в производстве"
  *
  * 1.6 Раздел "Заказы, готовые к завершению"
  *     Аналогично, п. 1.5, здесь возможны изменения только в кейсе 4, если при завершении НЕбракованной части партии,
  *     завершаются все работы по заказу, то должен обновиться список готовых к завершению заказов. Т.е., в этом случае:
  *     - очищаем хранилище этого списка, чтобы при следующем входе был выполнен запрос данных списка
  *
  * 2. Комплектование.
  * 2.1. Раздел "МЛ ожидающие комплектации".
  *      В кейсах 1, 2, 4 МЛ должен пропасть из списка ожидающих комплектацию, при этом логично и обновить информацию о
  *      МЛ, которые можно укомплектовать + детальную комплектацию, для исходного МЛ, с которым выполнялось действие
  *      отметки брака. В кейсе 4 дополнительно к этому пополняется склад, поэтому, нужно очистить детальную
  *      комплектацию для всех МЛ, т.к. все данные могли измениться. В кейсах 3, 5 требуется обновление количеств в
  *      списке МЛ, т.к. от исходного МЛ отделилась часть партии, а значит также требуется и обновление информации о
  *      том, какие МЛ могут быть укомплектованы и детальная комплектация, как минимум, по исходному МЛ.
  *      - По идее, для обновления списка, нужно бы было сбросить "кэш" по этой таблице, чтобы при следующем входе
  *      на этот экран был выполнен новый запрос. НО, в данный момент этот список осталась клиентским, т.к., сейчас
  *      перевести его на серверный не получается из-за особенностей сортировки и необходимости получать дополнительные
  *      параметры о том, могут ли МЛ быть укомплектованы. Поэтому в данный момент тут другая логика. Данные для списка
  *      МЛ хранятся в entities + в специальном хранилище в store хранятся данные о МЛ, которые можно укомплектовать.
  *      Эти данные перезапрашиваются по новой при каждом входе в этот раздел, данные entities ДОЗАПИСЫВАЮТСЯ, а данные
  *      МЛ, которые можно укомплектовать ПЕРЕЗАПИСЫВАЮТСЯ ПОЛНОСТЬЮ. Поэтому, в текущей реализации, нужно в кейсах
  *      1, 2, 4 удалить из entities МЛ, который стал завершенным, т.к. перезапрос дозаписывает данные и иначе МЛ не
  *      пропадёт из списка. В кейсах 3, 5 данные о количествах просто перезапишутся, т.е. дополнительно ничего делать
  *      не нужно. Данные о МЛ, которые можно укомплектовать перезапросятся сейчас во всех кейсах, тоже ничего не делаем
  *      дополнительно. Но, стоит учитывать, что эта таблица тоже должна стать серверной, поэтому это нужно будет
  *      изменить, сейчас при таком обновлении будет запрашиваться много данных.
  *      - В кейсах 1, 2, 3, 5 требуется обновление детальной комплектации для исходного МЛ, в кейсе 4 очищаем
  *     детальную комплектацию для всех МЛ
  *
  * 2.2 Раздел "сборочные".
  * В кейсах 1, 2, 4 МЛ должен пропасть из списка. В кейсе 4 дополнительно нужно обновление списка, т.к. пополняется
  * склад, а от этого зависят возможность резервирования и лейблы в списке сборочных МЛ. Все это решается очисткой
  * кэша таблицы сборочных МЛ. Для кейсов 3 и 5, МЛ не пропадает из списка, но меняются количества в партиях, поэтому
  * тоже проще всего сбросить кэш таблицы для универсальности обработки во всех кейсах.
  * [Обработка универсальна для всех кейсов]
  *
  * 2.3 Раздел "сборочные", экран резервирования.
  * В кейсах 1, 2, 3, 5 требуется удаления данных резервирования для исходного МЛ, т.к. МЛ или стал завершенным и эти
  * данные больше не нужны или у МЛ изменилось исходные количества и эти данные неактуальны. В кейсе 4 требуется
  * удаление всех данных резервирования, т.к. на складе было пополнение и данные склада в данных резервирования
  * изменились. Удаления нужны, чтобы эти данные перезапросились при повторном входе в приложение.
  *
  * 3. Мастер.
  * 3.1. Раздел просмотра заданий в Мастере.
  * В кейсах, 1, 2, 4 должны исчезнуть все задания по завершенному МЛ из списка заданий, которые "нужно сделать" и
  * появиться новые задания в списке "завершенных заданий" (в кейсе 2, по идее, в завершенных заданий может не быть
  * изменений, потому что операцию приостанавливают, но логично будет очистить в этом случае таблицу завершенных, т.к.
  * в Плановике выполнение операций может быть не по порядку + универсальность обработки). В кейсах 3, 5 будут тоже
  * обновления на вкладке "завершенных" (для кейса 5, аналогичное пояснение кейсу 2 выше), а также и для вкладке в
  * работе, потому что по исходным партиям изменились количества, а они отображаются в таблице.
  *      - Поэтому, для универсальности обработки очищаем кэши таблиц заданий, которые "нужно сделать" и
  *      "завершенных заданий". Делаем это также независимо от выбранных подразделений, т.к. не знаем какие из них
  *      именно затронуло завершение нового МЛ. При следующем входе в эти разделы будет выполнен новый запрос и
  *      данные обновятся
  * [Обработка универсальна для всех кейсов]
  *
  * 3.2 Раздел "МЛ приостановленные". Отметка брака партии не влияет на этот раздел., т.к. партия не переходит в
  * статус приостановленных и для этого статуса (т.е. из интерфейса приостановленных), нельзя
  * отмечать брак, действия запрещены
  *
  * 4. Рабочий.
  * 4.1. Раздел "Выбора подразделений". Когда отмечается брак, то в некоторых кейсах завершаются и его операции, т.е.,
  *      теоретически, в списке "подразделений с заданиями" какие-то подразделения могут остаться без заданий и,
  *      казалось бы, требуется обновление списка. НО сейчас концептуально утверждено, что мы не удаляем "подразделения
  *      с заданиями" из списка в процессе текущей сессии работы в приложении (до перезагрузки страницы), т.к. это
  *      может быть неочевидно - 'Были подразделения, тут неожиданно пропали, а потом, например, снова появились через
  *      секунду, т.к. МЛ новый запустили'. Аналогичная логика должна реализовываться и при броадкастинге, т.к. в этом
  *      случае будет ещё хуже, Рабочий выбрал свой класс РЦ и подразделение, а если список будет обновлен, то его
  *      редиректнет на экран выбора подразделений и своего подразделения он уже не найдёт, что неудобно.
  *      ПОЭТОМУ, здесь ничего не делаем (в списках заданий у рабочего просто будет пустой список, это нормально)
  *      В данный момент, в кейсах 3, 4, 5 появляются новые партии, но они сразу становятся завершенными, т.е. для
  *      рабочего они не имеют значения, мы их не отображаем, т.е. тоже ничего не делаем.
  *
  * 4.2. Раздел "Выбора класса РЦ в подразделении". Всё аналогично пункту 4.1. Здесь ничего не делаем
  *
  * 4.3. Раздел "Просмотра заданий для класса РЦ в подразделении". В кейсах 1, 2, 4 партии становятся завершенными,
  * т.е.все задания по ним должны пропасть из списков рабочего. В кейсах 3 и 5 партии остаются в производстве, но
  * изменяются статусы по операциям (заврешаются или приостанавливаются) + изменяются количества в партиях, которые
  * отображаются в таблице. Поэтому нужно очищать кэши таблиц заданий рабочего для всех кейсов.
  *      - Очищаем кэши всех таблиц просмотра заданий, т.к. довольно трудоёмко понять какие именно из них в каких
  * подразделениях и класса РЦ были затронуты при различных кейсах (особенно учитывая то, что в Плановике какие-то
  * действия по операциям могут выполняться не по порядку). При следующем входе в эти разделы будет выполнен новый
  * запрос и данные обновятся.
  * [Обработка универсальна для всех кейсов]
  *
  * 4.4. Раздел "Просмотра ВСЕХ заданий рабочего":
 *      - Всё аналогично п.4.3, т.к. это ещё одна таблица заданий рабочего в отдельном разделе
  *
  * 4.5 Раздел "Просмотра заданий для класса РЦ в подразделении", окно просмотра операции, экран потребления ДСЕ.
  * В кейсах 1, 2, 4, МЛ завершается, т.е. нужно удалить данные потребления по нему, т.к. они больше не нужны. В кейсах
  * 3 и 5 партия делится, т.е. изменяется количество ДСЕ для резервирования в МЛ, поэтому тоже нужно удалить данные
  * потребления для этого МЛ, чтобы при следующем входе они обновились. Т.е. обработка универсальна для всех кейсов.
  * [Обработка универсальна для всех кейсов]
  *
  * 5 Приложение "Контроль"
  * 5.1 Раздел "Контроль. Несоответствующая продукция"
  *   - Для всех пяти кейсорв (они описаны в начале комментария) в таблице "Несоответствующая продукция" появляется
  *     новый МЛ. Поэтому очищаем данные этой таблицы, чтобы при её повторном открытии были запрошены обновлённые данные
  *
  * 5.2 Раздел "Контроль. Окончательный брак". Событие отметки брака не влияет на этот раздел
  */
  handleDefectiveEntitiesMarked: (data, allTasksTablesIds) => {

    const {
      sheetOperationStatus,
      sheetId,
      completedOrderId,
      completedOrderName,
      wasEntityBatchSplit,
      wasEntityBatchFinished,
    } = data;

    const defectMarkingUseCase = getDefectMarkingUseCase(
      sheetOperationStatus,
      wasEntityBatchSplit,
      wasEntityBatchFinished,
    );

    /*
    * Список экшенов для кейсов, для которых нужна кастомная, а не универсальная обработка
    * */
    let customActionsToDispatch = [];

    // 1.6, 5.1
    if(
      defectMarkingUseCase === DEFECT_MARKING_USE_CASES.PART_OF_ENTITY_BATCH_ON_LAST_OPERATION_FINISH &&
      completedOrderId !== null
    ) {
      //Выводим нотификейшен в случае, когда завершение части МЛ означает также и готовность заказа
      sendOrderIsReadyToCompleteNotification(completedOrderName);

      customActionsToDispatch.push(
        clearTableRemoteData(ORDER_IN_PRODUCTION_AND_READY_TO_COMPLETE_TABLES_IDS),
        clearTableData(createOrderEntriesTableId(ORDER_TYPE.IN_PRODUCTION, completedOrderId)),
      );
    }

    //2.1 для списка стандартных МЛ комплектования
    if(
      defectMarkingUseCase === DEFECT_MARKING_USE_CASES.WHOLE_ENTITY_BATCH_ON_OPERATION_FINISH ||
      defectMarkingUseCase === DEFECT_MARKING_USE_CASES.WHOLE_ENTITY_BATCH_ON_OPERATION_PAUSE ||
      defectMarkingUseCase === DEFECT_MARKING_USE_CASES.PART_OF_ENTITY_BATCH_ON_LAST_OPERATION_FINISH
    ) {
      customActionsToDispatch.push(
        deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
      );
    }


    //2.1 для детальной комплектации стандартного МЛ и
    //2.3
    if(defectMarkingUseCase === DEFECT_MARKING_USE_CASES.PART_OF_ENTITY_BATCH_ON_LAST_OPERATION_FINISH) {
      customActionsToDispatch.push(
        clearAllDefaultSheetsPartsAndMaterialsToConsume(),
        deleteAllAssemblySheetsReserveData(),
        //1.4
        clearTableRemoteData([
          SHEET_TYPE.COMPLETED,
        ]),
      );
    }else{
      customActionsToDispatch.push(
        clearDefaultSheetPartsAndMaterialsToConsume(sheetId),
        deleteAssemblySheetReserveData({ sheetId }),
      );
    }

    /*
    * Универсальные обработки для всех кейсов, к которым добавляются кастомные customActionsToDispatch, вычисленные
    * в зависимости от текущего кейса
    * */
    //1.1 перезапрос
    dispatch(reFetchRemoteTableData(SHEET_TYPE.IN_PRODUCTION, SHEET_TYPE.IN_PRODUCTION, fetchSheetsData));

    dispatch([
      /*
      * 1.1 очистка данных таблицы операций МЛ, по которому отметили брак
      * */
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId)),

      clearTableRemoteData([
        SHEET_TYPE.INAPPROPRIATE,

        //2.2
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,

        //3.1
        MASTER_TASKS_TO_DO_TABLE_ID,
        MASTER_COMPLETED_TASKS_TABLE_ID,

        //4.4
        TASKS_MODEL,

        //4.3
        ...allTasksTablesIds,
      ]),

      // 4.5
      deleteAssemblySheetConsumeData({ sheetId }),

      ...customActionsToDispatch,
    ]);

  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {
    sheetOperationsTableId,
    sheetOperationsTableData,
    allTasksTablesIds,
    detailedDataToConsumeForDefaultSheet,
  } = stateProps;

  const {
    fetchReviewedSheetOperationsRemoteTableEntities,
    finishSheet: finishSheetFromDispatchProps,
    fetchPossiblePartsAndMaterialsToConsumeForDefaultSheet,
    consumePartsAndMaterialsForDefaultSheet,
    handleSheetOperationStatusChanged: handleSheetOperationStatusChangedFromDispatchProps,
    handleSheetFinished: handleSheetFinishedFromDispatchProps,
    handleSheetOperationDataChanged: handleSheetOperationDataChangedFromDispatchProps,
    handleSheetPaused: handleSheetPausedFromDispatchProps,
    handleEntityBatchSplit: handleEntityBatchSplitFromDispatchProps,
    handleDefectiveEntitiesMarked: handleDefectiveEntitiesMarkedFromDispatchProps,
  } = dispatchProps;

  const {
    sheetToReviewData,
    closeReviewDialog,
    PermissionsManager,
  } = ownProps;

  const {
    sheetId,
    sheetIdentity,
    entityBatchId,
    orderId,
    orderName,
  } = sheetToReviewData;

  const fetchSheetOperations = ({ tableParams }) =>
    fetchReviewedSheetOperationsRemoteTableEntities(sheetToReviewData.sheetId, tableParams);

  const finishSheet = () => finishSheetFromDispatchProps(sheetId, sheetIdentity)
    .then(({ data }) => {
      const { changedOrderId } = data;

      const isOrderCompleted = changedOrderId !== null;

      broadcastSheetFinished(sheetId, sheetIdentity, orderId, orderName, isOrderCompleted);
      handleSheetFinishedFromDispatchProps(sheetId, sheetIdentity, allTasksTablesIds, orderId, orderName, isOrderCompleted);
      closeReviewDialog();
    });

  return {
    sheetOperationsTableId,
    sheetOperationsTableData,
    sheetToReviewData,
    PermissionsManager,
    fetchSheetOperations,
    fetchPossiblePartsAndMaterialsToConsumeForDefaultSheet,
    detailedDataToConsumeForDefaultSheet,
    provideAndFinishSheet: () => consumePartsAndMaterialsForDefaultSheet(
      sheetId,
      sheetIdentity,
      entityBatchId,
      detailedDataToConsumeForDefaultSheet,
    )
        .then(finishSheet),
    finishSheet,

    handleSheetOperationStatusChanged: () =>
      handleSheetOperationStatusChangedFromDispatchProps(
        sheetOperationsTableId,
        fetchSheetOperations,
        allTasksTablesIds,
      ),

    handleSheetFinished: (
      {
        sheetId,
        sheetIdentity,
        orderId,
        orderName,
      },
      isOrderCompleted,
      ) => {
      handleSheetFinishedFromDispatchProps(sheetId, sheetIdentity, allTasksTablesIds, orderId, orderName, isOrderCompleted);
      closeReviewDialog();
    },

    handleSheetOperationDataChanged: () =>
      handleSheetOperationDataChangedFromDispatchProps(
        sheetOperationsTableId,
        fetchSheetOperations,
        allTasksTablesIds,
      ),

    handleSheetPaused: ({
      sheetId,
      sheetIdentity,
    }) => {
      handleSheetPausedFromDispatchProps(sheetId, sheetIdentity, allTasksTablesIds);
      closeReviewDialog();
    },

    handleEntityBatchSplit: ({
      parentSheetId,
      isParentEntityBatchWasFinished,
      isChildEntityBatchWasFinished,
      changedOrderId,
      sheetIdentity,
    }) => {
      handleEntityBatchSplitFromDispatchProps({
        allTasksTablesIds,
        parentSheetId,
        isParentEntityBatchWasFinished,
        isChildEntityBatchWasFinished,
        changedOrderId,
        sheetIdentity,
      });

      /*
      * В отличии от обработки некоторых других событий, когда закрытие модального окна просмотра МЛ сразу, кажется,
      * логичным, в случае с событием деления партии могут возникать вопросы - мол зачем закрывать просмотр МЛ,
      * мы разделили текущую партию и можем остаться просматривать её МЛ, нужно только обновить количество в шапке.
      * И это вполне реализуемо, если сделать доработки, что SheetReviewDialog будет дополнительно к текущим пропсами,
      * отправлять в компоненты контенты новый пропс колбэк на изменение данных по МЛ его локального стейта.
      * Но, всё же нет окончательного понимания, насколько нужно оставаться в модальнике просмотра МЛ, фактически,
      * МЛ разделен и мы не знаем хочет пользователь остаться смотреть его, или же новый отделенный МЛ, или вообще
      * ему больше ничего не надо. Поэтому как самое простое решение, чтобы не обновлять данные и не делать доработки,
      * всё же решено было закрывать модальник просмотра исходного МЛ. Возможно, это изменится в будущем.
      * */
      closeReviewDialog();
    },

    handleDefectiveEntitiesMarked: eventData => {
      handleDefectiveEntitiesMarkedFromDispatchProps(eventData, allTasksTablesIds);
      closeReviewDialog();
    },
  };
};

export const SheetInProductionReviewContentContainer = compose(
  withPermissionsManager,
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
  ),
)(SheetInProductionReviewContent);

SheetInProductionReviewContentContainer.displayName = 'SheetInProductionReviewContentContainer';
