import { ShortAmountLabelTrans, ShortPercentLabelTrans } from '../../../../utils/commonTransComponents';
import _isNumber from 'lodash/isNumber';
import { TEXT_FIELD_INPUT_MODES } from '../../TextFieldWithInputModes/constants';

export const SHEET_OPERATION_PROGRESS_MODE_LABELS_MAP = {
  [TEXT_FIELD_INPUT_MODES.PERCENT]: ShortPercentLabelTrans,
  [TEXT_FIELD_INPUT_MODES.AMOUNT]: ShortAmountLabelTrans,
};

/*
 * Отображаются только целые числа для любого из режимов
 * */
export const prepareSheetOperationProgressValueToDisplay = progress => {
  const numberProgress = _isNumber(progress) ? progress : Number(progress);

  return Number.isInteger(numberProgress) ?
    numberProgress.toString() :
    Math.floor(numberProgress).toString();
};
