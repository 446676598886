import PropTypes from 'prop-types';

import _omit from 'lodash/omit';
import { SHEET_OPERATION_STATUS, ENTITY_BATCH_PROVIDING_STATE, ENTITY_BATCH_PROVIDABLE_STATE } from './sheets';
import { FILTER_GROUP_TYPES, FILTER_TYPES } from '../api/restCollectionApi/index';
import { MATERIAL_UI_DIALOG_MAX_WIDTH } from './materialUI';

const {
  string,
  objectOf,
  number,
  arrayOf,
  any,
  oneOfType,
  func,
  shape,
  bool,
  element,
  elementType,
  oneOf,
  node,
} = PropTypes;

export const NUMBER_OR_STRING_TYPE = oneOfType([string, number]);

export const OBJECT_OF_ANY_TYPE = objectOf(any);
export const ARRAY_OF_ANY_OBJECTS_TYPE = arrayOf(OBJECT_OF_ANY_TYPE);

export const FUNC_IS_REQUIRED_TYPE = func.isRequired;

export const SELECTED_WORKER_TASKS_TABLE_SETTINGS_DATA_TYPE = shape({
  departmentIdentity: string,
  departmentName: string,
  equipmentClassIdentity: string,
  equipmentClassName: string,
  tasksTableColumns: OBJECT_OF_ANY_TYPE.isRequired,
});

export const SETTINGS_SELECT_OPTION_TYPE = PropTypes.shape({
  id: PropTypes.string.isRequired,
  data: SELECTED_WORKER_TASKS_TABLE_SETTINGS_DATA_TYPE,
});

export const TABLE_COLUMNS_TYPE = arrayOf(shape({
  accessor: string.isRequired,
  show: bool.isRequired,
  Header: oneOfType([string, element]).isRequired,
  className: string,
  sortable: bool.isRequired,
  resizable: bool.isRequired,
  type: string.isRequired,
  width: number,
  customCellRenderer: elementType,
}));

const ENTITY_BATCH_PROVIDING_STATE_TYPE = PropTypes.oneOf(Object.values(ENTITY_BATCH_PROVIDING_STATE));

const ENTITY_BATCH_PROVIDABLE_STATE_TYPE = PropTypes.oneOf(Object.values(ENTITY_BATCH_PROVIDABLE_STATE));

const SHEET_SUMMARY_SHAPE = {
  entityIdentity: string,
  entityCode: string,
  entityName: string,
  sheetId: NUMBER_OR_STRING_TYPE,
  sheetIdentity: string,
  entityBatchId: NUMBER_OR_STRING_TYPE.isRequired,
  entityBatchIdentity: string,
  entitiesInBatchAmount: number.isRequired,
  orderId: NUMBER_OR_STRING_TYPE,
  orderName: string,
  startDate: string.isRequired,
  stopDate: string.isRequired,
  providingState: ENTITY_BATCH_PROVIDING_STATE_TYPE,
  providableState: ENTITY_BATCH_PROVIDABLE_STATE_TYPE,
};

export const SHEET_SUMMARY_TYPE = shape(SHEET_SUMMARY_SHAPE);
export const INAPPROPRIATE_SHEET_TO_REVIEW_DATA_TYPE = shape({
  ...SHEET_SUMMARY_SHAPE,
  userName: string,
  defectReason: string,
  entityBatchTransactionCreateStamp: string,
});

export const SHEET_TO_START_SUMMARY_TYPE = shape({
  ..._omit(
    SHEET_SUMMARY_SHAPE,
    ['sheetId', 'sheetIdentity', 'orderId', 'providingState'],
  ),
  fromState: PropTypes.bool,
});

export const SHEET_IN_PRODUCTION_SUMMARY_TYPE = shape(
  _omit(
    SHEET_SUMMARY_SHAPE,
    ['entityBatchId', 'entityBatchIdentity'],
  ),
);

export const COMPLETED_SHEET_SUMMARY_TYPE = SHEET_IN_PRODUCTION_SUMMARY_TYPE;
export const DEFAULT_SHEET_WAITING_PARTS_AND_MATERIALS_SUMMARY_TYPE = SHEET_IN_PRODUCTION_SUMMARY_TYPE;

export const TRANS_TYPE = oneOfType([string, element]);

export const TASK_TABLE_ROW_DATA_TYPE = shape({
  sheetOperationId: NUMBER_OR_STRING_TYPE.isRequired,
  status: oneOf(Object.values(SHEET_OPERATION_STATUS)),
  progress: number.isRequired,
  canBeStarted: bool.isRequired,
  isPreviousSheetOperationFinished: bool.isRequired,
  providingState: ENTITY_BATCH_PROVIDING_STATE_TYPE.isRequired,
  sheetId: NUMBER_OR_STRING_TYPE.isRequired,
  sheetIdentity: string.isRequired,
  entityBatchId: NUMBER_OR_STRING_TYPE.isRequired,
  entitiesInBatchAmount: number.isRequired,
  operationStartDate: string.isRequired,
  operationStopDate: string.isRequired,
  operationIdentity: string.isRequired,
  operationNumber: string.isRequired,
  operationName: string.isRequired,
  entityIdentity: string.isRequired,
  entityCode: string,
  entityName: string.isRequired,
  orderName: string,
  orderPriority: number,
  isAssembly: bool.isRequired,
  isAssemblySheet:  bool.isRequired,
});

export const SHEET_OPERATION_STATUS_TYPE = PropTypes.oneOf(Object.values(SHEET_OPERATION_STATUS));

const SCHEMA_MODEL_FIELD_TYPE_SHAPE_OBJ =  {
  columnName: string.isRequired,
  displayName: node.isRequired,
  order: number,
  show: bool,
  display: bool,
  type: string,
  defaultValue: NUMBER_OR_STRING_TYPE,
  textAlign: string,
  className: arrayOf(string),
  customComponent: string,
  width: number,
  tooltip: bool,
  disableSort: bool,
  disableFilter: bool,
};

export const TABLE_SCHEMA_FIELD_TYPE = shape({
  ...SCHEMA_MODEL_FIELD_TYPE_SHAPE_OBJ,
  customComponent: elementType,
});
export const TABLE_SCHEMA_FIELDS_TYPE = objectOf(TABLE_SCHEMA_FIELD_TYPE);

export const TABLE_SORT_PARAMS_TYPE = arrayOf(shape({
  column: string.isRequired,
  asc: bool.isRequired,
}));

export const TABLE_COLUMNS_FILTER_VALUE_TYPE = oneOfType([
  number,
  string,
  arrayOf(NUMBER_OR_STRING_TYPE),
]);

export const TABLE_FILTER_PARAMS_TYPE = objectOf(shape({
  filterType: oneOf(Object.values(FILTER_TYPES)).isRequired,
  filterValue: TABLE_COLUMNS_FILTER_VALUE_TYPE,
}));

export const TABLE_COLUMNS_CUSTOM_FILTERS_TYPE = objectOf(
  shape({
    filterComponent: elementType,
    filterComponentProps: OBJECT_OF_ANY_TYPE,
    getFilterTitle: PropTypes.func,
    getFilterChipContent: PropTypes.func,
  }),
);

const TABLE_MENU_OPTION_SHAPE_OBJ = {
  optionId: NUMBER_OR_STRING_TYPE,
  optionTitle: node,
  optionClassName: string,
  optionIconClassName: string,
  onSelect: func,
  isVisible: func,
  isActive: func,
};

export const TABLE_MENU_OPTIONS_TYPE = arrayOf(shape({
  ...TABLE_MENU_OPTION_SHAPE_OBJ,
  subMenu: shape({
    subMenuClassName: string,
    suMenuOptions: arrayOf(shape({
      ...TABLE_MENU_OPTION_SHAPE_OBJ,
    })),
  }),
}));

export const TABLE_MENU_TYPE = shape({
  disableVisibilityOption: PropTypes.bool,
  disableSummaryOption: PropTypes.bool,
  disablePageSizeOption: PropTypes.bool,
  summaryOptionTitle: node,
  visibilityOptionTitle: node,
  pageSizeOptionTitle: node,
  menuOptions: TABLE_MENU_OPTIONS_TYPE,
  disableMenu: PropTypes.bool,
});

const TABLE_SUMMARY_COMPONENT_PROPS_TYPE = shape({
  summaryKeys: arrayOf(string).isRequired,
});

const REMOTE_TABLE_SUMMARY_COMPONENT_PROPS_TYPE = shape({
  tableId: string,
  tableModel: string,
  requestParams: shape({
    requestModel: string.isRequired,
    with: arrayOf(string),
    tableModel: string.isRequired,
  }),
  aggregatedDataSchema: shape({
    customColumns: OBJECT_OF_ANY_TYPE,
    rules: arrayOf(shape({
      column: string.isRequired,
      rule: string.isRequired,
    })),
  }),
});

export const TABLE_SUMMARY_TYPE = shape({
  title: string.isRequired,
  Component: elementType,
  props: oneOfType([TABLE_SUMMARY_COMPONENT_PROPS_TYPE, REMOTE_TABLE_SUMMARY_COMPONENT_PROPS_TYPE]),
});

export const ENTITY_OR_RESOURCE_COMMON_FIELDS = {
  id: NUMBER_OR_STRING_TYPE.isRequired,
  name: string,
  identity: string.isRequired,
};

export const ENTITY_OR_RESOURCE_COMMON_FIELDS_BASED_TYPE = shape(ENTITY_OR_RESOURCE_COMMON_FIELDS);

export const USER_DATA_TYPE = shape({
  id: NUMBER_OR_STRING_TYPE.isRequired,
  name: string.isRequired,
  lastName: string.isRequired,
  patronymicName: string,
  identity: NUMBER_OR_STRING_TYPE.isRequired,
  roles: arrayOf(string).isRequired,
  permissions: arrayOf(number).isRequired,
});

export const MASTER_TASKS_TABLE_ROW_DATA_TYPE = shape({
  orderId: NUMBER_OR_STRING_TYPE,
  order__name: string,
  entityRouteSheet__identity: string.isRequired,
  entity__identity: string.isRequired,
  entity__code: string,
  entity__name: string.isRequired,
  operation__identity: string.isRequired,
  operation__nop: string.isRequired,
  operation__name: string.isRequired,
  progress: number.isRequired,
  startDate: string.isRequired,
  stopDate: string.isRequired,
  department__identity: string.isRequired,
  department__name: string.isRequired,
  equipmentClass__identity: string.isRequired,
  equipmentClass__name: string.isRequired,
  isAssembly: bool.isRequired,
  isAssemblySheet: bool.isRequired,

  taskId:  NUMBER_OR_STRING_TYPE.isRequired,
  status: oneOf(Object.values(SHEET_OPERATION_STATUS)),
  canBeStarted: PropTypes.bool.isRequired,
});


export const ORDERS_REVIEW_TABLE_ROW_DATA_TYPE = shape({
  id: NUMBER_OR_STRING_TYPE.isRequired,
  name: string.isRequired,
  priority: number.isRequired,
  status: number.isRequired,
  startDate: string,
  stopDate: string,
});

const REQUEST_COLUMN_FILTER_TYPE = PropTypes.shape({
  column: string,
  filterType: oneOf(Object.values(FILTER_TYPES)),
  filterValue: bool,
});
export const REQUEST_FILTER_GROUP_TYPE = PropTypes.shape({
  filterGroupType: oneOf(Object.values(FILTER_GROUP_TYPES)),
});
REQUEST_FILTER_GROUP_TYPE.filters = oneOf([
  arrayOf(REQUEST_COLUMN_FILTER_TYPE),
  REQUEST_FILTER_GROUP_TYPE,
]);

/*
* Типы из исходников material-ui
* */
export const MATERIAL_UI_DIALOG_MAX_WIDTH_TYPE = oneOf([...Object.values(MATERIAL_UI_DIALOG_MAX_WIDTH), false]);

export const MATERIAL_UI_GRID_BREAKPOINT_VALUE_TYPE = oneOf([false, 'auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);

export const MATERIAL_UI_GRID_BREAKPOINTS_TYPE = shape({
  xs: MATERIAL_UI_GRID_BREAKPOINT_VALUE_TYPE,
  sm: MATERIAL_UI_GRID_BREAKPOINT_VALUE_TYPE,
  md: MATERIAL_UI_GRID_BREAKPOINT_VALUE_TYPE,
  lg: MATERIAL_UI_GRID_BREAKPOINT_VALUE_TYPE,
  xl: MATERIAL_UI_GRID_BREAKPOINT_VALUE_TYPE,
});
