import { Transformer } from '../Transformer';
import {
  getSettingsEntityUniqId,
  WORKER_EQ_CLASS_IN_DEP_TASKS_TABLE_SETTINGS_GROUP,
} from '../../constants/settings';
import { SCHEMA_MODEL_INITIAL_STATE } from '../../reducers/schemaModel/initialState';
import { TASK_VIEW_SCREEN_MODEL } from '../../reducers/schemaModel/models/taskViewScreenSchema';
import _pickBy from 'lodash/pickBy';


/*
* Настройки в ca_frontend разделены на группы, идентификатор каждой группы задаётся константой, например
* WORKER_TASKS_TABLE_SETTINGS_ID. В каждой такой группе может храниться неограниченное количество разных
* экземпляров группы настроек, такие экземпляры хранятся по ключам group__name.
* */
export class Settings extends Transformer {

  static transformToState(item) {
    const {
      name,
      value,
      group,
    } = item;

    const transformedValue = group === WORKER_EQ_CLASS_IN_DEP_TASKS_TABLE_SETTINGS_GROUP ?
      transformTasksTableColumnsSettingsValue(value) :
      value;

    return {
      id: getSettingsEntityUniqId(group, name),
      name,
      value: transformedValue,
      group,
    };
  };
}

const transformTasksTableColumnsSettingsValue = value => {
  const {
    tasksTableColumns,
  } = value;

  /*
    * Настройки порядка колонок в таблице заданий настраиваются пользователем и хранятся в json поле БД
    * Соответственно, в случае появления новых колонок в таблице, миграция в БД никакая не производится.
    *
    * Нужно корректно обработать кейс появления в старых пользовательских настройках новых колонок.
    * Исходим из следующих положений:
    * - пользовательский порядок колонок не должен изменяться
    * - новые колонки должны появиться в модели таблицы в конце (в правой части)
    * - для всех колонок значение поля order должно быть уникальным
    * */

  const amountOfColumnsInUserSettings = Object.keys(tasksTableColumns).length;

    /*
  * Сначала ищем новые колонки таблицы заданий, которых нет в пользовательских настройках
  * */
  const newTaskTableColumnsDataFromSchema = _pickBy(
    SCHEMA_MODEL_INITIAL_STATE[TASK_VIEW_SCREEN_MODEL].fields,
    (value, key) => !tasksTableColumns[key],
  );

  /*
  * если новые колонки есть, то пробегаемся по ним и формируем данные настроек, а именно, проставляем ключ show в
  * дефолтном значении и ключ order с таким значением, чтобы новые колонки попали в конец модели таблицы
  * */
  const newTaskTableColumnsSettingsData = Object
    .keys(newTaskTableColumnsDataFromSchema)
    .reduce(
      (acc, key, index) => {
        const {
          show,
        } = newTaskTableColumnsDataFromSchema[key];

        acc[key] = {
          show,

          /*
          * в amountOfColumnsInUserSettings содержится общее количество колонок в модели таблицы. Но order начинается
          * с 0 и по идее, чтобы использовать amountOfColumnsInUserSettings в качестве максимального порядкового номера
          * колонки мы должны были бы вычесть из этого значения единицу.
          * Однако, при пробеге по массиву новых колонок модели мы используем для корректировки order значение index
          * для текущей итерации. Т.к. индексы массивов начинаются с 0 и мы должны были бы прибавить к индексу единицу,
          * но т.к. до этого мы единицу не вычитали, то и сейчас можем не прибавлять, а просто использовать индекс.
          *
          * Пример:
          * были колонки со следующими order: 0, 1, 2, 3, 4, 5
          * amountOfColumnsInUserSettings: 6
          * новых колонок 3 штуки, для них order должны быть присвоены: 6 для колонки №1, 7 для №2, 8 для №3
          * при пробеге по колонкам мы присваиваем:
          *  первой колонке amountOfColumnsInUserSettings (6) + index (0) = 6
          *  второй колонке amountOfColumnsInUserSettings (6) + index (1) = 7
          *  третьей колонке amountOfColumnsInUserSettings (6) + index (2) = 8
          * */
          order: amountOfColumnsInUserSettings + index,
        };

        return acc;
      },
      {},
    );

  return {
    ...value,
    tasksTableColumns: {
      ...tasksTableColumns,
      ...newTaskTableColumnsSettingsData,
    },
  };
};
