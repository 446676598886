import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';

import { push } from 'connected-react-router';
import { allTasksTablesIdsSelector, tasksTableDataSelector } from '../../../selectors/taskView';

import { fetchTasksRemoteTableData, initTasksTableSchema, updateFiltersWithScannedSheet } from '../../../operations/tasks';
import { SHEET_TYPE } from '../../../constants/sheets';
import { clearTableData, clearTableRemoteData, reFetchRemoteTableData } from '../../../reducers/table/actions';
import { createOrderEntriesTableId, createSheetTypeOperationsTableId } from '../../../utils/tables';
import { deleteEntitiesFromStore } from '../../../reducers/entities/actions';
import { SHEET_MODEL } from '../../../constants/models';
import {
  clearAllDefaultSheetsPartsAndMaterialsToConsume,
  clearDefaultSheetPartsAndMaterialsToConsume,
} from '../../../reducers/storageManagementApp/defaultSheets/actions';
import { getTaskToDoTableRowStyle } from '../../../tableProperties/rowStyles/taskRowStyles';
import { MASTER_TASKS_TO_DO_TABLE_ID } from '../../MasterApp/MasterTasksToDo/constants';
import { MASTER_COMPLETED_TASKS_TABLE_ID } from '../../MasterApp/MasterCompletedTasks/constants';
import { sendEntityBatchSplitNotification, sendSheetPausedNotification } from '../../../operations/sheets';
import { PAUSED_SHEETS_TABLES_IDS_ARRAY } from '../../../constants/table';
import { ORDER_IN_PRODUCTION_AND_READY_TO_COMPLETE_TABLES_IDS, ORDER_TYPE } from '../../../constants/orders';
import {
  deleteAllAssemblySheetsReserveData,
  deleteAssemblySheetReserveData,
} from '../../../reducers/storageManagementApp/assemblySheets/reserveData/actions';
import { deleteAssemblySheetConsumeData } from '../../../reducers/workerApp/assemblySheets/consumeData/actions';
import { DEFECT_MARKING_USE_CASES, getDefectMarkingUseCase } from '../../../utils/sheets';
import { sendOrderIsReadyToCompleteNotification } from '../../../operations/orders';
import { Tasks } from './Tasks';
import { TASKS_MODEL } from '../../../reducers/schemaModel/models/tasksSchema';
import { workerTasksAdminFiltersSettingsSelector } from '../../../selectors/settings';

import { asyncComponent } from '../../../hoc/asyncComponent/asyncComponent';
import { saveUserSettings } from '../../../operations/userSettings';
import { tasksAdditionalFiltersSelector } from '../../../reducers/appState/selectors';
import { USER_SETTINGS_ID } from '../constants';
import {
  getEntityBatchSplitCaseSpecificActions,
  getSplitEntityBatchCaseData,
} from '../../../api/socketApi/socketMessageHandlers/caClientMessageHandler/entityBatchSplit';
import {
  getOrderCompletedRelatedActions,
} from '../../../api/socketApi/socketMessageHandlers/caClientMessageHandler/sheetFinish';

const tasksTableId = TASKS_MODEL;


const mapStateToProps = state => {

  const tasksTableData = tasksTableDataSelector(state, { tableId: tasksTableId });

  const allTasksTablesIds = allTasksTablesIdsSelector(state);

  const { isWorkerTasksFiltersManagingByAdmin } = workerTasksAdminFiltersSettingsSelector(state);

  const workerTasksAdditionalFilters = tasksAdditionalFiltersSelector(state);

  return {
    tasksTableId,
    tasksTableData,
    allTasksTablesIds,
    workerTasksAdditionalFilters,
    isWorkerTasksFiltersManagingByAdmin,
  };
};

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      push,
      reFetchRemoteTableData,
      fetchTasksRemoteTableData,
      clearTableRemoteData,
      updateFiltersWithScannedSheet,
      saveUserSettings,
      initTasksTableSchema,
    },
    dispatch,
  ),

  /*
  * Подробное описание о влиянии события завершения МЛ на разделы приложения представлено в комментарии к
  * handleSheetFinished в SheetInProductionReviewContentContainer. Выполняем здесь аналогичные очистки, но, т.к.
  * в этом случае завершаем МЛ из интерфейса просмотра ВСЕХ заданий в приложении "Рабочий", то текущую таблицу очищать
  * не нужно, для неё сразу перезапрашиваются данные (reFetchCurrentTasksRemoteTableData)
  */
  handleSheetFinished: (
    sheetId,
    allTasksTablesIds,
    reFetchCurrentTasksRemoteTableData,
    orderId,
    orderName,
    isOrderCompleted,
  ) => {

    reFetchCurrentTasksRemoteTableData();

    const orderCompletedActionsToDispatch = isOrderCompleted ?
      ([
        clearTableRemoteData(ORDER_IN_PRODUCTION_AND_READY_TO_COMPLETE_TABLES_IDS),
        clearTableData(createOrderEntriesTableId(ORDER_TYPE.IN_PRODUCTION, orderId)),
      ]) :
      [];

    dispatch([
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId)),
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        SHEET_TYPE.COMPLETED,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        MASTER_TASKS_TO_DO_TABLE_ID,
        MASTER_COMPLETED_TASKS_TABLE_ID,
        ...allTasksTablesIds,
      ]),
      deleteAllAssemblySheetsReserveData(),
      deleteAssemblySheetConsumeData({ sheetId }),
      deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
      clearAllDefaultSheetsPartsAndMaterialsToConsume(),
      ...orderCompletedActionsToDispatch,
    ]);
  },


  /*
  * Подробное описание события изменение статуса операции МЛ (т.е. задания для приложения "Рабочий") представлено в
  * комментарии handleSheetOperationStatusChanged в SheetInProductionReviewContentContainer. Здесь выполняются
  * аналогичные обработки, только для случая, когда статус операций изменяют из раздела просмотра ВСЕХ заданий в
  * приложении "Рабочий". Нужно, аналогично, очистить все таблицы заданий для классов РЦ в подразделении в другом
  * разделе приложения "Рабочий", а, также, очистить данные таблицы просмотра операций МЛ в приложении "Плановик" и
  * заданий в приложении "Мастер", чтобы при следующем входе в эти разделы обновленные данные были запрошены заново.
  * Для текущей таблицы в разделе ВСЕХ заданий приложения "Рабочий" данные не очищаем, т.к. для неё сразу
  * выполняется перезапрос данных - reFetchCurrentTasksRemoteTableData.
  * Для событий начала, приостановки и возобновления выполнения задания, теоретически, можно было находить операцию
  * в табличном стор и обновлять данные без перезапроса, но решено сделать обработку проще, по аналогии с событием
  * завершения задания, когда задание удаляется из таблицы и в этом случае для серверной таблицы обязательно нужен
  * перезапрос данных, т.к. происходит смещение. Кроме того, перезапрос при любом изменение статуса задания гарантирует
  * синхронность работы с сервером, который изменяет статусы операций экшн точкой, т.е. мы перезапрашиваем данные,
  * чтобы получить от сервера результат его действий после выполнения запроса на экшн точку и точно уверены, что
  * отображаем то же самое, что у нас в БД.
  * */
  handleTaskStatusChanged: (sheetId, allTasksTablesIds, reFetchCurrentTasksRemoteTableData) => {

    reFetchCurrentTasksRemoteTableData();

    dispatch(clearTableRemoteData([
      createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId),
      MASTER_TASKS_TO_DO_TABLE_ID,
      MASTER_COMPLETED_TASKS_TABLE_ID,
      ...allTasksTablesIds,
    ]));
  },

  /*
  * Подробное описание о влиянии события изменения данных операции МЛ представлено в комментарии к
  * handleSheetOperationDataChanged в SheetInProductionReviewContentContainer. Здесь всё аналогично, очищаем все
  * таблицы заданий для классов РЦ в подразделении в соседнем разделе приложения "Рабочий", также таблицу "Мастера" по
  * "заданиям, которые нужно сделать" ("завершенные задания" в мастере это событие не влияет), и таблицу просмотра
  * операций МЛ в приложении "Плановик", чтобы при следующем входе в эти разделы обновленные данные были
  * запрошены заново. Для текущей таблицы ВСЕХ заданий "Рабочего" данные не очищаем, т.к. для неё сразу выполняется
  * перезапрос данных - reFetchCurrentTasksRemoteTableData, а, если сначала очистить, то интерфейс будет"дергаться".
  * Подробное пояснение почему данные текущей таблицы просто перезапрашиваются тоже есть в упоминаемом выше комментарии.
  */
  handleSheetOperationDataChanged: (
    sheetId,
    allTasksTablesIds,
    reFetchCurrentTasksRemoteTableData,
  ) => {
    reFetchCurrentTasksRemoteTableData();

    dispatch(clearTableRemoteData([
      createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId),
      MASTER_TASKS_TO_DO_TABLE_ID,
      ...allTasksTablesIds,
    ]));
  },

  /*
  * Подробное описание о влиянии события приостановки МЛ на разделы приложения представлено в комментарии к
  * handleSheetPaused SheetInProductionReviewContentContainer. Выполняем здесь аналогичные очистки, но, т.к.
  * в этом случае завершаем МЛ из интерфейса просмотра ВСЕХ заданий, то текущую таблицу очищать не нужно, для неё
  * сразу перезапрашиваются данные (reFetchCurrentTasksRemoteTableData)
  */
  handleSheetPaused: (
    sheetId,
    sheetIdentity,
    allTasksTablesIds,
    reFetchCurrentTasksRemoteTableData,
  ) => {

    reFetchCurrentTasksRemoteTableData();

    //Выводим нотификейшен о приостановке МЛ
    sendSheetPausedNotification(sheetIdentity);

    dispatch([
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId)),
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        ...PAUSED_SHEETS_TABLES_IDS_ARRAY,
        MASTER_TASKS_TO_DO_TABLE_ID,
        ...allTasksTablesIds,
      ]),
      deleteAssemblySheetConsumeData({ sheetId }),
      deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
      clearDefaultSheetPartsAndMaterialsToConsume(sheetId),
    ]);
  },

  /*
  * Подробное описание о влиянии события деления партии на разделы приложения представлено в комментарии к
  * handleEntityBatchSplit в SheetInProductionReviewContentContainer. Выполняем здесь аналогичные очистки и запросы,
  * но, т.к. в этом случае деление партии выполняется из интерфейса просмотра ВСЕХ заданий Рабочего,
  * то текущую таблицу заданий очищать не нужно, для неё сразу перезапрашиваются данные
  * (reFetchMasterTasksToDoRemoteTableData)
  * */
  handleEntityBatchSplit: ({
    parentSheetId,
    isParentEntityBatchWasFinished,
    isChildEntityBatchWasFinished,
    changedOrderId,
    sheetIdentity,
    allTasksTablesIds,
    reFetchTasksRemoteTableData,
  }) => {

    reFetchTasksRemoteTableData();

    // выводим нотификейшн о наступлении события разделения партии
    sendEntityBatchSplitNotification(sheetIdentity);

    const {
      isOneOfBatchesFinished,
      isOrderFinished,
    } = getSplitEntityBatchCaseData({
      isParentEntityBatchWasFinished,
      isChildEntityBatchWasFinished,
      changedOrderId,
    });

    const sheetOperationsTableId = createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, parentSheetId);

    const tableIdsToClear = [
      sheetOperationsTableId,
      SHEET_TYPE.IN_PRODUCTION,
      MASTER_TASKS_TO_DO_TABLE_ID,
      MASTER_COMPLETED_TASKS_TABLE_ID,
      SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      ...allTasksTablesIds,
    ];

    if(isOneOfBatchesFinished) {
      tableIdsToClear.push(SHEET_TYPE.COMPLETED);
    }

    const actionsToDispatch = [
      clearTableRemoteData(tableIdsToClear),

      ...getEntityBatchSplitCaseSpecificActions({
        isOneOfBatchesFinished,
        parentSheetId,
      }),

      ...getOrderCompletedRelatedActions(isOrderFinished, changedOrderId),
    ];


    dispatch(actionsToDispatch);
  },

  /*
  * Подробное описание о влиянии события фиксации брака на разделы приложения представлено в комментарии к
  * handleDefectiveEntitiesMarked в SheetInProductionReviewContentContainer.
  * Выполняем здесь аналогичные очистки и запросы, но, т.к. в этом случае фиксация брака выполняется из интерфейса
  * ВСЕХ заданий "Рабочего", то текущую таблицу очищать не нужно, для неё сразу перезапрашиваются
  * данные в reFetchCurrentTasksRemoteTableData
  */
  handleDefectiveEntitiesMarked: (
    data,
    allTasksTablesIds,
    reFetchCurrentTasksRemoteTableData,
  ) => {

    const {
      sheetOperationStatus,
      sheetId,
      completedOrderId,
      completedOrderName,
      wasEntityBatchSplit,
      wasEntityBatchFinished,
    } = data;

    const defectMarkingUseCase = getDefectMarkingUseCase(
      sheetOperationStatus,
      wasEntityBatchSplit,
      wasEntityBatchFinished,
    );

    /*
    * Список экшенов для кейсов, для которых нужна кастомная, а не универсальная обработка
    * */
    let customActionsToDispatch = [];

    if(
      defectMarkingUseCase === DEFECT_MARKING_USE_CASES.PART_OF_ENTITY_BATCH_ON_LAST_OPERATION_FINISH &&
      completedOrderId !== null
    ) {
      //Выводим нотификейшен в случае, когда завершение части МЛ означает также и готовность заказа
      sendOrderIsReadyToCompleteNotification(completedOrderName);

      customActionsToDispatch.push(
        clearTableRemoteData(ORDER_IN_PRODUCTION_AND_READY_TO_COMPLETE_TABLES_IDS),
        clearTableData(createOrderEntriesTableId(ORDER_TYPE.IN_PRODUCTION, completedOrderId)),
      );
    }

    if(
      defectMarkingUseCase === DEFECT_MARKING_USE_CASES.WHOLE_ENTITY_BATCH_ON_OPERATION_FINISH ||
      defectMarkingUseCase === DEFECT_MARKING_USE_CASES.WHOLE_ENTITY_BATCH_ON_OPERATION_PAUSE ||
      defectMarkingUseCase === DEFECT_MARKING_USE_CASES.PART_OF_ENTITY_BATCH_ON_LAST_OPERATION_FINISH
    ) {
      customActionsToDispatch.push(
        deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
      );
    }

    if(defectMarkingUseCase === DEFECT_MARKING_USE_CASES.PART_OF_ENTITY_BATCH_ON_LAST_OPERATION_FINISH) {
      customActionsToDispatch.push(
        clearAllDefaultSheetsPartsAndMaterialsToConsume(),
        deleteAllAssemblySheetsReserveData(),
        clearTableRemoteData([
          SHEET_TYPE.COMPLETED,
        ]),
      );
    }else{
      customActionsToDispatch.push(
        clearDefaultSheetPartsAndMaterialsToConsume(sheetId),
        deleteAssemblySheetReserveData({ sheetId }),
      );
    }

    /*
    * Универсальные обработки для всех кейсов, к которым добавляются кастомные customActionsToDispatch, вычисленные
    * в зависимости от текущего кейса
    * */
    reFetchCurrentTasksRemoteTableData();

    dispatch([
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId)),

      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        SHEET_TYPE.INAPPROPRIATE,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        MASTER_COMPLETED_TASKS_TABLE_ID,
        ...allTasksTablesIds,
      ]),

      deleteAssemblySheetConsumeData({ sheetId }),

      ...customActionsToDispatch,
    ]);

  },
});


const mergeProps = (stateProps, dispatchProps) => {
  const {
    tasksTableId,
    tasksTableData,
    allTasksTablesIds,
    workerTasksAdditionalFilters,
    isWorkerTasksFiltersManagingByAdmin,
  } = stateProps;

  const {
    fetchTasksRemoteTableData: fetchTasksRemoteTableDataFromDispatchProps,
    reFetchRemoteTableData,
    handleSheetFinished: handleSheetFinishedFromDispatchProps,
    handleTaskStatusChanged: handleTaskStatusChangedFromDispatchProps,
    handleSheetOperationDataChanged: handleSheetOperationDataChangedFromDispatchProps,
    handleSheetPaused: handleSheetPausedFromDispatchProps,
    handleEntityBatchSplit: handleEntityBatchSplitFromDispatchProps,
    handleDefectiveEntitiesMarked: handleDefectiveEntitiesMarkedFromDispatchProps,
    updateFiltersWithScannedSheet,
    saveUserSettings,
    initTasksTableSchema,
  } = dispatchProps;

  const fetchTasksRemoteTableData = ({ tableParams }) => fetchTasksRemoteTableDataFromDispatchProps(tableParams);


  const reFetchTasksRemoteTableData = () =>
    reFetchRemoteTableData(
      tasksTableId,
      tasksTableId,
      fetchTasksRemoteTableData,
    );

  const setWorkerTasksAdditionalFilters = newAdditionalFilters => {
    return saveUserSettings({
      [USER_SETTINGS_ID.WORKER_APP_TASKS_ADDITIONAL_FILTERS]: newAdditionalFilters,
    })
      .then(reFetchTasksRemoteTableData);
  };

  const saveWorkerTasksTableFiltersAndSearchHistory = (newFilters, newSearchHistory) => {
    return saveUserSettings({
      [USER_SETTINGS_ID.WORKER_APP_TASKS_TABLE_FILTERS]: newFilters,
      [USER_SETTINGS_ID.WORKER_APP_SEARCH_HISTORY]: newSearchHistory,
    });
  };


  return {
    tasksTableId,
    tasksTableData,
    workerTasksAdditionalFilters,
    setWorkerTasksAdditionalFilters,
    saveWorkerTasksTableFiltersAndSearchHistory,
    fetchTasksRemoteTableData,
    isWorkerTasksFiltersManagingByAdmin,
    handleTaskStatusChanged: ({ sheetId }) =>
      handleTaskStatusChangedFromDispatchProps(
        sheetId,
        allTasksTablesIds,
        reFetchTasksRemoteTableData,
      ),

    handleSheetFinished: ({ sheetId, orderId, orderName }, isOrderCompleted) =>
      handleSheetFinishedFromDispatchProps(
        sheetId,
        allTasksTablesIds,
        reFetchTasksRemoteTableData,
        orderId,
        orderName,
        isOrderCompleted,
      ),

    handleSheetOperationDataChanged: ({ sheetId }) =>
      handleSheetOperationDataChangedFromDispatchProps(
        sheetId,
        allTasksTablesIds,
        reFetchTasksRemoteTableData,
      ),

    handleSheetPaused: ({ sheetId, sheetIdentity }) =>
      handleSheetPausedFromDispatchProps(
        sheetId,
        sheetIdentity,
        allTasksTablesIds,
        reFetchTasksRemoteTableData,
      ),

    handleEntityBatchSplit: ({
      parentSheetId,
      isParentEntityBatchWasFinished,
      isChildEntityBatchWasFinished,
      changedOrderId,
      sheetIdentity,
    }) =>
      handleEntityBatchSplitFromDispatchProps({
        parentSheetId,
        isParentEntityBatchWasFinished,
        isChildEntityBatchWasFinished,
        changedOrderId,
        sheetIdentity,
        allTasksTablesIds,
        reFetchTasksRemoteTableData,
      }),

    handleDefectiveEntitiesMarked: eventData =>
      handleDefectiveEntitiesMarkedFromDispatchProps(
        eventData,
        allTasksTablesIds,
        reFetchTasksRemoteTableData,
      ),


    getTasksViewScreenTableRowStyle: getTaskToDoTableRowStyle,

    updateFiltersWithScannedSheet,

    initTasksTableSchema,
  };
};

export const TasksContainer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
  ),
  asyncComponent({
    resolve: [
      {
        fn: ({ initTasksTableSchema }) => {
          initTasksTableSchema();

          return Promise.resolve();
        },
      },
    ],
  }),
)(Tasks);

TasksContainer.displayName = 'TasksContainer';
