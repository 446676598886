import { createSelector } from 'reselect';

import {
  WORKER_EQ_CLASS_IN_DEP_TASKS_TABLE_MAIN_SETTINGS_ID, WORKER_EQ_CLASS_IN_DEP_TASKS_TABLE_MAIN_SETTINGS_NAME,
  WORKER_EQ_CLASS_IN_DEP_TASKS_TABLE_SETTINGS_GROUP, WORKER_TASKS_TABLE_MAIN_SETTINGS_ID,
} from '../constants/settings';

import { fieldComparatorFactory, stringComparator } from '@bfg-frontend/utils/lib/array';

import _get from 'lodash/get';
import _mapValues from 'lodash/mapValues';
import { SCHEMA_MODEL_INITIAL_STATE } from '../reducers/schemaModel/initialState';
import { TASK_VIEW_SCREEN_MODEL } from '../reducers/schemaModel/models/taskViewScreenSchema';
import {
  settingsEntitiesSelector,
  settingsEntitiesListForGroupSelector,
} from './settings';
import { TASKS_MODEL } from '../reducers/schemaModel/models/tasksSchema';


export const workerTasksTableSettingsSelectOptionsSelector = createSelector(
  state => settingsEntitiesListForGroupSelector(state, { group: WORKER_EQ_CLASS_IN_DEP_TASKS_TABLE_SETTINGS_GROUP }),
  workerTasksTableSettingsGroup => {

    const mainSettingsOption = {
      id: WORKER_EQ_CLASS_IN_DEP_TASKS_TABLE_MAIN_SETTINGS_ID,
      group: WORKER_EQ_CLASS_IN_DEP_TASKS_TABLE_SETTINGS_GROUP,
      name: WORKER_EQ_CLASS_IN_DEP_TASKS_TABLE_MAIN_SETTINGS_NAME,
      value: {
        departmentIdentity: null,
        departmentName: null,
        equipmentClassIdentity: null,
        equipmentClassName: null,
        tasksTableColumns: {},
      },
    };

    const settingsOptions = workerTasksTableSettingsGroup
      .filter(({ id }) => id !== WORKER_EQ_CLASS_IN_DEP_TASKS_TABLE_MAIN_SETTINGS_ID)
      .sort(fieldComparatorFactory(stringComparator, 'id'));

    return [mainSettingsOption].concat(settingsOptions);
  },
);

export const workerTasksTableSelectedSettingsDataSelector = createSelector(
  settingsEntitiesSelector,
  (_, { settingsId }) => settingsId,
  (settingsEntities, settingsId) => {
    const { value = {} } = _get(settingsEntities, [settingsId], {});
    /*
    * Мы вынуждены хранить в настройках в БД и клиентский идентификатор и имя, чтобы иметь возможность отобразить их
    * в случае, если после переимпорта данных таких сущностей уже нет в системе
    * */
    const {
      departmentIdentity,
      departmentName,
      equipmentClassIdentity,
      equipmentClassName,
      tasksTableColumns = {},
    } = value;

    return {
      departmentIdentity,
      departmentName,
      equipmentClassIdentity,
      equipmentClassName,
      tasksTableColumns: _mapValues(
        SCHEMA_MODEL_INITIAL_STATE[TASK_VIEW_SCREEN_MODEL].fields,
        (columnData, columnKey) => ({
          ...columnData,
          ..._get(tasksTableColumns, [columnKey], {}),
        }),
      ),
    };
  },
);

export const tasksTableSettingsSelector = createSelector(
  settingsEntitiesSelector,
  (settingsEntities = {}) => {

    const { value } = _get(settingsEntities, [WORKER_TASKS_TABLE_MAIN_SETTINGS_ID], {});

    return _mapValues(
      SCHEMA_MODEL_INITIAL_STATE[TASKS_MODEL].fields,
      (columnData, columnKey) => ({
        ...columnData,
        ..._get(value, [columnKey], {}),
      }),
    );
  },
);
