
export const SET_GROUP_SHEETS_START_PROCESS_DATA = 'SET_GROUP_SHEETS_START_PROCESS_DATA';
export const RESET_GROUP_SHEETS_START_PROCESS_STATE = 'RESET_GROUP_SHEETS_START_PROCESS_STATE';


export const setGroupSheetsStartProcessData = ({
  sheetsToStartAmount,
  startedSheetsAmount,
  status,
}) => ({
  type: SET_GROUP_SHEETS_START_PROCESS_DATA,
  sheetsToStartAmount,
  startedSheetsAmount,
  status,
});


export const resetGroupSheetsStartProcessState = () => ({
  type: RESET_GROUP_SHEETS_START_PROCESS_STATE,
});