import { Trans } from '@lingui/macro';
import React from 'react';
import { ReadyToStartOnlyLabel } from '../../../utils/commonTransComponents';

export const WORKER_TASKS_TABLE_ADDITIONAL_FILTER_IDENTITY = {
  CURRENT_USER_TASKS: 'CURRENT_USER_TASKS',
  START_READY_TASKS: 'START_READY_TASKS',
};


const handleAdditionalFilterChange = (currentFilterKey, isCurrentFilterChecked, allFilters, setFilters) =>
  () => {
    if(isCurrentFilterChecked) {
      const newFilters = allFilters.filter(filterKey => filterKey !== currentFilterKey);

      return setFilters(newFilters);
    }

    const newFilters = allFilters.concat(currentFilterKey);

    setFilters(newFilters);
  };

export const TASKS_VIEW_SCREEN_TABLE_ADDITIONAL_FILTERS_SCHEMA = [
  {
    filterKey: WORKER_TASKS_TABLE_ADDITIONAL_FILTER_IDENTITY.CURRENT_USER_TASKS,
    filterLabel: (
      <Trans id="tasks_view_screen@current_user_tasks_additional_filter">
        Я исполнитель
      </Trans>
    ),
    filterChangeCbFactory: handleAdditionalFilterChange,
  },
  {
    filterKey: WORKER_TASKS_TABLE_ADDITIONAL_FILTER_IDENTITY.START_READY_TASKS,
    filterLabel: ReadyToStartOnlyLabel,
    filterChangeCbFactory: handleAdditionalFilterChange,
  },
];