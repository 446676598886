import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';

import { asyncComponent } from '../../../hoc/asyncComponent/asyncComponent';

import { push } from 'connected-react-router';

import {
  departmentTasksFilterSelector,
  equipmentClassTasksFilterSelector,
} from '../../../reducers/workerApp/tasksMainFilters/selectors';
import { allTasksTablesIdsSelector, tasksViewScreenTableDataSelector } from '../../../selectors/taskView';

import { WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE } from '../../../constants/routes';

import { TasksViewScreen } from './TasksViewScreen';
import { initTasksDepartmentFilterFromRoute } from '../../../operations/departments';
import { initTasksEquipmentClassFilterFromRoute } from '../../../operations/equipmentClasses';
import {
  fetchEquipmentClassInDepartmentTasksRemoteTableData,
  initEqClassInDepTasksTableSchema,
} from '../../../operations/tasks';
import { SHEET_TYPE } from '../../../constants/sheets';
import { reactRouterCurrentPathnameSelector, reactRouterParamsSelector } from '../../../selectors/reactRouter';
import { clearTableData, clearTableRemoteData, reFetchRemoteTableData } from '../../../reducers/table/actions';
import {
  createOrderEntriesTableId,
  createSheetTypeOperationsTableId,
  getEquipmentClassInDepartmentTasksTableId,
} from '../../../utils/tables';
import { deleteEntitiesFromStore } from '../../../reducers/entities/actions';
import { SHEET_MODEL } from '../../../constants/models';
import {
  clearAllDefaultSheetsPartsAndMaterialsToConsume,
  clearDefaultSheetPartsAndMaterialsToConsume,
} from '../../../reducers/storageManagementApp/defaultSheets/actions';
import { getTaskToDoTableRowStyle } from '../../../tableProperties/rowStyles/taskRowStyles';
import { MASTER_TASKS_TO_DO_TABLE_ID } from '../../MasterApp/MasterTasksToDo/constants';
import { MASTER_COMPLETED_TASKS_TABLE_ID } from '../../MasterApp/MasterCompletedTasks/constants';
import { sendEntityBatchSplitNotification, sendSheetPausedNotification } from '../../../operations/sheets';
import { PAUSED_SHEETS_TABLES_IDS_ARRAY } from '../../../constants/table';
import { ORDER_IN_PRODUCTION_AND_READY_TO_COMPLETE_TABLES_IDS, ORDER_TYPE } from '../../../constants/orders';
import {
  deleteAllAssemblySheetsReserveData,
  deleteAssemblySheetReserveData,
} from '../../../reducers/storageManagementApp/assemblySheets/reserveData/actions';
import { deleteAssemblySheetConsumeData } from '../../../reducers/workerApp/assemblySheets/consumeData/actions';
import { DEFECT_MARKING_USE_CASES, getDefectMarkingUseCase } from '../../../utils/sheets';
import { sendOrderIsReadyToCompleteNotification } from '../../../operations/orders';
import { workerTasksAdminFiltersSettingsSelector } from '../../../selectors/settings';
import { TASKS_MODEL } from '../../../reducers/schemaModel/models/tasksSchema';
import { USER_SETTINGS_ID } from '../constants';
import { saveUserSettings } from '../../../operations/userSettings';
import { tasksViewScreenAdditionalFiltersSelector } from '../../../reducers/appState/selectors';
import {
  getEntityBatchSplitCaseSpecificActions,
  getSplitEntityBatchCaseData,
} from '../../../api/socketApi/socketMessageHandlers/caClientMessageHandler/entityBatchSplit';
import {
  getOrderCompletedRelatedActions,
} from '../../../api/socketApi/socketMessageHandlers/caClientMessageHandler/sheetFinish';


const mapStateToProps = (state, ownProps) => {
  const {
    departmentId: departmentIdFromRoute,
    equipmentClassId: equipmentClassIdFromRoute,
  } = reactRouterParamsSelector(null, ownProps);

  const currentPathname = reactRouterCurrentPathnameSelector(null, ownProps);

  const departmentTasksFilter = departmentTasksFilterSelector(state);
  const equipmentClassTasksFilter = equipmentClassTasksFilterSelector(state);

  const tasksTableId = getEquipmentClassInDepartmentTasksTableId(
    departmentTasksFilter.id,
    equipmentClassTasksFilter.id,
  );

  const { isWorkerTasksFiltersManagingByAdmin } = workerTasksAdminFiltersSettingsSelector(state);

  const tasksTableData = tasksViewScreenTableDataSelector(state, { tableId: tasksTableId });

  const allTasksTablesIds = allTasksTablesIdsSelector(state);

  const workerTasksAdditionalFilters = tasksViewScreenAdditionalFiltersSelector(state);

  return {
    workerTasksAdditionalFilters,
    departmentIdFromRoute,
    equipmentClassIdFromRoute,
    departmentTasksFilter,
    equipmentClassTasksFilter,
    tasksTableId,
    tasksTableData,
    allTasksTablesIds,
    isWorkerTasksFiltersManagingByAdmin,
    currentPathname,
  };
};

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      push,
      initTasksDepartmentFilterFromRoute,
      initTasksEquipmentClassFilterFromRoute,
      reFetchRemoteTableData,
      initEqClassInDepTasksTableSchema,
      fetchEquipmentClassInDepartmentTasksRemoteTableData,
      clearTableRemoteData,
      saveUserSettings,
    },
    dispatch,
  ),

  /*
  * Подробное описание о влиянии события завершения МЛ на разделы приложения представлено в комментарии к
  * handleSheetFinished в SheetInProductionReviewContentContainer. Выполняем здесь аналогичные очистки, но, т.к.
  * в этом случае завершаем МЛ из интерфейса просмотра заданий рабочего для класса РЦ в подразделении, то
  * текущую таблицу очищать не нужно, для неё сразу перезапрашиваются данные (reFetchCurrentTasksRemoteTableData)
  */
  handleSheetFinished: (
    sheetId,
    allTasksTablesIdsWithoutCurrentTableId,
    reFetchCurrentTasksRemoteTableData,
    orderId,
    orderName,
    isOrderCompleted,
  ) => {

    reFetchCurrentTasksRemoteTableData();

    const orderCompletedActionsToDispatch = isOrderCompleted ?
      ([
        clearTableRemoteData(ORDER_IN_PRODUCTION_AND_READY_TO_COMPLETE_TABLES_IDS),
        clearTableData(createOrderEntriesTableId(ORDER_TYPE.IN_PRODUCTION, orderId)),
      ]) :
      [];

    dispatch([
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId)),
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        SHEET_TYPE.COMPLETED,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        MASTER_TASKS_TO_DO_TABLE_ID,
        MASTER_COMPLETED_TASKS_TABLE_ID,
        TASKS_MODEL,
        ...allTasksTablesIdsWithoutCurrentTableId,
      ]),
      deleteAllAssemblySheetsReserveData(),
      deleteAssemblySheetConsumeData({ sheetId }),
      deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
      clearAllDefaultSheetsPartsAndMaterialsToConsume(),
      ...orderCompletedActionsToDispatch,
    ]);
  },


  /*
  * Подробное описание события изменение статуса операции МЛ (т.е. задания для приложения "Рабочий") представлено в
  * комментарии handleSheetOperationStatusChanged в SheetInProductionReviewContentContainer. Здесь выполняются
  * аналогичные обработки, только для случая, когда статус операций изменяют из раздела просмотра заданий рабочего для класса РЦ в подразделении
  * в приложении. "Рабочий". Нужно, аналогично, очистить таблицы заданий всех классоц РЦ в подразделении, кроме текущей,
  *  а, также, очистить данные таблицы просмотра операций МЛ в приложении "Плановик", заданий в приложении "Мастер" и
  * заданий в отдельном разделе ВСЕХ заданий рабочего, чтобы при следующем входе в эти разделы обновленные данные
  * были запрошены заново. Для текущей таблицы данные не очищаем, т.к. для неё сразу выполняется перезапрос данных -
  * reFetchCurrentTasksRemoteTableData, а, если сначала очистить, то интерфейс будет "дергаться".
  * Для событий начала, приостановки и возобновления выполнения задания, теоретически, можно было находить операцию
  * в табличном стор и обновлять данные без перезапроса, но решено сделать обработку проще, по аналогии с событием
  * завершения задания, когда задание удаляется из таблицы и в этом случае для серверной таблицы обязательно нужен
  * перезапрос данных, т.к. происходит смещение. Кроме того, перезапрос при любом изменение статуса задания гарантирует
  * синхронность работы с сервером, который изменяет статусы операций экшн точкой, т.е. мы перезапрашиваем данные,
  * чтобы получить от сервера результат его действий после выполнения запроса на экшн точку и точно уверены, что
  * отображаем то же самое, что у нас в БД.
  * */
  handleTaskStatusChanged: (sheetId, allTasksTablesIdsWithoutCurrentTableId, reFetchCurrentTasksRemoteTableData) => {

    reFetchCurrentTasksRemoteTableData();

    dispatch(clearTableRemoteData([
      createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId),
      MASTER_TASKS_TO_DO_TABLE_ID,
      MASTER_COMPLETED_TASKS_TABLE_ID,
      TASKS_MODEL,
      ...allTasksTablesIdsWithoutCurrentTableId,
    ]));
  },

  /*
  * Подробное описание о влиянии события изменения данных операции МЛ представлено в комментарии к
  * handleSheetOperationDataChanged в SheetInProductionReviewContentContainer. Здесь всё аналогично, очищаем все
  * таблицы просмотра заданий рабочего для класса РЦ в подразделении, кроме текущей, также таблицу "Мастера" по
  * "заданиям, которые нужно сделать" ("завершенные задания" в мастере это событие не влияет), таблицу просмотра
  * операций МЛ в приложении "Плановик" и таблицу "ВСЕХ заданий рабочего" в отдельно разделе, чтобы при следующем
  * входе в эти разделы обновленные данные были запрошены заново. Для текущей таблицы данные не очищаем, т.к. для
  * неё сразу выполняется перезапрос данных - reFetchCurrentTasksRemoteTableData, а, если сначала очистить, то
  * интерфейс будет"дергаться". Подробное пояснение почему данные текущей таблицы просто перезапрашиваются тоже
  * есть в упоминаемом выше комментарии.
  */
  handleSheetOperationDataChanged: (
    sheetId,
    allTasksTablesIdsWithoutCurrentTableId,
    reFetchCurrentTasksRemoteTableData,
  ) => {
    reFetchCurrentTasksRemoteTableData();

    dispatch(clearTableRemoteData([
      createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId),
      MASTER_TASKS_TO_DO_TABLE_ID,
      TASKS_MODEL,
      ...allTasksTablesIdsWithoutCurrentTableId,
    ]));
  },

  /*
  * Подробное описание о влиянии события приостановки МЛ на разделы приложения представлено в комментарии к
  * handleSheetPaused SheetInProductionReviewContentContainer. Выполняем здесь аналогичные очистки, но, т.к.
  * в этом случае завершаем МЛ из интерфейса просмотра заданий рабочего для класса РЦ в подразделении, то текущую
  * таблицу очищать не нужно, для неё сразу перезапрашиваются данные (reFetchCurrentTasksRemoteTableData)
  */
  handleSheetPaused: (
    sheetId,
    sheetIdentity,
    allTasksTablesIdsWithoutCurrentTableId,
    reFetchCurrentTasksRemoteTableData,
  ) => {

    reFetchCurrentTasksRemoteTableData();

    //Выводим нотификейшен о приостановке МЛ
    sendSheetPausedNotification(sheetIdentity);

    dispatch([
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId)),
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        ...PAUSED_SHEETS_TABLES_IDS_ARRAY,
        MASTER_TASKS_TO_DO_TABLE_ID,
        TASKS_MODEL,
        ...allTasksTablesIdsWithoutCurrentTableId,
      ]),
      deleteAssemblySheetConsumeData({ sheetId }),
      deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
      clearDefaultSheetPartsAndMaterialsToConsume(sheetId),
    ]);
  },

  /*
  * Подробное описание о влиянии события деления партии на разделы приложения представлено в комментарии к
  * handleEntityBatchSplit в SheetInProductionReviewContentContainer. Выполняем здесь аналогичные очистки и запросы,
  * но, т.к. в этом случае деление партии выполняется из интерфейса просмотра заданий рабочего для класса РЦ в
  * подразделении, которые "нужно сделать", то текущую таблицу заданий очищать не нужно, для неё сразу
  * перезапрашиваются данные (reFetchMasterTasksToDoRemoteTableData)
  * */
  handleEntityBatchSplit: ({
    parentSheetId,
    isParentEntityBatchWasFinished,
    isChildEntityBatchWasFinished,
    changedOrderId,
    sheetIdentity,
    allTasksTablesIdsWithoutCurrentTableId,
    reFetchTasksRemoteTableData,
  }) => {

    reFetchTasksRemoteTableData();

    // выводим нотификейшн о наступлении события разделения партии
    sendEntityBatchSplitNotification(sheetIdentity);

    const {
      isOneOfBatchesFinished,
      isOrderFinished,
    } = getSplitEntityBatchCaseData({
      isParentEntityBatchWasFinished,
      isChildEntityBatchWasFinished,
      changedOrderId,
    });

    const sheetOperationsTableId = createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, parentSheetId);

    const tableIdsToClear = [
      sheetOperationsTableId,
      SHEET_TYPE.IN_PRODUCTION,
      MASTER_TASKS_TO_DO_TABLE_ID,
      MASTER_COMPLETED_TASKS_TABLE_ID,
      SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      TASKS_MODEL,
      ...allTasksTablesIdsWithoutCurrentTableId,
    ];

    if(isOneOfBatchesFinished) {
      tableIdsToClear.push(SHEET_TYPE.COMPLETED);
    }

    const actionsToDispatch = [
      clearTableRemoteData(tableIdsToClear),

      ...getEntityBatchSplitCaseSpecificActions({
        isOneOfBatchesFinished,
        parentSheetId,
      }),

      ...getOrderCompletedRelatedActions(isOrderFinished, changedOrderId),
    ];

    dispatch(actionsToDispatch);
  },

  /*
  * Подробное описание о влиянии события фиксации брака на разделы приложения представлено в комментарии к
  * handleDefectiveEntitiesMarked в SheetInProductionReviewContentContainer.
  * Выполняем здесь аналогичные очистки и запросы, но, т.к. в этом случае фиксация брака выполняется из интерфейса
  * просмотра заданий рабочего для класса РЦ в подразделении, то текущую таблицу заданий очищать не нужно, для
  * неё сразу перезапрашиваются данные в reFetchCurrentTasksRemoteTableData
  */
  handleDefectiveEntitiesMarked: (
    data,
    allTasksTablesIdsWithoutCurrentTableId,
    reFetchCurrentTasksRemoteTableData,
  ) => {

    const {
      sheetOperationStatus,
      sheetId,
      completedOrderId,
      completedOrderName,
      wasEntityBatchSplit,
      wasEntityBatchFinished,
    } = data;

    const defectMarkingUseCase = getDefectMarkingUseCase(
      sheetOperationStatus,
      wasEntityBatchSplit,
      wasEntityBatchFinished,
    );

    /*
    * Список экшенов для кейсов, для которых нужна кастомная, а не универсальная обработка
    * */
    let customActionsToDispatch = [];

    if(
      defectMarkingUseCase === DEFECT_MARKING_USE_CASES.PART_OF_ENTITY_BATCH_ON_LAST_OPERATION_FINISH &&
      completedOrderId !== null
    ) {
      //Выводим нотификейшен в случае, когда завершение части МЛ означает также и готовность заказа
      sendOrderIsReadyToCompleteNotification(completedOrderName);

      customActionsToDispatch.push(
        clearTableRemoteData(ORDER_IN_PRODUCTION_AND_READY_TO_COMPLETE_TABLES_IDS),
        clearTableData(createOrderEntriesTableId(ORDER_TYPE.IN_PRODUCTION, completedOrderId)),
      );
    }

    if(
      defectMarkingUseCase === DEFECT_MARKING_USE_CASES.WHOLE_ENTITY_BATCH_ON_OPERATION_FINISH ||
      defectMarkingUseCase === DEFECT_MARKING_USE_CASES.WHOLE_ENTITY_BATCH_ON_OPERATION_PAUSE ||
      defectMarkingUseCase === DEFECT_MARKING_USE_CASES.PART_OF_ENTITY_BATCH_ON_LAST_OPERATION_FINISH
    ) {
      customActionsToDispatch.push(
        deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
      );
    }

    if(defectMarkingUseCase === DEFECT_MARKING_USE_CASES.PART_OF_ENTITY_BATCH_ON_LAST_OPERATION_FINISH) {
      customActionsToDispatch.push(
        clearAllDefaultSheetsPartsAndMaterialsToConsume(),
        deleteAllAssemblySheetsReserveData(),
        clearTableRemoteData([
          SHEET_TYPE.COMPLETED,
        ]),
      );
    }else{
      customActionsToDispatch.push(
        clearDefaultSheetPartsAndMaterialsToConsume(sheetId),
        deleteAssemblySheetReserveData({ sheetId }),
      );
    }

    /*
    * Универсальные обработки для всех кейсов, к которым добавляются кастомные customActionsToDispatch, вычисленные
    * в зависимости от текущего кейса
    * */
    reFetchCurrentTasksRemoteTableData();

    dispatch([
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId)),

      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        SHEET_TYPE.INAPPROPRIATE,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        MASTER_COMPLETED_TASKS_TABLE_ID,
        TASKS_MODEL,
        ...allTasksTablesIdsWithoutCurrentTableId,
      ]),

      deleteAssemblySheetConsumeData({ sheetId }),

      ...customActionsToDispatch,
    ]);

  },
});


const mergeProps = (stateProps, dispatchProps) => {
  const {
    departmentIdFromRoute,
    equipmentClassIdFromRoute,
    departmentTasksFilter,
    equipmentClassTasksFilter,
    tasksTableId,
    tasksTableData,
    allTasksTablesIds,
    workerTasksAdditionalFilters,
    isWorkerTasksFiltersManagingByAdmin,
  } = stateProps;

  const {
    push,
    initTasksDepartmentFilterFromRoute,
    initTasksEquipmentClassFilterFromRoute,
    fetchEquipmentClassInDepartmentTasksRemoteTableData,
    reFetchRemoteTableData,
    handleSheetFinished: handleSheetFinishedFromDispatchProps,
    handleTaskStatusChanged: handleTaskStatusChangedFromDispatchProps,
    handleSheetOperationDataChanged: handleSheetOperationDataChangedFromDispatchProps,
    handleSheetPaused: handleSheetPausedFromDispatchProps,
    handleEntityBatchSplit: handleEntityBatchSplitFromDispatchProps,
    handleDefectiveEntitiesMarked: handleDefectiveEntitiesMarkedFromDispatchProps,
    initEqClassInDepTasksTableSchema,
    clearTableRemoteData,
    saveUserSettings,
  } = dispatchProps;

  const fetchTasksRemoteTableData = ({ tableParams }) => fetchEquipmentClassInDepartmentTasksRemoteTableData(
    {
      equipmentClassIdsArray: [equipmentClassTasksFilter.id],
      departmentIdsArray: [departmentTasksFilter.id],
    },
      tableParams,
    );

  const reFetchTasksRemoteTableData = () =>
    reFetchRemoteTableData(
      tasksTableId,
      tasksTableId,
      fetchTasksRemoteTableData,
    );

  const setWorkerTasksAdditionalFilters = newAdditionalFilters => {

    return saveUserSettings({
      [USER_SETTINGS_ID.WORKER_APP_EQ_CLASS_IN_DEP_TASKS_ADDITIONAL_FILTERS]: newAdditionalFilters,
    })
      .then(reFetchTasksRemoteTableData)
      .then(() => {
        // после переключения фильтров сбрасываем кеш всех остальных таблиц заданий, данные текущей таблицы уже обновлены
        const allTasksTablesIdsWithoutCurrentTableId = allTasksTablesIds
          .filter(tableId => tableId !== tasksTableId);

        clearTableRemoteData(allTasksTablesIdsWithoutCurrentTableId);
      });
  };


  const getAllTasksTablesIdsWithoutCurrentTableId = () =>
    allTasksTablesIds.filter(tableId => tableId !== tasksTableId);


  return {
    //пропсы TasksViewScreen
    departmentTasksFilter,
    equipmentClassTasksFilter,
    tasksTableId,
    tasksTableData,
    workerTasksAdditionalFilters,
    isWorkerTasksFiltersManagingByAdmin,
    setWorkerTasksAdditionalFilters,
    fetchTasksRemoteTableData,

    handleTaskStatusChanged: ({ sheetId }) =>
      handleTaskStatusChangedFromDispatchProps(
        sheetId,
        getAllTasksTablesIdsWithoutCurrentTableId(),
        reFetchTasksRemoteTableData,
      ),

    handleSheetFinished: ({ sheetId, orderId, orderName }, isOrderCompleted) =>
      handleSheetFinishedFromDispatchProps(
        sheetId,
        getAllTasksTablesIdsWithoutCurrentTableId(),
        reFetchTasksRemoteTableData,
        orderId,
        orderName,
        isOrderCompleted,
      ),

    handleSheetOperationDataChanged: ({ sheetId }) =>
      handleSheetOperationDataChangedFromDispatchProps(
        sheetId,
        getAllTasksTablesIdsWithoutCurrentTableId(),
        reFetchTasksRemoteTableData,
      ),

    handleSheetPaused: ({ sheetId, sheetIdentity }) =>
      handleSheetPausedFromDispatchProps(
        sheetId,
        sheetIdentity,
        getAllTasksTablesIdsWithoutCurrentTableId(),
        reFetchTasksRemoteTableData,
      ),

    handleEntityBatchSplit: ({
      parentSheetId,
      isParentEntityBatchWasFinished,
      isChildEntityBatchWasFinished,
      changedOrderId,
      sheetIdentity,
    }) =>
      handleEntityBatchSplitFromDispatchProps({
        parentSheetId,
        isParentEntityBatchWasFinished,
        isChildEntityBatchWasFinished,
        changedOrderId,
        sheetIdentity,
        allTasksTablesIdsWithoutCurrentTableId: getAllTasksTablesIdsWithoutCurrentTableId(),
        reFetchTasksRemoteTableData,
      }),

    handleDefectiveEntitiesMarked: eventData =>
      handleDefectiveEntitiesMarkedFromDispatchProps(
        eventData,
        getAllTasksTablesIdsWithoutCurrentTableId(),
        reFetchTasksRemoteTableData,
      ),


    getTasksViewScreenTableRowStyle: getTaskToDoTableRowStyle,


    //Функции для asyncComponent
    fetchRequiredData: () => {

      //Схема инициализируется для модели таблицы, а не для id, но, в этом случае, они совпадают
      const initCurrentTasksTableSchema = tasksTableIdToInit => initEqClassInDepTasksTableSchema(tasksTableIdToInit);

      /*
      * Если все фильтры для заданий установлены в state, это значит, что никаких дополнительных проверок выполнять
      * не нужно, инициализируем таблицу для этих фильтров tasksTableId, которая была вычислена в mapStateToProps
      * */
      if(!!departmentTasksFilter.id && !!equipmentClassTasksFilter.id) {
        initCurrentTasksTableSchema(tasksTableId);
        return Promise.resolve();
      }
      /*
      * Если хотя бы одного фильтра нет в state, то их нужно инициализировать из параметров роута, если это возможно
      * */
      return initTasksDepartmentFilterFromRoute(departmentIdFromRoute)
        .then(
          departmentEntity =>
            Promise.all([
              departmentEntity,
              initTasksEquipmentClassFilterFromRoute(equipmentClassIdFromRoute),
            ])
            .then(
              ([ departmentEntity, equipmentClassEntity ]) => {

                /*
                * Проверки по фильтрам выполняются, когда хотя бы один из них не определен при маунте компонента. В
                * этом случае tasksTableId из mapStateToProps, который вычисляется на основании этих фильтров,
                * в этом цикле рендера будет ещё невалидным (т.к. фильтры не определены), поэтому после умпешного
                * завершения всех проверок вычисляем id таблицы, которую нужно проинициализировать, ещё раз, на
                * основании данных из успешных проверочных запросов (эти же данные устанавливаются в стор для фильтров
                * и на следующем цикле рендера в компонент уже уйдет валидный такой же идентфиикатор таблицы)
                * */
                const initializedTasksTableId = getEquipmentClassInDepartmentTasksTableId(
                  departmentEntity.id,
                  equipmentClassEntity.id,
                );
                initCurrentTasksTableSchema(initializedTasksTableId);
              },
              () => push([WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE, departmentIdFromRoute].join('/')),
            ),
          () => push(WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE),
        );
    },
  };
};

export const TasksViewScreenContainer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
  ),
  asyncComponent({
    resolve: [
      {
        fn: ({ fetchRequiredData }) => fetchRequiredData(),
      },
    ],
  }),
)(TasksViewScreen);

TasksViewScreenContainer.displayName = 'TasksViewScreenContainer';
