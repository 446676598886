import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { EqClassInDepTasksSettingsControlPanel } from './EqClassInDepTasksSettingsControlPanel/EqClassInDepTasksSettingsControlPanel';
import { EqClassInDepTasksSettingsEditForm } from './EqClassInDepTasksSettingsEditForm/EqClassInDepTasksSettingsEditForm';


import {
  FUNC_IS_REQUIRED_TYPE,
  SELECTED_WORKER_TASKS_TABLE_SETTINGS_DATA_TYPE,
  SETTINGS_SELECT_OPTION_TYPE,
} from '../../../../constants/propTypes';
import {
  WORKER_TASKS_TABLE_SETTINGS_VIEW_MODE,
  WORKER_EQ_CLASS_IN_DEP_TASKS_TABLE_SETTINGS_GROUP,
  getSettingsEntityUniqId,
  sendSettingsSavedNotification,
  getSettingsNameFromId,
} from '../../../../constants/settings';

import _mapValues from 'lodash/mapValues';
import _get from 'lodash/get';
import _pick from 'lodash/pick';

import { TASK_VIEW_SCREEN_MODEL } from '../../../../reducers/schemaModel/models/taskViewScreenSchema';
import { SETTINGS_MODEL } from '../../../../constants/models';
import { SCHEMA_MODEL_INITIAL_STATE } from '../../../../reducers/schemaModel/initialState';
import { useConfirmOnLeave } from '../../../../hoc/confirmOnLeave/confirmOnLeave';
import { PERMISSIONS_MANAGER_TYPE } from '../../../../hoc/withPermissionsManager/constants';
import { Trans } from '@lingui/macro';
import { NOTIFICATION_LEVEL, sendNonClosingNotification } from '../../../../constants/notification';
import { clearNotifications, isNotificationActive, updateNotification } from '@bfg-frontend/notification-system';
import { TASKS_TABLE_COLUMNS_DATA_KEYS_TO_SAVE } from '../constants';
import {
  CancelLabelTrans,
  DeleteLabelTrans,
  DeleteSettingsConfirmLabelTrans,
  SettingsDeletingLabelTrans,
} from '../../../../utils/commonTransComponents';
import { MATERIAL_UI_DIALOG_MAX_WIDTH } from '../../../../constants/materialUI';
import { AppConfirmContext } from '../../../AppConfirm/AppConfirmContext';
import {
  WORKER_TASKS_TABLE_NEW_SETTINGS_EQUIPMENT_CLASS_SELECT_ID,
} from './EqClassInDepTasksSettingsEditForm/NewSettingsOwnerSelects/NewSettingsOwnerSelects';


const DISABLE_REASON_NOTIFICATION_ID = 'DISABLE_REASON_NOTIFICATION_ID';

const sendValidationErrorNotification = message => sendNonClosingNotification(
  message,
  NOTIFICATION_LEVEL.ERROR,
  {
    id: DISABLE_REASON_NOTIFICATION_ID,
  },
);

export class EqClassInDepTasksSettings extends Component {
  static _getInitialStateForSelectedSettingsFromProps = props => {
    const {
      selectedSettingsId,
      selectedSettingsData: {
        tasksTableColumns,
      },
    } = props;
    return {
      /*
      * храним в локальном state дублирующую информацию об идентификаторе заселекченных настроек, т.к. это необходимо
      * для сравнения в getDerivedStateFromProps (Это рекомендуемый способ из доков реакта, т.к. в  getDerivedStateFromProps
      * осознанно нет доступа к prevProps и не будет)
      */
      selectedSettingsId,
      viewMode: WORKER_TASKS_TABLE_SETTINGS_VIEW_MODE.EDIT,
      selectedDepartment: null,
      selectedEquipmentClass: null,
      tasksTableColumns: _mapValues(
        tasksTableColumns,
        columnData => ({ ...columnData }),
      ),
    };
  };

  static propTypes = {
    isMainSettingsSelected: PropTypes.bool.isRequired,
    selectedSettingsId: PropTypes.string.isRequired,
    selectedSettingsData: SELECTED_WORKER_TASKS_TABLE_SETTINGS_DATA_TYPE,
    settingsSelectOptions: PropTypes.arrayOf(SETTINGS_SELECT_OPTION_TYPE).isRequired,
    selectSettings: FUNC_IS_REQUIRED_TYPE,
    deleteSelectedSettings: FUNC_IS_REQUIRED_TYPE,
    saveSettingsEntity: FUNC_IS_REQUIRED_TYPE,
    saveSettingsEntityAndAddToStore: FUNC_IS_REQUIRED_TYPE,
    addEntitiesToStore: FUNC_IS_REQUIRED_TYPE,
    settingsEntities: PropTypes.objectOf(SETTINGS_SELECT_OPTION_TYPE).isRequired,
    fetchEquipmentClassesInDepartment: FUNC_IS_REQUIRED_TYPE,
    PermissionsManager: PERMISSIONS_MANAGER_TYPE,
    clearAutocompleteData: FUNC_IS_REQUIRED_TYPE,
  };

  /*
  * По идее, getDerivedStateFromProps вызывается и при initial рендере и возвращает сам state, т.е. конструктор,
  * теоретически, можно было и не определять. С другой стороны, в этом случае в getDerivedStateFromProps второй аргумент
  * равен null и нужно делать дополнительные проверки. Кроме того, в общем случае, если не определить state в
  * конструкторе, то обязательно нужно предусмотреть, чтобы getDerivedStateFromProps вернул не null при initial рендере,
  * т.к. иначе state не будет инициализирован. Реокмендованный способ (в том числе для читаемости) - в конструкторе
  * инициализировать state "пустыми данными", а в getDerivedStateFormProps заполнять их
  * */
  constructor(props) {
    super(props);
    this.state = {
      selectedSettingsId: null,
      viewMode: WORKER_TASKS_TABLE_SETTINGS_VIEW_MODE.EDIT,
      selectedDepartment: null,
      selectedEquipmentClass: null,
      equipmentClassesOptionsByDepartmentId: {},
      tasksTableColumns: {},
    };
  }

  static getDerivedStateFromProps = (props, state) => {
    if (props.selectedSettingsId === state.selectedSettingsId) return null;
    return EqClassInDepTasksSettings._getInitialStateForSelectedSettingsFromProps(props);
  };

  componentDidUpdate() {
    const isDisableReasonNotificationActive = isNotificationActive(DISABLE_REASON_NOTIFICATION_ID);

    const message = this._getNewSettingsSaveDisableReasonTrans();

    if (!message && isDisableReasonNotificationActive) return clearNotifications([DISABLE_REASON_NOTIFICATION_ID]);

    if (!isDisableReasonNotificationActive) return sendValidationErrorNotification(message);

    updateNotification(
      DISABLE_REASON_NOTIFICATION_ID,
      {
        render: () => message,
      },
    );
  }

  componentWillUnmount() {
    if (isNotificationActive(DISABLE_REASON_NOTIFICATION_ID)) clearNotifications([DISABLE_REASON_NOTIFICATION_ID]);
  }

  static contextType = AppConfirmContext

  _isViewModeEqualsToCbFactory = viewMode => () => this.state.viewMode === viewMode;
  _isCreatingViewMode = this._isViewModeEqualsToCbFactory(WORKER_TASKS_TABLE_SETTINGS_VIEW_MODE.CREATE);

  _renderSettingsControlPanel = () => {
    const {
      selectedSettingsId,
      settingsSelectOptions,
      selectSettings,
      PermissionsManager,
    } = this.props;
    return (
      <EqClassInDepTasksSettingsControlPanel
          startNewSettingsCreating={this._handleStartNewSettingsCreating}
          startNewSettingsCreatingUsingSelectedAsTemplate={this._handleCreateNewSettingsUsingSelectedAsTemplate}
          selectedSettingsId={selectedSettingsId}
          settingsSelectOptions={settingsSelectOptions}
          selectSettings={({ id }) => selectSettings(id)}
          PermissionsManager={PermissionsManager}
      />
    );
  };

  _handleStartNewSettingsCreating = () =>
    this.setState({
      viewMode: WORKER_TASKS_TABLE_SETTINGS_VIEW_MODE.CREATE,
      tasksTableColumns: _mapValues(
        SCHEMA_MODEL_INITIAL_STATE[TASK_VIEW_SCREEN_MODEL].fields,
        columnData => _pick(
          columnData,
          [
            'columnName',
            'displayName',
            'order',
            'show',
          ],
        ),
      ),
    });

  _handleCreateNewSettingsUsingSelectedAsTemplate = () =>
    this.setState({
      viewMode: WORKER_TASKS_TABLE_SETTINGS_VIEW_MODE.CREATE,
    });

  _renderSelectedSettingsForm = (isColumnsSettingsChanged, isCreatingViewMode) => {
    const {
      selectedDepartment,
      selectedEquipmentClass,
      tasksTableColumns,
    } = this.state;

    const {
      isMainSettingsSelected,
      PermissionsManager,
    } = this.props;

    const isDeleteBtnDisabled = isCreatingViewMode || isMainSettingsSelected;

    return(
      <EqClassInDepTasksSettingsEditForm
          isCreating={isCreatingViewMode}
          selectedDepartment={selectedDepartment}
          selectDepartment={this._handleDepartmentSelect}
          selectedEquipmentClass={selectedEquipmentClass}
          selectEquipmentClass={this._handleEquipmentClassSelect}
          tasksTableColumnsSettings={tasksTableColumns}
          editTasksTableColumnsSettings={this._handleTasksTableColumnsSettingsEdit}
          settingsSaveDisableReasonTrans={this._getNewSettingsSaveDisableReasonTrans()}
          onSettingsEditCancel={this._handleEditingCancel}
          onSettingsSave={this._handleSaveSettings}
          isDeleteSettingsButtonDisabled={isDeleteBtnDisabled}
          deleteSelectedSettings={this._handleDeleteSettings}
          PermissionsManager={PermissionsManager}
          isColumnsSettingsChanged={isColumnsSettingsChanged}
      />
    );
  };

  _handleDepartmentSelect = departmentEntity => {
    this.props.clearAutocompleteData(WORKER_TASKS_TABLE_NEW_SETTINGS_EQUIPMENT_CLASS_SELECT_ID);
    this.setState({
      selectedDepartment: departmentEntity,
      selectedEquipmentClass: null,
    });
  };

  _isColumnsSettingsChanged = () => {
    const { selectedSettingsData } = this.props;

    const { tasksTableColumns: initialTasksTableColumnsSettings } = selectedSettingsData;

    const { tasksTableColumns: currentTableColumnsSettings } = this.state;

    /*
    Не используем _isEqual, потому что сравнить нам нужно только поля order и show. some будет работать гораздо быстрее
    */
    return Object
      .keys(initialTasksTableColumnsSettings)
      .some(columnName => {
        const {
          order: initialOrder,
          show: initialShow,
        } = initialTasksTableColumnsSettings[columnName];

        const {
          order: currentOrder,
          show: currentShow,
        } = currentTableColumnsSettings[columnName];

        return initialOrder !== currentOrder || initialShow !== currentShow;
      });
  };

  _handleEquipmentClassSelect = equipmentClassEntity =>
    this.setState({
      selectedEquipmentClass: equipmentClassEntity,
    });

  _handleTasksTableColumnsSettingsEdit = newTasksTableColumnsSettings =>
    this.setState({
      tasksTableColumns: newTasksTableColumnsSettings,
    });

  _getNewSettingsSaveDisableReasonTrans = () => {

    if(!this._isCreatingViewMode()) {
      return null;
    }

    const {
      selectedDepartment,
      selectedEquipmentClass,
    } = this.state;
    const { settingsEntities } = this.props;

    const newSettingsNameToSave = [selectedDepartment, selectedEquipmentClass]
      .filter(owner => owner !== null)
      .map(({ id }) => id)
      .join('_');

    switch(true) {
    case !selectedDepartment:
      return (
        <Trans id="worker_tasks_table_settings@department_should_be_choosen_for_settings">
          Для новых настроек необходимо выбрать хотя бы одно подразделение
        </Trans>
      );
    case !!settingsEntities[getSettingsEntityUniqId(WORKER_EQ_CLASS_IN_DEP_TASKS_TABLE_SETTINGS_GROUP, newSettingsNameToSave)]:
      return selectedEquipmentClass === null ?

        <Trans id="worker_tasks_table_settings@settings_for_department_already_exists">
          Настройки для выбранного подразделения уже существуют
        </Trans> :

        <Trans id="worker_tasks_table_settings@settings_for_equipment_class_in_department_already_exists">
          Настройки для выбранного класса РЦ в подразделения уже существуют
        </Trans>;
    default:
      return null;
    }
  };

  _handleEditingCancel = () =>
    this.setState(EqClassInDepTasksSettings._getInitialStateForSelectedSettingsFromProps(this.props));

  _handleSaveSettings = () =>
    this._isCreatingViewMode() ?
      this._saveNewSetting() :
      this._editSettings();

  _saveNewSetting = () => {
    const {
      saveSettingsEntity,
      addEntitiesToStore,
      selectSettings,
    } = this.props;
    const {
      selectedDepartment: {
        id: selectedDepartmentId,
        name: selectedDepartmentName,
        identity: selectedDepartmentIdentity,
      },
      selectedEquipmentClass,
      tasksTableColumns,
    } = this.state;

    const newSettingsName = selectedEquipmentClass ?
      [selectedDepartmentId, selectedEquipmentClass.id].join('_') :
      selectedDepartmentId;

    const newSettingsData = {
      departmentId: selectedDepartmentId,
      departmentIdentity: selectedDepartmentIdentity,
      departmentName: selectedDepartmentName,
      equipmentClassId: _get(selectedEquipmentClass, 'id', null),
      equipmentClassIdentity: _get(selectedEquipmentClass, 'identity', null),
      equipmentClassName: _get(selectedEquipmentClass, 'name', null),
      tasksTableColumns,
    };

    return saveSettingsEntity({
      group: WORKER_EQ_CLASS_IN_DEP_TASKS_TABLE_SETTINGS_GROUP,
      name: newSettingsName.toString(),
      value: {
        ...newSettingsData,
        tasksTableColumns: _mapValues(
          tasksTableColumns,
          columnData => _pick(columnData, TASKS_TABLE_COLUMNS_DATA_KEYS_TO_SAVE),
        ),
      },
    })
      /*
      Из за логики с не закрывающимися нотификейшенами об ошибках валидации тут нельзя использовать
      saveSettingsEntityAndAddToStore. Потому что после запроса сохранения в store будет добавлена новая, созданная
      настройка и будет вызван componentDidUpdate. При этом _getNewSettingsSaveDisableReasonTrans, который вызывается
      на didUpdate будет выполнена проверка на наличие новой создаваемой настройки в store и появится нотификейшн с ошибкой
      валидации. Эта проверка на дублирование настроек не будет выполнена в режиме "редактирования настройки"
      (this.state.viewMode), поэтому после запроса сначала меняем viewMode и только потом сохраняем настройку в store.
      */
      .then(settingsEntity => {
        this.setState({
          viewMode: WORKER_TASKS_TABLE_SETTINGS_VIEW_MODE.EDIT,
        });

        sendSettingsSavedNotification();

        const { id } = settingsEntity;

        addEntitiesToStore({
          [SETTINGS_MODEL]: { [id]: settingsEntity },
        });

        selectSettings(settingsEntity.id);
      });
  };

  _handleDeleteSettings = () => {

    const { deleteSelectedSettings } = this.props;

    const confirm = this.context;

    return confirm({
      confirmModalTitle: SettingsDeletingLabelTrans,
      confirmText: DeleteSettingsConfirmLabelTrans,
      confirmBtnTitle: DeleteLabelTrans,
      cancelBtnTitle: CancelLabelTrans,
      confirmDialogInnerProps: {
        dialogMaxWidth: MATERIAL_UI_DIALOG_MAX_WIDTH.XS,
      },
    })
      .then(deleteSelectedSettings);
  }

  _editSettings = () => {
    const {
      selectedSettingsId,
      selectedSettingsData,
      saveSettingsEntityAndAddToStore,
    } = this.props;

    const { tasksTableColumns } = this.state;

    return saveSettingsEntityAndAddToStore({
      group: WORKER_EQ_CLASS_IN_DEP_TASKS_TABLE_SETTINGS_GROUP,
      name: getSettingsNameFromId(selectedSettingsId),
      value: {
        ...selectedSettingsData,
        tasksTableColumns: _mapValues(
          tasksTableColumns,
          columnData => _pick(columnData, TASKS_TABLE_COLUMNS_DATA_KEYS_TO_SAVE),
        ),
      },
    })
      .then(() => {
        sendSettingsSavedNotification();
        this.setState({
          viewMode: WORKER_TASKS_TABLE_SETTINGS_VIEW_MODE.EDIT,
        });
      });
  };

  _renderUseConfirmOnLeaveComponent = isColumnsSettingsChanged =>
    <WorkerTasksTableSettingsUseConfirmOnLeaveComponent isColumnsSettingsChanged={isColumnsSettingsChanged}/>;

  render() {
    const isColumnsSettingsChanged = this._isColumnsSettingsChanged();
    const isCreatingViewMode = this._isCreatingViewMode();

    return(
      <div className="eq-class-in-dep-tasks-screen">
        {isCreatingViewMode ? null : this._renderSettingsControlPanel()}
        {this._renderSelectedSettingsForm(isColumnsSettingsChanged, isCreatingViewMode)}
        {this._renderUseConfirmOnLeaveComponent(isColumnsSettingsChanged)}
      </div>
    );
  };
}

/*
* Декоратор confirmOnLeave и хук useConformOnLeave не могут применяться напрямую к EqClassInDepTasksSettings, т.к.
* confirmOnLeave не учитывает и не может учитывать локальный стейт декорируемого компонента, т.к. декоратор создаёт
* компонента обертку над ним, ну а хук нельзя применить к компоненту, реализованному через класс. Поэтому,
* приходится создавать служебный компонент для реализации этой логики. Можно было реализовать это через любой из
* вариантов, т.к. в служебный компонент уже нужные данные передается просто в виде пропсов. Решено реализовать через
* хук, т.к. через декоратор создастся и сам служебный компонент, который не рендерит ничего, и ещё и обертка вокруг
* него для реализации конфирмации
* */
const WorkerTasksTableSettingsUseConfirmOnLeaveComponent = props => {

  useConfirmOnLeave(
    ({ isColumnsSettingsChanged }) => isColumnsSettingsChanged,
    props,
  );

  return null;
};
