import React from 'react';
import { Accordion } from '../../common/Accordion/Accordion';
import SettingsIcon from '@mui/icons-material/Settings';
import PlumpingIcon from '@mui/icons-material/Plumbing';
import { Trans } from '@lingui/macro';
import { EqClassInDepTasksSettingsContainer } from './EqClassInDepTasksTableSettings/EqClassInDepTasksSettingsContainer';
import { WORKER_APP_VIEW_MODE } from '../../WorkerApp/constants';
import './style.css';
import { TasksTableSettingsContainer } from './TasksTableSettings/TasksTableSettingsContainer';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { asyncComponent } from '../../../hoc/asyncComponent/asyncComponent';
import {
  WORKER_EQ_CLASS_IN_DEP_TASKS_TABLE_SETTINGS_GROUP,
  WORKER_TASKS_TABLE_SETTINGS_GROUP,
} from '../../../constants/settings';
import { fetchSettings } from '../../../operations/settings';

const WORKER_TASKS_TABLE_SETTINGS_ACCORDION_ITEMS = [
  {
    id: WORKER_APP_VIEW_MODE.EQ_CLASS_IN_DEP_TASKS,
    title: (
      <Trans id="worker_tasks_table_settings@eq_class_in_dep_tasks_accordion_title">
        Настройки колонок интерфейса "Задания на класс РЦ"
      </Trans>
    ),
    IconComponent: PlumpingIcon,
    ContentComponent: EqClassInDepTasksSettingsContainer,
  },
  {
    id: WORKER_APP_VIEW_MODE.TASKS,
    title: (
      <Trans id="worker_tasks_table_settings@tasks_accordion_title">
        Настройки колонок интерфейса "Все задания"
      </Trans>
    ),
    IconComponent: SettingsIcon,
    ContentComponent: TasksTableSettingsContainer,
  },
];

export const WorkerTasksTableSettingsScreen = () => (
  <div className="worker-tasks-table-columns-settings">
    <Accordion items={WORKER_TASKS_TABLE_SETTINGS_ACCORDION_ITEMS}/>
  </div>
);

const mapDispatchToProps = {
  fetchSettings,
};

export const WorkerTasksTableSettingsScreenContainer = compose(
  connect(null, mapDispatchToProps),
  asyncComponent({
    resolve: [
      {
        fn: ({ fetchSettings }) => fetchSettings([
          {
            group: WORKER_EQ_CLASS_IN_DEP_TASKS_TABLE_SETTINGS_GROUP,
          },
          {
            group: WORKER_TASKS_TABLE_SETTINGS_GROUP,
          },
        ]),
      },
    ],
  }),
)(WorkerTasksTableSettingsScreen);


