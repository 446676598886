import { DefectConfirmationDateLabelTrans, UserConfirmedDefectLabelTrans } from '../../../utils/commonTransComponents';
import moment from 'moment';
import { FORMAT_SHORT_TIME } from '../../../constants/dateFormats';

export const DEFECTIVE_SHEET_SECONDARY_SUMMARY_SCHEMA = [
  {
    key: 'entityBatchTransactionCreateStamp',
    label: DefectConfirmationDateLabelTrans,
    rowIdentity: 'current-sheet-defect-confirmation-date',
    getCustomValue: ({ entityBatchTransactionCreateStamp }) =>
      moment(entityBatchTransactionCreateStamp).format(FORMAT_SHORT_TIME),
  },
  {
    key: 'userName',
    label: UserConfirmedDefectLabelTrans,
    rowIdentity: 'current-sheet-user-confirmed-defect',
  },
];
