import { connect } from 'react-redux';

import { Autocomplete } from './Autocomplete';

import {
  setAutocompleteOptions,
  selectAutocompleteValue,
  clearAutocompleteData,
} from '../../../reducers/autocomplete/actions';
import {
  autocompleteSelectedValueSelector,
  autocompleteOptionsSelector,
} from '../../../reducers/autocomplete/selectors';

import _debounce from 'lodash/debounce';
import _isFunction from 'lodash/isFunction';
import {
  MATERIAL_UI_STYLED_REACT_SELECT_COMPONENTS,
  MATERIAL_UI_STYLED_REACT_SELECT_STYLES,
} from '../../../constants/autocomplete';
import _isEmpty from 'lodash/isEmpty';
import _mapValues from 'lodash/mapValues';


const AUTOCOMPLETE_OPTIONS_REQUEST_DELAY_IN_MS = 700;




const createAutocomplete = (options = {}) => {

  const {
    defaultComponents,
    defaultStyles,
  } = options;

  /*
* Реализуем "контролируемый", "неконтролируемый" и "частично контролируемый" автокомплит.
* 1. Для "контролируемого" автокомплита задаются пропсы value, options и onChange, setOptions.
* В этом случае служебный store и логика работы с ним использоваться не будет
* 2. Для"неконтролируемого" автокомплита НЕ задаются пропсы value, options и onChange, setOptions.
* В этом случае используется служебный store по идентфикатору автокомплита
* 3. Для "частично контролируемого" автокомплита можно задавать только часть пропсов value, options и onChange,
* setOptions, чтобы комбинировать логику работы со служебным стор и без него. Например, может быть
* полезным, когда опции для автокомплита можно вычислить на основании каких-то данных и нет смысла дублировать опции
* в автокомплитный store, НО выбранное значение хочется хранить именно в авктокомплитном store.
* ВАЖНО! Дополнительные проверки на вероятные конфликты в логике при "частично контролируемом" автокомплите не
* производятся, это отдается на откуп "пользователю" компонента с целью дать в этом вопросе максимальную гибкость, но
* нужно самостоятельно за этим следить. Например, теоретически, можно рассмотреть следующий такой "конфликт":
* Мы может не задавать options, но задать setOptions, тогда опции будут получаться только из автокомплитного store и
* заданный setOptions, по сути, должен будет дублировать логику неконтролируемого компонента. С другой стороны, может
* setOptions может выполнять ещё и некоторую дополнительную логику, что даёт больше гибкости в случае необходимости
* */
  const mapStateToProps = (state, ownProps) => {

    const {
      id,
      value,
      options,
    } = ownProps;

    return {
      value: value === undefined ? autocompleteSelectedValueSelector(state, { id }) : value,
      options: options === undefined ? autocompleteOptionsSelector(state, { id }) : options,
    };
  };


  const mapDispatchToProps = (
    dispatch,
    ownProps,
  ) => {

    const {
      id,
      onChange,
      setOptions: setOptionsFromOwnProps,
      shouldClearDataOnUnmount = false,
      onUnmount,
      loadOptions,
      loadOptionsActionCreator,
    } = ownProps;

    /*
    * Для пропсов setOptions и onChange "контролируемого" автокомплита id передается вторым аргументом в отличии от
    * экшенов "неконтролируемого" автокомплита, где он передается первым аргументом. Так удобней, потому что,
    * для "контролируемого" автокомплита id часто будет не важен, т.к. работа со store вестись не будет и он будет
    * постоянно игнорироваться. Но, на случай, если всё-же нужен, то id присылается вторым аргументом
    * */
    const setOptions = setOptionsFromOwnProps === undefined ?
      options => dispatch(setAutocompleteOptions(id, options)) :
      options => setOptionsFromOwnProps(options, id);

    return {

      onChange: onChange === undefined ?
        value => dispatch(selectAutocompleteValue(id, value)) :
        value => onChange(value, id),

      /*
      * В пропсе shouldClearDataOnUnmount нет смысла, когда работаем с "контролируемым" автокомплитом, т.к., в этом
      * случае, мы должны задать пропсами сам колбэк onUnmount в котором можно сделать всё что угодно + для
      * "контролируемого" компонента, скорее всего, контекст "очистки" будет не очень понятным. Ппоэтому
      * shouldClearDataOnUnmount используется только для "неконтролируемого" автокомплита
      * */
      onUnmount: getOnUnmountCb(onUnmount, id, shouldClearDataOnUnmount, dispatch),

      loadOptions: getLoadOptionsCb(loadOptions, setOptions, loadOptionsActionCreator, dispatch),
    };
  };

  /*
  * Хочется иметь гибкость при указании того, как опции должны загружаться. С одной стороны, хочется просто иметь
  * возможность задавать функцию - loadOptions, которая возвращает промис и ни к чему не привязываться. С другой стороны,
  * довольно часто запросы осуществляются через экшен криеторы, которые нужно биндить через dispatch, и, получается,
  * что поблизости к рендеру автокомплита всегда должен быть контейнер, что не всегда удобно. В это же время, обертка
  * автокомплита сама имеет доступ к dispatch и может прибиндить передаваемых экшнкриетор самостоятельно. Поэтому,
  * второй вариант для того, чтобы указать, как опции должны загружаться, это определение экшнкриетора
  * loadOptionsActionCreator. Эти пропсы взаимоисключающие, сначала проверяется loadOptions, если проп не задан, то
  * loadOptionsActionCreator, если он тоже не задан, значит автокомплит не будет загружать опции
  * */
  const getLoadOptionsCb = (loadOptions, setOptions, loadOptionsActionCreator, dispatch) => {
    if(!_isFunction(loadOptions) && !_isFunction(loadOptionsActionCreator)) return;

    if(_isFunction(loadOptions))
      return _debounce(
        inputValue => loadOptions(inputValue).then(setOptions),
        AUTOCOMPLETE_OPTIONS_REQUEST_DELAY_IN_MS,
      );

    return _debounce(
      inputValue => dispatch(loadOptionsActionCreator(inputValue)).then(setOptions),
      AUTOCOMPLETE_OPTIONS_REQUEST_DELAY_IN_MS,
    );
  };

  const getOnUnmountCb = (onUnmount, id, shouldClearDataOnUnmount, dispatch) => {
    if(_isFunction(onUnmount)) return () => onUnmount(id);

    if(shouldClearDataOnUnmount) return  () => dispatch(clearAutocompleteData(id));

    return undefined;
  };


  /*
  * mergeProps нужен для того, чтобы в компонент не передавались все ownProps, как это делается при дефолтном mergeProps
  * */
  const mergeProps = (stateProps, dispatchProps, ownProps) => {

    const {
      value,
      options,
    } = stateProps;

    const {
      onChange,
      onUnmount,
      loadOptions,
    } = dispatchProps;

    /*
    * Чтобы не было путаницы с пропсами, явно распаковываем и передаем дальше в компонент используемые им пропсы из
    * ownProps контейнера. Сами ownProps не распаковываем.
    * */
    const {
      getOptionValue,
      getOptionLabel,
      shouldPreloadData,
      preloadInputValue,
      onInputChange,
      isMulti,
      placeholder,
      noOptionsMessage,
      reactSelectProps,
      isClearable,
      isDisabled,
      isSearchable,
      wrapperClassName,
      components,
      styles,

      /*
      * Пропсы title, error и showError не являются частью апи реакт-селекта.
      * Реакт-селект предоставляет возможность прокидывать любые пропсы внутрь подлежащих компонентов. Для этого
      * используется специальный ключ selectProps, именно по этому ключу будет доступ к кастомным пропсам из
      * компонентов-составляющих реакт-селекта.
      * */
      title,
      error,
      showError,
    } = ownProps;

    return {
      value,
      options,
      onChange,
      onUnmount,
      loadOptions,
      shouldPreloadData,
      preloadInputValue,
      getOptionValue,
      getOptionLabel,
      onInputChange,
      isMulti,
      title,
      error,
      showError,
      placeholder,
      noOptionsMessage,
      isClearable,
      isDisabled,
      isSearchable,
      reactSelectProps,
      wrapperClassName,
      components: _getResultComponents(defaultComponents, components),
      styles: _getResultStyles(defaultStyles, styles),
    };
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
  )(Autocomplete);
};

const _getResultComponents = (defaultComponents = {}, components = {}) => {
  if(_isEmpty(defaultComponents) && _isEmpty(components))
    return undefined;

  return {
    ...defaultComponents,
    ...components,
  };
};

const _getResultStyles = (defaultStyles = {}, styles = {}) => {

  const isDefaultStylesEmpty = _isEmpty(defaultStyles);
  const isStylesEmpty = _isEmpty(styles);

  if(isDefaultStylesEmpty && isStylesEmpty)
    return undefined;

  if(isStylesEmpty)
    return defaultStyles;

  if(isDefaultStylesEmpty)
    return styles;

  return {
    ...styles,
    ..._mapValues(
      defaultStyles,
      (getStyleCb, innerComponentKey) => {
        const customGetStyleCbForKey = styles[innerComponentKey];
        if(!_isFunction(customGetStyleCbForKey)) return getStyleCb;

        return (base, props) => {
          const predefinedStylesBase = getStyleCb(base, props);
          return customGetStyleCbForKey(predefinedStylesBase, props);
        };
      },
    ),
  };
};

export const DefaultAutocompleteContainer = createAutocomplete();

export const AutocompleteContainer = createAutocomplete({
  defaultComponents: MATERIAL_UI_STYLED_REACT_SELECT_COMPONENTS,
  defaultStyles: MATERIAL_UI_STYLED_REACT_SELECT_STYLES,
});
