import React, { Component } from 'react';
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';

import { MasterTasksTable } from '../MasterTasksTable/MasterTasksTable';
import { MASTER_COMPLETED_TASKS_TABLE_ID } from './constants';
import { FUNC_IS_REQUIRED_TYPE, MASTER_TASKS_TABLE_ROW_DATA_TYPE } from '../../../constants/propTypes';
import { getSheetOperationReviewDataFromMasterTask } from '../../../utils/tasks';
import { SheetOperationReviewDialogContainer } from '../../common/SheetOperationReviewDialog/SheetOperationReviewDialogContainer';

import _isEqual from 'lodash/isEqual';
import { isAlwaysTrueCb } from '../../../constants/common';
import {
  MASTER_COMPLETED_TASKS_TABLE_MODEL,
} from '../../../reducers/schemaModel/models/masterTasksSchema';


/*
* Код, связанный с SheetOperationReviewDialogContainer и подготовка пропсов для него получился очень похожими на
* MasterTasksToDo, но пока как-то объединять не стал, т.к. до конца непонятно нужно ли это. Теоретически, всю логику с
* SheetOperationReviewDialogContainer можно было бы перенести в MasterTasksTable, а различающиеся вещи передавать
* пропсами. С другой стороны, эти компонента не сказать,Ч то прям сильно связаны. Ещё есть предположение, что хранить
* данные о выбранном задании в локальном стейте в дальнейшем будет не очень удобно Поэтому, нужно ещё будет
* поразмышлять как именно тут следует заабстрагировать и, вообще, нужно ли это
* */
export class MasterCompletedTasks extends Component {

  static propTypes = {
    departmentIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    masterCompletedTasksTableData: PropTypes.arrayOf(MASTER_TASKS_TABLE_ROW_DATA_TYPE),
    fetchMasterCompletedTasksRemoteTableData: FUNC_IS_REQUIRED_TYPE,
    reFetchMasterCompletedTasksRemoteTableData: FUNC_IS_REQUIRED_TYPE,
    clearMasterAppDepartmentsRelatedRemoteTablesData: FUNC_IS_REQUIRED_TYPE,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedTaskData: null,
    };
  }

  /*
  * Подробно об особенностях реализации таблиц мастера с "внешней" для таблицы фильтрацией по подразделениям описано
  * в комментарии к componentDidUpdate в MasterTasksToDo. Здесь всё аналогично, только, как описывалось в п.4
  * упоминаемого комментария, в этом случае очищаем очищаем все таблицы (кроме этой), которые зависят от значения фильтра
  * по подразделениям
  * */
  componentDidUpdate(prevProps) {
    if(_isEqual(prevProps.departmentIds, this.props.departmentIds)) {
      return;
    }

    const {
      clearMasterAppDepartmentsRelatedRemoteTablesData,
      reFetchMasterCompletedTasksRemoteTableData,
    } = this.props;

    clearMasterAppDepartmentsRelatedRemoteTablesData();

    reFetchMasterCompletedTasksRemoteTableData();
  }

  _handleTaskRowClick = (_, taskData) => {
    this.setState({
      selectedTaskData: getSheetOperationReviewDataFromMasterTask(taskData),
    });
  };

  _handleTaskReviewDialogClose = () => this.setState({ selectedTaskData: null });

  render() {

    const {
      masterCompletedTasksTableData,
      fetchMasterCompletedTasksRemoteTableData,
    } = this.props;
    const {
      selectedTaskData,
    } = this.state;

    return (
      <div className="master-completed-tasks">
        <MasterTasksTable
            tableId={MASTER_COMPLETED_TASKS_TABLE_ID}
            tableModel={MASTER_COMPLETED_TASKS_TABLE_MODEL}
            fetchRemoteTableData={fetchMasterCompletedTasksRemoteTableData}
            tableData={masterCompletedTasksTableData}
            noDataContent={
              <Trans id="master_completed_tasks@no_completed_tasks">
                Нет завершенных заданий
              </Trans>
            }
            onRowClick={this._handleTaskRowClick}
        />
        {
          selectedTaskData === null ?
            null :
            <SheetOperationReviewDialogContainer
                closeDialog={this._handleTaskReviewDialogClose}
                sheetOperationData={selectedTaskData}
                areStatusChangeButtonsHidden={isAlwaysTrueCb}
                isPauseSheetAdditionalActionHidden={isAlwaysTrueCb}
                isSplitEntityBatchAdditionalActionHidden={isAlwaysTrueCb}
                isAssigneesChangeDisabled={isAlwaysTrueCb}
                isEquipmentChangeDisabled={isAlwaysTrueCb}
                isNoteChangeDisabled={isAlwaysTrueCb}
                isConsumeEntitiesActionHidden={isAlwaysTrueCb}
            />
        }
      </div>
    );
  }
}