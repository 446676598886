import _get from 'lodash/get';
import {
  getQueryParamsFromQueryString as _getQueryParamsFromQueryString,
  isIdInUrlParamsValid as _isIdInUrlParamsValid,
  transformQueryParamsToQueryString as _transformQueryParamsToQueryString,
  URL_REGEX as _URL_REGEX,
} from '@bfg-frontend/url';

/*
* Довольно частым являются случаи, когда идентификаторы БД сущностей располагаются в адресной строке браузера
* (чтобы ссылка содержала указатель на данные и можно было удобно обмениваться ссылками). Т.к. адресная строка может
* редактироваться пользователями вручную, то попасть в неё может, теоретически, всё что угодно. Поэтому логика вычисления
* валидности строки, которая должная являться идентификатора вынесена в отдельную функцию. Функция проверяет, что
* идентификатор БД сущности должен быть валидным целым числом, т.к. это идентификаторы в БД. Функция обычно используется,
* перед осуществлением запроса сущности из БД, запрос не выполняется, если итак понятно, что идентификатор невалиден.
*
* По реализации:
* Регулярное выражение проверяет, что строка содержит только числа и не начинается с нуля, чтобы нельзя было
* задать 0 (т.к. таких идентификаторов в БД не бывает) или строку вида 0123 с нулями впереди
*/
export const isIdInUrlParamsValid = _isIdInUrlParamsValid;

/*
* Трансформирует объект гет параметров, заданных объектом queryParams в строку гет параметров, которая подставляется в
* ссылке запросе после '?'.
* При обработке:
*  - Декамелайзит ключи параметров, заданные в queryParams
*  - Для массивов значение ключа дублируется со всеми значениями, это используется для указания множественного параметра
*  - Есть обработка специального "блочного параметра" объекта, вида
* { column: 'name', params: [{ key: 'key1', value: 'value1' }, { key: 'key2', value: 'value2' }, ...]}, когда для
* определенного ключа нужны дополнительные связанные ключи и их параметры.
* Например:
* queryParams = {
*   userName: 'John',
*   hobby: ['soccer', 'swimming'],
*   sortBy: { column: 'age', params: [{ key: 'asc', value: true }, { key: 'ignore_nulls', value: false }] }
* }
*
* transformQueryParamsToQueryString(queryParams) =
* 'user_name=John&hobby=soccer&hobby=swimming&sort_by=age&asc=true&ignore_nulls=false
* */
export const transformQueryParamsToQueryString = _transformQueryParamsToQueryString;

export const isQueryParamWithParamsBlock = queryParamValue => !!_get(queryParamValue, 'params');

export const getQueryParamsFromQueryString = _getQueryParamsFromQueryString;

export const URL_REGEX = _URL_REGEX;
