import React from 'react';
import PropTypes from 'prop-types';

import _isNil from 'lodash/isNil';
import cn from 'classnames';

import './style.css';
import { MATERIAL_UI_LABEL_PLACEMENT, MATERIAL_UI_TOOLTIP_PLACEMENT } from '../../../constants/materialUI';
import { Tooltip } from '@mui/material';


export const SimpleInfoRow = ({
  wrapperClassName,
  labelClassName,
  label,
  valueClassName,
  value,
  emptyValue,
  delimiter = ':',
  tooltipContent = null,
  tooltipPlacement = MATERIAL_UI_LABEL_PLACEMENT.TOP,
}) => {

  const valueMarkup = (
    <span
        className={
          cn(
            'simple-info-row__value',
            valueClassName,
          )
        }
    >
      {_isNil(value) ? emptyValue : value}
    </span>
  );


  return (
    <div
        className={
          cn(
            'simple-info-row',
            wrapperClassName,
          )
        }
    >
      {
        label &&
        <span
            className={
              cn(
                'simple-info-row__label',
                labelClassName,
              )
            }
        >
        {label}
          {delimiter}
      </span>
      }
      {
        tooltipContent ?
          <Tooltip
              className="simple-info-row__value-tooltip"
              title={tooltipContent}
              placement={tooltipPlacement}
              classes={{
                popper: 'simple-info-row__tooltip-popper',
                tooltip: 'simple-info-row__tooltip',
              }}
          >
            {valueMarkup}
          </Tooltip> :
          valueMarkup
      }
    </div>
  );
};

const { string, number, node, oneOfType, oneOf } = PropTypes;

const valueType = oneOfType([string, number, node]);

SimpleInfoRow.propTypes = {
  wrapperClassName: string,
  labelClassName: string,
  label: node,
  valueClassName: string,
  value: valueType,
  emptyValue: valueType,
  delimiter: string,
  tooltipContent: node,
  tooltipPlacement: oneOf(Object.values(MATERIAL_UI_TOOLTIP_PLACEMENT)),
};
