/*
* Сокет для общесистемных сообщений
* */
export const SYSTEM_MESSAGES_SOCKET_IDENTITY = 'message';

/*
* Тип клиентских сообщений в сокет message (сообщений броадкастинга)
* */
export const IA_CLIENT_SOCKET_MESSAGE_TYPE = 'CLIENT_MESSAGE_CUSTOM_EVENTS';

/*
* Тип клиентских сообщений, отправляемых приложением cyber assistant в сокет message
* */
export const CA_CLIENT_SOCKET_MESSAGE_TYPE = 'CYBER_ASSISTANT_CLIENT_SOCKET_MESSAGE';


/*
* В клиентских соощениях в сокете в зависимости от размера пересылаемых полезных данных, эти данные могут записываться
* или в самом теле сообщения или загружаться на эту временную точку
* */
export const CLIENT_SOCKET_MESSAGE_TEMPORARY_DATA_POINT = 'temporary';

/*
* Тип события по смене основной сессии в приложении IA, по которой ведется работа
* */
export const MAIN_PLANNING_SESSION_CHANGED_EVENT_TYPE = 'ACTIVE_QRM_SESSION_CHANGED';

/*
* Тип события завершения процесса актуализации плана и состояния производства в приложении IA
* */
export const PLAN_AND_PRODUCTION_STATE_ACTUALIZATION_EVENT_TYPE = 'PLAN_AND_PRODUCTION_STATE_ACTUALIZATION';

/*
* Тип события запуска маршрутных листов в производство
* */
export const SHEETS_STARTED_EVENT_TYPE = 'SHEETS_STARTED';

/*
* Тип события начала и окончания массового запуска маршрутных листов производство
* */
export const GROUP_SHEETS_START_PROCESS_EVENT_TYPE = 'GROUP_SHEETS_START_PROCESS_EVENT_TYPE';

/*
* Тип события завершения маршрутного листа в производство
* */
export const SHEET_FINISHED_EVENT_TYPE = 'SHEET_FINISHED';

/*
* Тип события изменения статуса операции маршрутного листа и завершения всех предыдущих операций (именно так работает
* экшн точка)
* */
export const SHEET_OPERATION_STATUS_CHANGED_EVENT_TYPE = 'SHEET_OPERATION_STATUS_CHANGED';

/*
* Тип события потребления деталей и материалов со склада для комплектации стандартного МЛ
* */
export const PARTS_AND_MATERIALS_FOR_DEFAULT_SHEET_CONSUMED_EVENT_TYPE = 'PARTS_AND_MATERIALS_FOR_DEFAULT_SHEET_CONSUMED';

/*
* Тип события назначения исполнителей для операции МЛ
* */
export const SHEET_OPERATION_DATA_CHANGED_EVENT_TYPE = 'SHEET_OPERATION_DATA_CHANGED';

/*
* Тип события создания новой единицы оборудования
* */
export const EQUIPMENT_ENTITY_CREATED_EVENT_TYPE = 'EQUIPMENT_ENTITY_CREATED';

/*
* Тип события приостановки МЛ
* */
export const SHEET_PAUSED_EVENT_TYPE = 'SHEET_PAUSED';

/*
* Тип события возобновления МЛ
* */
export const SHEET_RESUMED_EVENT_TYPE = 'SHEET_RESUMED';

/*
* Тип события разделения партии
* */
export const ENTITY_BATCH_SPLIT_EVENT_TYPE = 'ENTITY_BATCH_SPLIT';

/*
* Тип события завершения заказа
* */
export const ORDER_COMPLETED_EVENT_TYPE = 'ORDER_COMPLETE';

/*
* Тип события резервирования деталей и материалов со склада под сборочный МЛ
* */
export const PARTS_AND_MATERIALS_FOR_ASSEMBLY_SHEET_RESERVED_EVENT_TYPE = 'PARTS_AND_MATERIALS_FOR_ASSEMBLY_SHEET_RESERVED';

/*
 * Тип события потребления деталей и материалов из резерва
 * */
export const PARTS_AND_MATERIALS_FOR_ASSEMBLY_SHEET_CONSUMED_EVENT_TYPE = 'PARTS_AND_MATERIALS_FOR_ASSEMBLY_SHEET_CONSUMED';


/*
 * Тип события передачи в работу сборочного МЛ (смена флага providingState)
 * */
export const ASSEMBLY_SHEET_WAS_SEND_TO_PRODUCTION_EVENT_TYPE = 'ASSEMBLY_SHEET_WAS_SEND_TO_PRODUCTION';

/*
Тип события отметки брака на операции
*/
export const DEFECTIVE_ENTITIES_MARKED_EVENT_TYPE = 'DEFECTIVE_ENTITIES_MARKED';

/*
Тип события удаления шаблона дополнительной характеристики операции МЛ
*/
export const SHEET_OPERATION_FEATURE_DELETED_EVENT_TYPE = 'SHEET_OPERATION_FEATURE_DELETED';

/*
Тип события ввода значения характеристики для операции МЛ
*/
export const SHEET_OPERATION_FEATURES_VALUES_CHANGED_EVENT_TYPE = 'SHEET_OPERATION_FEATURES_VALUES_CHANGED';

/*
* Тип события возвращения маршрутного листа в работу из несоответствующей продукции
* */
export const SHEET_INAPPROPRIATE_STATE_UNCONFIRMED_EVENT_TYPE = 'RETURN_ENTITY_BATCH_IN_PRODUCTION';

/*
* Тип события списания маршрутного листа в окончательный брак
* */
export const SHEET_FINAL_DEFECTIVE_STATE_CONFIRMED_EVENT_TYPE = 'MARK_ENTITY_BATCH_AS_DEFECTIVE';
