import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PartsAndMaterialsReserveForAssemblySheet } from './PartsAndMaterialsReserveForAssemblySheet';
import { withPermissionsManager } from '../../../../hoc/withPermissionsManager/withPermissionsManager';
import {
  fetchPartAndMaterialsReserveDataForAssemblyEntityBatch,
  reservePartsAndMaterialsForAssemblySheet,
  sendAssemblySheetToProduction,
  sendAssemblySheetWasSendToProductionNotification,
  sendPartAndMaterialsForAssemblySheetReservedNotification,
} from '../../../../operations/sheets';
import { assemblySheetReserveDataSelector } from '../../../../reducers/storageManagementApp/assemblySheets/reserveData/selectors';
import { clearTableRemoteData, reFetchRemoteTableData } from '../../../../reducers/table/actions';
import { SHEET_TYPE } from '../../../../constants/sheets';
import { MASTER_TASKS_TO_DO_TABLE_ID } from '../../../MasterApp/MasterTasksToDo/constants';
import { allTasksTablesIdsSelector } from '../../../../selectors/taskView';
import { fetchSheetTypeRemoteTableDataCbFactory } from '../../../Sheets/SheetsContainer';
import { fetchFullAssemblySheetsPartsAndMaterials } from '../AssemblySheetsWaitingPartsAndMaterials/AssemblySheetsWaitingPartsAndMaterials';
import {
  deleteAllAssemblySheetsReserveData,
} from '../../../../reducers/storageManagementApp/assemblySheets/reserveData/actions';
import { deleteAssemblySheetConsumeData } from '../../../../reducers/workerApp/assemblySheets/consumeData/actions';
import { clearAllDefaultSheetsPartsAndMaterialsToConsume } from '../../../../reducers/storageManagementApp/defaultSheets/actions';
import { TASKS_MODEL } from '../../../../reducers/schemaModel/models/tasksSchema';


export const PartsAndMaterialsReserveForAssemblySheetContainer = withPermissionsManager(props => {
  const {
    sheetToReviewData,
    PermissionsManager,
    updateSheetToReviewData,
  } = props;

  const {
    entityBatchId,
    sheetId,
    sheetIdentity,
  } = sheetToReviewData;

  const dispatch = useDispatch();

  const reserveData = useSelector(state => assemblySheetReserveDataSelector(state, { sheetId }));
  const allTasksTablesIds = useSelector(state => allTasksTablesIdsSelector(state));

  const onPartsAndMaterialsReserve = reserveDataUpdate =>
    dispatch(reservePartsAndMaterialsForAssemblySheet(
      sheetId,
      sheetIdentity,
      entityBatchId,
      reserveDataUpdate,
    ))
    .then(response => {
      handlePartsAndMaterialsForAssemblySheetReserved();
      return response;
    });

  const onSendToProduction = () => dispatch(
    sendAssemblySheetToProduction({ entityBatchId, sheetIdentity }),
  )
    .then(() => _handleAssemblySheetWasSendToProduction());


  /*
   * Влияние события "отдать в работу" на разные типы приложения и обработка этого с учетом того, что событие
   * "отдать в работу" происходит на экране Комплектование -> резервирование:
   *
   * 1. Плановик.
   * 1.1. Раздел "МЛ в производстве". При передаче МЛ в работу меняется флаг providingState, а от него зависят лейблы
   * укомплектован/не укомплектован, поэтому нажно очистить кеш таблицы "в производстве", чтобы при следующем переходе
   * на этот экран данные обновились
   * 1.2. Раздел "МЛ на запуск". Событие "отдать в работу" не влияет на этот раздел.
   * 1.3. Раздел "МЛ приостановленные". Событие "отдать в работу" не влияет на этот раздел.
   * 1.4. Раздел "МЛ завершенные".  Событие "отдать в работу" не влияет на этот раздел.
   * 1.5 Раздел "Заказы в производстве". Событие "отдать в работу" не влияет на этот раздел.
   * 1.6 Раздел "Заказы, готовые к завершению". Событие "отдать в работу" не влияет на этот раздел.
   *
   * 2. Комплектование.
   * 2.1. Раздел "стандартные". Событие "отдать в работу" не влияет на этот раздел, т.к. в работу передаются только
   * сборочные МЛ
   * 2.2 Раздел "сборочные". После передачи в работу для списка сборочных МЛ должны обновиться лейблы
   * в производстве/ДСЕ доступны/ДСЕ недоступны, поэтому перезапрашиваем данные для списка и обновляем их.
   * 2.3 Раздел "сборочные", экран резервирования. После передачи в работу кнопка "отдать в работу" должна
   * удалиться/задизейблиться, это зависит от флага providingState. Данные МЛ хранятся в локальном стейте компонента
   * EntityReviewDialog, из которого передаётся калбек пропс updateEntityReviewDataState, при вызове этого калбека в
   * EntityReviewDialog обновится локальный стейт в соответствии с новыми пропсами, которые изменяются при перезапросе
   * списка сборочных МЛ.
   *
   * 3. Мастер.
   * 3.1. Раздел "задания" -> "требуется выполнить". После передачи в работу задание становится доступно для работы
   * над ним, поэтому удаляем данные этой таблицы из store.
   * 3.2 Раздел "задания" -> "завершённые". Событие "отдать в работу" не влияет на этот раздел.
   * 3.2 Раздел "Маршрутные листы", таб "приостановленные". Событие "отдать в работу" не влияет на этот раздел.
   *
   * 4. Рабочий.
   * 4.1. Раздел "Выбора подразделений". Событие "отдать в работу" не влияет на этот раздел.
   * 4.2. Раздел "Выбора класса РЦ в подразделении". Событие "отдать в работу" не влияет на этот раздел.
   * 4.3. Раздел "Просмотра заданий для класса РЦ в подразделении". После события "отдать в работу" задание можно брать
   * в работу, пожтому очищаем кэши всех таблиц просмотра заданий, т.к. не знаем какие из них именно затронуло
   * завершение нового МЛ.При следующем входе в эти разделы будет выплонен новый запрос и данные обновятся.
   * 4.4. Раздел "Просмотра ВСЕХ заданий рабочий". После события "отдать в работу" задание можно брать
   * в работу, поэтому очищаем кэш таблицы просмотра заданий.При следующем входе в этот раздел будет выполнен новый
   * запрос и данные обновятся.
   * 4.5 Раздел "Просмотр операции МЛ". С обновлением этого экрана возникают сложности из-за хранения данных в
   * локальном стейте, сейчас для обновления данные пользователю нужно закрыть и открыть это окно. На данный момент
   * решено не выполнять обновление экрана просмотра операции после события "отдать в работу", это будет делаться
   * в дальнейшем отдельной задачей.
   * 4.6 Раздел "Просмотр операции МЛ", экран потребьления ДСЕ. Это модальное окно можно открыть только после того,
   * как МЛ был "передан в работу", поэтому обновление не требуется.
   * */
  const _handleAssemblySheetWasSendToProduction = () => {
    sendAssemblySheetWasSendToProductionNotification(sheetIdentity);

    dispatch([
      clearTableRemoteData([
        // 1.1
        SHEET_TYPE.IN_PRODUCTION,
        // 3.1
        MASTER_TASKS_TO_DO_TABLE_ID,
        //4.4
        TASKS_MODEL,
        // 4.3
        ...allTasksTablesIds,
      ]),
    ]);
    // 2.2
    dispatch(reFetchRemoteTableData(
      SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      fetchSheetTypeRemoteTableDataCbFactory(dispatch, fetchFullAssemblySheetsPartsAndMaterials),
    ));
    // 2.3
    updateSheetToReviewData();
  };

  /*
   * Влияние события резервирования на разные типы приложения и обработка этого с учетом того, что событие
   * происходит на экране резервирования:
   *
   * 1. Плановик.
   * 1.1 Раздел "МЛ в производстве". Резервирование не влияет на этот раздел.
   * 1.2 Раздел "МЛ на запуск". Резервирование не влияет на этот раздел.
   * 1.3 Раздел "МЛ приостановленные". Резервирование не влияет на этот раздел.
   * 1.4 Раздел "МЛ завершенные". Резервирование не влияет на этот раздел.
   *
   * 2. Комплектование.
   * 2.1 Раздел "стандартные". После резервирования изменяется количество ДСЕ на складе, поэтому нужно очистить данные
   * таблицы стандартных сборочных МЛ.
   * 2.2 Раздел "сборочные". После резервирования меняется количество ДСЕ на складе, а от этого зависят лейблы
   * ДСЕ доступны/недоступны, поэтому нужно перезапросить данные для списка.
   * 2.3 Раздел "Сборочные", окно резервирования. Данные таблицы нужно обновить, потому что форма в модальном окне
   * копирует все данные для таблицы из пропсов в локальный стейт и рассчитывает некоторые значения  для валидации формы.
   * Если данные не обновить, то ошибки и некоторые значения в форме будут неактуальными. Просто перезапрашиваем данные.
   * Также нужно очистить данные резервирования для всех остальных МЛ, кроме текущего, т.к. количество ДСЕ на складе
   * меняется.
   *
   * 3. Мастер
   * 3.1 Раздел просмотра заданий Мастера. Резервирование не влияет на этот раздел.
   * 3.2 Раздел "МЛ приостановленные". Резервирование не влияет на этот раздел.
   *
   * 4. Рабочий.
   * 4.1 Раздел "Выбора подразделений". Резервирование не влияет на этот раздел.
   * 4.2 Раздел "Выбора класса РЦ в подразделении". Резервирование не влияет на этот раздел.
   * 4.3 Раздел "Просмотра заданий для класса РЦ в подразделении". Резервирование не влияет на этот раздел.
   * 4.4. Раздел "Просмотра ВСЕХ заданий рабочий". Резервирование не влияет на этот раздел
   * 4.5 Раздел "Просмотр операции МЛ". Резервирование не влияет на этот раздел.
   * 4.6 Раздел "Потребление ДСЕ" рабочим. При резервировании ДСЕ меняется количество, доступных для потребления
   * ДСЕ рабочим, поэтому нужно очистить данные потребления по идентификатору МЛ, чтобы при следующем открытии окна
   * потребления данные обновились.
   * */
  const handlePartsAndMaterialsForAssemblySheetReserved = () => {
    sendPartAndMaterialsForAssemblySheetReservedNotification(sheetIdentity);

    dispatch([
      // 2.1
      clearAllDefaultSheetsPartsAndMaterialsToConsume(),
      // 2.3
      deleteAllAssemblySheetsReserveData([sheetId]),
      // 4.6
      deleteAssemblySheetConsumeData({ sheetId }),
    ]);
    // 2.2
    dispatch(reFetchRemoteTableData(
      SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      fetchSheetTypeRemoteTableDataCbFactory(dispatch, fetchFullAssemblySheetsPartsAndMaterials),
    ));
    // 2.3
    dispatch(fetchPartAndMaterialsReserveDataForAssemblyEntityBatch(entityBatchId, sheetId));
  };

  useEffect(() => {
    // явно сравниваем с undefined, потому что для пустых данных в store запишется {}
    if (reserveData !== undefined) return;
    dispatch(fetchPartAndMaterialsReserveDataForAssemblyEntityBatch(entityBatchId, sheetId));
  }, [dispatch, entityBatchId, reserveData, sheetId]);


  if (reserveData === undefined) return null;

  return (
    <PartsAndMaterialsReserveForAssemblySheet
        sheetToReviewData={sheetToReviewData}
        onPartsAndMaterialsReserve={onPartsAndMaterialsReserve}
        onSendToProduction={onSendToProduction}
        initialReserveData={reserveData}
        PermissionsManager={PermissionsManager}
    />
  );
});