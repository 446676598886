import {
  transformUrlGetParamsToTableParams,
} from '../../components/tableFactory/TableUsedUrlGetParams/transformUrlGetParamsToTableParams';
import { tableParamsSelector } from './selectors';
import _isNil from 'lodash/isNil';

export const TABLE_SET_PARAMS = 'TABLE_SET_PARAMS';
export const TABLE_CLEAR_REMOTE_DATA = 'TABLE_CLEAR_REMOTE_DATA';
export const TABLE_CLEAR_DATA = 'TABLE_CLEAR_DATA';
export const TABLE_SET_REMOTE_SUMMARY_DATA = 'TABLE_SET_REMOTE_SUMMARY_DATA';
export const TABLE_UPDATE_REMOTE_ITEM_BY_ID = 'TABLE_UPDATE_REMOTE_ITEM_BY_ID';

export const SET_SELECTED_TABLE_ROWS = 'SET_SELECTED_TABLE_ROWS';
export const RESET_SELECTED_TABLE_ROWS = 'RESET_SELECTED_TABLE_ROWS';
export const REMOVE_SELECTED_TABLE_ROWS = 'REMOVE_SELECTED_TABLE_ROWS';

/*
* Сортировка таблицы в текущей реализации привязана к модели таблицы на не к id. Подробнее о причинах в reducers/table/reducer.js,
* поэтому в экшн передается и tableId и tableModel, а обрабатывается он в редьюсерах table и schemaModel.
* tableModel на третьем месте среди параметров, т.к. предполагалось, что всё будет привязываться к tableId, а tableModel
* просто удалится, но пока было решено оставить такую логику
*
* */
export const setTableParams = (
  tableId,
  tableParams,
  tableModel,
) => ({
  type: TABLE_SET_PARAMS,
  tableId,
  tableParams,
  tableModel,
});

export const updateRemoteTableItemById = (tableId, model, itemId, itemDataToUpdate = {}) => ({
  type: TABLE_UPDATE_REMOTE_ITEM_BY_ID,
  tableId,
  model,
  itemId,
  itemDataToUpdate,
});

export const clearTableRemoteData = tableIds => ({
  type: TABLE_CLEAR_REMOTE_DATA,
  tableIds,
});

export const clearTableData = tableIdOrIdsArray => ({
  type: TABLE_CLEAR_DATA,
  tableIdOrIdsArray,
});

export const setRemoteTableSummaryData = (tableId, summaryData) => ({
  type: TABLE_SET_REMOTE_SUMMARY_DATA,
  tableId,
  summaryData,
});

export const setTableParamsFromUrlGetParams = (
  urlGetParams,
  { tableId, tableModel },
) =>
  setTableParams(
    tableId,
    transformUrlGetParamsToTableParams(urlGetParams),
    tableModel,
  );

/*
* Довольно часто требуется обновить данные удаленной таблицы для текущих параметров. Например, когда стало известно, что
* таблицу обновил какой-то другой источник. Т.е. это аналогия сброса кэша (хотя у таблиц у нас, можно сказать, кэша и
* нет, точнее будет сказать, это просто сброс\обновление текущих данных таблицы в табличном store)
* */
export const reFetchRemoteTableData = (tableId, tableModel, fetchRemoteTableData) =>
  (dispatch, getState) => {
    const {
      activePage,
      pageSize,
      sortParams,
      filterParams,
     } = tableParamsSelector(getState(), { tableId, tableModel });

    return fetchRemoteTableData({
      tableId,
      tableModel,
      tableParams: {
        sortParams,
        filterParams,
        activePage,
        pageSize,
      },
    })
    /*
    * API подразумевает, что параметр fetchRemoteTableData должен возвращать:
    * itemsIds - массив идентификаторов, определяющих ряды удаленной таблицы для текущих параметров запроса
    * (массив определяет порядок серверной сортировки, данные рядов будут собираться по itemsById)
    * itemsById - нормализованные данные, для рядов удаленной таблицы для текущих параметров запроса
    * (чтобы уменьшить количество хранимых данных, в нормализованном виде все уникальные сущности будут
    * храниться в одном экземпляре + это облегчает быстрый доступ к сущностям по id без пробегов по массивам)
    * totalItemsAmount - общее количество рядов удаленной таблицы для текущих параметров запроса (чтобы корректно
    * отображать пагинацию)
    * */
      .then(
        ({ itemsIds, itemsById, totalItemsAmount }) =>
          dispatch(setTableParams(
            tableId,
            {
              remoteData: {
                currentRemoteItemsIds: itemsIds,
                currentRemoteItemsById: itemsById,
                totalRemoteItemsAmount: totalItemsAmount,
              },
            },
            tableModel,
          )),
      );
  };

/*
* Как правило, параметры удаленных таблиц изменяются пользователем через взаимодействие с элементами управления
* таблицы (смена сортировки, переключение страницы отображения и т.д.), обработка этих взаимодействий реализована
* в табличной абстракции.
* НО, бывают случаи, когда нужно изменить параметры удаленной таблицы "программно" в ответ на какое-то другое действие
* или событие, когда пользователь не взаимодействует с элементами управления таблицы. Т.е. требуется перезапросить
* данные текущей таблицы с этими измененными параметрами и при успешном запросе записать обновленные параметры и
* новые данные для этих параметров в табличный стор. Этот action creator, как раз, может использоваться для
* таких случаев.
* Логика работы: текущие параметры заданной таблицы получаются из стора, и объединяются с обновленными параметрами,
* заданными в аргументе updatedTableParams. Т.е. в updatedTableParams задается только изменившиеся параметры, не
* требуется задавать всегда все параметры таблицы. Например, если нужно обновить только номер страницы, то в
* updatedTableParams задается только activePage, все остальные параметры для запроса будут взяты из стора текущей
* таблицы. На основании обновленных параметров выполняется перезапрос данных удаленной таблицы. После запроса в стор
* сохраняются полученные данные и только измененные параметры таблицы, потому что при обработке setTableParams это
* проработано, обновляются только те параметры, которые переданы в экшен, что, в данном случае, и требуется.
* */
export const updateRemoteTableParams = (tableId, tableModel, fetchRemoteTableData, updatedTableParams = {}) =>
  (dispatch, getState) => {
    const {
      activePage,
      pageSize,
      sortParams,
      filterParams,
    } = tableParamsSelector(getState(), { tableId, tableModel });

    const resultTableParamsAfterUpdate = {
      activePage,
      pageSize,
      filterParams,
      sortParams,
      ...updatedTableParams,
    };

    return fetchRemoteTableData({
      tableId,
      tableModel,
      tableParams: resultTableParamsAfterUpdate,
    })
      /*
      * API подразумевает, что параметр fetchRemoteTableData должен возвращать:
      * itemsIds - массив идентификаторов, определяющих ряды удаленной таблицы для текущих параметров запроса
      * (массив определяет порядок серверной сортировки, данные рядов будут собираться по itemsById)
      * itemsById - нормализованные данные, для рядов удаленной таблицы для текущих параметров запроса
      * (чтобы уменьшить количество хранимых данных, в нормализованном виде все уникальные сущности будут
      * храниться в одном экземпляре + это облегчает быстрый доступ к сущностям по id без пробегов по массивам)
      * totalItemsAmount - общее количество рядов удаленной таблицы для текущих параметров запроса (чтобы корректно
      * отображать пагинацию)
      * */
      .then(
        response => {

          const remoteData = _isNil(response) ?
            null :
            ({
              currentRemoteItemsIds: response.itemsIds,
              currentRemoteItemsById: response.itemsById,
              totalRemoteItemsAmount: response.totalItemsAmount,
            });

          dispatch(setTableParams(
            tableId,
            {
              ...updatedTableParams,
              remoteData,
            },
            tableModel,
          ));
        },
      );
  };

export const setSelectedTableRows = (tableId, data) => ({
  type: SET_SELECTED_TABLE_ROWS,
  tableId,
  data,
});

export const resetSelectedTableRows = tableId => ({
  type: RESET_SELECTED_TABLE_ROWS,
  tableId,
});

export const removeSelectedTableRows = (tableId, rowIdsToRemove) => ({
  type: REMOVE_SELECTED_TABLE_ROWS,
  tableId,
  rowIdsToRemove,
});
