import { confirmOnLeaveFactory } from '@bfg-frontend/confirm-on-leave';

import { history } from '../../reduxStore/index';


const DEFAULT_CONFIRM_MESSAGE = 'Changes you made may not be saved.';

export const {
  withConfirmOnLeave,
  useConfirmOnLeave,
} = confirmOnLeaveFactory(DEFAULT_CONFIRM_MESSAGE, history);
