import React from 'react';
import TextField from '@mui/material/TextField';
import cn from 'classnames';

import './style.css';
import { TEXT_FORM_FIELD_DEFAULT_PROPS, TEXT_FORM_FIELD_PROPS } from './constants';

export const TextFormField = props => {
  const {
    label,
    onChange,
    inputProps,
    value,
    error,
    helperText,
    onBlur,
    onFocus,
    formKey,
    variant,
    className,
    wrapperClassName,
    isDisabled,
    autoFocus,
  } = props;

  return (
    <span className={cn('text-form-field', wrapperClassName)}>
      <TextField
          InputLabelProps={{ shrink: true }}
          className={cn(
            'text-form-field__text-field',
            className,
            {
              'text-form-field__text-field--error': !!error,
            },
          )}
          id={['text-form-field__text-field', formKey].join('_')}
          variant={variant}
          label={label}
          error={!!error}
          value={value}
          onChange={e => onChange(e.target.value)}
          onBlur={onBlur}
          onFocus={onFocus}
          helperText={error ? error : helperText}
          fullWidth
          InputProps={{
            ...inputProps,
            className: 'text-form-field__text-field-input-wrap',
          }}
          disabled={isDisabled}
          autoFocus={autoFocus}
      />
    </span>
  );
};

TextFormField.propTypes = TEXT_FORM_FIELD_PROPS;

TextFormField.defaultProps = TEXT_FORM_FIELD_DEFAULT_PROPS;