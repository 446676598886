import React from 'react';

import PropTypes from 'prop-types';
import { UsersAutocomplete } from '../../../entitiesAutocomplete/UsersAutocomplete/UsersAutocomplete';
import { getUserFullNameWithNote } from '../../../../utils/stringBuilder';
import {
  NoDataLabelTrans, NotAssignedPluralLabelTrans,
} from '../../../../utils/commonTransComponents';
import { DefaultAutocompleteContainer } from '../../Autocomplete/AutocompleteContainer';
import {
  SHEET_OPERATION_ASSIGNEE_AUTOCOMPLETE_FIELDS,
  SHEET_OPERATION_ASSIGNEE_AUTOCOMPLETE_ID,
} from './constants';
import Chip from '@mui/material/Chip';
import { FUNC_IS_REQUIRED_TYPE, NUMBER_OR_STRING_TYPE } from '../../../../constants/propTypes';

import './style.css';


export const SheetOperationAssigneesSection = props => {

  const {
    isEditable,
    assignees,
    setAssignees,
  } = props;

  return (
    <div className="sheet-operation-assignees-section">
      {
        isEditable ?
          _renderSheetOperationAssigneesAutocomplete(assignees, setAssignees) :
          _renderSheetOperationAssigneesReadOnlyInfo(assignees)
      }
    </div>
  );
};

const _renderSheetOperationAssigneesAutocomplete = (assignees, setAssignees) =>
  <div className="sheet-operation-assignees_section__assignees-autocomplete">
    <UsersAutocomplete
        id={SHEET_OPERATION_ASSIGNEE_AUTOCOMPLETE_ID}
        value={assignees}
        onChange={setAssignees}
        isMulti
        fields={SHEET_OPERATION_ASSIGNEE_AUTOCOMPLETE_FIELDS}
        getOptionLabel={getUserFullNameWithNote}
        placeholder={NotAssignedPluralLabelTrans}
        noOptionsMessage={NoDataLabelTrans}
        shouldLoadOptions
        shouldPreloadData
        baseAutocompleteContainer={DefaultAutocompleteContainer}
    />
  </div>;

const _renderSheetOperationAssigneesReadOnlyInfo = assignees =>
  <div className="sheet-operation-assignees_section__assignees-read-only-info">
    {
      assignees.length > 0 ?
        assignees
          .map(userData => (
            <Chip
                key={userData.id}
                className="sheet-operation-assignees-section__assignee-chip"
                label={getUserFullNameWithNote(userData)}
            />
          )) :
        <Chip
            className="sheet-operation-assignees-section__assignee-chip"
            label={NotAssignedPluralLabelTrans}
        />
    }
  </div>;

SheetOperationAssigneesSection.propTypes = {
  isEditable: PropTypes.bool,
  assignees: PropTypes.arrayOf(
    PropTypes.shape({
      id: NUMBER_OR_STRING_TYPE.isRequired,
      name: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      patronymicName: PropTypes.string,
    }),
  ),
  setAssignees: FUNC_IS_REQUIRED_TYPE,
};
