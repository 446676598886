import React, { useCallback, useMemo, useRef } from 'react';
import { TextFormField } from '../TextFormField/TextFormField';
import { getRestInputModesValues } from './constants';
import { TextFieldToggleButtonGroup } from '../TextFieldToggleButtonGroup/TextFieldToggleButtonGroup';
import { TEXT_FORM_FIELD_DEFAULT_PROPS, TEXT_FORM_FIELD_PROPS } from '../TextFormField/constants';
import PropTypes from 'prop-types';
import { TEXT_FIELD_TOGGLE_BUTTON_GROUP_SCHEMA_ITEM_TYPE } from '../TextFieldToggleButtonGroup/constants';
import { FUNC_IS_REQUIRED_TYPE } from '../../../constants/propTypes';
import './style.css';
import _identity from 'lodash/identity';
import _get from 'lodash/get';
import cn from 'classnames';

export const TextFieldWithInputModes = props => {
  const {
    modesSchema,
    label,
    error,
    inputValueNormalizer,
    inputMode,
    onInputModeChange,
    value,
    onChange,
    wrapperClassName,
    ...restProps
  } = props;

  const inputRef = useRef(null);

  const inputLabel = useMemo(() => {

    const currentModeSchema = (modesSchema || []).find(({ value }) => inputMode === value);

    const currentModeLabel = _get(
      currentModeSchema,
      ['label'],
      '',
    );

    return (
      <span>
        {label},
        {' '}
        {currentModeLabel}
      </span>
    );
  }, [modesSchema, inputMode, label]);

  const inputModeChangeHandler = (e, newInputMode) => {
    inputRef.current.focus();

    /*
    * По умолчанию кнопки в компоненте ToggleButtonGroup можно "отжимать" - если кликнуть на активную кнопку, то она
    * станет неактивной. То есть можно сделать так, что ни одна из кнопок не будет активной и value будет равно null.
    * Для этого компонента такой функционал не нужен - какой-то из режимов ввода должен быть выбран всегда. Поэтому,
    * если value === null, то не меняем значение sheetOperationProgressMode
    */
    if (newInputMode === null) return;

    onInputModeChange(newInputMode);
  };


  const inputValueChangeHandler = useCallback(value => {
    const normalizedValue = inputValueNormalizer(value);

    onChange(normalizedValue);
  }, [onChange, inputValueNormalizer]);

  return (
    <TextFormField
        {...restProps}
        value={value}
        className="text-field-with-input-modes"
        wrapperClassName={
          cn(
            'text-field-with-input-modes__wrapper',
            wrapperClassName,
          )
        }
        label={inputLabel}
        onChange={inputValueChangeHandler}
        error={error}
        helperText={!!error ? null : getRestInputModesValues(value, inputMode, modesSchema)}
        inputProps={{
          inputRef,
          endAdornment: (
            <TextFieldToggleButtonGroup
                value={inputMode}
                onChange={inputModeChangeHandler}
                toggleButtonsSchema={modesSchema}
            />
          ),
        }}
    />
  );
};

TextFieldWithInputModes.propTypes = {
  ...TEXT_FORM_FIELD_PROPS,
  onInputModeChange: FUNC_IS_REQUIRED_TYPE,
  inputMode: PropTypes.string.isRequired,
  /*
  Пример modesSchema с процентами и штуками
   [
     {
       id: TEXT_FIELD_INPUT_MODES.PERCENT,
       label: ShortPercentLabelTrans,
       value: TEXT_FIELD_INPUT_MODES.PERCENT,
       getValueForMode: (currentInputValue, currentInputMode) => {
         return getPercentFromAmount(maxAmount, inputValue);
       },
     },
     {
       id: TEXT_FIELD_INPUT_MODES.AMOUNT,
       label: ShortAmountLabelTrans,
       value: TEXT_FIELD_INPUT_MODES.AMOUNT,
       getValueForMode: (currentInputValue, currentInputMode) => {
         return getAmountFromPercent(maxAmount, inputValue);
       },
     },
   ]
  */
  modesSchema: PropTypes.arrayOf(PropTypes.shape({
    ...TEXT_FIELD_TOGGLE_BUTTON_GROUP_SCHEMA_ITEM_TYPE,
    getValueForMode: FUNC_IS_REQUIRED_TYPE,
  })),
};

TextFieldWithInputModes.defaultProps = {
  ...TEXT_FORM_FIELD_DEFAULT_PROPS,
  inputValueNormalizer: _identity,
};