import { compose, createStore, applyMiddleware } from 'redux';

import { enableBatching } from 'redux-batched-actions';

import { createArrayMiddleware } from './middlewares/arrayMiddleware';

import { createBrowserHistory } from 'history';
import { routerMiddleware, CALL_HISTORY_METHOD } from 'connected-react-router';

import thunkMiddleware from 'redux-thunk';

import crateAppReducer from '../reducers';

import { PROD } from '../constants/environment';
import { persistStatePartsInLocalStorage } from './storeEnhancers/persistStatePartsInLocalStorage';
import { COLLAPSIBLE_INITIAL_STATE } from '../reducers/collapsible/reducer';

import { SHEET_OPERATION_REVIEW_INITIAL_STATE } from '../reducers/sheetOperationReview/reducer';


export const history = createBrowserHistory();


const appReducer = crateAppReducer(history);
/*
* Расширяем главный редьюсер для работы с экшенами роутера и для обработки массива экшенов, сгенерированного при помощи
* batchActions (см. arrayMiddleware)
* */

const finalReducer = enableBatching(appReducer);

/*
* Создаем storeEnhancer с redux-dev-tools и нужными мидлварами
* */
const composeEnhancersFunc = (process.env.NODE_ENV !== PROD && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

/*
* Для удобства, первой мидлварой является arrayMiddleWare, для возможности передачи массива любых экшенов в dispatch.
* Параметр callback нужен, чтобы мидлвара правильно отфильтровала экшены, которые можно выполнить через batchDispatch.
* Эшены роутинга должны быть обработаны своей мидлварой, т.к. для роутинга есть особая логика - экшены с определенным типом роутера,
* изменяют стор не непрямую, мидлвара роутера сначала изменяет объект history, а только потом вызывается отдельный служебный экшен
* для обновления store. Поэтому экшены роутера не должны попадать в batchDispatch.
* */
const isPlainActionForAnotherMiddleware = action => action.type === CALL_HISTORY_METHOD;
const arrayMiddleware = createArrayMiddleware(isPlainActionForAnotherMiddleware);

const PERSISTED_IN_LOCAL_STORAGE_STORE_PARTS = {
  collapsible: {
    paths: [],
    initialState: COLLAPSIBLE_INITIAL_STATE,
  },
  sheetOperationReview: {
    paths: ['progressMode'],
    initialState: SHEET_OPERATION_REVIEW_INITIAL_STATE,
  },
};

const storeEnhancer = composeEnhancersFunc(
  persistStatePartsInLocalStorage({
    localStorageKey: 'bfg-ca-redux-local-data',
    persistingSchema: PERSISTED_IN_LOCAL_STORAGE_STORE_PARTS,
  }),
  applyMiddleware(
    arrayMiddleware,
    routerMiddleware(history),
    thunkMiddleware,
  ),
);

export const store = createStore(finalReducer, storeEnhancer);
