import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { AutocompleteContainer } from '../../../Autocomplete/AutocompleteContainer';
import {
  FUNC_IS_REQUIRED_TYPE,
  NUMBER_OR_STRING_TYPE,
  OBJECT_OF_ANY_TYPE,
} from '../../../../../constants/propTypes';
import { FILTER_TYPES } from '../../../../../api/restCollectionApi';
import { FILTER_DATA_TYPE } from '../../filters.types';

import _get from 'lodash/get';
import _size from 'lodash/size';


export const AutocompleteFilterComponent = props => {
  const {
    //пропсы из компонента Filters
    filterKey,
    filterData,
    onChange,

    //cобственные пропсы этого типа фильтра, передаваемые в схему через filterComponentProps
    autocompleteId,
    loadOptionsActionCreator,
    options,
    isMulti,
    isClearable,
    shouldPreloadData,
    preloadInputValue,
    getOptionValue,
    getOptionLabel,
    placeholder,
    noOptionsMessage,
    autocompleteComponent: AutocompleteComponent,

    /*
    * Пропсы, которые нужно дополнительно передать для autocompleteComponent. Они явно выделяются от остальных
    * пропсов, описанных выше, т.к. те пропсы одинаковы для всех автокомплитов системы и, в первую очередь, для
    * дефолтного AutocompleteContainer. В этих же пропсах задаются какие-то специфичные пропсы для
    * autocompleteComponent.
    * Фактически, можно не задавать autocompleteComponent и задать additionalAutocompleteProps, и тогда эти пропсы
    * будут переданы в дефолтный AutocompleteContainer, что, скорее всего, не будет являться ожидаемым поведением, но
    * как-то отдельно это не проверяется, т.к. непонятно зачем такой кейс может возникнуть при использовании
    * */
    additionalAutocompleteProps,
  } = props;

  const autocompleteValue = _get(filterData, 'filterAdditionalData', isMulti ? [] : null);

  const handleChange = useCallback(
    value => {

      let newFilterData;


      //Вроде бы, для никаких других типов фильтров, кроме ONE_OF для мультиавтокомплита и EQUALS для
      //простого устанавливаться не может, поэтому это задается не через пропсы
      if(isMulti) {

        newFilterData = _size(value) === 0 ?
          null :
          ({
            filterType: FILTER_TYPES.ONE_OF,
            filterValue: value.map(getOptionValue),
            filterAdditionalData: value,
          });

      }else{

        newFilterData = value === null ?
          null :
          ({
            filterType: FILTER_TYPES.EQUALS,
            filterValue: getOptionValue(value),
            filterAdditionalData: value,
          });

      }

      onChange({
        [filterKey]: newFilterData,
      });
    },
    [filterKey, isMulti, onChange, getOptionValue],
  );

  return (
    <div className="autocomplete-filter-component">
      <AutocompleteComponent
          {...additionalAutocompleteProps}
          id={autocompleteId}
          loadOptionsActionCreator={loadOptionsActionCreator}
          value={autocompleteValue}
          options={options}
          isMulti={isMulti}
          shouldPreloadData={shouldPreloadData}
          preloadInputValue={preloadInputValue}
          onChange={handleChange}
          getOptionValue={getOptionValue}
          getOptionLabel={getOptionLabel}
          placeholder={placeholder}
          noOptionsMessage={noOptionsMessage}
          isClearable={isClearable}
      />
    </div>
  );
};

AutocompleteFilterComponent.defaultProps = {
  placeholder: null,
  getOptionValue: ({ id }) => id,
  isClearable: false,
  autocompleteComponent: AutocompleteContainer,
  additionalAutocompleteProps: {},
};

AutocompleteFilterComponent.propTypes = {
  filterKey: PropTypes.string.isRequired,
  filterData: FILTER_DATA_TYPE,
  onChange: FUNC_IS_REQUIRED_TYPE,

  options: PropTypes.arrayOf(OBJECT_OF_ANY_TYPE),
  autocompleteId: NUMBER_OR_STRING_TYPE.isRequired,
  loadOptionsActionCreator: PropTypes.func,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  shouldPreloadData: PropTypes.bool,
  preloadInputValue: PropTypes.string,
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  placeholder: PropTypes.node,
  noOptionsMessage: PropTypes.node,
  autocompleteComponent: PropTypes.elementType,
  additionalAutocompleteProps: OBJECT_OF_ANY_TYPE,
};
