import _mapValues from 'lodash/mapValues';
import _pick from 'lodash/pick';
import { matchPath } from 'react-router-dom';
import humps from 'humps';
import { FILTER_GROUP_TYPES, FILTER_TYPES } from '../api/restCollectionApi';
import {
  DEPARTMENT_MODEL,
  ENTITY_BATCH_MODEL,
  ENTITY_MODEL,
  EQUIPMENT_CLASS_MODEL,
  EQUIPMENT_MODEL,
  OPERATION_MODEL,
  ORDER_MODEL,
  SHEET_MODEL,
  SHEET_OPERATION_AGGREGATED_MODEL,
  SHEET_OPERATION_ASSIGNEE_MODEL,
  SHEET_OPERATION_MODEL,
  USER_MODEL,
} from '../constants/models';
import { WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE, WORKER_APP_TASKS_ROUTE } from '../constants/routes';
import _get from 'lodash/get';
import _size from 'lodash/size';
import _keyBy from 'lodash/keyBy';
import {
  appUserIdSelector,
  tasksAdditionalFiltersSelector,
  tasksViewScreenAdditionalFiltersSelector,
} from '../reducers/appState/selectors';
import { routerPathnameSelector } from '../reducers/router/selectors';
import { TASKS_MODEL } from '../reducers/schemaModel/models/tasksSchema';
import { schemaForModelSelector } from '../reducers/schemaModel/selectors';
import { updateRemoteTableParams } from '../reducers/table/actions';
import { currentEqClassInDepTasksTableSchemaSelector } from '../selectors/taskView';
import { createNewSchemaModel, updateSchemaModelsData } from '../reducers/schemaModel/actions';
import { fetchDataFromServerDataPoint } from '../api/index';
import { SERVER_DATA_POINT } from '../constants/serverDataPoints';
import { ENTITIES_TRANSFORMERS_BY_MODEL_MAP, transformEntitiesToState } from '../transformers/entities';
import { WORKER_TASKS_TABLE_ADDITIONAL_FILTER_IDENTITY } from '../components/WorkerApp/TasksViewScreen/constants';
import { workerTasksAdminFiltersSettingsSelector } from '../selectors/settings';
import {
  TASKS_FILTER_SCHEMA_FILTER_KEYS,
} from '../components/Table/tableComponents/TasksTableFilters/TasksTableFilters';
import _isNil from 'lodash/isNil';

import { fetchEntitiesFromServer } from '../reducers/entities/actions';

import { currentTasksTableSchemaSelector } from '../selectors/tasks';
import _isEqual from 'lodash/isEqual';
import { SCHEMA_MODEL_INITIAL_STATE } from '../reducers/schemaModel/initialState';


const SHEET_TASKS_DATA_POINT_MODELS = [
  ENTITY_MODEL,
  ENTITY_BATCH_MODEL,
  SHEET_MODEL,
  SHEET_OPERATION_ASSIGNEE_MODEL,
  DEPARTMENT_MODEL,
  EQUIPMENT_CLASS_MODEL,
  EQUIPMENT_MODEL,
  OPERATION_MODEL,
  ORDER_MODEL,
  USER_MODEL,
];

/*
 * Функция getWorkerTasksAdditionalFilters определяет, нужно ли передавать дополнительные фильтры "Я исполнитель" и
 * "Только доступные задачи" в запрос заданий и возвращает значения этих фильтров. Эти фильтры доступны только в
 * разделе рабочего.
 *
 * Выбранные фильтры и условия того, включать их в запрос или нет определены тут, потому что у экшена
 * fetchEquipmentClassInDepartmentTasksRemoteTableData довольно много использований, в том числе при броадкастинге.
 *
 * Если передавать значения фильтров в параметрах, то придётся вносить изменения во всех обработчиках броадкастинга и
 * в каждом из них определять, находимся мы в разделе рабочего или нет. А если в будущем данные фильтры добавятся,
 * напримерв раздел Мастера, то придётся проделывать всё это снова.
 *
 * Чтобы избежать лишних трудозатрат определяем нужно ли передавать в запрос фильтры прямо в экшене запроса. Если
 * фильтры когда-то будут добавлены в другой раздел, то изменения нужно будет внести только в одном месте.
 */
const getWorkerTasksAdditionalFilters = state => {
  const currentPathname = routerPathnameSelector(state);

  const tasksForEquipmentClassInDepartmentRouteMatch = matchPath(currentPathname, {
    path: `${WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE}/:departmentId/:equipmentClassId`,
  });

  const tasksRouteMatch = matchPath(currentPathname, {
    path: WORKER_APP_TASKS_ROUTE,
  });

  // Если находимся в разделе "Рабочий. Просмотр задания для класса РЦ в подразделении" или в разделе
  // "Рабочий. Все задания"
  if(tasksForEquipmentClassInDepartmentRouteMatch !== null || tasksRouteMatch !== null) {
    const workerTasksAdditionalFiltersSelector = tasksRouteMatch !== null ? tasksAdditionalFiltersSelector :
      tasksViewScreenAdditionalFiltersSelector;

    const {
      tasksFilters: adminTasksFilters,
      isWorkerTasksFiltersManagingByAdmin,
    } = workerTasksAdminFiltersSettingsSelector(state);

    const workerAdditionalTasksFilters = isWorkerTasksFiltersManagingByAdmin ?
      adminTasksFilters :
      workerTasksAdditionalFiltersSelector(state);

    const isStartReadyTasksFilterActive = workerAdditionalTasksFilters.includes(WORKER_TASKS_TABLE_ADDITIONAL_FILTER_IDENTITY.START_READY_TASKS);
    const isCurrentUserTasksFilterActive = workerAdditionalTasksFilters.includes(WORKER_TASKS_TABLE_ADDITIONAL_FILTER_IDENTITY.CURRENT_USER_TASKS);

    return {
      isStartReadyTasksFilterActive,
      isCurrentUserTasksFilterActive,
    };
  }

  return {
    isStartReadyTasksFilterActive: false,
    isCurrentUserTasksFilterActive: false,
  };
};

/*
* Общая логика запроса заданий, которые ещё нужно сделать, со специальной DATA точки SERVER_DATA_POINT.TASKS_TO_DO,
* и обработка этих данных для дальнейшей работы с ними на клиенте.
*
* В логику DATA точки заложено, что ко всем заданным фильтрам добавляется ещё фильтры того, что это задания МЛ, которые
* именно относятся к активным МЛ партий в производстве (т.е. не для приостановленных партий или завершенных) и
* которые ещё "нужно сделать" (т.е., завершенные задания тоже выфильтровываются, при этом приостановленные попадают
* в выборку, т.к. их ещё нужно сделать и можно их вернуть в работу без каких-либо ограничений)
* */
export const fetchTasksToDoFromDataPoint = (query, requestOptions) =>
  async(dispatch, getState) => {
    const { with: withParams } = query;

    const decamelizedWithParams = _size(withParams) ?
      withParams.map(model => humps.decamelize(model)) :
      undefined;

    const queryParams = decamelizedWithParams ?
    {
      ...query,
      with: decamelizedWithParams,
    } : query;

    const response = await dispatch(fetchDataFromServerDataPoint(
      SERVER_DATA_POINT.TASKS_TO_DO,
      queryParams,
      requestOptions,
    ));

    const {
      // в data находятся поля модели SHEET_OPERATION_MODEL и SHEET_OPERATION_AGGREGATED_MODEL
      data,
      [OPERATION_MODEL]: operationEntitiesArray,
    } = response;

    // формируем массив идентификаторов модели SHEET_OPERATION_MODEL
    const entityRouteSheetOperationItemsIds = data.map(({ id }) => id);

    // формируем массив идентификаторов модели SHEET_OPERATION_AGGREGATED_MODEL, они соответствуют идентификаторам
    // модели SHEET_OPERATION_MODEL и все находятся в поле data
    const sheetOperationAggregatedItemsIds = entityRouteSheetOperationItemsIds;

    // формируем объект itemsIds всех остальных моделей (entity, entityBatch, entityRouteSheet, operation, order, user,
    // entityRouteSheetOperationExecutor)
    const allOtherModelsItemsIds = _mapValues(
      _pick(response, SHEET_TASKS_DATA_POINT_MODELS),
      modelEntityArray => modelEntityArray.map(({ id }) => id),
    );

    const itemsIds = {
      ...allOtherModelsItemsIds,
      [SHEET_OPERATION_AGGREGATED_MODEL]: sheetOperationAggregatedItemsIds,
      [SHEET_OPERATION_MODEL]: entityRouteSheetOperationItemsIds,
    };

    const state = getState();

    // формируем объект itemsById модели SHEET_OPERATION_MODEL, все поля этой модели находятся в data
    const entityRouteSheetOperationItemsById = _keyBy(transformEntitiesToState(data, SHEET_OPERATION_MODEL, state), 'id');

    // формируем объект itemsById модели SHEET_OPERATION_AGGREGATED_MODEL
    const sheetOperationAggregatedItemsById = data
      // удаляем из data лишние поля, оставляем только поля модели  SHEET_OPERATION_AGGREGATED_MODEL
      .map(item => _pick(item, ['id', 'first', 'last', 'lastAssembly', 'previousFinished']))
      .reduce((acc, item) => {
        const { id } = item;

        // добавляем к модели SHEET_OPERATION_AGGREGATED_MODEL поле isAssembly из модели OPERATION_MODEL
        const operationEntity = operationEntitiesArray.find(({ id: operationId }) => id === operationId);

        acc[id] = {
          ...item,
          isAssembly: _get(operationEntity, ['isAssembly'], false),
        };

        return acc;
      }, {});

    // если в ответе есть модели, для которых ENTITIES_TRANSFORMERS_BY_MODEL_MAP, то выполняем трансформацию этих моделей,
    // если трансформера нет, то записываем в transformedResponseEntitiesById модель из ответа
    const transformedResponseEntitiesById = Object
      .keys(_pick(response, SHEET_TASKS_DATA_POINT_MODELS))
      .reduce((acc, model) => {
        const entitiesTransformer = ENTITIES_TRANSFORMERS_BY_MODEL_MAP[model];

        acc[model] = entitiesTransformer ? transformEntitiesToState(response[model], model, state) : response[model];

        return acc;
      }, {});

    // формируем объект itemsById всех остальных моделей (entity, entityBatch, entityRouteSheet, operation, order, user,
    // entityRouteSheetOperationExecutor)
    const allOtherModelsItemsById = _mapValues(
      transformedResponseEntitiesById,
      modelEntitiesArray => _keyBy(modelEntitiesArray, 'id'),
    );

    const itemsById = {
      ...allOtherModelsItemsById,
      [SHEET_OPERATION_AGGREGATED_MODEL]: sheetOperationAggregatedItemsById,
      [SHEET_OPERATION_MODEL]: entityRouteSheetOperationItemsById,
    };

    return {
      itemsIds,
      itemsById,
      totalItemsAmount: _get(response, ['meta', 'count'], 0),
    };
  };

const FETCH_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_WITH_PARAMS = [
  ENTITY_MODEL,
  ENTITY_BATCH_MODEL,
  SHEET_MODEL,
  SHEET_OPERATION_ASSIGNEE_MODEL,
  EQUIPMENT_MODEL,
  OPERATION_MODEL,
  ORDER_MODEL,
  USER_MODEL,
];

export const fetchEquipmentClassInDepartmentTasksRemoteTableData = (params, tableParams, requestOptions) =>
  async(dispatch, getState) => {
    const {
      equipmentClassIdsArray = [],
      departmentIdsArray = [],
    } = params;

    const {
      activePage,
      pageSize,
    } = tableParams;

    const state = getState();
    const currentUserId = appUserIdSelector(state);

    // подробный комментарий написан к getWorkerTasksAdditionalFilters
    const {
      isStartReadyTasksFilterActive,
      isCurrentUserTasksFilterActive,
    } = getWorkerTasksAdditionalFilters(state);

    const query = {
      executorId: isCurrentUserTasksFilterActive ? [currentUserId] : [],
      equipmentClassId: equipmentClassIdsArray,
      departmentId: departmentIdsArray,
      executableOnly: isStartReadyTasksFilterActive,
      start: (activePage - 1) * pageSize,
      stop: activePage * pageSize,
      with: FETCH_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_WITH_PARAMS,
    };

    return dispatch(fetchTasksToDoFromDataPoint(query, requestOptions));
  };

const FETCH_TASKS_REMOTE_TABLE_DATA_WITH_PARAMS = [
  DEPARTMENT_MODEL,
  ENTITY_MODEL,
  ENTITY_BATCH_MODEL,
  SHEET_MODEL,
  SHEET_OPERATION_ASSIGNEE_MODEL,
  EQUIPMENT_MODEL,
  EQUIPMENT_CLASS_MODEL,
  OPERATION_MODEL,
  ORDER_MODEL,
  USER_MODEL,
];

export const fetchTasksRemoteTableData = (tableParams, requestOptions) =>
  async(dispatch, getState) => {
    const {
      filterParams = {},
      activePage,
      pageSize,
    } = tableParams;

    const state = getState();
    const currentUserId = appUserIdSelector(state);

    const {
      isStartReadyTasksFilterActive,
      isCurrentUserTasksFilterActive,
    } = getWorkerTasksAdditionalFilters(state);

    const query = {
      ...getTasksRequestFiltersFromFilterParams(filterParams),
      executableOnly: isStartReadyTasksFilterActive,
      start: (activePage - 1) * pageSize,
      stop: activePage * pageSize,
      with: FETCH_TASKS_REMOTE_TABLE_DATA_WITH_PARAMS,
    };

    if(isCurrentUserTasksFilterActive) {
      query.executorId = [currentUserId];
    }

    return dispatch(fetchTasksToDoFromDataPoint(query, requestOptions));
  };

const {
  ENTITY,
  EQ_CLASS_IN_DEP,
  EQUIPMENT,
  OPERATION,
  ORDER,
  SHEET,
  USER,
} = TASKS_FILTER_SCHEMA_FILTER_KEYS;

const TASKS_TABLE_FILTERS_SERVER_KEYS_MAP = {
  [ENTITY]: 'entityId',
  [ORDER]: 'orderId',
  [EQUIPMENT]: 'equipmentId',
  [OPERATION]: 'operationName',
  [SHEET]: 'entityRouteSheetId',
  [USER]: 'executorId',
  [EQ_CLASS_IN_DEP]: 'departmentEquipmentClassId',
};

const parseEquipmentClassesInDepartmentsAutocompleteOptionId = id => {
  const [equipmentClassId, departmentId] = id.split('/');

  return { equipmentClassId, departmentId };
};

export const getTasksRequestFiltersFromFilterParams = filterParams => Object
  .keys(filterParams)
  .reduce(
    (acc, key) => {
      const serverFilterKey = TASKS_TABLE_FILTERS_SERVER_KEYS_MAP[key];
      if(!serverFilterKey) {
        return acc;
      }

      const filterValue = _get(filterParams, [key, 'filterValue']);
      if(_isNil(filterValue)) {
        return acc;
      }

      /*
      * отдельно обрабатываем ключ фильтра класса РЦ в подразделении, т.к. для него необходима частная логика преобразования
      * */
      if(key === TASKS_FILTER_SCHEMA_FILTER_KEYS.EQ_CLASS_IN_DEP) {
        acc[serverFilterKey] = filterValue
          .map(equipmentClassInDepartmentCombinedId => {
            const {
              departmentId,
              equipmentClassId,
            } = parseEquipmentClassesInDepartmentsAutocompleteOptionId(equipmentClassInDepartmentCombinedId);

            return `["${departmentId}", "${equipmentClassId}"]`;
          });
        return acc;
      }

      acc[serverFilterKey] = filterValue;
      return acc;
    },
    {},
  );


export const initEqClassInDepTasksTableSchema = tableModel =>
  (dispatch, getState) => {
    const state = getState();

    const isTaskTableSchemaExist = !!schemaForModelSelector(state, { model: tableModel });

    if(isTaskTableSchemaExist) return;

    const currentTasksTableSchema = currentEqClassInDepTasksTableSchemaSelector(state);

    return dispatch(createNewSchemaModel(tableModel, currentTasksTableSchema));
  };

export const initTasksTableSchema = () =>
  (dispatch, getState) => {
    const currentTasksTableSchema = currentTasksTableSchemaSelector(getState());

    if (_isEqual(SCHEMA_MODEL_INITIAL_STATE[TASKS_MODEL], currentTasksTableSchema)) return;

    return dispatch(updateSchemaModelsData({ [TASKS_MODEL]: currentTasksTableSchema }));
  };

export const fetchSheetOperationsAggregatedData = sheetOperationIds =>
  dispatch => {
    if(_size(sheetOperationIds) === 0) {
      return Promise.resolve({});
    }

    return dispatch(fetchDataFromServerDataPoint(
      SERVER_DATA_POINT.SHEET_OPERATIONS_AGGREGATED_DATA,
      {
        id: sheetOperationIds,
      },
    ))
      .then(response => {
        const responseData = _get(response, ['data'], []);

        return _keyBy(responseData, 'id');
      });
  };

const ENTITY_BATCH_RESERVE_STATE_REQUEST_OPTIONS = {
  showServerError: false,
  isBlockingRequest: true,
};

const ENTITY_BATCH_SPECIFICATION_DOES_NOT_EXIST_ERROR = 'ENTITY_BATCH_SPECIFICATION_DOES_NOT_EXIST';

export const fetchEntityBatchReserveState = entityBatchId =>
  dispatch => dispatch(fetchDataFromServerDataPoint(
    SERVER_DATA_POINT.ENTITY_BATCH_RESERVE_STATE,
    { entityBatchId },
    ENTITY_BATCH_RESERVE_STATE_REQUEST_OPTIONS,
  ))
    .then(({ data }) => {
      const { allEntitiesWereReserved, allReserveWasConsumed } = data;

      /*
      * Особенный кейс:
      * В сценариях работы, теоретически, в CA может оказаться партия с флагом "сборочности", при этом у неё в
      * спецификации есть только покупные ДСЕ, которые мы в данный момент не резервируем. Они считаются сразу
      * укомплектованными. Ранее на укомплектованность был фильтр в точке, но из-за него выдавалась общая ошибка
      * "Партии не существует", что невозможно было однозначно интерпретировать. Кроме того, в catch описан ещё один
      * специальный кейс, когда у партии может не быть спецификации, что тоже надо различать, поэтому этот фильтр на
      * укомплектованность точно был лишним.
      * В описываемом кейсе, т.к. партия, на самом деле, считается укомплектованной и у неё в спецификации только
      * покупные в ответ на проверку приходит allEntitiesWereReserved: null и allReserveWasConsumed: null. Если не
      * обработать этот момент отдельно, то это будет равносильно тому, что ничего не зарезервировано и не потреблено, а
      * это некорректно, поэтому обрабатываем чтобы возвращалось, что всё зарезервировано и потреблено
      * */
      if(allEntitiesWereReserved === null && allReserveWasConsumed === null) {
        return { allEntitiesWereReserved: true, allReserveWasConsumed: true };
      }

      return { allEntitiesWereReserved, allReserveWasConsumed };
    })
    .catch(error => {
      const errorIdentity = _get(error, ['response', 'identity']);

      /*
      * Особенный кейс:
      * В сценариях работы, теоретически, в CA может оказаться партия с флагом "сборочности", при этом у неё нет
      * спецификации, т.к. сейчас нигде эти вещи напрямую не связаны, "сборочность" определяется только по наличию в
      * технологии "сборочной" операции, а задать при вводе данных такую операцию можно для любого ДСЕ, в том числе, у
      * которого нет спецификации. В логике точки проверки состояния резерва, получается, что сравнивать резерв не
      * с чем, т.к. нет спецификации и точка выдает ошибку. С точки зрения клиенткого приложения эта ошибка означает,
      * как раз, то, что партия сборочная и у неё нет спецификации, а значит, что и резервировать \ потреблять под неё
      * ничего не надо, поэтому в такому случае уже можно считать, что всё зарезервировано и потреблено. Если не добавить
      * эту проверку и не вернуть зарезолвленный промис, то получится, что завершить сборочную операцию такой партии
      * будет невозможно, т.к. промис просто бы реджектился и дальнейшая обработка не производилась.
      * */
      if(errorIdentity === ENTITY_BATCH_SPECIFICATION_DOES_NOT_EXIST_ERROR) {
        return { allEntitiesWereReserved: true, allReserveWasConsumed: true };
      }

      return Promise.reject(error);
    });

export const updateFiltersWithScannedSheet = (
  {
    sheetIdentity,
    signal,
  },
) =>
  dispatch => {

    const query = {
      filter: {
        filterGroupType: FILTER_GROUP_TYPES.AND,
        filters: [
          {
            column: 'identity',
            filterType: FILTER_TYPES.EQUALS,
            filterValue: sheetIdentity,
          },
        ],
      },
    };

    return dispatch(fetchEntitiesFromServer(
      SHEET_MODEL,
      query,
      {
        isBlockingRequest: false,
        signal,
      },
    ))
      .then(response => {
        const {
          responseEntitiesIds,
          entities,
          responseMeta: {
            count,
          },
        } = response;

        if (count === 0) {
          return Promise.reject();
        }

        const sheetId = responseEntitiesIds[SHEET_MODEL][0];
        const sheetEntity = entities[SHEET_MODEL][sheetId];

        const updatedTableParams = {
          filterParams: {
            [TASKS_FILTER_SCHEMA_FILTER_KEYS.SHEET]: {
              filterAdditionalData: [sheetEntity],
              filterType: FILTER_TYPES.ONE_OF,
              filterValue: [sheetId],
            },
          },
        };

        return dispatch(updateRemoteTableParams(
          TASKS_MODEL,
          TASKS_MODEL,
          ({ tableParams }) => dispatch(
            fetchTasksRemoteTableData(
              tableParams,
              { isBlockingRequest: false, signal },
            ),
          ),
          updatedTableParams,
        ));
      });
  };
