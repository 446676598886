import React, { useCallback, useState } from 'react';
import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import './style.css';
import { Icon } from '../Icon/Icon';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

export const Accordion = props => {
  const {
    items,
  } = props;


  const [isOpenById, setIsOpenById] = useState({});

  const changeHandler = useCallback((id, isOpen) => {
    setIsOpenById(prevState => ({
      ...prevState,
      [id]: isOpen,
    }));
  }, [setIsOpenById]);



  return (
    <div className="accordion">
      {
        items.map(({
          id,
          title,
          IconComponent,
          ContentComponent,
        }) => {

          const isItemOpen = _get(isOpenById, [id], false);

          const summaryWrapperClassName = cn('accordion__summary-wrapper', {
            'accordion__summary-wrapper--open': isItemOpen,
          });

          const expandIconClassName = cn('accordion__expand-icon', {
            'accordion__expand-icon--open': isItemOpen,
          });

          return (
            <MuiAccordion
                key={id}
                disableGutters
                elevation={0}
                expanded={isItemOpen}
                onChange={(_, isExpanded) => changeHandler(id, isExpanded)}
                className="accordion__item"

                /*
                * По умолчанию, аккордеон рендерит все компоненты, независимо от того есть они на экране или нет.
                * В документации mui указывается, что это для серверного рендеринга и SEO, что логично.
                * У нас в проектах такое пока не используется, поэтому, можно оптимизировать отрисовку. Пока установлено
                * явно отключение, но, если понадобится кастомизации, можно будет вынести в пропсы, например,
                * сделать muiAccordionProps, чтобы и уметь задавать какие-то дополнительные пропсы и переопределять
                * дефолтные
                * */
                TransitionProps={{ unmountOnExit: true }}
            >

              <AccordionSummary
                  id={id}
                  className="accordion__summary"
              >
                <div className={summaryWrapperClassName}>
                  <Icon>
                    <IconComponent />
                  </Icon>
                  <Typography noWrap className="accordion__summary-text">
                    {title}
                  </Typography>
                  <ExpandMoreIcon className={expandIconClassName} />
                </div>
              </AccordionSummary>

              <AccordionDetails className="accordion__details">
                <ContentComponent />
              </AccordionDetails>
            </MuiAccordion>
          );
        })
      }
    </div>
  );
};

Accordion.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    IconComponent: PropTypes.elementType.isRequired,
    ContentComponent: PropTypes.elementType.isRequired,
    title: PropTypes.node.isRequired,
    id: PropTypes.string.isRequired,
  })),
};