import { Trans } from '@lingui/macro';
import React from 'react';
import { COLUMN_TYPE } from '../../../constants/table';
import { initializeTableModelFields } from '../initializeTableModelFields';

export const TASK_VIEW_SCREEN_MODEL = 'taskViewScreen';

export const TASK_VIEW_SCREEN_SCHEMA_FIELDS = [
  {
    columnName: 'sheetIdentity',
    displayName: (
      <Trans id="worker_tasks.table_column@sheet_identity">
        ИД МЛ партии
      </Trans>
    ),
    type: COLUMN_TYPE.STRING,
  },
  {
    columnName: 'orderName',
    displayName: (
      <Trans id="worker_tasks.table_column@order_name">
        Заказ
      </Trans>
    ),
    type: COLUMN_TYPE.STRING,
    customComponent: 'OrderNameRenderer',
  },
  {
    columnName: 'entityIdentity',
    type: COLUMN_TYPE.STRING,
    displayName: (
      <Trans id="worker_tasks.table_column@entity_identity">
        ИД ДСЕ
      </Trans>
    ),
    show: false,
  },
  {
    columnName: 'entityCode',
    type: COLUMN_TYPE.STRING,
    displayName: (
      <Trans id="worker_tasks.table_column@entity_code">
        Шифр ДСЕ
      </Trans>
    ),
  },
  {
    columnName: 'entityName',
    type: COLUMN_TYPE.STRING,
    displayName: (
      <Trans id="worker_tasks.table_column@entity_name">
        ДСЕ
      </Trans>
    ),
  },
  {
    columnName: 'entitiesInBatchAmount',
    type: COLUMN_TYPE.NUMBER,
    displayName: (
      <Trans id="worker_tasks.table_column@entities_in_batch_amount">
        Размер партии
      </Trans>
    ),
  },
  {
    columnName: 'operationIdentity',
    type: COLUMN_TYPE.STRING,
    displayName: (
      <Trans id="worker_tasks.table_column@operation_identity">
        ИД Операции
      </Trans>
    ),
    show: false,
  },
  {
    columnName: 'operationNumber',
    type: COLUMN_TYPE.STRING,
    displayName: (
      <Trans id="worker_tasks.table_column@operation_number">
        № Операции
      </Trans>
    ),
  },
  {
    columnName: 'operationName',
    type: COLUMN_TYPE.STRING,
    displayName: (
      <Trans id="worker_tasks.table_column@operation_name">
        Операция
      </Trans>
    ),
  },
  {
    columnName: 'operationProdTime',
    type: COLUMN_TYPE.NUMBER,
    displayName: (
      <Trans id="worker_tasks.table_column@operation_prod_time">
        Тшт., ч.
      </Trans>
    ),
    customComponent: 'HumanizedTimeFromHoursRenderer',
    // пояснение см. в masterTasksSchema.js
    disableFilter: true,
  },
  {
    columnName: 'progress',
    displayName: (
      <Trans id="worker_tasks.table_column@progress">
        Прогресс операции
      </Trans>
    ),
    type: COLUMN_TYPE.NUMBER,
    customComponent: 'SheetOperationProgressRenderer',
    disableFilter: true,
  },
  {
    columnName: 'operationLabor',
    type: COLUMN_TYPE.NUMBER,
    displayName: (
      <Trans id="worker_tasks.table_column@operation_labor">
        Трудоемкость операции, ч.
      </Trans>
    ),
    customComponent: 'OperationLaborRenderer',
    disableFilter: true,
  },
  {
    columnName: 'assignees',
    displayName: (
      <Trans id="worker_tasks.table_column@assignees">
        Исполнители
      </Trans>
    ),
    type: COLUMN_TYPE.STRING,
    disableFilter: true,
    customComponent: 'AssigneesRenderer',
  },
  {
    columnName: 'equipment',
    displayName: (
      <Trans id="worker_tasks.table_column@equipment">
        Оборудование
      </Trans>
    ),
    type: COLUMN_TYPE.STRING,
    disableFilter: true,
    customComponent: 'WorkerTasksEquipmentCombinedDataRenderer',
  },
  {
    columnName: 'operationStartDate',
    type: COLUMN_TYPE.DATE,
    displayName: (
      <Trans id="worker_tasks.table_column@operation_start_date">
        Плановая дата начала
      </Trans>
    ),
    customComponent: 'DateRenderer',
  },
  {
    columnName: 'operationStopDate',
    type: COLUMN_TYPE.DATE,
    displayName: (
      <Trans id="worker_tasks.table_column@operation_stop_date">
        Плановая дата окончания
      </Trans>
    ),
    customComponent: 'DateRenderer',
  },
];

export const taskViewScreenSchema = {
  fields: initializeTableModelFields(TASK_VIEW_SCREEN_SCHEMA_FIELDS),
};
