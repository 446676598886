import { createSelector } from 'reselect';
import { plannerAppStateSelector } from '../selectors';
import {
  GROUP_SHEETS_START_BROADCAST_STATUS,
} from '../../../components/PlannerApp/SheetsToStart/AllSheetsToStart/constants';

export const groupSheetsStartProcessStateSelector = createSelector(
  plannerAppStateSelector,
  ({ groupSheetsStart }) => groupSheetsStart,
);

export const isGroupSheetsStartInProgressSelector = createSelector(
  groupSheetsStartProcessStateSelector,
  ({ status }) => status !== GROUP_SHEETS_START_BROADCAST_STATUS.NOT_STARTED,
);