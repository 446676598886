import React from 'react';
import _isNumber from 'lodash/isNumber';
import { divDecimals, mulDecimals } from '@bfg-frontend/utils/lib/decimal';

export const getRestInputModesValues = (currentInputModeValue, currentInputMode, modesSchema) => {
  return modesSchema
    .filter(({ value }) => value !== currentInputMode)
    .map(({ getValueForMode, label, id }) => {
      return (
        <React.Fragment key={id}>
          {'~'}
          {' '}
          {getValueForMode(currentInputModeValue, currentInputMode)}
          {', '}
          {label}
        </React.Fragment>
      );
    });
};

export const getAmountFromPercent = (maxAmount, percentAmount) => {
  const numberPercentAmount = _isNumber(percentAmount) ? percentAmount : Number(percentAmount);

  if (numberPercentAmount === 0 || maxAmount <= 0) return 0;

  const fractionalPercentProgress = divDecimals(numberPercentAmount, 100);

  return Number(mulDecimals(maxAmount, fractionalPercentProgress).toFixed(3));
};

export const getPercentFromAmount = (maxAmount, amount) => {
  const numberAmount = _isNumber(amount) ? amount : Number(amount);

  if (numberAmount === 0 || maxAmount <= 0) return 0;

  const fractionalPercentProgress = divDecimals(numberAmount, maxAmount);

  return Number(mulDecimals(fractionalPercentProgress, 100).toFixed(6));
};

export const TEXT_FIELD_INPUT_MODES = {
  PERCENT: 'PERCENT',
  AMOUNT: 'AMOUNT',
};
