import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import './style.css';
import TextField from '@mui/material/TextField';
import { FUNC_IS_REQUIRED_TYPE } from '../../../../../constants/propTypes';
import { FILTER_TYPES } from '../../../../../api/restCollectionApi/index';

import _get from 'lodash/get';
import { FILTER_DATA_TYPE } from '../../filters.types';
import { MATERIAL_UI_VARIANT } from '../../../../../constants/materialUI';


export const TextInputFilterComponent = props => {
  const {
    filterKey,
    filterData,
    onChange,

    error,

    filterType = FILTER_TYPES.CONTAINS,
  } = props;

  const filterValue = _get(filterData, 'filterValue', null);

  const handleChange = useCallback(
    e => {
      const newValue = e.target.value;

      onChange({
        [filterKey]: newValue === '' ?
          null :
          ({
            filterType: filterType,
            filterValue: e.target.value,
          }),
      });
    },
    [filterKey, filterType, onChange],
  );

  return (
    <TextField
        className="text-input-filter-component"
        margin="dense"
        id={`text-input-filter-component-${filterKey}`}
        type="text"
        variant={MATERIAL_UI_VARIANT.STANDARD}
        value={filterValue === null ? '' : filterValue}
        fullWidth
        onChange={handleChange}
        error={!!error}
        helperText={error}
        FormHelperTextProps={{
          className: 'text-input-filter-component__helper-text',
        }}
    />
  );
};

TextInputFilterComponent.propTypes = {
  filterKey: PropTypes.string.isRequired,
  filterData: FILTER_DATA_TYPE,
  onChange: FUNC_IS_REQUIRED_TYPE,

  error: PropTypes.node,

  filterType: PropTypes.oneOf(Object.values(FILTER_TYPES)),
};