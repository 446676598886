import { tasksSearchHistorySelector } from '../../../../reducers/workerApp/tasksSearchHistory/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { setTasksSearchHistory } from '../../../../reducers/workerApp/tasksSearchHistory/actions';
import { useCallback } from 'react';


const DEFAULT_MAX_AMOUNT_OF_STORED_SEARCH_HISTORY = 3;

export const getUpdatedSearchHistoryForKeyIfItChanged = (
  searchHistoryForKey = [],
  getHistoryItemId,
  newSearchHistoryItem,
  maxAmountOfStoredSearchHistory = DEFAULT_MAX_AMOUNT_OF_STORED_SEARCH_HISTORY,
) => {

  /*
  * - если элемент уже есть в истории - ничего не делаем
  * - если элемента нет - добавляем в конец массива элементов истории
  * - если превышен размер истории - удаляем первый элемент массива элементов истории
  * */

  const newSearchHistoryItemId = getHistoryItemId(newSearchHistoryItem);

  if(!!searchHistoryForKey.find(searchHistoryItem => getHistoryItemId(searchHistoryItem) === newSearchHistoryItemId)) {
    return undefined;
  }

  const searchHistoryForKeyWithNewItem = searchHistoryForKey.concat(newSearchHistoryItem);

  return searchHistoryForKeyWithNewItem.length > maxAmountOfStoredSearchHistory ?
      searchHistoryForKeyWithNewItem.slice(1) :
      searchHistoryForKeyWithNewItem;
};

/*
* В будущем нужно будет понять, насколько эта функциональность с хранением истории поиска \ фильтрации универсальна и,
* если так, то попробовать абстрагировать хук. Сложные моменты:
*  - Если делать логику совсем абстрактной и выделять отдельный стор, то нужно или, например, выделять отдельный
* "верхнеуровневый ключ поиска \ истории", чтобы ещё внутри по ключам хранить данные истории (например, для этого кейса,
* вероятно, мы хотим верхнеуровневый ключ, что это "поиск в заданиях рабочего", а внутри уже ключи для каждого фильтра)
* или же, если не делать ещё одну вложенность, то ключи самой истории должны быть обязательно уникальны и, вероятно,
* будут довольно длинными + в этом случае, не до конца понятно, как их задавать хуку, т.е., например, или массивом,
* или использовать хук для каждого их ключей (например, в данном случае, если у нас история для 7 фильтров, то вызывать
* хук 7 раз для разных ключей, или же вызывать его только для того ключа, чей таб активен.)
*  - Не до конца понятно концептуально, что есть "история поиска". Если это относится к фильтрам, то можно подумать,
* над хранением и где-то рядом с табличными данными, где хранятся сами фильтры. Или может будут какие-то другие
* кейсы поиска. Сейчас пока что логика, всё равно, кажется не совсем абстрактной с этим хранением, например,
* ограниченноого количества записей + и структура этих записей не до конца понятна - она сейчас объект, скорее,
* из-за того, что мы хотим её восстанавливать в автокомплит, а, вероятно, структура должна быть какая-то специальная,
* где сохраняемый сейчас объект это только один из ключей, которые могут быть любыми и отличаться для разных
* абстрактных кейсов
* */
export const useTasksSearchHistory = (maxAmountOfStoredSearchHistory = DEFAULT_MAX_AMOUNT_OF_STORED_SEARCH_HISTORY) => {
  const dispatch = useDispatch();
  const searchHistory = useSelector(tasksSearchHistorySelector);

  const clearHistory = useCallback(
    () => dispatch(setTasksSearchHistory({})),
    [dispatch],
  );

  const setSearchHistory = useCallback(
    (key, newSearchHistoryItem, getHistoryItemId) => {

      const searchHistoryForKey = searchHistory[key];

      const updatedSearchHistoryForKey = getUpdatedSearchHistoryForKeyIfItChanged(
        searchHistoryForKey,
        getHistoryItemId,
        newSearchHistoryItem,
        maxAmountOfStoredSearchHistory,
      );

      /*
      Если getUpdatedSearchHistoryForKeyIfItChanged вернул undefined, значит в истории уже есть значение нового фильтра,
      обновлять стейт не нужно
      */
      if (!updatedSearchHistoryForKey) return;

      const updatedSearchHistory = {
        ...searchHistory,
        [key]: updatedSearchHistoryForKey,
      };

      dispatch(setTasksSearchHistory(updatedSearchHistory));

      /* возвращаем updatedSearchHistory, чтобы можно было получить обновлённый стейт сразу после вызова setSearchHistory */
      return updatedSearchHistory;
    },
    [dispatch, maxAmountOfStoredSearchHistory, searchHistory],
  );

  return {
    searchHistory,
    setSearchHistory,
    clearHistory,
  };
};
