import { createSelector } from 'reselect';
import _mapValues from 'lodash/mapValues';
import { tasksTableSettingsSelector } from './workerTasksTableSettings';
import { SCHEMA_MODEL_INITIAL_STATE } from '../reducers/schemaModel/initialState';
import { TASKS_MODEL } from '../reducers/schemaModel/models/tasksSchema';

const INITIAL_TASKS_TABLE_SCHEMA = SCHEMA_MODEL_INITIAL_STATE[TASKS_MODEL];
export const currentTasksTableSchemaSelector = createSelector(
  tasksTableSettingsSelector,
  currentTasksTableColumnsSettings => {
    return currentTasksTableColumnsSettings ?
      ({
        ...INITIAL_TASKS_TABLE_SCHEMA,
        fields:_mapValues(
          INITIAL_TASKS_TABLE_SCHEMA.fields,
          (defaultColumnData, columnId) => ({
            ...defaultColumnData,
            ...currentTasksTableColumnsSettings[columnId],
          }),
        ),
      }) :
      INITIAL_TASKS_TABLE_SCHEMA;
  },
);