import { SheetsContainer } from '../../Sheets/SheetsContainer';
import {
  QUALITY_CONTROL_DEFECTIVE_SHEETS_ROUTE,
} from '../../../constants/routes';
import { ENTITY_BATCH_TRANSACTION_TYPE, SHEET_TYPE } from '../../../constants/sheets';
import { fetchDefectiveSheetData, fetchDefectiveSheets } from '../../../operations/sheets';
import { Trans } from '@lingui/macro';
import {
  DEFECT_SHEETS_FILTERS_SCHEMA,
} from '../../Sheets/sheetsFilters/sheetsFilters';
import React from 'react';
import { DefectLabelTrans } from '../../../utils/commonTransComponents';
import { SimpleSummary } from '../../common/SimpleSummary/SimpleSummary';
import { useSelector } from 'react-redux';
import { defectiveSheetsRemoteTableDataSelector } from '../../../selectors/sheets';
import { MATERIAL_UI_STYLE_COLOR } from '../../../constants/materialUI';
import {
  DefectiveSheetsReviewContentContainer,
} from './DefectiveSheetsReviewContent/DefectiveSheetsReviewContentContainer';
import _get from 'lodash/get';
import { DEFECTIVE_SHEET_SECONDARY_SUMMARY_SCHEMA } from './constants';


const getDefectiveSheetAdditionalLabel = () => [{
  labelContent: DefectLabelTrans,
  labelStyle: MATERIAL_UI_STYLE_COLOR.SECONDARY,
  labelKey: 'defect',
}];

const renderSheetItemRightSideContent = sheetData => {
  const markedAsDefectiveEntityBatchTransactionEntity = _get(
    sheetData,
    [
      'entityBatchTransactionsByType',
      ENTITY_BATCH_TRANSACTION_TYPE.MARKED_AS_DEFECTIVE,
    ],
  );

  if (!markedAsDefectiveEntityBatchTransactionEntity) return null;

  const {
      userName,
      entityBatchTransactionCreateStamp,
  } = markedAsDefectiveEntityBatchTransactionEntity;

  return (
    <SimpleSummary
        summaryData={{
          userName,
          entityBatchTransactionCreateStamp,
        }}
        secondarySummarySchema={DEFECTIVE_SHEET_SECONDARY_SUMMARY_SCHEMA}
    />
  );
};


export const DefectiveSheets = () => {
  const sheetsData = useSelector(state => defectiveSheetsRemoteTableDataSelector(
    state,
    { tableId: SHEET_TYPE.DEFECTIVE },
  ));

  return (
    <SheetsContainer
        className="defective-sheets"
        mainRoutePath={QUALITY_CONTROL_DEFECTIVE_SHEETS_ROUTE}
        sheetsIdentity={SHEET_TYPE.DEFECTIVE}
        sheetItemIdProperty="sheetId"
        fetchSheetTypeEntitiesActionCreator={fetchDefectiveSheets}
        fetchSheetToReviewDataActionCreator={fetchDefectiveSheetData}
        sheetsData={sheetsData}
        sheetReviewContentComponent={DefectiveSheetsReviewContentContainer}
        noDataText={
            <Trans id="defect_state@no_sheets_in_defect_state">
              В данный момент нет ни одного маршрутного листа в статусе "окончательный брак"
            </Trans>
        }
        filtersSchema={DEFECT_SHEETS_FILTERS_SCHEMA}
        renderSheetItemRightSideContent={renderSheetItemRightSideContent}
        getSheetItemAdditionalInfoLabels={getDefectiveSheetAdditionalLabel}
    />
  );
};
