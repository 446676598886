import { fetchEntitiesFromServer, saveEntitiesOnServer } from '../reducers/entities/actions';
import { USER_SETTINGS_MODEL } from '../constants/models';
import { FILTER_GROUP_TYPES, FILTER_TYPES } from '../api/restCollectionApi';
import _get from 'lodash/get';
import {
  appUserIdSelector,
  userSettingsSelector,
  workerAppLastVisitedRouteSelector,
  workerAppViewModeSelector,
} from '../reducers/appState/selectors';
import { setUserSettings } from '../reducers/appState/actions';
import {
  USER_SETTINGS_ID, WORKER_APP_VIEW_MODE,
} from '../components/WorkerApp/constants';
import { routerPathnameSelector } from '../reducers/router/selectors';
import { WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE, WORKER_APP_TASKS_ROUTE } from '../constants/routes';
import { matchPath } from 'react-router-dom';

export const fetchUserSettingsAndAddToStore = userId =>
  dispatch => {

    const query = {
      filter: {
        filterGroupType: FILTER_GROUP_TYPES.AND,
        filters: [
          {
            column: 'userId',
            filterType: FILTER_TYPES.EQUALS,
            filterValue: userId,
          },
        ],
      },
    };

    return dispatch(fetchEntitiesFromServer(USER_SETTINGS_MODEL, query))
      .then(response => {
        const {
          responseMeta: { count },
          entities,
        } = response;

        if (count === 0) return;

        const currentUserSettings = _get(entities, [USER_SETTINGS_MODEL, userId, 'data']);

        dispatch(setUserSettings(currentUserSettings));

        return currentUserSettings;
      });
  };

const SAVE_USER_SETTINGS_TO_DECAMELIZE = [
  'userSettings',
  'userId',
];

const SAVE_USER_SETTINGS_REQUEST_OPTIONS = {
  isBlockingRequest: false,
  decamelizeKeysConversionCb: (key, convert) => {

    return SAVE_USER_SETTINGS_TO_DECAMELIZE.includes(key) ?
      convert(key) :
      key;
  },
};

export const saveUserSettings = newSettingsByIds =>
  (dispatch, getState) => {
    const state = getState();

    const currentUserId = appUserIdSelector(state);

    const currentUserSettings = userSettingsSelector(state);

    const isSettingsNew = !currentUserSettings;

    /*
    Поле data в модели USER_SETTINGS_MODEL - это JSON поле, в котором хранятся все настройки пользователя.
    При сохранении новых настроек мы полностью перезаписываем это поле в БД, поэтому при выполнении запроса
    нужно сформировать полный список настроек "старые" + "новые", чтобы не удалить старые настройки
    */
    const userSettingsToSave = {
      ...(currentUserSettings || {}),
      ...newSettingsByIds,
    };

    return dispatch(saveEntitiesOnServer(
      USER_SETTINGS_MODEL,
      [{
        userId: currentUserId,
        data: userSettingsToSave,
      }],
      isSettingsNew,
      SAVE_USER_SETTINGS_REQUEST_OPTIONS,
    ))
      .then(({ responseEntitiesWithoutErrors }) => {
        if (!responseEntitiesWithoutErrors.length) return;

        const { data } = _get(responseEntitiesWithoutErrors, [0], {});

        dispatch(setUserSettings(data));
      });
  };

/*
* Если у пользователя нет никаких настроек для приложения рабочего, но он заходит сразу на один из интерфейсов,
* которые определяют настройки (часть настроек определяется текущим роутом приложения), то важно их сразу сохранить,
* иначе может получиться кейс, что пользователь заходит сразу по ссылке в интерфейсе "Все задания", в настройках
* этого нет и другим никаким способом эти настройки не сохраняться, если пользователь просто закроет ссылку или
* разлогинится.
* */
export const initWorkerAppUserSettingsIfNotExist = () =>
  (dispatch, getState) => {

    const state = getState();

    const workerAppViewMode = workerAppViewModeSelector(state);
    const lastVisitedRoute = workerAppLastVisitedRouteSelector(state);

    /*
    * Если настройки, которые зависят от роутов уже были сохранены ранее, то ничего делать не нужно, логика при
    * инициализации приложение уже корректно будет их учитывать, т.е. именно они будут "источником" правды, а не
    * роут, т.к. сам роут будет подстраиваться (будет выполняться редирект, если нужно) под настройки
    * */
    if(!!workerAppViewMode && !!lastVisitedRoute) {
      return Promise.resolve();
    }

    const currentPathname = routerPathnameSelector(state);

    if(currentPathname === WORKER_APP_TASKS_ROUTE) {
      return dispatch(saveUserSettings({
        [USER_SETTINGS_ID.WORKER_APP_VIEW_MODE]: WORKER_APP_VIEW_MODE.TASKS,
        [USER_SETTINGS_ID.WORKER_APP_LAST_VISITED_ROUTE]: currentPathname,
      }));
    }

    if(
      !!(matchPath(currentPathname, {
        path: `${WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE}/:departmentId/:equipmentClassId`,
      }))
    ) {
      return dispatch(saveUserSettings({
        [USER_SETTINGS_ID.WORKER_APP_VIEW_MODE]: WORKER_APP_VIEW_MODE.EQ_CLASS_IN_DEP_TASKS,
        [USER_SETTINGS_ID.WORKER_APP_LAST_VISITED_ROUTE]: currentPathname,
      }));
    }

    /*
    * Если предыдущий if не сработал, и currentPathname.startsWith(WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE)
    * = true, значит пользователь находится на экранах выбора класса РЦ или подразделения. В этом случае,
    * WORKER_APP_VIEW_MODE уже определен и его надо сохранить, если это не было сделано раньше,
    * а WORKER_APP_LAST_VISITED_ROUTE для этих экранов пока принято не сохранять, т.к. это не особо важно, важно
    * сохранить именно уже выбранный класс РЦ и подразделение, что делается в прошлом ифе на нужном роуте
    * Если же workerAppViewMode уже был сохранен, а lastVisitedRoute нет (т.е. не сработал иф в самом начале
    * обработки), значит пользователь в прошлый раз, как раз, закончил работу на одном из экранов выбора класса РЦ или
    * подразделения, и значит ничего делать не нужно, workerAppViewMode уже WORKER_APP_VIEW_MODE.EQ_CLASS_IN_DEP_TASKS,
    * а WORKER_APP_LAST_VISITED_ROUTE пока неизвестен
    * */
    if(!workerAppViewMode && currentPathname.startsWith(WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE)) {
      return dispatch(saveUserSettings({
        [USER_SETTINGS_ID.WORKER_APP_VIEW_MODE]: WORKER_APP_VIEW_MODE.EQ_CLASS_IN_DEP_TASKS,
      }));
    }

    return Promise.resolve();
  };