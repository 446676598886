import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import _partial from 'lodash/partial';
import _isFunction from 'lodash/isFunction';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _pickBy from 'lodash/pickBy';
import _mapValues from 'lodash/mapValues';
import _size from 'lodash/size';
import _isEqual from 'lodash/isEqual';

import { FUNC_IS_REQUIRED_TYPE } from '../../../constants/propTypes';
import { tableParamsSelector } from '../../../reducers/table/selectors';
import { schemaFieldsForModelSelector } from '../../../reducers/schemaModel/selectors';
import { setTableParams } from '../../../reducers/table/actions';
import {
  changeColumnsOrder,
  changeColumnVisibility,
  resizeColumns,
} from '../../../reducers/schemaModel/actions';
import {
  COLUMN_TYPE_COMPARATORS,
} from '../../../utils/arrayComparators';
import { asyncComponent } from '../../../hoc/asyncComponent/asyncComponent';
import { TableCellRenderer } from '../../Table/TableCellRenderers/TableCellRenderer';
import Filters from '../Filters';
import {
  stringComparator,
  complexComparatorFactory,
  fieldComparatorFactory,
  negateComparator,
} from '@bfg-frontend/utils/lib/array';
import { clickHandlerFactory } from '@bfg-frontend/utils/lib/clickHandlers';


export const withTablesDataLayerHOC = tableCustomCellRenderers  =>
  Component => {

    /*
    * Обертка над декорируемым компонентом для реализации общей логики проверки активной страницы текущих табличных данных.
    *
    * Для случаев обновлений данных таблиц, когда рядов в таблице становится меньше, чем было (удаляются вручную или
    * обновляется каким-то образом асинхронно, в фоне), необходимо обработать моменты, когда активная страница данных
    * уже не существует. В этом случае переключаем страницу на последнюю существующую. Сама обработка в
    * checkActivePageCbFactory в mergeProps
    * */
    class ComponentWrapper extends React.Component {

      componentDidMount() {
        this.props.checkActivePage();
      }

      componentDidUpdate() {
        this.props.checkActivePage();
      }

      render() {
        return(
          <Component {...this.props}/>
        );
      };
    }

    ComponentWrapper.propTypes = {
      checkActivePage: FUNC_IS_REQUIRED_TYPE,
    };

    const mapStateToProps = (state, ownProps) => {
      const {
        tableId,
        tableModel,
        defaultPageSize,
      } = ownProps;
      const {
        activePage,
        pageSize,
        filterParams,
        sortParams,
        remoteData,
      } = tableParamsSelector(state, { tableId, tableModel, defaultPageSize });

      return{
        activePage,
        pageSize,
        filterParams,
        sortParams,
        remoteData,
        schemaFields: schemaFieldsForModelSelector(state, { model : tableModel }),
      };
    };

    const mapDispatchToProps = {
      setTableParams,
      changeColumnsOrder,
      changeColumnVisibility,
      resizeColumns,
    };

    const mergeProps = (stateProps, dispatchProps, ownProps) => {

      const {
        activePage,
        pageSize,
        filterParams,
        sortParams,
        remoteData: remoteTableData,
        schemaFields,
      } = stateProps;

      const {
        setTableParams,
        changeColumnsOrder,
        changeColumnVisibility,
        resizeColumns,
      } = dispatchProps;

      const {
        tableId,
        tableModel,
        rowIdProperty,
        tableTitle,
        additionalComponents,
        disableColumnsResize,
        disableColumnsOrderChange,
        disableSort,
        disableFilter,
        customFilters,
        maxPageButtonsAmount,
        simplePagination,
        onRowClick,
        onRowDoubleClick,
        getRowStyle,
        getRowFactory,
        noDataContent,
        selectedRowId,
        customCellRenderersProps,
        wrappedTableComponentProps,
        tableMenu,
        tableSummary,
        fetchRemoteTableData,
        onSortChange,
        onFilterChange,
        onPageChange,
        onPageSizeChange,
        customTableComponents,
        alwaysShowPagination,
        withPageSizeSelect,
      } = ownProps;

      const resultSchemaFields = getResultSchemaFields(schemaFields);

      const {
        allRowsData,
        filteredAndSortedAllRowsData,
        filteredAndSortedRowsDataForCurrentPage,
      } = getRowsDataProps(stateProps, ownProps, resultSchemaFields);

      const updateTableParamsCb = updateTableParamsCbFactory(stateProps, dispatchProps, ownProps);

      const totalItemsAmount = getTotalItemsAmount(stateProps, ownProps, filteredAndSortedAllRowsData);
      const totalPagesAmount = getTotalPagesAmount(pageSize, totalItemsAmount);

      return{
        tableId,
        tableModel,
        tableTitle,
        additionalComponents,
        rowIdProperty,
        allRowsData,
        filteredAndSortedAllRowsData,
        filteredAndSortedRowsDataForCurrentPage,
        noDataContent,
        selectedRowId,
        onRowCombinedClicksHandler: clickHandlerFactory(onRowClick, onRowDoubleClick),
        schemaFields: resultSchemaFields,
        sortParams,
        changeSort: disableSort ?
          undefined :
          changeSortCbFactory(sortParams, schemaFields, updateTableParamsCb, onSortChange),
        disableSort,
        filterParams,
        changeFilters: disableFilter ?
          undefined :
          changeFilterCbFactory(updateTableParamsCb, onFilterChange),
        disableFilter,
        customFilters,
        activePage,
        pageSize,
        totalPagesAmount,
        totalItemsAmount,
        maxPageButtonsAmount,
        simplePagination,
        changePage: changePageCbFactory(activePage, updateTableParamsCb, onPageChange),
        changePageSize: changePageSizeCbFactory(pageSize, activePage, totalItemsAmount, updateTableParamsCb, onPageSizeChange),
        changeColumnVisibility: _partial(changeColumnVisibility, tableModel),
        resizeColumns: disableColumnsResize ?
          undefined :
          _partial(resizeColumns, tableModel),
        disableColumnsResize,
        changeColumnsOrder: disableColumnsOrderChange ? undefined : _partial(changeColumnsOrder, tableModel),
        disableColumnsOrderChange,
        getRowStyle,
        getRowFactory,
        checkActivePage: checkActivePageCbFactory(activePage, totalPagesAmount, updateTableParamsCb, onPageChange),
        customCellRenderersProps,
        wrappedTableComponentProps,
        tableMenu,
        tableSummary,
        fetchRemoteTableData,
        setTableParams,
        remoteTableData,
        customTableComponents,
        alwaysShowPagination,
        withPageSizeSelect,
      };
    };

    const updateTableParamsCbFactory = (stateProps, dispatchProps, ownProps) => {
      const {
        activePage,
        pageSize,
        filterParams,
        sortParams,
      } = stateProps;
      const { setTableParams } = dispatchProps;
      const {
        tableId,
        tableModel,
        fetchRemoteTableData,
      } = ownProps;

      return (updatedTableParams = {}, onTableParamsUpdate) => {

        const resultTableParamsAfterUpdate = {
          activePage,
          pageSize,
          filterParams,
          sortParams,
          ...updatedTableParams,
        };

        /*
        * Если определен проп для "ручного" изменения параметра,то вызываем только его. В этом случае таблица считается
        * "контролируемой" (controlled), т.е. все манипуляции по изменению параметров таблицы для корректной работы
        * нужно будет выполнять для такой таблицы отдельно самостоятельно
        * */
        if(_isFunction(onTableParamsUpdate))
          return onTableParamsUpdate(tableId, { ...resultTableParamsAfterUpdate }, tableModel);

        /*
        * Если таблица "неконтролируемая" (uncontrolled) и не удаленная, то просто обновляем параметры для таблицы
        * в стандартном store
        * */
        if (!_isFunction(fetchRemoteTableData)) {
          setTableParams(tableId, { ...updatedTableParams }, tableModel);
          return Promise.resolve();
        }

        /*
        * Если таблица "неконтролируемая" (uncontrolled) и удаленная, то выполняем запрос данных, и только по
        * окончанию запроса обновляем стандартный store 1 раз, записывая туда и новые параметры таблицы и данные для
        * удаленной таблицы
        * */
        return fetchRemoteTableData({
          tableId,
          tableModel,
          tableParams: { ...resultTableParamsAfterUpdate },
        })
        /*
        * API подразумевает, что проп fetchRemoteTableData должен возвращать:
        * itemsIds - массив идентификаторов, определяющих ряды удаленной таблицы для текущих параметров запроса
        * (массив определяет порядок серверной сортировки, данные рядов будут собираться по itemsById)
        * itemsById - нормализованные данные, для рядов удаленной таблицы для текущих параметров запроса
        * (чтобы уменьшить количество хранимых данных, в нормализованном виде все уникальные сущности будут
        * храниться в одном экземпляре + это облегчает быстрый доступ к сущностям по id без пробегов по массивам)
        * totalItemsAmount - общее количество рядов удаленной таблицы для текущих параметров запроса (чтобы корректно
        * отображать пагинацию)
        * */
          .then(
            ({ itemsIds, itemsById, totalItemsAmount }) => {

              const updatedRemoteDataParams = {
                currentRemoteItemsIds: itemsIds,
                currentRemoteItemsById: itemsById,
                totalRemoteItemsAmount: totalItemsAmount,
              };

              setTableParams(
                tableId,
                {
                  ...updatedTableParams,
                  remoteData: updatedRemoteDataParams,
                },
                tableModel,
              );
            },
          );
      };
    };

    const getTotalPagesAmount = (pageSize, tableTotalItemsAmount) => {
      if(tableTotalItemsAmount === 0) return 1;

      return Math.ceil(tableTotalItemsAmount / pageSize);
    };

    const getTotalItemsAmount = (stateProps, ownProps, filteredAndSortedAllRowsData) => {
      const {
        remoteData,
      } = stateProps;
      const {
        fetchRemoteTableData,
      } = ownProps;

      return _isFunction(fetchRemoteTableData) ?
        _get(remoteData, 'totalRemoteItemsAmount', 0) :
        filteredAndSortedAllRowsData.length;
    };

    const getResultSchemaFields = schemaFields => _mapValues(
      schemaFields,
      (schemaFieldData, schemaField) => {
        const { customComponent: FieldCustomCellRendererIdentity } = schemaFieldData;

        if(!FieldCustomCellRendererIdentity) return schemaFieldData;

        const FieldCustomCellRenderer =
          _get(tableCustomCellRenderers, ['components', FieldCustomCellRendererIdentity]) ||
          _get(tableCustomCellRenderers, ['containers', FieldCustomCellRendererIdentity]);

        if (!FieldCustomCellRenderer)
          throw new Error(
            /* eslint-disable */
            'Для колонки таблицы ' + schemaField + ' задан идентификатор неизвестного ' +
            'компонента/контейнера-отрисовщика. Все компоненты/контейнеры-отрисовщики, указываемые в моделях таблиц ' +
            'schemaModel, должны быть определены при инициализации таблицы в параметре tableCustomCellRenderers в ' +
            'tableFactory'
            /* eslint-disable */
          );

        if(
          !TableCellRenderer.isPrototypeOf(FieldCustomCellRenderer) &&
          !TableCellRenderer.isPrototypeOf(FieldCustomCellRenderer.WrappedComponent)
        )
          throw new Error(
            /* eslint-disable */
            'Для колонки таблицы ' + schemaField + ' задан компонент/контейнер-отрисовщик неизвестной структуры. ' +
            'Все компоненты-отрисовщики или компоненты внутри контейнеров-отрисовщик должны наследоваться от ' +
            'абстрактного компонента TableCellRenderer'
            /* eslint-disable */
          );

        return{
          ...schemaFieldData,
          customComponent: FieldCustomCellRenderer
        }
      }
    );

    const getRowsDataProps = (stateProps, ownProps, resultSchemaFields) => {
      const { fetchRemoteTableData, rowsData: allRowsData } = ownProps;

      if(_isFunction(fetchRemoteTableData) || allRowsData.length === 0)
        return{
          allRowsData,
          filteredAndSortedAllRowsData: allRowsData,
          filteredAndSortedRowsDataForCurrentPage: allRowsData
        };

      const {
        filterParams,
        sortParams,
        activePage,
        pageSize,
      } = stateProps;
      const {
        disableFilter,
        disableSort,
        customCellRenderersProps = {},
        customColumnsComparators = {},
        customSortFn,
      } = ownProps;

      const filteredTableRowsData = disableFilter ?
        allRowsData :
        filterTableRows(allRowsData, filterParams, resultSchemaFields, customCellRenderersProps);

      const filteredAndSortedTableRows = disableSort ?
        filteredTableRowsData :
        sortTableRows(filteredTableRowsData, sortParams, resultSchemaFields, customColumnsComparators, customSortFn);

      return {
        allRowsData,
        filteredAndSortedAllRowsData: filteredAndSortedTableRows,
        filteredAndSortedRowsDataForCurrentPage: getRowsDataForPage(filteredAndSortedTableRows, activePage, pageSize)
      }

    };

    const filterTableRows = (rowsData, filterParams, resultSchemaFields, customCellRenderersProps) => {

      if(_isEmpty(filterParams)) return rowsData;

      return Filters.getFilteredData(
        rowsData,
        filterParams,
        getFilteredFieldsViewValueGetters(filterParams, resultSchemaFields, customCellRenderersProps)
      );
    };

    const getFilteredFieldsViewValueGetters = (filterParams, resultSchemaFields, customCellRenderersProps) => {

      const filteredFieldsViewValueGetters = _mapValues(
        filterParams,
        (filterData, filterFieldName) => {
          const CustomComponent = _get(resultSchemaFields, [filterFieldName, 'customComponent']);
          if (!CustomComponent) return null;

          const FieldCustomCellRendererComponent = CustomComponent || CustomComponent.WrappedComponent;

          return (rowFieldValue, rowData) =>
            FieldCustomCellRendererComponent.getViewValue({
              ...customCellRenderersProps[filterFieldName],
              value: rowFieldValue,
              data: rowData
            });
        }
      );

      return _pickBy(
        filteredFieldsViewValueGetters,
        viewValueGetter => viewValueGetter !== null
      );

    };

    const sortTableRows = (rowsData, sortParams, resultSchemaFields, customColumnsComparators = {}, customSortFn) => {

      /*
      * В текущей реализации сортировка всегда по одной колонке таблице, но, теоретически, в будущем возможна и множественная
      * сортировка. Поэтому данные о сортировке хранятся в массиве
      * */
      if(_size(sortParams) === 0) return rowsData;

      if(_isFunction(customSortFn))
        return customSortFn(rowsData, sortParams);

      const comparatorsArray = sortParams
        .filter(({ column }) => !!resultSchemaFields[column])
        .map(({ column, asc }) => {

          const columnComparator =
            customColumnsComparators[column] ||
            _get(COLUMN_TYPE_COMPARATORS, resultSchemaFields[column].type, stringComparator);

          return fieldComparatorFactory(
            asc ? columnComparator : negateComparator(columnComparator),
            column
          )
        });

      if(!comparatorsArray.length) return rowsData;

      const resultComparator = complexComparatorFactory(comparatorsArray);

      return rowsData
        .slice()
        .sort(resultComparator);
    };

    const getRowsDataForPage = (rowsData, activePage, pageSize) =>
      rowsData
        .slice(
          (activePage - 1) * pageSize,
          activePage * pageSize
        );

    const changeSortCbFactory = (prevSortParams, schemaFields, updateTableParamsCb, onSortChange) =>
      newSortParams => {
        const resultNewSortParams = newSortParams
          .filter(({ column }) => {
            const schemaField = schemaFields[column];
            return !!schemaField && !schemaField.disableSort
          });

        if(resultNewSortParams.length === 0 || _isEqual(resultNewSortParams, prevSortParams)) return;

        updateTableParamsCb({ sortParams: resultNewSortParams }, onSortChange);
      };

    const changePageCbFactory = (currentActivePage, updateTableParamsCb, onPageChange) =>
      newActivePage => {
        if(newActivePage === currentActivePage) return;
        updateTableParamsCb({ activePage: newActivePage }, onPageChange)
      };

    const changeFilterCbFactory = (updateTableParamsCb, onFilterChange) =>
      newFilters => updateTableParamsCb({filterParams: newFilters, activePage: 1}, onFilterChange);

    const changePageSizeCbFactory = (
      currentPageSize,
      currentActivePage,
      totalItemsAmount,
      updateTableParamsCb,
      onPageSizeChange,
      ) =>
        newPageSize => {
          if(newPageSize === currentPageSize) return;

          /*
          * Необходимо выполнить проверку на корректность активной страницы с учетом нового размера страниц. При
          * увеличении размера страницы общее количество страниц уменьшается, поэтому возможна ситуация, когда после
          * переключения текущий номер страницы будет больше доступного количества страниц.
          * В случае с серверной таблицей будет выполнен заведомо ошибочный запрос, а в случае с локальной таблицей
          * появятся лишние перерисовки в интерфейсе (сначала отрисуется пустая таблица, затем таблица с данными)
          * */
          if(currentActivePage >= getTotalPagesAmount(newPageSize, totalItemsAmount))
            return updateTableParamsCb({ pageSize: newPageSize, activePage: 1 }, onPageSizeChange);

          updateTableParamsCb({ pageSize: newPageSize }, onPageSizeChange)
        };

    const checkActivePageCbFactory = (currentActivePage, totalPagesAmount, updateTableParamsCb, onPageChange) =>
      () => {
        if(currentActivePage <= totalPagesAmount) return;

        updateTableParamsCb({ activePage: totalPagesAmount }, onPageChange);
      };

    const tableAsyncComponentProps = {
      resolve: [
        {
          fn: props => {
            //TODO сюда в будущем можно добавить динамические импорты кастомных рендереров ячеек таблицы, а также
            //TODO создание на их основе схем таблиц (а не создавать их при инициализации приложения) и запись их в стор
            //TODO ну и любые другие действия, которые нужно сделать до рендера таблицы. Поэтому всю таблицу уже сейчас
            //TODO оборачиваем в asyncComponent.
            //TODO Кроме всего этого, для удаленных таблиц здесь уже сейчас выполнятся запрос данных, если проп
            //TODO fetchRemoteTableData определен, новую логику нужно будет добавлять учитывая этот функционал


            //Если не удаленная таблица, то запросов делать не нужно
            if(!_isFunction(props.fetchRemoteTableData)) return Promise.resolve();

            /*
            * Если таблица удаленная и на маунт компонента в стор уже есть данные по удаленной таблице,
            * то это означает, что для параметров в стор данные уже запрашивались, не делаем лишний запрос данных с
            * теми же параметрами
            * */
            if(props.remoteTableData !== null) return Promise.resolve();

            const {
              fetchRemoteTableData,
              setTableParams,
              sortParams,
              filterParams,
              activePage,
              pageSize,
              tableId,
              tableModel
            } = props;

            return fetchRemoteTableData({
              tableId,
              tableModel,
              tableParams: {
                sortParams,
                filterParams,
                activePage,
                pageSize
              }
            })
            /*
            * API подразумевает, что проп fetchRemoteTableData должен возвращать:
            * itemsIds - массив идентификаторов, определяющих ряды удаленной таблицы для текущих параметров запроса
            * (массив определяет порядок серверной сортировки, данные рядов будут собираться по itemsById)
            * itemsById - нормализованные данные, для рядов удаленной таблицы для текущих параметров запроса
            * (чтобы уменьшить количество хранимых данных, в нормализованном виде все уникальные сущности будут
            * храниться в одном экземпляре + это облегчает быстрый доступ к сущностям по id без пробегов по массивам)
            * totalItemsAmount - общее количество рядов удаленной таблицы для текущих параметров запроса (чтобы корректно
            * отображать пагинацию)
            * */
              .then(
                ({ itemsIds, itemsById, totalItemsAmount }) =>
                  setTableParams(
                    tableId,
                    {
                      remoteData: {
                        currentRemoteItemsIds: itemsIds,
                        currentRemoteItemsById: itemsById,
                        totalRemoteItemsAmount: totalItemsAmount
                      }
                    },
                    tableModel
                  )
              );
          }
        }
      ]
    };

    const ConnectedToTableDataLayerComponentWrapper = compose(
      connect(mapStateToProps, mapDispatchToProps, mergeProps),
      asyncComponent(tableAsyncComponentProps),
    )(ComponentWrapper);

    ConnectedToTableDataLayerComponentWrapper.displayName = 'ConnectedToTableDataLayerComponentWrapper';

    return props =>
      <div key={props.tableId}>
        <ConnectedToTableDataLayerComponentWrapper {...props} />
      </div>;
  };
