export const PLANNER_APP_MAIN_ROUTE = '/planner';

export const PLANNER_APP_SHEETS_SUB_ROUTE = 'sheets';
export const PLANNER_APP_ORDERS_SUB_ROUTE = 'orders';

export const PLANNER_APP_SHEETS_ROUTE = [PLANNER_APP_MAIN_ROUTE, PLANNER_APP_SHEETS_SUB_ROUTE].join('/');
export const PLANNER_APP_ORDERS_ROUTE = [PLANNER_APP_MAIN_ROUTE, PLANNER_APP_ORDERS_SUB_ROUTE].join('/');

export const PLANNER_APP_ORDERS_IN_PRODUCTION_ROUTE = `${PLANNER_APP_ORDERS_ROUTE}/in_production`;
export const PLANNER_APP_ORDERS_READY_TO_COMPLETE_ROUTE = `${PLANNER_APP_ORDERS_ROUTE}/ready_to_complete`;
export const PLANNER_APP_ORDERS_COMPLETED_ROUTE = `${PLANNER_APP_ORDERS_ROUTE}/completed`;

export const PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE = `${PLANNER_APP_SHEETS_ROUTE}/in_production`;
export const PLANNER_APP_SHEETS_TO_START_ROUTE = `${PLANNER_APP_SHEETS_ROUTE}/to_start`;
export const PLANNER_APP_PAUSED_SHEETS_ROUTE = `${PLANNER_APP_SHEETS_ROUTE}/paused`;
export const PLANNER_APP_COMPLETED_SHEETS_ROUTE = `${PLANNER_APP_SHEETS_ROUTE}/completed`;

export const MASTER_APP_MAIN_ROUTE = '/master';
export const MASTER_APP_MAIN_ROUTE_WITH_DEPARTMENTS_PARAM = `${MASTER_APP_MAIN_ROUTE}/:departmentIds`;

export const MASTER_APP_TASKS_SUB_ROUTE = 'tasks';
export const MASTER_APP_SHEETS_SUB_ROUTE = 'sheets';
export const MASTER_APP_TASKS_ROUTE = [MASTER_APP_MAIN_ROUTE_WITH_DEPARTMENTS_PARAM, MASTER_APP_TASKS_SUB_ROUTE].join('/');
export const MASTER_APP_SHEETS_ROUTE = [MASTER_APP_MAIN_ROUTE_WITH_DEPARTMENTS_PARAM, MASTER_APP_SHEETS_SUB_ROUTE].join('/');

export const MASTER_APP_PAUSED_SHEETS_SUB_ROUTE = 'paused';
export const MASTER_APP_PAUSED_SHEETS_ROUTE = [MASTER_APP_SHEETS_ROUTE, MASTER_APP_PAUSED_SHEETS_SUB_ROUTE].join('/');

export const MASTER_APP_TASKS_TO_DO_SUB_ROUTE = 'to_do';
export const MASTER_APP_COMPLETE_TASKS_SUB_ROUTE = 'completed';
export const MASTER_APP_TASKS_TO_DO_ROUTE =
  `${MASTER_APP_TASKS_ROUTE}/${MASTER_APP_TASKS_TO_DO_SUB_ROUTE}`;
export const MASTER_APP_COMPLETED_TASKS_ROUTE =
  `${MASTER_APP_TASKS_ROUTE}/${MASTER_APP_COMPLETE_TASKS_SUB_ROUTE}`;

export const STORAGE_MANAGEMENT_APP_MAIN_ROUTE = '/storage_management';

const SHEETS_WAITING_PARTS_AND_MATERIALS_SUB_ROUTE = 'sheets_waiting_parts_and_materials';
export const STORAGE_MANAGEMENT_APP_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE =
  `${STORAGE_MANAGEMENT_APP_MAIN_ROUTE}/${SHEETS_WAITING_PARTS_AND_MATERIALS_SUB_ROUTE}`;

const DEFAULT_SHEETS_SUB_ROUTE = 'default';
const ASSEMBLY_SHEETS_SUB_ROUTE = 'assembly';

export const STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE =
  `${STORAGE_MANAGEMENT_APP_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE}/${DEFAULT_SHEETS_SUB_ROUTE}`;
export const STORAGE_MANAGEMENT_APP_ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE =
  `${STORAGE_MANAGEMENT_APP_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE}/${ASSEMBLY_SHEETS_SUB_ROUTE}`;

export const WORKER_APP_MAIN_ROUTE = '/worker';
const EQUIPMENT_CLASS_IN_DEPARTMENT_SUB_ROUTE = 'eq_class_in_dep_tasks';
const TASKS_SUB_ROUTE = 'tasks';
export const WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE =
  [WORKER_APP_MAIN_ROUTE, EQUIPMENT_CLASS_IN_DEPARTMENT_SUB_ROUTE].join('/');
export const WORKER_APP_TASKS_ROUTE =
  [WORKER_APP_MAIN_ROUTE, TASKS_SUB_ROUTE].join('/');

export const QUALITY_CONTROL_APP_MAIN_ROUTE = '/quality_control';
export const QUALITY_CONTROL_DEFECTIVE_SHEETS_ROUTE = `${QUALITY_CONTROL_APP_MAIN_ROUTE}/defective`;
export const QUALITY_CONTROL_INAPPROPRIATE_SHEETS_ROUTE = `${QUALITY_CONTROL_APP_MAIN_ROUTE}/inappropriate`;

export const LOGIN_ROUTE = '/login';

export const ADMIN_APP_MAIN_ROUTE = '/admin';
export const ADMIN_APP_USERS_ROUTE = `${ADMIN_APP_MAIN_ROUTE}/users`;
export const ADMIN_APP_WORKER_TASKS_SETTINGS_ROUTE = `${ADMIN_APP_MAIN_ROUTE}/worker_tasks_settings`;
export const ADMIN_APP_WORKER_TASKS_TABLE_ROUTE = `${ADMIN_APP_WORKER_TASKS_SETTINGS_ROUTE}/columns`;
export const ADMIN_APP_WORKER_TASKS_TABLE_FILTERS_ROUTE = `${ADMIN_APP_WORKER_TASKS_SETTINGS_ROUTE}/filters`;


export const ADMIN_APP_SHEET_OPERATION_SETTINGS_ROUTE = `${ADMIN_APP_MAIN_ROUTE}/sheet_operation_settings`;
export const ADMIN_APP_SHEET_OPERATION_EQUIPMENT_SETTINGS_SUB_ROUTE = `${ADMIN_APP_SHEET_OPERATION_SETTINGS_ROUTE}/equipment`;

/* features */
export const ADMIN_APP_SHEET_OPERATION_FEATURES_ACTIVE_STATE_ROUTE_PARAMS_MAP = {
  ACTIVE: 'active',
  DISABLED: 'disabled',
};

export const ADMIN_APP_SHEET_OPERATION_FEATURES_SETTINGS_SUB_ROUTE =
  `${ADMIN_APP_SHEET_OPERATION_SETTINGS_ROUTE}/features`;

export const ADMIN_APP_SHEET_OPERATION_FEATURES_SETTINGS_TABS_ROUTE =
  `${ADMIN_APP_SHEET_OPERATION_FEATURES_SETTINGS_SUB_ROUTE}/:activeState`;

export const ADMIN_APP_ACTIVE_SHEET_OPERATION_FEATURES_SETTINGS_ROUTE = [
  ADMIN_APP_SHEET_OPERATION_FEATURES_SETTINGS_SUB_ROUTE,
  ADMIN_APP_SHEET_OPERATION_FEATURES_ACTIVE_STATE_ROUTE_PARAMS_MAP.ACTIVE,
].join('/');

export const ADMIN_APP_DISABLED_SHEET_OPERATION_FEATURES_SETTINGS_ROUTE = [
  ADMIN_APP_SHEET_OPERATION_FEATURES_SETTINGS_SUB_ROUTE,
  ADMIN_APP_SHEET_OPERATION_FEATURES_ACTIVE_STATE_ROUTE_PARAMS_MAP.DISABLED,
].join('/');

export const ADMIN_APP_SHEET_OPERATION_FEATURE_REVIEW_SCREEN_ROUTE =
  `${ADMIN_APP_SHEET_OPERATION_FEATURES_SETTINGS_TABS_ROUTE}/:featureId`;


export const ADMIN_APP_CLEAR_DB_ROUTE = `${ADMIN_APP_MAIN_ROUTE}/clear-db`;
export const NEW_USER_SUB_ROUTE = 'new';
