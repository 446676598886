export const tablePaginationPropsAdapter = ({
  activePage,
  changePage,
  totalPagesAmount,
}) => (
  {
    activePage,
    changePage,
    totalPagesAmount,
  }
);

export const tablePaginationWithPagePropsAdapter = ({
  activePage,
  changePage,
  totalPagesAmount,
  withPageSizeSelect,
  pageSize,
  changePageSize,
}) => (
  {
    activePage,
    changePage,
    totalPagesAmount,
    withPageSizeSelect,
    pageSize,
    changePageSize,
  }
);
