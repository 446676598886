import { compose } from 'redux';
import { connect } from 'react-redux';
import { clearAutocompleteData } from '../../../../reducers/autocomplete/actions';

import { EqClassInDepTasksSettings } from './EqClassInDepTasksSettings';

import {
  deleteSettingsEntity,
  saveSettingsEntity, saveSettingsEntityAndAddToStore,
} from '../../../../operations/settings';
import {
  workerTasksTableSettingsToShowSelector,
} from '../../../../reducers/workerTasksTableSettings/selectors';
import {
  workerTasksTableSelectedSettingsDataSelector,
  workerTasksTableSettingsSelectOptionsSelector,
} from '../../../../selectors/workerTasksTableSettings';

import {
  getSettingsNameFromId,
  WORKER_EQ_CLASS_IN_DEP_TASKS_TABLE_MAIN_SETTINGS_ID,
  WORKER_EQ_CLASS_IN_DEP_TASKS_TABLE_SETTINGS_GROUP,
} from '../../../../constants/settings';
import { withPermissionsManager } from '../../../../hoc/withPermissionsManager/withPermissionsManager';
import { showWorkerTasksTableSettings } from '../../../../reducers/workerTasksTableSettings/actions';
import { settingsEntitiesForGroupSelector } from '../../../../selectors/settings';
import { fetchEquipmentClassesInDepartment } from '../../../../operations/equipmentClasses';
import { addEntitiesToStore } from '../../../../reducers/entities/actions';

const mapStateToProps = state => {
  const selectedSettingsId = workerTasksTableSettingsToShowSelector(state);
  const selectedSettingsData = workerTasksTableSelectedSettingsDataSelector(state, { settingsId: selectedSettingsId });
  const isMainSettingsSelected = selectedSettingsId === WORKER_EQ_CLASS_IN_DEP_TASKS_TABLE_MAIN_SETTINGS_ID;
  const settingsEntities = settingsEntitiesForGroupSelector(state, { group: WORKER_EQ_CLASS_IN_DEP_TASKS_TABLE_SETTINGS_GROUP });

  return{
    settingsSelectOptions: workerTasksTableSettingsSelectOptionsSelector(state),
    selectedSettingsId,
    selectedSettingsData,
    isMainSettingsSelected,
    settingsEntities,
  };
};

const mapDispatchToProps = {
  showWorkerTasksTableSettings,
  deleteSettingsEntity,
  saveSettingsEntity,
  saveSettingsEntityAndAddToStore,
  addEntitiesToStore,
  fetchEquipmentClassesInDepartment,
  clearAutocompleteData,
};

const mergeProps = (stateProps, dispatchProps) => {
  const {
    selectedSettingsId,
  } = stateProps;

  const {
    showWorkerTasksTableSettings,
    deleteSettingsEntity,
    saveSettingsEntity,
    saveSettingsEntityAndAddToStore,
    addEntitiesToStore,
    fetchEquipmentClassesInDepartment,
    clearAutocompleteData,
  } = dispatchProps;

  return {
    ...stateProps,

    fetchEquipmentClassesInDepartment,
    selectSettings: showWorkerTasksTableSettings,
    saveSettingsEntity,
    saveSettingsEntityAndAddToStore,
    addEntitiesToStore,
    deleteSelectedSettings: () => deleteSettingsEntity({ group: WORKER_EQ_CLASS_IN_DEP_TASKS_TABLE_SETTINGS_GROUP, name: getSettingsNameFromId(selectedSettingsId) }),
    clearAutocompleteData,
  };
};

export const EqClassInDepTasksSettingsContainer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
  ),
  withPermissionsManager,
)(EqClassInDepTasksSettings);

EqClassInDepTasksSettingsContainer.displayName = 'EqClassInDepTasksSettingsContainer';