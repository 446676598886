import { routerPathnameSelector } from '../../../../reducers/router/selectors';
import { matchPath } from 'react-router-dom';
import {
  PLANNER_APP_COMPLETED_SHEETS_ROUTE,
  QUALITY_CONTROL_DEFECTIVE_SHEETS_ROUTE,
  QUALITY_CONTROL_INAPPROPRIATE_SHEETS_ROUTE,
} from '../../../../constants/routes';
import { clearTableData, clearTableRemoteData, reFetchRemoteTableData } from '../../../../reducers/table/actions';
import {
  createSheetTypeOperationsTableId,
} from '../../../../utils/tables';
import { SHEET_TYPE } from '../../../../constants/sheets';
import {
  fetchSheetTypeRemoteTableDataCbFactory,
} from '../../../../components/Sheets/SheetsContainer';
import {
  fetchCompletedSheets,
  fetchDefectiveSheets,
  fetchInappropriateSheets, sendSheetFinalDefectiveStateConfirmedNotification,
} from '../../../../operations/sheets';
import { push } from 'connected-react-router';
import _get from 'lodash/get';


export const handleSheetFinalDefectiveStateConfirmed = message =>
  (dispatch, getState) => {

    const state = getState();

    const {
      sheetId,
      sheetIdentity,
    } = message;


    const currentPathname = routerPathnameSelector(state);

    //Если находимся в разделе "Контроль. Несоответствующая продукция"
    const inappropriateSheetsRouteMatch = matchPath(currentPathname, {
      path: QUALITY_CONTROL_INAPPROPRIATE_SHEETS_ROUTE,
    });
    if (inappropriateSheetsRouteMatch !== null) {
      return dispatch(_updateIfOnInappropriateSheetsInScreen(
        sheetId,
        currentPathname,
        sheetIdentity,
      ));
    }


    //Если находимся в разделе "Контроль. Окончательный брак"
    const defectiveSheetsRouteMatch = matchPath(currentPathname, {
      path: QUALITY_CONTROL_DEFECTIVE_SHEETS_ROUTE,
    });
    if (defectiveSheetsRouteMatch !== null) {
      return dispatch(_updateIfOnDefectiveSheetsInScreen(sheetId));
    }


    //Если находимся в разделе "Плановик. Завершённые"
    const completedSheetsRouteMatch = matchPath(currentPathname, {
      path: PLANNER_APP_COMPLETED_SHEETS_ROUTE,
    });
    if (completedSheetsRouteMatch !== null) {
      return dispatch(_updateIfOnCompletedSheetsScreen(sheetId));
    }



    /*
    * Для всех остальных разделов не нужна какая-то дополнительная обработка, но и для этого случая необходимо
    * очистить данные всех интерфейсов, где должны быть обновления из-за возвращения МЛ в работу, чтобы при следующем
    * входе в эти разделы данные были запрошены заново
    * */
    dispatch([
      clearTableRemoteData([
        SHEET_TYPE.DEFECTIVE,
        SHEET_TYPE.COMPLETED,
        SHEET_TYPE.INAPPROPRIATE,
      ]),
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.INAPPROPRIATE, sheetId)),
    ]);

  };


/*
* Если находимся в разделе "Контроль. Несоответствующая продукция", то очищаем все данные других интерфейсов кроме этого, где должны
* быть обновления из-за отметки окончательного брака, а для этого раздела перезапрашиваем данные списка для текущей
* страницы
* Дополнительно проверяем, если пользователь находится на экране просмотра МЛ, по которому был подтверждён брак,
* то закрываем окно просмотра этого МЛ и выполняем редирект на экран "Несоответствующая продукция"
* */
const _updateIfOnInappropriateSheetsInScreen = (
  sheetId,
  currentPathname,
  sheetIdentity,
) => dispatch => {

  dispatch([
    clearTableRemoteData([
      SHEET_TYPE.DEFECTIVE,
      SHEET_TYPE.COMPLETED,
    ]),
    clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.INAPPROPRIATE, sheetId)),
  ]);

  dispatch(reFetchRemoteTableData(
    SHEET_TYPE.INAPPROPRIATE,
    SHEET_TYPE.INAPPROPRIATE,
    fetchSheetTypeRemoteTableDataCbFactory(
      dispatch,
      query => fetchInappropriateSheets(query, { isBlockingRequest: false }),
    ),
  ));

  const inappropriateSheetsRouteMatch = matchPath(currentPathname, {
    path: `${QUALITY_CONTROL_INAPPROPRIATE_SHEETS_ROUTE}/:sheetId`,
  });


  // если находимся НЕ на экране просмотра партии, то больше ничего не делаем
  if (inappropriateSheetsRouteMatch === null) return;

  const sheetIdFromRoute = _get(inappropriateSheetsRouteMatch, ['params', 'sheetId']);

  if (String(sheetIdFromRoute) === String(sheetId)) {
    sendSheetFinalDefectiveStateConfirmedNotification(sheetIdentity);
    dispatch(push(QUALITY_CONTROL_INAPPROPRIATE_SHEETS_ROUTE));
  }
};

/*
* Если находимся в разделе "Контроль. Окончательный брак", то очищаем все данные других интерфейсов кроме этого, где должны
* быть обновления из-за отметки окончательного брака, а для этого раздела перезапрашиваем данные списка для текущей
* страницы
* */
const _updateIfOnDefectiveSheetsInScreen = sheetId =>
    dispatch => {

      dispatch([
        clearTableRemoteData([
          SHEET_TYPE.INAPPROPRIATE,
          SHEET_TYPE.COMPLETED,
        ]),
        clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.INAPPROPRIATE, sheetId)),
      ]);

      dispatch(reFetchRemoteTableData(
        SHEET_TYPE.DEFECTIVE,
        SHEET_TYPE.DEFECTIVE,
        fetchSheetTypeRemoteTableDataCbFactory(
          dispatch,
          query => fetchDefectiveSheets(query, { isBlockingRequest: false }),
        ),
      ));
    };

/*
* Если находимся в разделе "Плановик. Завершённые", то очищаем все данные других интерфейсов кроме этого, где должны
* быть обновления из-за отметки окончательного брака, а для этого раздела перезапрашиваем данные списка для текущей
* страницы
* */
const _updateIfOnCompletedSheetsScreen = sheetId =>
  dispatch => {

    dispatch([
      clearTableRemoteData([
        SHEET_TYPE.INAPPROPRIATE,
        SHEET_TYPE.DEFECTIVE,
      ]),
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.INAPPROPRIATE, sheetId)),
    ]);

    dispatch(reFetchRemoteTableData(
      SHEET_TYPE.COMPLETED,
      SHEET_TYPE.COMPLETED,
      fetchSheetTypeRemoteTableDataCbFactory(
        dispatch,
        query => fetchCompletedSheets(query, { isBlockingRequest: false }),
      ),
    ));
  };