import { Trans } from '@lingui/macro';
import React from 'react';
import { COLUMN_TYPE } from '../../../constants/table';
import { initializeTableModelFields } from '../initializeTableModelFields';
import { TASK_VIEW_SCREEN_SCHEMA_FIELDS } from './taskViewScreenSchema';

export const TASKS_MODEL = 'tasks';

export const TASKS_SCHEMA_FIELDS = [
  {
    columnName: 'departmentIdentity',
    displayName: (
      <Trans id="worker_tasks.table_column@department_identity">
        ИД Подразделения
      </Trans>
    ),
    type: COLUMN_TYPE.STRING,
    show: false,
  },
  {
    columnName: 'departmentName',
    displayName: (
      <Trans id="worker_tasks.table_column@department_name">
        Подразделение
      </Trans>
    ),
    type: COLUMN_TYPE.STRING,
  },
  {
    columnName: 'equipmentClassIdentity',
    displayName: (
      <Trans id="worker_tasks.table_column@equipment_class_identity">
        ИД Класса РЦ
      </Trans>
    ),
    type: COLUMN_TYPE.STRING,
    show: false,
  },
  {
    columnName: 'equipmentClassName',
    displayName: (
      <Trans id="worker_tasks.table_column@equipment_class_name">
        Класс РЦ
      </Trans>
    ),
    type: COLUMN_TYPE.STRING,
  },
  ...TASK_VIEW_SCREEN_SCHEMA_FIELDS,
];

export const tasksSchema = {
  fields: initializeTableModelFields(TASKS_SCHEMA_FIELDS),
};
