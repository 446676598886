import { useCallback, useEffect, useRef } from 'react';
import _noop from 'lodash/noop';


/**
 * Хук для обработки сканера, который работает как клавиатура.
 * Ожидает ввода startKey, после чего начинает "запоминать" последовательность введенных символов
 * и заканчивает после ввода endKey (не включительно), после чего вызывается onScanned.
 * Если задержка между вводом символов больше delay, то "запоминание" прерывается и снова ожидается
 * ввод startKey.
 * @param startKey:
 *  {
 *    key: string,
 *    ctrlKey?: boolean,   // Будет ли зажат Ctrl при "нажатии" клавиши
 *    altKey?: boolean,    // Будет ли зажат Alt при "нажатии" клавиши
 *    shiftKey?: boolean,  // Будет ли зажат Shift при "нажатии" клавиши
 *  } - клавиша, которая идет перед самим штрихкодом
 * @param endKey: { key: string, ctrlKey?: boolean, altKey?: boolean, shiftKey?: boolean }
 *    - клавиша, которая идет после самого штрихкода
 * @param delay: number - максимальная задержка между символами при вводе штрихкода
 * @param onScanned: (barcode: string) => void - обработчик, вызываемый при завершении сканирования
 * @param barcodeRegex: Regex - регулярное выражение, определяющее, корректен ли введенный символ. Скорее всего,
 * обычно будет подходить значение по умолчанию, потому что основная цель - это не учитывать комбинацию нажатий
 * Shift + символ, Control + символ, Alt + символ, т.к. в этом случае в событии keydown приходит сначала
 * Shift\Control\Alt , а затем сам символ последовательно. При чём, например, для Shift, приходит и Shift, и, затем,
 * и заглавный символ, т.е. Shift тоже просто смело можно игнорировать
 */

const DEFAULT_START_KEY = {
  key: 'F6',
  ctrlKey: false,
  altKey: false,
  shiftKey: false,
};
const DEFAULT_END_KEY = {
  key: 'Enter',
  ctrlKey: false,
  altKey: false,
  shiftKey: false,
};

const DEFAULT_BARCODE_REGEX = /^[\w\s_]$/;

const DEFAULT_DELAY = 100;

export const useBarcodeScanner = ({
  startKey = DEFAULT_START_KEY,
  endKey = DEFAULT_END_KEY,
  delay = DEFAULT_DELAY,
  onScanned = _noop,
  barcodeRegex = DEFAULT_BARCODE_REGEX,
}) => {

  // Из-за асинхронности useState, при быстрых операциях он работает не очень
  const isReading = useRef(false);
  const barcode = useRef('');
  const isSubmitting = useRef(false);

  const timeoutId = useRef(null);

  const stopReadingCb = useCallback(
    () => {
      isReading.current = false;
      barcode.current = '';
      isSubmitting.current = false;
    },
    [],
  );

  const listenToKeyboard = e => {
    // Если идет чтение, то мы хотим отменить стандартное поведение клавиш, например Tab или Enter
    if (isReading.current) {
      e.preventDefault();
    }

    // Если идет обработка результатов, то ничего не делаем. По окончанию обработки флаг будет сброшен
    if (!!isSubmitting.current) {
      return;
    }


    // Всегда очищаем таймаут, т.к. было нажатие
    clearTimeout(timeoutId.current);


    /*
    * Пока не ввели символ старта считывания, игнорируем события.
    * Если ввели символ старта считывания, то устанавливаем флаг чтения, символ старта не учитываем в итоговой строке
    * Если повторно в режиме считывания будет нажат символ старта, то уже не считаем это новым стартом считывания, а
    * просто работаем, как с очередным символом. Режим считывания сбрасывается после финального символа, или по
    * таймауту
    * */
    if(!isReading.current) {

      if(checkIfKeyDown(e, startKey)) {
        isReading.current = true;
      }

      return;
    }

    /*
    * Введен символ окончания чтения - временно блокируем чтение, вызываем onScanned, который предполагается
    * промисом, с итоговой строкой в качестве параметра
    * Независимо от результата выполнения промиса затем сбрасываем блокировку, чтобы она не оставалась и в случае ошибки
    * Символ окончания не учитывается в итоговой строке
    * */
    if (checkIfKeyDown(e, endKey)) {

      if(barcode.current === '') {
        stopReadingCb();
        return;
      }

      isSubmitting.current = true;

      return onScanned(barcode.current)
        .finally(stopReadingCb);
    }


    /*
    * Валидация символов требуется, в основном, чтобы иметь возможность игнорировать служебные символы.
    * Подробнее в комментарии к хуку
    * */
    if (barcodeRegex.test(e.key)) {
      barcode.current += e.key;
    }

    /*
    * Проверку таймаута ввода символов, в целом, лучше  всегда ставить, т.к. это помогает от случайных нажатий
    * на обычной клавиатуре, а не сканере и, как следствие, спасает от неожиданного поведения и ошибок сканирования.
    * Но, на всякий случай, даём возможность задать delay null, чтобы, если это требуется, отключить проверку
    * таймаута ввода символов
    * */
    if(delay !== null) {
      timeoutId.current = setTimeout(stopReadingCb, delay);
    }
  };

  useEffect(
    () => {
      document.addEventListener('keydown', listenToKeyboard);

      return () => {
        clearTimeout(timeoutId.current);
        document.removeEventListener('keydown', listenToKeyboard);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
};

const checkIfKeyDown = (keyDownEvent, keyToCompare) => {
  const {
    key: keyDownEventKey,
    ctrlKey: keyDownEventCtrl,
    altKey: keyDownEventAlt,
    shiftKey: keyDownEventShift,
  } = keyDownEvent;

  const {
    key: keyToCompareKey,
    ctrlKey: keyToCompareCtrl = false,
    altKey: keyToCompareAlt = false,
    shiftKey: keyToCompareShift = false,
  } = keyToCompare;

  return (
    keyDownEventKey === keyToCompareKey &&
    keyDownEventCtrl === keyToCompareCtrl &&
    keyDownEventAlt === keyToCompareAlt &&
    keyDownEventShift === keyToCompareShift
  );
};
