import {
  QUALITY_CONTROL_INAPPROPRIATE_SHEETS_ROUTE,
} from '../../../constants/routes';
import { ENTITY_BATCH_TRANSACTION_TYPE, SHEET_TYPE } from '../../../constants/sheets';
import { Trans } from '@lingui/macro';
import {
  INAPPROPRIATE_SHEETS_FILTERS_SCHEMA,
} from '../../Sheets/sheetsFilters/sheetsFilters';
import { SheetsContainer } from '../../Sheets/SheetsContainer';
import React from 'react';
import { fetchInappropriateSheetData, fetchInappropriateSheets } from '../../../operations/sheets';
import { useSelector } from 'react-redux';
import { SimpleSummary } from '../../common/SimpleSummary/SimpleSummary';
import {
  InappropriateSheetsLabelTrans,
} from '../../../utils/commonTransComponents';
import { inappropriateSheetsRemoteTableDataSelector } from '../../../selectors/sheets';
import { MATERIAL_UI_STYLE_COLOR } from '../../../constants/materialUI';
import _get from 'lodash/get';
import { INAPPROPRIATE_SHEET_SECONDARY_SUMMARY_SCHEMA } from './constants';
import {
  InappropriateSheetReviewContentContainer,
} from './InappropriateSheetsReviewContent/InappropriateSheetReviewContentContainer';


const getInappropriateSheetAdditionalLabel = () => [{
  labelContent: InappropriateSheetsLabelTrans,
  labelStyle: MATERIAL_UI_STYLE_COLOR.PRIMARY,
  labelKey: 'withoutDefect',
}];


const renderSheetItemRightSideContent = sheetData => {

  const markedAsInappropriateEntityBatchTransactionEntity = _get(
    sheetData,
    [
      'entityBatchTransactionsByType',
      ENTITY_BATCH_TRANSACTION_TYPE.MARKED_AS_INAPPROPRIATE,
    ],
  );

  if (!markedAsInappropriateEntityBatchTransactionEntity) return null;

  const {
    userName,
    defectReason,
    entityBatchTransactionCreateStamp,
  } = markedAsInappropriateEntityBatchTransactionEntity;

  return (
    <SimpleSummary
        summaryData={{
          defectReason,
          entityBatchTransactionCreateStamp,
          userName,
        }}
        secondarySummarySchema={INAPPROPRIATE_SHEET_SECONDARY_SUMMARY_SCHEMA}
    />
  );
};

export const InappropriateSheets = () => {

  const sheetsData = useSelector(state => inappropriateSheetsRemoteTableDataSelector(
    state,
    { tableId: SHEET_TYPE.INAPPROPRIATE },
  ));

  return (
    <SheetsContainer
        className="inappropriate-sheets"
        mainRoutePath={QUALITY_CONTROL_INAPPROPRIATE_SHEETS_ROUTE}
        sheetsIdentity={SHEET_TYPE.INAPPROPRIATE}
        sheetItemIdProperty="sheetId"
        fetchSheetTypeEntitiesActionCreator={fetchInappropriateSheets}
        fetchSheetToReviewDataActionCreator={fetchInappropriateSheetData}
        sheetReviewContentComponent={InappropriateSheetReviewContentContainer}
        sheetsData={sheetsData}
        noDataText={
          <Trans id="defect_sheets@no_sheets_in_inappropriate_state">
            В данный момент нет ни одного маршрутного листа в статусе "несоответствующая продукция"
          </Trans>
        }
        filtersSchema={INAPPROPRIATE_SHEETS_FILTERS_SCHEMA}
        renderSheetItemRightSideContent={renderSheetItemRightSideContent}
        getSheetItemAdditionalInfoLabels={getInappropriateSheetAdditionalLabel}
    />
  );
};
