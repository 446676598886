export const SHEET_DATA_FROM_CA_ENTITY_TEMPLATE = {
  sheetNote: 'note',
  sheetIdentity: 'identity',
  entityBatchId: 'entityBatchId',
  startDate: 'startDate',
  stopDate: 'stopDate',
  isAssembly: 'isAssembly',
};

export const ENTITY_BATCH_DATA_FROM_CA_ENTITY_TEMPLATE = {
  entityBatchStatus: 'status',
  providingState: 'providingState',
  providableState: 'providableState',
  entityId: 'entityId',
  orderId: 'orderId',
  entitiesInBatchAmount: 'amount',
  fromState: 'fromState',
  defectState: 'defectState',
};

export const SHEET_OPERATION_DATA_FROM_CA_ENTITY_TEMPLATE = {
  sheetId: 'sheetId',
  sheetOperationId: 'id',
  sheetOperationIdentity: 'identity',
  operationStartDate: 'startDate',
  operationStopDate: 'stopDate',
  status: 'status',
  executorId: 'executorId',
  operationId: 'operationId',
  departmentId: 'departmentId',
  equipmentClassId: 'equipmentClassId',
  equipmentId: 'equipmentId',
  progress: 'progress',
  note: 'note',
  prodTime: 'prodTime',
};

export const DEPARTMENT_DATA_FROM_CA_ENTITY_TEMPLATE = {
  departmentIdentity: 'identity',
  departmentName: 'name',
};

export const EQUIPMENT_CLASS_DATA_FROM_CA_ENTITY_TEMPLATE = {
  equipmentClassIdentity: 'identity',
  equipmentClassName: 'name',
};

export const EQUIPMENT_DATA_FROM_CA_ENTITY_TEMPLATE = {
  equipmentIdentity: 'identity',
  equipmentName: 'name',
};

export const ENTITY_MODEL_DATA_FROM_CA_ENTITY_TEMPLATE = {
  entityIdentity: 'identity',
  entityCode: 'code',
  entityName: 'name',
};

export const ORDER_DATA_FROM_CA_ENTITY_TEMPLATE = {
  orderName: 'name',
  orderPriority: 'priority',
};

export const OPERATION_DATA_FROM_CA_ENTITY_TEMPLATE = {
  operationIdentity: 'identity',
  operationNumber: 'nop',
  operationName: 'name',
  isAssembly: 'isAssembly',
};

export const ENTITY_BATCH_TRANSACTION_DATA_FROM_CA_TEMPLATE = {
  entityBatchTransactionCreateStamp: 'createStamp',
  transactionType: 'type',
  defectReason: 'note',
};


export const DEPARTMENT_DATA_FROM_IA_ENTITY_TEMPLATE = DEPARTMENT_DATA_FROM_CA_ENTITY_TEMPLATE;

export const EQUIPMENT_CLASS_DATA_FROM_IA_ENTITY_TEMPLATE = EQUIPMENT_CLASS_DATA_FROM_CA_ENTITY_TEMPLATE;

export const ORDER_DATA_FROM_IA_ENTITY_TEMPLATE = ORDER_DATA_FROM_CA_ENTITY_TEMPLATE;

export const OPERATION_DATA_FROM_IA_ENTITY_TEMPLATE = {
  ...OPERATION_DATA_FROM_CA_ENTITY_TEMPLATE,
  departmentId: 'departmentId',
  equipmentClassId: 'equipmentClassId',
  entityRouteId: 'entityRouteId',
  prodTime: 'prodTime',
};

export const SIMULATION_OPERATION_TASK_DATA_FROM_IA_ENTITY_TEMPLATE = {
  operationStartTime: 'startTime',
  operationStartDate: 'startDate',
  operationStopDate: 'stopDate',
  operationId: 'operationId',
  entityBatchId: 'simulationEntityBatchId',
  entityBatchAmountForTask: 'entityAmount',
  subTaskIndex: 'index',
  totalSubTaskAmount: 'of',
  operationType: 'type',
};
