import React from 'react';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { TEXT_FIELD_TOGGLE_BUTTON_GROUP_PROP_TYPES } from './constants';
import './style.css';

export const TextFieldToggleButtonGroup = props => {
  const {
    value,
    onChange,
    toggleButtonsSchema,
  } = props;


  return (
    <ToggleButtonGroup
        value={value}
        exclusive
        onChange={onChange}
        className="text-field-toggle-button-group"
    >
      {
        toggleButtonsSchema.map(({ id, value, label, disabled = false }) => (
          <ToggleButton
              key={id}
              value={value}
              disabled={disabled}
              className="text-field-toggle-button-group__toggle-button"
          >
            {label}
          </ToggleButton>
        ))
      }
    </ToggleButtonGroup>
  );
};

TextFieldToggleButtonGroup.propTypes = TEXT_FIELD_TOGGLE_BUTTON_GROUP_PROP_TYPES;