import {
  fetchPartAndMaterialsReserveDataForAssemblyEntityBatch,
  fetchPossiblePartsAndMaterialsToConsumeForDefaultSheet,
  fetchSheetsInProduction,
  fetchDefaultSheetsWithEnoughPartsAndMaterialsInStorage,
  fetchInappropriateSheets,
  fetchCompletedSheets,
} from '../../../../operations/sheets';
import { ENTITY_BATCH_PROVIDING_STATE, SHEET_TYPE } from '../../../../constants/sheets';
import {
  fetchEquipmentClassInDepartmentTasksRemoteTableData,
  fetchTasksRemoteTableData,
} from '../../../../operations/tasks';
import { allTasksTablesIdsSelector } from '../../../../selectors/taskView';
import { routerPathnameSelector } from '../../../../reducers/router/selectors';
import { matchPath } from 'react-router-dom';
import {
  MASTER_APP_COMPLETED_TASKS_ROUTE,
  MASTER_APP_TASKS_TO_DO_ROUTE, PLANNER_APP_COMPLETED_SHEETS_ROUTE,
  PLANNER_APP_ORDERS_IN_PRODUCTION_ROUTE,
  PLANNER_APP_ORDERS_READY_TO_COMPLETE_ROUTE,
  PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE, QUALITY_CONTROL_INAPPROPRIATE_SHEETS_ROUTE,
  STORAGE_MANAGEMENT_APP_ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
  STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
  WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE,
  WORKER_APP_TASKS_ROUTE,
} from '../../../../constants/routes';
import { clearTableData, clearTableRemoteData, reFetchRemoteTableData } from '../../../../reducers/table/actions';
import {
  createOrderEntriesTableId,
  createSheetTypeOperationsTableId,
  getEquipmentClassInDepartmentTasksTableId,
} from '../../../../utils/tables';
import {
  addEntitiesToStore,
  deleteEntitiesFromStore,
  fetchEntitiesFromServer,
} from '../../../../reducers/entities/actions';
import { ENTITY_BATCH_MODEL, SHEET_MODEL } from '../../../../constants/models';
import {
  clearAllDefaultSheetsPartsAndMaterialsToConsume,
  clearDefaultSheetPartsAndMaterialsToConsume,
} from '../../../../reducers/storageManagementApp/defaultSheets/actions';
import {
  fetchSheetTypeRemoteTableDataCbFactory,
  SHEETS_REMOTE_TABLE_DATA_WITH_PARAMS,
} from '../../../../components/Sheets/SheetsContainer';
import { push } from 'connected-react-router';
import { getMasterAppDepartmentIdsFromRouteParam } from '../../../../components/MasterApp/MasterWorkspace/masterAppDepartmentIdsRouteParam';
import { MASTER_TASKS_TO_DO_TABLE_ID } from '../../../../components/MasterApp/MasterTasksToDo/constants';
import { MASTER_COMPLETED_TASKS_TABLE_ID } from '../../../../components/MasterApp/MasterCompletedTasks/constants';
import {
  fetchMasterDepartmentsCompletedTasksRemoteTableData,
  fetchMasterDepartmentsTasksToDoRemoteTableData,
} from '../../../../operations/masterWorkspace/index';
import { masterTasksToDoAdditionalFiltersSelector } from '../../../../reducers/masterApp/tasksToDoAdditionalFilters/selectors';
import {
  fetchOrdersInProduction,
  fetchOrdersReadyToComplete,
  sendOrderIsReadyToCompleteNotification,
} from '../../../../operations/orders';
import { ORDER_IN_PRODUCTION_AND_READY_TO_COMPLETE_TABLES_IDS, ORDER_TYPE } from '../../../../constants/orders';
import { fetchOrderRemoteTableDataCbFactory } from '../../../../components/Orders/OrdersContainer';
import { fetchFullAssemblySheetsPartsAndMaterials } from '../../../../components/StorageManagementApp/SheetsWaitingPartsAndMaterials/AssemblySheetsWaitingPartsAndMaterials/AssemblySheetsWaitingPartsAndMaterials';
import {
  deleteAllAssemblySheetsReserveData,
  deleteAssemblySheetReserveData,
} from '../../../../reducers/storageManagementApp/assemblySheets/reserveData/actions';
import { deleteAssemblySheetConsumeData } from '../../../../reducers/workerApp/assemblySheets/consumeData/actions';
import { FILTER_GROUP_TYPES, FILTER_TYPES } from '../../../restCollectionApi/index';
import { DEFECT_MARKING_USE_CASES, getDefectMarkingUseCase } from '../../../../utils/sheets';
import { TASKS_MODEL } from '../../../../reducers/schemaModel/models/tasksSchema';
import {
  MASTER_COMPLETED_TASKS_TABLE_MODEL,
  MASTER_TASKS_TO_DO_TABLE_MODEL,
} from '../../../../reducers/schemaModel/models/masterTasksSchema';

/*
* Подробно о влиянии события фиксации брака описано в комментарии к handleDefectiveEntitiesMarked в файле
* SheetInProductionReviewContentContainer.
*
* ВАЖНО! Для экранов МЛ в производстве у Плановика и экранов задний у Мастера и у Рабочего, для общего интерфейса
* просмотра операции принято решение не делать в случае с браком каких-то сложных обработок, которые касаются
* внутренних модальников и потребления, например, как в обработчике деления партии с isConsumeEntitiesDialogOpen,
* несмотря на то, что в определенных кейсах партия при фиксации в браке тоже остается в работе, но делится и
* количество изменяется. Решено реализовать простейшую обработку в локальном обработчике экрана просмотра операции МЛ -
* закрывать модальник с этим экраном и выводит оповещение, что по Мл зафиксирован брак.
* Это было сделано так, потому что было обнаружено, что даже с более сложной обработкой в случае с событием деления
* партии, экран просмотра МЛ не совсем корректно обновляется при делении партии, как минимум, значение количества
* ДСЕ в партии не изменяется. При этом есть понимание, что надо переносить экран просмотра операции МЛ, на отдельный
* роут, где многие проблемы должны решиться. Поэтому, предполагается, что после рефакторинга и переноса на отдельный
* роут, эти обработки будут рассмотрены повторно, а сейчас здесь более детально прорабатывать и не имеет смысла.
* */
export const handleDefectiveEntitiesMarked = message =>
  (dispatch, getState) => {

    const {
      sheetOperationStatus,
      sheetId,
      wasEntityBatchSplit,
      wasEntityBatchFinished,
    } = message;

    const state = getState();

    const allTasksTablesIds = allTasksTablesIdsSelector(state);

    const currentPathname = routerPathnameSelector(state);

    // вычисляем по какому из возможных 5 сценариев произошел вызов события фиксации брака
    const defectMarkingUseCase = getDefectMarkingUseCase(sheetOperationStatus, wasEntityBatchSplit, wasEntityBatchFinished);

    //Если находимся в разделе "Плановик. МЛ в производстве"
    const sheetsInProductionRouteMatch = matchPath(currentPathname, {
      path: PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE,
    });
    if(sheetsInProductionRouteMatch !== null)
      return dispatch(_updateIfOnSheetsInProductionScreen(
        defectMarkingUseCase,
        message,
        allTasksTablesIds,
        currentPathname,
      ));


    //Если находимся в разделе "Плановик. Завершенные МЛ"
    const completedSheetsRouteMatch = matchPath(currentPathname, {
      path: PLANNER_APP_COMPLETED_SHEETS_ROUTE,
    });
    if(completedSheetsRouteMatch !== null)
      return dispatch(_updateIfOnCompletedSheetsScreen(
        defectMarkingUseCase,
        message,
        allTasksTablesIds,
      ));

    //Если находимся в разделе "Плановик. Заказы. В производстве"
    const ordersInProductionRouteMatch = matchPath(currentPathname, {
      path: PLANNER_APP_ORDERS_IN_PRODUCTION_ROUTE,
    });
    if (ordersInProductionRouteMatch !== null)
      return dispatch(_updateIfOnOrdersInProductionScreen(
        defectMarkingUseCase,
        message,
        allTasksTablesIds,
        currentPathname,
      ));

    //Если находимся в разделе "Плановик. Заказы. Готовые к завершению"
    const ordersReadyToCompleteRouteMatch = matchPath(currentPathname, {
      path: PLANNER_APP_ORDERS_READY_TO_COMPLETE_ROUTE,
    });
    if (ordersReadyToCompleteRouteMatch !== null)
      return dispatch(_updateIfOnOrdersReadyToCompleteScreen(
        defectMarkingUseCase,
        message,
        allTasksTablesIds,
      ));

    //Если находимся в разделе "Комплектование - "стандартные" неукомплектованные маршрутные листы"
    const defaultSheetsWaitingPartsAndMaterialsRouteMatch = matchPath(currentPathname, {
      path: STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
    });
    if(defaultSheetsWaitingPartsAndMaterialsRouteMatch !== null)
      return dispatch(_updateIfOnDefaultSheetsWaitingPartsAndMaterialsScreen(
        defectMarkingUseCase,
        message,
        allTasksTablesIds,
        currentPathname,
      ));

    //Если находимся в разделе "Комплектование" - "сборки"
    const assemblySheetsWaitingPartsAndMaterialsRouteMatch = matchPath(currentPathname, {
      path: STORAGE_MANAGEMENT_APP_ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
    });
    if (assemblySheetsWaitingPartsAndMaterialsRouteMatch !== null) {
      return dispatch(_updateIfOnAssemblySheetsWaitingPartsAndMaterialsScreen(
        defectMarkingUseCase,
        message,
        allTasksTablesIds,
        currentPathname,
      ));
    }

    //Если находимся  в разделе "Мастер. Требуется выполнить"
    const masterTasksTodoRouteMatch = matchPath(currentPathname, {
      path: MASTER_APP_TASKS_TO_DO_ROUTE,
    });
    if(masterTasksTodoRouteMatch !== null)
      return dispatch(_updateIfOnMasterTasksTodoScreen(
        defectMarkingUseCase,
        message,
        allTasksTablesIds,
        getMasterAppDepartmentIdsFromRouteParam(masterTasksTodoRouteMatch.params.departmentIds),
      ));

    //Если находимся  в разделе "Мастер. Завершенные"
    const masterCompletedTasksRouteMatch = matchPath(currentPathname, {
      path: MASTER_APP_COMPLETED_TASKS_ROUTE,
    });
    if(masterCompletedTasksRouteMatch !== null)
      return dispatch(_updateIfOnMasterCompletedTasksScreen(
        defectMarkingUseCase,
        message,
        allTasksTablesIds,
        getMasterAppDepartmentIdsFromRouteParam(masterCompletedTasksRouteMatch.params.departmentIds),
      ));

    //Если находимся в разделе "Рабочий. Просмотр задания для класса РЦ в подразделении"
    const tasksForEquipmentClassInDepartmentRouteMatch = matchPath(currentPathname, {
      path: `${WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE}/:departmentId/:equipmentClassId`,
    });
    if(tasksForEquipmentClassInDepartmentRouteMatch !== null) {
      const {
        departmentId,
        equipmentClassId,
      } = tasksForEquipmentClassInDepartmentRouteMatch.params;

      return dispatch(_updateIfOnTasksViewScreen(
        defectMarkingUseCase,
        message,
        allTasksTablesIds,
        departmentId,
        equipmentClassId,
      ));
    }

    //Если находимся в разделе "Рабочий. Просмотр ВСЕХ заданий"
    const workerAppTasksRouteMatch = matchPath(currentPathname, {
      path: WORKER_APP_TASKS_ROUTE,
    });
    if(workerAppTasksRouteMatch !== null) {
      return dispatch(_updateIfOnWorkerAppTasksScreen(
        defectMarkingUseCase,
        message,
        allTasksTablesIds,
      ));
    }

    //Если находимся в разделе "Контроль. Несоответствующая продукция"
    const inappropriateSheetsRouteMatch = matchPath(currentPathname, {
      path: QUALITY_CONTROL_INAPPROPRIATE_SHEETS_ROUTE,
    });
    if (inappropriateSheetsRouteMatch !== null)
      return dispatch(_updateIfOnInappropriateSheetsScreen(
        defectMarkingUseCase,
        message,
        allTasksTablesIds,
      ));

    /*
    * Для всех остальных разделов не нужна какая-то дополнительная обработка, но и для этого случая необходимо
    * очистить данные всех интерфейсов, где должны быть обновления из-за отметки брака МЛ, чтобы при следующем
    * входе в эти разделы данные были запрошены заново
    * */

    const defaultCustomActions = _getDefaultCustomActions(message, defectMarkingUseCase);

    dispatch([
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId)),

      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        SHEET_TYPE.INAPPROPRIATE,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        MASTER_TASKS_TO_DO_TABLE_ID,
        MASTER_COMPLETED_TASKS_TABLE_ID,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),

      deleteAssemblySheetConsumeData({ sheetId }),

      ...defaultCustomActions,
    ]);

  };


const _getDefaultCustomActions = (message, defectMarkingUseCase) => {
  const {
    sheetId,
    completedOrderId,
    completedOrderName,
  } = message;

  /*
  * Список экшенов для кейсов, для которых нужна кастомная, а не универсальная обработка
  * */
  let customActions = [];

  if(
    defectMarkingUseCase === DEFECT_MARKING_USE_CASES.PART_OF_ENTITY_BATCH_ON_LAST_OPERATION_FINISH &&
    completedOrderId !== null
  ) {
    //Выводим нотификейшен в случае, когда завершение части МЛ означает также и готовность заказа
    sendOrderIsReadyToCompleteNotification(completedOrderName);

    customActions.push(
      clearTableRemoteData(ORDER_IN_PRODUCTION_AND_READY_TO_COMPLETE_TABLES_IDS),
      clearTableData(createOrderEntriesTableId(ORDER_TYPE.IN_PRODUCTION, completedOrderId)),
    );
  }

  if(
    defectMarkingUseCase === DEFECT_MARKING_USE_CASES.WHOLE_ENTITY_BATCH_ON_OPERATION_FINISH ||
    defectMarkingUseCase === DEFECT_MARKING_USE_CASES.WHOLE_ENTITY_BATCH_ON_OPERATION_PAUSE ||
    defectMarkingUseCase === DEFECT_MARKING_USE_CASES.PART_OF_ENTITY_BATCH_ON_LAST_OPERATION_FINISH
  ) {
    customActions.push(
      deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
    );
  }

  if(defectMarkingUseCase === DEFECT_MARKING_USE_CASES.PART_OF_ENTITY_BATCH_ON_LAST_OPERATION_FINISH) {
    customActions.push(
      clearAllDefaultSheetsPartsAndMaterialsToConsume(),
      deleteAllAssemblySheetsReserveData(),
      clearTableRemoteData([
        SHEET_TYPE.COMPLETED,
      ]),
    );
  }else{
    customActions.push(
      clearDefaultSheetPartsAndMaterialsToConsume(sheetId),
      deleteAssemblySheetReserveData({ sheetId }),
    );
  }

  return customActions;
};


/*
* Если находимся в разделе "Плановик. МЛ в производстве", то очищаем все данные других интерфейсов кроме этого, где
* должны быть обновления из-за фиксации брака, а для этого раздела перезапрашиваем данные списка для текущей
* страницы.
* Дополнительно, если находимся в режиме просмотра МЛ, по которому только что фиксировался брак, то
* редиректим пользователя на на экран просмотра списка МЛ (подробности в комментарии к коду редиректа, возможно,
* в будущем он будет изменен)
* */
const _updateIfOnSheetsInProductionScreen = (defectMarkingUseCase, message, allTasksTablesIds, currentPathname) =>
  dispatch => {

    const {
      sheetId,
    } = message;

    const defaultCustomActions = _getDefaultCustomActions(message, defectMarkingUseCase);

    dispatch([
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId)),

      clearTableRemoteData([
        SHEET_TYPE.INAPPROPRIATE,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        MASTER_TASKS_TO_DO_TABLE_ID,
        MASTER_COMPLETED_TASKS_TABLE_ID,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),

      deleteAssemblySheetConsumeData({ sheetId }),

      ...defaultCustomActions,
    ]);

    dispatch(reFetchRemoteTableData(
      SHEET_TYPE.IN_PRODUCTION,
      SHEET_TYPE.IN_PRODUCTION,
      fetchSheetTypeRemoteTableDataCbFactory(
        dispatch,
        query => fetchSheetsInProduction(query, { isBlockingRequest: false }),
      ),
    ));

    const sheetInProductionReviewRouteMatch = matchPath(currentPathname, {
      path: `${PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE}/:sheetId`,
    });

    /*
     * Если мы просматриваем МЛ, по которому отмечается брак, то в случае кейсов 1, 2, 4, он становится завершенным и
     * просматривать его больше нельзя, логично сделать редирект. В случае кейсов 3 и 5, МЛ остается в производстве,
     * но у него изменяется количество и статусы операций. Т.к. обновить промежуточный стейт, в котором записано
     * количество партий трудоёмко, то, по крайней мере пока, делаем аналогично редирект, при повторном входе,
     * информация обновится. Также, решение не заморачиваться  с дополнительной обработкой во всех описанных случаях
     * связано с тем, что при просмотре текущего МЛ, по которому фиксируется брак, может быть открыто множество
     * внутренних модальных окон: отметка по операции другим пользователем, фиксация брака другим пользователем,
     * потреблением на сборочной операции другим пользователем и т.д. Проще всего просто выполнить редирект, чтобы не
     * было конфликта данных и все возможные локальные данные сбросились, а модальники закрылись, очистка всех кэшей
     * уже сделана выше.
     * */
    if(
      sheetInProductionReviewRouteMatch !== null &&
      sheetInProductionReviewRouteMatch.params.sheetId === sheetId.toString()
    ) {
      dispatch(push(PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE));
    }
  };


const _updateIfOnOrdersInProductionScreen = (defectMarkingUseCase, message, allTasksTablesIds, currentPathname) =>
  dispatch => {

    const {
      sheetId,
      completedOrderId,
      completedOrderName,
    } = message;

    let customActions = [];

    if(
      defectMarkingUseCase === DEFECT_MARKING_USE_CASES.WHOLE_ENTITY_BATCH_ON_OPERATION_FINISH ||
      defectMarkingUseCase === DEFECT_MARKING_USE_CASES.WHOLE_ENTITY_BATCH_ON_OPERATION_PAUSE ||
      defectMarkingUseCase === DEFECT_MARKING_USE_CASES.PART_OF_ENTITY_BATCH_ON_LAST_OPERATION_FINISH
    ) {
      customActions.push(
        deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
      );
    }

    if(defectMarkingUseCase === DEFECT_MARKING_USE_CASES.PART_OF_ENTITY_BATCH_ON_LAST_OPERATION_FINISH) {
      customActions.push(
        clearAllDefaultSheetsPartsAndMaterialsToConsume(),
        deleteAllAssemblySheetsReserveData(),
      );
    }else{
      customActions.push(
        clearDefaultSheetPartsAndMaterialsToConsume(sheetId),
        deleteAssemblySheetReserveData({ sheetId }),
      );
    }

    dispatch([
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId)),

      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        SHEET_TYPE.INAPPROPRIATE,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        MASTER_TASKS_TO_DO_TABLE_ID,
        MASTER_COMPLETED_TASKS_TABLE_ID,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),

      deleteAssemblySheetConsumeData({ sheetId }),

      ...customActions,
    ]);

    /*
    * Для случая, когда это не кейс с завершением части партии при завершении последней операции, или если это
    * именно такой кейс, но заказ не был завершен (в этом случае completedOrderId === null) ничего больше
    * делать не нужно
    * */
    if(
      defectMarkingUseCase !== DEFECT_MARKING_USE_CASES.PART_OF_ENTITY_BATCH_ON_LAST_OPERATION_FINISH  ||
      completedOrderId === null
    ) return;

    // если же заказ завершился, то перезапрашиваем данные таблицы заказов в производстве
    dispatch(reFetchRemoteTableData(
      ORDER_TYPE.IN_PRODUCTION,
      ORDER_TYPE.IN_PRODUCTION,
      fetchOrderRemoteTableDataCbFactory(
        dispatch,
        query => fetchOrdersInProduction(query, { isBlockingRequest: false }),
      ),
    ));

    // вызываем нотификейшн с предупреждением о том, что заказ готов к завершению
    sendOrderIsReadyToCompleteNotification(completedOrderName);

    dispatch([
      // сбрасываем кэш таблицы заказов готовых к завершению
      clearTableRemoteData([ORDER_TYPE.READY_TO_COMPLETE]),
      // сбрасываем кэш таблицы просмотра заказа в списке заказов в производстве
      clearTableData(createOrderEntriesTableId(ORDER_TYPE.IN_PRODUCTION, completedOrderId)),
    ]);

    /*
    * Для случая, когда находимся на роуте просмотра заказа, который изменил свой статус на "готов к завершению",
    * выполняем редирект к списку заказов в производстве
    * */
    const plannerAppOrderInProductionReviewRouteMatch = matchPath(currentPathname, {
      path: `${PLANNER_APP_ORDERS_IN_PRODUCTION_ROUTE}/:orderId`,
    });

    if(
      plannerAppOrderInProductionReviewRouteMatch !== null &&
      plannerAppOrderInProductionReviewRouteMatch.params.orderId === completedOrderId.toString()
    ) {
      return dispatch(push(PLANNER_APP_ORDERS_IN_PRODUCTION_ROUTE));
    }
  };

/*
* Если находимся в разделе "Плановик.  Завершенные МЛ, то очищаем все данные других интерфейсов кроме этого, где должны
* быть обновления из-за фиксации брака по партии МЛ, а для этого раздела перезапрашиваем данные списка для текущей
* страницы, если брак был отмечен для части партии при завершении последней операции
* */
const _updateIfOnCompletedSheetsScreen = (
  defectMarkingUseCase,
  message,
  allTasksTablesIds,
) =>
  dispatch => {

    const {
      sheetId,
      completedOrderId,
      completedOrderName,
    } = message;

    let customActions = [];

    if(
      defectMarkingUseCase === DEFECT_MARKING_USE_CASES.PART_OF_ENTITY_BATCH_ON_LAST_OPERATION_FINISH &&
      completedOrderId !== null
    ) {
      //Выводим нотификейшен в случае, когда завершение части МЛ означает также и готовность заказа
      sendOrderIsReadyToCompleteNotification(completedOrderName);

      customActions.push(
        clearTableRemoteData(ORDER_IN_PRODUCTION_AND_READY_TO_COMPLETE_TABLES_IDS),
        clearTableData(createOrderEntriesTableId(ORDER_TYPE.IN_PRODUCTION, completedOrderId)),
      );
    }

    if(
      defectMarkingUseCase === DEFECT_MARKING_USE_CASES.WHOLE_ENTITY_BATCH_ON_OPERATION_FINISH ||
      defectMarkingUseCase === DEFECT_MARKING_USE_CASES.WHOLE_ENTITY_BATCH_ON_OPERATION_PAUSE ||
      defectMarkingUseCase === DEFECT_MARKING_USE_CASES.PART_OF_ENTITY_BATCH_ON_LAST_OPERATION_FINISH
    ) {
      customActions.push(
        deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
      );
    }

    /*
    Содержимое таблицы завершённые МЛ может измениться только для 4 кейса, когда брак был отмечен при завершении
    последней операции для части партии. В этом случае появляется завершённый МЛ и МЛ в статусе
    "Несоответствующая продукция"
    */
    if(defectMarkingUseCase === DEFECT_MARKING_USE_CASES.PART_OF_ENTITY_BATCH_ON_LAST_OPERATION_FINISH) {
      dispatch(reFetchRemoteTableData(
        SHEET_TYPE.COMPLETED,
        SHEET_TYPE.COMPLETED,
        fetchSheetTypeRemoteTableDataCbFactory(
          dispatch,
          query => fetchCompletedSheets(query, { isBlockingRequest: false }),
        ),
      ));

      customActions.push(
        clearAllDefaultSheetsPartsAndMaterialsToConsume(),
        deleteAllAssemblySheetsReserveData(),
      );
    }else{
      customActions.push(
        clearDefaultSheetPartsAndMaterialsToConsume(sheetId),
        deleteAssemblySheetReserveData({ sheetId }),
      );
    }


    dispatch([
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId)),

      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        SHEET_TYPE.INAPPROPRIATE,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        MASTER_TASKS_TO_DO_TABLE_ID,
        MASTER_COMPLETED_TASKS_TABLE_ID,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),

      deleteAssemblySheetConsumeData({ sheetId }),

      ...customActions,
    ]);

  };

const _updateIfOnOrdersReadyToCompleteScreen = (defectMarkingUseCase, message, allTasksTablesIds) =>
  dispatch => {

    const {
      sheetId,
      completedOrderId,
      completedOrderName,
    } = message;

    let customActions = [];

    if(
      defectMarkingUseCase === DEFECT_MARKING_USE_CASES.WHOLE_ENTITY_BATCH_ON_OPERATION_FINISH ||
      defectMarkingUseCase === DEFECT_MARKING_USE_CASES.WHOLE_ENTITY_BATCH_ON_OPERATION_PAUSE ||
      defectMarkingUseCase === DEFECT_MARKING_USE_CASES.PART_OF_ENTITY_BATCH_ON_LAST_OPERATION_FINISH
    ) {
      customActions.push(
        deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
      );
    }

    if(defectMarkingUseCase === DEFECT_MARKING_USE_CASES.PART_OF_ENTITY_BATCH_ON_LAST_OPERATION_FINISH) {
      customActions.push(
        clearAllDefaultSheetsPartsAndMaterialsToConsume(),
        deleteAllAssemblySheetsReserveData(),
      );
    }else{
      customActions.push(
        clearDefaultSheetPartsAndMaterialsToConsume(sheetId),
        deleteAssemblySheetReserveData({ sheetId }),
      );
    }

    dispatch([
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId)),

      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        SHEET_TYPE.INAPPROPRIATE,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        MASTER_TASKS_TO_DO_TABLE_ID,
        MASTER_COMPLETED_TASKS_TABLE_ID,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),

      deleteAssemblySheetConsumeData({ sheetId }),

      ...customActions,
    ]);


    /*
    * Для случая, когда это не кейс с завершением части партии при завершении последней операции, или если это
    * именно такой кейс, но заказ не был завершен (в этом случае completedOrderId === null) ничего больше
    * делать не нужно
    * */
    if(
      defectMarkingUseCase !== DEFECT_MARKING_USE_CASES.PART_OF_ENTITY_BATCH_ON_LAST_OPERATION_FINISH  ||
      completedOrderId === null
    ) return;


    // если же заказ завершился, то перезапрашиваем данные таблицы заказов, готовых к завершению
    dispatch(reFetchRemoteTableData(
      ORDER_TYPE.READY_TO_COMPLETE,
      ORDER_TYPE.READY_TO_COMPLETE,
      fetchOrderRemoteTableDataCbFactory(
        dispatch,
        query => fetchOrdersReadyToComplete(query, { isBlockingRequest: false }),
      ),
    ));

    // вызываем нотификейшн с предупреждением о том, что заказ готов к завершению
    sendOrderIsReadyToCompleteNotification(completedOrderName);

    dispatch([
      // сбрасываем кэш таблицы заказов в производстве
      clearTableRemoteData([ORDER_TYPE.IN_PRODUCTION]),
      // сбрасываем кэш таблицы просмотра заказа в списке заказов в производстве
      clearTableData(createOrderEntriesTableId(ORDER_TYPE.IN_PRODUCTION, completedOrderId)),
    ]);

  };


const _updateIfOnDefaultSheetsWaitingPartsAndMaterialsScreen = (
  defectMarkingUseCase,
  message,
  allTasksTablesIds,
  currentPathname,
) => dispatch => {

  const {
    sheetId,
  } = message;

  /*
  * Для экрана стандартных МЛ, используем дефолтные кастомные экшены, несмотря на то, что они затрагивают логику
  * этого экрана:
  *  - в случае кейсов 1, 2, 4, нужны экшены по очистке entities, т.к. МЛ пропадает из списка. Перезапрос данных по МЛ,
  *  чтобы обновить количества для остальных кейсов 3 и 5, будет сделан далее дополнительно к этой обработке для этого
  * экрана.
  *  - в случае кейса 4 нужно сбросить детальную информацию о комплектации, т.к. происходит пополнение склада и все
  * данные становятся неактульными. В случае кейсов, 1, 2, 3, 5 нужно сбросить детальную информацию только по МЛ, по
  * которому была фиксация брака, т.к. МЛ или пропадает из списка (кейсы, 1 и 2), или изменяет своё количество, и
  * детальная комплектация становится неактуальной (кейсы 3 и 5). Дополнительно к этому далее будет делаться редирект,
  * если сейчас идёт просмотр МЛ, по которому фиксировали брак для любого из кейсов, чтобы при повторном входе
  * детальная комплектация повторно запросилась. Если идёт просмотр другого МЛ и сейчас кейс 4 с пополнением склада, то
  * сразу перезапрашиваем детальную комплектацию для МЛ из роута без редиректа
  * */
  const defaultCustomActions = _getDefaultCustomActions(message, defectMarkingUseCase);

  dispatch([
    clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId)),

    clearTableRemoteData([
      SHEET_TYPE.IN_PRODUCTION,
      SHEET_TYPE.INAPPROPRIATE,
      SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      MASTER_TASKS_TO_DO_TABLE_ID,
      MASTER_COMPLETED_TASKS_TABLE_ID,
      TASKS_MODEL,
      ...allTasksTablesIds,
    ]),

    deleteAssemblySheetConsumeData({ sheetId }),

    ...defaultCustomActions,
  ]);

  /*
  * Пока принято, что это запрос  на стандартные МЛ, которые можно укомплектовать, выполняется для любого из кейсов,
  * потому что МЛ или пропадают из списка и данные по ним не нужны, или меняют количество и данные по ним нужно
  * обновить, или же пополняется склад (кейс 4), тогда все эти данные могут быть неактуальны
  * */
  dispatch(fetchDefaultSheetsWithEnoughPartsAndMaterialsInStorage({ isBlockingRequest: false }));

  const defaultSheetWaitingPartsAndMaterialsReviewRouteMatch = matchPath(currentPathname, {
    path: `${STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE}/:sheetId`,
  });

  if(defaultSheetWaitingPartsAndMaterialsReviewRouteMatch !== null) {

    const {
      sheetId: sheetIdFromRoute,
    } = defaultSheetWaitingPartsAndMaterialsReviewRouteMatch.params;

    /*
    * Cм. комментарий к вызову _getDefaultCustomActions в этом обработчике выше, чтобы понять, почему редирект
    * выполняется всегда, если просматривается МЛ, по которому фиксировали брак.
    * */
    if(sheetIdFromRoute === sheetId.toString()) {

      dispatch(push(STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE));

    } else if (defectMarkingUseCase === DEFECT_MARKING_USE_CASES.PART_OF_ENTITY_BATCH_ON_LAST_OPERATION_FINISH) {


      /*
      * Если находимся при просмотре МЛ, не по которому фиксировался брак, и это кейс 4, когда было пополнение
      * склада, то нужно перезапросить данные по детальной комплектации для sheetIdFromRoute, т.к. мы её сбросили
      * в дефолтных кастомных экшенах выше
      * */
      const query = {
        filter: {
          filterGroupType: FILTER_GROUP_TYPES.AND,
          filters: [
            {
              column: 'id',
              filterType: FILTER_TYPES.EQUALS,
              filterValue: sheetIdFromRoute,
            },
          ],
        },
        with: [],
      };

      dispatch(fetchSheetsInProduction(query, { isBlockingRequest: false }))
        .then(response => {

          if(!!response && response.responseMeta.count !== 0) {
            const { entityBatchId } = response.entities[SHEET_MODEL][sheetIdFromRoute];

            dispatch(fetchPossiblePartsAndMaterialsToConsumeForDefaultSheet(
              entityBatchId,
              sheetIdFromRoute,
              { isBlockingRequest: false },
            ));
          }

        });
    }
  }

  /*
  * Для кейсов 3 и 5, надо перезапросить МЛ в entities, чтобы обновить данные по количеству. Важно проверить при
  * запросе, что МЛ стандартный и неукомплектован перед записью в entities, т.к. только такие МЛ отображаются в
  * списке на этом экране
  * */
  if(
    defectMarkingUseCase === DEFECT_MARKING_USE_CASES.PART_OF_ENTITY_BATCH_ON_OPERATION_FINISH ||
    defectMarkingUseCase === DEFECT_MARKING_USE_CASES.PART_OF_ENTITY_BATCH_ON_OPERATION_PAUSE
  ) {

    const query = {
      filter: {
        filterGroupType: FILTER_GROUP_TYPES.AND,
        filters: [
          {
            column: 'id',
            filterType: FILTER_TYPES.EQUALS,
            filterValue: sheetId,
          },
        ],
      },
      with: SHEETS_REMOTE_TABLE_DATA_WITH_PARAMS,
    };

    dispatch(fetchSheetsInProduction(query, { isBlockingRequest: false }))
      .then(response => {

        if(!response || response.responseMeta.count === 0) return;

        const {
          entities,
          responseEntitiesIds,
        } = response;

        const { isAssembly } = entities[SHEET_MODEL][sheetId];

        if(isAssembly) return;

        const entityBatchId = responseEntitiesIds[ENTITY_BATCH_MODEL][0];

        const { providingState } = entities[ENTITY_BATCH_MODEL][entityBatchId];

        if(providingState === ENTITY_BATCH_PROVIDING_STATE.UNPROVIDED) {
          dispatch(addEntitiesToStore(entities));
        }
      });
  }
};


const _updateIfOnAssemblySheetsWaitingPartsAndMaterialsScreen = (
  defectMarkingUseCase,
  message,
  allTasksTablesIds,
  currentPathname,
) => dispatch => {

  const {
    entityBatchId,
    sheetId,
    completedOrderId,
    completedOrderName,
  } = message;

  let customActions = [];

  if(
    defectMarkingUseCase === DEFECT_MARKING_USE_CASES.PART_OF_ENTITY_BATCH_ON_LAST_OPERATION_FINISH &&
    completedOrderId !== null
  ) {
    //Выводим нотификейшен в случае, когда завершение части МЛ означает также и готовность заказа
    sendOrderIsReadyToCompleteNotification(completedOrderName);

    customActions.push(
      clearTableRemoteData(ORDER_IN_PRODUCTION_AND_READY_TO_COMPLETE_TABLES_IDS),
      clearTableData(createOrderEntriesTableId(ORDER_TYPE.IN_PRODUCTION, completedOrderId)),
    );
  }

  if(
    defectMarkingUseCase === DEFECT_MARKING_USE_CASES.WHOLE_ENTITY_BATCH_ON_OPERATION_FINISH ||
    defectMarkingUseCase === DEFECT_MARKING_USE_CASES.WHOLE_ENTITY_BATCH_ON_OPERATION_PAUSE ||
    defectMarkingUseCase === DEFECT_MARKING_USE_CASES.PART_OF_ENTITY_BATCH_ON_LAST_OPERATION_FINISH
  ) {
    customActions.push(
      deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
    );
  }

  if(defectMarkingUseCase === DEFECT_MARKING_USE_CASES.PART_OF_ENTITY_BATCH_ON_LAST_OPERATION_FINISH) {
    customActions.push(
      clearAllDefaultSheetsPartsAndMaterialsToConsume(),
    );
  }else{
    customActions.push(
      clearDefaultSheetPartsAndMaterialsToConsume(sheetId),
    );
  }

  dispatch([
    clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId)),

    clearTableRemoteData([
      SHEET_TYPE.IN_PRODUCTION,
      SHEET_TYPE.INAPPROPRIATE,
      MASTER_TASKS_TO_DO_TABLE_ID,
      MASTER_COMPLETED_TASKS_TABLE_ID,
      TASKS_MODEL,
      ...allTasksTablesIds,
    ]),

    deleteAssemblySheetConsumeData({ sheetId }),

    ...customActions,
  ]);


  dispatch(reFetchRemoteTableData(
    SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
    SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
    fetchSheetTypeRemoteTableDataCbFactory(
      dispatch,
      query => fetchFullAssemblySheetsPartsAndMaterials(query, { isBlockingRequest: false }),
    ),
  ));

  const partsAndMaterialsReserveRouteMatch = matchPath(currentPathname, {
    path: `${STORAGE_MANAGEMENT_APP_ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE}/:sheetId`,
  });

  /*
  * Если находимся НЕ на экране резервирования, то удаляем все данные резервирования из store в случае кейса 4, т.к.
  * было пополнение склада, во всех остальных кейсах удаляем только данные резервирования для МЛ, по которому
  * фиксировался брак, т.к. Мл или становится завершенным или у него изменяется количество, т.е. данные его
  * резервирования неактуальные
  * */
  if (partsAndMaterialsReserveRouteMatch === null) {

    if(defectMarkingUseCase === DEFECT_MARKING_USE_CASES.PART_OF_ENTITY_BATCH_ON_LAST_OPERATION_FINISH) {
      dispatch(deleteAllAssemblySheetsReserveData());
    }else{
      dispatch(deleteAssemblySheetReserveData({ sheetId }));
    }

    return;
  }

  const currentSheetId = partsAndMaterialsReserveRouteMatch.params.sheetId;

  /*
  * Если находимся на экране резервирования и сейчас кейсы 3 или 5, когда МЛ не пропадает из списка, то перезапрашиваем
  * данные по резерву, если сейчас просматриваем МЛ, по которому была фиксация брака, т.к количество ДСЕ изменилось,
  * если просматриваем другой МЛ, то сбрасываем кэш резерва
  *
  * */
  if(
    defectMarkingUseCase === DEFECT_MARKING_USE_CASES.PART_OF_ENTITY_BATCH_ON_OPERATION_FINISH ||
    defectMarkingUseCase === DEFECT_MARKING_USE_CASES.PART_OF_ENTITY_BATCH_ON_OPERATION_PAUSE
  ) {

    return currentSheetId === sheetId.toString() ?
      dispatch(fetchPartAndMaterialsReserveDataForAssemblyEntityBatch(entityBatchId, sheetId, undefined, { isBlockingRequest: false })) :
      dispatch(deleteAssemblySheetReserveData({ sheetId }));
  }


  /*
  * Если находимся на экране резервирования и сейчас кейсы 1 или 2, когда МЛ пропадает из списка, то сбрасываем кэш
  * резерва, а также, делаем редирект, если сейчас просматриваем МЛ, по которому была фиксация брака
  * */
  if(defectMarkingUseCase !== DEFECT_MARKING_USE_CASES.PART_OF_ENTITY_BATCH_ON_LAST_OPERATION_FINISH)  {

    if(currentSheetId === sheetId.toString()) {
      dispatch(push(STORAGE_MANAGEMENT_APP_ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE));
    }

    return dispatch(deleteAssemblySheetReserveData({ sheetId }));
  }

  /*
  * Если находимся на экране резервирования и сейчас кейс 4, когда МЛ пропадает из списка, но происходит пополнение
  * склада, то:
  * */

  /*
  * Если находимся на экране резервирования МЛ'а, по которому фиксировался брак, и он завершился то закрываем
  * модальное окно и очищаем все данные резерва, т.к. было пополнение склада
  * */
  if (currentSheetId === sheetId.toString()) {
    return dispatch([
      push(STORAGE_MANAGEMENT_APP_ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE),
      deleteAllAssemblySheetsReserveData(),
    ]);
  }

  /**
   * Если находимся на экране резервирования другого МЛ, то нужно перезапросить данные резервирования для него, а
   * данные остальных удалить из store. Но для перезапроса нужен entityBatchId просматриваемого МЛ, т.к. он
   * неизвестен (в параметрах роута только sheetId), поэтому нужно запросить партию ПЛ
   */
  const query = {
    filter: {
      filterGroupType: FILTER_GROUP_TYPES.AND,
      filters: [
        {
          column: 'id',
          filterType: FILTER_TYPES.EQUALS,
          filterValue: currentSheetId,
        },
      ],
    },
  };

  return dispatch(fetchEntitiesFromServer(
    SHEET_MODEL,
    query,
    { isBlockingRequest: false },
  ))
    .then(response => {
      const {
        responseEntitiesIds,
        responseMeta: {
          count,
        },
      } = response;

      if (count === 0) {
        return dispatch(deleteAllAssemblySheetsReserveData([currentSheetId]));
      }

      const currentEntityBatchId = responseEntitiesIds[SHEET_MODEL][0];

      dispatch(deleteAllAssemblySheetsReserveData([currentSheetId]));
      return dispatch(fetchPartAndMaterialsReserveDataForAssemblyEntityBatch(
        currentEntityBatchId,
        currentSheetId,
        undefined,
        { isBlockingRequest: false },
      ));
    });
};


const _updateIfOnMasterTasksTodoScreen = (
  defectMarkingUseCase,
  message,
  allTasksTablesIds,
  departmentIds,
) =>
  (dispatch, getState) => {

    const {
      sheetId,
    } = message;

    const defaultCustomActions = _getDefaultCustomActions(message, defectMarkingUseCase);

    dispatch([
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId)),

      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        SHEET_TYPE.INAPPROPRIATE,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        MASTER_COMPLETED_TASKS_TABLE_ID,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),

      deleteAssemblySheetConsumeData({ sheetId }),

      ...defaultCustomActions,
    ]);

    const masterTasksToDoAdditionalFilters = masterTasksToDoAdditionalFiltersSelector(getState());

    dispatch(reFetchRemoteTableData(
      MASTER_TASKS_TO_DO_TABLE_ID,
      MASTER_TASKS_TO_DO_TABLE_MODEL,
      ({ tableParams }) =>
        dispatch(fetchMasterDepartmentsTasksToDoRemoteTableData(
          departmentIds,
          masterTasksToDoAdditionalFilters,
          tableParams,
          { isBlockingRequest: false },
        )),
    ));
  };

/*
* Если находимся в разделе просмотра заданий "Мастер. Завершенные", то очищаем все данные других интерфейсов
* кроме этого, где должны быть обновления из-за отметки брака, а для этого раздела перезапрашиваем данные для текущей
* страницы таблицы
* */
const _updateIfOnMasterCompletedTasksScreen = (
  defectMarkingUseCase,
  message,
  allTasksTablesIds,
  departmentIds,
) =>
  dispatch => {

    const {
      sheetId,
    } = message;

    const defaultCustomActions = _getDefaultCustomActions(message, defectMarkingUseCase);

    dispatch([
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId)),

      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        SHEET_TYPE.INAPPROPRIATE,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        MASTER_TASKS_TO_DO_TABLE_ID,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),

      deleteAssemblySheetConsumeData({ sheetId }),

      ...defaultCustomActions,
    ]);

    dispatch(reFetchRemoteTableData(
      MASTER_COMPLETED_TASKS_TABLE_ID,
      MASTER_COMPLETED_TASKS_TABLE_MODEL,
      ({ tableParams }) =>
        dispatch(fetchMasterDepartmentsCompletedTasksRemoteTableData(
          departmentIds,
          tableParams,
          { isBlockingRequest: false },
        )),
    ));
  };

/*
* Если находимся в разделе "Рабочий. Просмотр задания для класса РЦ в подразделении", то очищаем все данные других
* интерфейсов кроме этого, где должны быть обновления из-за отметки брака, а для этого раздела перезапрашиваем
* задания для текущих параметров просматриваемой таблицы, чтобы информация обновилась
* */
const _updateIfOnTasksViewScreen = (
  defectMarkingUseCase,
  message,
  allTasksTablesIds,
  departmentIdFromRoute,
  equipmentClassIdFromRoute,
) =>
  dispatch => {

    const currentTableId = getEquipmentClassInDepartmentTasksTableId(
      departmentIdFromRoute,
      equipmentClassIdFromRoute,
    );

    //Текущую таблицу не очищаем, чтобы не было скачков в интерфейсе, данные в ней обновятся при перезапросе данных
    const allTasksTablesIdsWithoutCurrentTableId = allTasksTablesIds
      .filter(tableId => tableId !== currentTableId);

    const {
      sheetId,
    } = message;

    const defaultCustomActions = _getDefaultCustomActions(message, defectMarkingUseCase);

    dispatch([
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId)),

      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        SHEET_TYPE.INAPPROPRIATE,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        MASTER_TASKS_TO_DO_TABLE_ID,
        MASTER_COMPLETED_TASKS_TABLE_ID,
        TASKS_MODEL,
        ...allTasksTablesIdsWithoutCurrentTableId,
      ]),

      deleteAssemblySheetConsumeData({ sheetId }),

      ...defaultCustomActions,
    ]);

    dispatch(reFetchRemoteTableData(
      currentTableId,
      currentTableId,
      ({ tableParams }) =>
        dispatch(fetchEquipmentClassInDepartmentTasksRemoteTableData(
          {
            departmentIdsArray: [departmentIdFromRoute],
            equipmentClassIdsArray: [equipmentClassIdFromRoute],
          },
          tableParams,
          { isBlockingRequest: false },
        )),
    ));
  };

/*
 * Если находимся в разделе "Рабочий. Просмотр ВСЕХ заданий", то очищаем все данные других интерфейсов кроме этого, где
 * должны быть обновления из-за отметки брака, а для этого раздела перезапрашиваем данные для таблицы заданий,
 * с учетом текущих параметров таблицы и внешних фильтров
 * */
const _updateIfOnWorkerAppTasksScreen = (
  defectMarkingUseCase,
  message,
  allTasksTablesIds,
) =>
  dispatch => {

    const {
      sheetId,
    } = message;

    const defaultCustomActions = _getDefaultCustomActions(message, defectMarkingUseCase);

    dispatch([
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId)),

      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        SHEET_TYPE.INAPPROPRIATE,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        MASTER_TASKS_TO_DO_TABLE_ID,
        MASTER_COMPLETED_TASKS_TABLE_ID,
        ...allTasksTablesIds,
      ]),

      deleteAssemblySheetConsumeData({ sheetId }),

      ...defaultCustomActions,
    ]);

    dispatch(reFetchRemoteTableData(
      TASKS_MODEL,
      TASKS_MODEL,
      ({ tableParams }) =>
        dispatch(fetchTasksRemoteTableData(tableParams, { isBlockingRequest: false })),
    ));
  };


/*
 * Если находимся в разделе "Контроль. Несоответствующая продукция", то очищаем все данные других интерфейсов кроме этого,
 * где должны быть обновления из-за отметки брака, а для этого раздела перезапрашиваем данные для таблицы заданий,
 * с учетом текущих параметров таблицы и внешних фильтров
 * */
const _updateIfOnInappropriateSheetsScreen = (
  defectMarkingUseCase,
  message,
  allTasksTablesIds,
) =>
  dispatch => {

    const {
      sheetId,
    } = message;

    const defaultCustomActions = _getDefaultCustomActions(message, defectMarkingUseCase);

    dispatch([
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId)),

      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        MASTER_TASKS_TO_DO_TABLE_ID,
        MASTER_COMPLETED_TASKS_TABLE_ID,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),

      deleteAssemblySheetConsumeData({ sheetId }),

      ...defaultCustomActions,
    ]);

    dispatch(reFetchRemoteTableData(
      SHEET_TYPE.INAPPROPRIATE,
      SHEET_TYPE.INAPPROPRIATE,
      fetchSheetTypeRemoteTableDataCbFactory(
        dispatch,
        query => fetchInappropriateSheets(query, { isBlockingRequest: false }),
      ),
    ));
  };