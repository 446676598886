import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import './style.css';
import {
  MATERIAL_UI_DIALOG_MAX_WIDTH,
  MATERIAL_UI_VARIANT,
} from '../../../../constants/materialUI';
import { CancelLabelTrans, ShortAmountLabelTrans } from '../../../../utils/commonTransComponents';
import { SimpleConfirmDialog } from '../../SimpleConfirmDialog/SimpleConfirmDialog';
import { FUNC_IS_REQUIRED_TYPE } from '../../../../constants/propTypes';
import TextField from '@mui/material/TextField';
import { required } from '../../../../utils/formValidators/index';
import { amountOfDigitsInFloatNumberValidatorFabric, isInRangeValidatorFabric } from '@bfg-frontend/validators';
import { FILTER_GROUP_TYPES, FILTER_TYPES } from '../../../../api/restCollectionApi/index';
import { fetchEntitiesFromServer } from '../../../../reducers/entities/actions';
import { WAREHOUSE_ENTITY_BATCH_RESERVE_MODEL } from '../../../../constants/models';
import _get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { SimpleHelpAlert } from '../../SimpleHelpAlert/SimpleHelpAlert';
import {
  getAmountFromPercent,
  getPercentFromAmount,
  TEXT_FIELD_INPUT_MODES,
} from '../../TextFieldWithInputModes/constants';
import { TextFormField } from '../../TextFormField/TextFormField';
import { sheetOperationProgressModeSelector } from '../../../../reducers/sheetOperationReview/selectors';
import { SheetOperationProgressInput } from '../SheetOperationProgressInput/SheetOperationProgressInput';
import { setSheetOperationProgressMode } from '../../../../reducers/sheetOperationReview/actions';
import { prepareSheetOperationProgressValueToDisplay } from '../SheetOperationStatusChangeDialog/constants';
import { subDecimals } from '@bfg-frontend/utils/lib/decimal';
import { onlyNumsNormalizer } from '@bfg-frontend/normalizers';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { renderSheetOperationProgressMarkup } from '../constants';


//См. описание использования action creator'а внутри компонента. Это временное решение! Поэтому расположено прямо в
//компоненте
const _checkIfSomethingIsConsumedForBatch = entityBatchId =>
  dispatch => {

    const query = {
      filter: {
        filterGroupType: FILTER_GROUP_TYPES.AND,
        filters: [
          {
            column: 'entityBatchId',
            filterType: FILTER_TYPES.EQUALS,
            filterValue: entityBatchId,
          },
          {
            column: 'consumedQuantity',
            filterType: FILTER_TYPES.GREATER_THAN,
            filterValue: 0,
          },
        ],
      },
      page: 1,
      limit: 1,
    };

    return dispatch(fetchEntitiesFromServer(WAREHOUSE_ENTITY_BATCH_RESERVE_MODEL, query))
      .then(({ responseMeta }) => _get(responseMeta, 'count') > 0)
      .catch(() => null);
  };

const ENTITY_BATCH_SPLIT_REASON_TEXTAREA_ROWS = 3;

const getSheetOperationProgressInitialValue = (
  isCurrentProgressModePercent,
  entitiesInBatchAmount,
  progressInPercent,
) => {
  return isCurrentProgressModePercent ?
    progressInPercent :
    prepareSheetOperationProgressValueToDisplay(
      getAmountFromPercent(entitiesInBatchAmount, progressInPercent),
    );
};

export const SplitEntityBatchDialog = props => {
  const {
    isOpen,
    onSubmit,
    closeDialog,

    sheetOperationData: {
      entitiesInBatchAmount: initialEntitiesInBatchAmount,
      entityBatchId,
      progress: initialSheetOperationProgressInPercents,
    },


  } = props;

  const [reason, setReason] = useState('');

  const sheetOperationProgressMode = useSelector(state => sheetOperationProgressModeSelector(state));

  const isCurrentProgressModePercent = useMemo(
    () => sheetOperationProgressMode === TEXT_FIELD_INPUT_MODES.PERCENT,
    [sheetOperationProgressMode],
  );

  const initialSheetOperationProgressInAmount = useMemo(
    () => getSheetOperationProgressInitialValue(
      false,
      initialEntitiesInBatchAmount,
      initialSheetOperationProgressInPercents,
    ),
    [initialSheetOperationProgressInPercents, initialEntitiesInBatchAmount],
  );

  /*
  * Размер новой партии потомка и её прогресс
  * */
  const [entitiesInChildBatchAmount, setEntitiesInChildBatchAmount] = useState('');
  const [childBatchProgress, setChildBatchProgress] = useState(
    () => getSheetOperationProgressInitialValue(
      isCurrentProgressModePercent,
      Number(entitiesInChildBatchAmount) || 0,
      '0',
    ),
  );


  /*
  * Размер партии родителя, после выделения новой партии и её прогресс
  * */
  const entitiesInParentBatchAmount = useMemo(
    () => {
      return subDecimals(initialEntitiesInBatchAmount, Number(entitiesInChildBatchAmount) || 0);
    },
    [initialEntitiesInBatchAmount, entitiesInChildBatchAmount],
  );
  const [parentBatchProgress, setParentBatchProgress] = useState(
    () => getSheetOperationProgressInitialValue(
      isCurrentProgressModePercent,
      entitiesInParentBatchAmount,
      '0',
    ),
  );

  /*
  * Ошибка формы деления партии. Устанавливаются на сабмит. Сбрасываются при переключении
  * режима отображения прогресса
  * */
  const [errors, setErrors] = useState({});



  /*
  * ВРЕМЕННОЕ РЕШЕНИЕ!
  * Запрещаем делить сборочную партию, если по ней было потребление, т.к. сейчас нет логики, которая позволяла бы
  * корректно поделить потребленное количество ДСЕ при делении партии, и это может приводить к тому, что под
  * разделенную партию будет потреблено больше, чем требуется и для чего-то не хватит деталей. Поэтому, пока
  * лучше запретить, в будущем логика должна появиться
  */
  //TODO удалить временное решение
  const [isConsumedForBatch, setIsConsumedForBatch] = useState(null);
  const dispatch = useDispatch();

  useEffect(
    () => {
      dispatch(_checkIfSomethingIsConsumedForBatch(entityBatchId))
        .then(setIsConsumedForBatch);
    },
    [dispatch, entityBatchId, setIsConsumedForBatch],
  );


  const entitiesInChildBatchAmountChangeHandler = useCallback(value => {
    const normalizedValue = onlyNumsNormalizer(value);

    setEntitiesInChildBatchAmount(normalizedValue);
  }, [setEntitiesInChildBatchAmount]);

  const onSheetOperationProgressModeChange = useCallback(newInputMode => {
    const isNewInputModePercent = newInputMode === TEXT_FIELD_INPUT_MODES.PERCENT;

    // TODO добавить initialProgressInPercent (третий параметр) из config.js, когда это будет реализовано
    setChildBatchProgress(getSheetOperationProgressInitialValue(
      isNewInputModePercent,
      entitiesInChildBatchAmount,
      '0',
    ));

    setParentBatchProgress(getSheetOperationProgressInitialValue(
      isNewInputModePercent,
      entitiesInParentBatchAmount,
      '0',
    ));

    dispatch(setSheetOperationProgressMode(newInputMode));

    /*
    * Сбрысываем ошибки валидации, т.к. режим отображения изменился и ошибки для нового режима
    * рассчитывается иначе, и тексты ошибок тоже будут другие, т.е. валидация нужная новая, она
    * будет выполнена повторно при очередном сабмите формы
    * */
    setErrors({});
  }, [dispatch, entitiesInParentBatchAmount, setParentBatchProgress, entitiesInChildBatchAmount]);

  if(isConsumedForBatch === null) {
    return null;
  }

  return (
    <SimpleConfirmDialog
        className="split-entity-batch-dialog"
        dialogMaxWidth={MATERIAL_UI_DIALOG_MAX_WIDTH.SM}
        isOpen={isOpen}
        closeDialog={closeDialog}
        title={
          <Trans id="sheet_operation_review.split_entity_batch_dialog@title">
            Разделить партию
          </Trans>
        }
        additionalComponent={
          <div>
            {
              isConsumedForBatch ?
                <SimpleHelpAlert
                    className="split-entity-batch-dialog__is-consumed-for-batch-alert"
                    content={
                      <Trans id="sheet_operation_review.split_entity_batch_dialog@can_not_split_because_already_consumed">
                        Под партию потреблены комплектующие, такую партию запрещается делить
                      </Trans>
                    }
                /> :
                <React.Fragment>

                  <TextFormField
                      wrapperClassName="split-entity-batch-dialog__entities-amount-to-split-input"
                      value={entitiesInChildBatchAmount}
                      label={(
                        <Trans
                            id="sheet_operation_review.split_entity_batch_dialog@entities_amount_to_split"
                        >
                          Количество ДСЕ в отделяемой (новой) партии, {ShortAmountLabelTrans}
                        </Trans>
                      )}
                      onChange={entitiesInChildBatchAmountChangeHandler}
                      error={
                        _getFieldErrorMessage(
                          errors.entitiesInChildBatchAmount,
                          entitiesInChildBatchAmount,
                        )
                      }
                  />

                  <SheetOperationProgressInput
                      wrapperClassName="split-entity-batch-dialog__child-batch-progress-input"
                      value={childBatchProgress}
                      onChange={setChildBatchProgress}
                      inputMode={sheetOperationProgressMode}
                      onInputModeChange={onSheetOperationProgressModeChange}
                      label={(
                        <Trans
                            id="sheet_operation_review.split_entity_batch_dialog@progress_of_batch_to_be_splited"
                        >
                          Прогресс выполнения отделяемой (новой) партии
                        </Trans>
                      )}
                      error={
                        _getFieldErrorMessage(
                          errors.childBatchProgress,
                          childBatchProgress,
                        )}
                      entitiesInBatchAmount={Number(entitiesInChildBatchAmount)}
                  />


                  {
                    _renderInitialBatchInfo(
                      initialEntitiesInBatchAmount,
                      entitiesInParentBatchAmount,
                      initialSheetOperationProgressInPercents,
                      initialSheetOperationProgressInAmount,
                      entitiesInParentBatchAmount,
                    )}

                  <SheetOperationProgressInput
                      value={parentBatchProgress}
                      onChange={setParentBatchProgress}
                      inputMode={sheetOperationProgressMode}
                      onInputModeChange={onSheetOperationProgressModeChange}
                      label={(
                        <Trans id="sheet_operation_review.split_entity_batch_dialog@progress_of_parent_batch">
                          Прогресс выполнения текущей партии после деления
                        </Trans>
                      )}
                      error={
                        _getFieldErrorMessage(
                          errors.parentBatchProgress,
                          parentBatchProgress,
                        )
                      }
                      entitiesInBatchAmount={Number(entitiesInParentBatchAmount)}
                  />

                  {_renderEntityBatchSplitReasonField(reason, setReason, errors.reason)}

                </React.Fragment>
            }
          </div>
        }
        confirmBtn={
          <Trans id="sheet_operation_review.split_entity_batch_dialog@submit_button">
            Разделить партию
          </Trans>
        }
        cancelBtn={CancelLabelTrans}
        onConfirm={() => {
          const errors =  _getErrors({
            entitiesInChildBatchAmount,
            childBatchProgress,
            entitiesInParentBatchAmount,
            parentBatchProgress,
            isCurrentProgressModePercent,
            initialEntitiesInBatchAmount,
            reason,
          });

          if(
            !!errors.entitiesInChildBatchAmount ||
            !!errors.childBatchProgress ||
            !!errors.parentBatchProgress ||
            !!errors.reason
          ) {
            return setErrors(errors);
          }

          return onSubmit({

            entitiesInChildBatchAmount: Number(entitiesInChildBatchAmount),

            childBatchProgress: Number(
              _getProgressInPercents(
                childBatchProgress,
                isCurrentProgressModePercent,
                entitiesInChildBatchAmount,
              ),
            ),

            parentBatchProgress: Number(
              _getProgressInPercents(
                parentBatchProgress,
                isCurrentProgressModePercent,
                entitiesInParentBatchAmount,
              ),
            ),

            reason: reason.trim(),
          });
        }}

        disableConfirm={isConsumedForBatch}
    />
  );
};

const _getProgressInPercents = (
  progressFromTextField,
  isCurrentProgressModePercent,
  entitiesInBatchAmount,
) => {
  if(isCurrentProgressModePercent) {
    return progressFromTextField;
  }

  return getPercentFromAmount(entitiesInBatchAmount, progressFromTextField);
};

const MAX_AMOUNT_OF_DIGITS_IN_BATCH_SIZE = 4;
const amountOfDigitsInBatchSizeValidator = amountOfDigitsInFloatNumberValidatorFabric({
  amountOfDigits: MAX_AMOUNT_OF_DIGITS_IN_BATCH_SIZE,
  errorIdentityCreator: (_, amountOfDigits) =>
    <Trans id="sheet_operation_review.split_entity_batch_dialog@amount_of_digits_in_batch_size_must_be_in_range">
      Значение размера отделяемой (новой) партии не должно содержать более { amountOfDigits } знаков после запятой
    </Trans>,
});

const _getErrors = ({
  entitiesInChildBatchAmount,
  childBatchProgress,
  entitiesInParentBatchAmount,
  parentBatchProgress,
  isCurrentProgressModePercent,
  initialEntitiesInBatchAmount,
  reason,
}) => {

  const minEntitiesInBatchAmount = 0;
  const maxEntitiesInBatchAmountValue = initialEntitiesInBatchAmount;

  const entitiesInChildBatchAmountIsInRangeValidator = isInRangeValidatorFabric({
    minValue: minEntitiesInBatchAmount,
    minValueInclusive: false,
    maxValue: maxEntitiesInBatchAmountValue,
    maxValueInclusive: false,
    errorIdentityCreator: () =>
      <Trans id="sheet_operation_review.split_entity_batch_dialog@batch_size_must_be_in_range">
        Размер отделяемой (новой) партии должен быть больше {minEntitiesInBatchAmount} и не превышать размер исходной партии {maxEntitiesInBatchAmountValue}
      </Trans>,
  });

  const childBatchProgressIsInRangeValidator = isCurrentProgressModePercent ?
    isInRangeValidatorFabric({
      minValue: 0,
      minValueInclusive: true,
      maxValue: 100,
      maxValueInclusive: true,
      errorIdentityCreator: () => (
        <Trans id="sheet_operation_review.split_entity_batch_dialog@batch_to_split_progress_must_be_in_range_for_percent_mode">
          Прогресс отделяемой (новой) партии не должен быть больше 100 %
        </Trans>
      ),
    }) :
    isInRangeValidatorFabric({
      minValue: 0,
      minValueInclusive: true,
      maxValue: entitiesInChildBatchAmount,
      maxValueInclusive: true,
      errorIdentityCreator: () => (
        <Trans id="sheet_operation_review.split_entity_batch_dialog@batch_to_split_progress_must_be_in_range_for_amount_mode">
          Прогресс отделяемой (новой) партии должен быть больше 0 и не превышать заданный размер партии {entitiesInChildBatchAmount}
        </Trans>
      ),
    });

  const parentBatchProgressIsInRangeValidator = isCurrentProgressModePercent ?
    isInRangeValidatorFabric({
      minValue: 0,
      minValueInclusive: true,
      maxValue: 100,
      maxValueInclusive: true,
      errorIdentityCreator: () => (
        <Trans id="sheet_operation_review.split_entity_batch_dialog@parent_batch_progress_must_be_in_range_for_percent_mode">
          Прогресс текущей партии после деления не должен быть больше 100 %
        </Trans>
      ),
    }) :
    isInRangeValidatorFabric({
      minValue: 0,
      minValueInclusive: true,
      maxValue: entitiesInParentBatchAmount,
      maxValueInclusive: true,
      errorIdentityCreator: () => (
        <Trans id="sheet_operation_review.split_entity_batch_dialog@parent_batch_progress_must_be_in_range_for_amount_mode">
          Прогресс текущей партии после деления должен быть больше 0 и не превышать новый размер партии {entitiesInParentBatchAmount}
        </Trans>
      ),
    });

  const entitiesInChildBatchAmountError = required(entitiesInChildBatchAmount) ||
    amountOfDigitsInBatchSizeValidator(entitiesInChildBatchAmount) ||
    entitiesInChildBatchAmountIsInRangeValidator(Number(entitiesInChildBatchAmount));

  const childBatchProgressError = required(childBatchProgress) ||
    childBatchProgressIsInRangeValidator(Number(childBatchProgress));

  const parentBatchProgressError = required(parentBatchProgress) ||
    parentBatchProgressIsInRangeValidator(Number(parentBatchProgress));

  const reasonError = required(reason);

  return {
    entitiesInChildBatchAmount: !!entitiesInChildBatchAmountError ?
      ({
        errorValue: entitiesInChildBatchAmount,
        errorMsg: entitiesInChildBatchAmountError,
      }) :
      undefined,

    childBatchProgress: !!childBatchProgressError ?
      ({
        errorValue: childBatchProgress,
        errorMsg: childBatchProgressError,
      }) :
      undefined,

    parentBatchProgress: !!parentBatchProgressError ?
      ({
        errorValue: parentBatchProgress,
        errorMsg: parentBatchProgressError,
      }) :
      undefined,

    reason: !!reasonError ?
      ({
        errorValue: reason,
        errorMsg: reasonError,
      }) :
      undefined,
  };
};

const _renderEntityBatchSplitReasonField = (reason, setReason, reasonErrorData) =>  {

  const reasonErrorMsg = _getFieldErrorMessage(reasonErrorData, reason);

  return(
    <div className="split-entity-batch-dialog__split-reason">
      <div className="split-entity-batch-dialog__split-reason-label">
        <Trans id="sheet_operation_review.split_entity_batch_dialog@reason_rield_label">
          Причина деления партии
        </Trans>
        {':'}
      </div>
      <TextField
          id="split-entity-batch-dialog__split-reason-textarea"
          className="split-entity-batch-dialog__split-reason-textarea"
          multiline
          rows={ENTITY_BATCH_SPLIT_REASON_TEXTAREA_ROWS}
          variant={MATERIAL_UI_VARIANT.OUTLINED}
          fullWidth
          value={reason}
          onChange={e => setReason(e.target.value)}
          error={!!reasonErrorMsg}
          helperText={reasonErrorMsg}
      />
    </div>
  );
};


const _renderInitialBatchInfo = (
  initialEntitiesInBatchAmount,
  entitiesInParentBatchAmount,
  initialSheetOperationProgressInPercents,
  initialSheetOperationProgressInAmount,
  /*
  * Если понадобится выводить и измененный прогресс выполнения родительской партии после деления со "стрелкой",
  * аналогично изменению размера партии, то в функцию также передается параметр entitiesInParentBatchAmount,
  * который можно использовать
  * */
) => (
  <SimpleHelpAlert
      className="split-entity-batch-dialog__initial-batch-size-alert"
      content={
        <React.Fragment>
          <div className="split-entity-batch-dialog__initial-batch-size">
            <Trans id="sheet_operation_review.split_entity_batch_dialog@initial_batch_size_label">
              Количество ДСЕ в текущей партии после деления
            </Trans>
            {':'}
            <span className="split-entity-batch-dialog__initial-batch-size-value">
              {
                <React.Fragment>
                  {initialEntitiesInBatchAmount}
                  <ArrowRightAltIcon
                      className="split-entity-batch-dialog__initial-batch-size-value-arrow-icon"
                  />
                  {entitiesInParentBatchAmount}
                </React.Fragment>
              }
            </span>
          </div>
          <div>
            <Trans id="sheet_operation_review.split_entity_batch_dialog@progress_of_parent_batch_before_splitting">
              Прогресс выполнения текущей партии до деления
            </Trans>
            {':'}
            <span className="split-entity-batch-dialog__progress-of-parent-batch-before-splitting-value">
              {
                renderSheetOperationProgressMarkup(
                  initialSheetOperationProgressInPercents,
                  initialSheetOperationProgressInAmount,
                )
              }
            </span>
          </div>
        </React.Fragment>
      }
  />
);

const _getFieldErrorMessage = (fieldErrorData, fieldValue) => {
  if(!fieldErrorData) {
    return undefined;
  }

  const { errorValue, errorMsg }  = fieldErrorData;

  return errorValue === fieldValue ?
    errorMsg :
    undefined;
};

SplitEntityBatchDialog.propTypes = {
  closeDialog: FUNC_IS_REQUIRED_TYPE,
  onSubmit: FUNC_IS_REQUIRED_TYPE,
  isOpen: PropTypes.bool.isRequired,
  sheetOperationData: PropTypes.shape({
    entityBatchId: PropTypes.number.isRequired,
    sheetOperationId: PropTypes.number.isRequired,
    progress: PropTypes.number.isRequired,
    entitiesInBatchAmount: PropTypes.number.isRequired,
  }).isRequired,
};
