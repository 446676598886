import { sendTemporaryData } from '../temporaryData';
import _debounce from 'lodash/debounce';
import { sendMessageViaSocket } from '../../index';
import { SYSTEM_MESSAGES_SOCKET_IDENTITY } from '../../../../constants/sockets';


const SOCKET_MESSAGE_TIMEOUT = 2000;
const SIZE_LIMIT_BYTES = 2 * 1024; // KILOBYTES * BYTES
const getObjectSizeInBytes = obj => Buffer.from(JSON.stringify(obj)).length;
const doesDataFitInMessage = obj => getObjectSizeInBytes(obj) < SIZE_LIMIT_BYTES;
const socketUrl = [
  window.config.WEBSOCKET_SERVER_HOST,
  SYSTEM_MESSAGES_SOCKET_IDENTITY,
].join('/');

export class Broadcaster {
  constructor(messageType) {
    this.messageType = messageType;
    this.messages = [];
  }
  /*
  * Отправляет в сокет данные
  * */
  sendMessageToSocket({ data, temporaryDataKey }) {
    return sendMessageViaSocket(
      socketUrl,
      { type: this.messageType,
        data,
        temporaryDataKey,
      },
    );
  }
  /*
  * Отправляет в socket сообщение о том, что необходимо загрузить данные по определенному id.
  * Используется, чтобы не перегружать socket данными.
  * */
  sendLazyLoadableData(data) {
    return sendTemporaryData(data)
      .then(response => response.key)
      .then(temporaryDataKey => this.sendMessageToSocket({ temporaryDataKey }));
  }
  sendBatchOfMessages = _debounce(() => {
    const doMessagesFitInRequest = doesDataFitInMessage(this.messages);
    doMessagesFitInRequest
      ? this.sendMessageToSocket({ data: this.messages })
      : this.sendLazyLoadableData(this.messages);
    //очищаем
    this.messages = [];
  }, SOCKET_MESSAGE_TIMEOUT);
  sendMessage = message => {
    //Чтобы не было слишком частых запросов - делаем debounce, но сохраняем сообщения в messages
    this.messages.push(message);
    this.sendBatchOfMessages();
  };
  sendMessageWithoutDelay = message => {
    // для сохранения общего формата отправки оборачиваем сообщение в массив
    const messageInArray = [message];

    doesDataFitInMessage(messageInArray)
      ? this.sendMessageToSocket({ data: messageInArray })
      : this.sendLazyLoadableData(messageInArray);
  };
}
