import { createSelector } from 'reselect';
import {
  departmentTasksFilterSelector,
  equipmentClassTasksFilterSelector,
} from '../reducers/workerApp/tasksMainFilters/selectors';

import {
  getSettingsEntityUniqId,
  WORKER_EQ_CLASS_IN_DEP_TASKS_TABLE_MAIN_SETTINGS_ID,
  WORKER_EQ_CLASS_IN_DEP_TASKS_TABLE_SETTINGS_GROUP,
} from '../constants/settings';

import {
  ENTITY_MODEL_DATA_FROM_CA_ENTITY_TEMPLATE,
  OPERATION_DATA_FROM_CA_ENTITY_TEMPLATE,
  EQUIPMENT_DATA_FROM_CA_ENTITY_TEMPLATE,
  DEPARTMENT_DATA_FROM_CA_ENTITY_TEMPLATE,
  EQUIPMENT_CLASS_DATA_FROM_CA_ENTITY_TEMPLATE,
} from '../utils/entities';

import _mapValues from 'lodash/mapValues';
import _get from 'lodash/get';
import { getSheetOrderParams } from './sheets';
import { tableCurrentRemoteDataSelector, tableStateSelector } from '../reducers/table/selectors';
import {
  DEPARTMENT_MODEL,
  ENTITY_BATCH_MODEL,
  ENTITY_MODEL,
  EQUIPMENT_CLASS_MODEL,
  EQUIPMENT_MODEL,
  OPERATION_MODEL,
  ORDER_MODEL,
  SHEET_MODEL,
  SHEET_OPERATION_AGGREGATED_MODEL,
  SHEET_OPERATION_ASSIGNEE_MODEL,
  SHEET_OPERATION_MODEL,
  USER_MODEL,
} from '../constants/models';
import _size from 'lodash/size';
import { TASK_VIEW_SCREEN_MODEL } from '../reducers/schemaModel/models/taskViewScreenSchema';
import { SCHEMA_MODEL_INITIAL_STATE } from '../reducers/schemaModel/initialState';
import {
  checkIfTaskCanBeStarted,
  prepareAssigneesDataForSheetsOperationsRemoteTable,
   prepareOperationProdTimeAndLaborValues,
} from '../utils/tasks';
import { settingsEntitiesForGroupSelector } from './settings';
import { getEntityDataByTemplate } from '@bfg-frontend/utils/lib/stringBuilders/entity';


const INITIAL_TASK_VIEW_SCREEN_SCHEMA = SCHEMA_MODEL_INITIAL_STATE[TASK_VIEW_SCREEN_MODEL];

export const currentTasksTableSettingsEntitySelector = createSelector(
  departmentTasksFilterSelector,
  equipmentClassTasksFilterSelector,
  state => settingsEntitiesForGroupSelector(state, { group: WORKER_EQ_CLASS_IN_DEP_TASKS_TABLE_SETTINGS_GROUP }),
  (departmentTasksFilter, equipmentClassTasksFilter, workerTasksTableSettingsEntities) => {
    const { id: departmentIdTasksFilter } = departmentTasksFilter;
    const { id: equipmentClassIdTasksFilter } = equipmentClassTasksFilter;
    const equipmentClassInDepartmentSettingsId = [
      departmentIdTasksFilter,
      equipmentClassIdTasksFilter,
    ].join('_');
    /*
    * Ищем настройки сначала по классу РЦ в подразделения, если таких нет, то проверяем есть ли общие настройки
    * по подразделению, если и таких нет то выбираем общие настройки.
    * */
    return workerTasksTableSettingsEntities[getSettingsEntityUniqId(WORKER_EQ_CLASS_IN_DEP_TASKS_TABLE_SETTINGS_GROUP, equipmentClassInDepartmentSettingsId)] ||
      workerTasksTableSettingsEntities[getSettingsEntityUniqId(WORKER_EQ_CLASS_IN_DEP_TASKS_TABLE_SETTINGS_GROUP, departmentIdTasksFilter)] ||
      workerTasksTableSettingsEntities[WORKER_EQ_CLASS_IN_DEP_TASKS_TABLE_MAIN_SETTINGS_ID];
  },
);

export const currentTasksTableColumnsSettingsSelector = createSelector(
  currentTasksTableSettingsEntitySelector,
  settingsEntity => _get(settingsEntity, ['value', 'tasksTableColumns']),
);

export const currentEqClassInDepTasksTableSchemaSelector = createSelector(
  currentTasksTableColumnsSettingsSelector,
  currentTasksTableColumnsSettings =>
    /*
    * Если в системе совсем нет никаких настроек, даже основных, то берется модель колонок по-умолчанию
    * taskViewScreenSchema
    * */
    currentTasksTableColumnsSettings ?
      ({
        ...INITIAL_TASK_VIEW_SCREEN_SCHEMA,
        fields:_mapValues(
          INITIAL_TASK_VIEW_SCREEN_SCHEMA.fields,
          (defaultColumnData, columnId) => ({
            ...defaultColumnData,
            ...currentTasksTableColumnsSettings[columnId],
          }),
        ),
      }) :
      INITIAL_TASK_VIEW_SCREEN_SCHEMA,
);

const prepareTasksViewScreenSheetOperationDataCb = (
  sheetOperationId,
  {
    sheetOperationEntities,
    sheetEntities,
    entityBatchEntities,
    sheetOperationAggregatedDataEntities,
    operationEntities,
    entityModelEntities,
    equipmentEntities,
    orderEntities,
    sheetOperationAssigneeEntities,
    userEntities,
  },
  ) => {

  const sheetOperation = sheetOperationEntities[sheetOperationId];

  const {
    startDate,
    stopDate,
    status: sheetOperationStatus,
    operationId,
    sheetId,
    progress,
    departmentId,
    equipmentClassId,
    equipmentId,
    note,
    prodTime,
  } = sheetOperation;

  const {
    entityBatchId,
    identity: sheetIdentity,
    isAssembly: isAssemblySheet,
  } = sheetEntities[sheetId];

  const {
    providingState,
    entityId,
    orderId,
    amount: entitiesInBatchAmount,
  } = entityBatchEntities[entityBatchId];

  const {
    previousFinished: isPreviousSheetOperationFinished = false,
    lastAssembly: isLastAssemblySheetOperation = false,
  } = sheetOperationAggregatedDataEntities[sheetOperationId] || {};

  const assignees = prepareAssigneesDataForSheetsOperationsRemoteTable(sheetOperationId, sheetOperationAssigneeEntities, userEntities);

  const {
    operationProdTime,
    operationLabor,
  } = prepareOperationProdTimeAndLaborValues(prodTime, entitiesInBatchAmount);


  return {
    sheetOperationId,
    status: sheetOperationStatus,
    progress,
    canBeStarted: checkIfTaskCanBeStarted(
      isAssemblySheet,
      providingState,
      sheetOperationStatus,
      isPreviousSheetOperationFinished,
    ),
    isPreviousSheetOperationFinished,
    isLastAssemblySheetOperation,
    providingState,
    isAssemblySheet,
    sheetId,
    sheetIdentity,
    entityBatchId,
    entitiesInBatchAmount,
    operationStartDate: startDate,
    operationStopDate: stopDate,
    operationProdTime,
    operationLabor,
    assignees,
    departmentId,
    equipmentClassId,
    equipmentId,
    note,
    operationId,
    ...getEntityDataByTemplate(operationEntities[operationId], OPERATION_DATA_FROM_CA_ENTITY_TEMPLATE),
    ...getEntityDataByTemplate(entityModelEntities[entityId], ENTITY_MODEL_DATA_FROM_CA_ENTITY_TEMPLATE),
    ...getEntityDataByTemplate(equipmentEntities[equipmentId], EQUIPMENT_DATA_FROM_CA_ENTITY_TEMPLATE),
    ...getSheetOrderParams(orderId, orderEntities[orderId]),
  };
};

const prepareTasksSheetOperationDataCb = (
  sheetOperationId,
  {
    sheetOperationEntities,
    sheetEntities,
    entityBatchEntities,
    sheetOperationAggregatedDataEntities,
    operationEntities,
    entityModelEntities,
    equipmentEntities,
    orderEntities,
    sheetOperationAssigneeEntities,
    userEntities,
    departmentModelEntities,
    equipmentClassModelEntities,
  },
) => {
  const sheetOperation = sheetOperationEntities[sheetOperationId];

  const {
    departmentId,
    equipmentClassId,
  } = sheetOperation;

  return {
    ...prepareTasksViewScreenSheetOperationDataCb(
      sheetOperationId,
      {
        sheetOperationEntities,
        sheetEntities,
        entityBatchEntities,
        sheetOperationAggregatedDataEntities,
        operationEntities,
        entityModelEntities,
        equipmentEntities,
        orderEntities,
        sheetOperationAssigneeEntities,
        userEntities,
      },
    ),
    ...getEntityDataByTemplate(departmentModelEntities[departmentId], DEPARTMENT_DATA_FROM_CA_ENTITY_TEMPLATE),
    ...getEntityDataByTemplate(equipmentClassModelEntities[equipmentClassId], EQUIPMENT_CLASS_DATA_FROM_CA_ENTITY_TEMPLATE),
  };
};

const tasksTableDataSelectorFactory = prepareRelatedModelEntitiesDataCb => createSelector(
  tableCurrentRemoteDataSelector,
  currentRemoteData => {

    if(currentRemoteData === null) return [];

    const{
      currentRemoteItemsIds,
      currentRemoteItemsById,
    } = currentRemoteData;

    const sheetOperationsEntitiesIds = currentRemoteItemsIds[SHEET_OPERATION_MODEL];

    if(_size(sheetOperationsEntitiesIds) === 0) return [];

    const {
      [SHEET_OPERATION_MODEL]: sheetOperationEntities = {},
      [SHEET_MODEL]: sheetEntities = {},
      [ENTITY_BATCH_MODEL]: entityBatchEntities = {},
      [OPERATION_MODEL]: operationEntities = {},
      [ENTITY_MODEL]: entityModelEntities = {},
      [ORDER_MODEL]: orderEntities = {},
      [SHEET_OPERATION_AGGREGATED_MODEL]: sheetOperationAggregatedDataEntities = {},
      [EQUIPMENT_MODEL]: equipmentEntities = {},
      [DEPARTMENT_MODEL]: departmentModelEntities = {},
      [EQUIPMENT_CLASS_MODEL]: equipmentClassModelEntities = {},
      [SHEET_OPERATION_ASSIGNEE_MODEL]: sheetOperationAssigneeEntities = {},
      [USER_MODEL]: userEntities = {},
    } = currentRemoteItemsById;

    return sheetOperationsEntitiesIds
      .map(sheetOperationId => prepareRelatedModelEntitiesDataCb(
        sheetOperationId,
        {
          sheetOperationEntities,
          sheetEntities,
          entityBatchEntities,
          sheetOperationAggregatedDataEntities,
          operationEntities,
          entityModelEntities,
          equipmentEntities,
          orderEntities,
          sheetOperationAssigneeEntities,
          userEntities,
          departmentModelEntities,
          equipmentClassModelEntities,
        },
      ));
  },
);

export const tasksViewScreenTableDataSelector = tasksTableDataSelectorFactory(prepareTasksViewScreenSheetOperationDataCb);
export const tasksTableDataSelector = tasksTableDataSelectorFactory(prepareTasksSheetOperationDataCb);

/*
* TODO В зависимости у селектора весь табличный стор, который, изменяется довольно часто, поэтому мемоизация
* TODO срабатывает не очень хорошо. Нужно подумать, как замемоизировать селектор получше
* */
export const allTasksTablesIdsSelector = createSelector(
  tableStateSelector,
  tableState => Object
    .keys(tableState)
    .filter(tableId => tableId.startsWith(TASK_VIEW_SCREEN_MODEL)),
);
