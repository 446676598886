import {
  fetchDefaultSheetsWaitingPartsAndMaterials,
  fetchDefaultSheetsWithEnoughPartsAndMaterialsInStorage,
  fetchSheetsInProduction,
} from '../../../../operations/sheets';
import {
  MASTER_APP_TASKS_TO_DO_ROUTE,
  PLANNER_APP_ORDERS_IN_PRODUCTION_ROUTE,
  PLANNER_APP_ORDERS_READY_TO_COMPLETE_ROUTE,
  PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE,
  PLANNER_APP_SHEETS_TO_START_ROUTE,
  STORAGE_MANAGEMENT_APP_ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
  STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
  WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE,
  WORKER_APP_TASKS_ROUTE,
} from '../../../../constants/routes';
import { matchPath } from 'react-router-dom';
import {
  fetchEquipmentClassInDepartmentTasksRemoteTableData,
  fetchTasksRemoteTableData,
} from '../../../../operations/tasks';
import { routerPathnameSelector } from '../../../../reducers/router/selectors';
import { removeSheetsToStart } from '../../../../reducers/plannerApp/sheetsToStart/actions';
import {
  clearTableRemoteData,
  reFetchRemoteTableData,
  removeSelectedTableRows,
} from '../../../../reducers/table/actions';
import { SHEET_TYPE } from '../../../../constants/sheets';
import { fetchSheetTypeRemoteTableDataCbFactory } from '../../../../components/Sheets/SheetsContainer';

import { push } from 'connected-react-router';

import { addEntitiesToStore } from '../../../../reducers/entities/actions';
import { fetchDepartmentsWithTasks } from '../../../../operations/departments';
import { fetchEquipmentClassesForDepartmentWithTasks } from '../../../../operations/equipmentClasses';
import { getEquipmentClassInDepartmentTasksTableId } from '../../../../utils/tables';
import {
  clearAllDepartmentsWithTasks,
  clearAllEquipmentClassesInDepartmentWithTask,
  clearEquipmentClassesInDepartmentWithTasks,
} from '../../../../reducers/workerApp/tasksOwners/actions';
import { allTasksTablesIdsSelector } from '../../../../selectors/taskView';
import { eqClInDepsWithTasksEntitiesSelector } from '../../../../reducers/workerApp/tasksOwners/selectors';
import { MASTER_TASKS_TO_DO_TABLE_ID } from '../../../../components/MasterApp/MasterTasksToDo/constants';
import { fetchMasterDepartmentsTasksToDoRemoteTableData } from '../../../../operations/masterWorkspace/index';
import {
  getMasterAppDepartmentIdsFromRouteParam,
} from '../../../../components/MasterApp/MasterWorkspace/masterAppDepartmentIdsRouteParam';
import {
  masterTasksToDoAdditionalFiltersSelector,
} from '../../../../reducers/masterApp/tasksToDoAdditionalFilters/selectors';
import { ORDER_IN_PRODUCTION_AND_READY_TO_COMPLETE_TABLES_IDS, ORDER_TYPE } from '../../../../constants/orders';
import { fetchOrdersInProduction, fetchOrdersReadyToComplete } from '../../../../operations/orders';
import { fetchOrderRemoteTableDataCbFactory } from '../../../../components/Orders/OrdersContainer';
import {
  fetchFullAssemblySheetsPartsAndMaterials,
} from '../../../../components/StorageManagementApp/SheetsWaitingPartsAndMaterials/AssemblySheetsWaitingPartsAndMaterials/AssemblySheetsWaitingPartsAndMaterials';
import { TASKS_MODEL } from '../../../../reducers/schemaModel/models/tasksSchema';
import { MASTER_TASKS_TO_DO_TABLE_MODEL } from '../../../../reducers/schemaModel/models/masterTasksSchema';
import {
  SHEETS_WAITING_PARTS_AND_MATERIALS_REQUEST_WITH_PARAMS,
} from '../../../../components/StorageManagementApp/SheetsWaitingPartsAndMaterials/DefaultSheetsWaitingPartsAndMaterialsContainer/constants';


/*
* Подробно о влиянии события запуска МЛ на типы приложения описано в комментарии к handleSheetStarted в
* SheetToStartReviewContentContainer. Т.к. handleSheetStarted выполняется для пользователя, который сам запускает МЛ
* из раздела просмотра МЛ на запуск, то в нём для всех остальных разделов, где надо обновить данные, в основном,
* сбрасывается "клиентский кэш" (т.е. данные из стора), чтобы, при повторном входе в интерфейсы, запрос выполнился
* по новой.
* В случае с получением события запуска МЛ другими пользователями нужна похожая обработка, но, с учетом, того, что
* пользователи могут находиться в этот момент в разделах, которые сразу же должны быть обновлены - в этом случае,
* сбрасывать "кэш" не нужно, а нужен именно перезапрос \ обновление данных. Если перед перезапросом данных мы сначала
* очистим "кэш" (т.е. данные текущего раздела), а только потом перезапросим новые данные, то у пользователя в интерфейсе
* случится "скачок" (данные пропадут, кое-где мелькнет сообщение о пустых данных, а потом тут же появятся новые данные),
* что нежелательно.
* Для всех остальных разделов, где нужны обновления, кроме текущего, требуется, аналогично, очистить "кэш", чтобы при
* повторном входе в интерфейсы запросы выполнились заново
* */
export const handleSheetsStart = entityBatchFromIaIds =>
  (dispatch, getState) => {

    const state = getState();

    const allTasksTablesIds = allTasksTablesIdsSelector(state);

    const currentPathname = routerPathnameSelector(state);


    //Если находимся в разделе "Плановик. МЛ в производстве"
    const sheetsInProductionRouteMatch = matchPath(currentPathname, {
      path: PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE,
    });
    if(sheetsInProductionRouteMatch !== null)
      return dispatch(_updateIfOnSheetsInProductionScreen(entityBatchFromIaIds, allTasksTablesIds));


    //Если находимся в разделе "Плановик. МЛ на запуск"
    const sheetsToStartRouteMatch = matchPath(currentPathname, {
      path: PLANNER_APP_SHEETS_TO_START_ROUTE,
    });
    if (sheetsToStartRouteMatch !== null)
      return dispatch(_updateIfOnSheetsToStartScreen(entityBatchFromIaIds, currentPathname, allTasksTablesIds));

    //Если находимся в разделе "Плановик. Заказы. В производстве"
    const ordersInProductionRouteMatch = matchPath(currentPathname, {
      path: PLANNER_APP_ORDERS_IN_PRODUCTION_ROUTE,
    });
    if (ordersInProductionRouteMatch !== null)
      return dispatch(_updateIfOnOrdersInProductionScreen(entityBatchFromIaIds, allTasksTablesIds));

    //Если находимся в разделе "Плановик. Заказы. Готовые к завершению"
    const ordersReadyToCompleteRouteMatch = matchPath(currentPathname, {
      path: PLANNER_APP_ORDERS_READY_TO_COMPLETE_ROUTE,
    });
    if (ordersReadyToCompleteRouteMatch !== null)
      return dispatch(updateIfOnOrdersReadyToCompleteScreen(entityBatchFromIaIds, allTasksTablesIds, currentPathname));

    //Если находимся в разделе "Комплектование - "стандартные" неукомплектованные маршрутные листы"
    const defaultSheetsWaitingPartsAndMaterialsRouteMatch = matchPath(currentPathname, {
      path: STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
    });
    if(defaultSheetsWaitingPartsAndMaterialsRouteMatch !== null)
      return dispatch(_updateIfOnDefaultSheetsWaitingPartsAndMaterialsAppScreen(entityBatchFromIaIds, allTasksTablesIds));

    //Если находимся в разделе "Комплектование" - "сборки"
    const assemblySheetsWaitingPartsAndMaterialsRouteMatch = matchPath(currentPathname, {
      path: STORAGE_MANAGEMENT_APP_ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
    });
    if (assemblySheetsWaitingPartsAndMaterialsRouteMatch !== null) {
      return dispatch(_updateIfOnAssemblySheetsWaitingPartsAndMaterialsScreen(entityBatchFromIaIds, allTasksTablesIds));
    }

    //Если находимся  в разделе "Мастер. Требуется выполнить"
    const masterTasksTodoRouteMatch = matchPath(currentPathname, {
      path: MASTER_APP_TASKS_TO_DO_ROUTE,
    });
    if(masterTasksTodoRouteMatch !== null)
      return dispatch(_updateIfOnMasterTasksTodoScreen(
        entityBatchFromIaIds,
        getMasterAppDepartmentIdsFromRouteParam(masterTasksTodoRouteMatch.params.departmentIds),
        allTasksTablesIds,
      ));

    //Если находимся в разделе "Рабочий. Выбор подразделений", т.е. строго по роуту WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE
    const departmentChooseScreenRouteMatch = matchPath(currentPathname, {
      path: WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE,
    });
    if(departmentChooseScreenRouteMatch !== null && departmentChooseScreenRouteMatch.isExact)
      return dispatch(_updateIfOnDepartmentChooseScreen(entityBatchFromIaIds, allTasksTablesIds));


    //Если находимся в разделе "Рабочий. Выбор класса РЦ в подразделении", т.е. строго по роуту
    //`${WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE}/:departmentId`
    const equipmentClassInDepartmentChooseRouteMatch = matchPath(currentPathname, {
      path: `${WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE}/:departmentId`,
    });
    if(equipmentClassInDepartmentChooseRouteMatch !== null && equipmentClassInDepartmentChooseRouteMatch.isExact)
      return dispatch(_updateIfOnEquipmentClassInDepartmentChooseScreen(
        entityBatchFromIaIds,
        equipmentClassInDepartmentChooseRouteMatch.params.departmentId,
        allTasksTablesIds,
      ));


    //Если находимся в разделе "Рабочий. Просмотр задания для класса РЦ в подразделении"
    const tasksForEquipmentClassInDepartmentRouteMatch = matchPath(currentPathname, {
      path: `${WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE}/:departmentId/:equipmentClassId`,
    });
    if(tasksForEquipmentClassInDepartmentRouteMatch !== null) {
      const {
        departmentId,
        equipmentClassId,
      } = tasksForEquipmentClassInDepartmentRouteMatch.params;

      return dispatch(_updateIfOnTasksViewScreen(
        entityBatchFromIaIds,
        departmentId,
        equipmentClassId,
        allTasksTablesIds,
      ));
    }

    //Если находимся в разделе "Рабочий. Просмотр ВСЕХ заданий"
    const workerAppTasksRouteMatch = matchPath(currentPathname, {
      path: WORKER_APP_TASKS_ROUTE,
    });
    if(workerAppTasksRouteMatch !== null) {
      return dispatch(_updateIfOnWorkerAppTasksScreen(
        entityBatchFromIaIds,
        allTasksTablesIds,
      ));
    }

    /*
    * Для всех остальных разделов не нужна какая-то дополнительная обработка, но и для этого случая необходимо
    * очистить данные всех интерфейсов, где должны быть обновления из-за запуска нового МЛ, чтобы при следующем
    * входе в эти разделы данные были запрошены заново
    * */
    dispatch([
      removeSheetsToStart(entityBatchFromIaIds),
      removeSelectedTableRows(SHEET_TYPE.ALL_TO_START, entityBatchFromIaIds),
      clearTableRemoteData([
        ...ORDER_IN_PRODUCTION_AND_READY_TO_COMPLETE_TABLES_IDS,
        SHEET_TYPE.IN_PRODUCTION,
        MASTER_TASKS_TO_DO_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),
      clearAllDepartmentsWithTasks(),
      clearAllEquipmentClassesInDepartmentWithTask(),
    ]);
  };

/*
* Если находимся в разделе "Плановик. МЛ в производстве", то очищаем все данные других интерфейсов кроме этого, где
* должны быть обновления из-за запуска нового МЛ, а для этого раздела перезапрашиваем данные списка для текущей
* страницы
* */
const _updateIfOnSheetsInProductionScreen = (entityBatchFromIaIds, allTasksTablesIds) =>
  dispatch => {
    dispatch([
      removeSheetsToStart(entityBatchFromIaIds),
      removeSelectedTableRows(SHEET_TYPE.ALL_TO_START, entityBatchFromIaIds),
      clearTableRemoteData([
        ...ORDER_IN_PRODUCTION_AND_READY_TO_COMPLETE_TABLES_IDS,
        MASTER_TASKS_TO_DO_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),
      clearAllDepartmentsWithTasks(),
      clearAllEquipmentClassesInDepartmentWithTask(),
    ]);

    dispatch(reFetchRemoteTableData(
      SHEET_TYPE.IN_PRODUCTION,
      SHEET_TYPE.IN_PRODUCTION,
      fetchSheetTypeRemoteTableDataCbFactory(
        dispatch,
        query => fetchSheetsInProduction(query, { isBlockingRequest: false }),
      ),
    ));
  };


/*
* Если находимся в разделе "Плановик. МЛ на запуск", то очищаем все данные других интерфейсов кроме этого, где должны
* быть обновления из-за запуска нового МЛ. В интерфейсе два списка для "всех" и "сборочных" МЛ. Данные списков на запуск
* перезапрашивать не нужно, списки здесь не серверные, и обновления произойдут результате вызова
* removeSheetsToStart([entityBatchFromIaId]).
* Если ещё находимся в режима просмотра МЛ, который, как раз, и был запущен, выполняем редирект на список МЛ, т.к.
* МЛ больше не является "МЛ на запуск" и чтобы его не попытались повторно запустить
*/
const _updateIfOnSheetsToStartScreen = (entityBatchFromIaIds, currentPathname, allTasksTablesIds) =>
  dispatch => {
    dispatch([
      removeSheetsToStart(entityBatchFromIaIds),
      removeSelectedTableRows(SHEET_TYPE.ALL_TO_START, entityBatchFromIaIds),
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        ...ORDER_IN_PRODUCTION_AND_READY_TO_COMPLETE_TABLES_IDS,
        MASTER_TASKS_TO_DO_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),
      clearAllDepartmentsWithTasks(),
      clearAllEquipmentClassesInDepartmentWithTask(),
    ]);

    const sheetToStartReviewRouteMatch = matchPath(currentPathname, {
      path: `${PLANNER_APP_SHEETS_TO_START_ROUTE}/:sheetId`,
    });

    if(
      sheetToStartReviewRouteMatch !== null &&
      entityBatchFromIaIds.includes(Number(sheetToStartReviewRouteMatch.params.sheetId))
    ) {
      dispatch(push(PLANNER_APP_SHEETS_TO_START_ROUTE));
    }
  };

/*
* Если находимся в разделе "Плановик. Заказы в производстве", то очищаем все данные других интерфейсов кроме этого, где
* должны быть обновления из-за запуска нового МЛ, а для этого раздела перезапрашиваем данные списка для текущей
* страницы
* */
const _updateIfOnOrdersInProductionScreen = (entityBatchFromIaIds, allTasksTablesIds) =>
  dispatch => {
    dispatch([
      removeSheetsToStart(entityBatchFromIaIds),
      removeSelectedTableRows(SHEET_TYPE.ALL_TO_START, entityBatchFromIaIds),
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        ORDER_TYPE.READY_TO_COMPLETE,
        MASTER_TASKS_TO_DO_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),
      clearAllDepartmentsWithTasks(),
      clearAllEquipmentClassesInDepartmentWithTask(),
    ]);

    dispatch(reFetchRemoteTableData(
      ORDER_TYPE.IN_PRODUCTION,
      ORDER_TYPE.IN_PRODUCTION,
      fetchOrderRemoteTableDataCbFactory(
        dispatch,
        query => fetchOrdersInProduction(query, { isBlockingRequest: false }),
      ),
    ));
  };

/*
* Если находимся в разделе "Плановик. Заказы готовые к завершению", то очищаем все данные других интерфейсов кроме этого,
* где должны быть обновления из-за запуска нового МЛ, а для этого раздела перезапрашиваем данные списка для текущей
* страницы
*
* Отдельно стоит отметить обработку, когда пользователь находится в интерфейсе просмотра содержимого заказа из списка
* заказов готовых к завершению, а в этот момент происходит запуск в производство МЛ, который относится к просматриваемому
* заказу. В этом случае обновляются данные по заказу в СА, и его статус со статуса "готов к завершению" меняется на
* "в производстве", что логично, т.к. был запущен новый МЛ.
*
* В этом случае нужно выполнить редирект пользователя с просмотра содержимого этого заказа, т.к. сам заказ более не
* находится в списке "готовых к завершению" а находится в списке "в производстве". Чтобы выполнить редирект, мы должны
* проверить, действительно ли пользователь находится в просмотре этого заказа, а для этого, в свою очередь, мы должны
* знать идентификатор заказа.
* При запуске МЛ мы не можем получить данные по идентификатору заказа в ответе точки запуска, но можем выполнить запрос
* запущенной партии и получить из неё идентификатор по ключу orderId.
* */
const updateIfOnOrdersReadyToCompleteScreen = (entityBatchFromIaIds, allTasksTablesIds, currentPathname) =>
  dispatch => {

    dispatch([
      removeSheetsToStart(entityBatchFromIaIds),
      removeSelectedTableRows(SHEET_TYPE.ALL_TO_START, entityBatchFromIaIds),
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        ORDER_TYPE.IN_PRODUCTION,
        MASTER_TASKS_TO_DO_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),
      clearAllDepartmentsWithTasks(),
      clearAllEquipmentClassesInDepartmentWithTask(),
    ]);

    dispatch(reFetchRemoteTableData(
      ORDER_TYPE.READY_TO_COMPLETE,
      ORDER_TYPE.READY_TO_COMPLETE,
      fetchOrderRemoteTableDataCbFactory(
        dispatch,
        query => fetchOrdersReadyToComplete(query, { isBlockingRequest: false }),
      ),
    ));


    const orderReadyForCompleteReviewRouteMatch = matchPath(currentPathname, {
      path: `${PLANNER_APP_ORDERS_READY_TO_COMPLETE_ROUTE}/:orderId`,
    });

    if(orderReadyForCompleteReviewRouteMatch !== null) {
      return dispatch(push(PLANNER_APP_ORDERS_READY_TO_COMPLETE_ROUTE));
    }
  };

/*
* Если находимся в разделе "Комлектование - стандартные МЛ", то очищаем данные всех интерфейсов, где должны быть
* обновления из-за запуска нового МЛ. После этого запрашиваем сущность запущенного МЛ и если МЛ стандартный
* (isAssembly = false), то проверяем является ли он неукомплектованным после запуска (т.е., по сути, имеет ли он
* спецификацию, т.к. МЛ на ДСЕ без спецификации сразу являются укомплектованными).
* Если он является неукомплектованным, значит список МЛ, ожидающих комплектацию нужно обновить.
* С учетом всех описанных в комментарии к handleSheetStarted в SheetToStartReviewContentContainer особенностей про
* клиентскую таблицу стандартных МЛ, ожидающих комплектацию, требуется:
* - Записать сущность только что полученного в запросе МЛ в хранилище entities
* - Перезапросить информацию о МЛ, которые можно укомплектовать
*
* Когда таблица станет серверной, то эту обработку надо будет заменить на перезапрос текущей страницы просмотра,
* аналогично обрботке списка МЛ в производстве. (Возможно, в будущем и запрос о возможности комплектации можно будет
* сделать на 1 МЛ, а не на все МЛ, тогда тут можно будет подумать над новой логикой запроса и хранения кэшированной
* информации по МЛ, которые можно укомплектовать).
* */

const _updateIfOnDefaultSheetsWaitingPartsAndMaterialsAppScreen = (entityBatchFromIaIds, allTasksTablesIds) =>
  dispatch => {
    dispatch([
      removeSheetsToStart(entityBatchFromIaIds),
      removeSelectedTableRows(SHEET_TYPE.ALL_TO_START, entityBatchFromIaIds),
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        ...ORDER_IN_PRODUCTION_AND_READY_TO_COMPLETE_TABLES_IDS,
        MASTER_TASKS_TO_DO_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),
      clearAllDepartmentsWithTasks(),
      clearAllEquipmentClassesInDepartmentWithTask(),
    ]);

    return Promise.all([
      dispatch(fetchDefaultSheetsWaitingPartsAndMaterials(
        {
          with: SHEETS_WAITING_PARTS_AND_MATERIALS_REQUEST_WITH_PARAMS,
        },
        {
          isBlockingRequest: false,
        },
      ))
        .then(response => dispatch(addEntitiesToStore(response.entities))),
      dispatch(fetchDefaultSheetsWithEnoughPartsAndMaterialsInStorage({ isBlockingRequest: false })),
    ]);
  };


/*
 * Если находимся в разделе "Комплектование. Сборочные", то очищаем все данные других интерфейсов кроме
 * этого, где должны быть обновления из-за запуска новых МЛ, а для этого раздела перезапрашиваем данные списка для
 * текущей страницы
 * */
const _updateIfOnAssemblySheetsWaitingPartsAndMaterialsScreen = (entityBatchFromIaId, allTasksTablesIds) =>
    dispatch => {

      dispatch([
        removeSheetsToStart([entityBatchFromIaId]),
        removeSelectedTableRows(SHEET_TYPE.ALL_TO_START, [entityBatchFromIaId]),
        clearTableRemoteData([
          SHEET_TYPE.IN_PRODUCTION,
          ...ORDER_IN_PRODUCTION_AND_READY_TO_COMPLETE_TABLES_IDS,
          MASTER_TASKS_TO_DO_TABLE_ID,
          TASKS_MODEL,
          ...allTasksTablesIds,
        ]),
        clearAllDepartmentsWithTasks(),
        clearAllEquipmentClassesInDepartmentWithTask(),
      ]);

      return dispatch(reFetchRemoteTableData(
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        fetchSheetTypeRemoteTableDataCbFactory(
          dispatch,
          query => fetchFullAssemblySheetsPartsAndMaterials(query, { isBlockingRequest: false }),
        ),
      ));
    };

/*
* Если находимся в разделе "Мастер. Требуется выполнить", то очищаем все данные других интерфейсов кроме этого, где
* должны быть обновления из-за запуска нового МЛ, а для этого раздела перезапрашиваем данные для таблицы заданий,
* которые нужно выполнить в подразделениях из параметров роутинга, с учетом текущих параметров таблицы
* */
const _updateIfOnMasterTasksTodoScreen = (entityBatchFromIaIds, departmentIds, allTasksTablesIds) =>
  (dispatch, getState) => {
    dispatch([
      removeSheetsToStart(entityBatchFromIaIds),
      removeSelectedTableRows(SHEET_TYPE.ALL_TO_START, entityBatchFromIaIds),
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        ...ORDER_IN_PRODUCTION_AND_READY_TO_COMPLETE_TABLES_IDS,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),
      clearAllDepartmentsWithTasks(),
      clearAllEquipmentClassesInDepartmentWithTask(),
    ]);

    const masterTasksToDoAdditionalFilters = masterTasksToDoAdditionalFiltersSelector(getState());

    dispatch(reFetchRemoteTableData(
      MASTER_TASKS_TO_DO_TABLE_ID,
      MASTER_TASKS_TO_DO_TABLE_MODEL,
      ({ tableParams }) =>
        dispatch(fetchMasterDepartmentsTasksToDoRemoteTableData(
          departmentIds,
          masterTasksToDoAdditionalFilters,
          tableParams,
          { isBlockingRequest: false },
        )),
    ));
  };


/*
* Если находимся в разделе "Рабочий. Выбор подразделений", т.е. строго по роуту WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE, то очищаем все
* данные других интерфейсов кроме этого, где должны быть обновления из-за запуска нового МЛ, а для этого раздела
* перезапрашиваем подразделения с заданиями, чтобы список обновился
* */
const _updateIfOnDepartmentChooseScreen = (entityBatchFromIaIds, allTasksTablesIds) =>
  dispatch => {
    dispatch([
      removeSheetsToStart(entityBatchFromIaIds),
      removeSelectedTableRows(SHEET_TYPE.ALL_TO_START, entityBatchFromIaIds),
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        ...ORDER_IN_PRODUCTION_AND_READY_TO_COMPLETE_TABLES_IDS,
        MASTER_TASKS_TO_DO_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),
      clearAllEquipmentClassesInDepartmentWithTask(),
    ]);

    dispatch(fetchDepartmentsWithTasks({ isBlockingRequest: false }));
  };


/*
* Если находимся в разделе "Рабочий. Выбор класса РЦ подразделении", т.е. строго по роуту
* `${WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE}/:departmentId`, то очищаем все данные других интерфейсов кроме этого, где должны быть
* обновления из-за запуска нового МЛ, а для этого раздела перезапрашиваем классы РЦ в подразделении с заданиями, чтобы
* список обновился.
* */
const _updateIfOnEquipmentClassInDepartmentChooseScreen = (
  entityBatchFromIaIds,
  departmentIdFromRoute,
  allTasksTablesIds,
) =>
  (dispatch, getState) => {

    //список классов РЦ для текущего подразделения не очищаем, чтобы не было скачков в интерфейсе, данные в списка
    //обновятся при перезапросе данных
    const eqClInDepsWithTasksEntities = eqClInDepsWithTasksEntitiesSelector(getState());

    const departmentIdsToClear = Object
      .keys(eqClInDepsWithTasksEntities)
      .filter(departmentIdKey => Number(departmentIdKey) !== Number(departmentIdFromRoute));

    dispatch([
      removeSheetsToStart(entityBatchFromIaIds),
      removeSelectedTableRows(SHEET_TYPE.ALL_TO_START, entityBatchFromIaIds),
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        ...ORDER_IN_PRODUCTION_AND_READY_TO_COMPLETE_TABLES_IDS,
        MASTER_TASKS_TO_DO_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),
      clearAllDepartmentsWithTasks(),
      clearEquipmentClassesInDepartmentWithTasks(departmentIdsToClear),
    ]);

    dispatch(fetchEquipmentClassesForDepartmentWithTasks(departmentIdFromRoute, { isBlockingRequest: false }));
  };


/*
* Если находимся в разделе "Рабочий. Просмотр задания для класса РЦ в подразделении", то очищаем все данные других
* интерфейсов кроме этого, где должны быть обновления из-за запуска нового МЛ, а для этого раздела перезапрашиваем
* задания для текущих параметров просматриваеомй таблицы, чтобы информация обновилась
* */
const _updateIfOnTasksViewScreen = (
  entityBatchFromIaIds,
  departmentIdFromRoute,
  equipmentClassIdFromRoute,
  allTasksTablesIds,
) =>
  dispatch => {
    const currentTableId = getEquipmentClassInDepartmentTasksTableId(
      departmentIdFromRoute,
      equipmentClassIdFromRoute,
    );

    //Текущую таблицу не очищаем, чтобы не было скачков в интерфейсе, данные в ней обновятся при перезапросе данных
    const allTasksTablesIdsWithoutCurrentTableId = allTasksTablesIds
      .filter(tableId => tableId !== currentTableId);

    dispatch([
      removeSheetsToStart(entityBatchFromIaIds),
      removeSelectedTableRows(SHEET_TYPE.ALL_TO_START, entityBatchFromIaIds),
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        ...ORDER_IN_PRODUCTION_AND_READY_TO_COMPLETE_TABLES_IDS,
        MASTER_TASKS_TO_DO_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        TASKS_MODEL,
        ...allTasksTablesIdsWithoutCurrentTableId,
      ]),
      clearAllDepartmentsWithTasks(),
      clearAllEquipmentClassesInDepartmentWithTask(),
    ]);

    dispatch(reFetchRemoteTableData(
      currentTableId,
      currentTableId,
      ({ tableParams }) =>
        dispatch(fetchEquipmentClassInDepartmentTasksRemoteTableData(
          {
            departmentIdsArray: [departmentIdFromRoute],
            equipmentClassIdsArray: [equipmentClassIdFromRoute],
          },
          tableParams,
          { isBlockingRequest: false },
        )),
    ));
  };

/*
 * Если находимся в разделе "Рабочий. Просмотр ВСЕХ заданий", то очищаем все данные других интерфейсов кроме этого, где
 * должны быть обновления из-за запуска нового МЛ, а для этого раздела перезапрашиваем данные для таблицы заданий,
 * с учетом текущих параметров таблицы и внешних фильтров
 * */
const _updateIfOnWorkerAppTasksScreen = (entityBatchFromIaIds, allTasksTablesIds) =>
  dispatch => {

    dispatch([
      removeSheetsToStart(entityBatchFromIaIds),
      removeSelectedTableRows(SHEET_TYPE.ALL_TO_START, entityBatchFromIaIds),
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        ...ORDER_IN_PRODUCTION_AND_READY_TO_COMPLETE_TABLES_IDS,
        MASTER_TASKS_TO_DO_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        ...allTasksTablesIds,
      ]),
      clearAllDepartmentsWithTasks(),
      clearAllEquipmentClassesInDepartmentWithTask(),
    ]);

    dispatch(reFetchRemoteTableData(
      TASKS_MODEL,
      TASKS_MODEL,
      ({ tableParams }) =>
        dispatch(fetchTasksRemoteTableData(tableParams, { isBlockingRequest: false })),
    ));
  };
