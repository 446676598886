import {
  RESET_GROUP_SHEETS_START_PROCESS_STATE,
  SET_GROUP_SHEETS_START_PROCESS_DATA,
} from './actions';
import {
  GROUP_SHEETS_START_BROADCAST_STATUS,
} from '../../../components/PlannerApp/SheetsToStart/AllSheetsToStart/constants';

export const GROUP_SHEETS_START_INITIAL_STATE = {
  status: GROUP_SHEETS_START_BROADCAST_STATUS.NOT_STARTED,
  sheetsToStartAmount: null,
  startedSheetsAmount: null,
};

export const groupSheetsStart = (state = { ...GROUP_SHEETS_START_INITIAL_STATE }, action) => {

  switch (action.type) {
  case SET_GROUP_SHEETS_START_PROCESS_DATA: {
    const {
      sheetsToStartAmount,
      startedSheetsAmount,
      status,
    } = action;

    return {
      ...state,
      sheetsToStartAmount,
      startedSheetsAmount,
      status,
    };
  }

  case RESET_GROUP_SHEETS_START_PROCESS_STATE: {
    return { ...GROUP_SHEETS_START_INITIAL_STATE };
  }

  default: return state;
  }
};