import { SHEET_OPERATION_STATUS, ENTITY_BATCH_PROVIDING_STATE } from '../constants/sheets';
import { Trans } from '@lingui/macro';
import React from 'react';
import _isNil from 'lodash/isNil';
import { divDecimals, mulDecimals, subDecimals } from '@bfg-frontend/utils/lib/decimal';
import { getEntityDataByTemplate } from '@bfg-frontend/utils/lib/stringBuilders/entity';


/*
* Функция проверяет, достаточно ли комплектации для начала работ над партией.
* */
const checkIfHasEnoughPartsAndMaterialsToStart = (isAssemblySheet, providingState) =>
  (isAssemblySheet && providingState !== ENTITY_BATCH_PROVIDING_STATE.UNPROVIDED) ||
  (!isAssemblySheet && providingState === ENTITY_BATCH_PROVIDING_STATE.PROVIDED);

/*
* Задание может быть взято в работу если:
*   1. МЛ укомплектован (т.е. providingState === SHEET_PROVIDING_STATE.PROVIDED для стандартных МЛ и providingState !==
*      SHEET_PROVIDING_STATE.UNPROVIDED для сборочных МЛ)
*   2. Предыдущая операция по МЛ завершена
*   3. Никакие работы по ней ещё не проводились ранее (т.е. sheetOperationStatus === SHEET_OPERATION_STATUS.CREATED)
*      или операция была поставлена на паузу ранее, т.е. по ней уже велись работы, но были приостановлены
*      (т.е. sheetOperationStatus === SHEET_OPERATION_STATUS.PAUSED).
* */
export const checkIfTaskCanBeStarted = (
  isAssemblySheet,
  providingState,
  sheetOperationStatus,
  isPreviousOperationFinished,
) =>
  checkIfHasEnoughPartsAndMaterialsToStart(isAssemblySheet, providingState) &&
  isPreviousOperationFinished &&
  (sheetOperationStatus === SHEET_OPERATION_STATUS.CREATED || sheetOperationStatus === SHEET_OPERATION_STATUS.PAUSED);


/*
* Общая логика по генерации информационного контента о причине того, что задание на операцию нельзя начать или
* возобновить. Логика часто используется, как пропс при использовании SheetOperationReviewDialogContainer в различных
* интерфейсах, где дублируется функционал просмотра операции МЛ:
*
* Контент отображается, когда операцию нельзя взять в работу, т.е. статус операции CREATED или PAUSED, из которого
* можно, теоретически, взять задание в работу + проверяется флаг canBeStarted, который учитывает, что для начала
* работ по операции МЛ должен быть укомплектован и все предыдущие операции должны быть завершены. Если что-то
* из этого не выполняется и canBeStarted = false, то выводится одна из указанных ранее причин
* */
export const getTaskCanNotStartOrContinueHelpInfoContent = taskData => {

  const {
    status,
    canBeStarted,
    providingState,
    isAssemblySheet,
  } = taskData;

  if(
    (status === SHEET_OPERATION_STATUS.CREATED || status === SHEET_OPERATION_STATUS.PAUSED) &&
    !canBeStarted
  ) {

    return checkIfHasEnoughPartsAndMaterialsToStart(isAssemblySheet, providingState) ?

      <Trans id="worker_tasks@can_not_start_task_because_previous_tasks_not_done_yet">
        Выбранное задание можно будет взять в работу только после завершения всех предыдущих заданий по партии
      </Trans> :

      <Trans id="worker_tasks@can_not_start_task_because_not_enough_parts_and_materials">
        Выбранное задание можно будет взять в работу только после того как МЛ будет укомплектован
      </Trans>;
  }

  return null;
};

/*
* Общая логика для вычисления должны ли быть скрыты кнопки начала или возобновления задания на операцию. Логика часто
* используется, как пропс при использовании SheetOperationReviewDialogContainer в различных интерфейсах, где
* дублируется функционал просмотра операции МЛ:
*
* Кнопки начала или возобновления задания должны быть скрыты, когда операцию нельзя взять в работу, т.е. статус
* операции CREATED или PAUSED, из которого можно, теоретически, взять задание в работу + проверяется флаг
* canBeStarted, который учитывает, что для начала работ по операции МЛ должен быть укомплектован и все предыдущие
* операции должны быть завершены.
* */
export const areTaskStartOrContinueActionButtonsHidden = taskData => {
  const {
    status,
    canBeStarted,
  } = taskData;

  return (
    (status === SHEET_OPERATION_STATUS.CREATED || status === SHEET_OPERATION_STATUS.PAUSED) &&
    !canBeStarted
  );
};

const SHEET_OPERATION_REVIEW_DATA_FROM_MASTER_TASK_TEMPLATE = {
  sheetOperationId: 'taskId',
  sheetId: 'entityRouteSheetId',
  entityBatchId: 'entityBatchId',
  operationId: 'operationId',
  operationIdentity: 'operation__identity',
  operationNumber: 'operation__nop',
  operationName: 'operation__name',
  operationStartDate: 'startDate',
  operationStopDate: 'stopDate',
  operationProdTime: 'operationProdTime',
  operationLabor: 'operationLabor',
  progress: 'progress',
  status: 'status',
  departmentId: 'departmentId',
  departmentIdentity: 'department__identity',
  departmentName: 'department__name',
  equipmentClassId: 'equipmentClassId',
  equipmentClassIdentity: 'equipmentClass__identity',
  equipmentClassName: 'equipmentClass__name',
  equipmentId: 'equipmentId',
  equipmentIdentity: 'equipment__identity',
  equipmentName: 'equipment__name',
  sheetIdentity: 'entityRouteSheet__identity',
  entitiesInBatchAmount: 'entitiesInBatchAmount',
  entityIdentity: 'entity__identity',
  entityCode: 'entity__code',
  entityName: 'entity__name',
  orderId: 'orderId',
  orderName: 'order__name',
  canBeStarted: 'canBeStarted',
  providingState: 'providingState',
  assignees: 'assignees',
  note: 'note',
  isPreviousSheetOperationFinished: 'isPreviousSheetOperationFinished',
  isAssembly: 'isAssembly',
  isAssemblySheet: 'isAssemblySheet',
  isLastAssemblySheetOperation: 'isLastAssemblySheetOperation',
};

export const getSheetOperationReviewDataFromMasterTask = masterTaskData =>
  getEntityDataByTemplate(
    masterTaskData,
    SHEET_OPERATION_REVIEW_DATA_FROM_MASTER_TASK_TEMPLATE,
  );


/*
* Общая служебная функция по подготовке данных массива исполнителей для ряда операции МЛ (или задания) в серверной
* таблице, все данные для которой записаны в форматах стандартного табличного хранилище или в таком же виде, т.к. функция
* напрямую не привязывается к хранилищу, а, именно, когда есть нормализованные данные по sheetOperationAssigneeEntities
* и userEntities, внутри которых есть данные для заданной sheetOperationId.
* Как правило, функция используется в селекторах данных для серверных таблиц операций МЛ (или заданий), где для ряда
* таблицы известен sheetOperationId и есть доступ к стандартному currentRemoteItemsById из табличного хранилища в
* store для нужной серверной таблицы, откуда и получаются sheetOperationAssigneeEntities и userEntities, как
* нормализованные данные текущего запроса, для которого вызывается селектор, т.е., в указанных нормализованных
* данных гарантированно есть данные для заданной sheetOperationId.
* Логика: Предполагается, что в sheetOperationAssigneeEntities и userEntities в нормализованном виде записаны все
* данные, необходимые для текущей страницы таблицы. Соответственно, для заданного sheetOperationId находим все связи
* с исполнителями среди сущностей sheetOperationAssigneeEntities, по ним определяем идентификаторы пользователей
* и собираем данные по пользователям из userEntities
* */
export const UNKNOWN_ASSIGNEE_FIELD_VALUE = 'Unknown';
export const prepareAssigneesDataForSheetsOperationsRemoteTable = (
  sheetOperationId,
  sheetOperationAssigneeEntities = {},
  userEntities = {},
) => {
  return Object
    .values(sheetOperationAssigneeEntities)
    .reduce(
      (assigneesAcc, sheetOperationAssigneeEntity) => {
        if(sheetOperationAssigneeEntity.sheetOperationId !== sheetOperationId) return assigneesAcc;

        /*
        * Дефолтные значения для экстренных каких-то случаев, когда в currentRemoteItemsById есть данных по связям
        * пользователей с операциям, но нет данных по самим пользователям. По идее, такого не должно происходить, т.к.
        * это нелогично, но обрабатываем, чтобы и в этих случаях не было эксешенов.
        * */
        const {
          name = UNKNOWN_ASSIGNEE_FIELD_VALUE,
          lastName = UNKNOWN_ASSIGNEE_FIELD_VALUE,
          patronymicName = UNKNOWN_ASSIGNEE_FIELD_VALUE,
          note, // поле опционально, поэтому не ставим значение по умолчанию
        } = userEntities[sheetOperationAssigneeEntity.userId] || {};

        assigneesAcc.push({
          id: sheetOperationAssigneeEntity.userId,
          name,
          lastName,
          patronymicName,
          note,
        });

        return assigneesAcc;
      },
      [],
    );
};

export const isConsumeEntitiesOnTaskActionHidden = ({ providingState }) =>
  providingState !== ENTITY_BATCH_PROVIDING_STATE.PARTIALLY_PROVIDED;


export const prepareOperationProdTimeAndLaborValues = (prodTime, entitiesInBatchAmount) => {
  const operationProdTime = _isNil(prodTime) || prodTime === 0 ?
  null :
  prodTime;

  const operationLabor = operationProdTime === null || _isNil(entitiesInBatchAmount) ?
    null :
    mulDecimals(operationProdTime, entitiesInBatchAmount);

  return {
    operationProdTime,
    operationLabor,
  };
};

export const calculateLaborValue = (initialLaborValue, progress) => {
  if(progress === 100) {
    return 0;
  }

  return subDecimals(initialLaborValue, divDecimals(mulDecimals(initialLaborValue, progress), 100));
};

