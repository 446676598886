import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { WORKER_APP_MAIN_ROUTE } from '../../../constants/routes';
import cn from 'classnames';
import IconButton from '@mui/material/IconButton';
import { ArrowBack } from '@mui/icons-material';
import './style.css';
import { WorkerAppTasksViewModeTitleTrans } from '../WorkerAppViewModeChoosingScreen/constants';
import { WorkerLabelTrans } from '../../../utils/commonTransComponents';
import { saveUserSettings } from '../../../operations/userSettings';
import { USER_SETTINGS_ID } from '../constants';


export const WorkerTasksTitleComponent = () => {

  const dispatch = useDispatch();

  const onGoBackButtonClick = useCallback(
    () => dispatch(saveUserSettings({
      [USER_SETTINGS_ID.WORKER_APP_VIEW_MODE]: null,
      [USER_SETTINGS_ID.WORKER_APP_LAST_VISITED_ROUTE]: null,
    }))
      .then(() => dispatch(push(WORKER_APP_MAIN_ROUTE))),
    [dispatch],
  );

  return (
    <div className="worker-tasks-title-component">
      <IconButton
          className={cn(
            'worker-tasks-title-component__control-button',
          )}
          onClick={onGoBackButtonClick}
          color="inherit"
          size="large"
      >
        <ArrowBack className="worker-tasks-title-component__control-button-icon"/>
      </IconButton>
      <div className="worker-tasks-title-component__title-wrap">
        <div className="worker-tasks-title-component__title">
          {'"'}
          {WorkerLabelTrans}
          {'.'}
          {' '}
          {WorkerAppTasksViewModeTitleTrans}
          {'"'}
        </div>
      </div>
    </div>
  );
};
