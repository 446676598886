import { createSelector } from 'reselect';
import { reactRouterParamsSelector } from './reactRouter';
import _isNil from 'lodash/isNil';
import _isEmpty from 'lodash/isEmpty';
import _partialRight from 'lodash/partialRight';
import { getMasterAppDepartmentIdsFromRouteParam } from '../components/MasterApp/MasterWorkspace/masterAppDepartmentIdsRouteParam';
import { departmentEntitiesSelector } from '../reducers/entities/selectors';
import { tableCurrentRemoteDataSelector } from '../reducers/table/selectors';
import {
  DEPARTMENT_MODEL,
  ENTITY_BATCH_MODEL,
  ENTITY_MODEL, EQUIPMENT_CLASS_MODEL,
  EQUIPMENT_MODEL,
  OPERATION_MODEL, ORDER_MODEL,
  SHEET_MODEL,
  SHEET_OPERATION_AGGREGATED_MODEL,
  SHEET_OPERATION_ASSIGNEE_MODEL,
  SHEET_OPERATION_MODEL,
  USER_MODEL,
} from '../constants/models';
import _size from 'lodash/size';
import { MASTER_COMPLETED_TASKS_TABLE_ID } from '../components/MasterApp/MasterCompletedTasks/constants';
import { MASTER_TASKS_TO_DO_TABLE_ID } from '../components/MasterApp/MasterTasksToDo/constants';
import {
   checkIfTaskCanBeStarted,
   prepareAssigneesDataForSheetsOperationsRemoteTable,
   prepareOperationProdTimeAndLaborValues,
} from '../utils/tasks';
import { getEntityDataByTemplate } from '@bfg-frontend/utils/lib/stringBuilders/entity';


export const departmentIdsRouteParamSelector = (_, props) =>
  reactRouterParamsSelector(null, props).departmentIds;

export const departmentIdsFromRouteParamSelector = createSelector(
  departmentIdsRouteParamSelector,
  departmentIdsRouteParam =>
    _isNil(departmentIdsRouteParam) ?
      null :
      getMasterAppDepartmentIdsFromRouteParam(departmentIdsRouteParam),
);

export const departmentsAutocompleteValueSelector = createSelector(
  departmentIdsFromRouteParamSelector,
  departmentEntitiesSelector,
  (departmentIdsFromRouteParam, departmentEntities = {}) => {

    if(!departmentIdsFromRouteParam || _isEmpty(departmentEntities)) return [];

    /*
    * Дополнительных проверок на существование сущности в departmentEntities в селекторе не проводится, это
    * обеспечивается в логике работы приложения - при инициализации экрана с селектом, при обработке события выбора
    * новой опции в селекте и т.п.
    * */
    return departmentIdsFromRouteParam
      .map(idFromRoute => departmentEntities[idFromRoute]);
  },
);

export const MASTER_TASKS_TABLE_ROW_OPERATION_DATA_TEMPLATE = {
  operation__identity: 'identity',
  operation__nop: 'nop',
  operation__name: 'name',
  isAssembly: 'isAssembly',
};

export const MASTER_TASKS_TABLE_ROW_ENTITY_DATA_TEMPLATE = {
  entity__identity: 'identity',
  entity__code: 'code',
  entity__name: 'name',
};

export const MASTER_TASKS_TABLE_ROW_DEPARTMENT_DATA_TEMPLATE = {
  department__identity: 'identity',
  department__name: 'name',
};

export const MASTER_TASKS_TABLE_ROW_EQUIPMENT_CLASS_DATA_TEMPLATE = {
  equipmentClass__identity: 'identity',
  equipmentClass__name: 'name',
};

export const MASTER_TASKS_TABLE_ROW_EQUIPMENT_DATA_TEMPLATE = {
  equipment__identity: 'identity',
  equipment__name: 'name',
};

export const MASTER_TASKS_TABLE_ROW_ORDER_DATA_TEMPLATE = {
  order__name: 'name',
  order__priority: 'priority',
};


export const getCommonTaskTableRowData = (
  sheetOperationId,
  currentRemoteItemsById,
) => {
  const {
    [SHEET_OPERATION_MODEL]: sheetOperationEntities = {},
    [SHEET_MODEL]: sheetEntities = {},
    [ENTITY_BATCH_MODEL]: entityBatchEntities = {},
    [OPERATION_MODEL]: operationEntities = {},
    [ENTITY_MODEL]: entityModelEntities = {},
    [ORDER_MODEL]: orderEntities = {},
    [DEPARTMENT_MODEL]: departmentEntities = {},
    [EQUIPMENT_CLASS_MODEL]: equipmentClassEntities = {},
    [EQUIPMENT_MODEL]: equipmentEntities = {},
    [SHEET_OPERATION_ASSIGNEE_MODEL]: sheetOperationAssigneeEntities = {},
    [USER_MODEL]: userEntities = {},
  } = currentRemoteItemsById;

  const sheetOperation = sheetOperationEntities[sheetOperationId];

  const {
    startDate,
    stopDate,
    status,
    operationId,
    sheetId,
    progress,
    departmentId,
    equipmentClassId,
    equipmentId,
    note,
    prodTime,
  } = sheetOperation;

  const {
    entityBatchId,
    identity: sheetIdentity,
    isAssembly: isAssemblySheet,
  } = sheetEntities[sheetId];

  const {
    providingState,
    entityId,
    orderId,
    amount: entitiesInBatchAmount,
  } = entityBatchEntities[entityBatchId];

  const assignees = prepareAssigneesDataForSheetsOperationsRemoteTable(sheetOperationId, sheetOperationAssigneeEntities, userEntities);

  const {
    operationProdTime,
    operationLabor,
  } = prepareOperationProdTimeAndLaborValues(prodTime, entitiesInBatchAmount);

  return {
    taskId: sheetOperationId,
    status,
    progress,
    isAssemblySheet,
    providingState,
    entityRouteSheetId: sheetId,
    entityRouteSheet__identity: sheetIdentity,
    entityBatchId,
    entitiesInBatchAmount,
    startDate,
    stopDate,
    assignees,
    departmentId,
    equipmentClassId,
    equipmentId,
    orderId,
    note,
    operationProdTime,
    operationLabor,
    operationId,
    ...getEntityDataByTemplate(operationEntities[operationId], MASTER_TASKS_TABLE_ROW_OPERATION_DATA_TEMPLATE),
    ...getEntityDataByTemplate(entityModelEntities[entityId], MASTER_TASKS_TABLE_ROW_ENTITY_DATA_TEMPLATE),
    ...getEntityDataByTemplate(departmentEntities[departmentId], MASTER_TASKS_TABLE_ROW_DEPARTMENT_DATA_TEMPLATE),
    ...getEntityDataByTemplate(equipmentClassEntities[equipmentClassId], MASTER_TASKS_TABLE_ROW_EQUIPMENT_CLASS_DATA_TEMPLATE),
    ...getEntityDataByTemplate(equipmentEntities[equipmentId], MASTER_TASKS_TABLE_ROW_EQUIPMENT_DATA_TEMPLATE),
    ...getOrderData(orderId, orderEntities[orderId]),
  };
};

const getOrderData = (orderId, orderEntity) => {

  if(orderId === null)
    return{
      order__name: null,
      order__priority: null,
    };

  return getEntityDataByTemplate(orderEntity, MASTER_TASKS_TABLE_ROW_ORDER_DATA_TEMPLATE);
};

export const masterCompletedTasksTableDataSelector = createSelector(
  state => tableCurrentRemoteDataSelector(state, { tableId: MASTER_COMPLETED_TASKS_TABLE_ID }),
  currentRemoteData => {

    if (currentRemoteData === null) return [];

    const {
      currentRemoteItemsIds,
      currentRemoteItemsById,
    } = currentRemoteData;

    const sheetOperationsEntitiesIds = currentRemoteItemsIds[SHEET_OPERATION_MODEL];

    if (_size(sheetOperationsEntitiesIds) === 0) return [];

    const _getCommonTasksTableRowData = _partialRight(
      getCommonTaskTableRowData,
      currentRemoteItemsById,
    );

    return sheetOperationsEntitiesIds
      .map(sheetOperationId => ({
        ..._getCommonTasksTableRowData(sheetOperationId),
        canBeStarted: false,

        //т.к. это завершенные операции, то предыдущие значит тоже завершены иначе у нас быть не может по воркфлоу
        isPreviousSheetOperationFinished: true,
      }));
  },
);

export const masterTasksToDoTableDataSelector = createSelector(
  state => tableCurrentRemoteDataSelector(state, { tableId: MASTER_TASKS_TO_DO_TABLE_ID }),
  currentRemoteData => {

    if (currentRemoteData === null) return [];

    const {
      currentRemoteItemsIds,
      currentRemoteItemsById,
    } = currentRemoteData;

    const sheetOperationsEntitiesIds = currentRemoteItemsIds[SHEET_OPERATION_MODEL];

    if (_size(sheetOperationsEntitiesIds) === 0) return [];

    const {
      [SHEET_OPERATION_AGGREGATED_MODEL]: sheetOperationAggregatedDataEntities = {},
    } = currentRemoteItemsById;

    const _getCommonTasksTableRowData = _partialRight(
      getCommonTaskTableRowData,
      currentRemoteItemsById,
    );

    return sheetOperationsEntitiesIds
      .map(sheetOperationId => {

        const commonTasksTableRowData = _getCommonTasksTableRowData(sheetOperationId);

        const {
          status,
          providingState,
          isAssemblySheet,
        } = commonTasksTableRowData;

        const {
          previousFinished: isPreviousSheetOperationFinished = false,
          lastAssembly: isLastAssemblySheetOperation = false,
        } = sheetOperationAggregatedDataEntities[sheetOperationId] || {};

        return {
          ...commonTasksTableRowData,
          isLastAssemblySheetOperation,
          isPreviousSheetOperationFinished,
          canBeStarted: checkIfTaskCanBeStarted(
            isAssemblySheet,
            providingState,
            status,
            isPreviousSheetOperationFinished,
          ),
        };
      });
  },
);
