import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import {
  finishSheetOperation,
  pauseSheetOperation,
  continueSheetOperation,
  startSheetOperation,
  sendSheetOperationStartedNotification,
  sendSheetOperationPausedNotification,
  sendSheetOperationContinuedNotification,
  sendSheetOperationFinishedNotification,
  broadcastSheetOperationStatusChanged,
  broadcastSheetFinished,
  pauseSheet,
  broadcastSheetPaused,
  splitEntityBatch,
  broadcastEntityBatchSplit,
  fetchPartAndMaterialsConsumeDataForAssemblyEntityBatch,
  markDefectiveEntities,
  broadcastDefectiveEntitiesMarked,
  sendDefectiveEntitiesMarkedNotification,
} from '../../../operations/sheets';

import { SheetOperationReviewDialog } from './SheetOperationReviewDialog';
import { getSheetOperationCombinedName } from '../../../utils/stringBuilder';

import _noop from 'lodash/noop';
import { withPermissionsManager } from '../../../hoc/withPermissionsManager/withPermissionsManager';

import {
  broadcastSheetOperationDataChanged,
  fetchSheetOperationFeaturesValuesAndAddToStore,
  setSheetOperationAssignees,
  setSheetOperationEquipment,
  sheetOperationStatusChanged,
} from '../../../operations/sheetOperations';


import { addAutocompleteOptions } from '../../../reducers/autocomplete/actions';
import _get from 'lodash/get';
import { prepareFormWithServerValidationTextFieldValueForSubmit } from '@bfg-frontend/utils/lib/formsWithServerValidation';
import { setEntityNote, createEquipmentEntity, broadcastEquipmentEntityCreated } from '../../../operations/entities';
import { OPERATION_MODEL, SHEET_MODEL, SHEET_OPERATION_MODEL } from '../../../constants/models';
import { getClientMessagesDataArray } from '../../../api/socketApi/socketMessageHandlers/getClientMessagesDataArray';
import { SHEET_OPERATION_STATUS } from '../../../constants/sheets';
import { deleteAssemblySheetConsumeData } from '../../../reducers/workerApp/assemblySheets/consumeData/actions';
import { fetchEntitiesFromServer } from '../../../reducers/entities/actions';
import { FILTER_GROUP_TYPES, FILTER_TYPES } from '../../../api/restCollectionApi';
import {
  sheetOperationFeaturesValuesSelector,
  sheetOperationReviewIsConsumeEntitiesDialogOpenSelector,
} from '../../../reducers/sheetOperationReview/selectors';
import { sheetOperationReviewSetIsConsumeEntitiesDialogOpen } from '../../../reducers/sheetOperationReview/actions';
import { fetchEntityBatchReserveState } from '../../../operations/tasks';
import { sheetOperationSettingsSelector } from '../../../selectors/settings';
import {
  SHOULD_CHECK_EQUIPMENT_AVAILABILITY,
  IS_EQUIPMENT_FOR_OPERATION_REQUIRED,
} from '../../AdminApp/SheetOperationSettings/constants';

/*
* Подробный комментарий почему состояние модальника потребления находится в store тогда, как все остальные параметры
* в локальном state SheetOperationReviewDialog, представлен в reducers/sheetOperationReview
* */
const mapStateToProps = state => ({
  isConsumeEntitiesDialogOpen: sheetOperationReviewIsConsumeEntitiesDialogOpenSelector(state),
  sheetOperationSettings: sheetOperationSettingsSelector(state),
  sheetOperationFeaturesValues: sheetOperationFeaturesValuesSelector(state),
});

const mapDispatchToProps = (dispatch, ownProps) => {

  const {
    sheetOperationData,
    handleSheetOperationStatusChanged = _noop,
    handleSheetFinished = _noop,
    handleSheetOperationDataChanged = _noop,
    handleSheetPaused = _noop,
    handleEntityBatchSplit = _noop,
    handleDefectiveEntitiesMarked = _noop,
  } = ownProps;

  const defaultSheetOperationStatusChangeCbFactory = (
    changeStatusActionCreator,
    sendNotificationCb,
    newSheetOperationStatus,
  ) =>

    /*
    * Прогресс передается в колбэки только определенных случаях (пока только когда операция приостанавливается),
    * отсутствие аргумента здесь отдельно никак не обрабатывается, обработка есть в самих экшенкриеторах -
    * changeStatusActionCreator, поэтому туда безопасно передать undefined
    * */
    newSheetOperationProgress => {
      const {
        sheetIdentity,
        operationNumber,
        operationName,
        operationIdentity,
        progress,
      } = sheetOperationData;

      dispatch(changeStatusActionCreator(sheetIdentity, operationNumber, newSheetOperationProgress))
        .then(() => {

          const sheetOperationDataAfterStatusChange = {
            ...sheetOperationData,
            status: newSheetOperationStatus,
            progress: newSheetOperationProgress === undefined ? progress : newSheetOperationProgress,
          };

          broadcastSheetOperationStatusChanged(sheetOperationDataAfterStatusChange);

          sendNotificationCb(
            sheetIdentity,
            getSheetOperationCombinedName(operationNumber, operationName, operationIdentity),
          );

          dispatch(sheetOperationStatusChanged(sheetOperationDataAfterStatusChange));

          handleSheetOperationStatusChanged(sheetOperationDataAfterStatusChange);
        });
    };

  return {
    ...bindActionCreators({
      addAutocompleteOptions,
      getClientMessagesDataArray,
      // TODO после переноса окна просмотра операции на отдельный роут добавить обработку броадкастинга для
      // доп. характеристик
      fetchSheetOperationFeaturesValuesAndAddToStore,
      setIsConsumeEntitiesDialogOpen: sheetOperationReviewSetIsConsumeEntitiesDialogOpen,
    }, dispatch),

    startSheetOperation:
      defaultSheetOperationStatusChangeCbFactory(
        startSheetOperation,
        sendSheetOperationStartedNotification,
        SHEET_OPERATION_STATUS.IN_PRODUCTION,
      ),

    pauseSheetOperation:
      defaultSheetOperationStatusChangeCbFactory(
        pauseSheetOperation,
        sendSheetOperationPausedNotification,
        SHEET_OPERATION_STATUS.PAUSED,
      ),

    continueSheetOperation:
      defaultSheetOperationStatusChangeCbFactory(
        continueSheetOperation,
        sendSheetOperationContinuedNotification,
        SHEET_OPERATION_STATUS.IN_PRODUCTION,
      ),

    markDefectiveEntities: dataToSubmit => dispatch(markDefectiveEntities(dataToSubmit))
      .then(response => {
        const {
          data: {
            defectiveEntityRouteSheetId,
            entityRouteSheetId,
            entityBatchWasFinished,
            changedOrderId,
          },
        } = response;

        const {
          entityBatchId,
          sheetOperationId,
          sheetOperationStatus,
          isMarkDefectiveWholeEntityBatch,
        } = dataToSubmit;

        const {
          sheetIdentity,
          orderName,
        } = sheetOperationData;

        const data = {
          entityBatchId,
          sheetOperationId,
          sheetOperationStatus,
          sheetId: isMarkDefectiveWholeEntityBatch ?
            defectiveEntityRouteSheetId :
            entityRouteSheetId,
          completedOrderId: changedOrderId,
          completedOrderName: changedOrderId === null ? null : orderName,
          wasEntityBatchSplit: !isMarkDefectiveWholeEntityBatch,
          wasEntityBatchFinished: entityBatchWasFinished,
          sheetIdentity,
        };

        sendDefectiveEntitiesMarkedNotification(sheetIdentity);

        broadcastDefectiveEntitiesMarked(data);

        handleDefectiveEntitiesMarked(data);
      }),

    fetchEntityBatchReserveState: () => {
      const { entityBatchId } = sheetOperationData;
      return dispatch(fetchEntityBatchReserveState(entityBatchId))
        .then(({ allEntitiesWereReserved, allReserveWasConsumed }) =>
          ({ allEntitiesWereReserved, allReserveWasConsumed }));
    },

    finishSheetOperation: () => {
      const {
        sheetId,
        sheetIdentity,
        operationNumber,
        operationName,
        operationIdentity,
        orderName,
      } = sheetOperationData;

      return dispatch(finishSheetOperation(sheetIdentity, operationNumber))
        .then(response => {


          sendSheetOperationFinishedNotification(
            sheetIdentity,
            getSheetOperationCombinedName(operationNumber, operationName, operationIdentity),
          );

          const {
            data: {
              entityBatchWasFinished,
              changedOrderId,
            },
          } = response;

          if(entityBatchWasFinished) {

            /*
            * При завершении последней операции МЛ (т.е. завершении всего МЛ) может случиться, что это был последний МЛ
            * для некоторого заказа. В таком случае, в ответе с точки в ключе changedOrderId приходит идентификатор
            * завершенного заказа.
            * завершения заказа может влиять на обработку события, поэтому вычисляем здесь isOrderCompleted
            * */
            const isOrderCompleted = changedOrderId !== null;

            broadcastSheetFinished(sheetId, sheetIdentity, changedOrderId, orderName, isOrderCompleted);

            handleSheetFinished(sheetOperationData, isOrderCompleted);

          } else {

            /*
            * Пока мы строго разделили логически, что обработки, в случае завершения последней операции, т.е. при
            * завершении всего МЛ и в случае просто завершения операции, разные. Т.е. предполагаем всегда, что
            * событие заврешения МЛ учитывает и завершение последней операции сценарно, т.е. не нужно в этом случае
            * выполнять обработки 2 событий. Поэтому все обработки (и броадкастинг, и экшен изменения статуса и
            * внешний обработчик) по смене статуса операции выполняются только в случае завершения не последней
            * операции МЛ
            * */
            const sheetOperationDataAfterStatusChange = {
              ...sheetOperationData,
              status: SHEET_OPERATION_STATUS.FINISHED,
              progress: 100,
            };

            broadcastSheetOperationStatusChanged(sheetOperationDataAfterStatusChange);

            dispatch(sheetOperationStatusChanged(sheetOperationDataAfterStatusChange));

            handleSheetOperationStatusChanged(sheetOperationDataAfterStatusChange);
          }
        });
    },

    setSheetOperationAssignees: assigneesDataArray => {
      const {
        sheetId,
        sheetOperationId,
      } = sheetOperationData;

      return dispatch(setSheetOperationAssignees(
        sheetOperationId,
        assigneesDataArray.map(({ id }) => id),
      ))
        .then(() => {

          const sheetOperationChangedData = {
            assignees: assigneesDataArray,
          };

          broadcastSheetOperationDataChanged(sheetId, sheetOperationId, sheetOperationChangedData);
          handleSheetOperationDataChanged(sheetOperationData, sheetOperationChangedData);
        });
    },

    setSheetOperationEquipment: equipmentEntity => {
      const {
        sheetId,
        sheetOperationId,
      } = sheetOperationData;

      const equipmentId = _get(equipmentEntity, 'id', null);

      return  dispatch(setSheetOperationEquipment(
        sheetOperationId,
        equipmentId,
      ))
        .then(() => {

          const sheetOperationChangedData = {
            equipment: equipmentEntity,
          };

          broadcastSheetOperationDataChanged(sheetId, sheetOperationId, sheetOperationChangedData);
          handleSheetOperationDataChanged(sheetOperationData, sheetOperationChangedData);
        });
    },
    createEquipmentEntity: ({ name, identity }) => {

      const entityToCreateData = {
        name: prepareFormWithServerValidationTextFieldValueForSubmit(name),
        identity: prepareFormWithServerValidationTextFieldValueForSubmit(identity),
        departmentId: sheetOperationData.departmentId,
        equipmentClassId: sheetOperationData.equipmentClassId,
      };

      return dispatch(createEquipmentEntity(entityToCreateData))
        .then(response => {

          if(!response.data) return response;

          broadcastEquipmentEntityCreated({
            id: response.data,
            ...entityToCreateData,
          });

          return response;
        });
    },

    setSheetOperationNote: note => {
      const {
        sheetId,
        sheetOperationId,
      } = sheetOperationData;

      return dispatch(setEntityNote(
        SHEET_OPERATION_MODEL,
        sheetOperationId,
        note,
      ))
        .then(() => {

          const sheetOperationChangedData = {
            note,
          };

          broadcastSheetOperationDataChanged(sheetId, sheetOperationId, sheetOperationChangedData);
          handleSheetOperationDataChanged(sheetOperationData, sheetOperationChangedData);
        });
    },

   /*
    * В случае приостановки МЛ у нас нет возможности, как-то и сохранить причину остановку МЛ и остановить МЛ в один
    * запрос, поэтому в случае успешной остановки МЛ дополнительно отправляем запрос на сохранение причины приостановки,
    * которую сейчас принято хранить в поле note у модели маршрутного листа. Фактически, запрос на установку
    * причины остановки никак не влияет на само событие приостановки, но события обработки и броадкастинга логичней
    * выполнить, всё же, после окончания этого запроса, но не важно как он завершился. В общем случае, конечно, мы
    * всегда надеемся, что в случае успешной остановки (т.е. когда сервером уже провалидированы какие то данные),
    * запрос на сохранение причины остановки тоже должен выполниться успешно.
    *
    * */
    pauseSheet: pauseSheetReason => {
      const {
        sheetId,
        sheetIdentity,
        entityBatchId,
      } = sheetOperationData;


      /*
      * Как указывалось в комментарии выше, в случае сохранения причины приостановки мы продолжаем обработку даже
      * если запрос завершился с ошибкой (это, конечно, плохо, но не супер критично), поэтому в catch промис тоже
      * резолвится для удобства дальнейшей обработки
      * */
      const savePauseSheetReason = () =>
        dispatch(setEntityNote(
          SHEET_MODEL,
          sheetId,
          pauseSheetReason,
        ))
          .catch(() => Promise.resolve());

      return dispatch(pauseSheet(entityBatchId))
        .then(savePauseSheetReason)
        .then(() => {
          broadcastSheetPaused(sheetId, sheetIdentity);
          handleSheetPaused(sheetOperationData);
        });
    },

    fetchPartAndMaterialsConsumeDataForAssemblyEntityBatch: (entityBatchId, sheetId) =>
      dispatch(fetchPartAndMaterialsConsumeDataForAssemblyEntityBatch(entityBatchId, sheetId)),

    deleteAssemblySheetConsumeData: sheetId => dispatch(deleteAssemblySheetConsumeData({ sheetId })),

    fetchOperationData: sheetOperationId =>
      dispatch(fetchEntitiesFromServer(
        SHEET_OPERATION_MODEL,
        {
          filter: {
            filterGroupType: FILTER_GROUP_TYPES.AND,
            filters: [
              {
                column: 'id',
                filterType: FILTER_TYPES.EQUALS,
                filterValue: sheetOperationId,
              },
            ],
          },
        },
        {
          isBlockingRequest: false,
        },
      ))
        .then(response => {
          const {
            responseMeta: { count },
          } = response;

          if (count === 0) return null;

          const {
            entities,
          } = response;

          return entities[SHEET_OPERATION_MODEL][sheetOperationId];
        }),


    splitEntityBatch: ({
      entitiesInChildBatchAmount,
      childBatchProgress,
      parentBatchProgress,
      reason,
    }) => {
      const {
        sheetId,
        entityBatchId,
        sheetIdentity,
        sheetOperationId,
      } = sheetOperationData;


      return dispatch(splitEntityBatch({
        entityBatchId,
        sheetOperationId,
        entitiesInChildBatchAmount,
        childBatchProgress,
        parentBatchProgress,
        reason,
      }))
        .then(splitEntityBatchResponse => {

          debugger;

          const {
            childEntityBatchId,
            childEntityRouteSheetId,
            childEntityBatchWasFinished,
            parentEntityBatchWasFinished,
            changedOrderId,
          } = _get(splitEntityBatchResponse, 'data', {});


          const handleEntityBatchSplitData = {
            parentSheetId: sheetId,
            parentEntityBatchId: entityBatchId,
            childSheetId: childEntityRouteSheetId,
            childEntityBatchId,
            isParentEntityBatchWasFinished: parentEntityBatchWasFinished,
            isChildEntityBatchWasFinished: childEntityBatchWasFinished,
            changedOrderId,
            sheetIdentity,
          };

          broadcastEntityBatchSplit(handleEntityBatchSplitData);

          handleEntityBatchSplit(handleEntityBatchSplitData);
        });

    },

    checkOperationInProductionOnEquipment: equipmentId => {
      const queryParams =  {
        filter: {
          filterGroupType: FILTER_GROUP_TYPES.AND,
          filters: [
            {
              column: 'status',
              filterType: FILTER_TYPES.EQUALS,
              filterValue: SHEET_OPERATION_STATUS.IN_PRODUCTION,
            },
            {
              column: 'equipmentId',
              filterType: FILTER_TYPES.EQUALS,
              filterValue: equipmentId,
            },
          ],
        },
        with: [OPERATION_MODEL],
        start: 0,
        stop: 1,
      };

      // запрашиваем операции со статусом "в работе" и используемым оборудованием с идентификатором equipmentId
      return dispatch(fetchEntitiesFromServer(
        SHEET_OPERATION_MODEL,
        queryParams,
      ))
      .then(response => {
        const { responseMeta: { count } } = response;

        if (count === 0) return null;

        const { identity, name, nop } = Object.values(response.entities[OPERATION_MODEL])[0] || {};

        return getSheetOperationCombinedName(nop, name, identity);
      });
    },
  };
};

/*
* mergeProps служебный, чтобы передать только нужные для компонента пропсы из ownProps и все сформированные колбэки
* из dispatchProps
* */
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {
    className,
    closeDialog,
    sheetOperationData,
    areStatusChangeButtonsHidden,
    isPauseSheetAdditionalActionHidden,
    isSplitEntityBatchAdditionalActionHidden,
    isConsumeEntitiesActionHidden,
    getHelpAlertContent,
    isAssigneesChangeDisabled,
    isEquipmentChangeDisabled,
    isNoteChangeDisabled,
  } = ownProps;

  const {
    isConsumeEntitiesDialogOpen,
    sheetOperationSettings: {
      [IS_EQUIPMENT_FOR_OPERATION_REQUIRED]: isEquipmentForOperationRequired,
      [SHOULD_CHECK_EQUIPMENT_AVAILABILITY]: shouldCheckEquipmentAvailability,
    },
    sheetOperationFeaturesValues,
  } = stateProps;


  return{
    className,
    closeDialog,
    sheetOperationData,
    areStatusChangeButtonsHidden,
    isPauseSheetAdditionalActionHidden,
    isSplitEntityBatchAdditionalActionHidden,
    isConsumeEntitiesActionHidden,
    getHelpAlertContent,
    isAssigneesChangeDisabled,
    isEquipmentChangeDisabled,
    isNoteChangeDisabled,
    isConsumeEntitiesDialogOpen,
    isEquipmentForOperationRequired,
    shouldCheckEquipmentAvailability,
    sheetOperationFeaturesValues,
    ...dispatchProps,
  };
};

export const SheetOperationReviewDialogContainer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
  ),
  withPermissionsManager,
)(SheetOperationReviewDialog);

SheetOperationReviewDialogContainer.displayName = 'SheetOperationReviewDialogContainer';
