import {
  DefectLabelTrans,
  InappropriateSheetsLabelTrans } from '../../utils/commonTransComponents';
import {
  QUALITY_CONTROL_DEFECTIVE_SHEETS_ROUTE,
  QUALITY_CONTROL_INAPPROPRIATE_SHEETS_ROUTE,
} from '../../constants/routes';
import { DefectiveSheets } from './DefectiveSheets/DefectiveSheets';
import { InappropriateSheets } from './InappropriateSheets/InappropriateSheets';
import { SHEET_TYPE } from '../../constants/sheets';

const getEntitiesReviewTabRouteParams = (mainRoute, Component, entityIdRouteParam) => ({
  path: [
    [mainRoute, `:${entityIdRouteParam}`].join('/'),
    mainRoute,
  ],
  component: Component,
});


export const QUALITY_CONTROL_SHEETS_TABS = [
  {
    tabId: SHEET_TYPE.INAPPROPRIATE,
    tabTitle: InappropriateSheetsLabelTrans,
    tabLink: QUALITY_CONTROL_INAPPROPRIATE_SHEETS_ROUTE,
    tabRouteParams: getEntitiesReviewTabRouteParams(
      QUALITY_CONTROL_INAPPROPRIATE_SHEETS_ROUTE,
      InappropriateSheets,
      'sheetId',
    ),
  },
  {
    tabId: SHEET_TYPE.DEFECTIVE,
    tabTitle: DefectLabelTrans,
    tabLink: QUALITY_CONTROL_DEFECTIVE_SHEETS_ROUTE,
    tabRouteParams: getEntitiesReviewTabRouteParams(
      QUALITY_CONTROL_DEFECTIVE_SHEETS_ROUTE,
      DefectiveSheets,
      'sheetId',
    ),
  },
];
