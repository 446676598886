import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withTablesDataLayerHOC } from './withTablesDataLayerHOC/withTablesDataLayerHOC';
import { TableMenuWrapper } from './TableMenuWrapper/TableMenuWrapper';

import './style.css';

import _identity from 'lodash/identity';
import _isFunction from 'lodash/isFunction';
import _pick from 'lodash/pick';

import cn from 'classnames';

import {
  ARRAY_OF_ANY_OBJECTS_TYPE,
  FUNC_IS_REQUIRED_TYPE,
  NUMBER_OR_STRING_TYPE,
  OBJECT_OF_ANY_TYPE,
  TABLE_COLUMNS_CUSTOM_FILTERS_TYPE,
  TABLE_FILTER_PARAMS_TYPE,
  TABLE_MENU_TYPE,
  TABLE_SCHEMA_FIELDS_TYPE,
  TABLE_SORT_PARAMS_TYPE,
} from '../../constants/propTypes';


const TABLE_FROM_FACTORY_DEFAULT_CLASS_NAME = 'table-from-factory';

// eslint-disable-next-line react/prop-types
const DefaultTableTitleComponent = ({ tableTitle, className }) => (
  <div className={className}>
    {tableTitle}
  </div>
);

// eslint-disable-next-line react/prop-types
const DefaultTableOpenMenuButtonComponent = ({ menuTitle, className, onClick }) => (
  <button
      className={className}
      onClick={onClick}
  >
    {menuTitle}
  </button>
);

const TABLE_PAGINATION_COMPONENT_PROPS_FROM_FACTORY = [
  'totalItemsAmount',
  'pageSize',
  'activePage',
  'changePage',
  'changePageSize',
  'withPageSizeSelect',
  'totalPagesAmount',
];

const TABLE_FILTERS_COMPONENT_PROPS_FROM_FACTORY = [
  'schemaFields',
  'filterParams',
  'changeFilters',
  'customFilters',
  'filteredAndSortedRowsDataForCurrentPage',
];

const TABLE_TITLE_COMPONENT_PROPS_FROM_FACTORY = [
  'tableTitle',
];

export const tableFactory = ({
  tableClassName,
  tableCustomCellRenderers,

  tableComponents: {
    table: {
      component: TableComponent,
      propsAdapter: tablePropsAdapter = _identity,
      titles: tableComponentTitles = {},
      icons: tableComponentIcons = {},
    },
    title: {
      component: TableTitleComponent = DefaultTableTitleComponent,
      propsAdapter: tableTitleComponentPropsAdapter = _identity,
      titles: tableTitleComponentTitles = {},
      icons: tableTitleComponentIcons = {},
    } = {},
    openMenuButton: {
      component: TableOpenMenuButtonComponent = DefaultTableOpenMenuButtonComponent,
      propsAdapter: tableOpenMenuButtonComponentPropsAdapter = _identity,
      titles: tableOpenMenuButtonComponentTitles = {},
      icons: tableOpenMenuButtonComponentIcons = {},
    } = {},
    menu: {
      component: TableMenuComponent,
      propsAdapter: tableMenuComponentPropsAdapter = _identity,
      titles: tableMenuComponentTitles = {},
      icons: tableMenuComponentIcons = {},
    } = {},
    pagination: {
      component: TablePaginationComponent,
      propsAdapter: tablePaginationComponentPropsAdapter = _identity,
      titles: tablePaginationComponentTitles = {},
      icons: tablePaginationComponentIcons = {},
    } = {},
    filters: {
      component: TableFiltersComponent,
      propsAdapter: tableFiltersComponentPropsAdapter = _identity,
      titles: tableFiltersComponentTitles = {},
      icons: tableFiltersComponentIcons = {},
    } = {},
    summary: {
      component: tableSummaryModalComponent,
      propsAdapter: tableSummaryModalComponentPropsAdapter = _identity,
      titles: tableSummaryComponentTitles = {},
      icons: tableSummaryComponentIcons = {},
    } = {},
  },
}) => {

  class Table extends Component {
    _getTableElementClassName = elementClassName => {
      const defaultTableElementClassName =
        this._getElementClassName(TABLE_FROM_FACTORY_DEFAULT_CLASS_NAME, elementClassName);

      if(!tableClassName) return defaultTableElementClassName;

      return[
        this._getElementClassName(tableClassName, elementClassName),
        defaultTableElementClassName,
      ].join(' ');
    };

    _getElementClassName = (blockName, elementClassName) => [
      blockName,
      elementClassName,
    ].join('__');

    _renderPagination = () => {
      /**
       * Если alwaysShowPagination = true, то необходимо отображать пагинацию даже если у нас только
       * одна страница с данными. Это может понадобиться, когда мы можем изменить количество элементов
       * на странице.
       **/
      const {
        totalItemsAmount,
        alwaysShowPagination,
        totalPagesAmount,
      } = this.props;

      const needPagination = !!TablePaginationComponent && totalItemsAmount !== 0 && (alwaysShowPagination || totalPagesAmount !== 1);
      if (!needPagination) {
        return null;
      }

      const componentPropsFromFactory = {
        ..._pick(this.props, TABLE_PAGINATION_COMPONENT_PROPS_FROM_FACTORY),
        titles: tablePaginationComponentTitles,
        icons: tablePaginationComponentIcons,
      };

      return (
        <TablePaginationComponent
            {...tablePaginationComponentPropsAdapter(componentPropsFromFactory)}
            className={this._getTableElementClassName('table-pagination')}
        />
      );
    };

    _renderTableColumnsFilters = () => {
      if (!_isFunction(this.props.changeFilters)) return null;

      const{
        tableId,
        customTableComponents: {
          filters: {
            component: CustomTableFiltersComponent,
            propsAdapter: customTableFiltersComponentPropsAdapter,
            componentProps: customTableFiltersComponentProps = {},
          } = {},
        },
      } = this.props;

      const ResultTableFiltersComponent = CustomTableFiltersComponent || TableFiltersComponent;

      if (!ResultTableFiltersComponent) {
        return null;
      }

      const resultTableFiltersComponentPropsAdapter = customTableFiltersComponentPropsAdapter || tableFiltersComponentPropsAdapter;

      const componentPropsFromFactory = {
        ..._pick(this.props, TABLE_FILTERS_COMPONENT_PROPS_FROM_FACTORY),
        titles: tableFiltersComponentTitles,
        icons: tableFiltersComponentIcons,
      };

      return (
        <div className={this._getTableElementClassName('columns-filters-wrap')}>
          <ResultTableFiltersComponent
              {...resultTableFiltersComponentPropsAdapter(componentPropsFromFactory)}
              {...customTableFiltersComponentProps}
              key={[tableClassName, 'table-columns-filters', tableId].join('-')}
          />
        </div>
      );
    };

    _renderTableTitle = () => {
      const { tableTitle } = this.props;

      if(!tableTitle) return null;

      const componentPropsFromFactory = {
        ..._pick(this.props, TABLE_TITLE_COMPONENT_PROPS_FROM_FACTORY),
        titles: tableTitleComponentTitles,
        icons: tableTitleComponentIcons,
      };

      return(
        <div className={this._getTableElementClassName('title-wrap')}>
          <TableTitleComponent
              {...tableTitleComponentPropsAdapter(componentPropsFromFactory)}
              className={this._getTableElementClassName('table-title')}
          />
        </div>
      );
    };

    _renderAdditionalComponents = () => {
      const { additionalComponents } = this.props;

      if(!additionalComponents) return null;

      return(
        <div className={this._getTableElementClassName('additional-components-wrap')}>
          {additionalComponents}
        </div>
      );
    };

    _renderTableMenu = () => {
      const {
        tableMenu: {
          menuOptions = [],
          disableMenu,
        } = {},
      } = this.props;

      if(!TableOpenMenuButtonComponent || !TableMenuComponent || disableMenu) return null;

      const tableWrapperIcons = {
        openMenuButton: tableOpenMenuButtonComponentIcons,
        menu: tableMenuComponentIcons,
        summary: tableSummaryComponentIcons,
      };

      const tableWrapperTitles = {
        openMenuButton: tableOpenMenuButtonComponentTitles,
        menu: tableMenuComponentTitles,
        summary: tableSummaryComponentTitles,
      };

      return(
        <div className={this._getTableElementClassName('table-menu-wrap')}>
          <TableMenuWrapper
              menuOptions={menuOptions}
              tableProps={this.props}
              icons={tableWrapperIcons}
              titles={tableWrapperTitles}
              TableSummaryModalComponent={tableSummaryModalComponent}
              tableSummaryModalComponentPropsAdapter={tableSummaryModalComponentPropsAdapter}
              tableSummaryModalClassName={this._getTableElementClassName('table-summary-modal')}
              TableOpenMenuButtonComponent={TableOpenMenuButtonComponent}
              tableOpenMenuButtonComponentPropsAdapter={tableOpenMenuButtonComponentPropsAdapter}
              TableMenuComponent={TableMenuComponent}
              tableMenuComponentPropsAdapter={tableMenuComponentPropsAdapter}
          />
        </div>
      );
    };

    _renderTableComponent = () => {
      const componentPropsFromFactory = {
        ...this.props,
        icons: tableComponentIcons,
        titles: tableComponentTitles,
      };

      return (
        <div className={this._getTableElementClassName('table-component-wrap')}>
          <TableComponent
              {...this.props.wrappedTableComponentProps}
              {...tablePropsAdapter(componentPropsFromFactory)}
          />
        </div>
      );
    };

    render() {
      const pagination = this._renderPagination();

      const tableMenu = this._renderTableMenu();

      const topPaginationWithTableMenuWrap = (pagination === null && tableMenu === null) ?
        null :
        <div
            className={cn(
              this._getTableElementClassName('top-table-pagination-with-table-menu-wrap'),
              {
                [this._getTableElementClassName('top-table-pagination-with-table-menu-wrap--flex-end')]: pagination === null,
              },
            )}
        >
          {pagination}
          {this._renderTableMenu()}
        </div>;

      const bottomPaginationWrap = pagination === null ?
        null :
        <div className={this._getTableElementClassName('bottom-table-pagination-wrap')}>
          {pagination}
        </div>;

      return (
        <div className={tableClassName}>
          {this._renderTableColumnsFilters()}
          {this._renderTableTitle()}
          {this._renderAdditionalComponents()}
          {topPaginationWithTableMenuWrap}
          <div>
            {this._renderTableComponent()}
          </div>
          {bottomPaginationWrap}
        </div>
      );
    }
  }

  Table.propTypes = {
    tableId: PropTypes.string.isRequired,
    tableModel: PropTypes.string.isRequired,
    tableTitle: PropTypes.string,
    additionalComponents: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.element),
    ]),
    rowIdProperty: PropTypes.string,
    allRowsData: ARRAY_OF_ANY_OBJECTS_TYPE,
    filteredAndSortedAllRowsData: ARRAY_OF_ANY_OBJECTS_TYPE,
    filteredAndSortedRowsDataForCurrentPage: ARRAY_OF_ANY_OBJECTS_TYPE,
    noDataContent: PropTypes.node.isRequired,
    selectedRowId: NUMBER_OR_STRING_TYPE,
    onRowCombinedClicksHandler: PropTypes.func,
    schemaFields: TABLE_SCHEMA_FIELDS_TYPE,
    sortParams: TABLE_SORT_PARAMS_TYPE,
    changeSort: PropTypes.func,
    filterParams: TABLE_FILTER_PARAMS_TYPE,
    changeFilters: PropTypes.func,
    customFilters: TABLE_COLUMNS_CUSTOM_FILTERS_TYPE,
    activePage: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    totalPagesAmount: PropTypes.number.isRequired,
    maxPageButtonsAmount: PropTypes.number,
    simplePagination: PropTypes.bool,
    changePage: FUNC_IS_REQUIRED_TYPE,
    changeColumnVisibility: FUNC_IS_REQUIRED_TYPE,
    resizeColumns: FUNC_IS_REQUIRED_TYPE,
    changeColumnsOrder: PropTypes.func,
    getRowStyle: PropTypes.func,
    tableMenu: TABLE_MENU_TYPE,
    customCellRenderersProps: PropTypes.objectOf(OBJECT_OF_ANY_TYPE),
    customColumnsComparators: PropTypes.objectOf(OBJECT_OF_ANY_TYPE),
    wrappedTableComponentProps: OBJECT_OF_ANY_TYPE,
    customTableComponents: PropTypes.objectOf(
      PropTypes.shape({
        component: PropTypes.elementType,
        propsAdapter: PropTypes.func,
      }),
    ),
    alwaysShowPagination: PropTypes.bool,
    withPageSizeSelect: PropTypes.bool,
    totalItemsAmount: PropTypes.number,
  };

  Table.defaultProps = {
    rowIdProperty: 'id',
    allRowsData: [],
    filteredAndSortedAllRowsData: [],
    filteredAndSortedRowsDataForCurrentPage: [],
    noDataContent: '',
    customCellRenderersProps: {},
    customColumnsComparators: {},
    customTableComponents: {},
  };

  return withTablesDataLayerHOC(tableCustomCellRenderers)(Table);
};

