import { connect } from 'react-redux';

import { push } from 'connected-react-router';

import { LoginScreen } from './LoginScreen';
import { reactRouterCurrentSearchSelector } from '../../selectors/reactRouter';
import { getQueryParamsFromQueryString } from '../../utils/url';
import { initApp } from '../../operations/app';
import { login, logout } from '../../operations/users/index';
import { getUserPermittedUrlAfterAuthentication } from '../../utils/permissions/index';
import { getCurrentUserLastVisitedRoute } from '../../operations/users/getCurrentUserLastVisitedRoute';


const mapDispatchToProps = (dispatch, ownProps) => ({

  submitLogin: loginData =>
    dispatch(login(loginData))
      .then(userData => dispatch(initApp(userData)))
      .then(userData => {
        const currentLocationSearchString = reactRouterCurrentSearchSelector(null, ownProps);
        const {
          returnTo: returnToParamFromRoute = '/',
        } = getQueryParamsFromQueryString(currentLocationSearchString);

        const currentUserLastVisitedUrl = dispatch(getCurrentUserLastVisitedRoute(userData));

        const urlToCheck = currentUserLastVisitedUrl === null ?
          returnToParamFromRoute :
          currentUserLastVisitedUrl;

        const urlToRedirectAfterLogin = getUserPermittedUrlAfterAuthentication(urlToCheck, userData.permissions);

        /*
        * Явно обрабатываем случай, когда у пользователя нет разрешений на просмотр хотя бы одного из разделов приложения.
        * В таком случае необходимо выполнить выход из системы, чтобы пользователь при входе увидел форму логина.
        *
        * Такая ситуация возникает, когда пользователь авторизован в приложении под служебной учетной записью, которая
        * требуется лишь для какой-то конкретной цели и имеет ограниченный набор разрешений, например, разрешение на обращение
        * к каким-нибудь data- или action-точкам.
        *
        * Пока единственный возможный кейс - это запуск процесса актуализации Плана и Состояния производства из IA для
        * пользователя, который ранее не был авторизован в СА (запуск будет выполнен от имени служебного пользователя)/
        * */
        if(urlToRedirectAfterLogin === null) {
          return dispatch(logout());
        }

        //Для этого экшена своя милдвара, нет смысла размещать его в batch dispatch выше
        dispatch(push(urlToRedirectAfterLogin));
      }),
});


export const LoginScreenContainer = connect(
  null,
  mapDispatchToProps,
)(LoginScreen);

LoginScreenContainer.displayName = 'LoginScreenContainer';