import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  saveSettingsEntityAndAddToStore,
} from '../../../../operations/settings';
import {
  tasksTableSettingsSelector,
} from '../../../../selectors/workerTasksTableSettings';

import { withPermissionsManager } from '../../../../hoc/withPermissionsManager/withPermissionsManager';
import { TasksTableSettings } from './TasksTableSettings';
import {
  WORKER_TASKS_TABLE_MAIN_SETTINGS_NAME,
  sendSettingsSavedNotification,
  WORKER_TASKS_TABLE_SETTINGS_GROUP,
} from '../../../../constants/settings';
import _mapValues from 'lodash/mapValues';
import _pick from 'lodash/pick';
import { TASKS_TABLE_COLUMNS_DATA_KEYS_TO_SAVE } from '../constants';

const mapStateToProps = state => ({
  initialSettingsData: tasksTableSettingsSelector(state),
});

const mapDispatchToProps = {
  saveSettingsEntity: saveSettingsEntityAndAddToStore,
};

const mergeProps = (stateProps, dispatchProps) => {
  const {
    initialSettingsData,
  } = stateProps;

  const {
    saveSettingsEntity,
  } = dispatchProps;

  const onSettingsSave = newTasksTableColumnsData => saveSettingsEntity({
    group: WORKER_TASKS_TABLE_SETTINGS_GROUP,
    name: WORKER_TASKS_TABLE_MAIN_SETTINGS_NAME,
    value: _mapValues(
      newTasksTableColumnsData,
      columnData => _pick(columnData, TASKS_TABLE_COLUMNS_DATA_KEYS_TO_SAVE),
    ),
  })
    .then(() => sendSettingsSavedNotification());

  return {
    initialSettingsData,
    onSettingsSave,
  };
};

export const TasksTableSettingsContainer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
  ),
  withPermissionsManager,
)(TasksTableSettings);

TasksTableSettingsContainer.displayName = 'TasksTableSettingsContainer';