import PropTypes from 'prop-types';
import { MATERIAL_UI_TOOLTIP_PLACEMENT } from '../../../constants/materialUI';


const {
  shape,
  string,
  func,
  oneOfType,
  node,
  oneOf,
} = PropTypes;

export const SIMPLE_SUMMARY_ROW_SCHEMA_TYPE = shape({
  key: string.isRequired,
  label: oneOfType([node, string]),
  rowIdentity: string.isRequired,
  getCustomValue: func,
  getCustomLabel: func,
  getTooltipContent: PropTypes.func,
  tooltipPlacement: oneOf(Object.values(MATERIAL_UI_TOOLTIP_PLACEMENT)),
});

