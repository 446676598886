import { tableFactory } from '../../../tableFactory/tableFactory';
import {
  tablePaginationWithPagePropsAdapter,
} from '../../../Table/propsAdapters/tablePaginationPropsAdapter/tablePaginationPropsAdapter';
import { Pagination } from '../../Pagination/Pagination';
import {
  EntitiesReviewTableComponent,
} from './EntitiesReviewTableComponent/EntitiesReviewTableComponent';

import {
  EntitiesReviewTableFiltersComponent,
}
from './EntitiesReviewTableFiltersComponent/EntitiesReviewTableFiltersComponent';

import './style.css';
import {
  AddFilterLabelTrans,
  ApplyFilterLabelTrans,
  CancelLabelTrans,
  ChooseFiltersLabelTrans,
  RemoveAllFiltersLabelTrans,
} from '../../../../utils/commonTransComponents';
import { entitiesReviewTableComponentPropsAdapter } from './EntitiesReviewTableComponent/constants';


export const EntitiesReviewTableFromFactory = tableFactory({
  tableClassName: 'entities-review-table',

  tableComponents: {
    table: {
      component: EntitiesReviewTableComponent,
      propsAdapter: entitiesReviewTableComponentPropsAdapter,
    },
    pagination: {
      component: Pagination,
      propsAdapter: tablePaginationWithPagePropsAdapter,
    },
    filters: {
      component: EntitiesReviewTableFiltersComponent,
      titles: {
        filtersModalTitle: ChooseFiltersLabelTrans,
        applyFiltersBtnTitle: ApplyFilterLabelTrans,
        cancelFiltersBtnTitle: CancelLabelTrans,
        addFiltersBtnTitle: AddFilterLabelTrans,
        removeAllFiltersBtnTitle: RemoveAllFiltersLabelTrans,
      },
    },
  },
});