import PropTypes from 'prop-types';
import { FUNC_IS_REQUIRED_TYPE, OBJECT_OF_ANY_TYPE } from '../../../constants/propTypes';
import { MATERIAL_UI_VARIANT } from '../../../constants/materialUI';

export const TEXT_FORM_FIELD_PROPS = {
  label: PropTypes.node,
  onChange: FUNC_IS_REQUIRED_TYPE,
  inputProps: OBJECT_OF_ANY_TYPE,
  value: PropTypes.string,
  error: PropTypes.node,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  formKey: PropTypes.string,
  wrapperClassName: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.string,
  isDisabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  helperText: PropTypes.node,
};

export const TEXT_FORM_FIELD_DEFAULT_PROPS = {
  variant: MATERIAL_UI_VARIANT.STANDARD,
  isDisabled: false,
  autoFocus: false,
};