import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { NewSettingsOwnerSelects } from './NewSettingsOwnerSelects/NewSettingsOwnerSelects';
import { TasksTableColumnsSettingsGroup } from './TasksTableColumnsSettingsGroup/TasksTableColumnsSettingsGroup';

import {
  ENTITY_OR_RESOURCE_COMMON_FIELDS_BASED_TYPE,
  FUNC_IS_REQUIRED_TYPE,
  OBJECT_OF_ANY_TYPE,
} from '../../../../../constants/propTypes';

import cn from 'classnames';
import { PERMISSIONS_MANAGER_TYPE } from '../../../../../hoc/withPermissionsManager/constants';

import './style.css';

export class EqClassInDepTasksSettingsEditForm extends Component {

  static propTypes = {
    isCreating: PropTypes.bool.isRequired,
    selectedDepartment: ENTITY_OR_RESOURCE_COMMON_FIELDS_BASED_TYPE,
    selectDepartment: FUNC_IS_REQUIRED_TYPE,
    selectedEquipmentClass: ENTITY_OR_RESOURCE_COMMON_FIELDS_BASED_TYPE,
    selectEquipmentClass: FUNC_IS_REQUIRED_TYPE,
    tasksTableColumnsSettings: OBJECT_OF_ANY_TYPE.isRequired,
    editTasksTableColumnsSettings: FUNC_IS_REQUIRED_TYPE,
    settingsSaveDisableReasonTrans: PropTypes.node,
    onSettingsEditCancel: FUNC_IS_REQUIRED_TYPE,
    onSettingsSave: FUNC_IS_REQUIRED_TYPE,
    isDeleteSettingsButtonDisabled: PropTypes.bool.isRequired,
    isColumnsSettingsChanged: PropTypes.bool.isRequired,
    deleteSelectedSettings: FUNC_IS_REQUIRED_TYPE,
    PermissionsManager: PERMISSIONS_MANAGER_TYPE,
  };

  _renderNewSettingsOwnerSelects = () => {
    const {
      selectedDepartment,
      selectDepartment,
      selectedEquipmentClass,
      selectEquipmentClass,
    } = this.props;
    return(
      <NewSettingsOwnerSelects
          selectedDepartment={selectedDepartment}
          selectDepartment={selectDepartment}
          selectedEquipmentClass={selectedEquipmentClass}
          selectEquipmentClass={selectEquipmentClass}
      />
    );
  };

  _renderTasksTableColumnsSettingsGroup = () => {
    const {
      tasksTableColumnsSettings,
      editTasksTableColumnsSettings,
      settingsSaveDisableReasonTrans,
      onSettingsEditCancel,
      onSettingsSave,
      isDeleteSettingsButtonDisabled,
      deleteSelectedSettings,
      PermissionsManager,
      isColumnsSettingsChanged,
      isCreating,
    } = this.props;

    return(
      <TasksTableColumnsSettingsGroup
          isCreating={isCreating}
          tasksTableColumnsSettings={tasksTableColumnsSettings}
          editTasksTableColumnsSettings={editTasksTableColumnsSettings}
          settingsSaveDisableReasonTrans={settingsSaveDisableReasonTrans}
          onSettingsEditCancel={onSettingsEditCancel}
          onSettingsSave={onSettingsSave}
          isDeleteSettingsButtonDisabled={isDeleteSettingsButtonDisabled}
          deleteSelectedSettings={deleteSelectedSettings}
          PermissionsManager={PermissionsManager}
          isColumnsSettingsChanged={isColumnsSettingsChanged}
      />
    );
  };

  render() {
    const {
      isCreating,
    } = this.props;

    return (
      <div
          className={
            cn(
              'eq-class-in-dep-tasks-edit-form',
              { 'eq-class-in-dep-tasks-edit-form--creating': isCreating },
            )
          }
      >
        {isCreating && this._renderNewSettingsOwnerSelects()}
        {this._renderTasksTableColumnsSettingsGroup()}
      </div>
    );
  }
}
