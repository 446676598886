import React, { Component } from 'react';
import { Trans } from '@lingui/macro';

import { SimpleItemChoosePanel } from '../common/SimpleItemChoosePanel/SimpleItemChoosePanel';

import {
  ADMIN_APP_USERS_ROUTE,
} from '../../constants/routes';
import { FUNC_IS_REQUIRED_TYPE } from '../../constants/propTypes';
import {
  APPS_DATA,
  MASTER_APP,
  PLANNER_APP,
  QUALITY_CONTROL_APP,
  STORAGE_MANAGEMENT_APP,
  WORKER_APP,
} from '../../constants/app';

import cn from 'classnames';

import './style.css';
import { NoDataLabelTrans } from '../../utils/commonTransComponents';
import { PERMISSION, PERMISSIONS_MANAGER_TYPE } from '../../hoc/withPermissionsManager/constants';

import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import { FullScreenReloadIconButton } from '../../fullscreen/FullScreenReloadIconButton/FullScreenReloadIconButton';
import { ReloadIconButton } from '../ReloadIconButton/ReloadIconButton';
import { SHOW_APP_RELOAD_BUTTONS_ONLY_ON_FULL_SCREEN } from '../../fullscreen/constants';


const APP_CHOOSING_SCREEN_CARD_ITEMS_IDENTITIES = [
  PLANNER_APP,
  STORAGE_MANAGEMENT_APP,
  MASTER_APP,
  QUALITY_CONTROL_APP,
  WORKER_APP,
];

const APP_CHOOSING_SCREEN_GRID_PROPS = {
  direction: 'column',
  justify: 'center',
  alignItems: 'center',
};

const APP_CHOOSING_ITEMS_SHOW_DELAY_IN_MS = 200;

export class AppChoosingScreen extends Component {

  static propTypes = {
    onAppSelect: FUNC_IS_REQUIRED_TYPE,
    PermissionsManager: PERMISSIONS_MANAGER_TYPE,
  };

  constructor(props) {
    super(props);
    this.showAppChoosingItemsTimeOut = null;
    this.state = { areAppChoosingItemsHidden: true };
  }

  componentDidMount() {
    //после маунта компонента по таймауту сбрасываем флаг areAppChoosingItemsHidden, при этом сбросится класс у
    //компонента и элементы появятся анимировано
    this.showAppChoosingItemsTimeOut = setTimeout(() => {
      this.setState({ areAppChoosingItemsHidden: false });
      this.showAppChoosingItemsTimeOut = null;
    }, APP_CHOOSING_ITEMS_SHOW_DELAY_IN_MS);
  }

  componentWillUnmount() {
    if(this.showAppChoosingItemsTimeOut !== null) {
      clearTimeout(this.showAppChoosingItemsTimeOut);
      this.showAppChoosingItemsTimeOut = null;
    }
  };

  _createAppChoosingItemsList = () =>
    APP_CHOOSING_SCREEN_CARD_ITEMS_IDENTITIES
      .filter(appIdentity =>
        this.props.PermissionsManager.isGranted(
          APPS_DATA[appIdentity].appReviewPermission,
        ))
      .map(appIdentity => {
        const {
          appMainRouteLink,
          appTitle,
          appDescription,
          disabled,
          tooltipContent,
        } =  APPS_DATA[appIdentity];

        return {
          itemId: appIdentity,
          itemData: { appMainRouteLink },
          itemContent: this._renderAppChoosingItemContent(appTitle, appDescription),
          gridItemWrapClassName: 'app-choosing-screen__app-card-grid-wrap',
          itemClassName: 'app-choosing-screen__app-card',
          disabled,
          tooltipContent,
        };
      });

  _renderAppChoosingItemContent = (appTitle, appDescription) =>
    <React.Fragment>
      {this._renderAppTitle(appTitle)}
      {this._renderAppDescription(appDescription)}
    </React.Fragment>;

  _renderAppTitle = appTitle =>
    <div className="app-choosing-screen__app-card-title">
      <span className="app-choosing-screen__app-card-title-label">
        <Trans id="label@app">
          Приложение
        </Trans>
      </span>
      <span className="app-choosing-screen__app-card-title-value">
        "{appTitle}"
      </span>
    </div>;

  _renderAppDescription = appDescription =>
    <div className="app-choosing-screen__app-card-description">
      {appDescription}
    </div>;

  _handleAppSelect = (_, { appMainRouteLink }) => this.props.onAppSelect(appMainRouteLink);

  _renderAppChoosingScreenTopPanel = () => (
    <div className="app-choosing-screen__top-panel">
      {
        SHOW_APP_RELOAD_BUTTONS_ONLY_ON_FULL_SCREEN ?
          <FullScreenReloadIconButton /> :
          <ReloadIconButton />
      }

      {
        this.props.PermissionsManager.isGranted(PERMISSION.REVIEW_ADMIN_APP) ?
          <IconButton
              className="app-choosing-screen__top-panel-admin-app-enter-button"
              onClick={() => this.props.onAppSelect(ADMIN_APP_USERS_ROUTE)}
              size="large"
          >
            <SettingsIcon/>
          </IconButton> :
          null
      }

    </div>
  );

  render() {
    return (
      <div
          className={
            cn(
              'app-choosing-screen',
              { 'app-choosing-screen--items-hidden': this.state.areAppChoosingItemsHidden },
            )
          }
      >
        {this._renderAppChoosingScreenTopPanel()}
        <SimpleItemChoosePanel
            itemsList={this._createAppChoosingItemsList()}
            onSelectItem={this._handleAppSelect}
            gridProps={APP_CHOOSING_SCREEN_GRID_PROPS}
            noItemsText={NoDataLabelTrans}
        />
      </div>
    );
  }
}
