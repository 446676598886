import { createSelector } from 'reselect';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import { schemaForModelSelector } from '../schemaModel/selectors';
import { DEFAULT_TABLE_PAGE_SIZE } from '../../constants/table';


export const tableIdFromPropsSelector = (_, { tableId }) => tableId;
export const tableModelFromPropsSelector = (_, { tableModel }) => tableModel;

export const tableStateSelector = state => state.tables;

export const tableStateByIdSelector = (state, { tableId }) =>
  _get(tableStateSelector(state), tableId);

export const tableActivePageSelector = (state, { tableId }) =>
  _get(tableStateByIdSelector(state, { tableId }), 'activePage', 1);

export const tableColumnsFilterParamsSelector = (state, { tableId }) =>
  _get(tableStateByIdSelector(state, { tableId }), 'filterParams');

export const tableCurrentRemoteDataSelector = (state, { tableId }) =>
  _get(tableStateByIdSelector(state, { tableId }), 'remoteData', null);

export const tableCurrentRemoteItemsIdsSelector = (state, { tableId }) =>
  _get(tableCurrentRemoteDataSelector(state, { tableId }), 'currentRemoteItemsIds');

export const tableCurrentRemoteItemsByIdSelector = (state, { tableId }) =>
  _get(tableCurrentRemoteDataSelector(state, { tableId }), 'currentRemoteItemsById');

export const tableRemoteTotalItemsAmountSelector = (state, { tableId }) =>
  _get(tableCurrentRemoteDataSelector(state, { tableId }), 'totalRemoteItemsAmount', 0);

export const tableCurrentRemoteSummaryDataSelector = (state, { tableId }) =>
  _get(tableCurrentRemoteDataSelector(state, { tableId }), 'currentSummaryData');

export const tableSelectedRowsSelector = (state, { tableId }) =>
  _get(tableStateByIdSelector(state, { tableId }), 'selectedRows');

/*
* Подробнее про то, почему в текущей реализации информация о сортировке и о количестве отображаемых рядов на
* странице таблицы привязывается к модели таблицы, а не к id таблицы, описано в reduсers/table/reducer.js.
* Именно поэтому селекторы tableSortParamsSelector и tablePageSizeSelector получают информацию из schemaModel
*/
const tableSchemaModelSelector = (state, { tableModel }) => schemaForModelSelector(state, { model: tableModel });

export const tableSortParamsSelector = createSelector(
  tableSchemaModelSelector,
  schemaForModel => _get(schemaForModel, ['sortParams']),
);

export const tablePageSizeSelector = (state, { tableModel, defaultPageSize = DEFAULT_TABLE_PAGE_SIZE }) =>
  _get(tableSchemaModelSelector(state, { tableModel }), 'pageSize', defaultPageSize);


/*
* ВАЖНО!
* Этот селектор объединяет все параметры таблицы и формирует из этого объекта для удобства. НО он общий для
* всех таблиц и принимает id и модель таблицы как параметры. Т.е. если он вызывается в процессе отрисовки какого-то
* компонента для разных таблиц, то будет получать новые параметры, мемоизация не будет срабатывать и постоянно будет
* возвращаться новый объект. Очевидно, что если это используется в контейнере (что, в целом, при обычном использовании,
* конечно, бывает не так часто) и этот объект просто возвращается из mapStateToProps, то, т.к. объект новый, это будет
* приводить к ненужным перерисовкам, т.к. параметры самой таблицы могли и не измениться (как известно, connect, по
* умолчанию, производит только shallowEqual возвращаемых из mapStateToProps пропсов).
* Особенно, стОит обратить на это внимание, когда в интерфейсе на одном экране несколько таблиц.
* Этот селектор, по сути, исключительно для удобства, чтобы не вызывать 5 его внутренних селекторов каждый раз в тех
* местах где нужно получить все параметры таблицы. Т.е. при использовании в контейнерах, нужно обращать на это внимание
* и анализировать, может ли он вызываться для разных таблиц пока контейнер примонтирован. В этом случае, чтобы избежать,
* ненужных перерисовок, получаемый объект следует распаковывать и возвращать из maStateToProps уже сами параметры таблицы:
* const mapStateToProps = (state) => {
*   const {
*     activePage,
*     pageSize,
*     sortParams,
*     filterParams,
*     remoteData
*   } = tableParamsSelector(state, { tableId: 'ID', tableModel: 'MODEL'});
*   return{
*     activePage,
*     pageSize,
*     sortParams,
*     filterParams,
*     remoteData
*   }
* }
*
* Всё это справедливо и для случаев, когда этот селектор используется как входной параметр для других селекторов,
* реализующих мемоизацию!
*
* Это, в целом, конечно, очевидные вещи и касаются логики работы всех селекторов, возвращающих новые объекты и контейнеры,
* redux, просто хотелось отметить этот момент ещё раз, т.к. при использовании возникали случаи, когда это не учитывалось.
* Были идеи, даже удалить этот селектор, чтобы не было возможности использовать его неправильно, но альтернатива -
* это вызывать каждый раз 5 внутренних селекторов, поэтому решил пока, в текущей реализации, оставить
* */
export const tableParamsSelector = createSelector(
  tableActivePageSelector,
  tablePageSizeSelector,
  tableSortParamsSelector,
  tableColumnsFilterParamsSelector,
  tableCurrentRemoteDataSelector,
  (
    activePage,
    pageSize,
    sortParams,
    filterParams,
    remoteData,
  ) => ({
    activePage,
    pageSize,
    sortParams,
    filterParams,
    remoteData,
  }),
);

export const isTableItemsAlreadyInStoreSelector = (state, { tableId, tableModel, newTableFetchParams = {} }) => {
  const tableRemoteData = tableCurrentRemoteDataSelector(state, { tableId });

  if(tableRemoteData === null) return false;

  const {
    activePage,
    pageSize,
    sortParams,
    filterParams,
  } = tableParamsSelector(state, { tableId, tableModel });

  const {
    activePage: newRequestActivePage,
    pageSize: newRequestPageSize,
    sortParams: newRequestSortParams,
    filterParams: newRequestFilterParams,
  } = newTableFetchParams;

  return(
    activePage === newRequestActivePage &&
    pageSize === newRequestPageSize &&
    _isEqual(sortParams, newRequestSortParams) &&
    _isEqual(filterParams, newRequestFilterParams)
  );
};


/*
* Здесь логика формирования строки урла роутера с учетом старого АПИ (НЕ АПИ КОЛЛЕКЦИЙ), когда будет произведен переход
* на АПИ коллекций, если захотим изменить формат в урле для роутинга, то здесь нужно будет вносить корректировки.
*
* В данный момент, практически, никак не учитываются типы фильтров, предполагается что они всегда contains. Для случаев,
* где сейчас использует гет параметры для таблиц, этого пока было достаточно
* */
export const transformTableParamsToUrlGetParams = ({
                                                     activePage,
                                                     pageSize,
                                                     sortParams,
                                                     filterParams,
                                                   }) => {

  const urlSortParams = getUrlSortParams(sortParams);

  const urlsFiltersParams = getUrlFilterParams(filterParams);

  return {
    page: activePage,
    limit: pageSize,
    ...urlSortParams,
    ...urlsFiltersParams,
  };
};


const getUrlSortParams = tableSortParamsInState => {
  if(!tableSortParamsInState) return {};

  const {
    column: sortBy,
    asc,
  } = tableSortParamsInState[0];

  return {
    sortBy,
    asc,
  };
};

const getUrlFilterParams = tableColumnsFiltersInState => {
  if(_isEmpty(tableColumnsFiltersInState)) return {};

  const columnNamesToFilter = Object.keys(tableColumnsFiltersInState);

  const columnFiltersArray = columnNamesToFilter
    .map(columnName => tableColumnsFiltersInState[columnName].filterValue);

  return {
    columnNames: columnNamesToFilter.join(','),
    search: JSON.stringify(columnFiltersArray),
  };
};

export const urlGetParamsFromStoreForTableSelector = createSelector(
  tableActivePageSelector,
  tablePageSizeSelector,
  tableSortParamsSelector,
  tableColumnsFilterParamsSelector,
  (activePage, pageSize, sortParams, filterParams) =>
    transformTableParamsToUrlGetParams({
      activePage,
      pageSize,
      sortParams,
      filterParams,
    }),
);
