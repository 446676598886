import { createSelector } from 'reselect';

import _get from 'lodash/get';
import {
  DEPARTMENT_MODEL,
  ENTITY_BATCH_MODEL,
  SHEET_MODEL,
  SHEET_OPERATION_MODEL, ENTITY_MODEL,
  EQUIPMENT_CLASS_MODEL,
  OPERATION_MODEL,
  ORDER_MODEL,
  USER_MODEL,
  ROLE_MODEL,
  USER_ROLE_MODEL,
  ENTITY_BATCH_TRANSACTION_MODEL,
} from '../../constants/models';

export const entitiesStateSelector = state => state.entities;

export const entitySelector = (state, { model, id }) =>
  _get(entitiesStateSelector(state), [model, id]);

export const entitiesForModelSelector = (state, { model }) =>
  entitiesStateSelector(state)[model];

export const entitiesForModelSelectorFactory = model => createSelector(
  state => entitiesForModelSelector(state, { model }),
  entities => entities || {},
);

export const entitiesListForModelSelectorFactory = model => createSelector(
  state => entitiesForModelSelector(state, { model }),
  entities => Object.values(entities || {}),
);

export const sheetEntitiesSelector = entitiesForModelSelectorFactory(SHEET_MODEL);
export const entityBatchEntitiesSelector = entitiesForModelSelectorFactory(ENTITY_BATCH_MODEL);
export const sheetOperationEntitiesSelector =
  entitiesForModelSelectorFactory(SHEET_OPERATION_MODEL);
export const operationEntitiesSelector = entitiesForModelSelectorFactory(OPERATION_MODEL);
export const equipmentClassEntitiesSelector = entitiesForModelSelectorFactory(EQUIPMENT_CLASS_MODEL);
export const departmentEntitiesSelector = entitiesForModelSelectorFactory(DEPARTMENT_MODEL);
export const entityModelEntitiesSelector = entitiesForModelSelectorFactory(ENTITY_MODEL);
export const orderEntitiesSelector = entitiesForModelSelectorFactory(ORDER_MODEL);
export const userEntitiesSelector = entitiesForModelSelectorFactory(USER_MODEL);
export const roleEntitiesSelector = entitiesForModelSelectorFactory(ROLE_MODEL);
export const userRoleEntitiesSelector = entitiesForModelSelectorFactory(USER_ROLE_MODEL);
export const entityBatchTransactionEntitiesSelector = entitiesForModelSelectorFactory(ENTITY_BATCH_TRANSACTION_MODEL);