import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _omit from 'lodash/omit';
import { useSelector } from 'react-redux';
import { isNotificationActive, updateNotification } from '@bfg-frontend/notification-system';
import {
  clearNotifications,
  NOTIFICATION_LEVEL,
  PreventToastTransition,
  sendTopCenterNotification,
} from '../../constants/notification';
import { tableActivePageSelector, tableCurrentRemoteItemsIdsSelector } from '../../reducers/table/selectors';
import _get from 'lodash/get';
import _isFunction from 'lodash/isFunction';
import _isEmpty from 'lodash/isEmpty';

/**
 *
 * @param toastId { string } - идентификатор тоста, в котором будет рендерится количество выбранных
 * строк и кнопки доступных действий (сбросить, удалить)
 *
 * @param renderSelectedRowsActionsToast { function } - калбек, который принимает selectedRowsById, resetSelectedRows и рендерит
 * содержимое тоста
 *
 * @param filterSelectedRowsIdsCb { function } - калбек фильтра, который принимает идентификатор строки таблицы.
 * Используется для фильтрации выбранных строк, например чтобы запретить выбирать позиции заказов, которые в производстве
 *
 * @param tableId { string } - идентификатор таблицы, используется для получения данных таблицы.
 *
 * @param tableModel { string } - основная модель таблицы, используется для получения массива
 * идентификаторов строк таблицы из store.
 * Параметр не используется если задан tableRowsIdsArray.
 *
 * @param tableRowsIdsArray { array } - массив идентификаторов строк таблицы. Используется для различных вычислений
 * строк с зажатым shift. Параметр не нужно задавать для серверных таблиц. Для не серверных он обязателен.
 *
 * @param setSelectedRowsFromProps { function(selectedRows) } - калбек для записи выбранных строк таблицы. Если задан
 * этот параметр, то локальный стейт хука не используется. Формат записи выбранных строк формируется в хуке.
 * setSelectedRowsFromProps должен просто полностью перезаписывать данные в хранилище.
 *
 * @param selectedRowsFromProps { object } - выбранные строки, переданные извне. Используется в комбинации с
 * setSelectedRowsFromProps, когда локальное хранилище хука не нужно.
 *
 * @return {{onCheckboxChange: ((function(*, *, *, *): (void))|*), selectedRows: T, resetSelectedRows: (function(): void)}}
 */

export const useTableCheckboxSelectedRows = ({
  toastId,
  renderSelectedRowsActionsToast,
  filterSelectedRowsIdsCb,
  tableId,
  tableModel,
  tableRowsIdsArray: tableRowsIdsArrayFromProps,
  setSelectedRows: setSelectedRowsFromProps,
  selectedRows: selectedRowsFromProps,
}) => {


  const tableActivePage = useSelector(state => tableActivePageSelector(state, { tableId }));

  const tableRowsIdsArray = useSelector(state => {
    if (tableRowsIdsArrayFromProps) return tableRowsIdsArrayFromProps;

    return _get(tableCurrentRemoteItemsIdsSelector(state, { tableId }), [tableModel], []);
  });


  // Для реализации логики выбора строк с зажатым shift на нескольких страницах храним данные в разрезе страниц таблицы.
  const [selectedRowsByPage, setSelectedRowsByPage] = useState({});


  const setSelectedRows = useMemo(() => {
    return _isFunction(setSelectedRowsFromProps) ? setSelectedRowsFromProps : setSelectedRowsByPage;
  }, [setSelectedRowsFromProps, setSelectedRowsByPage]);

  const selectedRows = useMemo(() => {
    return _isFunction(setSelectedRowsFromProps) ? selectedRowsFromProps : selectedRowsByPage;
  }, [selectedRowsFromProps, setSelectedRowsFromProps, selectedRowsByPage]);

  const selectedRowsById = useMemo(
    () => Object.assign({}, ...Object.values(selectedRows)),
    [selectedRows],
  );

  /*
  * собираем массив интервалов выбранных значений. Каждый интервал представляет собой массив с начальным и конечным
  * индексом, причем начальный индекс всегда меньше конечного.
  * Пример того, как выглядит значение если на странице выбраны элементы с индексами 0,1,2,3,6,7,8,14,15:
  * [
  *   [0,3],
  *   [6,8],
  *   [14,15],
  * ]
  * */
  const selectedRowsIntervalsForCurrentPage = useMemo(
    () => {
      const intervals = [];
      let intervalStartIndex = null;

      /*
      * формируем итоговый массив интервалов выбранных значений
      * */
      for(let i = 0; i < tableRowsIdsArray.length; i++) {
        /*
        * если старт интервала не установлен и строка с текущим индексом выбрана на странице, устанавливаем текущий
        * индекс как старт интервала и переходим к проверке следующего индекса
        * */
        const currentIndexId = tableRowsIdsArray[i];

        if(intervalStartIndex === null && selectedRowsById[currentIndexId]) {
          intervalStartIndex = i;
        }

        /*
        * если старт интервала установлен и строка с текущим индексом - это конце интервала, устанавливаем текущий
        * индекс как конец интервала, добавляем интервал к результирующему массиву и переходим к проверке следующего индекса
        * */
        const nextIndexId = tableRowsIdsArray[i + 1];

        if(intervalStartIndex !== null && !selectedRowsById[nextIndexId]) {
          intervals.push([intervalStartIndex, i]);
          intervalStartIndex = null;
        }
      }

      return intervals;
    },
    [selectedRowsById, tableRowsIdsArray],
  );

  /*
  * lastSelectedRef - объект, в котором в разрезе страниц таблицы хранятся идентификаторы последних выбранных строк,
  * по одному значению на страницу. Этот параметр используется для логики выбора строк с зажатым shift. Он обновляется
  * каждый раз когда:
  * 1. выбираем строку (делаем чекбокс активным) без зажатого shift
  * 2. ничего не выбрано и выбираем строку с зажатым shift
  * 3. кликаем по выбранному элементу с зажатым shift и снимаем его выделение
  * Этот идентификатор используется для выбора строк, то есть выбор строк с зажатым shift всегда начинается с
  * lastSelectedRef, далее строки выбираются вверх или вниз до той, по которой кликнули
  */
  const lastSelectedRef = useRef({});

  const updateLastSelectedRef = useCallback(rowId => {
    lastSelectedRef.current = {
      ...lastSelectedRef.current,
      [tableActivePage]: rowId,
    };
  }, [tableActivePage]);

  const updateLastSelectedAndAddRowToSelected = useCallback(itemId => {
    updateLastSelectedRef(itemId);

    return setSelectedRows({
      ...selectedRows,
      [tableActivePage]: {
        ...selectedRows[tableActivePage],
        [itemId]: true,
      },
    });
  }, [setSelectedRows, selectedRows, tableActivePage, updateLastSelectedRef]);

  const unselectRows = useCallback(newFilteredIdsToUnselect => {
    const newSelectedRowsForCurrentPage = _omit(selectedRows[tableActivePage], newFilteredIdsToUnselect);

    /*
    * Ели сняли последний чекбокс на странице, то удаляем эту страницу из selectedRows
    */
    return setSelectedRows(
      _isEmpty(newSelectedRowsForCurrentPage) ? _omit(selectedRows, [tableActivePage]) : {
        ...selectedRows,
        [tableActivePage]: newSelectedRowsForCurrentPage,
      },
    );
  }, [setSelectedRows, selectedRows, tableActivePage]);

  /*
  * В некоторых кейсах нам необходимо знать, где был прошлый клик, даже если он не был началом интервала, а был только
  * промежуточным кликом. Для этого создаем previouslySelectedRef и записываем туда ИНДЕКС элемента по которому был
  * произведен клик. Записываем это значение всегда перед return из обработчика клика, чтобы гарантировать, что это
  * значение будет использоваться только при следующем клике на чекбокс.
  *
  * TODO скорректировать нейминг рефов и связанной логики, для более легкого восприятия кода
  * */
  const previouslySelectedRef = useRef({});
  const updatePreviouslySelectedRef = useCallback(rowId => {
    previouslySelectedRef.current = {
      ...previouslySelectedRef.current,
      [tableActivePage]: rowId,
    };
  }, [tableActivePage]);

  const onCheckboxChange = useCallback(
    (checked, itemId, rowData, e) => {

      const lastSelectedForActivePage = lastSelectedRef.current[tableActivePage];
      const previouslySelectedForActivePage = previouslySelectedRef.current[tableActivePage];

      // обработка логики выбора с зажатым shift
      if (e.shiftKey) {
        e.preventDefault();

        // индекс строки, которую выбирают после того, как выбрали начальную, от которой начнётся выбор интервала
        const indexOfSelected = tableRowsIdsArray.indexOf(itemId);

        // П.2 из коммента к lastSelectedRef
        if (lastSelectedForActivePage === undefined && checked) {
          updatePreviouslySelectedRef(indexOfSelected);
          return updateLastSelectedAndAddRowToSelected(itemId);
        }

        // индекс строки с которой начнётся выбор
        const indexOfLastSelected = tableRowsIdsArray.indexOf(lastSelectedForActivePage);


        /*
        * вычисляем, является ли интервал возрастающим в разрезе индексов границ интервала, т.е. идет ли он
        * сверху вниз или снизу вверх.
        * */
        const isIndexRaisingInterval = indexOfSelected > indexOfLastSelected;

        /*
        * вычисляем, каким было последнее действие с элементом ряда: выбор или снятие выбора
        * */
        const isLastSelectedChecked = !!selectedRowsById[lastSelectedForActivePage];

        /*
        * Далее события могут развиваться по нескольким сценариям:
        *  1. isLastSelectedChecked = true и новый индекс больше чем indexOfLastSelected - в этом случае должен быть
        *     выбран новый интервал от indexOfLastSelected до нового индекса
        *  2. isLastSelectedChecked = true и новый индекс меньше чем indexOfLastSelected - в этом случае должен быть
        *     выбран новый интервал от нового индекса до indexOfLastSelected
        *  3. isLastSelectedChecked = true, новый индекс больше чем indexOfLastSelected и новый индекс попадает в
        *     верхнеуровневый интервал выбранных строк. В таком случае стандартное поведение - уменьшить верхнеуровневый
        *     интервал до размера от indexOfLastSelected до нового индекса
        *  4. isLastSelectedChecked = true, новый индекс меньше чем indexOfLastSelected и новый индекс попадает в
        *     верхнеуровневый интервал выбранных строк. В таком случае стандартное поведение - уменьшить верхнеуровневый
        *     интервал до размера от нового индекса до indexOfLastSelected
        *  5. isLastSelectedChecked = false, новый индекс больше чем indexOfLastSelected и новый индекс попадает в
        *     верхнеуровневый интервал выбранных строк. В таком случае стандартное поведение - снять выбор с интервала
        *     от (indexOfLastSelected + 1) до нового индекса. Увеличиваем на единицу, т.к. с indexOfLastSelected выбор
        *     был снят при клике на него
        *  6. isLastSelectedChecked = false, новый индекс меньше чем indexOfLastSelected и новый индекс попадает в
        *     верхнеуровневый интервал выбранных строк. В таком случае стандартное поведение - снять выбор с интервала
        *     от нового индекса до (indexOfLastSelected - 1). Уменьшаем на единицу, т.к. с indexOfLastSelected выбор
        *     был снят при клике на него
        * */

        /*
        * Вычисляем границу интервала для элемента из lastSelectedRef. Граница нужна, чтобы корректно проверить,
        * является ли текущий интервал частью верхнеуровневого интервала выбранных элементов.
        * Для кейсов 1-4 это просто indexOfLastSelected, а для кейсов 5-6 требуется дополнительная обработка из-за
        * того, что в этих кейсах с indexOfLastSelected уже снят выбор
        * */
        let lastSelectedIntervalEnd;
        if(isLastSelectedChecked) {
          lastSelectedIntervalEnd = indexOfLastSelected;
        } else {
          lastSelectedIntervalEnd = isIndexRaisingInterval ?
            indexOfLastSelected + 1 :
            indexOfLastSelected - 1;
        }

        /*
        * Интервал может быть вложенным в кейсах 3-6.
        * Вычисляем, является ли выбранный интервал вложенным по отношению к существующим интервалам выбранных элементов.
        * Для этого ищем среди всех интервалов интервал, который бы полностью включал в себя границы текущего интервала.
        * */
        const wrappingInterval = selectedRowsIntervalsForCurrentPage
          .find(interval =>
            interval[0] <= Math.min(lastSelectedIntervalEnd, indexOfSelected) &&
            interval[1] >= Math.max(lastSelectedIntervalEnd, indexOfSelected));

        /*
        * Выполняем обработку для кейсов 3-6
        * */
        if(wrappingInterval) {

          /*
          * если повторно кликнули по началу интервала то отменяем выбор всего интервала
          * */
          if(indexOfSelected === indexOfLastSelected) {

            const idsToUnselect = previouslySelectedForActivePage > indexOfSelected ?
              tableRowsIdsArray.slice(indexOfLastSelected, previouslySelectedForActivePage + 1) :
              tableRowsIdsArray.slice(previouslySelectedForActivePage, indexOfLastSelected + 1);

            updatePreviouslySelectedRef(indexOfSelected);

            unselectRows(idsToUnselect);
          }

          /*
          * обработка для кейсов 3,4
          * кейсы в которых мы уменьшаем верхнеуровневый интервал нажатием на один из его элементов
          * */
          if(isLastSelectedChecked) {

          /*
          * формируем интервал идентификаторов, для которых необходимо снять выделение
          * */
            const idsToUnselect = isIndexRaisingInterval ?
              tableRowsIdsArray.slice(indexOfSelected, wrappingInterval[1] + 1) :
              tableRowsIdsArray.slice(wrappingInterval[0], indexOfSelected + 1);

            /*
            * фильтруем, если необходимо
            * */
            const newFilteredIdsToUnselect = _isFunction(filterSelectedRowsIdsCb) ?
              idsToUnselect.filter(filterSelectedRowsIdsCb) :
              idsToUnselect;

            /*
            * снимаем выделение
            * */
            updatePreviouslySelectedRef(indexOfSelected);

            return unselectRows(newFilteredIdsToUnselect);
          }

          /*
          * обработка для кейсов 5,6
          * кейсы в которых мы снимаем выделение с части элементов верхнеуровневого интервала.
          *
          * формируем интервал идентификаторов, для которых необходимо снять выделение
          * */
          const idsToUnselect = isIndexRaisingInterval ?
            tableRowsIdsArray.slice(indexOfLastSelected, indexOfSelected + 1) :
            tableRowsIdsArray.slice(indexOfSelected, indexOfLastSelected + 1);

          /*
          * фильтруем, если необходимо
          * */
          const newFilteredIdsToUnselect = _isFunction(filterSelectedRowsIdsCb) ?
            idsToUnselect.filter(filterSelectedRowsIdsCb) :
            idsToUnselect;

          /*
          * снимаем выделение
          * */
          updatePreviouslySelectedRef(indexOfSelected);

          return unselectRows(newFilteredIdsToUnselect);
        }

        if(!checked) {
          /*
          * формируем интервал идентификаторов, для которых необходимо снять выделение
          * */
          const idsToUnselect = isIndexRaisingInterval ?
            tableRowsIdsArray.slice(indexOfLastSelected, indexOfSelected + 1) :
            tableRowsIdsArray.slice(indexOfSelected, indexOfLastSelected + 1);

          /*
          * фильтруем, если необходимо
          * */
          const newFilteredIdsToUnselect = _isFunction(filterSelectedRowsIdsCb) ?
            idsToUnselect.filter(filterSelectedRowsIdsCb) :
            idsToUnselect;

          /*
          * снимаем выделение
          * */
          updatePreviouslySelectedRef(indexOfSelected);

          return unselectRows(newFilteredIdsToUnselect);
        }


        /*
        * формируем интервал идентификаторов, для которых необходимо установить выделение
        * */
        const newIdsToSelect = isIndexRaisingInterval ?
          tableRowsIdsArray.slice(indexOfLastSelected, indexOfSelected + 1) :
          tableRowsIdsArray.slice(indexOfSelected, indexOfLastSelected + 1);

        /*
        * фильтруем, если необходимо
        * */
        const newFilteredIdsToSelect = _isFunction(filterSelectedRowsIdsCb) ?
          newIdsToSelect.filter(filterSelectedRowsIdsCb) :
          newIdsToSelect;

        /*
        * фиксируем выбранный интервал идентификаторов не теряя остальные интервалы и значения на этой странице
        * */
        updatePreviouslySelectedRef(indexOfSelected);

        return setSelectedRows({
          ...selectedRows,
          [tableActivePage]: {
            ...selectedRows[tableActivePage],
            ...newFilteredIdsToSelect
              .reduce((acc, entryId) => {
                acc[entryId] = true;
                return acc;
              }, {}),
          },
        });
      }

      // обработка выбора без shift
      if (checked) {
        // П.1 из коммента к lastSelectedRef
        return updateLastSelectedAndAddRowToSelected(itemId);
      }

      const isOnlyOneRowSelectedOnCurrentPage = Object.keys(selectedRows[tableActivePage]).length === 1;

      // если снимаем галочку с lastSelected или с единственной выбранной строки то удаляем lastSelected если нет
      // выбранных строк, чтобы при следующем выборе с зажатым shift выбралась только одна строка
      if (itemId === lastSelectedForActivePage && isOnlyOneRowSelectedOnCurrentPage) {
        updateLastSelectedRef(undefined);

        // Если был снят последний чекбокс на странице, то удаляем эту страницу из стейта
        return setSelectedRows(_omit(selectedRows, [tableActivePage]));
      }

      // в остальных кейсах обновляем данные в lastSelectedRef, чтобы там всегда хранилась последняя выбранная запись
      updateLastSelectedRef(itemId);

      return unselectRows([itemId]);
    },
    [
      updatePreviouslySelectedRef,
      selectedRows,
      setSelectedRows,
      tableRowsIdsArray,
      selectedRowsIntervalsForCurrentPage,
      updateLastSelectedAndAddRowToSelected,
      tableActivePage,
      updateLastSelectedRef,
      selectedRowsById,
      filterSelectedRowsIdsCb,
      unselectRows,
    ],
  );

  const resetSelectedRows = useCallback(
    () => {
      lastSelectedRef.current = {};
      previouslySelectedRef.current = {};
      setSelectedRows({});
    },
    [setSelectedRows],
  );

  /*
  Обработка логики нотификейшена с количеством выбранных строк
  */
  useEffect(
    () => {
      if (!_isFunction(renderSelectedRowsActionsToast)) return;

      const isSelectedRowsEmpty = _isEmpty(selectedRowsById);

      if (isNotificationActive(toastId)) {

        if (isSelectedRowsEmpty) {
          return clearNotifications([toastId]);
        }

        return updateNotification(
          toastId,
          {
            render: renderSelectedRowsActionsToast(selectedRowsById, resetSelectedRows),
          },
        );
      }

      if (isSelectedRowsEmpty) return;

      sendTopCenterNotification(
        renderSelectedRowsActionsToast(selectedRowsById, resetSelectedRows),
        NOTIFICATION_LEVEL.INFO,
        {
          id: toastId,
          closeButton: false,
          timeOut: 0,
          closeOnClick: false,
          transition: PreventToastTransition,
        },
      );
    },
    [
      renderSelectedRowsActionsToast,
      resetSelectedRows,
      selectedRowsById,
      toastId,
    ],
  );

  useEffect(
    () =>
      () => clearNotifications([toastId]),
    [toastId],
  );

  return{
    selectedRows: selectedRowsById,
    resetSelectedRows,
    onCheckboxChange,
  };
};
