import {
  DefectReasonLabelTrans,
  DefectStateDateLabelTrans,
  UserNotedDefectLabelTrans,
} from '../../../utils/commonTransComponents';
import moment from 'moment/moment';
import { FORMAT_SHORT_TIME } from '../../../constants/dateFormats';
import _isNil from 'lodash/isNil';
import { MATERIAL_UI_TOOLTIP_PLACEMENT } from '../../../constants/materialUI';

export const INAPPROPRIATE_SHEET_SECONDARY_SUMMARY_SCHEMA = [
  {
    key: 'entityBatchTransactionCreateStamp',
    label: DefectStateDateLabelTrans,
    rowIdentity: 'current-sheet-entity-batch-transaction-create-stamp',
    getCustomValue: ({ entityBatchTransactionCreateStamp }) =>
      moment(entityBatchTransactionCreateStamp).format(FORMAT_SHORT_TIME),
  },
  {
    key: 'userName',
    label: UserNotedDefectLabelTrans,
    rowIdentity: 'current-sheet-user-noted-defect',
  },
  {
    key: 'defectReason',
    label: DefectReasonLabelTrans,
    rowIdentity: 'current-sheet-defect-reason',
    getCustomValue: ({ defectReason }) =>
      _isNil(defectReason) || defectReason === '' ?
        '-' :
        defectReason,
    getTooltipContent: value =>
      _isNil(value) || value === '' ?
       '-' :
       value,
    tooltipPlacement: MATERIAL_UI_TOOLTIP_PLACEMENT.TOP_START,
  },
];
