import {
  fetchEquipmentClassInDepartmentTasksRemoteTableData,
  fetchTasksRemoteTableData,
} from '../../../../operations/tasks';
import { allTasksTablesIdsSelector } from '../../../../selectors/taskView';
import { routerPathnameSelector } from '../../../../reducers/router/selectors';
import {
  MASTER_APP_COMPLETED_TASKS_ROUTE,
  MASTER_APP_TASKS_TO_DO_ROUTE,
  PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE,
  WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE,
  WORKER_APP_TASKS_ROUTE,
} from '../../../../constants/routes';
import { matchPath } from 'react-router-dom';
import { clearTableRemoteData, reFetchRemoteTableData } from '../../../../reducers/table/actions';
import { createSheetTypeOperationsTableId, getEquipmentClassInDepartmentTasksTableId } from '../../../../utils/tables';
import { SHEET_TYPE } from '../../../../constants/sheets';
import { fetchReviewedSheetOperationsRemoteTableEntities } from '../../../../operations/sheets';
import { getMasterAppDepartmentIdsFromRouteParam } from '../../../../components/MasterApp/MasterWorkspace/masterAppDepartmentIdsRouteParam';
import { MASTER_TASKS_TO_DO_TABLE_ID } from '../../../../components/MasterApp/MasterTasksToDo/constants';
import { MASTER_COMPLETED_TASKS_TABLE_ID } from '../../../../components/MasterApp/MasterCompletedTasks/constants';
import {
  fetchMasterDepartmentsCompletedTasksRemoteTableData,
  fetchMasterDepartmentsTasksToDoRemoteTableData,
} from '../../../../operations/masterWorkspace/index';
import { masterTasksToDoAdditionalFiltersSelector } from '../../../../reducers/masterApp/tasksToDoAdditionalFilters/selectors';
import { sheetOperationStatusChanged } from '../../../../operations/sheetOperations/index';
import { TASKS_MODEL } from '../../../../reducers/schemaModel/models/tasksSchema';
import {
  MASTER_COMPLETED_TASKS_TABLE_MODEL,
  MASTER_TASKS_TO_DO_TABLE_MODEL,
} from '../../../../reducers/schemaModel/models/masterTasksSchema';


/*
* Подробно о влиянии события изменения статуса МЛ на типы приложения описано в комментарии к
* handleSheetOperationStatusChanged в SheetInProductionReviewContentContainer и к handleTaskStatusChanged в
* TasksViewScreenContainer, TasksContainer и MasterTasksToDoContainer. Эти обработки реализованы исходя из того, в каком
* разделе был изменен статус операции. В случае с получением события изменения статуса МЛ другими пользователями нужна
* похожая обработка, но, с учетом, того, что пользователи могут находиться в этот момент в разделах, которые сразу же должны
* быть обновлены - в этом случае, сбрасывать "кэш" не нужно, а нужен именно перезапрос \ обновление данных. Если перед
* перезапросом данных мы сначала очистим "кэш" (т.е. данные текущего раздела), а только потом перезапросим новые данные,
* то у пользователя в интерфейсе случится "скачок" (данные быстро пропадут, кое-где мелькнет сообщение о пустых данных,
* а потом тут же появятся новые данные), что нежелательно.
* Для всех остальных разделов, где нужны обновления, кроме текущего, требуется, аналогично, очистить "кэш", чтобы при
* повторном входе в интерфейсы запросы выполнились заново.
*
* Важно! Кроме всего описанного есть общая обработка по вызову экшена смены статуса операции, которая должна выполняться
* всегда, независимо от того, в каком разделе находится пользователь. Об этом не описывается в комментариях к
* обработчикам, упоминаемым выше, т.к. это не входит в логику этих обработчиков, а является общей логикой,
* выполняемой в SheetOperationReviewDialogContainer. Согласно общей логике обработчиков броадкастинга, мы, в общем
* случае, пытаемся разделять обработку в зависимости от раздела и даже специально дублируем вызовы экшенов для разных
* разделов, чтобы проще было читать код и анализировать, что происходит в конкретном разделе по событию. Но, в этом
* случае это кажется излишним, т.к. обработка не содержит никаких сайдэффектов, это просто вызов экшена, обновляющий
* стор, который должен быть вызван, как факт наступления события. Может возникнуть вопрос, что в sheetStart есть подобная
* обработка, которая не содержит в себе никаких сайдэффектов, это тоже просто вызов экшена removeSheetsToStart, но
* в этом случае обработка дублировалась. Да, это, конечно, похоже, но, мне показалось, что тут у нас эта логика
* изначально располагается в конкретном компоненте SheetsToStartContainer и обработка конкретного действия из
* определенного раздела. Тогда как вызов экшена смены статуса операции происходит в общей абстракции просмотра
* операции, которая применяется в самых различных разделах и это никак не влияет на обработку. Поэтому обработка
* вынесена отдельно и производится в самом начале независимо от того, где находится пользоваитель.
* Возможно, в будущем нужно пересмотреть и removeSheetsToStart, реализовать его как-то аналогично, возможно, вынести
* их обработчиков SheetsToStartContainer в логику экшена, например, т.к. она общая.
* */
export const handleSheetOperationStatusChange = message =>
  (dispatch, getState) => {
    const {
      sheetOperationDataAfterStatusChange,
    } = message;

    const {
      sheetId,
    } = sheetOperationDataAfterStatusChange;

    //Общая обработка, о которой упоминается в комментарии выше
    dispatch(sheetOperationStatusChanged(sheetOperationDataAfterStatusChange));

    const state = getState();

    const allTasksTablesIds = allTasksTablesIdsSelector(state);

    const currentPathname = routerPathnameSelector(state);

    //Если находимся в разделе "Плановик. МЛ в производстве" при просмотре МЛ у операции которого был изменен статус
    const sheetInProductionReviewRouteMatch = matchPath(currentPathname, {
      path: `${PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE}/:sheetId`,
    });
    if(
      sheetInProductionReviewRouteMatch !== null &&
      sheetInProductionReviewRouteMatch.params.sheetId === sheetId.toString()
    ) {
      return dispatch(_updateIfOnSheetInProductionWithChangedOperationsScreen(sheetId, allTasksTablesIds));
    }

    //Если находимся  в разделе "Мастер. Требуется выполнить"
    const masterTasksTodoRouteMatch = matchPath(currentPathname, {
      path: MASTER_APP_TASKS_TO_DO_ROUTE,
    });
    if(masterTasksTodoRouteMatch !== null)
      return dispatch(_updateIfOnMasterTasksTodoScreen(
        sheetId,
        getMasterAppDepartmentIdsFromRouteParam(masterTasksTodoRouteMatch.params.departmentIds),
        allTasksTablesIds,
      ));

    //Если находимся  в разделе "Мастер. Завершенные"
    const masterCompletedTasksRouteMatch = matchPath(currentPathname, {
      path: MASTER_APP_COMPLETED_TASKS_ROUTE,
    });
    if(masterCompletedTasksRouteMatch !== null)
      return dispatch(_updateIfOnMasterCompletedTasksScreen(
        sheetId,
        getMasterAppDepartmentIdsFromRouteParam(masterCompletedTasksRouteMatch.params.departmentIds),
        allTasksTablesIds,
      ));


    //Если находимся в разделе "Рабочий. Просмотр задания для класса РЦ в подразделении"
    const tasksForEquipmentClassInDepartmentRouteMatch = matchPath(currentPathname, {
      path: `${WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE}/:departmentId/:equipmentClassId`,
    });
    if(tasksForEquipmentClassInDepartmentRouteMatch !== null) {
      const {
        departmentId,
        equipmentClassId,
      } = tasksForEquipmentClassInDepartmentRouteMatch.params;

      return dispatch(_updateIfOnTasksViewScreen(
        sheetId,
        departmentId,
        equipmentClassId,
        allTasksTablesIds,
      ));
    }

    //Если находимся в разделе "Рабочий. Просмотр ВСЕХ заданий"
    const workerAppTasksRouteMatch = matchPath(currentPathname, {
      path: WORKER_APP_TASKS_ROUTE,
    });
    if(workerAppTasksRouteMatch !== null) {
      return dispatch(_updateIfOnWorkerAppTasksScreen(
        sheetId,
        allTasksTablesIds,
      ));
    }

    /*
    * Для всех остальных разделов не нужна какая-то дополнительная обработка, но и для этого случая необходимо
    * очистить данные всех интерфейсов, где должны быть обновления из-за смены статуса операции МЛ, чтобы при следующем
    * входе в эти разделы данные были запрошены заново
    * */
    dispatch(clearTableRemoteData([
      createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId),
      MASTER_TASKS_TO_DO_TABLE_ID,
      MASTER_COMPLETED_TASKS_TABLE_ID,
      TASKS_MODEL,
      ...allTasksTablesIds,
    ]));
  };

/*
* Если находимся в разделе "Плановик. МЛ в производстве" при просмотре МЛ операции которого изменили статус, то очищаем
* данные всех таблиц заданий Мастера и всех таблиц заданий в приложении "Рабочий", а для этого раздела перезапрашиваем
* данные списка для текущей страницы.
* */
const _updateIfOnSheetInProductionWithChangedOperationsScreen = (sheetId, allTasksTablesIds) =>
  dispatch => {

    dispatch(clearTableRemoteData([
      MASTER_TASKS_TO_DO_TABLE_ID,
      MASTER_COMPLETED_TASKS_TABLE_ID,
      TASKS_MODEL,
      ...allTasksTablesIds,
    ]));

    const sheetOperationsTableId = createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId);

    dispatch(reFetchRemoteTableData(
      sheetOperationsTableId,
      null,
      ({ tableParams }) =>
        dispatch(fetchReviewedSheetOperationsRemoteTableEntities(sheetId, tableParams, { isBlockingRequest: false })),
    ));
  };

/*
* Если находимся в разделе просмотра заданий "Мастер. Требуется выполнить", то очищаем данные таблицы просмотра
* операций МЛ у которого изменился статус операции в "Плановике", очищаем данные таблицы "Мастер. Завершенные", т.к.
* в общем случае при смене статуса одной операции могут изменяться статусы других операций (например,  могут
* завершиться предыдущие, т.к. в плановике можно изменять статус операци МЛ не по порядку), очищаем все таблицы заданий
* в приложении "Рабочий". Для таблицы "Мастер. Требуется выполнить" перезапрашиваем задания для текущих параметров
* просматриваемой таблицы, чтобы информация обновилась
* */
const _updateIfOnMasterTasksTodoScreen = (
  sheetId,
  departmentIds,
  allTasksTablesIds,
) =>
  (dispatch, getState) => {
    dispatch(clearTableRemoteData([
      createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId),
      MASTER_COMPLETED_TASKS_TABLE_ID,
      TASKS_MODEL,
      ...allTasksTablesIds,
    ]));

    const masterTasksToDoAdditionalFilters = masterTasksToDoAdditionalFiltersSelector(getState());

    dispatch(reFetchRemoteTableData(
      MASTER_TASKS_TO_DO_TABLE_ID,
      MASTER_TASKS_TO_DO_TABLE_MODEL,
      ({ tableParams }) =>
        dispatch(fetchMasterDepartmentsTasksToDoRemoteTableData(
          departmentIds,
          masterTasksToDoAdditionalFilters,
          tableParams,
          { isBlockingRequest: false },
        )),
    ));
  };

/*
* Если находимся в разделе просмотра заданий "Мастер. Завершенные", то очищаем данные таблицы просмотра
* операций МЛ у которого изменился статус операции в "Плановике", очищаем данные таблицы "Мастер. Требуется выполнить",
* очищаем все таблицы заданий в приложении "Рабочий". Для таблицы "Мастер. Завершенные" перезапрашиваем задания для
* текущих параметров просматриваемой таблицы, чтобы информация обновилась, т.к. в общем случае при смене статуса одной
* операции могут изменяться статусы других операций (например, могут завершиться предыдущие, т.к. в Плановике можно
* изменять статус операци МЛ не по порядку)
* */
const _updateIfOnMasterCompletedTasksScreen = (
  sheetId,
  departmentIds,
  allTasksTablesIds,
) =>
  dispatch => {
    dispatch(clearTableRemoteData([
      createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId),
      MASTER_TASKS_TO_DO_TABLE_ID,
      TASKS_MODEL,
      ...allTasksTablesIds,
    ]));

    dispatch(reFetchRemoteTableData(
      MASTER_COMPLETED_TASKS_TABLE_ID,
      MASTER_COMPLETED_TASKS_TABLE_MODEL,
      ({ tableParams }) =>
        dispatch(fetchMasterDepartmentsCompletedTasksRemoteTableData(
          departmentIds,
          tableParams,
          { isBlockingRequest: false },
        )),
    ));
  };

/*
* Если находимся в разделе "Рабочий. Просмотр задания для класса РЦ в подразделении", то очищаем данные таблицы
* просмотра операций МЛ у которого изменились статусы операций в Плановике, очищаем данные таблиц заданий Мастера,
* очищаем данные для всех таблиц заданий в приложении "Рабочий" кроме текущей. Для этой таблицы перезапрашиваем задания
* для текущих параметров просматриваемой таблицы, чтобы информация обновилась
* */
const _updateIfOnTasksViewScreen = (
  sheetId,
  departmentIdFromRoute,
  equipmentClassIdFromRoute,
  allTasksTablesIds,
) =>
  dispatch => {
    const currentTableId = getEquipmentClassInDepartmentTasksTableId(
      departmentIdFromRoute,
      equipmentClassIdFromRoute,
    );

    //Текущую таблицу не очищаем, чтобы не было скачков в интерфейсе, данные в ней обновятся при перезапросе данных
    const allTasksTablesIdsWithoutCurrentTableId = allTasksTablesIds
      .filter(tableId => tableId !== currentTableId);

    dispatch(clearTableRemoteData([
      createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId),
      MASTER_TASKS_TO_DO_TABLE_ID,
      MASTER_COMPLETED_TASKS_TABLE_ID,
      TASKS_MODEL,
      ...allTasksTablesIdsWithoutCurrentTableId,
    ]));

    dispatch(reFetchRemoteTableData(
      currentTableId,
      currentTableId,
      ({ tableParams }) =>
        dispatch(fetchEquipmentClassInDepartmentTasksRemoteTableData(
          {
            departmentIdsArray: [departmentIdFromRoute],
            equipmentClassIdsArray: [equipmentClassIdFromRoute],
          },
          tableParams,
          { isBlockingRequest: false },
        )),
    ));
  };

/*
 * Если находимся в разделе "Рабочий. Просмотр ВСЕХ заданий", то очищаем все данные других интерфейсов кроме этого, где
 * должны быть обновления из-за смены статуса операции МЛ, а для этого раздела перезапрашиваем данные для таблицы заданий,
 *  с учетом текущих параметров таблицы и внешних фильтров
 * */
const _updateIfOnWorkerAppTasksScreen = (sheetId, allTasksTablesIds) =>
  dispatch => {

    dispatch(clearTableRemoteData([
      createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId),
      MASTER_TASKS_TO_DO_TABLE_ID,
      MASTER_COMPLETED_TASKS_TABLE_ID,
      ...allTasksTablesIds,
    ]));

    dispatch(reFetchRemoteTableData(
      TASKS_MODEL,
      TASKS_MODEL,
      ({ tableParams }) =>
        dispatch(fetchTasksRemoteTableData(tableParams, { isBlockingRequest: false })),
    ));
  };
