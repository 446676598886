import React from 'react';
import {
  ADMIN_APP_MAIN_ROUTE,
  MASTER_APP_MAIN_ROUTE,
  PLANNER_APP_MAIN_ROUTE,
  QUALITY_CONTROL_APP_MAIN_ROUTE,
  STORAGE_MANAGEMENT_APP_MAIN_ROUTE,
  WORKER_APP_MAIN_ROUTE,
} from './routes';
import { PERMISSION } from '../hoc/withPermissionsManager/constants';
import { Trans } from '@lingui/macro';

export const PLANNER_APP = 'plannerApp';
export const STORAGE_MANAGEMENT_APP = 'storageManagement';
export const MASTER_APP = 'masterApp';
export const WORKER_APP = 'workerApp';
export const ADMIN_APP = 'adminApp';
export const QUALITY_CONTROL_APP = 'qualityControlApp';

export const APP_CRITICAL_ERROR = {
  CONNECTION_ERROR: 'CONNECTION_ERROR',
};

export const APPS_DATA = {
  [PLANNER_APP]: {
    appIdentity: PLANNER_APP,
    appMainRouteLink: PLANNER_APP_MAIN_ROUTE,
    appTitle: (
      <Trans id="app_choosing@planner_app_title">
        Плановик
      </Trans>
    ),
    appDescription: (
      <Trans id="app_choosing@planner_app_description">
        Запуск и контроль маршрутных листов
      </Trans>
    ),
    appReviewPermission: PERMISSION.REVIEW_PLANNER_APP,
  },
  [STORAGE_MANAGEMENT_APP]: {
    appIdentity: STORAGE_MANAGEMENT_APP,
    appMainRouteLink: STORAGE_MANAGEMENT_APP_MAIN_ROUTE,
    appTitle: (
      <Trans id="app_choosing@parts_and_materials_consuming_app_title">
        Комплектование
      </Trans>
    ),
    appDescription: (
      <Trans id="app_choosing@parts_and_materials_consuming_app_description">
        Комплектование маршрутных листов
      </Trans>
    ),
    appReviewPermission: PERMISSION.REVIEW_STORAGE_MANAGEMENT_APP,
  },
  [MASTER_APP]: {
    appIdentity: MASTER_APP,
    appMainRouteLink: MASTER_APP_MAIN_ROUTE,
    appTitle: (
      <Trans id="app_choosing@master_app_title">
        Мастер
      </Trans>
    ),
    appDescription: (
      <Trans id="app_choosing@master_app_description">
        Управление исполнением работ по маршрутным листам
      </Trans>
    ),
    appReviewPermission: PERMISSION.REVIEW_MASTER_APP,
  },
  [WORKER_APP]: {
    appIdentity: WORKER_APP,
    appMainRouteLink: WORKER_APP_MAIN_ROUTE,
    appTitle: (
      <Trans id="app_choosing@worker_app_title">
        Рабочий
      </Trans>
    ),
    appDescription: (
      <Trans id="app_choosing@worker_app_description">
        Исполнение работ по маршрутным листам
      </Trans>
    ),
    appReviewPermission: PERMISSION.REVIEW_WORKER_APP,
  },
  [QUALITY_CONTROL_APP]: {
    appIdentity: QUALITY_CONTROL_APP,
    appMainRouteLink: QUALITY_CONTROL_APP_MAIN_ROUTE,
    appTitle: (
      <Trans id="app_choosing@quality_control_app_title">
        Контроль
      </Trans>
    ),
    appDescription: (
      <Trans id="app_choosing@quality_control_app_descrition">
        Работа с несоответствующей продукцией
      </Trans>
    ),
    /*
    Пока нет отдельно роли для "Контроль", поэтому для просмотра приложения используется разрешения запроса транзакций
    по партиям
    */
    appReviewPermission: PERMISSION.GET_ENTITY_BATCH_TRANSACTIONS,
  },
  [ADMIN_APP]: {
    appIdentity: ADMIN_APP,
    appMainRouteLink: ADMIN_APP_MAIN_ROUTE,
    appTitle: (
      <Trans id="app_choosing@administration_app_title">
        Администрирование
      </Trans>
    ),
    appDescription: (
      <Trans id="app_choosing@administration_app_description">
        Администрирование приложений
      </Trans>
    ),
    appReviewPermission: PERMISSION.REVIEW_ADMIN_APP,
  },
};
