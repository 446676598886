import { storageManagementAppAssemblySheetsSelector } from '../../selectors';
import { createSelector } from 'reselect';
import { addDecimals, subDecimals } from '@bfg-frontend/utils/lib/decimal';
import { getEntityCombinedName } from '@bfg-frontend/utils/lib/stringBuilders/entity';


export const assemblySheetReserveDataSelector = createSelector(
  (state, { sheetId }) => storageManagementAppAssemblySheetsSelector(state).reserveData[sheetId],
  reserveData => {
    if (reserveData === undefined) return undefined;

    return reserveData.reduce((acc, current) => {
      const {
        entityId,
        requiredAmount,
        entityIdentity,
        entityName,
        entityCode,
        entityBatches,
      } = current;

      let totalWarehouseAmount = 0;
      let totalReservedAmount = 0;

      acc[entityId] = {
        entityId,
        entityIdentity,
        entityName,
        entityCode,
        entityCombinedName: getEntityCombinedName({
          identity: entityIdentity,
          code: entityCode,
          name: entityName,
        }),
        requiredAmount,
        entityBatches: entityBatches.reduce((batchesAcc, item) => {

          const {
            warehouseAmount,
            reservedAmount,
            consumedAmount,
            identity,
            id,
          } = item;

          // считаем суммарное количество ДСЕ на складе и в резерве
          totalReservedAmount = addDecimals(totalReservedAmount, addDecimals(reservedAmount, consumedAmount));
          totalWarehouseAmount = addDecimals(totalWarehouseAmount, warehouseAmount);

          batchesAcc[id] = {
            id,
            identity,
            warehouseAmount,
            reservedAmount,
          };

          return batchesAcc;
        },
        {}),
      };

      acc[entityId].warehouseAmount = totalWarehouseAmount;
      acc[entityId].reservedAmount = totalReservedAmount;
      acc[entityId].remainingAmount = subDecimals(requiredAmount, totalReservedAmount);

      return acc;
    }, {});
  },
);
