import React, { Component } from 'react';
import { Trans } from '@lingui/macro';
import _get from 'lodash/get';

import {
  ENTITY_OR_RESOURCE_COMMON_FIELDS_BASED_TYPE,
  FUNC_IS_REQUIRED_TYPE,
} from '../../../../../../constants/propTypes';

import { DefaultAutocompleteContainer } from '../../../../../common/Autocomplete/AutocompleteContainer';
import { DefaultDepartmentsAutocomplete } from '../../../../../entitiesAutocomplete/DepartmentsAutocomplete/DepartmentsAutocomplete';
import {
  EquipmentClassesAutocomplete,
} from '../../../../../entitiesAutocomplete/EquipmentClassesAutocomplete/EquipmentClassesAutocomplete';
import './style.css';


export const WORKER_TASKS_TABLE_NEW_SETTINGS_EQUIPMENT_CLASS_SELECT_ID = 'WORKER_TASKS_TABLE_NEW_SETTINGS_EQUIPMENT_CLASS_SELECT_ID';
const ADMIN_SCREEN_DEPARTMENTS_AUTOCOMPLETE_ID = 'ADMIN_SCREEN_DEPARTMENTS_AUTOCOMPLETE_ID';

export class NewSettingsOwnerSelects extends Component {

  static propTypes = {
    selectedDepartment: ENTITY_OR_RESOURCE_COMMON_FIELDS_BASED_TYPE,
    selectDepartment: FUNC_IS_REQUIRED_TYPE,
    selectedEquipmentClass: ENTITY_OR_RESOURCE_COMMON_FIELDS_BASED_TYPE,
    selectEquipmentClass: FUNC_IS_REQUIRED_TYPE,
  };

  _renderDepartmentSelect = () => {
    const {
      selectDepartment,
      selectedDepartment,
    } = this.props;
    return(
      <div className="new-settings-owner-select__wrapper">
        <div className="eq-class-in-dep-tasks__title">
          <Trans id="worker_tasks_table_settings">
            Выберите подразделение для настроек
          </Trans>
        </div>
        <DefaultDepartmentsAutocomplete
            id={ADMIN_SCREEN_DEPARTMENTS_AUTOCOMPLETE_ID}
            value={selectedDepartment}
            onChange={selectDepartment}
            isClearable={false}
            wrapperClassName="new-settings-owner-selects__department-select"
        />
      </div>
    );
  };

  _renderEquipmentClassSelect = () => {
    const {
      selectedDepartment,
      selectedEquipmentClass,
      selectEquipmentClass,
    } = this.props;

    const departmentId = _get(selectedDepartment, 'id', null);
    const departmentIds = !!departmentId ? [departmentId] : undefined;

    return(
      <div className="new-settings-owner-select__wrapper">
        <div className="eq-class-in-dep-tasks__title">
          <Trans id="worker_tasks_table_settings@choose_equipment_class_for_settings">
            Выберите класс РЦ для настроек (не обязательно)
          </Trans>
        </div>
        <EquipmentClassesAutocomplete
            id={WORKER_TASKS_TABLE_NEW_SETTINGS_EQUIPMENT_CLASS_SELECT_ID}
            value={selectedEquipmentClass}
            onChange={selectEquipmentClass}
            isClearable
            isDisabled={!selectedDepartment}
            wrapperClassName="new-settings-owner-selects__equipment-class-select"
            shouldLoadOptions={!!selectedDepartment}
            shouldPreloadData
            departmentIds={departmentIds}
            AutocompleteComponent={DefaultAutocompleteContainer}
            /*
            В key добавлен идентификатор выбранного подразделения, чтобы при смене подразделения для этого
            автокомплита сразу запрашивались опции
            */
            key={departmentId}
        />
      </div>
    );
  };

  render() {
    return (
      <div className="new-settings-owner-selects">
        {this._renderDepartmentSelect()}
        {this._renderEquipmentClassSelect()}
      </div>
    );
  }
}
