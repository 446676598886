import React from 'react';
import { Trans } from '@lingui/macro';

export const SHEET_TO_START_ERRORS = {
  IO: {
    ENTITY_BATCH_EXISTS: (
      <Trans id="sheet_to_start@entity_batch_exists_error">
        МЛ для указанной партии уже существует в системе. Новый МЛ для партии не был создан. Вероятно, имеются
        проблемы с синхронизацией работы системы. Обратитесь к системному администратору
      </Trans>
    ),
    ENTITY_ROUTE_SHEET_EXISTS: (
      <Trans id="sheet_to_start@sheet_exists_error">
        В системе уже есть МЛ с указанным идентификатором, для нового МЛ необходимо задать другой идентификатор
      </Trans>
    ),
  },
};

export const SHEET_TO_FINISH_ERRORS = {
  IO: {
    ENTITY_ROUTE_SHEET_DOES_NOT_EXIST: (
      <Trans id="sheet_in_production.finish_sheet@sheet_does_not_exist_error">
        Указанного МЛ не существует в системе. Обратитесь к системному администратору. Вероятно, имеются проблемы с
        синхронизацией работы системы
      </Trans>
    ),
  },
};

const InappropriateEntityBatchActionIsNotAllowedForCurrentStatusErrorTrans = (
  <Trans id="inappropriate_sheet.mark_sheet_inappropriate_state_unconfirmed@entity_batch_action_is_not_allowed_for_current_status_error">
    Ошибка при попытке вернуть МЛ в производство. Вернуть МЛ в производство можно, только если он находится
    в статусе "Несоответствующая продукция"
  </Trans>
);

const InappropriateEntityBatchDoesNotExistsErrorTrans = (
  <Trans id="inappropriate_sheet.mark_sheet_inappropriate_state_unconfirmed@entity_batch_does_not_exist_error">
    Ошибка при попытке вернуть МЛ в производство. МЛ не существует в системе
  </Trans>
);

export const MARK_SHEET_INAPPROPRIATE_STATE_UNCONFIRMED_ERRORS = {
  IO: {
    ACTION_IS_NOT_ALLOWED_FOR_CURRENT_ENTITY_BATCH_STATUS: InappropriateEntityBatchActionIsNotAllowedForCurrentStatusErrorTrans,
    ENTITY_BATCH_DOES_NOT_EXIST: InappropriateEntityBatchDoesNotExistsErrorTrans,
  },
};

const DefectiveEntityBatchDoesNotExistsErrorTrans = (
  <Trans id="inappropriate_sheet.mark_sheet_inappropriate_state_confirmed@entity_batch_does_not_exist_error">
    Ошибка при попытке списать МЛ в окончательный брак. МЛ не существует в системе
  </Trans>
);

const DefectiveEntityBatchActionIsNotAllowedForCurrentStatusErrorTrans = (
  <Trans id="inappropriate_sheet.mark_sheet_inappropriate_state_confirmed@entity_batch_action_is_not_allowed_for_current_status_error">
    Ошибка при попытке списать МЛ в окончательный брак. Списать МЛ можно, только если он находится в статусе
    "Несоответствующая продукция"
  </Trans>
);

export const MARK_SHEET_FINAL_DEFECTIVE_STATE_CONFIRMED_ERRORS = {
  IO: {
    ACTION_IS_NOT_ALLOWED_FOR_CURRENT_ENTITY_BATCH_STATUS: DefectiveEntityBatchActionIsNotAllowedForCurrentStatusErrorTrans,
    ENTITY_BATCH_DOES_NOT_EXIST: DefectiveEntityBatchDoesNotExistsErrorTrans,
  },
};

export const SHEET_OPERATION_TO_START_ERRORS = {
  IO: {
    ENTITY_ROUTE_SHEET_DOES_NOT_EXIST: (
      <Trans id="sheet_operation_review.start_operation@sheet_does_not_exist_error">
        Не удалось начать работу по операции, т.к. МЛ, к которому относится эта операция, не существует в системе.
        Обратитесь к системному администратору. Вероятно, имеются проблемы с синхронизацией работы системы
      </Trans>
    ),
    ACTIVE_ENTITY_ROUTE_SHEET_DOES_NOT_EXIST: (
      <Trans id="sheet_operation_review.start_operation@active_sheet_does_not_exist_error">
        Не удалось начать работу по операции, т.к. МЛ, к которому относится эта операция, не является активным.
        Обратитесь к системному администратору. Вероятно, имеются проблемы с синхронизацией работы системы
      </Trans>
    ),
    ENTITY_ROUTE_SHEET_OPERATION_DOES_NOT_EXIST: (
      <Trans id="sheet_operation_review.start_operation@sheet_operation_does_not_exist_error">
        Не удалось начать работу по операции, т.к. ее не существует в системе. Обратитесь к системному
        администратору. Вероятно, имеются проблемы с синхронизацией работы системы
      </Trans>
    ),
    ENTITY_ROUTE_SHEET_OPERATION_ALREADY_CHANGED: (
      <Trans id="sheet_operation_review.start_operation@sheet_operation_already_changed_error">
        Не удалось начать работу по операции, т.к. ее статус был изменен другим пользователем
      </Trans>
    ),
    ENTITY_ROUTE_SHEET_OPERATION_STATUS_TRANSITION_IS_NOT_ALLOWED: (
      <Trans id="sheet_operation_review.start_operation@sheet_operation_status_transaction_is_not_allowed_error">
        Не удалось начать работу по операции, т.к. для ее текущего статуса запрещен переход в состояние
        "В работе". Обратитесь к системному администратору. Вероятно, имеются проблемы с синхронизацией работы
        системы
      </Trans>
    ),
    ENTITY_ROUTE_SHEET_OPERATION_PROGRESS_LESS_THAN_CURRENT: (
      <Trans id="sheet_operation_review.start_operation@sheet_operation_progress_less_than_current_error">
        Не удалось начать работу по операции, т.к. указанное значение прогресса меньше исходного. Обратитесь к
        системному администратору. Вероятно, имеются проблемы с синхронизацией работы системы
      </Trans>
    ),
  },
};

export const SHEET_OPERATION_TO_PAUSE_ERRORS = {
  IO: {
    ENTITY_ROUTE_SHEET_DOES_NOT_EXIST: (
      <Trans id="sheet_operation_review.pause_operation@sheet_does_not_exist_error">
        Не удалось остановить работу по операции, т.к. МЛ этой операции не существует в системе. Обратитесь к
        системному администратору. Вероятно, имеются проблемы с синхронизацией работы системы
      </Trans>
    ),
    ACTIVE_ENTITY_ROUTE_SHEET_DOES_NOT_EXIST: (
      <Trans id="sheet_operation_review.pause_operation@active_sheet_does_not_exist_error">
        Не удалось остановить работу по операции, т.к. МЛ, к которому относится эта операция, не является
        активным. Обратитесь к системному администратору. Вероятно, имеются проблемы с синхронизацией работы системы
      </Trans>
    ),
    ENTITY_ROUTE_SHEET_OPERATION_DOES_NOT_EXIST: (
      <Trans id="sheet_operation_review.pause_operation@sheet_operation_does_not_exist_error">
        Не удалось остановить работу по операции, т.к. ее не существует в системе. Обратитесь к системному
        администратору. Вероятно, имеются проблемы с синхронизацией работы системы
      </Trans>
    ),
    ENTITY_ROUTE_SHEET_OPERATION_ALREADY_CHANGED: (
      <Trans id="sheet_operation_review.pause_operation@sheet_operation_already_changed_error">
        Не удалось остановить работу по операции, т.к. ее статус просматриваемой операции МЛ был изменен другим
        пользователем
      </Trans>
    ),
    ENTITY_ROUTE_SHEET_OPERATION_STATUS_TRANSITION_IS_NOT_ALLOWED: (
      <Trans id="sheet_operation_review.pause_operation@sheet_operation_status_transaction_is_not_allowed_error">
        Не удалось остановить работу по операции, т.к. ее текущего статуса запрещен переход в состояние
        "На паузе". Обратитесь к системному администратору. Вероятно, имеются проблемы с синхронизацией
        работы системы
      </Trans>
    ),
    ENTITY_ROUTE_SHEET_OPERATION_PROGRESS_LESS_THAN_CURRENT: (
      <Trans id="sheet_operation_review.pause_operation@sheet_operation_progress_less_than_current_error">
        Не удалось остановить работу по операции, т.к. указанное значение прогресса меньше исходного. Обратитесь
        к системному администратору. Вероятно, имеются проблемы с синхронизацией работы системы
      </Trans>
    ),
  },
};

export const SHEET_OPERATION_TO_CONTINUE_ERRORS = {
  IO: {
    ENTITY_ROUTE_SHEET_DOES_NOT_EXIST: (
      <Trans id="sheet_operation_review.continue_operation@sheet_does_not_exist_error">
        Не удалось возобновить работу по операции, т.к. МЛ, к которому относится эта операция, не существует в
        системе. Обратитесь к системному администратору. Вероятно, имеются проблемы с синхронизацией работы системы
      </Trans>
    ),
    ACTIVE_ENTITY_ROUTE_SHEET_DOES_NOT_EXIST: (
      <Trans id="sheet_operation_review.continue_operation@active_sheet_does_not_exist_error">
        Не удалось возобновить работу по операции, т.к. МЛ, к которому относится эта операция, не является
        активным. Обратитесь к системному администратору. Вероятно, имеются проблемы с синхронизацией работы системы
      </Trans>
    ),
    ENTITY_ROUTE_SHEET_OPERATION_DOES_NOT_EXIST: (
      <Trans id="sheet_operation_review.continue_operation@sheet_operation_does_not_exist_error">
        Не удалось возобновить работу по операции, т.к. ее не существует в системе. Обратитесь к системному
        администратору. Вероятно, имеются проблемы с синхронизацией работы системы
      </Trans>
    ),
    ENTITY_ROUTE_SHEET_OPERATION_ALREADY_CHANGED: (
      <Trans id="sheet_operation_review.continue_operation@sheet_operation_already_changed_error">
        Не удалось возобновить работу по операции, т.к. ее статус был изменен другим пользователем
      </Trans>
    ),
    ENTITY_ROUTE_SHEET_OPERATION_STATUS_TRANSITION_IS_NOT_ALLOWED: (
      <Trans id="sheet_operation_review.continue_operation@sheet_operation_status_transaction_is_not_allowed_error">
        Не удалось возобновить работу по операции, т.к. для ее текущего статуса запрещен переход в
        состояние "В работе". Обратитесь к системному администратору. Вероятно, имеются проблемы с
        синхронизацией работы системы
      </Trans>
    ),
    ENTITY_ROUTE_SHEET_OPERATION_PROGRESS_LESS_THAN_CURRENT: (
      <Trans id="sheet_operation_review.continue_operation@sheet_operation_progress_less_than_current">
        Не удалось возобновить работу по операции, т.к. указанное значение прогресса меньше исходного. Обратитесь
        к системному администратору. Вероятно, имеются проблемы с синхронизацией работы системы
      </Trans>
    ),
  },
};

export const SHEET_OPERATION_TO_FINISH_ERRORS = {
  IO: {
    ENTITY_ROUTE_SHEET_DOES_NOT_EXIST: (
      <Trans id="sheet_operation_review.finish_operation@sheet_does_not_exist_error">
        Не удалось завершить работу по операции, т.к. МЛ, к которому относится эта операция, не существует в
        системе. Обратитесь к системному администратору. Вероятно, имеются проблемы с синхронизацией работы системы
      </Trans>
    ),
    ACTIVE_ENTITY_ROUTE_SHEET_DOES_NOT_EXIST: (
      <Trans id="sheet_operation_review.finish_operation@sheet_does_not_exist_error">
        Не удалось завершить работу по операции, т.к. МЛ, к которому относится эта операция, не существует в
        системе. Обратитесь к системному администратору. Вероятно, имеются проблемы с синхронизацией работы системы
      </Trans>
    ),
    ENTITY_ROUTE_SHEET_OPERATION_DOES_NOT_EXIST: (
      <Trans id="sheet_operation_review.finish_operation@sheet_operation_does_not_exist_error">
        Не удалось завершить работу по операции, т.к. ее не существует в системе. Обратитесь к системному
        администратору. Вероятно, имеются проблемы с синхронизацией работы системы
      </Trans>
    ),
    ENTITY_ROUTE_SHEET_OPERATION_ALREADY_CHANGED: (
      <Trans id="sheet_operation_review.finish_operation@sheet_operation_already_changed_error">
        Не удалось завершить работу по операции, т.к. ее статус был изменен другим пользователем
      </Trans>
    ),
    ENTITY_ROUTE_SHEET_OPERATION_STATUS_TRANSITION_IS_NOT_ALLOWED: (
      <Trans id="sheet_operation_review.finish_operation@sheet_operation_status_transaction_is_not_allowed_error">
        Не удалось завершить работу по операции, т.к. для ее текущего статуса запрещен переход в
        состояние "Завершена". Обратитесь к системному администратору. Вероятно, имеются проблемы с
        синхронизацией работы системы
      </Trans>
    ),
    ENTITY_ROUTE_SHEET_OPERATION_PROGRESS_LESS_THAN_CURRENT: (
      <Trans id="sheet_operation_review.finish_operation@sheet_operation_progress_less_than_current_error">
        Не удалось завершить работу по операции, т.к. указанное значение прогресса меньше исходного. Обратитесь
        к системному администратору. Вероятно, имеются проблемы с синхронизацией работы системы
      </Trans>
    ),
  },
};

export const MARK_DEFECTIVE_ENTITIES_ERRORS = {
  IO: {
    ENTITY_BATCH_STATUS_DOES_NOT_VALID: (
      <Trans id="sheet_operation_review.mark_defective_entities@entity_batch_status_does_not_valid">
        Не удалось отметить брак на операции, т.к. партия исходного МЛ ранее уже была отправлена в брак или закончена.
        Обратитесь к системному администратору. Вероятно, имеются проблемы с синхронизацией работы системы
      </Trans>
    ),
    ENTITY_BATCH_QUANTITY_LOWER_THAN_NEEDED: (
      <Trans id="sheet_operation_review.mark_defective_entities@entity_batch_quantity_lower_than_needed">
        Не удалось отметить брак на операции, т.к. указанное количество бракованных деталей превышает размер всей партии.
        Обратитесь к системному администратору. Вероятно, имеются проблемы с синхронизацией работы системы
      </Trans>
    ),
    ENTITY_ROUTE_SHEET_OPERATION_DOES_NOT_EXIST: (
      <Trans id="sheet_operation_review.mark_defective_entities@sheet_operation_does_not_exist_error">
        Не удалось отметить брак на операции, т.к. ее не существует в системе. Обратитесь к системному
        администратору. Вероятно, имеются проблемы с синхронизацией работы системы
      </Trans>
    ),
  },
};

export const LOGIN_ERRORS = {
  IO: {
    USER_DOES_NOT_EXIST: (
      <Trans id="login@user_does_not_exist_error">
        Указанный пользователь не существует
      </Trans>
    ),
    PASSWORD_IS_INCORRECT: (
      <Trans id="login@user_password_is_incorrect_error">
        Для указанного пользователя задан неверный пароль
      </Trans>
    ),

    /*
    * Пока решено для ошибкиаутентификации пользователем, когда пользователь был задизейблен, в клиентском
    * приложении выводит такое же сообщение, как и в ситуации, когда такого пользователя не существует в систему.
    * Для клиентов необязательно знать тонкости, почему пользователь недоступен, для них он, как будто бы, был
    * удален и его не существует в системе, это, вроде бы, логично
    * */
    USER_IS_DISABLED: (
      <Trans id="login@user_does_not_exist_error">
        Указанный пользователь не существует
      </Trans>
    ),
  },
};

export const CREATE_USER_ERRORS = {
  IO: {
    ROLE_DOES_NOT_EXIST: (
      <Trans id="user_creating@role_does_not_exist_error">
        Ошибка при попытке создания нового пользователя. Выбранная роль пользователя не существует в системе
      </Trans>
    ),
  },
};

export const EDIT_USER_ERRORS = {
  IO: {
    ROLE_DOES_NOT_EXIST: (
      <Trans id="user_editing@role_does_not_exist_error">
        Ошибка при попытке редактирования нового пользователя. Выбранная роль пользователя не существует в системе
      </Trans>
    ),
    USER_DOES_NOT_EXIST: (
      <Trans id="user_editing@user_does_not_exist_error">
        Ошибка при попытке редактирования данных пользователя. Выбранный пользователь не существует в системе
      </Trans>
    ),
  },
};

export const DEACTIVATE_USER_ERRORS = {
  IO: {
    USER_DOES_NOT_EXIST: (
      <Trans id="user_deactivating@user_does_not_exist_error">
        Ошибка при попытке сделать пользователя неактивным. Выбранный пользователь не существует в системе
      </Trans>
    ),
  },
};

export const ACTIVATE_USER_ERRORS = {
  IO: {
    REST_SPECIFIED_RECORD_DOES_NOT_EXIST: (
      <Trans id="user_activating@user_does_not_exist_error">
        Ошибка при попытке сделать пользователя активным. Выбранный пользователь не существует в системе
      </Trans>
    ),
  },
};

export const SET_SHEET_OPERATION_ASSIGNEES_ERRORS = {
  IO: {
    ENTITY_ROUTE_SHEET_OPERATION_DOES_NOT_EXIST: (
      <Trans id="sheet_operation_review.set_assignees@sheet_operation_does_not_exist_error">
        Ошибка при попытке назначить исполнителя на операцию МЛ. Выбранная операция не существует в системе
      </Trans>
    ),
    USER_DOES_NOT_EXIST: (
      <Trans id="sheet_operation_review.set_assignees@user_does_not_exist_error">
        Ошибка при попытке назначить исполнителя на операцию МЛ. Выбранный пользователь не существует в системе
      </Trans>
    ),
    UNABLE_TO_CHANGE_FINISHED_OPERATION: (
      <Trans id="sheet_operation_review.set_assignees@unable_to_change_finished_operation_error">
        Ошибка при попытке назначить исполнителя на операцию МЛ. Нельзя назначить исполнителя для завершенной операции
      </Trans>
    ),
  },
};

export const SET_SHEET_OPERATION_EQUIPMENT_ERRORS = {
  IO: {
    ENTITY_ROUTE_SHEET_OPERATION_DOES_NOT_EXIST: (
      <Trans id="sheet_operation_review.set_equipment@sheet_operation_does_not_exist_error">
        Ошибка при попытке назначить оборудование для операции МЛ. Выбранная операция не существует в системе
      </Trans>
    ),
    EQUIPMENT_DOES_NOT_EXIST: (
      <Trans id="sheet_operation_review.set_equipment@equipment_does_not_exist_error">
        Ошибка при попытке назначить оборудование для операции МЛ. Выбранное оборудование не существует в системе
      </Trans>
    ),
    UNABLE_TO_CHANGE_FINISHED_OPERATION: (
      <Trans id="sheet_operation_review.set_equipment@unable_to_change_finished_operation_error">
        Ошибка при попытке назначить оборудование для операции МЛ. Нельзя назначить оборудование для
        завершенной операции
      </Trans>
    ),
  },
};

export const CREATE_EQUIPMENT_ERRORS = {
  IO: {
    EQUIPMENT_CLASS_DOES_NOT_EXIST: (
      <Trans id="sheet_operation_review.create_equipment@equipment_class_does_not_exist_error">
        Ошибка при попытке создания единицы оборудования. Класс РЦ просматриваемой операции не существует в системе
      </Trans>
    ),
    DEPARTMENT_DOES_NOT_EXIST: (
      <Trans id="sheet_operation_review.create_equipment@department_does_not_exist_error">
        Ошибка при попытке создания единицы оборудования. Подразделение просматриваемой операции не существует
        в системе
      </Trans>
    ),
  },
};

export const SET_ENTITY_NOTE_ERRORS = {
  IO: {
    ENTITY_ROUTE_SHEET_OPERATION_DOES_NOT_EXIST: (
      <Trans id="sheet_operation_review.set_operation_note@sheet_operation_does_not_exist_error">
        Ошибка при попытке изменить комментарий к операции МЛ. Операция МЛ не существует в системе
      </Trans>
    ),
    ENTITY_ROUTE_SHEET_DOES_NOT_EXIST:(
      <Trans id="sheet_operation_review.set_sheet_pause_note@sheet_does_not_exist_error">
        Ошибка при попытке изменить комментарий к МЛ. Маршрутный лист не существует в системе
      </Trans>
    ),
    ENTITY_BATCH_DOES_NOT_EXIST: (
      <Trans id="sheet_operation_review.set_entity_batch_split_note@entity_batch_does_not_exist_error">
        Ошибка при попытке изменить комментарий к партии. Партия не существует в системе
      </Trans>
    ),
  },
};

export const PAUSE_SHEET_ERRORS = {
  IO: {
    ENTITY_BATCH_DOES_NOT_EXIST: (
      <Trans id="sheet_operation_review.pause_sheet@sheet_does_not_exist_error">
        Ошибка при попытке остановить МЛ. МЛ не существует в системе
      </Trans>
    ),
    ACTION_IS_NOT_ALLOWED_FOR_CURRENT_ENTITY_BATCH_STATUS: (
      <Trans id="sheet_operation_review.pause_sheet@not_allowed_for_current_status_error">
        Ошибка при попытке остановить МЛ. Остановить можно только МЛ, которые находятся в производстве
      </Trans>
    ),
    THERE_IS_ENTITY_ROUTE_SHEET_OPERATION_IN_PROGRESS: (
      <Trans id="sheet_operation_review.pause_sheet@sheet_operation_in_progress_error">
        Ошибка при попытке остановить МЛ. Нельзя остановить МЛ, у которого есть операция со
        статусом "В работе". Остановите работы по операции МЛ и попробуйте снова
      </Trans>
    ),
  },
};

export const RESUME_SHEET_ERRORS = {
  IO: {
    ENTITY_BATCH_DOES_NOT_EXIST: (
      <Trans id="paused_sheet.resume_sheet@sheet_does_not_exist_error">
        Ошибка при попытке вернуть МЛ в производство. МЛ не существует в системе
      </Trans>
    ),
    ACTION_IS_NOT_ALLOWED_FOR_CURRENT_ENTITY_BATCH_STATUS: (
      <Trans id="paused_sheet.resume_sheet@not_allowed_for_current_status_error">
        Ошибка при попытке вернуть МЛ в производство. Вернуть в производство можно только остановленные МЛ
      </Trans>
    ),
  },
};

export const SPLIT_ENTITY_BATCH_ERRORS = {
  IO: {
    ENTITY_BATCH_DOES_NOT_EXIST: (
      <Trans id="sheet_operation_review.split_entity_batch@entity_batch_does_not_exist_error">
        Ошибка при попытке разделения партии. Партия не существует в системе
      </Trans>
    ),
    CHILD_ENTITY_BATCH_QUANTITY_SHOULD_BE_LESS_THAN_PARENT: (
      <Trans id="sheet_operation_review.split_entity_batch@child_entity_batch_quantity_should_be_less_than_parent_error">
        Ошибка при попытке разделения партии. Количество ДСЕ в отделяемой партии больше или равно количеству
        ДСЕ текущей партии
      </Trans>
    ),
  },
};

export const COMPLETE_ORDER_ERRORS = {
  IO: {
    ORDER_DOES_NOT_EXIST: (
      <Trans id="orders_ready_to_complete.complete_order@order_does_not_exist_error">
        Ошибка при попытке завершить заказ. Заказ не существует в системе
      </Trans>
    ),
    THERE_ARE_NOT_ENOUGH_ENTITIES_ON_WAREHOUSE_TO_COMPLETE_ORDER:	(
      <Trans id="orders_ready_to_complete.complete_order@not_enough_entities_to_complete_order_error">
        Ошибка при попытке завершить заказ. На складе недостаточно партий для завершения заказа
      </Trans>
    ),
  },
};

export const SETTINGS_ERRORS = {
  IO: {
    REST_SPECIFIED_RECORD_DOES_NOT_EXIST: (
      <Trans id="sheet_operation_settings@settings_do_not_exist_error">
        Не удалось отредактировать выбранные настройки. Попробуйте перезагрузить страницу. Если ошибка повторится,
        то обратитесь к системному администратору
      </Trans>
    ),
  },
};
