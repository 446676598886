import { fetchData } from '../httpRequestsApi/actions';

import { transformDataPointToState } from '../../transformers/dataPoints';

/*
* Для получения кастомных отчетов с сервера используются отправка GET запросов на, так называемые, data-точки.
* */


const getDataPointUrl = (host, dataPoint) => [
  host,
  'data',
  dataPoint,
].join('/');

export const fetchDataFromDataPoint = (host, dataPoint, queryParams = {}, options = {}) =>
  (dispatch, getState) => dispatch(fetchData(
      getDataPointUrl(host, dataPoint),
      queryParams,
      options,
    ))
      .then(response => ({
        ...response,
        data: transformDataPointToState(response.data, dataPoint, getState()),
      }));

