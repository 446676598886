export const SCHEMA_MODEL = 'schemaModel';

export const SIMULATION_SESSION_MODEL = 'simulationSession';

export const DEPARTMENT_MODEL = 'department';
export const EQUIPMENT_CLASS_MODEL = 'equipmentClass';
export const EQUIPMENT_MODEL = 'equipment';
export const OPERATION_MODEL = 'operation';
export const ENTITY_ROUTE_MODEL = 'entityRoute';
export const ENTITY_MODEL = 'entity';
export const ORDER_MODEL = 'order';
export const ORDER_ENTRY_MODEL = 'orderEntry';
export const WAREHOUSE_ENTITY_BATCH_MODEL = 'warehouseEntityBatch';
export const WAREHOUSE_ENTITY_BATCH_RESERVE_MODEL = 'warehouseEntityBatchReserve';
export const ENTITY_BATCH_TRANSACTION_MODEL = 'entityBatchTransaction';

export const SIMULATION_EQUIPMENT_MODEL = 'simulationEquipment';
export const SIMULATION_OPERATION_TASK_EQUIPMENT_MODEL = 'simulationOperationTaskEquipment';
export const SIMULATION_OPERATION_TASK_MODEL = 'simulationOperationTask';
export const SIMULATION_ENTITY_BATCH_MODEL = 'simulationEntityBatch';
export const SIMULATION_ORDER_ENTITY_BATCH_MODEL = 'simulationOrderEntityBatch';


export const SETTINGS_MODEL = 'settings';

export const ENTITY_BATCH_MODEL = 'entityBatch';
export const SHEET_MODEL = 'entityRouteSheet';
export const SHEET_OPERATION_MODEL = 'entityRouteSheetOperation';
export const SHEET_OPERATION_TRANSACTION_MODEL = 'entityRouteSheetTransaction';
export const SHEET_OPERATION_ASSIGNEE_MODEL = 'entityRouteSheetOperationExecutor';

//Служебная модель для информации по операции МЛ, получаемася со специальной DATA-точки
export const SHEET_OPERATION_AGGREGATED_MODEL = 'sheetOperationAggregatedModel';

export const USER_MODEL = 'user';
export const ROLE_MODEL = 'role';
export const USER_ROLE_MODEL = 'userRole';
export const USER_SETTINGS_MODEL = 'userSettings';

export const SHEET_OPERATION_FEATURES_MODEL = 'entityRouteSheetOperationFeature';
export const SHEET_OPERATION_FEATURE_SCOPE_MODEL = 'entityRouteSheetOperationFeatureTarget';
export const SHEET_OPERATION_FEATURE_VALUE_MODEL = 'entityRouteSheetOperationFeatureValue';
