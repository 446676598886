import { fetchSettings, saveSettingsEntityAndAddToStore } from '../../../operations/settings';
import { connect } from 'react-redux';
import { workerTasksAdminFiltersSettingsSelector } from '../../../selectors/settings';
import {
  WORKER_TASKS_ADMIN_FILTERS_MAIN_SETTINGS_GROUP,
  WORKER_TASKS_ADMIN_FILTERS_MAIN_SETTINGS_NAME,
} from './constants';
import { compose } from 'redux';
import { asyncComponent } from '../../../hoc/asyncComponent/asyncComponent';
import { WorkerTasksAdminFiltersSettings } from './WorkerTasksAdminFiltersSettings';
import { sendSettingsSavedNotification } from '../../../constants/settings';


const mapStateToProps = state => {
  const {
    tasksFilters,
    isWorkerTasksFiltersManagingByAdmin,
  } = workerTasksAdminFiltersSettingsSelector(state);

  return {
    tasksFilters,
    isWorkerTasksFiltersManagingByAdmin,
  };
};

const mapDispatchToProps = {
  fetchSettings,
  saveSettingsEntity: saveSettingsEntityAndAddToStore,
};

const mergeProps = (stateProps, dispatchProps) => {

  const {
    tasksFilters,
    isWorkerTasksFiltersManagingByAdmin,
  } = stateProps;

  const {
    fetchSettings,
    saveSettingsEntity,
  } = dispatchProps;

  const saveWorkerTasksAdminFiltersSettings = ({ isWorkerTasksFiltersManagingByAdmin, tasksFilters }) =>
    saveSettingsEntity({
      group: WORKER_TASKS_ADMIN_FILTERS_MAIN_SETTINGS_GROUP,
      name: WORKER_TASKS_ADMIN_FILTERS_MAIN_SETTINGS_NAME,
      value: {
        isWorkerTasksFiltersManagingByAdmin,
        tasksFilters,
      },
    });

  return {
    tasksFilters,
    isWorkerTasksFiltersManagingByAdmin,
    fetchWorkerTasksAdminFiltersSettings: () => fetchSettings([{
      group: WORKER_TASKS_ADMIN_FILTERS_MAIN_SETTINGS_GROUP,
      name: WORKER_TASKS_ADMIN_FILTERS_MAIN_SETTINGS_NAME,
    }]),
    onManageWorkerTasksFiltersChange: checked => saveWorkerTasksAdminFiltersSettings({
      tasksFilters,
      isWorkerTasksFiltersManagingByAdmin: checked,
    })
      .then(sendSettingsSavedNotification),
    onWorkerTasksFiltersChange: tasksFilters => saveWorkerTasksAdminFiltersSettings({
      tasksFilters,
      isWorkerTasksFiltersManagingByAdmin,
    })
      .then(sendSettingsSavedNotification),
  };
};

export const WorkerTasksAdminFiltersSettingsContainer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
  ),
  asyncComponent({
    resolve: [
      {
        fn: ({ fetchWorkerTasksAdminFiltersSettings }) => fetchWorkerTasksAdminFiltersSettings(),
      },
    ],
  }),
)(WorkerTasksAdminFiltersSettings);