export const WORKER_APP_VIEW_MODE = {
  TASKS: 'TASKS',
  EQ_CLASS_IN_DEP_TASKS: 'EQ_CLASS_IN_DEP_TASKS',
};

export const USER_SETTINGS_ID = {
  WORKER_APP_TASKS_ADDITIONAL_FILTERS: 'WORKER_APP_TASKS_ADDITIONAL_FILTERS',
  WORKER_APP_EQ_CLASS_IN_DEP_TASKS_ADDITIONAL_FILTERS: 'WORKER_APP_EQ_CLASS_IN_DEP_TASKS_ADDITIONAL_FILTERS',
  WORKER_APP_VIEW_MODE: 'WORKER_APP_VIEW_MODE',
  WORKER_APP_LAST_VISITED_ROUTE: 'WORKER_APP_LAST_VISITED_ROUTE',
  WORKER_APP_TASKS_TABLE_FILTERS: 'WORKER_APP_TASKS_TABLE_FILTERS',
  WORKER_APP_SEARCH_HISTORY: 'WORKER_APP_SEARCH_HISTORY',
};
