import {
  HTTP_REQUEST_METHOD,
  HTTP_REQUEST_STATUS,
  DEFAULT_HTTP_REQUEST_HEADERS,
  DEFAULT_HTTP_REQUEST_WITH_JSON_BODY_HEADERS,
} from './constants';


const makeHttpRequest = (url, options = {}) => {
  const httpRequestOptions = {
    credentials: 'include',
    ...options,
    headers: { ...DEFAULT_HTTP_REQUEST_HEADERS, ...options.headers },
  };

  return fetch(url, httpRequestOptions)
    .then(response => Promise.all([response.json(), response.status]))
    .then(([jsonResponse, status]) => ({
      status: getRequestStatus(status),
      response: jsonResponse,
    }))
    .catch(() => {
      if (options.signal?.aborted) {
        return {
          status: HTTP_REQUEST_STATUS.ABORTED,
          response: options.signal.reason,
        };
      }

      return { status: HTTP_REQUEST_STATUS.CONNECTION_ERROR };
    });
};

export const getRequestStatus = statusCode => {
  if(statusCode >= 200 && statusCode < 300) return HTTP_REQUEST_STATUS.SUCCESS;
  if(statusCode === 401) return HTTP_REQUEST_STATUS.UNAUTHORIZED;
  if(statusCode === 403) return HTTP_REQUEST_STATUS.FORBIDDEN;
  return HTTP_REQUEST_STATUS.FAILED;
};

export const makeGETHttpRequest = (url, options = {}) =>
  makeHttpRequest(url, { ...options, method: HTTP_REQUEST_METHOD.GET });

const makeHttpRequestWithJsonBody = (url, data, options = {}) =>
  makeHttpRequest(
    url,
    {
      ...options,
      body: JSON.stringify(data),
      headers: {
        ...DEFAULT_HTTP_REQUEST_WITH_JSON_BODY_HEADERS,
        ...options.headers,
      },
    },
  );

export const makePOSTHttpRequest = (url, data, options = {}) =>
  makeHttpRequestWithJsonBody(url, data, { ...options, method: HTTP_REQUEST_METHOD.POST });

export const makePUTHttpRequest = (url, data, options = {}) =>
  makeHttpRequestWithJsonBody(url, data, { ...options, method: HTTP_REQUEST_METHOD.PUT });

export const makeDELETEHttpRequest = (url, data, options = {}) =>
  makeHttpRequestWithJsonBody(url, data, { ...options, method: HTTP_REQUEST_METHOD.DELETE });
