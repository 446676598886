import React, { useMemo } from 'react';
import {
  getEquipmentClassesInDepartmentsAutocompleteLoadOptionsActionCreator,
} from '../../../operations/equipmentClasses';
import { NoDataLabelTrans, NotSelectedLabelTrans } from '../../../utils/commonTransComponents';
import { AutocompleteContainer } from '../../common/Autocomplete/AutocompleteContainer';
import { getResourceCombinedName } from '@bfg-frontend/utils/lib/stringBuilders/entity';
import PropTypes from 'prop-types';


const equipmentClassesInDepartmentsAutocompleteLoadOptionsActionCreator =
  getEquipmentClassesInDepartmentsAutocompleteLoadOptionsActionCreator();

export const EquipmentClassesInDepartmentsAutocomplete = props => {

  const {
    shouldLoadOptions,
  } = props;

  const loadOptionsActionCreator = useMemo(
    () => {
      if(!shouldLoadOptions) {
        return undefined;
      }

      return equipmentClassesInDepartmentsAutocompleteLoadOptionsActionCreator;
    },
    [shouldLoadOptions],
  );

  return (
    <AutocompleteContainer
        {...props}
        getOptionLabel={equipmentClassInDepartmentAutocompleteGetOptionLabel}
        loadOptionsActionCreator={loadOptionsActionCreator}
        placeholder={NotSelectedLabelTrans}
        noOptionsMessage={NoDataLabelTrans}
    />
  );
};

EquipmentClassesInDepartmentsAutocomplete.propTypes = {
  shouldLoadOptions: PropTypes.bool,
};

export const equipmentClassInDepartmentAutocompleteGetOptionLabel = option => {
  const {
    departmentEntity = {},
    equipmentClassEntity = {},
  } = option;

  const departmentLabel = getResourceCombinedName(departmentEntity);
  const equipmentClassLabel = getResourceCombinedName(equipmentClassEntity);

  return [equipmentClassLabel, departmentLabel].join(' / ');
};
