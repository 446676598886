import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { MATERIAL_UI_STYLE_COLOR } from '../../../constants/materialUI';
import { Trans } from '@lingui/macro';

export const TASKS_TABLE_COLUMNS_DATA_KEYS_TO_SAVE = [
  'show',
  'order',
];

export const getWorkerTasksDraggableRowSchema = (onColumnVisibilityChange, isCheckboxDisabled) => {

  return [
    {
      renderRowItem: ({ columnName, show, displayName }) => (
        <React.Fragment>
          <Checkbox
              className="worker-tasks-table-columns-settings__visibility-checkbox"
              checked={show}
              onChange={e => onColumnVisibilityChange(e, columnName)}
              value={String(columnName)}
              color={MATERIAL_UI_STYLE_COLOR.DEFAULT}
              disabled={isCheckboxDisabled}
          />
            {displayName}
        </React.Fragment>
      ),

      headerTitle: (
        <Trans id="worker_tasks_table_settings.table_header@columns_visibility">
          Видимость колонки
        </Trans>
      ),
      id: 'column-visibility',
    },
    {
      renderRowItem: ({ visibleIndexToShow }) => visibleIndexToShow,
      headerTitle: (
        <Trans id="worker_tasks_table_settings.table_header@columns_order">
          Очередность в таблице
        </Trans>
      ),
      id: 'order',
    },
  ];
};


export const getWorkerTasksTableSettingsDraggableRowClassNames = ({ show }) => ({
  'simple-table-with-draggable-rows__draggable-row--is-visible': show,
});