import React from 'react';
import { MATERIAL_UI_THEME_COLOR_CODES } from './materialUI';
import {
  AdministratorLabelTrans,
  MasterLabelTrans,
  PlannerLabelTrans,
  StorageManagerLabelTrans,
  WorkerLabelTrans,
} from '../utils/commonTransComponents';
import { Trans } from '@lingui/macro';


export const PREDEFINED_USER_ROLES = {
  ADMIN: 'ADMIN',
  MASTER: 'MASTER',
  PLANNER: 'PLANNER',
  WORKER: 'WORKER',
  STORAGE_MANAGER: 'STORAGE_MANAGER',
};

/*
* Карта параметров для отрисовки чипов предопределенных в БД пользовательских ролей
* */
export const USER_ROLE_CHIP_PARAMS_MAP = {
  [PREDEFINED_USER_ROLES.ADMIN]: {
    label: AdministratorLabelTrans,
    color: '#aaa', // не используем здесь MATERIAL_UI_THEME_COLOR_CODES.DEFAULT чтобы не сливалось с фоном чипа
    avatarTrans: (
      <Trans id="user_avatar_letter@admin">
        А
      </Trans>
    ),
  },
  [PREDEFINED_USER_ROLES.WORKER]: {
    label: WorkerLabelTrans,
    color: MATERIAL_UI_THEME_COLOR_CODES.PRIMARY,
    avatarTrans:  (
      <Trans id="user_avatar_letter@worker">
        Р
      </Trans>
    ),
  },
  [PREDEFINED_USER_ROLES.PLANNER]: {
    label: PlannerLabelTrans,
    color: MATERIAL_UI_THEME_COLOR_CODES.WARNING,
    avatarTrans:  (
      <Trans id="user_avatar_letter@planner">
        П
      </Trans>
    ),
  },
  [PREDEFINED_USER_ROLES.MASTER]: {
    label: MasterLabelTrans,
    color: MATERIAL_UI_THEME_COLOR_CODES.SECONDARY,
    avatarTrans:  (
      <Trans id="user_avatar_letter@master">
        М
      </Trans>
    ),
  },
  [PREDEFINED_USER_ROLES.STORAGE_MANAGER]: {
    label: StorageManagerLabelTrans,
    color:MATERIAL_UI_THEME_COLOR_CODES.SUCCESS,
    avatarTrans:  (
      <Trans id="user_avatar_letter@storage_manager">
        К
      </Trans>
    ),
  },
};
