import PropTypes from 'prop-types';
import { FUNC_IS_REQUIRED_TYPE } from '../../../constants/propTypes';

export const TEXT_FIELD_TOGGLE_BUTTON_GROUP_SCHEMA_ITEM_TYPE = PropTypes.shape({
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
}).isRequired;



export const TEXT_FIELD_TOGGLE_BUTTON_GROUP_PROP_TYPES = {
  value: PropTypes.string.isRequired,
  onChange: FUNC_IS_REQUIRED_TYPE,
  toggleButtonsSchema: PropTypes.arrayOf(TEXT_FIELD_TOGGLE_BUTTON_GROUP_SCHEMA_ITEM_TYPE),
};