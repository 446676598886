import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  FUNC_IS_REQUIRED_TYPE,
  SHEET_SUMMARY_TYPE,
  TABLE_COLUMNS_CUSTOM_FILTERS_TYPE,
} from '../../constants/propTypes';
import cn from 'classnames';
import {
  EntitiesReviewTableFromFactory,
} from '../common/EntitiesReview/EntitiesReviewTableFromFactory/EntitiesReviewTableFromFactory';
import { SheetSummary } from '../PlannerApp/SheetSummary/SheetSummary';
import {
  EntitiesReviewTableItemDefaultContent,
} from '../common/EntitiesReview/EntitiesReviewTableFromFactory/EntitiesReviewTableComponent/EntitiesReviewTableItemDefaultContent/EntitiesReviewTableItemDefaultContent';
import { SimpleSummary } from '../common/SimpleSummary/SimpleSummary';
import { EntityReviewDialog } from '../common/EntitiesReview/EntityReviewDialog/EntityReviewDialog';
import {
  ASSEMBLY_SHEET_WAS_SEND_TO_PRODUCTION_EVENT_TYPE,
  PARTS_AND_MATERIALS_FOR_DEFAULT_SHEET_CONSUMED_EVENT_TYPE,
} from '../../constants/sockets';
import { NoDataLabelTrans } from '../../utils/commonTransComponents';
import _isFunction from 'lodash/isFunction';
import {
  CURRENT_SHEET_OPERATION_DATA_SECONDARY_SUMMARY_SCHEMA,
  SHEET_REVIEW_DIALOG_TOP_PANEL_HEIGHT_IN_PX,
} from './constants';

import './style.css';


export const Sheets = props => {


  const {
    sheetsIdentity,
    sheetItemIdProperty,
    fetchSheetsData,
    sheetsData,
    noDataText,
    startSheetReview,
    filtersSchema,
    renderSheetItemRightSideContent: renderSheetItemRightSideContentFromProps,
    renderSheetItemLeftSideContent: renderSheetItemLeftSideContentFromProps,
    getSheetItemAdditionalInfoLabels,
    sheetToReviewIdFromRoute,
    fetchSheetToReview,
    sheetReviewContentComponent,
    stopSheetReview,
    getClientMessagesDataArray,
    className,
    renderSelectedRowsControls,
    renderSelectedRowsActionsToast,
    withRowSelection,
    alwaysShowPagination,
    withPageSizeSelect,
  } = props;


  const renderSheetItemRightSideContent = useCallback(sheetData => {
    if(_isFunction(renderSheetItemRightSideContentFromProps)) {
      return renderSheetItemRightSideContentFromProps(sheetData);
    }

    const {
      currentSheetOperationData = {},
      entitiesInBatchAmount,
    } = sheetData;

    return (
      <SimpleSummary
          summaryData={{
            ...currentSheetOperationData,
            entitiesInBatchAmount,
          }}
          secondarySummarySchema={CURRENT_SHEET_OPERATION_DATA_SECONDARY_SUMMARY_SCHEMA}
      />
    );
  }, [renderSheetItemRightSideContentFromProps]);

  const renderSheetItemLeftSideContent = useCallback(sheetData => {

    if(_isFunction(renderSheetItemLeftSideContentFromProps)) {
      return renderSheetItemLeftSideContentFromProps(sheetData);
    }

    const {
      entityIdentity,
      entityCode,
      entityName,
      sheetIdentity,
      entitiesInBatchAmount,
      entityBatchIdentity,
      orderName,
      startDate,
      stopDate,
    } = sheetData;

    return (
      <div className="sheets__left-side-content">
        <SheetSummary
            entityIdentity={entityIdentity}
            entityCode={entityCode}
            entityName={entityName}
            sheetIdentity={sheetIdentity}
            entitiesInBatchAmount={entitiesInBatchAmount}
            entityBatchIdentity={entityBatchIdentity}
            orderName={orderName}
            startDate={startDate}
            stopDate={stopDate}
        />
      </div>
    );
  }, [renderSheetItemLeftSideContentFromProps]);


  const renderItemContent = useCallback(entityData => (
    <EntitiesReviewTableItemDefaultContent
        entityData={entityData}
        renderItemLeftSideContent={renderSheetItemLeftSideContent}
        renderItemRightSideContent={renderSheetItemRightSideContent}
        getItemAdditionalInfoLabels={getSheetItemAdditionalInfoLabels}
    />
  ), [renderSheetItemRightSideContent, renderSheetItemLeftSideContent, getSheetItemAdditionalInfoLabels]);

  /*
  * Из-за текущей реализации с множеством локальных стейтов проблематично реализовывать обновление данных при
  * броадкастинге. У нас глобальные обработчики для событий броадкастинга, в которых нет данных о локальных стейтах
  * компонента. Поэтому пока нашлось решение для таких компонентов добавлять для сокета дополнительный обработчик
  * прямо в компоненте. В данном случае, речь о EntityReviewDialog, которому в пропсы можно передать обработчик
  * событий в сокете. Возвращаемые аргументы обработчика, которые можно использовать описаны в EntityReviewDialog
  * */
  const sheetReviewSocketMsgHandler = useCallback((
    message,
    { entityToReviewData: sheetToReviewData },
    _,
    { initEntityToReviewDataRemote: updateSheetToReviewData },
  ) => {

    if (sheetToReviewData === null) return;

    const {
      entityBatchId,
    } = sheetToReviewData;

    getClientMessagesDataArray(message)
      .then(messageDataArr => {

        /*
        * Отслеживаем следующие кейсы при просмотре маршрутного листа:
        *  - стандартный маршрутный лист комплектуют
        *  - сборочный маршрутный лист отправляют в производство
        *
        * В этих случаях важно обновить локальный стейт entityReview, т.к. есть сценарии работы в этих случаях, когда
        * важно обновить интерфейс:
        *  - когда комплектуют стандартный МЛ, то на экране завершения МЛ в производстве нужно чтобы обновился
        * статус, в зависимости от этого на экране меняются информационные сообщения и доступность кнопок
        *  - когда в производство отправляют сборочный МЛ, то при просмотре этого же МЛ важно, чтобы информация
        * обновилась, иначе отправлять в производство можно будет повторно другому пользователю
        *
        * Вероятно, обработчик будет расширяться, т.к. есть и другие кейсы обновления данных просматриваемого МЛ,
        * просто пока их обработка не признана критичной в отличии от описанных кейсов
        * */
        const shouldUpdateSheetToReview = !!messageDataArr
          .find(
            ({ data, event }) =>
              (
                event === PARTS_AND_MATERIALS_FOR_DEFAULT_SHEET_CONSUMED_EVENT_TYPE ||
                event === ASSEMBLY_SHEET_WAS_SEND_TO_PRODUCTION_EVENT_TYPE
              )  && entityBatchId === data.entityBatchId,
          );

        if (shouldUpdateSheetToReview) {
          updateSheetToReviewData();
        }
      });
  }, [getClientMessagesDataArray]);

  return (
    <div className={cn(className, 'sheets')}>
      <EntitiesReviewTableFromFactory
          tableId={sheetsIdentity}
          tableModel={sheetsIdentity}
          rowsData={sheetsData}
          fetchRemoteTableData={fetchSheetsData}
          noDataContent={noDataText}
          onRowClick={startSheetReview}
          wrappedTableComponentProps={{
            entityItemIdProperty: sheetItemIdProperty,
            renderItemContent: renderItemContent,
            renderSelectedRowsControls,
            withRowSelection,
            renderSelectedRowsActionsToast,
          }}
          customFilters={filtersSchema}
          alwaysShowPagination={alwaysShowPagination}
          withPageSizeSelect={withPageSizeSelect}
      />

      <EntityReviewDialog
          entityToReviewIdFromRoute={sheetToReviewIdFromRoute}
          fetchEntityToReview={fetchSheetToReview}
          stopEntityReview={stopSheetReview}
          entityReviewContentComponent={sheetReviewContentComponent}
          entityReviewContentComponentPropsAdapter={sheetReviewComponentPropsAdapter}
          renderDialogAppBarContent={renderSheetReviewDialogBarContent}
          fetchEntitiesData={fetchSheetsData}
          entityIdProperty={sheetItemIdProperty}
          entitiesData={sheetsData}
          fixedDialogAppBarHeight={SHEET_REVIEW_DIALOG_TOP_PANEL_HEIGHT_IN_PX}
          onSocketMessage={sheetReviewSocketMsgHandler}
      />

    </div>
  );
};

Sheets.propTypes = {
  className: PropTypes.string,
  sheetsIdentity: PropTypes.string.isRequired,
  sheetItemIdProperty: PropTypes.string.isRequired,
  fetchSheetsData: PropTypes.func,
  sheetsData: PropTypes.arrayOf(SHEET_SUMMARY_TYPE),
  fetchSheetToReview: PropTypes.func,
  sheetToReviewIdFromRoute: PropTypes.string,
  noDataText: PropTypes.node,
  renderSheetItemRightSideContent: PropTypes.func,
  renderSheetItemLeftSideContent: PropTypes.func,
  getSheetItemAdditionalInfoLabels: PropTypes.func,
  sheetReviewContentComponent: PropTypes.elementType,
  startSheetReview: FUNC_IS_REQUIRED_TYPE,
  stopSheetReview: FUNC_IS_REQUIRED_TYPE,
  filtersSchema: TABLE_COLUMNS_CUSTOM_FILTERS_TYPE,
  getClientMessagesDataArray: FUNC_IS_REQUIRED_TYPE,
  renderSelectedRowsControls: PropTypes.func,
  renderSelectedRowsActionsToast: PropTypes.func,
  withRowSelection: PropTypes.bool,
  alwaysShowPagination: PropTypes.bool,
  withPageSizeSelect: PropTypes.bool,
};

Sheets.defaultProps = {
  sheetsData: [],
  noDataText: NoDataLabelTrans,
  filtersSchema: {},
};

const renderSheetReviewDialogBarContent = entityToReviewData => (
  <div className="sheet-review-dialog__bar-content">
    <div className="sheet-review-dialog__summary">
      <SheetSummary {...entityToReviewData}/>
    </div>
  </div>
);
const sheetReviewComponentPropsAdapter = props => ({
  closeReviewDialog: props.stopEntityReview,
  fetchSheetsData: props.fetchEntitiesData,
  sheetToReviewData: props.entityToReviewData,
  updateSheetToReviewData: props.initEntityToReviewDataRemote,
});

