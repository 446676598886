import React from 'react';
import PropTypes from 'prop-types';
import { FORMAT_SHORT_TIME } from '../../../constants/dateFormats';
import { Table } from '../../Table/Table';
import {
  FUNC_IS_REQUIRED_TYPE,
  MASTER_TASKS_TABLE_ROW_DATA_TYPE,
  TABLE_COLUMNS_CUSTOM_FILTERS_TYPE,
} from '../../../constants/propTypes';

import './style.css';


const MASTER_TASKS_TABLE_MENU_PROPS = {
  disableSummaryOption: true,
};

const MASTER_TASKS_TABLE_CUSTOM_CELL_RENDERERS_PROPS = {
  startDate: {
    dateFormat: FORMAT_SHORT_TIME,
  },
  stopDate: {
    dateFormat: FORMAT_SHORT_TIME,
  },
};

export const MasterTasksTable = props => {
  const {
    tableId,
    tableModel,
    fetchRemoteTableData,
    tableData,
    noDataContent,
    onRowClick,
    getRowStyle,
    customFilters,
  } = props;
  return (
    <div className="master-tasks-table">
      <Table
          tableId={tableId}
          tableModel={tableModel}
          getRowStyle={getRowStyle}
          fetchRemoteTableData={fetchRemoteTableData}
          rowsData={tableData}
          noDataContent={noDataContent}
          onRowClick={onRowClick}
          customCellRenderersProps={MASTER_TASKS_TABLE_CUSTOM_CELL_RENDERERS_PROPS}
          tableMenu={MASTER_TASKS_TABLE_MENU_PROPS}
          disableSort
          customFilters={customFilters}
      />
    </div>
  );
};

MasterTasksTable.propTypes = {
  tableId: PropTypes.string.isRequired,
  tableModel: PropTypes.string.isRequired,
  fetchRemoteTableData: FUNC_IS_REQUIRED_TYPE,
  tableData: PropTypes.arrayOf(MASTER_TASKS_TABLE_ROW_DATA_TYPE),
  noDataContent: PropTypes.node.isRequired,
  onRowClick: PropTypes.func,
  getRowStyle: PropTypes.func,
  customFilters: TABLE_COLUMNS_CUSTOM_FILTERS_TYPE,
};
