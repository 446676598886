/*
* В данный момент, последний посещенный роут сохраняется только для пользователя "рабочего". Селектор создан сразу
* общий, поэтому и логика определения, что пользователь рабочий, в данный момент, расположена просто внутри него.
* Т.е.:
*  - Если пользователь рабочий и workerAppLastVisitedRouteSelector определен, значит это "последний посещенный роут пользователя"
*  - Если пользователь не рабочий (т.е. у него или несколько разрешений на просмотр разных типов приложений, или
* разрешений на просмотр раздела рабочего, вообще, нет), то "последний посещенный роут пользователя" пока считается
* неопределенным
* */
import { getUserAppsReviewPermissions } from '../../utils/permissions/index';
import { PERMISSION } from '../../hoc/withPermissionsManager/constants';
import { WORKER_APP_MAIN_ROUTE } from '../../constants/routes';
import { workerAppLastVisitedRouteSelector } from '../../reducers/appState/selectors';

export const getCurrentUserLastVisitedRoute = userData =>
  (dispatch, getState) => {
    const userAppReviewPermission = getUserAppsReviewPermissions(userData.permissions);

    /*
    * Пользователь является рабочим, если у него доступно для просмотра только одно приложение рабочего
    */
    if(userAppReviewPermission.length === 1 && userAppReviewPermission[0] === PERMISSION.REVIEW_WORKER_APP) {
      const workerAppLastVisitedRoute = workerAppLastVisitedRouteSelector(getState());
      return workerAppLastVisitedRoute || WORKER_APP_MAIN_ROUTE;
    }

    return null;

  };