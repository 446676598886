import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { SheetsContainer } from '../../../Sheets/SheetsContainer';
import { SheetToStartReviewContentContainer } from '../SheetToStartReviewContent/SheetToStartReviewContentContainer';

import { DEFAULT_DATE_INTERVAL_START, DEFAULT_DATE_INTERVAL_STOP, SHEET_TYPE } from '../../../../constants/sheets';
import { PLANNER_APP_SHEETS_TO_START_ROUTE } from '../../../../constants/routes';
import {
  AcceptLabelTrans,
  CancelLabelTrans,
  FilterNotSelectedLabelTrans,
  GroupSheetsStartInProgressTooltipLabelTrans,
  NoDataLabelTrans,
  NoMatchesFoundLabelTrans,
  StartAllSelectedSheetsLabelTrans,
} from '../../../../utils/commonTransComponents';
import moment from 'moment';
import { FORMAT_SHORT_TIME } from '../../../../constants/dateFormats';
import { Trans } from '@lingui/macro';

import './style.css';
import { DateTimeIntervalForm } from '../DateTimeIntervalForm/DateTimeIntervalForm';
import { MATERIAL_UI_STYLE_COLOR, MATERIAL_UI_VARIANT } from '../../../../constants/materialUI';
import CheckIcon from '@mui/icons-material/Check';
import Button from '@mui/material/Button';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import { useConfirm } from '../../../AppConfirm/AppConfirmContext';
import { addDecimals } from '@bfg-frontend/utils/lib/decimal';
import {
  abortGroupSheetsStartConfirmOptions,
  GROUP_SHEETS_START_BROADCAST_STATUS,
  sendPartOfSheetsStartedNotification,
} from './constants';
import { GroupSheetsStartDialog } from './GroupSheetsStartDialog/GroupSheetsStartDialog';
import {
  ARRAY_OF_ANY_OBJECTS_TYPE,
  FUNC_IS_REQUIRED_TYPE,
  NUMBER_OR_STRING_TYPE,
  OBJECT_OF_ANY_TYPE,
  SHEET_TO_START_SUMMARY_TYPE,
  TABLE_FILTER_PARAMS_TYPE,
} from '../../../../constants/propTypes';
import PropTypes from 'prop-types';
import { sendStartedSheetsAmountNotification } from '../../../../operations/sheets';
import { TooltipWithForwardedRef } from '../../../common/TooltipWrapper/TooltipWithForwardedRef';
import { useConfirmOnLeave } from '../../../../hoc/confirmOnLeave/confirmOnLeave';
import { NOTIFICATION_LEVEL } from '../../../../constants/notification';
import _throttle from 'lodash/throttle';
import { getEntityCombinedName } from '@bfg-frontend/utils/lib/stringBuilders/entity';
import { TextFormField } from '../../../common/TextFormField/TextFormField';


const ALL_SHEETS_TO_START_REQUIRED_AMOUNT_TEXT_FIELD_ID =
  'ALL_SHEETS_TO_START_REQUIRED_AMOUNT_TEXT_FIELD_ID';

const entityBatchAmountLabel = (
  <Trans id="all_sheets_to_start@entity_batch_amount_label">
    Размер партии
  </Trans>
);

const entityBatchAmountSearchLabel = (
  <Trans id="all_sheets_to_start@entity_batch_amount_search_label">
    Поиск
  </Trans>
);



const dateIntervalFilterValidator = (intervalStart, intervalStop) =>
  moment(intervalStart).isSameOrAfter(intervalStop) ?
    <Trans id="all_sheets_to_start@date_interval_filters_error">
      Начало интервала должно быть раньше, чем конец интервала
    </Trans> :
    undefined;

export const AllSheetsToStart = props => {
  const {
    mainPlanningSessionId,
    currentSheetToStartDataRequestStartDate,
    currentSheetToStartDataRequestStopDate,
    fetchSheetsToStart,
    isSheetsToStartDataInStore,
    startSheet,
    handleGroupSheetsStarted,
    sheetsToStartById,
    sheetsToStartData,
    filtersSchema,
    broadcastGroupSheetsProcessAndAddToStore,
    isGroupSheetsStartInProgress,
    tableFilterParams,
    resetSelectedTableRows,
    canSearchByRequiredAmount,
    onSetRequiredAmount,
  } = props;

  /** refs ***/

  /* флаг того, что сейчас происходит массовый запуск */
  const isGroupSheetsStartGoingOnRef = useRef(false);

  /*** state ***/

  /* данные всех выбранных МЛ */
  const [groupSheetsToStart, setGroupSheetsToStart] = useState({});
  /* количество запущенных МЛ, используется для отображения прогресса запуска */
  const [startedSheetsAmount, setStartedSheetsAmount] = useState(0);
  /* состояние модального окна запуска (редактирование/прогресс запуска) */
  const [isDialogInProgressMode, setIsDialogInProgressMode] = useState(false);
  /* необходимый размер партий */
  const [requiredAmount, setRequiredAmount] = useState('');

  /*** custom hooks ***/
  const confirm = useConfirm();

  useConfirmOnLeave(
    ({ isGroupSheetsStartGoingOn }) => isGroupSheetsStartGoingOn,
    { isGroupSheetsStartGoingOn: isGroupSheetsStartGoingOnRef.current },
  );


  /*** мемоизированные данные ***/

  /* количество МЛ для массового запуска */
  const sheetsToStartAmount = useMemo(() => Object.keys(groupSheetsToStart).length, [groupSheetsToStart]);

  /* список данных уникальных ДСЕ и суммы их количества для всех запускаемых МЛ */
  const entitiesInSheetsToStart = useMemo(() => {

    const entitiesToStartData = Object
      .values(groupSheetsToStart)
      .reduce((acc, { entityIdentity, entityCode, entityName, entitiesInBatchAmount }) => {

        const entityCombinedName = getEntityCombinedName({
          identity: entityIdentity,
          code: entityCode,
          name: entityName,
        });

        const currentEntityData = acc[entityIdentity];

        if (!currentEntityData) {
          acc[entityIdentity] = {
            entitiesInBatchAmount,
            entityCombinedName,
          };

          return acc;
        }

        acc[entityIdentity] = {
          ...currentEntityData,
          entitiesInBatchAmount: addDecimals(currentEntityData.entitiesInBatchAmount, entitiesInBatchAmount),
        };

        return acc;
      }, {});

    return Object.values(entitiesToStartData);
  }, [groupSheetsToStart]);



  /*** калбеки ***/

  /* Броадкастит количество текущих запущенных МЛ и их общее количество во время процесса массового запуска */
  const broadcastGroupSheetsStartProgress = useCallback(({ startedSheetsAmount, sheetsToStartAmount }) => {
    broadcastGroupSheetsProcessAndAddToStore({
      status: GROUP_SHEETS_START_BROADCAST_STATUS.IN_PROGRESS,
      startedSheetsAmount,
      sheetsToStartAmount,
    });
  }, [broadcastGroupSheetsProcessAndAddToStore]);

  const throttledBroadcastEventAndAddToStore = useMemo(
    () => _throttle(
      broadcastGroupSheetsStartProgress,
      5000,
      { leading: false },
    ),
    [broadcastGroupSheetsStartProgress],
  );

  const clearGroupSheetsToStartState = useCallback(() => setGroupSheetsToStart({}), [setGroupSheetsToStart]);

  const setIsGroupSheetsStartGoingOn = useCallback(value => {
    isGroupSheetsStartGoingOnRef.current = value;
  }, []);

  /* записывает в локальный стейт выбранные в таблице МЛы */
  const setSelectedSheetsFromTable = useCallback(selectedSheetsToStartByPage => {

    const selectedSheetsToStartIds = Object
      .values(selectedSheetsToStartByPage)
      .reduce((acc, selectedSheetsToStartOnCurrentPage) => {
        return acc.concat(Object.keys(selectedSheetsToStartOnCurrentPage));
      }, []);

    setGroupSheetsToStart(_pick(sheetsToStartById, selectedSheetsToStartIds));
  }, [sheetsToStartById]);

  /*
  очищает локальный стейт после завершения или отмены группового запуска МЛов и вызывает очистку данных
  для текущего и остальных интерфейсов текущего пользователя
  */
  const finishGroupSheetsStart = useCallback(startedSheets => {
    throttledBroadcastEventAndAddToStore.cancel();
    setIsGroupSheetsStartGoingOn(false);
    broadcastGroupSheetsProcessAndAddToStore({
      status: GROUP_SHEETS_START_BROADCAST_STATUS.FINISHED,
      startedSheetsAmount: startedSheets.length,
      sheetsToStartAmount,
    });
    setStartedSheetsAmount(0);
    clearGroupSheetsToStartState();
    setIsDialogInProgressMode(false);
    handleGroupSheetsStarted(startedSheets);

  }, [
    broadcastGroupSheetsProcessAndAddToStore,
    handleGroupSheetsStarted,
    clearGroupSheetsToStartState,
    sheetsToStartAmount,
    // сеттеры
    setStartedSheetsAmount,
    setIsGroupSheetsStartGoingOn,
    setIsDialogInProgressMode,
    throttledBroadcastEventAndAddToStore,
  ]);

  /* запускает МЛы по одному  */
  const groupSheetsStart = useCallback(() => {
    setIsDialogInProgressMode(true);
    setIsGroupSheetsStartGoingOn(true);

    /* в startedSheets накапливаем данные запущенных МЛ */
    const startedSheets = [];
    const startSheetsOneByOneCb = async() => {

      for (const entityBatchFromIaId in groupSheetsToStart) {

        if (!isGroupSheetsStartGoingOnRef.current) {
          /*
          Если во время запуска изменился groupSheetsStartDialogModeRef, значит пользователь в ручную остановил запуск
          МЛов, возвращаем ошибку. В catch будет выведено уведомление с информацией о запущенных и не запущенных МЛах
          */
          return Promise.reject();
        }

        const {
          entityBatchIdentity: entityBatchFromIaIdentity,
          fromState,
        } = groupSheetsToStart[entityBatchFromIaId];

        const startedSheetData = await startSheet(entityBatchFromIaId, entityBatchFromIaIdentity, fromState);

        setStartedSheetsAmount(prevState => prevState + 1);

        startedSheets.push(startedSheetData);

        /* Выполняем броадкастинг прогресса запуска когда меняется количество запущенных МЛ */
        throttledBroadcastEventAndAddToStore({
          startedSheetsAmount: startedSheets.length,
          sheetsToStartAmount,
        });
      }
    };

    broadcastGroupSheetsProcessAndAddToStore({
      status: GROUP_SHEETS_START_BROADCAST_STATUS.STARTED,
      sheetsToStartAmount,
      startedSheetsAmount: 0,
    });


    startSheetsOneByOneCb()
      .then(() => sendStartedSheetsAmountNotification(startedSheets.length, sheetsToStartAmount, NOTIFICATION_LEVEL.SUCCESS))
      .catch(() => {
        sendPartOfSheetsStartedNotification(startedSheets.length, sheetsToStartAmount);
      })
      .finally(() => finishGroupSheetsStart(startedSheets));
  }, [
    groupSheetsToStart,
    finishGroupSheetsStart,
    startSheet,
    broadcastGroupSheetsProcessAndAddToStore,
    setIsDialogInProgressMode,
    setIsGroupSheetsStartGoingOn,
    sheetsToStartAmount,
    throttledBroadcastEventAndAddToStore,
  ]);

  /* отменяет массовый запуск после подтверждения конфирма */
  const abortGroupSheetsStart = useCallback(() => {
    confirm(abortGroupSheetsStartConfirmOptions)
      .then(() => setIsGroupSheetsStartGoingOn(false));
  }, [confirm, setIsGroupSheetsStartGoingOn]);

  const handleRequiredAmountChanged = useCallback(newValue => {
    setRequiredAmount(newValue);
  }, [setRequiredAmount]);

  const handleRequiredAmountSubmit = useCallback(e => {
    e.preventDefault();
    onSetRequiredAmount(requiredAmount);
  }, [onSetRequiredAmount, requiredAmount]);

  const renderSearchByBatchAmount = useCallback(() => {
    if (!canSearchByRequiredAmount) {
      return null;
    }

    return (
      <form
          onSubmit={handleRequiredAmountSubmit}
          className="required-amount-form"
      >
        <TextFormField
            variant={MATERIAL_UI_VARIANT.OUTLINED}
            id={ALL_SHEETS_TO_START_REQUIRED_AMOUNT_TEXT_FIELD_ID}
            label={entityBatchAmountLabel}
            onChange={handleRequiredAmountChanged}
            value={requiredAmount}
            className="required-amount-text-field"
        />
        <Button
            variant={MATERIAL_UI_VARIANT.TEXT}
            type="submit"
        >
          {entityBatchAmountSearchLabel}
        </Button>
      </form>
    );
  }, [canSearchByRequiredAmount, handleRequiredAmountChanged, handleRequiredAmountSubmit, requiredAmount]);


  const renderGroupSheetsStartButton = useCallback(selectedSheetsToStart => (
    <TooltipWithForwardedRef
        title={isGroupSheetsStartInProgress ? GroupSheetsStartInProgressTooltipLabelTrans : null}
    >
      <Button
          color={MATERIAL_UI_STYLE_COLOR.SUCCESS}
          variant={MATERIAL_UI_VARIANT.CONTAINED}
          startIcon={<CheckIcon />}
          onClick={() => setSelectedSheetsFromTable(selectedSheetsToStart)}
          disabled={!!_isEmpty(selectedSheetsToStart) || isGroupSheetsStartInProgress}
      >
        {StartAllSelectedSheetsLabelTrans}
      </Button>
    </TooltipWithForwardedRef>
  ), [setSelectedSheetsFromTable, isGroupSheetsStartInProgress]);

  const renderSelectedRowsControls = useCallback(selectedSheetsToStart => {
    return (
      <React.Fragment>
        {renderSearchByBatchAmount()}
        {renderGroupSheetsStartButton(selectedSheetsToStart)}
      </React.Fragment>
    );
  }, [renderGroupSheetsStartButton, renderSearchByBatchAmount]);


  /*** эффекты ***/

  /* сбрасываем выбранные строки таблицы при изменении фильтров */
  useEffect(() => {
    resetSelectedTableRows(SHEET_TYPE.ALL_TO_START);
  }, [tableFilterParams, resetSelectedTableRows]);


  /* на unmount останавливаем процесс массовго запуска */
  useEffect(() => {
    return () => setIsGroupSheetsStartGoingOn(false);
    // eslint-disable-next-line
  }, []);


  const dateIntervalFilterProps = {
    mainPlanningSessionId,
    currentSheetToStartDataRequestStartDate,
    currentSheetToStartDataRequestStopDate,
    fetchSheetsToStart,
    isSheetsToStartDataInStore,
  };

  return (
    <div className="all-sheets-to-start">
      {renderDateIntervalFilters(dateIntervalFilterProps)}
      {
        isSheetsToStartDataInStore ?
          (
            <SheetsContainer
                className="all-sheets-to-start__sheets-container"
                mainRoutePath={PLANNER_APP_SHEETS_TO_START_ROUTE}
                sheetsIdentity={SHEET_TYPE.ALL_TO_START}
                sheetItemIdProperty="entityBatchId"
                sheetsData={sheetsToStartData}
                sheetReviewContentComponent={SheetToStartReviewContentContainer}
                noDataText={NoDataLabelTrans}
                filtersSchema={filtersSchema}
                renderSelectedRowsControls={renderSelectedRowsControls}
                withRowSelection
                renderSelectedRowsActionsToast={renderSelectedRowsToastContent}
                alwaysShowPagination
                withPageSizeSelect
            />
          ) : null
      }

      {
        _isEmpty(groupSheetsToStart) ? null :
          (
            <GroupSheetsStartDialog
                onClose={clearGroupSheetsToStartState}
                isDialogInProgressMode={isDialogInProgressMode}
                startedSheetsAmount={startedSheetsAmount}
                sheetsToStartAmount={sheetsToStartAmount}
                entitiesInSheetsToStart={entitiesInSheetsToStart}
                onAbortSheetsStartProcessClick={abortGroupSheetsStart}
                onSheetsStartClick={groupSheetsStart}
                isGroupSheetsStartInProgress={isGroupSheetsStartInProgress}
            />
          )
      }
    </div>
  );
};

const renderDateIntervalFilters = props => {
  const {
    mainPlanningSessionId,
    currentSheetToStartDataRequestStartDate,
    currentSheetToStartDataRequestStopDate,
    fetchSheetsToStart,
    isSheetsToStartDataInStore,
  } = props;

  if(!mainPlanningSessionId)
    return (
      <div className="all-sheets-to-start__no-sheets-to-start-label">
        <Trans id="all_sheets_to_start@no_sheets_to_start">
          В данный момент нет ни одного маршрутного листа на запуск
        </Trans>
      </div>
    );

  const intervalStart = currentSheetToStartDataRequestStartDate === null ?
    DEFAULT_DATE_INTERVAL_START :
    currentSheetToStartDataRequestStartDate;

  const intervalStop = currentSheetToStartDataRequestStopDate === null ?
    DEFAULT_DATE_INTERVAL_STOP :
    currentSheetToStartDataRequestStopDate;

  return (
    <div className="all-sheets-to-start__date-time-interval-filters">
      <DateTimeIntervalForm
          format={FORMAT_SHORT_TIME}
          intervalStartLabel={
          <Trans id="all_sheets_to_start@date_interval_filters_start_label">
            Начало интервала
          </Trans>
        }
          intervalStopLabel={
          <Trans id="all_sheets_to_start@date_interval_filters_stop_label">
            Конец интервала
          </Trans>
        }
          submitBtnLabel={AcceptLabelTrans}
          cancelChangesBtnLabel={CancelLabelTrans}
          intervalStartInitValue={intervalStart}
          intervalStopInitValue={intervalStop}
          validator={dateIntervalFilterValidator}
          isSubmitBtnHidden={(intervalStartFromPicker, intervalStopFromPicker) => {
            if(isSheetsToStartDataInStore === false) {
              return false;
            }

            return (
            moment(intervalStartFromPicker).isSame(intervalStart) &&
            moment(intervalStopFromPicker).isSame(intervalStop)
            );
          }}
          onSubmit={(startDate, stopDate) =>
          fetchSheetsToStart(
            moment(startDate).format(),
            moment(stopDate).format(),
          )}
      />
    </div>
  );
};


const renderSelectedRowsToastContent = selectedRowsById => {
  return (
    <div className="all-sheets-to-start__selected-sheets-notification">
      <Trans id="all_sheets_to_start@seleted_sheets_amount">
        Выбран(о) {Object.keys(selectedRowsById).length} МЛ
      </Trans>
    </div>
  );
};

AllSheetsToStart.propTypes = {
  startSheet: FUNC_IS_REQUIRED_TYPE,
  handleGroupSheetsStarted: FUNC_IS_REQUIRED_TYPE,
  broadcastGroupSheetsProcessAndAddToStore: FUNC_IS_REQUIRED_TYPE,
  isGroupSheetsStartInProgress: PropTypes.bool,
  fetchSheetsToStart: FUNC_IS_REQUIRED_TYPE,
  currentSheetToStartDataRequestStartDate: PropTypes.string,
  currentSheetToStartDataRequestStopDate: PropTypes.string,
  mainPlanningSessionId: NUMBER_OR_STRING_TYPE,
  isSheetsToStartDataInStore: PropTypes.bool.isRequired,
  sheetsToStartData: PropTypes.arrayOf(SHEET_TO_START_SUMMARY_TYPE),
  sheetsToStartById: OBJECT_OF_ANY_TYPE,
  resetSelectedTableRows: FUNC_IS_REQUIRED_TYPE,
  tableFilterParams: TABLE_FILTER_PARAMS_TYPE,
  filtersSchema: PropTypes.objectOf(
    PropTypes.shape({
      getFilterTitle: PropTypes.func.isRequired,
      getFilterChipContent: PropTypes.func.isRequired,
      filterComponent: PropTypes.elementType.isRequired,
      filterComponentProps: PropTypes.shape({
        autocompleteId: PropTypes.string.isRequired,
        getOptionLabel: PropTypes.func.isRequired,
        placeholder: FilterNotSelectedLabelTrans.isRequired,
        noOptionsMessage: NoMatchesFoundLabelTrans.isRequired,
        loadOptionsActionCreator: PropTypes.func,
        options: ARRAY_OF_ANY_OBJECTS_TYPE,
      }).isRequired,
    }),
  ),
  canSearchByRequiredAmount: PropTypes.bool,
  onSetRequiredAmount: PropTypes.func,
};