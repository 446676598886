import React from 'react';
import {
  resetGroupSheetsStartProcessState,
  setGroupSheetsStartProcessData,
} from '../../../../reducers/plannerApp/groupSheetsStart/actions';
import {
  GROUP_SHEETS_START_BROADCAST_STATUS,
} from '../../../../components/PlannerApp/SheetsToStart/AllSheetsToStart/constants';
import { NOTIFICATION_LEVEL, sendNotification } from '../../../../constants/notification';
import { Trans } from '@lingui/macro';
import { sendStartedSheetsAmountNotification } from '../../../../operations/sheets';
import { matchPath } from 'react-router-dom';
import { PLANNER_APP_SHEETS_TO_START_ROUTE } from '../../../../constants/routes';
import { routerPathnameSelector } from '../../../../reducers/router/selectors';

const sendGroupSheetsStartFinishedNotification = (startedSheetsAmount, sheetsToStartAmount) => sendNotification(
  <Trans id="sheet_to_start@group_sheets_start_process_finished">
    Массовый запуск маршрутных листов завершён. Запущено {startedSheetsAmount} из {sheetsToStartAmount} МЛ.
  </Trans>,
  NOTIFICATION_LEVEL.SUCCESS,
);

/*
Обработчик массового запуска маршрутных листов. Реализация без бэкенда. Логика:
1. Пользователь получает сообщение о начале массового запуска, эти данные записываются в store, кнопки запуска
маршрутных листов блокируются.
2. Запускается setTimeout на 10 секунд, который очищает стейт процесса запуска и
разблокирует кнопки запуска МЛов. Это нужно на случай, если пользователь запустивший массовый запуск не отправит
сообщение о его завершении, потому что закроет страницу браузера или перейдёт на другую.
2. Пока идёт процесс запуска, каждые 5 секунд броадкастится сообщение о процессе запуска. Если пришло сообщение о
прогрессе, то timeOut из пункта 1 - удаляется и запускается снова.
3. Приходит сообщение о завершении массового запуска или срабатывает timeout  пункта 1.
*/

const RESET_GROUP_SHEETS_START_PROCESS_TIMEOUT = 10000;

let timeoutId = null;

export const handleGroupSheetsStartProcess = message =>
  (dispatch, getState) => {

    const {
      status,
      sheetsToStartAmount,
      startedSheetsAmount,
    } = message;

    const state = getState();

    const currentPathname = routerPathnameSelector(state);


    const sheetsToStartRouteMatch = matchPath(currentPathname, {
      path: PLANNER_APP_SHEETS_TO_START_ROUTE,
    });

    /*
    Если НЕ находимся в разделе "Плановик. МЛ на запуск", то ничего не делаем, т.к. массовый запуск МЛ влияет
    только на этот экран
    */
    if (sheetsToStartRouteMatch === null) return;

    /*
    Выводим нотификейшн о завершении массового запуска и очищаем стейт
    */
    const resetGroupSheetsStartState = () => {
      sendGroupSheetsStartFinishedNotification(startedSheetsAmount, sheetsToStartAmount);
      dispatch(resetGroupSheetsStartProcessState());
    };

    /*
    Записываем текущее состояние массового запуска в store. От этого зависит блокировка кнопок запуска МЛов
    */
    dispatch(setGroupSheetsStartProcessData({
      status,
      sheetsToStartAmount,
      startedSheetsAmount,
    }));

    switch (status) {

    case GROUP_SHEETS_START_BROADCAST_STATUS.STARTED: {
      timeoutId = setTimeout(resetGroupSheetsStartState, RESET_GROUP_SHEETS_START_PROCESS_TIMEOUT);

      sendNotification(
        <Trans id="sheet_to_start@group_sheets_start_process_begin">
          Выполняется процесс массового запуска маршрутных листов. Запуск маршрутных листов будет отключен, пожалуйста
          дождитесь окончания процесса
        </Trans>,
        NOTIFICATION_LEVEL.INFO,
      );

      break;
    }

    /*
    Если процесс запуска продолжается, то перезапусаем timeout и отправляем нотификейшн с прогрессом
    */
    case GROUP_SHEETS_START_BROADCAST_STATUS.IN_PROGRESS: {
      clearTimeout(timeoutId);
      sendStartedSheetsAmountNotification(startedSheetsAmount, sheetsToStartAmount);
      timeoutId = setTimeout(resetGroupSheetsStartState, RESET_GROUP_SHEETS_START_PROCESS_TIMEOUT);

      break;
    }

    /*
    Если процесс запуска завершился, то удаляем timeout, очищаем стейт и вызываем нотификейшн о завершении массового
    запуска
    */
    case GROUP_SHEETS_START_BROADCAST_STATUS.FINISHED: {
      resetGroupSheetsStartState();
      clearTimeout(timeoutId);

      break;
    }

    default: return;
    }


  };