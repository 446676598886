import { combineReducers } from 'redux';

import { sheetsToStart } from './sheetsToStart/reducer';
import { lastSheetsOperations } from './lastSheetsOperations/reducer';
import { groupSheetsStart } from './groupSheetsStart/reducer';

export const plannerAppReducer = combineReducers({
  sheetsToStart,
  lastSheetsOperations,
  groupSheetsStart,
});