import { compose } from 'redux';
import { connect } from 'react-redux';
import { UsersTableScreen } from './UsersTableScreen';
import { ADMIN_APP_USERS_ROUTE, NEW_USER_SUB_ROUTE } from '../../../constants/routes';
import { push } from 'connected-react-router';
import {
  activateUser,
  deactivateUser,
  fetchActiveUsersRemoteTableData,
  reFetchActiveUsersRemoteTableData,
  reFetchDeactivatedUsersRemoteTableData,
} from '../../../operations/users';
import {
  activeUsersRemoteTableDataSelector,
  deactivatedUsersRemoteTableDataSelector,
} from '../../../selectors/usersAdministration';
import {
ADMIN_APP_ACTIVE_USERS_TABLE_ID,
 ADMIN_APP_DEACTIVATED_USERS_TABLE_ID,
  } from './constants';
import {
  fetchDeactivatedUsersRemoteTableData,
} from '../../../operations/users/index';
import { clearTableRemoteData } from '../../../reducers/table/actions';
import { withPermissionsManager } from '../../../hoc/withPermissionsManager/withPermissionsManager';
import {
  appUserIdSelector,
} from '../../../reducers/appState/selectors';


const mapStateToProps = state => ({
  appUserId: appUserIdSelector(state),
  activeUsersTableId: ADMIN_APP_ACTIVE_USERS_TABLE_ID,
  deactivatedUsersTableId: ADMIN_APP_DEACTIVATED_USERS_TABLE_ID,
  activeUsersTableData: activeUsersRemoteTableDataSelector(state),
  deactivatedUsersTableData: deactivatedUsersRemoteTableDataSelector(state),
});

const mapDispatchToProps = dispatch => ({
  onAddUser: () => dispatch(push(`${ADMIN_APP_USERS_ROUTE}/${NEW_USER_SUB_ROUTE}`)),
  fetchActiveUsers: ({ tableParams }) => dispatch(fetchActiveUsersRemoteTableData(tableParams)),
  fetchDeactivatedUsers: ({ tableParams }) => dispatch(fetchDeactivatedUsersRemoteTableData(tableParams)),
  editUser: id => dispatch(push(`${ADMIN_APP_USERS_ROUTE}/${id}`)),
  deactivateUser: id => Promise.resolve()
    .then(() => dispatch(deactivateUser(id)))
    .then(() => dispatch([
      reFetchActiveUsersRemoteTableData(),
      clearTableRemoteData([ADMIN_APP_DEACTIVATED_USERS_TABLE_ID]),
    ])),
  activateUser: userId => dispatch(activateUser(userId))
    .then(() => dispatch([
      reFetchDeactivatedUsersRemoteTableData(),
      clearTableRemoteData([ADMIN_APP_ACTIVE_USERS_TABLE_ID]),
    ])),
});

export const UsersTableScreenContainer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withPermissionsManager,
)(UsersTableScreen);