import React from 'react';
import { createCaEntitiesAutocompleteLoadOptionsActionCreator } from '../../../reducers/autocomplete/actions';
import { DEPARTMENT_MODEL } from '../../../constants/models';
import { FILTER_GROUP_TYPES, FILTER_TYPES } from '../../../api/restCollectionApi/index';
import { NoDataLabelTrans, NotSelectedLabelTrans } from '../../../utils/commonTransComponents';
import { AutocompleteContainer, DefaultAutocompleteContainer } from '../../common/Autocomplete/AutocompleteContainer';
import { getResourceCombinedName } from '@bfg-frontend/utils/lib/stringBuilders/entity';
import { NAME_FIELD_DEFAULT_SORT_BY } from '../../../constants/entities';


const departmentsAutocompleteLoadOptionsActionCreator = createCaEntitiesAutocompleteLoadOptionsActionCreator({
  requestModel: DEPARTMENT_MODEL,
  getRequestQuery: inputValue => {

    if (!inputValue) {
      return {
        /*
        * Наименования подразделений, скорее всего, в большинстве случаев будут уникальны, поэтому сортировки
        * только по этому полю будет достаточно. Пока решено не усложнять выборку множественной сортировкой
        * */
        sortBy: NAME_FIELD_DEFAULT_SORT_BY,
      };
    }

    return {
      filter: {
        filterGroupType: FILTER_GROUP_TYPES.OR,
        filters: [
          {
            column: 'identity',
            filterType: FILTER_TYPES.CONTAINS,
            filterValue: inputValue,
          },
          {
            column: 'name',
            filterType: FILTER_TYPES.CONTAINS,
            filterValue: inputValue,
          },
        ],
      },
      sortBy: NAME_FIELD_DEFAULT_SORT_BY,
    };
  },
});


/*
* Простая обертка над AutocompleteContainer с фиксацией части пропсов для автокомплита подразделений, т.к. он
* используется довольно часто. Пропсы, передаваемые, в компонент просто распаковывваются в сам AutocompleteContainer,
* никакой дополнительной логики решено было не добавлять
* */
export const DepartmentsAutocomplete = props =>
  <AutocompleteContainer
      {...props}
      getOptionLabel={getResourceCombinedName}
      loadOptionsActionCreator={departmentsAutocompleteLoadOptionsActionCreator}
      placeholder={NotSelectedLabelTrans}
      noOptionsMessage={NoDataLabelTrans}
  />;


export const DefaultDepartmentsAutocomplete = props =>
  <DefaultAutocompleteContainer
      {...props}
      getOptionLabel={getResourceCombinedName}
      loadOptionsActionCreator={departmentsAutocompleteLoadOptionsActionCreator}
      placeholder={NotSelectedLabelTrans}
      noOptionsMessage={NoDataLabelTrans}
  />;