import { components } from 'react-select';
import PropTypes from 'prop-types';
import './style.css';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  MATERIAL_UI_SIZE,
  MATERIAL_UI_VARIANT,
} from '../../../../constants/materialUI';
import { Button } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { FUNC_IS_REQUIRED_TYPE } from '../../../../constants/propTypes';
import _debounce from 'lodash/debounce';

const { MenuList } = components;

const MENU_LIST_SCROLL_BUTTON_HEIGHT = 31;

const SCROLL_BUTTONS_STATES = {
  IS_VISIBLE: 'IS_VISIBLE',
  IS_HIDDEN: 'IS_HIDDEN',
  IS_DISABLED: 'IS_DISABLED',
};

const MENU_LIST_SCROLL_COEFFICIENT = 0.75;

const getCurrentScrollButtonState = (isHidden, isDisabled) => {
  if (isHidden) return SCROLL_BUTTONS_STATES.IS_HIDDEN;
  if (isDisabled) return SCROLL_BUTTONS_STATES.IS_DISABLED;

  return SCROLL_BUTTONS_STATES.IS_VISIBLE;
};

export const AutocompleteMenuListWithScrollButtons = props => {

  const {
    innerRef,
    children,
    maxHeight,
  } = props;

  const menuListRef = useRef();
  const wrapperRef =  useRef();

  const [scrollButtonsState, setScrollButtonsState] = useState({
    topButton: SCROLL_BUTTONS_STATES.IS_HIDDEN,
    bottomButton: SCROLL_BUTTONS_STATES.IS_HIDDEN,
  });


  const scrollToTop = useCallback(() => {
    if (!menuListRef.current) return;

    const { current: menuListDomElement } = menuListRef;

    const { scrollTop, offsetHeight } = menuListDomElement;

    if (scrollTop === 0) return;

    menuListDomElement.scrollTo({
      top: scrollTop - offsetHeight * MENU_LIST_SCROLL_COEFFICIENT,
      behavior: 'smooth',
    });
  }, [menuListRef]);


  const scrollToBottom = useCallback(() => {
    if (!menuListRef.current) return;

    const { current: menuListDomElement } = menuListRef;

    const { scrollTop, offsetHeight, scrollHeight } = menuListDomElement;

    if ((scrollTop + offsetHeight) === scrollHeight) return;

    menuListDomElement.scrollTo({
      top: scrollTop + offsetHeight * MENU_LIST_SCROLL_COEFFICIENT,
      behavior: 'smooth',
    });
  }, [menuListRef]);


  const updateScrollButtonsState = useCallback(() => {
    if (!menuListRef.current) return;

    const {
      scrollTop,
      scrollHeight,
      offsetHeight,
    } = menuListRef.current;

    /*
    Скрываем кнопки, когда скролла нет
    */
    const isScrollButtonsHidden = offsetHeight === scrollHeight;

    const isScrollToTopButtonDisabled = scrollTop === 0;
    const isScrollToBottomButtonDisabled = (scrollTop + offsetHeight) === scrollHeight;

    setScrollButtonsState({
      topButton: getCurrentScrollButtonState(isScrollButtonsHidden, isScrollToTopButtonDisabled),
      bottomButton: getCurrentScrollButtonState(isScrollButtonsHidden, isScrollToBottomButtonDisabled),
    });

  }, [menuListRef, setScrollButtonsState]);


  const throttledScrollHandler = useMemo(
    () => _debounce(updateScrollButtonsState, 200),
    [updateScrollButtonsState],
  );

  const getMenuListRef = useCallback(ref => {
    if (menuListRef.current) return;

    menuListRef.current  = ref;

    return innerRef(wrapperRef.current);
  }, [innerRef]);


  useEffect(() => {
    menuListRef.current.addEventListener('scroll', throttledScrollHandler);
    return () => menuListRef.current.removeEventListener('scroll', throttledScrollHandler);
    // eslint-disable-next-line
  }, []);


  /*
  При изменении значения инпута в автокомплите меняется количество опций и изменяется высота их контейнера. Из-за
  этого скролл может пропадать и появляться, но слушатель скролла срабатывать не будет и кнопки прокрутки будут оставаться
  на экране. Поэтому при изменении количества children в этом useEffect вычисляем нужно ли рендерить кнопки прокрутки.
  Ссылка на children в react-select каждый раз новая, она обновляется даже при наведении мыши на опции. Поэтому в
  зависимостях useEffect используем children.length, чтобы updateScrollButtonsState вызывался не так часто.
  Событие "resize" тут использовать не получится, потому что при изменении количества опций высота контейнера
  в большинстве случаев остаётся такой же, но при этом скролл может появляться и исчезать.
  */
  useEffect(updateScrollButtonsState, [children.length, updateScrollButtonsState]);

  /**
   * @type {number}
   */
  const menuListMaxHeight = useMemo(() => {
    /**
    * Кнопки скрываются только когда скрола нет, если скрыта одна, то скрыта и вторая, нет смысле проверять обе кнопки
    */
    const isScrollButtonsHidden = scrollButtonsState.topButton === SCROLL_BUTTONS_STATES.IS_HIDDEN;

    if (!isScrollButtonsHidden) return maxHeight - MENU_LIST_SCROLL_BUTTON_HEIGHT * 2;

    return maxHeight;
  }, [maxHeight, scrollButtonsState]);

  return (
    <div ref={wrapperRef} className="autocomplete-menu-list-with-scroll-buttons">

      {_renderScrollButton(scrollButtonsState.topButton, scrollToTop, <ExpandLess />)}

      <MenuList
          {...props}
          className="autocomplete-menu-list-with-scroll-buttons__menu-list"
          innerRef={getMenuListRef}
          children={children}
          maxHeight={menuListMaxHeight}
      />

      {_renderScrollButton(scrollButtonsState.bottomButton, scrollToBottom, <ExpandMore />)}

    </div>
  );
};

const _renderScrollButton = (scrollButtonState, onClick, icon) => {

  const isDisabled = scrollButtonState === SCROLL_BUTTONS_STATES.IS_DISABLED;
  const isHidden = scrollButtonState === SCROLL_BUTTONS_STATES.IS_HIDDEN;

  if (isHidden) return null;

  return (
    <Button
        disabled={isDisabled}
        style={{
          height: MENU_LIST_SCROLL_BUTTON_HEIGHT,
        }}
        variant={MATERIAL_UI_VARIANT.TEXT}
        onClick={onClick}
        className="autocomplete-menu-list-with-scroll-buttons__scroll-button"
        size={MATERIAL_UI_SIZE.LARGE}
    >
      {icon}
    </Button>
  );
};


AutocompleteMenuListWithScrollButtons.propTypes = {
  innerRef: FUNC_IS_REQUIRED_TYPE,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.element,
  ]).isRequired,
  maxHeight: PropTypes.number.isRequired,
};