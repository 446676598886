import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './style.css';

import {
  SHEET_OPERATION_TABLE_ROW_DATA_SHAPE_OBJ,
} from '../../../PlannerApp/SheetOperationsTable/SheetOperationsTable.types';
import {
  FUNC_IS_REQUIRED_TYPE,
  INAPPROPRIATE_SHEET_TO_REVIEW_DATA_TYPE,
  SHEET_OPERATION_STATUS_TYPE,
} from '../../../../constants/propTypes';
import { SheetOperationsTable } from '../../../PlannerApp/SheetOperationsTable/SheetOperationsTable';
import {
  SheetOperationReviewDialogContainer,
} from '../../../common/SheetOperationReviewDialog/SheetOperationReviewDialogContainer';
import { SimpleSummary } from '../../../common/SimpleSummary/SimpleSummary';
import {
  markSheetInappropriateStateUnconfirmedLabelTrans,
  markSheetFinalDefectiveStateConfirmedLabelTrans,
  CancelLabelTrans,
} from '../../../../utils/commonTransComponents';
import Button from '@mui/material/Button';
import { MATERIAL_UI_STYLE_COLOR, MATERIAL_UI_VARIANT } from '../../../../constants/materialUI';
import { SimpleConfirmDialog } from '../../../common/SimpleConfirmDialog/SimpleConfirmDialog';
import { SheetSummary } from '../../../PlannerApp/SheetSummary/SheetSummary';
import { Trans } from '@lingui/macro';
import { SimpleHelpAlert } from '../../../common/SimpleHelpAlert/SimpleHelpAlert';
import { INAPPROPRIATE_SHEET_SECONDARY_SUMMARY_SCHEMA } from '../constants';
import _get from 'lodash/get';
import { ENTITY_BATCH_TRANSACTION_TYPE } from '../../../../constants/sheets';
import { isAlwaysTrueCb } from '../../../../constants/common';
import { PERMISSION, PERMISSIONS_MANAGER_TYPE } from '../../../../hoc/withPermissionsManager/constants';


export class InappropriateSheetReviewContent extends Component {

  static propTypes = {
    sheetOperationsTableId: PropTypes.string.isRequired,
    sheetOperationsTableData: PropTypes.arrayOf(
      PropTypes.shape({
        ...SHEET_OPERATION_TABLE_ROW_DATA_SHAPE_OBJ,
        status: SHEET_OPERATION_STATUS_TYPE.isRequired,
        progress: PropTypes.number.isRequired,
      }),
    ),
    fetchSheetOperations: FUNC_IS_REQUIRED_TYPE,
    markSheetInappropriateStateUnconfirmed: FUNC_IS_REQUIRED_TYPE,
    markSheetFinalDefectiveStateConfirmed: FUNC_IS_REQUIRED_TYPE,
    getSheetOperationsTableRowStyle: FUNC_IS_REQUIRED_TYPE,
    sheetToReviewData: INAPPROPRIATE_SHEET_TO_REVIEW_DATA_TYPE,
    PermissionsManager: PERMISSIONS_MANAGER_TYPE,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedSheetOperationData: null,
      isInappropriateStateUnconfirmedDialogOpen: false,
      isFinalDefectiveStateConfirmedDialogOpen: false,
    };
  };

  _renderSheetSummary = () => (
    <SimpleHelpAlert
        className="inappropriate-sheet-review-content__sheet_summary"
        content={this._getSheetSummaryContent()}
    />
  );

  _getSheetSummaryContent = () => {
    const {
      sheetToReviewData,
    } = this.props;

    const markedAsInappropriateEntityBatchTransactionEntity = _get(
      sheetToReviewData,
      [
        'entityBatchTransactionsByType',
        ENTITY_BATCH_TRANSACTION_TYPE.MARKED_AS_INAPPROPRIATE,
      ],
    );

    if (!markedAsInappropriateEntityBatchTransactionEntity) return null;

    const {
      defectReason,
      entityBatchTransactionCreateStamp,
      userName,
    } = markedAsInappropriateEntityBatchTransactionEntity;

    return (
      <SimpleSummary
          summaryData={{
            defectReason,
            entityBatchTransactionCreateStamp,
            userName,
          }}
          secondarySummarySchema={INAPPROPRIATE_SHEET_SECONDARY_SUMMARY_SCHEMA}
      />
    );
  };

  _renderActionButtons = () => {
    const {
      PermissionsManager,
    } = this.props;

    return (
      <div className="inappropriate-sheet-review-content__actions">
        {
          PermissionsManager.isDenied(PERMISSION.MARK_SHEET_INAPPROPRIATE_STATE_UNCONFIRMED) ? null :
            this._renderActionButton(
              markSheetInappropriateStateUnconfirmedLabelTrans,
              () => this.setState({ isInappropriateStateUnconfirmedDialogOpen: true }),
            )
        }
        {
          PermissionsManager.isDenied(PERMISSION.MARK_SHEET_INAPPROPRIATE_STATE_CONFIRMED) ? null :
          this._renderActionButton(
            markSheetFinalDefectiveStateConfirmedLabelTrans,
            () => this.setState({ isFinalDefectiveStateConfirmedDialogOpen: true }),
          )
        }
      </div>
    );
  }

  _renderActionButton = (label, onClick) => (
    <div className="inappropriate-sheet-review-content__action-button">
      <Button
          color={MATERIAL_UI_STYLE_COLOR.SECONDARY}
          variant={MATERIAL_UI_VARIANT.CONTAINED}
          onClick={onClick}
      >
        {label}
      </Button>
    </div>
  );

  _renderSheetOperationsTable = () => {
    const {
      sheetOperationsTableId,
      sheetOperationsTableData,
      fetchSheetOperations,
      getSheetOperationsTableRowStyle,
    } = this.props;

    return(
      <SheetOperationsTable
          sheetOperationsTableId={sheetOperationsTableId}
          sheetOperationsTableData={sheetOperationsTableData}
          fetchSheetOperations={fetchSheetOperations}
          onSheetOperationRowClick={this._handleSheetOperationRowClick}
          getSheetOperationsTableRowStyle={getSheetOperationsTableRowStyle}
      />
    );
  };

  _handleSheetOperationRowClick = (_, sheetOperationData) => {

    const {
      sheetToReviewData: {
        entityIdentity,
        entityCode,
        entityName,
        sheetIdentity,
        entityBatchId,
        entitiesInBatchAmount,
        orderId,
        orderName,
        providingState,
      },
    } = this.props;

    this.setState({
      selectedSheetOperationData: {
        ...sheetOperationData,
        entityIdentity,
        entityCode,
        entityName,
        sheetIdentity,
        entityBatchId,
        entitiesInBatchAmount,
        orderId,
        orderName,
        providingState,
      },

    });
  };

  _renderSheetOperationReviewDialog = () => {
    const {
      selectedSheetOperationData,
    } = this.state;

    if(selectedSheetOperationData === null) return;

    return(
      <SheetOperationReviewDialogContainer
          closeDialog={this._handleSheetOperationReviewDialogClose}
          sheetOperationData={selectedSheetOperationData}
          areStatusChangeButtonsHidden={isAlwaysTrueCb}
          isPauseSheetAdditionalActionHidden={isAlwaysTrueCb}
          isSplitEntityBatchAdditionalActionHidden={isAlwaysTrueCb}
          isAssigneesChangeDisabled={isAlwaysTrueCb}
          isEquipmentChangeDisabled={isAlwaysTrueCb}
          isNoteChangeDisabled={isAlwaysTrueCb}
          isConsumeEntitiesActionHidden={isAlwaysTrueCb}
      />
    );
  };

  _handleSheetOperationReviewDialogClose = () => this.setState({ selectedSheetOperationData: null });

  _renderMarkSheetInappropriateStateUnconfirmedConfirmDialog = () => {
    const {
      isInappropriateStateUnconfirmedDialogOpen,
    } = this.state;

    const {
      sheetToReviewData,
      markSheetInappropriateStateUnconfirmed,
    } = this.props;

    return (
      <SimpleConfirmDialog
          className="inappropriate-sheet-review-content__mark-inappropriate-state-unconfirmed-dialog"
          closeDialog={this._handleMarkSheetInappropriateStateUnconfirmedConfirmDialogClose}
          isOpen={isInappropriateStateUnconfirmedDialogOpen}
          title={
            <Trans id="inappropriate_sheet.mark_sheet_inappropriate_state_unconfirmed@confirm-modal-title">
              Вернуть в работу
            </Trans>
          }
          confirmText={
            <Trans id="inappropriate_sheet.mark_sheet_inappropriate_state_unconfirmed@confirm-modal-text">
              Маршрутный лист будет возвращен в работу на тот этап, на котором был отмечен брак. Вы уверены?
            </Trans>
          }
          confirmTextChipClassName={'confirmTextChipClassName'}
          confirmBtn={markSheetInappropriateStateUnconfirmedLabelTrans}
          onConfirm={markSheetInappropriateStateUnconfirmed}
          cancelBtn={CancelLabelTrans}
          additionalComponent={this._renderConfirmDialogSummary(sheetToReviewData)}
      />
    );
  };

  _handleMarkSheetInappropriateStateUnconfirmedConfirmDialogClose = () =>
    this.setState({ isInappropriateStateUnconfirmedDialogOpen: false });

  _renderMarkSheetFinalDefectiveStateConfirmedConfirmDialog = () => {
    const {
      isFinalDefectiveStateConfirmedDialogOpen,
    } = this.state;

    const {
      sheetToReviewData,
      markSheetFinalDefectiveStateConfirmed,
    } = this.props;

    return (
      <SimpleConfirmDialog
          className="inappropriate-sheet-review-content__mark-final-defective-state-confirmed-dialog"
          closeDialog={this._handleMarkSheetFinalDefectiveStateConfirmedConfirmDialogClose}
          isOpen={isFinalDefectiveStateConfirmedDialogOpen}
          title={
            <Trans id="inappropriate_sheet.mark_sheet_final_defective_state_confirmed@confirm-modal-title">
              Списать в окончательный брак
            </Trans>
          }
          confirmText={
            <Trans id="inappropriate_sheet.mark_sheet_final_defective_state_confirmed@confirm-modal-text">
              Данному маршрутному листу будет присвоен статус 'Окончательный брак'. После перепланирования система
              сформирует новый аналогичный маршрутный лист для повторного запуска в производство. Вы уверены?
            </Trans>
          }
          confirmTextChipClassName={'confirmTextChipClassName'}
          confirmBtn={markSheetFinalDefectiveStateConfirmedLabelTrans}
          onConfirm={markSheetFinalDefectiveStateConfirmed}
          cancelBtn={CancelLabelTrans}
          additionalComponent={this._renderConfirmDialogSummary(sheetToReviewData)}
      />
    );
  };

  _handleMarkSheetFinalDefectiveStateConfirmedConfirmDialogClose = () =>
    this.setState({ isFinalDefectiveStateConfirmedDialogOpen: false });

  _renderConfirmDialogSummary = sheetToReviewData => (
    <div className="inappropriate-sheet-review-content__confirm-dialog-summary">
      <SheetSummary
          withEntityLabel
          {...sheetToReviewData}
      />
      {this._getSheetSummaryContent()}
    </div>
  )

  render() {
    return (
      <div className="inappropriate-sheet-review-content">
        {this._renderSheetSummary()}
        {this._renderActionButtons()}
        {this._renderSheetOperationsTable()}
        {this._renderSheetOperationReviewDialog()}
        {this._renderMarkSheetInappropriateStateUnconfirmedConfirmDialog()}
        {this._renderMarkSheetFinalDefectiveStateConfirmedConfirmDialog()}
      </div>
    );
  }
}
