import {
  TABLE_CLEAR_DATA,
  TABLE_CLEAR_REMOTE_DATA,
  TABLE_SET_PARAMS,
  TABLE_UPDATE_REMOTE_ITEM_BY_ID,
  TABLE_SET_REMOTE_SUMMARY_DATA,
  SET_SELECTED_TABLE_ROWS,
  RESET_SELECTED_TABLE_ROWS,
  REMOVE_SELECTED_TABLE_ROWS,
} from './actions';

import _omit from 'lodash/omit';
import _isFunction from 'lodash/isFunction';
import _mapValues from 'lodash/mapValues';
import _get from 'lodash/get';
import { additionalTableReducer } from './additionalTableReducer/index';
import _isEmpty from 'lodash/isEmpty';


const createTableReducer = additionalReducer =>
  (state = {}, action) => {
    switch(action.type) {
    case TABLE_UPDATE_REMOTE_ITEM_BY_ID: {
      const { tableId } = action;

      if(!state[tableId]) {
        return state;
      }

      return {
        ...state,
        [tableId]: updateRemoteTableItemById(state[tableId], action),
      };
    }
    case TABLE_SET_PARAMS:
    case TABLE_SET_REMOTE_SUMMARY_DATA:
    case SET_SELECTED_TABLE_ROWS:
    case RESET_SELECTED_TABLE_ROWS:
    case REMOVE_SELECTED_TABLE_ROWS:
      return {
        ...state,
        [action.tableId]: updateTableParams(state[action.tableId], action),
      };
    case TABLE_CLEAR_REMOTE_DATA: {

      const { tableIds = [] } = action;
      if(tableIds.length === 0) return state;

      const tableIdsToClearSet = new Set(action.tableIds);

      return _mapValues(
        state,
        (tableIdState, tableId) =>
          tableIdsToClearSet.has(tableId) ?
            ({
              ...tableIdState,
              remoteData: null,
            }) :
            tableIdState,
      );
    }
    case TABLE_CLEAR_DATA:
      return _omit(state, action.tableIdOrIdsArray);
    default:
      return _isFunction(additionalReducer) ?
          additionalReducer(state, action) :
          state;
    }
  };

const updateRemoteTableItemById = (state, action) => {

  const { model, itemId, itemDataToUpdate } = action;

  const currentItem = _get(
    state,
    [
      'remoteData',
      'currentRemoteItemsById',
      model,
      itemId,
    ],
  );

  /*
  * Если в табличном хранилище нет данных с указанным идентификатором, то ничего не обновляем, иначе могут получиться
  * конфликты с текущими серверными данными. Подразумевается, что в таком случае данные таблицы ещё или не запрошены,
  * или сейчас таблица содержит другие данные и указанная сущность по itemId обновиться при следующем запросе
  * */
  if(!currentItem) {
    return state;
  }

  const { currentRemoteItemsById } = state.remoteData;

  return {
    ...state,
    remoteData: {
      ...state.remoteData,
      currentRemoteItemsById: {
        ...currentRemoteItemsById,
        [model]: {
          ...currentRemoteItemsById[model],
          [itemId]: {
            ...currentItem,
            ...itemDataToUpdate,
          },
        },
      },
    },
  };
};

function updateTableParams(state = {}, action) {
  switch(action.type) {

    /*
    * В текущей реализации было утверждено, что параметры сортировки таблиц и количества отображаемых строк на странице
    * таблиц привязываются к модели таблицы, а не к id таблицы. Т.е. у таблицы с одной моделью эти настройки будут
    * одинаковыми.
    * Объясняется это только тем, что пока в процессе использования показалось, что, для таблиц с одинаковой моделью но с
    * разными id (это, как правило, одни и те же отчеты но для разных сессий), указанные настройки должны быть общими,
    * т.к. это логично и удобно. Дефолтную сортировку, как правило, мы задаём при описании модели таблицы для всех таблиц
    * этой модели (так ещё и сложилось исторически, всегда там её и хранили). Количество отображаемых строк на странице,
    * вообще, кажется, более логичным в рамках модели, т.к. если пользователь под себя хочет настроить один из "типов
    * отчета", чтобы удобней анализировать, то он хочет настроить это для любого отчета этого типа
    * Все остальные параметры таблицы больше привязаны к данным таблицы, а не к модели, и всегда привязываются к
    * конкретной таблице по id. Поэтому, sortParams и pageSize из экшена TABLE_SET_PARAMS в текущей реализации
    * обрабатывается отдельно в reducers/schemaModel.js. В селекторах параметров таблиц, сортировка и количество строк
    * тоже получается обособленно из schemaModel.
    * Вполне вероятно, что в будущем это может измениться.
    * */
  case TABLE_SET_PARAMS: {
    const {
        activePage,
        filterParams,
        remoteData,
      } = action.tableParams;

    return {
      ...state,
      activePage: activePage || state.activePage,
      filterParams: filterParams ?
          { ...filterParams } :
          state.filterParams,
      remoteData: getRemoteTableParams(remoteData),
    };
  }
  case SET_SELECTED_TABLE_ROWS: {
    return {
      ...state,
      selectedRows: action.data,
    };
  }
  case RESET_SELECTED_TABLE_ROWS: {
    return {
      ...state,
      selectedRows: {},
    };
  }
  case REMOVE_SELECTED_TABLE_ROWS: {
    if (!state.selectedRows) return state;

    return {
      ...state,
      /*
      Выбранные строки таблицы хранятся в разрезе страниц, это нужно для реализации логики выбора с зажатым shift
      на нескольких страницах.
      Поэтому тут пробегаемся по всем страницам и ищем идентификатор строки, которую нужно удалить
      */
      selectedRows: Object
        .keys(state.selectedRows)
        .reduce((acc, tablePage) => {
          const selectedTableRowsForCurrentPage = _omit(state.selectedRows[tablePage], action.rowIdsToRemove);

          if (_isEmpty(selectedTableRowsForCurrentPage)) {
            return _omit(acc, [tablePage]);
          }

          acc[tablePage] = selectedTableRowsForCurrentPage;

          return acc;
        }, { ...state.selectedRows }),
    };
  }
  case TABLE_SET_REMOTE_SUMMARY_DATA:
    return{
      ...state,
      remoteData: {
        ...state.remoteData,
        currentSummaryData: { ...action.summaryData },
      },
    };
  default: return state;
  }
}

const getRemoteTableParams = remoteTableData => {
  if (!remoteTableData) return null;

  const{
    currentRemoteItemsIds,
    currentRemoteItemsById,
    totalRemoteItemsAmount,
  } = remoteTableData;

  return{
    currentRemoteItemsIds,
    currentRemoteItemsById,
    totalRemoteItemsAmount,
  };
};

export const tableReducer = createTableReducer(additionalTableReducer);
