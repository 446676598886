import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import './style.css';

import _isNil from 'lodash/isNil';
import _mapValues from 'lodash/mapValues';
import _isEqual from 'lodash/isEqual';
import _constant from 'lodash/constant';
import _isEmpty from 'lodash/isEmpty';
import { Trans } from '@lingui/macro';
import { MATERIAL_UI_STYLE_COLOR } from '../../../constants/materialUI';
import { FUNC_IS_REQUIRED_TYPE, NUMBER_OR_STRING_TYPE } from '../../../constants/propTypes';
import {
  EMPTY_PASSWORD_VALUE,
  USER_EDITING_SCREEN_FIELDS_SCHEMA,
} from './userEditingScreenFieldsSchema';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { isIdInUrlParamsValid } from '../../../utils/url';
import { ROLE_MODEL, USER_MODEL, USER_ROLE_MODEL } from '../../../constants/models';
import { USERS_TABLE_DATA_TYPE } from '../UsersTable/constants';
import { REQUEST_BODY_FIELDS_SERVER_ERRORS } from '../../../constants/serverErrors/requestBodyFieldsServerErrors';
import { SERVER_ERROR_IDENTITY } from '../../../constants/serverErrors/index';
import { useConfirmOnLeave } from '../../../hoc/confirmOnLeave/confirmOnLeave';
import { useConfirm } from '../../AppConfirm/AppConfirmContext';
import { Tooltip } from '@mui/material';
import { CardWithCustomHeader } from '../../common/CardWithCustomHeader/CardWithCustomHeader';
import NavigateBefore from '@mui/icons-material/NavigateBefore';
import {
  CreateLabelTrans,
  DeactivateLabelTrans,
  EditLabelTrans,
  RequestBodyFieldUnknownErrorLabelTrans,
} from '../../../utils/commonTransComponents';


const defaultGetProps = _constant({});


const USER_EDITING_REQUEST_SERVER_ERRORS_TRANS_MAP = {
  [REQUEST_BODY_FIELDS_SERVER_ERRORS.CAN_NOT_BE_NULL]: (
    <Trans id="user_editing@field_is_required_error">
      Обязательное поле
    </Trans>
  ),
  [REQUEST_BODY_FIELDS_SERVER_ERRORS.IS_NOT_BETWEEN_MINIMUM_AND_MAXIMUM]: (
    <Trans id="user_editing@field_is_too_long_error">
      Значение должно содержать менее 128 символов
    </Trans>
  ),

  //Эта ошибка обрабатывается кастомно и поэтому здесь отличающийся ключ, т.к. в отличии от остальных она не является
  //ошибкой структуры запроса, это логическая ошибка
  [SERVER_ERROR_IDENTITY.USER_IDENTITY_ALREADY_EXISTS]: (
    <Trans id="user_editing@user_identity_already_exists_error">
      Пользователь с таким табельным номером уже существует в системе
    </Trans>
  ),
};

const USER_CREATING_FORM_INITIAL_STATE = {
  name: '',
  lastName: '',
  patronymicName: '',
  identity: '',
  userRoles: [],
  password: EMPTY_PASSWORD_VALUE,
  note: '',
};

const getError = (formDataError, values, key) => {
  if(_isNil(formDataError)) return null;

  const {
    errorMsg,
    errorValue,
  } = formDataError;

  return checkCurrentValueEquality(errorValue, values[key], key) ?
    USER_EDITING_REQUEST_SERVER_ERRORS_TRANS_MAP[errorMsg] || RequestBodyFieldUnknownErrorLabelTrans :
    null;
};

const checkCurrentValueEquality = (errorValue, value, key) => {

  /* Отдельно обрабатываем ключ userRoles */
  if(key === 'userRoles') {
    return _isEqual(
      errorValue.map(({ id }) => id).sort(),
      value.map(({ id }) => id).sort(),
    );
  }

  /*
  * В formDataErrors записываются подготовленные данные, которые отправляются в обработчик сабмита формы. Для
  * строковых значений при подготовке данных выполняется trim(). Именно эти значения и попадают в эту функцию
  * в errorValue. Поэтому, чтобы корректно сравнить, что текущее значение строкового поля value идентично тому, с
  * которым была ошибка при прошлом сабмите, тоже выполняем для него trim(), иначе добавив пробел в начале или конце
  * после ошибочного сабмита в форме будет пропадать идентификация ошибки, хотя фактически были просто добавлены
  * незначащие пробелы.
  * */
  const valueToCompare = (typeof value === 'string') ? value.trim() : value;

  return _isEqual(errorValue, valueToCompare);
};

const initializeEditingScreenData = (
  isCreatingMode,
  setValues,
  setInitialValues,
  fetchUserById,
  redirectToUserAdministrationScreen,
  userIdFromRoute,
  activeUsersTableData,
) => {

  // для случая создания нового пользователя никаких данных запрашивать не нужно
  if(isCreatingMode)
    return setValues(USER_CREATING_FORM_INITIAL_STATE);

  // если ID в url невалидный, то редиректим на экран просмотра списка пользователей
  if(!isIdInUrlParamsValid(userIdFromRoute))
    return redirectToUserAdministrationScreen();

  // ищем данные в табличном сторе
  const userData = activeUsersTableData.find(({ id }) => String(id) === userIdFromRoute);

  // если в табличном сторе данные есть - формируем и устанавливаем инишиал стейт
  if(!!userData) {
    const {
      name,
      lastName,
      identity,
      patronymicName,
      userRoles,
      note,
    } = userData;

    const initialState = {
      name,
      lastName,
      identity,
      patronymicName: _prepareNullableTextFieldValueForForm(patronymicName),
      userRoles,
      password: EMPTY_PASSWORD_VALUE,
      note: _prepareNullableTextFieldValueForForm(note),
    };

    // сохраняем initialState в ключе editingModeInitialStateToCompare для возможности вычисления изменений
    setInitialValues(initialState);
    return setValues(initialState);
  }

  // если в табличном сторе данных по пользователю нет - выполняем запрос
  fetchUserById(userIdFromRoute)
    .then(response => {

      // если в БД данных по пользователю нет - выполняем редирект на экран списка пользователей
      if(!response || response.responseMeta.count === 0)
        return redirectToUserAdministrationScreen();

      const {
        entities: {
          [USER_MODEL]: userModelEntities = {},
          [USER_ROLE_MODEL]: userRoleModelEntities = {},
          [ROLE_MODEL]: roleModelEntities = {},
        },
      } = response;

      const userEntity = userModelEntities[userIdFromRoute];

      const {
        name,
        lastName,
        identity,
        patronymicName,
        note,
      } = userEntity;

      const userRoleEntitiesArray = Object
        .values(userRoleModelEntities)
        .reduce(
          (acc, { userId, roleId }) => {
            if(String(userId) === userIdFromRoute) {
              const {
                id,
                name,
              } = roleModelEntities[roleId];

              acc.push({ id, name });
              return acc;
            }

            return acc;
          },
          [],
        );

      const initialState = {
        name,
        lastName,
        identity,
        patronymicName: _prepareNullableTextFieldValueForForm(patronymicName),
        userRoles: userRoleEntitiesArray,
        password: EMPTY_PASSWORD_VALUE,
        note: _prepareNullableTextFieldValueForForm(note),
      };

      // сохраняем initialState в ключе editingModeInitialStateToCompare для возможности вычисления изменений
      setInitialValues(initialState);
      return setValues(initialState);
    });
};

const _prepareNullableTextFieldValueForForm = textFieldValue =>
  textFieldValue === null ?
    '' :
    textFieldValue;

const isFormDataChanged = (initialValues, currentValues, isCreatingMode) => {

  // для режима создания сравниваем с начальным состоянием данного режима
  if(isCreatingMode) return !_isEqual(currentValues, USER_CREATING_FORM_INITIAL_STATE);

  // для режима редактирования сравниваем с записанным на маунте компонента исходным состоянием
  return !_isEqual(currentValues, initialValues);
};

const shouldConfirmFn = ({ shouldCheckChangesOnLeaveRef, initialValuesRef, formDataRef, isCreatingMode }) => {

  /*
  * если shouldCheckChangesOnLeave равен false, то это говорит нам о том, что проверку на изменение
  * данные при покидании роута делать не нужно, т.е. конфирмация не нужна.
  * */
  if(!shouldCheckChangesOnLeaveRef.current) return false;

  return isFormDataChanged(initialValuesRef.current, formDataRef.current, isCreatingMode);
};

export const UserEditingScreen = props => {
  const {
    onSubmit,
    onDeactivate,
    isCreatingMode,
    isUserDeactivationPermitted,
    isUserEditingHimself,
    userIdFromRoute,
    fetchUserById,
    activeUsersTableData,
    redirectToUserAdministrationScreen,
    reFetchActiveUsersRemoteTableData,
    clearDeactivatedUsersTableData,
  } = props;

  const [values, setValues] = useState(null);

  /*
  * Свойство для сохранения начального значения формы, сравнение с которым будет использоваться
  * при вычислении необходимости отображения окна конфирма. Казалось бы, мы можем получать состояние из
  * пропсов, но это не так если пользователь переходит по прямой ссылке (нет данных о пользователе в табличном сторе).
  * Тогда логика на маунт компонента запрашивает данные о пользователе, поэтому мы их сразу сохраняем в это поле.
  * Также, для однотипности, в это поле начальные данные сохраняются и при инициализации после перехода из интерфейса
  * таблицы пользователей. Т.е. в этом ключе ВСЕГДА хранится начальное состояние формы после инициализации.
  * */
  const initialValuesRef = useRef(null);
  const setInitialValues = useCallback(
    initialState => initialValuesRef.current = initialState,
    [initialValuesRef],
  );

  const [formDataErrors, setFormDataErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [isPasswordFieldFocused, setIsPasswordFieldFocused] = useState(false);

  /*
  * Флаг, указывающий на необходимость проверки измененились ли данные формы при покидании роута с формой. Требуется
  * для того, чтобы отключать дефолтное поведение, установленное для этого роута, когда в случае ввода данных в форму,
  * при покидании роута выводится конфирм с предостережением о возможной потере введенных несохраненных данных.
  * Отключать это поведение нужно в случае успешного сабмита формы или деактивации пользователя, т.к. в конце обработки
  * этих событий происходит редирект на экран просмотра пользователей, который провоцирует появление конфирма, т.к.
  * данные были изменены, но этот конфирм не нужен логически: В случае сабмита мы подтвердили изменения и они
  * были уже сохранены на сервере, а просто мы не обновляем "начальные данные формы", т.к. незачем, мы всё равно
  * покидаем роут с формой; В случае с деактивацией пользователя используется и другая логика с конфирмом с
  * применением useConfirm, а также в случае успешной деактивации уже не важно изменялись ли данные или нет,
  * пользователь деактивирован, нужно просто произвести редирект
  * */
  const shouldCheckChangesOnLeaveRef = useRef(true);
  const setShouldCheckChangesOnLeave = useCallback(
    shouldCheckChangesOnLeave => shouldCheckChangesOnLeaveRef.current = shouldCheckChangesOnLeave,
    [shouldCheckChangesOnLeaveRef],
  );

  const formDataRef = useRef();

  useEffect(() => {
    formDataRef.current = values;
  });

  /*
  * Инициализируем форму начальными данными на didMount, поэтому зависимости useEffect пустые
  * */
  useEffect(
    () => {
      initializeEditingScreenData(
        isCreatingMode,
        setValues,
        setInitialValues,
        fetchUserById,
        redirectToUserAdministrationScreen,
        userIdFromRoute,
        activeUsersTableData,
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const confirm = useConfirm();

  useConfirmOnLeave(
    shouldConfirmFn,
    {
      shouldCheckChangesOnLeaveRef,
      initialValuesRef,
      formDataRef,
      isCreatingMode,
    },
  );

  const onFormSubmit = useCallback(
    e => {
      e.preventDefault();

      const formData = formDataRef.current;

      if(formData === null) return;

      if(!isFormDataChanged(initialValuesRef.current, formDataRef.current, isCreatingMode)) {

        /*
        * В данном случае уже проверено, что изменений на форме не было, чтобы повторно эта проверка не проводилась
        * в логике конфрмация покидания роута перед редиректом сбрасываем нужный флаг, которые отвечает за то,
        * будет проводится проверка или нет
        * */
        setShouldCheckChangesOnLeave(false);
        return redirectToUserAdministrationScreen();
      }

      /*
      * Перед вызовом колбэка сабмита формы, обрезаем пробелы в начале и в конце строки для значений из текстовых полей
      * */
      const formDataToSubmit = _mapValues(
        formData,
        (value => {

          if (typeof value === 'string') return value.trim();

          return value;
        }),
      );

      return onSubmit(formDataToSubmit)
        .then(({ validationErrors }) => {

          if(_isEmpty(validationErrors)) {

            /*
            * В случае успешного сабмита, производится редирект с роута формы, в этом случае все введенные данные
            * уже сохранены, проверку на изменение данных формы делать не нужно, устанавливаем соответствующий флаг
            * */
            setShouldCheckChangesOnLeave(false);
            return reFetchActiveUsersRemoteTableData()
              .then(redirectToUserAdministrationScreen);
          }

          const formDataErrorsToSave = USER_EDITING_SCREEN_FIELDS_SCHEMA
            .reduce((acc, item) => {
              const {
                key,
              } = item;

              if(validationErrors[key]) {

                /*
                * Сервер в ответ присылает массив ошибок для каждого поля, чтобы иметь возможность описать сразу
                * несколько ошибок для поля. Мы же хотим отображать в данный момент только одну ошибку, т.к. места под
                * отображение ошибок не так много. Поэтому записываем в стейт только первую из ошибок
                * */
                acc[key] = {
                  errorValue: formDataToSubmit[key],
                  errorMsg: validationErrors[key][0].message,
                };
              }

              return acc;
            }, {});

          return setFormDataErrors(formDataErrorsToSave);
        });
    },
    [
      formDataRef,
      initialValuesRef,
      isCreatingMode,
      onSubmit,
      setShouldCheckChangesOnLeave,
      redirectToUserAdministrationScreen,
      reFetchActiveUsersRemoteTableData,
    ],
  );

  /*
  *
  * Инициализация формы создания/редактирования происходит в функции initializeEditingScreenData, которая
  * вызывается в useEffect. До тех пор, пока инициализация не закончена, в values находится начальное значение = null.
  * Здесь выполняется проверка на равенствно null, чтобы не рендерить компонент, пока в локальном стейте
  * нет данных о пользователе
  * */
  if(values === null) return null;

  const createOnChangeHandler = key =>
    value => setValues({
      ...values,
      [key]: value,
    });

  const currentFormDataErrors = _mapValues(
    formDataErrors,
    (errorData, key) => getError(errorData, values, key),
  );

  const onUserDeactivateButtonClick = () =>
    confirm({
      confirmModalTitle: (
        <Trans id="user_editing@confirm_deactivate_title">
          Сделать пользователя неактивным
        </Trans>
      ),
      confirmText: (
        <Trans id="user_editing@confirm_deactivate_message">
          Вы уверены, что хотите сделать пользователя неактивным? Неактивные пользователи не имеют доступа к
          приложению, но история их действий сохраняется
        </Trans>
      ),
    })
      .then(onDeactivate)
      .then(() => Promise.all([
        reFetchActiveUsersRemoteTableData(),
        clearDeactivatedUsersTableData(),
      ]))
      .then(() => {

        /*
        * В случае успешной деактивации, а также после дополнительных запросов данных осуществляется редирект с
        * роута формы. Т.к. пользователь деактивирован, то уже не важно изменялись ли локально его данные на форме,
        * устанавливаем флаг, чтобы проверки при покидании роута уже не выполнялись
        * */
        setShouldCheckChangesOnLeave(false);
        return redirectToUserAdministrationScreen();
      });

  const isSubmitBtnDisabled = Object
    .values(currentFormDataErrors)
    .some(value => !!value);

  const state = {
    values,
    showPassword,
    formDataErrors,
    isPasswordFieldFocused,
    setValues,
    setShowPassword,
    setFormDataErrors,
    setIsPasswordFieldFocused,
  };

  return (
    <div className="user-editing-screen">
      <CardWithCustomHeader
          className="user-editing-screen__card"
          header={
            <span className="user-editing-screen__card-title">
              {
                isCreatingMode ?

                  <Trans id="user_editing@create_user_form_title">
                    Создание нового пользователя
                  </Trans> :

                  <Trans id="user_editing@edit_user_form_title">
                    Редактирование данных пользователя
                  </Trans>
              }
            </span>
          }
          actionsContent={
            <Button
                className="user-editing-screen__return-to-users-list-button"
                onClick={redirectToUserAdministrationScreen}
            >
              <NavigateBefore className="user-editing-screen__return-to-users-list-button-icon" />
              <Trans id="user_editing@return_to_user_list_button">
                Вернуться к списку пользователей
              </Trans>
            </Button>
          }
          content={
            <form onSubmit={onFormSubmit}>
              <CardContent>
                {USER_EDITING_SCREEN_FIELDS_SCHEMA
                  .map(item => {
                    const {
                      key,
                      label,
                      className,
                      getProps = defaultGetProps,
                      component: Component,
                    } = item;

                    const value = values[key];
                    const onChange = createOnChangeHandler(key);

                    const componentProps = getProps(state, props);

                    return (
                      <Component
                          value={value}
                          key={key}
                          className={className}
                          formKey={key}
                          label={label}
                          onChange={onChange}
                          error={currentFormDataErrors[key]}
                          {...componentProps}
                      />
                    );
                  })}
              </CardContent>
              <CardActions className="user-editing-screen__card-actions">
                <Button
                    className="user-editing-screen__submit-button"
                    color={MATERIAL_UI_STYLE_COLOR.PRIMARY}
                    type="submit"
                    disabled={isSubmitBtnDisabled}
                >
                  {
                    isCreatingMode ?
                      CreateLabelTrans :
                      EditLabelTrans
                  }
                </Button>
                {
                  _renderUserDeactivationButton(
                    isCreatingMode,
                    isUserDeactivationPermitted,
                    isUserEditingHimself,
                    onUserDeactivateButtonClick,
                  )
                }
              </CardActions>
            </form>
          }
      />
    </div>
  );
};

const _renderUserDeactivationButton = (
  isCreatingMode,
  isUserDeactivationPermitted,
  isUserEditingHimself,
  onUserDeactivateButtonClick,
) => {

  if(isCreatingMode || !isUserDeactivationPermitted) {
    return null;
  }

  const UserDeactivateButton = (
    <Button
        className="user-editing-screen__deactivate-button"
        color={MATERIAL_UI_STYLE_COLOR.SECONDARY}
        onClick={onUserDeactivateButtonClick}
        disabled={isUserEditingHimself}
    >
      {DeactivateLabelTrans}
    </Button>
  );

  /*
  * Чтобы тултип адекватно работал с задизейбленной кнопкой нужна её любая обертка в child у Tooltip, в данном
  * случае, это span
  * */
  return isUserEditingHimself ?
    <Tooltip
        classes={{
          popper: 'user-editing-screen__deactivate-button-tooltip-popper',
          tooltip: 'user-editing-screen__deactivate-button-tooltip',
        }}
        title={
          <Trans id="user_editing@you_can_not_deactivate_yourself_tooltip">
            Нельзя сделать неактивной свою собственную учетную запись
          </Trans>
        }
    >
      <span>
        {UserDeactivateButton}
      </span>
    </Tooltip> :
    UserDeactivateButton;
};

UserEditingScreen.propTypes = {
  isCreatingMode: PropTypes.bool.isRequired,
  isUserDeactivationPermitted: PropTypes.bool.isRequired,
  isUserEditingHimself: PropTypes.bool.isRequired,
  onSubmit: FUNC_IS_REQUIRED_TYPE,
  onDeactivate: FUNC_IS_REQUIRED_TYPE,
  userIdFromRoute: NUMBER_OR_STRING_TYPE.isRequired,
  fetchUserById: FUNC_IS_REQUIRED_TYPE,
  redirectToUserAdministrationScreen: FUNC_IS_REQUIRED_TYPE,
  reFetchActiveUsersRemoteTableData: FUNC_IS_REQUIRED_TYPE,
  clearDeactivatedUsersTableData: FUNC_IS_REQUIRED_TYPE,
  activeUsersTableData: USERS_TABLE_DATA_TYPE.isRequired,
};
