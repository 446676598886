import { createSelector } from 'reselect';

import _get from 'lodash/get';
import { getUserAppsReviewPermissions } from '../../utils/permissions/index';
import {
  USER_SETTINGS_ID,
} from '../../components/WorkerApp/constants';
import { WORKER_TASKS_TABLE_ADDITIONAL_FILTER_IDENTITY } from '../../components/WorkerApp/TasksViewScreen/constants';


export const appStateSelector = state => state.appState;

export const appUserSelector = state => appStateSelector(state).userData;
export const isAppUserAuthenticatedSelector = state => appUserSelector(state) !== null;
export const appUserIdSelector = state => _get(appUserSelector(state), 'id', null);
export const appUserPermissionsSelector = state => _get(appUserSelector(state), 'permissions', null);
export const isOnlyOneAppReviewPermittedForUserSelector = createSelector(
  appUserPermissionsSelector,
  appUserPermissions => {
    if(appUserPermissions === null) return false;

    return getUserAppsReviewPermissions(appUserPermissions).length === 1;
  },
);

export const mainPlanningSessionSelector = state => appStateSelector(state).mainPlanningSession;
export const mainPlanningSessionIdSelector = state => _get(mainPlanningSessionSelector(state), 'id', null);

export const appLanguageSelector = state => appStateSelector(state).language;

export const appCriticalErrorSelector = state => appStateSelector(state).criticalError;
export const hasAppCriticalErrorSelector = state => appCriticalErrorSelector(state) !== null;

/* userSettings */
export const userSettingsSelector = createSelector(
  appStateSelector,
  appState => appState.userSettings,
);


export const userSettingsByIdSelector = createSelector(
  userSettingsSelector,
  (_, { id }) => id,
  (userSettings, id) => _get(userSettings, [id]),
);


export const tasksAdditionalFiltersSelector = state =>
  userSettingsByIdSelector(state, { id: USER_SETTINGS_ID.WORKER_APP_TASKS_ADDITIONAL_FILTERS } ) ||
  [WORKER_TASKS_TABLE_ADDITIONAL_FILTER_IDENTITY.CURRENT_USER_TASKS];

export const tasksTableFiltersFromUserSettingsSelector = state =>
  userSettingsByIdSelector(state, { id: USER_SETTINGS_ID.WORKER_APP_TASKS_TABLE_FILTERS });

export const workerTasksTableSearchHistoryFromUserSettingsSelector = state =>
  userSettingsByIdSelector(state, { id: USER_SETTINGS_ID.WORKER_APP_SEARCH_HISTORY }) || {};


export const tasksViewScreenAdditionalFiltersSelector = state =>
  userSettingsByIdSelector(state, { id: USER_SETTINGS_ID.WORKER_APP_EQ_CLASS_IN_DEP_TASKS_ADDITIONAL_FILTERS }) || [];

export const workerAppViewModeSelector = state =>
  userSettingsByIdSelector(state, { id: USER_SETTINGS_ID.WORKER_APP_VIEW_MODE });

export const workerAppLastVisitedRouteSelector = state =>
  userSettingsByIdSelector(state, { id: USER_SETTINGS_ID.WORKER_APP_LAST_VISITED_ROUTE });