import {
  OperationLabelTrans,
  OperationNumberLabelTrans,
  SheetOperationProgressLabelTrans,
  SheetOperationStatusLabelTrans,
} from '../../utils/commonTransComponents';
import { SHEET_OPERATION_STATUS_TITLES_TRANS_MSG_MAP } from '../../constants/sheets';
import { renderSheetOperationProgress } from '../common/SheetOperationReviewDialog/constants';

export const SHEET_REVIEW_DIALOG_TOP_PANEL_HEIGHT_IN_PX = 217;

export const CURRENT_SHEET_OPERATION_DATA_SECONDARY_SUMMARY_SCHEMA = [
  {
    key: 'currentSheetOperationNumber',
    label: OperationNumberLabelTrans,
    rowIdentity: 'current-sheet-operation-number',
    getCustomValue: ({ nop }) => {
      if(nop === undefined) return null;

      return nop;
    },
  },
  {
    key: 'combinedOperationName',
    rowIdentity: 'combined-operation-name',
    label: OperationLabelTrans,
    getCustomValue: ({ name, identity }) => {
      if(name === undefined || identity === undefined) return null;

      return `${name} (${identity})`;
    },
  },
  {
    key: 'currentSheetOperationStatus',
    label: SheetOperationStatusLabelTrans,
    rowIdentity: 'current-sheet-operation-status',
    // eslint-disable-next-line react/prop-types
    getCustomValue: ({ status }) => {
      if(status === undefined) return null;

      return SHEET_OPERATION_STATUS_TITLES_TRANS_MSG_MAP[status];
    },
  },
  {
    key: 'currentSheetOperationProgress',
    label: SheetOperationProgressLabelTrans,
    rowIdentity: 'current-sheet-operation-progress',
    getCustomValue: ({ progress, entitiesInBatchAmount }) => {
      if(progress === undefined) return null;

      return renderSheetOperationProgress(entitiesInBatchAmount, progress);
    },
  },
];
