import PropTypes from 'prop-types';
import React from 'react';

import { TableCellRenderer } from '../TableCellRenderer';
import {
  OperationLaborLabelTrans,
  CurrentOperationLaborLabelTrans,
  NoDataLabelTrans,
} from '../../../../utils/commonTransComponents';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { NUMBER_OR_STRING_TYPE } from '../../../../constants/propTypes';
import _isNil from 'lodash/isNil';
import Tooltip from '@mui/material/Tooltip';

import './style.css';
import { calculateLaborValue } from '../../../../utils/tasks';
import { humanizeTimeFromHoursWithoutSeconds } from '../../../../utils/humanizeTimeFromHours/humanizedTimeFromHours';


export const OPERATION_LABOR_RENDERER_FRACTION_DIGITS = 2;

export class OperationLaborRenderer extends TableCellRenderer {

  static getViewValue() {
    /*
    На текущий момент не реализовано решение для формирования строки времени вида "1ч. 2мин. 3с.", потому что для
    названия единиц измерения времени нужно использовать переводы, а они доступны только в компонентах Trans, то есть
    в разметке. При приведении разметки к строке получаем "[object Object]".
    Этот рендерер сейчас используется только для серверных таблиц, для фильтрации которых не нужен метод getViewValue.
    При использовании его не для серверных таблиц нужно это учитывать и отключать фильтрацию для колонки.
    */
    return null;
    /*
    const {
      value,
      data: {
        progress,
      },
    } = props;

    const humanizedInitialLaborValue = humanizeNumber(
      value,
      {
        truncate: OPERATION_LABOR_RENDERER_FRACTION_DIGITS,
      },
    );

    if(value === null) {
      return null;
    }

    if(progress === 0) {
      return humanizedInitialLaborValue;
    }

    const currentLaborValue = calculateLaborValue(value, progress);

    const humanizedCurrentLaborValue = humanizeNumber(
      currentLaborValue,
      {
        truncate: OPERATION_LABOR_RENDERER_FRACTION_DIGITS,
      },
    );

    return [humanizedInitialLaborValue, humanizedCurrentLaborValue].join('-');
    */
  };

  render() {
    const {
      value: initialLaborValue,
      data: {

        /*
        * возможен кейс, когда прогресса для операции нет - это просмотр операций партии на запуск.
        * Для обработки такого случая проставляем здесь дефолтное значение прогресса равное 0
        * */
        progress = 0,
      },
    } = this.props;

    // если значение Тшт. не определено, отображаем лейбл "нет данных"
    if(_isNil(initialLaborValue)) {
      return NoDataLabelTrans;
    }

    const initialHumanizedLaborString = humanizeTimeFromHoursWithoutSeconds(Number(initialLaborValue));

    // если прогресс равен 0, то отображаем начальную трудоемкость
    if(progress === 0) {
      return(
        <div>
          {initialHumanizedLaborString}
        </div>
      );
    }

    const currentLaborValue = calculateLaborValue(initialLaborValue, progress);
    const currentHumanizedLaborString = humanizeTimeFromHoursWithoutSeconds(Number(currentLaborValue));

    return (
      <Tooltip
          title={
            <div className="operation-labor-renderer__tooltip-content">
              <div>
                {OperationLaborLabelTrans}
                {': '}
                {initialHumanizedLaborString}
              </div>
              <div>
                {CurrentOperationLaborLabelTrans}
                {': '}
                {currentHumanizedLaborString}
              </div>
            </div>
          }
          placement="top"
      >
        <div className="operation-labor-renderer">
          {initialHumanizedLaborString}
          <ArrowRightAltIcon/>
          {currentHumanizedLaborString}
        </div>
      </Tooltip>
    );
  }
}

OperationLaborRenderer.propTypes = {
  value: NUMBER_OR_STRING_TYPE,
  data: PropTypes.shape({
    progress: NUMBER_OR_STRING_TYPE,
  }),
};
