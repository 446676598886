import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import './style.css';
import { onlyNumsNormalizer } from '@bfg-frontend/normalizers';
import { FUNC_IS_REQUIRED_TYPE } from '../../../../constants/propTypes';
import {
  getAmountFromPercent,
  getPercentFromAmount,
  TEXT_FIELD_INPUT_MODES,
} from '../../TextFieldWithInputModes/constants';
import { prepareSheetOperationProgressValueToDisplay } from '../SheetOperationStatusChangeDialog/constants';
import { ShortAmountLabelTrans, ShortPercentLabelTrans } from '../../../../utils/commonTransComponents';
import { TextFieldWithInputModes } from '../../TextFieldWithInputModes/TextFieldWithInputModes';
import { useConfirm } from '../../../AppConfirm/AppConfirmContext';
import { Trans } from '@lingui/macro';
import { MATERIAL_UI_DIALOG_MAX_WIDTH } from '../../../../constants/materialUI';


export const SheetOperationProgressInput = props => {

  const {
    wrapperClassName,
    value,
    onChange,
    label,
    autoFocus,
    error,
    entitiesInBatchAmount,
    inputMode,
    onInputModeChange,
  } = props;

  const confirm = useConfirm();

  const inputModeChangeHandler = useCallback(newInputMode => {
    return confirm({
      confirmText: (
        <Trans id="sheet_operation_review.status_change_dialog@change_progress_mode_confirm_text">
          При изменении режима ввода прогресса по операции, текущее значение прогресса будет пересчитано, несохраненные
          данные будут потеряны. Вы уверены?
        </Trans>
      ),
      confirmDialogInnerProps: {
        dialogMaxWidth: MATERIAL_UI_DIALOG_MAX_WIDTH.SM,
      },
    })
      .then(() => onInputModeChange(newInputMode));
  }, [onInputModeChange, confirm]);

  const modesSchema = useMemo(() => ([
    {
      id: TEXT_FIELD_INPUT_MODES.PERCENT,
      value: TEXT_FIELD_INPUT_MODES.PERCENT,
      label: ShortPercentLabelTrans,
      getValueForMode: currentValue => {
        return prepareSheetOperationProgressValueToDisplay(getPercentFromAmount(entitiesInBatchAmount, currentValue));
      },
    },
    {
      id: TEXT_FIELD_INPUT_MODES.AMOUNT,
      value: TEXT_FIELD_INPUT_MODES.AMOUNT,
      label: ShortAmountLabelTrans,
      getValueForMode: currentValue => {
        return prepareSheetOperationProgressValueToDisplay(getAmountFromPercent(entitiesInBatchAmount, currentValue));
      },
    },
  ]), [entitiesInBatchAmount]);

  return (
    <TextFieldWithInputModes
        wrapperClassName={wrapperClassName}
        autoFocus={autoFocus}
        value={value}
        onChange={onChange}
        label={label}
        onInputModeChange={inputModeChangeHandler}
        inputMode={inputMode}
        error={error}
        modesSchema={modesSchema}
        inputValueNormalizer={onlyNumsNormalizer}
    />
  );
};

SheetOperationProgressInput.propTypes = {
  wrapperClassName: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: FUNC_IS_REQUIRED_TYPE,
  label: PropTypes.node.isRequired,
  autoFocus: PropTypes.bool,
  error: PropTypes.node,
  entitiesInBatchAmount: PropTypes.number.isRequired,
  inputMode: PropTypes.oneOf(Object.values(TEXT_FIELD_INPUT_MODES)).isRequired,
  onInputModeChange: FUNC_IS_REQUIRED_TYPE,
};

SheetOperationProgressInput.defaultProps = {
  autoFocus: false,
};