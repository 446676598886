import React, { useCallback, useMemo, useState } from 'react';
import {
  SimpleTableWithDraggableRows,
} from '../../../common/SimpleTableWithDraggableRows/SimpleTableWithDraggableRows';
import { WorkerTasksSettingsColumnsOrderTableTitleTrans } from '../../../../utils/commonTransComponents';
import { orderFieldComparator } from '../../../../constants/comparators';
import { PERMISSION, PERMISSIONS_MANAGER_TYPE } from '../../../../hoc/withPermissionsManager/constants';
import { FUNC_IS_REQUIRED_TYPE, OBJECT_OF_ANY_TYPE } from '../../../../constants/propTypes';
import { getWorkerTasksDraggableRowSchema, getWorkerTasksTableSettingsDraggableRowClassNames } from '../constants';
import { useConfirmOnLeave } from '../../../../hoc/confirmOnLeave/confirmOnLeave';
import _mapValues from 'lodash/mapValues';

export const TasksTableSettings = props => {

  const {
    initialSettingsData,
    PermissionsManager,
    onSettingsSave,
  } = props;

  const [tasksTableColumnsData, setTasksTableColumnsData] = useState(
    () =>
      _mapValues(
        initialSettingsData,
        columnData => ({ ...columnData }),
      ),
  );

  const columnsSortedByOrderArr = Object
    .values(tasksTableColumnsData)
    .sort(orderFieldComparator);


  let currentVisibleIndexToShow = 1;

  const columnsSortedByOrderArrWithVisibleIndexes = columnsSortedByOrderArr
    .map(item => {

      if(!item.show) {
        return { ...item, visibleIndexToShow: 0 };
      }

      return {
        ...item,
        visibleIndexToShow: currentVisibleIndexToShow ++,
      };
    });

  const isWorkerTasksTableSettingsPermissionDenied = useMemo(() =>
      PermissionsManager.isDenied(PERMISSION.MANAGE_WORKER_TASKS_TABLE_SETTINGS)
  , [PermissionsManager]);

  const isSettingsDataChanged = Object
    .keys(initialSettingsData)
    .some(columnKey => {
      const {
        order: initialOrder,
        show: initialShow,
      } = initialSettingsData[columnKey];

      const {
        order,
        show,
      } = tasksTableColumnsData[columnKey];

      return order !== initialOrder || show !== initialShow;
    });

  const onColumnVisibilityChange = useCallback((e, columnId) => {
    e.stopPropagation();

    const { checked } = e.target;

    /*
    * Если пытаемся сделать невидимой последнюю из видимых колонок, то ничего не делаем, должна быть хотя бы одна
    * видимая колонка
    * */
    if(
      !checked &&
      Object.values(tasksTableColumnsData).filter(({ show }) => show).length === 1
    ) return;

    const newTasksTableColumnsSettings = {
      ...tasksTableColumnsData,
      [columnId]: {
        ...tasksTableColumnsData[columnId],
        show: checked,
      },
    };

    setTasksTableColumnsData(newTasksTableColumnsSettings);

  }, [tasksTableColumnsData, setTasksTableColumnsData]);

  const onSave = useCallback(() => {
    onSettingsSave(tasksTableColumnsData);
  }, [tasksTableColumnsData, onSettingsSave]);


  const rowItemsSchema = useMemo(
    () => getWorkerTasksDraggableRowSchema(onColumnVisibilityChange, isWorkerTasksTableSettingsPermissionDenied),
    [onColumnVisibilityChange, isWorkerTasksTableSettingsPermissionDenied],
  );


  useConfirmOnLeave(
    ({ isSettingsDataChanged }) => isSettingsDataChanged,
    { isSettingsDataChanged },
  );

  return (
    <SimpleTableWithDraggableRows
        draggableItemsData={columnsSortedByOrderArrWithVisibleIndexes}
        getItemId={({ columnName }) => columnName}
        onDragEnd={reorderedTasksTableColumns => setTasksTableColumnsData(reorderedTasksTableColumns)}
        isDragDisabled={isWorkerTasksTableSettingsPermissionDenied}
        getDraggableRowClassNames={getWorkerTasksTableSettingsDraggableRowClassNames}
        rowItemsSchema={rowItemsSchema}
        onSave={onSave}
        onCancel={() => setTasksTableColumnsData(initialSettingsData)}
        isSaveBtnDisabled={!isSettingsDataChanged}
        isCancelBtnDisabled={!isSettingsDataChanged}
        tableTitle={WorkerTasksSettingsColumnsOrderTableTitleTrans}
    />
  );
};

TasksTableSettings.propTypes = {
  initialSettingsData: OBJECT_OF_ANY_TYPE,
  PermissionsManager: PERMISSIONS_MANAGER_TYPE,
  onSettingsSave: FUNC_IS_REQUIRED_TYPE,
};