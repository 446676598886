import { AutocompleteContainer } from '../../common/Autocomplete/AutocompleteContainer';
import { NoDataLabelTrans, NotSelectedLabelTrans } from '../../../utils/commonTransComponents';
import { createCaEntitiesAutocompleteLoadOptionsActionCreator } from '../../../reducers/autocomplete/actions';
import {
  ORDER_MODEL,
} from '../../../constants/models';
import { FILTER_GROUP_TYPES, FILTER_TYPES } from '../../../api/restCollectionApi';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { NAME_FIELD_DEFAULT_SORT_BY } from '../../../constants/entities';
import { SERVER_ORDER_STATUS } from '../../../constants/orders';
import _size from 'lodash/size';


export const getOrdersAutocompleteOptionLabel = ({ name }) => name;

const _getOrdersAutocompleteLoadOptionsActionCreator = (serverOrderStatuses, prepareOptions) =>
  createCaEntitiesAutocompleteLoadOptionsActionCreator({
    requestModel: ORDER_MODEL,
    prepareOptions,
    getRequestQuery: inputValue => {

      let filters = [];

      if(_size(serverOrderStatuses) > 0) {
        filters.push({
          column: 'status',
          filterType: serverOrderStatuses.length === 1 ?
            FILTER_TYPES.EQUALS :
            FILTER_TYPES.ONE_OF,
          filterValue: serverOrderStatuses.length === 1 ?
            serverOrderStatuses[0] :
            serverOrderStatuses,
        });
      }

      if (!inputValue) {

        let query = {
          sortBy: NAME_FIELD_DEFAULT_SORT_BY,
        };

        if(_size(filters) > 0) {
          query.filter = {
            filterGroupType: FILTER_GROUP_TYPES.AND,
            filters,
          };
        }

        return query;
      }

      return {
        filter: {
          filterGroupType: FILTER_GROUP_TYPES.AND,
          filters: [
            ...filters,
            {
              column: 'name',
              filterType: FILTER_TYPES.CONTAINS,
              filterValue: inputValue,
            },
          ],
        },
        sortBy: NAME_FIELD_DEFAULT_SORT_BY,
      };
    },
  });

export const OrdersAutocomplete = props => {

  const {
    shouldLoadOptions,
    prepareOptions,
    serverOrderStatuses,
  } = props;

  const loadOptionsActionCreator = useMemo(
    () => {
      if(!shouldLoadOptions) {
        return undefined;
      }

      return _getOrdersAutocompleteLoadOptionsActionCreator(serverOrderStatuses, prepareOptions);
    },
    [shouldLoadOptions, prepareOptions, serverOrderStatuses],
  );

  return (
    (
      <AutocompleteContainer
          {...props}
          getOptionLabel={getOrdersAutocompleteOptionLabel}
          loadOptionsActionCreator={loadOptionsActionCreator}
          placeholder={NotSelectedLabelTrans}
          noOptionsMessage={NoDataLabelTrans}
      />
    )
  );
};

OrdersAutocomplete.propTypes = {
  shouldLoadOptions: PropTypes.bool,
  prepareOptions: PropTypes.func,
  serverOrderStatuses: PropTypes.arrayOf(
    PropTypes.oneOf(Object.values(SERVER_ORDER_STATUS)),
  ),
};