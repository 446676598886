import { connect } from 'react-redux';
import { MasterTasksToDo } from './MasterTasksToDo';
import { masterTasksToDoTableDataSelector } from '../../../selectors/masterWorkspace';
import { fetchMasterDepartmentsTasksToDoRemoteTableData } from '../../../operations/masterWorkspace/index';
import { clearTableData, clearTableRemoteData, reFetchRemoteTableData } from '../../../reducers/table/actions';
import { createOrderEntriesTableId, createSheetTypeOperationsTableId } from '../../../utils/tables';
import { SHEET_TYPE } from '../../../constants/sheets';
import { deleteEntitiesFromStore } from '../../../reducers/entities/actions';
import { SHEET_MODEL } from '../../../constants/models';
import {
  clearAllDefaultSheetsPartsAndMaterialsToConsume,
  clearDefaultSheetPartsAndMaterialsToConsume,
} from '../../../reducers/storageManagementApp/defaultSheets/actions';
import { MASTER_COMPLETED_TASKS_TABLE_ID } from '../MasterCompletedTasks/constants';
import { allTasksTablesIdsSelector } from '../../../selectors/taskView';
import { MASTER_TASKS_TO_DO_TABLE_ID } from './constants';
import { sendEntityBatchSplitNotification, sendSheetPausedNotification } from '../../../operations/sheets';
import {
  masterTasksToDoAdditionalFiltersSelector,
} from '../../../reducers/masterApp/tasksToDoAdditionalFilters/selectors';
import { setMasterTasksToDoAdditionalFilters } from '../../../reducers/masterApp/tasksToDoAdditionalFilters/actions';
import { MASTER_APP_PAUSED_SHEETS_TABLE_ID, PAUSED_SHEETS_TABLES_IDS_ARRAY } from '../../../constants/table';
import { ORDER_IN_PRODUCTION_AND_READY_TO_COMPLETE_TABLES_IDS, ORDER_TYPE } from '../../../constants/orders';
import { sendOrderIsReadyToCompleteNotification } from '../../../operations/orders';
import { deleteAssemblySheetConsumeData } from '../../../reducers/workerApp/assemblySheets/consumeData/actions';
import {
  deleteAllAssemblySheetsReserveData,
  deleteAssemblySheetReserveData,
} from '../../../reducers/storageManagementApp/assemblySheets/reserveData/actions';
import { DEFECT_MARKING_USE_CASES, getDefectMarkingUseCase } from '../../../utils/sheets';
import { TASKS_MODEL } from '../../../reducers/schemaModel/models/tasksSchema';
import { MASTER_TASKS_TO_DO_TABLE_MODEL } from '../../../reducers/schemaModel/models/masterTasksSchema';
import {
  getOrderCompletedRelatedActions,
} from '../../../api/socketApi/socketMessageHandlers/caClientMessageHandler/sheetFinish';
import {
  getEntityBatchSplitCaseSpecificActions,
  getSplitEntityBatchCaseData,
} from '../../../api/socketApi/socketMessageHandlers/caClientMessageHandler/entityBatchSplit';


const mapStateToProps = state => ({
  masterTasksToDoAdditionalFilters: masterTasksToDoAdditionalFiltersSelector(state),
  masterTasksToDoTableData: masterTasksToDoTableDataSelector(state),
  allTasksTablesIds: allTasksTablesIdsSelector(state),
});

const mapDispatchToProps = dispatch => ({

  fetchMasterTasksToDoRemoteTableData: (departmentIds, additionalFilters, tableParams) =>
    dispatch(fetchMasterDepartmentsTasksToDoRemoteTableData(departmentIds, additionalFilters, tableParams)),

  reFetchRemoteTableData: (tableId, tableModel, fetchRemoteTableData) =>
    dispatch(reFetchRemoteTableData(tableId, tableModel, fetchRemoteTableData)),

  clearMasterAppDepartmentsRelatedRemoteTablesData: () =>
    dispatch(clearTableRemoteData([MASTER_COMPLETED_TASKS_TABLE_ID, MASTER_APP_PAUSED_SHEETS_TABLE_ID])),

  setMasterTasksToDoAdditionalFilters: additionalFilters =>
    dispatch(setMasterTasksToDoAdditionalFilters(additionalFilters)),


  /*
 * Подробное описание о влиянии события завершения МЛ на разделы приложения представлено в комментарии к
 * handleSheetFinished в SheetInProductionReviewContentContainer. Выполняем здесь аналогичные очистки, но, т.к.
 * в этом случае завершаем МЛ из интерфейса просмотра заданий Мастера, которые "нужно сделать", то текущую таблицу
 * заданий мастера MASTER_TASKS_TO_DO_TABLE_ID очищать не нужно, для неё сразу перезапрашиваются данные
 * (reFetchMasterTasksToDoRemoteTableData)
 */
  handleSheetFinished: (sheetId, allTasksTablesIds, reFetchMasterTasksToDoRemoteTableData, orderId, orderName, isOrderCompleted) => {

    reFetchMasterTasksToDoRemoteTableData();

    if (isOrderCompleted) sendOrderIsReadyToCompleteNotification(orderName);

    const orderCompletedActionsToDispatch = isOrderCompleted ?
      ([
        clearTableRemoteData(ORDER_IN_PRODUCTION_AND_READY_TO_COMPLETE_TABLES_IDS),
        clearTableData(createOrderEntriesTableId(ORDER_TYPE.IN_PRODUCTION, orderId)),
      ]) :
      [];

    dispatch([
      clearTableData([
        createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId),
      ]),
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        SHEET_TYPE.COMPLETED,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        MASTER_COMPLETED_TASKS_TABLE_ID,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),
      deleteAllAssemblySheetsReserveData(),
      deleteAssemblySheetConsumeData({ sheetId }),
      deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
      clearAllDefaultSheetsPartsAndMaterialsToConsume(),
      ...orderCompletedActionsToDispatch,
    ]);
  },

  /*
  * Подробное описание события изменение статуса операции МЛ (т.е. задания для приложения "Мастер") представлено в
  * комментарии handleSheetOperationStatusChanged в SheetInProductionReviewContentContainer. Здесь выполняются
  * аналогичные обработки, только для случая, когда статус операций изменяют из раздела просмотра заданий в приложении
  * "Мастер". Нужно, аналогично, очистить все таблицы заданий кроме текущей таблицы "Мастера", т.е. таблицу
  * "завершенных заданий" в "Мастере" и все таблицы заданий во всех разделах приложения "Рабочий", а, также, очистить
  * данные таблицы просмотра операций МЛ в приложении "Плановик", чтобы при следующем входе в эти разделы
  * обновленные данные были запрошены заново. Для текущей таблицы данные не очищаем, т.к. для неё сразу выполняется
  * перезапрос данных - reFetchMasterTasksToDoRemoteTableData, а, если сначала очистить, то интерфейс будет "дергаться".
  * Для событий начала, приостановки и возобновления выполнения задания, теоретически, можно было находить операцию
  * в табличном стор и обновлять данные без перезапроса, но решено сделать обработку проще, по аналогии с событием
  * завершения задания, когда задание удаляется из таблицы и в этом случае для серверной таблицы обязательно нужен
  * перезапрос данных, т.к. происходит смещение. Кроме того, перезапрос при любом изменение статуса задания гарантирует
  * синхронность работы с сервером, который изменяет статусы операций экшн точкой, т.е. мы перезапрашиваем данные,
  * чтобы получить от сервера результат его действий после выполнения запроса на экшн точку и точно уверены, что
  * отображаем то же самое, что у нас в БД.
  * */
  handleTaskStatusChanged: (sheetId, allTasksTablesIds, reFetchMasterTasksToDoRemoteTableData) => {

    reFetchMasterTasksToDoRemoteTableData();

    dispatch(clearTableRemoteData([
      createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId),
      MASTER_COMPLETED_TASKS_TABLE_ID,
      TASKS_MODEL,
      ...allTasksTablesIds,
    ]));
  },

  /*
  * Подробное описание о влиянии события изменения данных операции МЛ представлено в комментарии к
  * handleSheetOperationDataChanged в SheetInProductionReviewContentContainer. Здесь всё аналогично, очищаем все
  * таблицы заданий кроме таблиц "Мастера", т.е. все таблицы заданий во всех разделах приложении "Рабочий", а, также,
  * данные таблицы просмотра операций МЛ в приложении "Плановик", чтобы при следующем входе в эти разделы обновленные
  * данные были запрошены заново. Таблицу "завершенных заданий" в "Мастере" не очищаем, т.к. событие изменения данных
  * на неё не влияют, а текущую таблицу "заданий, которые нужно сделать" MASTER_TASKS_TO_DO_TABLE_ID очищать не нужно,
  * т.к. для неё сразу перезапрашиваются данные (reFetchMasterTasksToDoRemoteTableData). Подробное пояснение почему эти
  * данные просто перезапрашиваются тоже есть в упоминаемом выше комментарии.
  */
  handleSheetOperationDataChanged: (sheetId, allTasksTablesIds, reFetchMasterTasksToDoRemoteTableData) => {
    reFetchMasterTasksToDoRemoteTableData();

    dispatch(clearTableRemoteData([
      createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId),
      TASKS_MODEL,
      ...allTasksTablesIds,
    ]));
  },

  /*
  * Подробное описание о влиянии события приостановки МЛ на разделы приложения представлено в комментарии к
  * handleSheetPaused в SheetInProductionReviewContentContainer. Выполняем здесь аналогичные очистки, но, т.к.
  * в этом случае приостанавливаем МЛ из интерфейса просмотра заданий Мастера, которые "нужно сделать", то текущую
  * таблицу заданий мастера MASTER_TASKS_TO_DO_TABLE_ID очищать не нужно, для неё сразу перезапрашиваются данные
  * (reFetchMasterTasksToDoRemoteTableData)
  */
  handleSheetPaused: (sheetId, sheetIdentity, allTasksTablesIds, reFetchMasterTasksToDoRemoteTableData) => {

    reFetchMasterTasksToDoRemoteTableData();

    //Выводим нотификейшен о приостановке МЛ
    sendSheetPausedNotification(sheetIdentity);

    dispatch([
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId)),
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        ...PAUSED_SHEETS_TABLES_IDS_ARRAY,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),
      deleteAssemblySheetConsumeData({ sheetId }),
      deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
      clearDefaultSheetPartsAndMaterialsToConsume(sheetId),
    ]);
  },

  /*
  * Подробное описание о влиянии события деления партии на разделы приложения представлено в комментарии к
  * handleEntityBatchSplit в SheetInProductionReviewContentContainer. Выполняем здесь аналогичные очистки и запросы,
  * но, т.к. в этом случае деление партии выполняется из интерфейса просмотра заданий Мастера, которые "нужно сделать",
  * то текущую таблицу заданий мастера MASTER_TASKS_TO_DO_TABLE_ID очищать не нужно, для неё сразу перезапрашиваются
  * данные (reFetchMasterTasksToDoRemoteTableData)
  * */
  handleEntityBatchSplit: ({
    parentSheetId,
    isParentEntityBatchWasFinished,
    isChildEntityBatchWasFinished,
    changedOrderId,
    sheetIdentity,
    allTasksTablesIds,
    reFetchMasterTasksToDoRemoteTableData,
  }) => {

    reFetchMasterTasksToDoRemoteTableData();

    // выводим нотификейшн о наступлении события разделения партии
    sendEntityBatchSplitNotification(sheetIdentity);

    const {
      isOneOfBatchesFinished,
      isOrderFinished,
    } = getSplitEntityBatchCaseData({
      isParentEntityBatchWasFinished,
      isChildEntityBatchWasFinished,
      changedOrderId,
    });


    const sheetOperationsTableId = createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, parentSheetId);

    const tableIdsToClear = [
      sheetOperationsTableId,
      SHEET_TYPE.IN_PRODUCTION,
      MASTER_COMPLETED_TASKS_TABLE_ID,
      SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      TASKS_MODEL,
      ...allTasksTablesIds,
    ];

    if(isOneOfBatchesFinished) {
      tableIdsToClear.push(SHEET_TYPE.COMPLETED);
    }

    const actionsToDispatch = [
      clearTableRemoteData(tableIdsToClear),

      ...getEntityBatchSplitCaseSpecificActions({
        isOneOfBatchesFinished,
        parentSheetId,
      }),

      ...getOrderCompletedRelatedActions(isOrderFinished, changedOrderId),
    ];


    dispatch(actionsToDispatch);
  },


  /*
  * Подробное описание о влиянии события фиксации брака на разделы приложения представлено в комментарии к
  * handleDefectiveEntitiesMarked в SheetInProductionReviewContentContainer.
  * Выполняем здесь аналогичные очистки и запросы, но, т.к. в этом случае фиксация брака выполняется из интерфейса 
  * "Мастер: задания для выполнения", то текущую таблицу заданий очищать не нужно, для неё сразу перезапрашиваются 
  * данные в reFetchMasterTasksToDoRemoteTableData
  */
  handleDefectiveEntitiesMarked: (
    data,
    allTasksTablesIds, 
    reFetchMasterTasksToDoRemoteTableData,
  ) => {

    const {
      sheetOperationStatus,
      sheetId,
      completedOrderId,
      completedOrderName,
      wasEntityBatchSplit,
      wasEntityBatchFinished,
    } = data;

    const defectMarkingUseCase = getDefectMarkingUseCase(
      sheetOperationStatus,
      wasEntityBatchSplit,
      wasEntityBatchFinished,
    );

    /*
    * Список экшенов для кейсов, для которых нужна кастомная, а не универсальная обработка
    * */
    let customActionsToDispatch = [];

    if(
      defectMarkingUseCase === DEFECT_MARKING_USE_CASES.PART_OF_ENTITY_BATCH_ON_LAST_OPERATION_FINISH &&
      completedOrderId !== null
    ) {
      //Выводим нотификейшен в случае, когда завершение части МЛ означает также и готовность заказа
      sendOrderIsReadyToCompleteNotification(completedOrderName);

      customActionsToDispatch.push(
        clearTableRemoteData(ORDER_IN_PRODUCTION_AND_READY_TO_COMPLETE_TABLES_IDS),
        clearTableData(createOrderEntriesTableId(ORDER_TYPE.IN_PRODUCTION, completedOrderId)),
      );
    }

    if(
      defectMarkingUseCase === DEFECT_MARKING_USE_CASES.WHOLE_ENTITY_BATCH_ON_OPERATION_FINISH ||
      defectMarkingUseCase === DEFECT_MARKING_USE_CASES.WHOLE_ENTITY_BATCH_ON_OPERATION_PAUSE ||
      defectMarkingUseCase === DEFECT_MARKING_USE_CASES.PART_OF_ENTITY_BATCH_ON_LAST_OPERATION_FINISH
    ) {
      customActionsToDispatch.push(
        deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
      );
    }

    if(defectMarkingUseCase === DEFECT_MARKING_USE_CASES.PART_OF_ENTITY_BATCH_ON_LAST_OPERATION_FINISH) {
      customActionsToDispatch.push(
        clearAllDefaultSheetsPartsAndMaterialsToConsume(),
        deleteAllAssemblySheetsReserveData(),
        clearTableRemoteData([
          SHEET_TYPE.COMPLETED,
        ]),
      );
    }else{
      customActionsToDispatch.push(
        clearDefaultSheetPartsAndMaterialsToConsume(sheetId),
        deleteAssemblySheetReserveData({ sheetId }),
      );
    }

    /*
    * Универсальные обработки для всех кейсов, к которым добавляются кастомные customActionsToDispatch, вычисленные
    * в зависимости от текущего кейса
    * */
    reFetchMasterTasksToDoRemoteTableData();

    dispatch([
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId)),

      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        SHEET_TYPE.INAPPROPRIATE,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        MASTER_COMPLETED_TASKS_TABLE_ID,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),

      deleteAssemblySheetConsumeData({ sheetId }),

      ...customActionsToDispatch,
    ]);
  },

});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {
    masterTasksToDoAdditionalFilters,
    masterTasksToDoTableData,
    allTasksTablesIds,
  } = stateProps;

  const {
    fetchMasterTasksToDoRemoteTableData: fetchMasterTasksToDoRemoteTableDataFromDispatchProps,
    reFetchRemoteTableData: reFetchRemoteTableDataFromDispatchProps,
    clearMasterAppDepartmentsRelatedRemoteTablesData,
    setMasterTasksToDoAdditionalFilters: setMasterTasksToDoAdditionalFiltersFromDispatchProps,
    handleSheetFinished: handleSheetFinishedFromDispatchProps,
    handleTaskStatusChanged: handleTaskStatusChangedFromDispatchProps,
    handleSheetOperationDataChanged: handleSheetOperationDataChangedFromDispatchProps,
    handleSheetPaused: handleSheetPausedFromDispatchProps,
    handleEntityBatchSplit: handleEntityBatchSplitFromDispatchProps,
    handleDefectiveEntitiesMarked: handleDefectiveEntitiesMarkedFromDispatchProps,
  } = dispatchProps;

  const {
    departmentIds,
  } = ownProps;

  const fetchMasterTasksToDoRemoteTableData = ({ tableParams }) =>
      fetchMasterTasksToDoRemoteTableDataFromDispatchProps(departmentIds, masterTasksToDoAdditionalFilters, tableParams);

  const reFetchMasterTasksToDoRemoteTableData = () =>
    reFetchRemoteTableDataFromDispatchProps(
      MASTER_TASKS_TO_DO_TABLE_ID,
      MASTER_TASKS_TO_DO_TABLE_MODEL,
      fetchMasterTasksToDoRemoteTableData,
    );

  const setMasterTasksToDoAdditionalFilters = newAdditionalFilters => {
    reFetchRemoteTableDataFromDispatchProps(
      MASTER_TASKS_TO_DO_TABLE_ID,
      MASTER_TASKS_TO_DO_TABLE_MODEL,
      ({ tableParams }) =>
        fetchMasterTasksToDoRemoteTableDataFromDispatchProps(departmentIds, newAdditionalFilters, tableParams),
    )
      .then(() => setMasterTasksToDoAdditionalFiltersFromDispatchProps(newAdditionalFilters));
  };

  return{
    departmentIds,
    masterTasksToDoAdditionalFilters,
    masterTasksToDoTableData,
    fetchMasterTasksToDoRemoteTableData,
    reFetchMasterTasksToDoRemoteTableData,
    clearMasterAppDepartmentsRelatedRemoteTablesData,
    setMasterTasksToDoAdditionalFilters,

    handleSheetFinished: ({ sheetId, orderId, orderName }, isOrderCompleted) =>
      handleSheetFinishedFromDispatchProps(
        sheetId,
        allTasksTablesIds,
        reFetchMasterTasksToDoRemoteTableData,
        orderId,
        orderName,
        isOrderCompleted,
      ),

    handleTaskStatusChanged: ({ sheetId }) =>
      handleTaskStatusChangedFromDispatchProps(
        sheetId,
        allTasksTablesIds,
        reFetchMasterTasksToDoRemoteTableData,
      ),

    handleSheetOperationDataChanged: ({ sheetId }) =>
      handleSheetOperationDataChangedFromDispatchProps(
        sheetId,
        allTasksTablesIds,
        reFetchMasterTasksToDoRemoteTableData,
      ),

    handleSheetPaused: ({ sheetId, sheetIdentity }) =>
      handleSheetPausedFromDispatchProps(
        sheetId,
        sheetIdentity,
        allTasksTablesIds,
        reFetchMasterTasksToDoRemoteTableData,
      ),

    handleEntityBatchSplit: ({
      parentSheetId,
      isParentEntityBatchWasFinished,
      isChildEntityBatchWasFinished,
      changedOrderId,
      sheetIdentity,
    }) =>
      handleEntityBatchSplitFromDispatchProps({
        parentSheetId,
        isParentEntityBatchWasFinished,
        isChildEntityBatchWasFinished,
        changedOrderId,
        sheetIdentity,
        allTasksTablesIds,
        reFetchMasterTasksToDoRemoteTableData,
      }),

    handleDefectiveEntitiesMarked: data =>
      handleDefectiveEntitiesMarkedFromDispatchProps(
        data,
        allTasksTablesIds,
        reFetchMasterTasksToDoRemoteTableData,
      ),
  };
};

export const MasterTasksToDoContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(MasterTasksToDo);

MasterTasksToDoContainer.displayName = 'MasterTasksToDoContainer';