import React from 'react';
import PropTypes from 'prop-types';
import { AppTypeTopBarContainer } from '../AppTypeTopBar/AppTypeTopBarContainer';
import { QualityControlLabelTrans, SheetsLabelTrans } from '../../utils/commonTransComponents';
import { CardWithNavLinksHeader } from '../common/CardWithNavLinksHeader/CardWithNavLinksHeader';
import { QUALITY_CONTROL_SHEETS_TABS } from './constants';
import './style.css';
import { Redirect } from 'react-router-dom';
import {
  QUALITY_CONTROL_INAPPROPRIATE_SHEETS_ROUTE,
} from '../../constants/routes';

export const QualityControlApp = props => {

  const {
    match: {
      isExact,
    },
  } = props;

  if (isExact) return <Redirect to={QUALITY_CONTROL_INAPPROPRIATE_SHEETS_ROUTE}/>;

  return (
    <div className="quality-control-app">
      <AppTypeTopBarContainer
          className="quality-app__main-app-bar"
          appTitle={QualityControlLabelTrans}
      />

      <div className="quality-control-app__content app-container">
        <CardWithNavLinksHeader
            className="quality-control-app__sheets-card"
            title={SheetsLabelTrans}
            navTabs={QUALITY_CONTROL_SHEETS_TABS}
        />
      </div>

    </div>
  );

};

QualityControlApp.propTypes = {
  match: PropTypes.shape({
    isExact: PropTypes.bool.isRequired,
  }),
};