import React from 'react';
import { Trans } from '@lingui/macro';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';
import { connect } from 'react-redux';
import { FILTER_TYPES } from '../../../../api/restCollectionApi';
import { NOTIFICATION_LEVEL, sendNotification } from '../../../../constants/notification';

import { mainPlanningSessionIdSelector } from '../../../../reducers/appState/selectors';

import {
  fetchSheetsToStart,
  removeSheetsToStart,
  startSheet,
} from '../../../../reducers/plannerApp/sheetsToStart/actions';
import {
  sheetsToStartDataSelector,
  sheetsToStartEntitiesDataSelector,
  sheetsToStartFiltersSchemaSelector,
  sheetsToStartStateSheetsDataSelector,
} from '../../../../reducers/plannerApp/sheetsToStart/selectors';
import Filters from '../../../tableFactory/Filters';

import { AllSheetsToStart } from './AllSheetsToStart';
import {
  BATCHES_SIZES_FOR_REQUIRED_AMOUNT_STATUSES,
  filterSheetsForRequiredAmount,
  getDefaultSheetToStartIdentity,
  getSheetsByFilteredAmount,
} from '../../../../utils/sheets';
import {
  clearTableRemoteData,
  removeSelectedTableRows,
  resetSelectedTableRows,
  setTableParams,
} from '../../../../reducers/table/actions';
import { SHEET_TYPE } from '../../../../constants/sheets';
import { ORDER_IN_PRODUCTION_AND_READY_TO_COMPLETE_TABLES_IDS } from '../../../../constants/orders';
import { MASTER_TASKS_TO_DO_TABLE_ID } from '../../../MasterApp/MasterTasksToDo/constants';
import { TASKS_MODEL } from '../../../../reducers/schemaModel/models/tasksSchema';
import {
  clearAllDepartmentsWithTasks,
  clearAllEquipmentClassesInDepartmentWithTask,
} from '../../../../reducers/workerApp/tasksOwners/actions';
import { bindActionCreators } from 'redux';
import { allTasksTablesIdsSelector } from '../../../../selectors/taskView';
import { broadcastEventMessage } from '../../../../api/socketApi/broadcastApi/broadcast/broadcastEventMessage';
import { SHEETS_STARTED_EVENT_TYPE } from '../../../../constants/sockets';
import { broadcastGroupSheetsProcessAndAddToStore } from './constants';
import { isGroupSheetsStartInProgressSelector } from '../../../../reducers/plannerApp/groupSheetsStart/selectors';
import { tableColumnsFilterParamsSelector } from '../../../../reducers/table/selectors';


const mapStateToProps = state => {

  /*
Пока нет реализации со стороны бэкенда используется только флаг идёт/не идёт в данный момент групповой запуск МЛ
Индикация прогресса пока обрабатывается в броадкастинге.
*/
  const isGroupSheetsStartInProgress = isGroupSheetsStartInProgressSelector(state);


  return {
    mainPlanningSessionId: mainPlanningSessionIdSelector(state),
    sheetsToStartData: sheetsToStartDataSelector(state),
    sheetsToStartById: sheetsToStartEntitiesDataSelector(state),
    filtersSchema: sheetsToStartFiltersSchemaSelector(state),
    tableFilterParams: tableColumnsFilterParamsSelector(state, { tableId: SHEET_TYPE.ALL_TO_START }),
    currentSheetToStartData: sheetsToStartStateSheetsDataSelector(state),
    allTasksTablesIds: allTasksTablesIdsSelector(state),
    isGroupSheetsStartInProgress,
    filterParams: tableColumnsFilterParamsSelector(state, { tableId: SHEET_TYPE.ALL_TO_START }),
  };
};

const mapDispatchToProps = dispatch => (
  {
    ...bindActionCreators({
      fetchSheetsToStart,
      startSheet,
      resetSelectedTableRows,
      broadcastGroupSheetsProcessAndAddToStore,
      setFilterParams: filterParams => setTableParams(SHEET_TYPE.ALL_TO_START, { filterParams }),
    },
      dispatch),
    /* подробное описание обработки события запуска МЛ см. в SheetToStartReviewContentContainer */
    handleGroupSheetsStarted: (entityBatchFromIaIds, allTasksTablesIds) => {

      return dispatch([
        clearTableRemoteData([
          SHEET_TYPE.IN_PRODUCTION,
          ...ORDER_IN_PRODUCTION_AND_READY_TO_COMPLETE_TABLES_IDS,
          MASTER_TASKS_TO_DO_TABLE_ID,
          SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
          TASKS_MODEL,
          ...allTasksTablesIds,
        ]),

        //1.2
        removeSheetsToStart(entityBatchFromIaIds),
        removeSelectedTableRows(SHEET_TYPE.ALL_TO_START, entityBatchFromIaIds),

        //4.1
        clearAllDepartmentsWithTasks(),

        //4.2
        clearAllEquipmentClassesInDepartmentWithTask(),
      ]);
    },
  }
);

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {
    mainPlanningSessionId,
    sheetsToStartData,
    sheetsToStartById,
    filtersSchema,
    currentSheetToStartData: {
      startDate: currentSheetToStartDataRequestStartDate,
      stopDate: currentSheetToStartDataRequestStopDate,
      dataEntities: currentSheetToStartDataEntities,
    },
    allTasksTablesIds,
    isGroupSheetsStartInProgress,
    tableFilterParams,
    filterParams,
  } = stateProps;

  const {
    fetchSheetsToStart,
    startSheet,
    handleGroupSheetsStarted,
    broadcastGroupSheetsProcessAndAddToStore,
    resetSelectedTableRows,
    setFilterParams,
  } = dispatchProps;

  /**
   * Когда пользователь ищет МЛ по размеру партии
   *  - т.к. все строки в сторе у нас хранятся в неотфильтрованном виде на клиенте, а таблица
   *    сама их фильтрует при перерендерах/перелистываниях, нам необходимо отфильтровать строки
   *    ещё раз, как минимум один фильтр по ДСЕ у нас уже будет.
   *  - по полученным отфильтрованным данным мы уже можем искать размеры и количества партий.
   *  - если нашли размеры, то подбираем под них партии.
   *    - если не нашли, то никак не меняем фильтры, просто говорим, что ничего не нашли.
   *  - устанавливаем эти партии в фильтры таблицы, чтобы она сама занималась корректным отображением
   *    фильтрацией и т.д.
   *  - показываем нотификашку, что всё хорошо или нормально.
   */
  const handleSetRequiredAmount = requiredAmount => {
    if (_isEmpty(filterParams) || _isEmpty(currentSheetToStartDataEntities)) {
      return;
    }

    // Нужно убрать фильтр по партиям, если он есть, чтобы не искать только по уже выбранным
    const filterParamsWithoutEntityBatchIdentity = _omit(filterParams, 'entityBatchIdentity');

    const filteredRows = Filters.getFilteredData(
      Object.values(currentSheetToStartDataEntities),
      filterParamsWithoutEntityBatchIdentity,
    );

    const counts = filterSheetsForRequiredAmount(requiredAmount, filteredRows);
    if (counts.status === BATCHES_SIZES_FOR_REQUIRED_AMOUNT_STATUSES.NOT_FOUND) {
      sendNotification((
          <Trans id="all_sheets_to_start@required_batches_amount_not_found">
            Не найдено партий для введённого размера
          </Trans>
        ),
        NOTIFICATION_LEVEL.ERROR);
      return;
    }

    const filteredByAmountRows = getSheetsByFilteredAmount(filteredRows, counts.sizesAndAmounts);
    const entityBatchIdentity = filteredByAmountRows.reduce((acc, batch) => {
      const {
        entityBatchIdentity,
      } = batch;
      const additionalData = {
        ...batch,
      };
      acc.filterValue.push(entityBatchIdentity);
      acc.filterAdditionalData.push(additionalData);
      return acc;
    }, {
      filterType: FILTER_TYPES.ONE_OF,
      filterValue: [],
      filterAdditionalData: [],
    });

    setFilterParams({
      ...filterParamsWithoutEntityBatchIdentity,
      entityBatchIdentity,
    });

    switch (counts.status) {
    case BATCHES_SIZES_FOR_REQUIRED_AMOUNT_STATUSES.OK: {
      sendNotification((
          <Trans id="all_sheets_to_start@required_batches_amount_found">
            Найдены партии, содержащие {requiredAmount} ДСЕ
          </Trans>
        ),
        NOTIFICATION_LEVEL.SUCCESS);
      break;
    }
    case BATCHES_SIZES_FOR_REQUIRED_AMOUNT_STATUSES.SMALLER: {
      sendNotification((
          <Trans id="all_sheets_to_start@required_batches_amount_found_smaller">
            Найдены партии, в которых на {counts.difference} ДСЕ меньше, чем требуется
          </Trans>
        ),
        NOTIFICATION_LEVEL.WARNING);
      break;
    }
    default: {
    }
    }
  };

  const canSearchByRequiredAmount = !!_get(filterParams, 'entityIdentity');

  return {
    ...ownProps,
    mainPlanningSessionId,
    tableFilterParams,
    broadcastGroupSheetsProcessAndAddToStore,
    isGroupSheetsStartInProgress,
    resetSelectedTableRows,
    startSheet: (entityBatchFromIaId, entityBatchFromIaIdentity, fromState) => {
      const sheetIdentity = getDefaultSheetToStartIdentity(fromState, entityBatchFromIaIdentity, mainPlanningSessionId);
      return startSheet(
        {
          entityBatchFromIaId,
          entityBatchFromIaIdentity,
          sheetIdentity,
        },
        false,
        { isBlockingRequest: false },
      );
    },
    handleGroupSheetsStarted: startedSheetsData => {
      const startedEntityBatchFromIaIds = Object
        .values(startedSheetsData)
        .map(({ entityBatchFromIaId }) => entityBatchFromIaId);

      broadcastEventMessage(SHEETS_STARTED_EVENT_TYPE, startedEntityBatchFromIaIds);

      handleGroupSheetsStarted(startedEntityBatchFromIaIds, allTasksTablesIds);
    },
    sheetsToStartData,
    sheetsToStartById,
    filtersSchema,
    currentSheetToStartDataRequestStartDate,
    currentSheetToStartDataRequestStopDate,
    isSheetsToStartDataInStore: currentSheetToStartDataEntities !== null,
    fetchSheetsToStart: (newSheetToStartDataRequestStartDate, newSheetToStartDataRequestStopDate) =>
      fetchSheetsToStart(mainPlanningSessionId, newSheetToStartDataRequestStartDate, newSheetToStartDataRequestStopDate),
    canSearchByRequiredAmount,
    onSetRequiredAmount: handleSetRequiredAmount,
  };
};

export const AllSheetsToStartContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(AllSheetsToStart);

AllSheetsToStartContainer.displayName = 'AllSheetsToStartContainer';
