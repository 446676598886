import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { OBJECT_OF_ANY_TYPE, FUNC_IS_REQUIRED_TYPE } from '../../../../../../constants/propTypes';

import './style.css';
import { PERMISSION, PERMISSIONS_MANAGER_TYPE } from '../../../../../../hoc/withPermissionsManager/constants';
import {
  SimpleTableWithDraggableRows,
} from '../../../../../common/SimpleTableWithDraggableRows/SimpleTableWithDraggableRows';
import cn from 'classnames';
import { WorkerTasksSettingsColumnsOrderTableTitleTrans } from '../../../../../../utils/commonTransComponents';
import { orderFieldComparator } from '../../../../../../constants/comparators';
import {
  getWorkerTasksDraggableRowSchema,
  getWorkerTasksTableSettingsDraggableRowClassNames,
} from '../../../constants';



export class TasksTableColumnsSettingsGroup extends Component {

  static propTypes = {
    tasksTableColumnsSettings: OBJECT_OF_ANY_TYPE.isRequired,
    editTasksTableColumnsSettings: FUNC_IS_REQUIRED_TYPE,
    onSettingsEditCancel: FUNC_IS_REQUIRED_TYPE,
    onSettingsSave: FUNC_IS_REQUIRED_TYPE,
    isDeleteSettingsButtonDisabled: PropTypes.bool.isRequired,
    isColumnsSettingsChanged: PropTypes.bool.isRequired,
    deleteSelectedSettings: FUNC_IS_REQUIRED_TYPE,
    PermissionsManager: PERMISSIONS_MANAGER_TYPE,
    settingsSaveDisableReasonTrans: PropTypes.node,
    isCreating: PropTypes.bool.isRequired,
  };

  _onColumnVisibilityChange = (e, columnId) => {
    e.stopPropagation();

    const {
      tasksTableColumnsSettings,
    } = this.props;

    const checked = e.target.checked;

    /*
    * Если пытаемся сделать невидимой последнюю из видимых колонок, то ничего не делаем, должна быть хотя бы одна
    * видимая колонка
    * */
    if(
      !checked &&
      Object.values(tasksTableColumnsSettings).filter(({ show }) => show).length === 1
    ) return;

    const newTasksTableColumnsSettings = {
      ...tasksTableColumnsSettings,
      [columnId]: {
        ...tasksTableColumnsSettings[columnId],
        show: checked,
      },
    };

    this.props.editTasksTableColumnsSettings(newTasksTableColumnsSettings);
  };

  render() {
    const {
      editTasksTableColumnsSettings,
      PermissionsManager,
      onSettingsSave,
      onSettingsEditCancel,
      deleteSelectedSettings,
      isDeleteSettingsButtonDisabled,
      settingsSaveDisableReasonTrans,
      isCreating,
      isColumnsSettingsChanged,
      tasksTableColumnsSettings,
    } = this.props;

    const isSaveBtnDisabled = isCreating ? !!settingsSaveDisableReasonTrans :
      !isColumnsSettingsChanged;

    const isCancelBtnDisabled = isCreating ? false : !isColumnsSettingsChanged;

    const isWorkerTasksTableSettingsPermissionDenied =
      PermissionsManager.isDenied(PERMISSION.MANAGE_WORKER_TASKS_TABLE_SETTINGS);


    const columnsSortedByOrderArr = Object
      .values(tasksTableColumnsSettings)
      .sort(orderFieldComparator);

    let currentVisibleIndexToShow = 1;

    const columnsSortedByOrderArrWithVisibleIndexes = columnsSortedByOrderArr
      .map(item => {

        if(!item.show) {
          return { ...item, visibleIndexToShow: 0 };
        }

        return {
          ...item,
          visibleIndexToShow: currentVisibleIndexToShow ++,
        };
      });

    const className = cn('tasks-table-columns-settings-group', {
      'tasks-table-columns-settings-group--is-creating': isCreating,
    });

    return (
      <div className={className}>
        <SimpleTableWithDraggableRows
            draggableItemsData={columnsSortedByOrderArrWithVisibleIndexes}
            getItemId={({ columnName }) => columnName}
            onDragEnd={editTasksTableColumnsSettings}
            isDragDisabled={isWorkerTasksTableSettingsPermissionDenied}
            getDraggableRowClassNames={getWorkerTasksTableSettingsDraggableRowClassNames}
            rowItemsSchema={getWorkerTasksDraggableRowSchema(this._onColumnVisibilityChange, isWorkerTasksTableSettingsPermissionDenied)}
            onSave={onSettingsSave}
            onCancel={onSettingsEditCancel}
            onDelete={deleteSelectedSettings}
            isSaveBtnDisabled={isSaveBtnDisabled}
            isCancelBtnDisabled={isCancelBtnDisabled}
            isDeleteBtnDisabled={isDeleteSettingsButtonDisabled}
            tableTitle={WorkerTasksSettingsColumnsOrderTableTitleTrans}
        />
      </div>
    );
  }
}
