import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import './style.css';
import PropTypes from 'prop-types';
import { OBJECT_OF_ANY_TYPE } from '../../../constants/propTypes';
import Chip from '@mui/material/Chip';
import cn from 'classnames';
import Collapse from '@mui/material/Collapse';
import { Fade, Link } from '@mui/material';
import { ShowMoreLabelTrans } from '../../../utils/commonTransComponents';
import { usePartialRendering } from '../../../hooks/usePartialRendering';
import _isFunction from 'lodash/isFunction';

const ONE_CHIPS_ROW_HEIGHT = 47;
export const ChipList = props => {
  const {
    items,
    onDelete,
    className,
    getItemId,
    getItemLabel,
    initialRowsToShowAmount,
  } = props;

  const [isShowingAll, setIsShowingAll] = useState(false);
  const [isShowAllButtonVisible, setIsShowAllButtonVisible] = useState(false);

  const chipListItemsContainerRef = useRef(null);

  const chipListClassName = cn('chip-list', className);

  const collapsedChipListHeight = useMemo(
    () => ONE_CHIPS_ROW_HEIGHT * initialRowsToShowAmount,
    [initialRowsToShowAmount],
  );

  const renderChipListItem = useCallback(item => {
    const itemId = getItemId(item);
    const itemLabel = getItemLabel(item);

    /* Если onDelete не передан, то иконка удаления не рендерится */
    const deleteChipHandler = _isFunction(onDelete) ? e => onDelete(itemId, e) : undefined;

    const chipItemClassName = cn('chip-list__item', {
      'chip-list__item--with-delete': !!deleteChipHandler,
    });

    return (
      <Chip
          className={chipItemClassName}
          key={itemId}
          label={itemLabel}
          onDelete={deleteChipHandler}
      />
    );
  }, [onDelete, getItemId, getItemLabel]);

  const chipListMarkup = usePartialRendering(items, renderChipListItem);

  useEffect(() => {
    if (isShowingAll) return;

    const chipListContainerHeight = chipListItemsContainerRef.current.offsetHeight;

    if (chipListContainerHeight > collapsedChipListHeight) setIsShowAllButtonVisible(true);
  }, [isShowingAll, chipListMarkup, collapsedChipListHeight, setIsShowAllButtonVisible]);

  const showAllItems = useCallback(() => {
    setIsShowingAll(true);
    setIsShowAllButtonVisible(false);
  }, [setIsShowingAll, setIsShowAllButtonVisible]);


  return (
    <div className={chipListClassName}>

        <div className="chip-list__content">
          <Collapse in={isShowingAll} collapsedSize={collapsedChipListHeight}>
            <div ref={chipListItemsContainerRef} className="chip-list__items-container">
              {chipListMarkup}
            </div>

          </Collapse>
        </div>

      <Fade in={isShowAllButtonVisible}>
        <Link onClick={showAllItems} className="chip-list__show-more">
          {ShowMoreLabelTrans}
        </Link>
      </Fade>
    </div>
  );
};

ChipList.propTypes = {
  items: PropTypes.arrayOf(OBJECT_OF_ANY_TYPE),
  onDelete: PropTypes.func,
  className: PropTypes.string,
  getItemId: PropTypes.func,
  getItemLabel: PropTypes.func,
  initialRowsToShowAmount: PropTypes.number,
};

ChipList.defaultProps = {
  getItemId: ({ id }) => id,
  getItemLabel: ({ label }) => label,
  /* отвечает за максимальное количество показанных строк до нажатия на кнопку "Показать больше" */
  initialRowsToShowAmount: 2,
};