import { FILTER_TYPES } from '../../api/restCollectionApi/index';
import _isNil from 'lodash/isNil';

const {
  CONTAINS,
  NOT_CONTAINS,
  EQUALS,
  NOT_EQUALS,
  GREATER_OR_EQUAL,
  LESS_OR_EQUAL,
  ONE_OF,
} = FILTER_TYPES;

export default class Filters {

  /**
   * Проверяет соответствует ли объект данных item параметрам фильтра filterParams
   * @param item
   * @param filterParams:
   * {
   *    filterFieldName - поле объекта, по которому ведется фильтрация
   *    filterValue - значение фильтра для указанного поля
   *    filterType - тип фильтра
   *    fieldColumnData: columnsData[filterFieldName] - дополнительные параметры колонки фильтра
   * }
   *
   * @returns {boolean}
   */
  static isAppropriate(item, filterParams) {
    const {
      filterFieldName,
      filterValue,
      filterType,
      viewValueGetter,
    } = filterParams;
    const itemFieldValue = Filters.getItemFieldValue(item, filterFieldName, viewValueGetter);

    /*
    * Фильтруем пустые ячейки, которые получаются в таблице, т.к. значения ячейки равны null или undefined, при любом
    * значении фильтра. Это нужно, т.к.:,
    *  - Это логично, пустая строка не равна ниодному из значений фильтра (пустые фильтры концептуально недопустимы и
    *  вообще удаляются кодом в getFilteredData ниже,)
    *  - Если не сделать эту проверку, то все дальнейшие проверки выдадут исключения, т.к. туда придет null или undefined
    * */
    if(_isNil(itemFieldValue)) return false;

    switch (filterType) {
    case EQUALS:
      return itemFieldValue.toString().toUpperCase() === filterValue.toString().toUpperCase();
    case NOT_EQUALS:
      return itemFieldValue.toString().toUpperCase() !== filterValue.toString().toUpperCase();
    case GREATER_OR_EQUAL:
      return (Number(itemFieldValue) || 0) >= (Number(filterValue) || 0);
    case LESS_OR_EQUAL:
      return (Number(itemFieldValue) || 0) <= (Number(filterValue) || 0);
    case CONTAINS:
      return itemFieldValue.toString().toUpperCase().includes(filterValue.toString().toUpperCase());
    case NOT_CONTAINS:
      return !itemFieldValue.toString().toUpperCase().includes(filterValue.toString().toUpperCase());
    case ONE_OF:
      const itemFieldValueInUpperCase = itemFieldValue.toString().toUpperCase();
      return filterValue.some(filterElement => filterElement.toString().toUpperCase() === itemFieldValueInUpperCase);
    default:
      return true;
    }
  }

  static getItemFieldValue(item, filterFieldName, viewValueGetter) {
    const fieldValue = item[filterFieldName];

    if(!viewValueGetter) return fieldValue;

    return viewValueGetter(fieldValue, item);
  }

  static getFilteredData(data = [], filtersByFieldNames = {}, filteredFieldsViewValueGetters = {}) {

    return data
      .filter(item =>
          Object
            .keys(filtersByFieldNames)
            .map(filterFieldName => {
              const { filterValue, filterType } = filtersByFieldNames[filterFieldName];
              return {
                filterFieldName,
                filterValue,
                filterType,
                viewValueGetter: filteredFieldsViewValueGetters[filterFieldName],
              };
            })
            .every(filterParams => Filters.isAppropriate(item, filterParams)));

  }
}