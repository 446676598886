import { Transformer } from '../Transformer';


export class UserSettings extends Transformer {

  static transformToState(item) {
    const {
      createStamp,
      userId,
      data,
    } = item;


    return {
      createStamp,
      /* Для корректной нормализации данных задаём поле id */
      id: userId,
      data,
    };
  };
}