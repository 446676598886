import {
  fetchCompletedSheets,
  fetchPartAndMaterialsReserveDataForAssemblyEntityBatch,
  fetchPossiblePartsAndMaterialsToConsumeForDefaultSheet,
  fetchSheetsInProduction,
  fetchDefaultSheetsWithEnoughPartsAndMaterialsInStorage,
} from '../../../../operations/sheets';
import { SHEET_TYPE } from '../../../../constants/sheets';
import {
  fetchEquipmentClassInDepartmentTasksRemoteTableData,
  fetchTasksRemoteTableData,
} from '../../../../operations/tasks';
import { allTasksTablesIdsSelector } from '../../../../selectors/taskView';
import { routerPathnameSelector } from '../../../../reducers/router/selectors';
import { matchPath } from 'react-router-dom';
import {
  MASTER_APP_COMPLETED_TASKS_ROUTE,
  MASTER_APP_TASKS_TO_DO_ROUTE,
  PLANNER_APP_COMPLETED_SHEETS_ROUTE,
  PLANNER_APP_ORDERS_IN_PRODUCTION_ROUTE,
  PLANNER_APP_ORDERS_READY_TO_COMPLETE_ROUTE,
  PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE,
  STORAGE_MANAGEMENT_APP_ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
  STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
  WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE,
  WORKER_APP_TASKS_ROUTE,
} from '../../../../constants/routes';
import { clearTableData, clearTableRemoteData, reFetchRemoteTableData } from '../../../../reducers/table/actions';
import {
  createOrderEntriesTableId,
  createSheetTypeOperationsTableId,
  getEquipmentClassInDepartmentTasksTableId,
} from '../../../../utils/tables';
import { deleteEntitiesFromStore, fetchEntitiesFromServer } from '../../../../reducers/entities/actions';
import { SHEET_MODEL } from '../../../../constants/models';
import { clearAllDefaultSheetsPartsAndMaterialsToConsume } from '../../../../reducers/storageManagementApp/defaultSheets/actions';
import {
  fetchSheetTypeRemoteTableDataCbFactory,
  fetchSheetTypeToReviewCbFactory,
} from '../../../../components/Sheets/SheetsContainer';
import { push } from 'connected-react-router';
import { getMasterAppDepartmentIdsFromRouteParam } from '../../../../components/MasterApp/MasterWorkspace/masterAppDepartmentIdsRouteParam';
import { MASTER_TASKS_TO_DO_TABLE_ID } from '../../../../components/MasterApp/MasterTasksToDo/constants';
import { MASTER_COMPLETED_TASKS_TABLE_ID } from '../../../../components/MasterApp/MasterCompletedTasks/constants';
import {
  fetchMasterDepartmentsCompletedTasksRemoteTableData,
  fetchMasterDepartmentsTasksToDoRemoteTableData,
} from '../../../../operations/masterWorkspace/index';
import { masterTasksToDoAdditionalFiltersSelector } from '../../../../reducers/masterApp/tasksToDoAdditionalFilters/selectors';
import {
  fetchOrdersInProduction,
  fetchOrdersReadyToComplete,
  sendOrderIsReadyToCompleteNotification,
} from '../../../../operations/orders';
import { ORDER_IN_PRODUCTION_AND_READY_TO_COMPLETE_TABLES_IDS, ORDER_TYPE } from '../../../../constants/orders';
import { fetchOrderRemoteTableDataCbFactory } from '../../../../components/Orders/OrdersContainer';
import { fetchFullAssemblySheetsPartsAndMaterials } from '../../../../components/StorageManagementApp/SheetsWaitingPartsAndMaterials/AssemblySheetsWaitingPartsAndMaterials/AssemblySheetsWaitingPartsAndMaterials';
import { deleteAllAssemblySheetsReserveData } from '../../../../reducers/storageManagementApp/assemblySheets/reserveData/actions';
import { deleteAssemblySheetConsumeData } from '../../../../reducers/workerApp/assemblySheets/consumeData/actions';
import { FILTER_GROUP_TYPES, FILTER_TYPES } from '../../../restCollectionApi/index';
import { TASKS_MODEL } from '../../../../reducers/schemaModel/models/tasksSchema';
import {
  MASTER_COMPLETED_TASKS_TABLE_MODEL,
  MASTER_TASKS_TO_DO_TABLE_MODEL,
} from '../../../../reducers/schemaModel/models/masterTasksSchema';

/*
* Подробно о влиянии события завершения МЛ на типы приложения описано в комментарии к handleSheetFinished в
* SheetInProductionReviewContentContainer. Обработка в handleSheetFinished реализована для пользователя завершающего
* МЛ в интерфейсе просмотра "МЛ в производстве"
* В случае с получением события завершения МЛ другими пользователями нужна похожая обработка, но, с учетом, того, что
* пользователи могут находиться в этот момент в разделах, которые сразу же должны быть обновлены - в этом случае,
* сбрасывать "кэш" не нужно, а нужен именно перезапрос \ обновление данных. Если перед перезапросом данных мы сначала
* очистим "кэш" (т.е. данные текущего раздела), а только потом перезапросим новые данные, то у пользователя в интерфейсе
* случится "скачок" (данные быстро пропадут, кое-где мелькнет сообщение о пустых данных, а потом тут же появятся новые
* данные), что нежелательно.
* Для всех остальных разделов, где нужны обновления, кроме текущего, требуется, аналогично, очистить "кэш", чтобы при
* повторном входе в интерфейсы запросы выполнились заново
* */
export const handleSheetFinish = message =>
  (dispatch, getState) => {
    const {
      sheetId,
      orderId,
      orderName,
      isOrderCompleted,
    } = message;


    const state = getState();

    const allTasksTablesIds = allTasksTablesIdsSelector(state);

    const currentPathname = routerPathnameSelector(state);

    //Если находимся в разделе "Плановик. МЛ в производстве"
    const sheetsInProductionRouteMatch = matchPath(currentPathname, {
      path: PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE,
    });
    if(sheetsInProductionRouteMatch !== null)
      return dispatch(_updateIfOnSheetsInProductionScreen(
        sheetId,
        currentPathname,
        allTasksTablesIds,
        orderId,
        orderName,
        isOrderCompleted,
      ));


    //Если находимся в разделе "Плановик. Завершенные МЛ"
    const completedSheetsRouteMatch = matchPath(currentPathname, {
      path: PLANNER_APP_COMPLETED_SHEETS_ROUTE,
    });
    if(completedSheetsRouteMatch !== null)
      return dispatch(_updateIfOnCompletedSheetsScreen(
        sheetId,
        allTasksTablesIds,
        orderId,
        orderName,
        isOrderCompleted,
      ));

    //Если находимся в разделе "Плановик. Заказы. В производстве"
    const ordersInProductionRouteMatch = matchPath(currentPathname, {
      path: PLANNER_APP_ORDERS_IN_PRODUCTION_ROUTE,
    });
    if (ordersInProductionRouteMatch !== null)
      return dispatch(_updateIfOnOrdersInProductionScreen(
        sheetId,
        currentPathname,
        allTasksTablesIds,
        orderId,
        orderName,
        isOrderCompleted,
      ));

    //Если находимся в разделе "Плановик. Заказы. Готовые к завершению"
    const ordersReadyToCompleteRouteMatch = matchPath(currentPathname, {
      path: PLANNER_APP_ORDERS_READY_TO_COMPLETE_ROUTE,
    });
    if (ordersReadyToCompleteRouteMatch !== null)
      return dispatch(_updateIfOnOrdersReadyToCompleteScreen(
        sheetId,
        currentPathname,
        allTasksTablesIds,
        orderId,
        orderName,
        isOrderCompleted,
      ));

    //Если находимся в разделе "Комплектование - "стандартные" неукомплектованные маршрутные листы"
    const defaultSheetsWaitingPartsAndMaterialsRouteMatch = matchPath(currentPathname, {
      path: STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
    });
    if(defaultSheetsWaitingPartsAndMaterialsRouteMatch !== null)
      return dispatch(_updateIfOnDefaultSheetsWaitingPartsAndMaterialsScreen(
        sheetId,
        currentPathname,
        allTasksTablesIds,
        orderId,
        orderName,
        isOrderCompleted,
      ));

    //Если находимся в разделе "Комплектование" - "сборки"
    const assemblySheetsWaitingPartsAndMaterialsRouteMatch = matchPath(currentPathname, {
      path: STORAGE_MANAGEMENT_APP_ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
    });
    if (assemblySheetsWaitingPartsAndMaterialsRouteMatch !== null) {
      return dispatch(_updateIfOnAssemblySheetsWaitingPartsAndMaterialsScreen(
        sheetId,
        currentPathname,
        allTasksTablesIds,
        orderId,
        orderName,
        isOrderCompleted,
      ));
    }

    //Если находимся  в разделе "Мастер. Требуется выполнить"
    const masterTasksTodoRouteMatch = matchPath(currentPathname, {
      path: MASTER_APP_TASKS_TO_DO_ROUTE,
    });
    if(masterTasksTodoRouteMatch !== null)
      return dispatch(_updateIfOnMasterTasksTodoScreen(
        sheetId,
        getMasterAppDepartmentIdsFromRouteParam(masterTasksTodoRouteMatch.params.departmentIds),
        allTasksTablesIds,
        orderId,
        orderName,
        isOrderCompleted,
      ));

    //Если находимся  в разделе "Мастер. Завершенные"
    const masterCompletedTasksRouteMatch = matchPath(currentPathname, {
      path: MASTER_APP_COMPLETED_TASKS_ROUTE,
    });
    if(masterCompletedTasksRouteMatch !== null)
      return dispatch(_updateIfOnMasterCompletedTasksScreen(
        sheetId,
        getMasterAppDepartmentIdsFromRouteParam(masterCompletedTasksRouteMatch.params.departmentIds),
        allTasksTablesIds,
        orderId,
        orderName,
        isOrderCompleted,
      ));

    //Если находимся в разделе "Рабочий. Просмотр задания для класса РЦ в подразделении"
    const tasksForEquipmentClassInDepartmentRouteMatch = matchPath(currentPathname, {
      path: `${WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE}/:departmentId/:equipmentClassId`,
    });
    if(tasksForEquipmentClassInDepartmentRouteMatch !== null) {
      const {
        departmentId,
        equipmentClassId,
      } = tasksForEquipmentClassInDepartmentRouteMatch.params;

      return dispatch(_updateIfOnTasksViewScreen(
        sheetId,
        departmentId,
        equipmentClassId,
        allTasksTablesIds,
        orderId,
        orderName,
        isOrderCompleted,
      ));
    }

    //Если находимся в разделе "Рабочий. Просмотр ВСЕХ заданий"
    const workerAppTasksRouteMatch = matchPath(currentPathname, {
      path: WORKER_APP_TASKS_ROUTE,
    });
    if(workerAppTasksRouteMatch !== null) {
      return dispatch(_updateIfOnWorkerAppTasksScreen(
        sheetId,
        allTasksTablesIds,
        orderId,
        orderName,
        isOrderCompleted,
      ));
    }

    /*
    * Для всех остальных разделов не нужна какая-то дополнительная обработка, но и для этого случая необходимо
    * очистить данные всех интерфейсов, где должны быть обновления из-за завершения МЛ, чтобы при следующем
    * входе в эти разделы данные были запрошены заново
    * */
    dispatch([
      clearTableData([
        createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId),
      ]),
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        SHEET_TYPE.COMPLETED,
        MASTER_TASKS_TO_DO_TABLE_ID,
        MASTER_COMPLETED_TASKS_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),
      deleteAllAssemblySheetsReserveData(),
      deleteAssemblySheetConsumeData({ sheetId }),
      deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
      clearAllDefaultSheetsPartsAndMaterialsToConsume(),
      ...getOrderCompletedRelatedActions(isOrderCompleted, orderId, orderName),
    ]);

  };


/*
* Если находимся в разделе "Плановик. МЛ в производстве", то очищаем все данные других интерфейсов кроме этого, где
* должны быть обновления из-за завершения МЛ, а для этого раздела перезапрашиваем данные списка для текущей
* страницы.
* Дополнительно, если находимся в режиме просмотра МЛ, который только что был завершен, то редиректим пользователя на
* на экран просмотра списка МЛ
* */
const _updateIfOnSheetsInProductionScreen = (
  sheetId,
  currentPathname,
  allTasksTablesIds,
  orderId,
  orderName,
  isOrderCompleted,
  ) => dispatch => {

    dispatch([
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId)),
      clearTableRemoteData([
        SHEET_TYPE.COMPLETED,
        MASTER_TASKS_TO_DO_TABLE_ID,
        MASTER_COMPLETED_TASKS_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),
      deleteAllAssemblySheetsReserveData(),
      deleteAssemblySheetConsumeData({ sheetId }),
      deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
      clearAllDefaultSheetsPartsAndMaterialsToConsume(),
      ...getOrderCompletedRelatedActions(isOrderCompleted, orderId, orderName),
    ]);

    dispatch(reFetchRemoteTableData(
      SHEET_TYPE.IN_PRODUCTION,
      SHEET_TYPE.IN_PRODUCTION,
      fetchSheetTypeRemoteTableDataCbFactory(
        dispatch,
        query => fetchSheetsInProduction(query, { isBlockingRequest: false }),
      ),
    ));

    const sheetInProductionReviewRouteMatch = matchPath(currentPathname, {
      path: `${PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE}/:sheetId`,
    });

    if(
      sheetInProductionReviewRouteMatch !== null &&
      sheetInProductionReviewRouteMatch.params.sheetId === sheetId.toString()
    ) {
      dispatch(push(PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE));
    }
  };


/*
* Если находимся в разделе "Плановик.  Завершенные МЛ, то очищаем все данные других интерфейсов кроме этого, где должны
* быть обновления из-за завершения МЛ, а для этого раздела перезапрашиваем данные списка для текущей
* страницы
* */
const _updateIfOnCompletedSheetsScreen = (sheetId, allTasksTablesIds, orderId, orderName, isOrderCompleted) =>
  dispatch => {

    dispatch([
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId)),
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        MASTER_TASKS_TO_DO_TABLE_ID,
        MASTER_COMPLETED_TASKS_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),
      deleteAllAssemblySheetsReserveData(),
      deleteAssemblySheetConsumeData({ sheetId }),
      deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
      clearAllDefaultSheetsPartsAndMaterialsToConsume(),
      ...getOrderCompletedRelatedActions(isOrderCompleted, orderId, orderName),
    ]);

    dispatch(reFetchRemoteTableData(
      SHEET_TYPE.COMPLETED,
      SHEET_TYPE.COMPLETED,
      fetchSheetTypeRemoteTableDataCbFactory(
        dispatch,
        query => fetchCompletedSheets(query, { isBlockingRequest: false }),
      ),
    ));
  };


const _updateIfOnOrdersInProductionScreen = (
    sheetId,
    currentPathname,
    allTasksTablesIds,
    orderId,
    orderName,
    isOrderCompleted,
  ) => dispatch => {

    // выполняем стандартные обработки по очисткам для всех зависимых интерфейсов
    dispatch([
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId)),
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        SHEET_TYPE.COMPLETED,
        MASTER_TASKS_TO_DO_TABLE_ID,
        MASTER_COMPLETED_TASKS_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),
      deleteAllAssemblySheetsReserveData(),
      deleteAssemblySheetConsumeData({ sheetId }),
      deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
      clearAllDefaultSheetsPartsAndMaterialsToConsume(),
    ]);

    // если при завершении МЛ не завершился соответствующий заказ, то больше ничего не делаем
    if(!isOrderCompleted) return;

    // если заказ завершился, то:

    // перезапрашиваем данные таблицы заказов в производстве
    dispatch(reFetchRemoteTableData(
      ORDER_TYPE.IN_PRODUCTION,
      ORDER_TYPE.IN_PRODUCTION,
      fetchOrderRemoteTableDataCbFactory(
        dispatch,
        query => fetchOrdersInProduction(query, { isBlockingRequest: false }),
      ),
    ));

    // вызываем нотификейшн с предупреждением о том, что заказ готов к завершению
    sendOrderIsReadyToCompleteNotification(orderName);

    dispatch([
      // сбрасываем кэш таблицы заказов готовых к завершению
      clearTableRemoteData([ORDER_TYPE.READY_TO_COMPLETE]),
      // сбрасываем кэш таблицы просмотра заказа в списке заказов в производстве
      clearTableData(createOrderEntriesTableId(ORDER_TYPE.IN_PRODUCTION, orderId)),
    ]);

    /*
    * Для случая, когда находимся на роуте просмотра заказа, который изменил свой статус на "готов к завершению",
    * выполняем редирект к списку заказов в производстве
    * */
    const plannerAppOrderInProductionReviewRouteMatch = matchPath(currentPathname, {
      path: `${PLANNER_APP_ORDERS_IN_PRODUCTION_ROUTE}/:orderId`,
    });

    if(
      plannerAppOrderInProductionReviewRouteMatch !== null &&
      plannerAppOrderInProductionReviewRouteMatch.params.orderId === orderId.toString()
    ) {
      return dispatch(push(PLANNER_APP_ORDERS_IN_PRODUCTION_ROUTE));
    }
  };

const _updateIfOnOrdersReadyToCompleteScreen = (sheetId, currentPathname, allTasksTablesIds, orderId, orderName, isOrderCompleted) =>
  dispatch => {

    if(isOrderCompleted) {

      // перезапрашиваем данные таблицы заказов готовых к завершению
      dispatch(reFetchRemoteTableData(
        ORDER_TYPE.READY_TO_COMPLETE,
        ORDER_TYPE.READY_TO_COMPLETE,
        fetchOrderRemoteTableDataCbFactory(
          dispatch,
          query => fetchOrdersReadyToComplete(query, { isBlockingRequest: false }),
        ),
      ));

      sendOrderIsReadyToCompleteNotification(orderName);

      dispatch([
        // сбрасываем кэш таблицы просмотра содержимого заказа в производстве
        clearTableData(createOrderEntriesTableId(ORDER_TYPE.IN_PRODUCTION, orderId)),
        // сбрасываем кэш таблицы заказов в производстве
        clearTableRemoteData([ORDER_TYPE.IN_PRODUCTION]),
      ]);
    }

    dispatch([
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId)),
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        SHEET_TYPE.COMPLETED,
        MASTER_TASKS_TO_DO_TABLE_ID,
        MASTER_COMPLETED_TASKS_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),
      deleteAllAssemblySheetsReserveData(),
      deleteAssemblySheetConsumeData({ sheetId }),
      deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
      clearAllDefaultSheetsPartsAndMaterialsToConsume(),
    ]);
  };


/*
* Если находимся в разделе "Комлектование - стандартные МЛ", то очищаем данные других интерфейсов кроме этого, где должны
* быть обновления из-за завершения МЛ.
* Для текущего раздела:
*  - Учитывая все особенности клиентского списка стандартных МЛ, ожидающих комплектацию, (описано в комментарии к
* handleSheetFinished в SheetInProductionReviewContentContainer), для обновления списка в данный момент не нужен перезапрос
* данных, надо удалить сущность завершенного МЛ из entities
* - Перезапрашиваем данные по возможной комплектации, после запроса они перезапишут старые данные в стор и экран обно
* вится.
* - Если находимся в режиме просмотра конкретного МЛ и это только что завершенный МЛ, то выполняем редирект на список
* МЛ
* - Если находимся в режиме просмотра конкретного МЛ и это не тот МЛ, который только что завершили, то нужно
* перезапросить детальную комплектацию, т.к. данные обновились (и мы чуть ранее очистили весь стор с детальной
* комплектацией)
*
* Первые 2 пункта обрабокти должны имзениться при переходе на серверную таблицу.
*
* */
const _updateIfOnDefaultSheetsWaitingPartsAndMaterialsScreen = (
  sheetId,
  currentPathname,
  allTasksTablesIds,
  orderId,
  orderName,
  isOrderCompleted,
  ) => dispatch => {

    dispatch([
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId)),
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        SHEET_TYPE.COMPLETED,
        MASTER_TASKS_TO_DO_TABLE_ID,
        MASTER_COMPLETED_TASKS_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),
      deleteAllAssemblySheetsReserveData(),
      deleteAssemblySheetConsumeData({ sheetId }),
      deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
      clearAllDefaultSheetsPartsAndMaterialsToConsume(),
      ...getOrderCompletedRelatedActions(isOrderCompleted, orderId, orderName),
    ]);

    dispatch(fetchDefaultSheetsWithEnoughPartsAndMaterialsInStorage({ isBlockingRequest: false }));

    const defaultSheetWaitingPartsAndMaterialsReviewRouteMatch = matchPath(currentPathname, {
      path: `${STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE}/:sheetId`,
    });

    if(defaultSheetWaitingPartsAndMaterialsReviewRouteMatch === null) return;

    const {
      sheetId: sheetIdFromRoute,
    } = defaultSheetWaitingPartsAndMaterialsReviewRouteMatch.params;

    if(sheetIdFromRoute === sheetId.toString()) {
      dispatch(push(STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE));
      return;
    }

    //Т.к. при броадкастинге нам, в общем случае, в обработчике неизвестны параметры просматриваемого МЛ (сейчас
    //они в entities, но таблица должна стать серверной и данные по просматриваемому МЛ будут храниться в локальном
    //state), то придется запросить его, чтобы получить идентификатор партии для перезапроса детальной комплектации
    const fetchSheet = fetchSheetTypeToReviewCbFactory(
      dispatch,
      query => fetchSheetsInProduction(query, { isBlockingRequest: false }),
    );

    fetchSheet(sheetIdFromRoute)
      .then(response => {

        if(!response || response.responseMeta.count === 0) return;

        const { entityBatchId } = response.entities[SHEET_MODEL][sheetIdFromRoute];

        dispatch(fetchPossiblePartsAndMaterialsToConsumeForDefaultSheet(
          entityBatchId,
          sheetIdFromRoute,
          { isBlockingRequest: false },
        ));
      });

  };

/*
 * Если находимся в разделе "Комплектование. Сборочные", то очищаем все данные других интерфейсов кроме
 * этого, где должны быть обновления из-за завершения МЛ, а для этого раздела перезапрашиваем данные списка для
 * текущей страницы
 * */
const _updateIfOnAssemblySheetsWaitingPartsAndMaterialsScreen = (
  sheetId,
  currentPathname,
  allTasksTablesIds,
  orderId,
  orderName,
  isOrderCompleted,
) => dispatch => {
  dispatch([
    clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId)),
    clearTableRemoteData([
      SHEET_TYPE.IN_PRODUCTION,
      SHEET_TYPE.COMPLETED,
      MASTER_TASKS_TO_DO_TABLE_ID,
      MASTER_COMPLETED_TASKS_TABLE_ID,
      TASKS_MODEL,
      ...allTasksTablesIds,
    ]),
    deleteAssemblySheetConsumeData({ sheetId }),
    clearAllDefaultSheetsPartsAndMaterialsToConsume(),
    deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
    ...getOrderCompletedRelatedActions(isOrderCompleted, orderId, orderName),
  ]);

  dispatch(reFetchRemoteTableData(
    SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
    SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
    fetchSheetTypeRemoteTableDataCbFactory(
      dispatch,
      query => fetchFullAssemblySheetsPartsAndMaterials(query, { isBlockingRequest: false }),
    ),
  ));

  const partsAndMaterialsReserveRouteMatch = matchPath(currentPathname, {
    path: `${STORAGE_MANAGEMENT_APP_ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE}/:sheetId`,
  });

  // если находимся НЕ на экране резервирования, то удаляем все данные резервирования из store
  if (partsAndMaterialsReserveRouteMatch === null) {
    return dispatch(deleteAllAssemblySheetsReserveData());
  }

  const currentSheetId = partsAndMaterialsReserveRouteMatch.params.sheetId;

  // если находимся на экране резервирования МЛ'а, который завершился, то закрываем модальное окно и очищаем все
  // данные резервирвоания
  if (currentSheetId === sheetId.toString()) {
    return dispatch([
      push(STORAGE_MANAGEMENT_APP_ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE),
      deleteAllAssemblySheetsReserveData(),
    ]);
  }

  /**
   * Если на экране резервирования другого МЛ, то нужно перезапросить данные резервирования для него, а данные остальных
   * удалить из store. Но для перезапроса нужен entityBatchId просматриваемого МЛ, т.к. он неизвестен (в параметрах
   * роута только sheetId), поэтому нужно запросить партию МЛ
   */
  const query = {
    filter: {
      filterGroupType: FILTER_GROUP_TYPES.AND,
      filters: [
        {
          column: 'id',
          filterType: FILTER_TYPES.EQUALS,
          filterValue: currentSheetId,
        },
      ],
    },
  };

  return dispatch(fetchEntitiesFromServer(
    SHEET_MODEL,
    query,
    { isBlockingRequest: false },
  ))
    .then(response => {
      const {
        responseEntitiesIds,
        responseMeta: {
          count,
        },
      } = response;

      if (count === 0) {
        return dispatch(deleteAllAssemblySheetsReserveData([currentSheetId]));
      }

      const currentEntityBatchId = responseEntitiesIds[SHEET_MODEL][0];

      dispatch(deleteAllAssemblySheetsReserveData([currentSheetId]));
      return dispatch(fetchPartAndMaterialsReserveDataForAssemblyEntityBatch(
        currentEntityBatchId,
        currentSheetId,
        undefined,
        { isBlockingRequest: false },
      ));
    });
};

/*
* Если находимся в разделе просмотра заданий "Мастер. Требуется выполнить", то очищаем все данные других интерфейсов
* кроме этого, где должны быть обновления из-за завершения МЛ, а для этого раздела перезапрашиваем данные для текущей
* страницы таблицы
* */
const _updateIfOnMasterTasksTodoScreen = (
  sheetId,
  departmentIds,
  allTasksTablesIds,
  orderId,
  orderName,
  isOrderCompleted,
) =>
  (dispatch, getState) => {

    dispatch([
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId)),
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        SHEET_TYPE.COMPLETED,
        MASTER_COMPLETED_TASKS_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),
      deleteAllAssemblySheetsReserveData(),
      deleteAssemblySheetConsumeData({ sheetId }),
      deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
      clearAllDefaultSheetsPartsAndMaterialsToConsume(),
      ...getOrderCompletedRelatedActions(isOrderCompleted, orderId, orderName),
    ]);

    const masterTasksToDoAdditionalFilters = masterTasksToDoAdditionalFiltersSelector(getState());

    dispatch(reFetchRemoteTableData(
      MASTER_TASKS_TO_DO_TABLE_ID,
      MASTER_TASKS_TO_DO_TABLE_MODEL,
      ({ tableParams }) =>
        dispatch(fetchMasterDepartmentsTasksToDoRemoteTableData(
          departmentIds,
          masterTasksToDoAdditionalFilters,
          tableParams,
          { isBlockingRequest: false },
        )),
    ));
  };

/*
* Если находимся в разделе просмотра заданий "Мастер. Завершенные", то очищаем все данные других интерфейсов
* кроме этого, где должны быть обновления из-за завершения МЛ, а для этого раздела перезапрашиваем данные для текущей
* страницы таблицы
* */
const _updateIfOnMasterCompletedTasksScreen = (
  sheetId,
  departmentIds,
  allTasksTablesIds,
  orderId,
  orderName,
  isOrderCompleted,
) =>
  dispatch => {

    dispatch([
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId)),
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        SHEET_TYPE.COMPLETED,
        MASTER_TASKS_TO_DO_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),
      deleteAllAssemblySheetsReserveData(),
      deleteAssemblySheetConsumeData({ sheetId }),
      deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
      clearAllDefaultSheetsPartsAndMaterialsToConsume(),
      ...getOrderCompletedRelatedActions(isOrderCompleted, orderId, orderName),
    ]);


    dispatch(reFetchRemoteTableData(
      MASTER_COMPLETED_TASKS_TABLE_ID,
      MASTER_COMPLETED_TASKS_TABLE_MODEL,
      ({ tableParams }) =>
        dispatch(fetchMasterDepartmentsCompletedTasksRemoteTableData(
          departmentIds,
          tableParams,
          { isBlockingRequest: false },
        )),
    ));
  };

/*
* Если находимся в разделе "Рабочий. Просмотр задания для класса РЦ в подразделении", то очищаем все данные других
* интерфейсов кроме этого, где должны быть обновления из-за завершения МЛ, а для этого раздела перезапрашиваем
* задания для текущих параметров просматриваемой таблицы, чтобы информация обновилась
* */
const _updateIfOnTasksViewScreen = (
  sheetId,
  departmentIdFromRoute,
  equipmentClassIdFromRoute,
  allTasksTablesIds,
  orderId,
  orderName,
  isOrderCompleted,
) =>
  dispatch => {
    const currentTableId = getEquipmentClassInDepartmentTasksTableId(
      departmentIdFromRoute,
      equipmentClassIdFromRoute,
    );

    //Текущую таблицу не очищаем, чтобы не было скачков в интерфейсе, данные в ней обновятся при перезапросе данных
    const allTasksTablesIdsWithoutCurrentTableId = allTasksTablesIds
      .filter(tableId => tableId !== currentTableId);

    dispatch([
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId)),
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        SHEET_TYPE.COMPLETED,
        MASTER_TASKS_TO_DO_TABLE_ID,
        MASTER_COMPLETED_TASKS_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        TASKS_MODEL,
        ...allTasksTablesIdsWithoutCurrentTableId,
      ]),
      deleteAllAssemblySheetsReserveData(),
      deleteAssemblySheetConsumeData({ sheetId }),
      deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
      clearAllDefaultSheetsPartsAndMaterialsToConsume(),
      ...getOrderCompletedRelatedActions(isOrderCompleted, orderId, orderName),
    ]);

    dispatch(reFetchRemoteTableData(
      currentTableId,
      currentTableId,
      ({ tableParams }) =>
        dispatch(fetchEquipmentClassInDepartmentTasksRemoteTableData(
          {
            departmentIdsArray: [departmentIdFromRoute],
            equipmentClassIdsArray: [equipmentClassIdFromRoute],
          },
          tableParams,
          { isBlockingRequest: false },
        )),
    ));
  };


/*
 * Если находимся в разделе "Рабочий. Просмотр ВСЕХ заданий", то очищаем все данные других интерфейсов кроме этого, где
 * должны быть обновления из-за завершения МЛ, а для этого раздела перезапрашиваем данные для таблицы заданий,
 * с учетом текущих параметров таблицы и внешних фильтров
 * */
const _updateIfOnWorkerAppTasksScreen = (
  sheetId,
  allTasksTablesIds,
  orderId,
  orderName,
  isOrderCompleted,
) =>
  dispatch => {

    dispatch([
      clearTableData(createSheetTypeOperationsTableId(SHEET_TYPE.IN_PRODUCTION, sheetId)),
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        SHEET_TYPE.COMPLETED,
        MASTER_TASKS_TO_DO_TABLE_ID,
        MASTER_COMPLETED_TASKS_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        ...allTasksTablesIds,
      ]),
      deleteAllAssemblySheetsReserveData(),
      deleteAssemblySheetConsumeData({ sheetId }),
      deleteEntitiesFromStore(SHEET_MODEL, [sheetId]),
      clearAllDefaultSheetsPartsAndMaterialsToConsume(),
      ...getOrderCompletedRelatedActions(isOrderCompleted, orderId),
    ]);

    dispatch(reFetchRemoteTableData(
      TASKS_MODEL,
      TASKS_MODEL,
      ({ tableParams }) =>
        dispatch(fetchTasksRemoteTableData(tableParams, { isBlockingRequest: false })),
    ));
  };


export const getOrderCompletedRelatedActions = (isOrderCompleted, orderId) => isOrderCompleted ?
  ([
    clearTableRemoteData(ORDER_IN_PRODUCTION_AND_READY_TO_COMPLETE_TABLES_IDS),
    clearTableData(createOrderEntriesTableId(ORDER_TYPE.IN_PRODUCTION, orderId)),
  ]) :
  [];
