import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import { WorkerNavigationPanelContainer } from './WorkerNavigationPanel/WorkerNavigationPanelContainer';
import { DepartmentChooseScreenContainer } from './TasksOwnerChooseScreen/DepartmentChooseScreenContainer';
import { TasksViewScreenContainer } from './TasksViewScreen/TasksViewScreenContainer';
import { EquipmentClassChooseScreenContainer } from './TasksOwnerChooseScreen/EquipmentClassChooseScreenContainer';

import {
  WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE,
  WORKER_APP_MAIN_ROUTE,
  WORKER_APP_TASKS_ROUTE,
} from '../../constants/routes';

import './style.css';
import { AppTypeTopBarContainer } from '../AppTypeTopBar/AppTypeTopBarContainer';
import { APP_TYPE_BAR_SIZE } from '../AppTypeTopBar/constants';
import { WorkerAppViewModeChoosingScreen } from './WorkerAppViewModeChoosingScreen/WorkerAppViewModeChoosingScreen';
import { WORKER_APP_VIEW_MODE } from './constants';
import { WorkerAppTypeTopBar } from './WorkerAppTypeTopBar/WorkerAppTypeTopBar';
import { WorkerTasksTitleComponent } from './WorkerTasksTitleComponent/WorkerTasksTitleComponent';
import { useSelector } from 'react-redux';
import { TasksContainer } from './Tasks/TasksContainer';
import {
  workerAppLastVisitedRouteSelector,
  workerAppViewModeSelector,
} from '../../reducers/appState/selectors';


// eslint-disable-next-line react/prop-types
export const WorkerApp = ({ match : { isExact } }) => {

  const workerAppViewMode = useSelector(workerAppViewModeSelector);
  const lastVisitedRoute = useSelector(workerAppLastVisitedRouteSelector);

  if(isExact && !!workerAppViewMode) {
    if(workerAppViewMode === WORKER_APP_VIEW_MODE.TASKS) {
      return <Redirect to={lastVisitedRoute ? lastVisitedRoute : WORKER_APP_TASKS_ROUTE}/>;
    }

    if(workerAppViewMode === WORKER_APP_VIEW_MODE.EQ_CLASS_IN_DEP_TASKS) {
      return <Redirect to={lastVisitedRoute ? lastVisitedRoute : WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE}/>;
    }
  }

  return (
    <div className="worker-app">
      <Switch>
        <Route
            exact
            path={WORKER_APP_MAIN_ROUTE}
            component={WorkerAppViewModeChoosingScreen}
        />
        <Route
            path={WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE}
            render={_renderEquipmentClassInDepartmentTasksRoutes}
        />
        <Route
            path={WORKER_APP_TASKS_ROUTE}
            render={_renderTasksRoutes}
        />
        <Redirect to={WORKER_APP_MAIN_ROUTE}/>
      </Switch>
    </div>
  );
};

const _renderTasksRoutes = () => {
  return (
    <div>
      <WorkerAppTypeTopBar
          className="worker-app-type-top-bar--tasks"
          titleComponent={WorkerTasksTitleComponent}
      />
      <div className="worker-app__tasks-content-wrap">
        <Switch>
          <Route
              exact
              path={WORKER_APP_TASKS_ROUTE}
              component={TasksContainer}
          />
        </Switch>
      </div>
    </div>
  );
};

const _renderEquipmentClassInDepartmentTasksRoutes = () => {
  return (
    <div>
      <AppTypeTopBarContainer
          className="worker-app__eq-class-in-dep-tasks-main-app-bar"
          position="fixed"
          size={APP_TYPE_BAR_SIZE.LARGE}
          showReloadButtonOnlyOnFullscreen={false}
          TitleComponent={WorkerNavigationPanelContainer}
          renderButtons={_renderWorkerAppTypeTopBarButtonsForEqClassInDepTasksViewMode}
      />
      <div className="worker-app__eq-class-in-dep-tasks-content-wrap">
        <Switch>
          <Route
              exact
              path={WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE}
              component={DepartmentChooseScreenContainer}
          />
          <Route
              path={
              [
                WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE,
                ':departmentId',
                ':equipmentClassId',
              ].join('/')
            }
              component={TasksViewScreenContainer}
          />
          <Route
              path={
              [
                WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE,
                ':departmentId',
              ].join('/')
            }
              component={EquipmentClassChooseScreenContainer}
          />
        </Switch>
      </div>
    </div>
  );
};

const _renderWorkerAppTypeTopBarButtonsForEqClassInDepTasksViewMode = (reloadButton, userMenuButton, exitFromAppButton) => (
  <React.Fragment>
    {userMenuButton}
    {reloadButton}
    {exitFromAppButton}
  </React.Fragment>
);
