import { departmentEntitiesSelector } from '../../reducers/entities/selectors';
import { addEntitiesToStore, fetchEntitiesFromServer } from '../../reducers/entities/actions';
import {
  DEPARTMENT_MODEL,
  ENTITY_BATCH_MODEL,
  ENTITY_MODEL,
  EQUIPMENT_CLASS_MODEL, EQUIPMENT_MODEL,
  OPERATION_MODEL,
  ORDER_MODEL,
  SHEET_MODEL, SHEET_OPERATION_ASSIGNEE_MODEL, USER_MODEL,
} from '../../constants/models';
import { FILTER_GROUP_TYPES, FILTER_TYPES, WITH_PARAMS } from '../../api/restCollectionApi/index';

import { replace } from 'connected-react-router';

import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import { isIdInUrlParamsValid } from '../../utils/url';
import { MASTER_APP_MAIN_ROUTE } from '../../constants/routes';
import {
  replaceDepartmentIdsParamInCurrentPathname,
} from '../../components/MasterApp/MasterWorkspace/masterAppDepartmentIdsRouteParam';
import { routerPathnameSelector } from '../../reducers/router/selectors';
import { SHEET_OPERATION_STATUS } from '../../constants/sheets';
import { fetchSheetsOperationsRemoteTableEntities } from '../sheetOperations/index';
import { MASTER_TASKS_TO_DO_TABLE_ADDITIONAL_FILTER_IDENTITY } from '../../constants/tasks';
import { fetchTasksToDoFromDataPoint } from '../tasks';
import _isNil from 'lodash/isNil';
import { MASTER_TASKS_COLUMNS } from '../../reducers/schemaModel/models/masterTasksSchema';


/*
* Экшн инициализации параметра роутера о выбранных подразделениях мастера departmentIdsFromRoute.
* Проверяет параметр роутера в приложении мастера на валидность идентификаторов и существование самих сущностей
* подразделений. Существование проверяется по наличии в entities, если там подразделений нет, то отсутствующие
* запрашиваются и дозаписываются в entities. В итоге всеех проверок, из параметра departmentIdsFromRoute
* выфильтровываются все невалидные идентификаторы и идентификаторы несуществующих подразделений, выполняется replace
* на параметр роутера, в котором остаются только прошедшие все проверки идентификаторы (!!!до конца не уверен логично
* ли это, может лучше было бы сразу в этом случае тоже реплейсить на роут без выбранных подразделений, т.к. что-то
* пошло не так и пусть пользователь лучше сам выберет нужные подразделения заново). Если все идентификаторы невалидны,
* то выполняется replace на роут без параметра, т.е. в этом случае подразделения не выбраны.
* */
export const initMasterDepartments = departmentIdsFromRoute =>
  (dispatch, getState) => {

    /*
    * Если departmentIdsFromRoute не задан, т.е. инициализация вызвана в момент, когда в параметре роутера не определен
    * параметр подразделений, то ничего не делаем
    * */
    if(!departmentIdsFromRoute) {
      return Promise.resolve();
    }

    /*
    * Проверяем идентфикаторы подразделений на то, что они, вообще, могут быть идентификаторами БД, т.е., являются
    * числом. Иначе, если выполнить запрос с такими идентификаторами, то получим ошибку сервера, что не нужно, т.к.
    * мы и без запроса может задетектировать такие идентфикаторы
    * */
    const validDepartmentsIdsFromRoute = departmentIdsFromRoute.filter(isIdInUrlParamsValid);

    /*
    * Если все заданные идентификаторы не прошли проверку, т.е. не могут являться идентификаторами БД, то сразу
    * replace'им текущую ссылку на ссылку, без параметров подразделений, т.е. селект выбора подразделений будет пустой.
    * */
    if(validDepartmentsIdsFromRoute.length === 0) {
      return dispatch(replace(MASTER_APP_MAIN_ROUTE));
    }

    /*
    * Если есть идентификаторы, которые прошли проверку, то нужно проверить существуют ли подразделения с такими
    * идентификаторами. Сначала проверяем есть ли они в entities, не найденные подразделения пытаемся запросить из БД
    * по идентификаторам
    * */
    const departmentEntitiesInStore = departmentEntitiesSelector(getState());
    const validDepartmentsIdsFromRouteToFetch =
      validDepartmentsIdsFromRoute.filter(idFromRoute => !departmentEntitiesInStore[idFromRoute]);


    return dispatch(_fetchValidDepartmentsIdsFromRoute(validDepartmentsIdsFromRouteToFetch))
      .then(departmentEntitiesFromResponse => {

        /*
        * Если какие-то подразделения были получены в ходе проверки, то записываем их в entities, т.к. пока принято,
        * что данные для селекта у нас формируются из entities. Ну поэтому и проверка на существование из entities
        * выполняется. В теории, можно было бы после сохранения снова получить данные из entities, как мы это уже делали
        * выше, но, показалось, что там будет запутаннее, сейчас же получается, что departmentEntitiesInStore - это
        * сущнсти подразделений до запроса по которым выполнялась проверка, какие из подразделений нужно запросить, и
        * departmentEntitiesFromResponse - это запрошенные сущности, которые будут дозаписаны в entities, что удобно.
        * */
        if(!_isEmpty(departmentEntitiesFromResponse)) {
          dispatch(addEntitiesToStore({ [DEPARTMENT_MODEL]: departmentEntitiesFromResponse }));
        }

        /*
        * Теперь у нас есть данные по всем подразделениям с валидными идентификаторами из параметра роутера, часть
        * из них уже может быть в entities, т.е. в departmentEntitiesInStore, часть их них, мы пытались запросить, и
        * они в departmentEntitiesFromResponse. Теперь проверяем все ли из первоначально заданных в параметре
        * departmentIdsFromRoute идентификаторов прошли все проверки и на валидность и, на существование.
        *
        * Для этого сначала для валидных идентификаторов выфильтровываем только те, для которых были найдены
        * сущности подразделений в departmentEntitiesInStore, или в departmentEntitiesFromResponse, т.е. получаем
        * окончательно валидные идентификаторы подразделений
        * */
        const validAndExistedDepartmentsIdsFromRoute = validDepartmentsIdsFromRoute
          .filter(
            idFromRoute =>
              !!departmentEntitiesInStore[idFromRoute] ||
              !!departmentEntitiesFromResponse[idFromRoute],
          );

        /*
        * Если после проверок на существование, валидных идентификаторов подразделений не осталось, то replace'им на
        * роут экрана мастера без параметров подразделений.
        * */
        if(validAndExistedDepartmentsIdsFromRoute.length === 0) {
          return dispatch(replace(MASTER_APP_MAIN_ROUTE));
        }

        /*
        * Если валидные параметры имеются, то сравниваем, отличается ли исходный массив идентификаторов
        * departmentIdsFromRoute, от этого получившегося массива с валидными параметрами (Дополнительную сортировку
        * выполнять не нужно, т.к. все фильтраци проводились по исходному массиву, т.е. если все проверки пройдены,
        * то идентификаторы в итоговом массиве будут в том же порядке). Если проверку прошли не все
        * идентификаторы и массивы отличаются, то replacе'им на текущую ссылку, но только с валидными идентификаторамми.
        * Вероятно, стоило, как в прошлом ифе, и для этого случая зареплейсить на роут экрана мастера без параметров
        * подразделений, непонятно, как правильно, чуть подробнее об этом в комментарии к самому экшену
        * initMasterDepartments.
        *
        *
        * В случае, если все идентификаторы прошли проверки, то уже ничего делать не нужно, мы осатемся на том же
        * роуте, сущности, если дозапросились, то уже были записаны выше в entities, инициализация завершена
        * */
        if(!_isEqual(departmentIdsFromRoute, validAndExistedDepartmentsIdsFromRoute)) {

          /*
          * Экшн initMasterDepartments вызывается для компонента находящегося в приложении "Мастер", поэтому
          * можно воспользоваться специальной функцией преобразования текущей ссылки в приложении мастер, на
          * ссылку с измененными параметрами подразделений - replaceDepartmentIdsParamInCurrentPathname, передав в
          * неё текущую ссылку, полученную из селектора routerPathnameSelector, и вычисленные валидные идентификаторы
          * подразделений
          * */
          const currentPathname = routerPathnameSelector(getState());

          const currentPathnameWithReplacedDepartmentIdsParam = replaceDepartmentIdsParamInCurrentPathname(
            currentPathname,
            validAndExistedDepartmentsIdsFromRoute,
          );

          return dispatch(replace(currentPathnameWithReplacedDepartmentIdsParam));
        }
      });

  };

const _fetchValidDepartmentsIdsFromRoute = validDepartmentsIdsFromRouteToFetch =>
  dispatch => {

    /*
    * Для удобства написания кода в initMasterDepartments эта проверка тоже перенесена в эту функцию. Здесь проверяется
    * на то, что все подразделения с вычисленными из параметра роутера валидными идентификаторы уже есть в entities.
    * В этом случае запрос на проверку выполнять не нужно, возвращаем из функции пустой объект, т.к. ожидается
    * подразделения, полученные из ответа на запрос departmentEntitiesFromResponse, а запрос не исполнялся, т.е. это
    * логично.
    * */
    if(validDepartmentsIdsFromRouteToFetch.length === 0) {
      return Promise.resolve({});
    }

    /*
    * Выполняем проверочный запрос на существование подразделений. Т.к. и всех из заданных подразделений может не быть,
    * то по результатам запроса достаем сущности подразделений через _get
    * */
    return dispatch(fetchEntitiesFromServer(
      DEPARTMENT_MODEL,
      {
        filter: {
          filterGroupType: FILTER_GROUP_TYPES.AND,
          filters: [
            {
              column: 'id',
              filterType: FILTER_TYPES.ONE_OF,
              filterValue: validDepartmentsIdsFromRouteToFetch,
            },
          ],
        },
      },
    ))
      .then(response => _get(response, ['entities', DEPARTMENT_MODEL], {}));
  };

const MASTER_DEPARTMENTS_TASKS_REQUEST_DEFAULT_WITH_PARAMS = [
  OPERATION_MODEL,
  DEPARTMENT_MODEL,
  EQUIPMENT_CLASS_MODEL,
  SHEET_MODEL,
  ENTITY_BATCH_MODEL,
  ENTITY_MODEL,
  {
    column: ORDER_MODEL,
    params: [{ key: WITH_PARAMS.STRICT, value: false }],
  },
];
const MASTER_DEPARTMENTS_TASKS_REQUEST_SORT_PARAMS = [
  {
    column: 'startDate',
    params: [{ key: 'asc', value: true }],
  },
  {
    column: 'id',
    params: [{ key: 'asc', value: true }],
  },
];

const WORKER_TASKS_WITH_EXECUTOR_FILTER_VALUE = {
  DISABLED: 0,
  WITH_EXECUTOR: 1,
  WITHOUT_EXECUTOR: 2,
};

const WORKER_TASKS_WITH_EQUIPMENT_FILTER_VALUE = {
  DISABLED: 0,
  WITH_EQUIPMENT: 1,
  WITHOUT_EQUIPMENT: 2,
};

const getMastersTasksToDoAdditionalFilters = additionalFilters => {
  const additionalFiltersSet = new Set(additionalFilters);

  const isAllTasksFilterActive = additionalFiltersSet.has(
    MASTER_TASKS_TO_DO_TABLE_ADDITIONAL_FILTER_IDENTITY.ALL_TASKS,
  );

  if (isAllTasksFilterActive) {
    return {
      hasStartReadyTasksFilter: false,
      hasWithoutAssigneeFilter: false,
      hasWithoutEquipmentFilter: false,
    };
  }

  const hasStartReadyTasksFilter = additionalFiltersSet.has(
    MASTER_TASKS_TO_DO_TABLE_ADDITIONAL_FILTER_IDENTITY.READY_TO_START_TASKS,
  );
  const hasWithoutAssigneeFilter = additionalFiltersSet.has(
    MASTER_TASKS_TO_DO_TABLE_ADDITIONAL_FILTER_IDENTITY.TASKS_WITHOUT_ASSIGNEES,
  );
  const hasWithoutEquipmentFilter = additionalFiltersSet.has(
    MASTER_TASKS_TO_DO_TABLE_ADDITIONAL_FILTER_IDENTITY.TASKS_WITHOUT_EQUIPMENT,
  );

  return {
    hasStartReadyTasksFilter,
    hasWithoutAssigneeFilter,
    hasWithoutEquipmentFilter,
  };
};

const FETCH_MASTER_DEPARTMENTS_TASKS_TO_DO_WITH_PARAMS = [
  DEPARTMENT_MODEL,
  ENTITY_MODEL,
  ENTITY_BATCH_MODEL,
  SHEET_MODEL,
  SHEET_OPERATION_ASSIGNEE_MODEL,
  EQUIPMENT_MODEL,
  EQUIPMENT_CLASS_MODEL,
  OPERATION_MODEL,
  ORDER_MODEL,
  USER_MODEL,
];

export const fetchMasterDepartmentsTasksToDoRemoteTableData = (
  departmentIds,
  additionalFilters,
  tableParams,
  requestOptions,
) =>
  dispatch => {
    const {
      filterParams = {},
      activePage,
      pageSize,
    } = tableParams;

    const {
      hasStartReadyTasksFilter,
      hasWithoutAssigneeFilter,
      hasWithoutEquipmentFilter,
    } = getMastersTasksToDoAdditionalFilters(additionalFilters);

    const query = {
      ...getMasterTasksRequestFiltersFromFilterParams(filterParams),
      departmentId: departmentIds,
      executableOnly: hasStartReadyTasksFilter,
      withEquipment: hasWithoutEquipmentFilter ?
        WORKER_TASKS_WITH_EQUIPMENT_FILTER_VALUE.WITHOUT_EQUIPMENT :
        WORKER_TASKS_WITH_EQUIPMENT_FILTER_VALUE.DISABLED,
      withExecutor: hasWithoutAssigneeFilter ?
        WORKER_TASKS_WITH_EXECUTOR_FILTER_VALUE.WITHOUT_EXECUTOR :
        WORKER_TASKS_WITH_EXECUTOR_FILTER_VALUE.DISABLED,
      start: (activePage - 1) * pageSize,
      stop: activePage * pageSize,
      with: FETCH_MASTER_DEPARTMENTS_TASKS_TO_DO_WITH_PARAMS,
    };

    return dispatch(fetchTasksToDoFromDataPoint(query, requestOptions));
  };


const MASTER_TASKS_TABLE_FILTERS_SERVER_KEYS_MAP = {
  [MASTER_TASKS_COLUMNS.ENTITY_NAME]: 'entityId',
  [MASTER_TASKS_COLUMNS.ORDER_NAME]: 'orderId',
  [MASTER_TASKS_COLUMNS.EQUIPMENT]: 'equipmentId',
  [MASTER_TASKS_COLUMNS.OPERATION_NAME]: 'operationName',
  [MASTER_TASKS_COLUMNS.SHEET_IDENTITY]: 'entityRouteSheetId',
  [MASTER_TASKS_COLUMNS.ASSIGNEES]: 'executorId',
  [MASTER_TASKS_COLUMNS.EQUIPMENT_CLASS_NAME]: 'equipmentClassId',
};

const getMasterTasksRequestFiltersFromFilterParams = filterParams => Object
  .keys(filterParams)
  .reduce(
    (acc, key) => {
      const serverFilterKey = MASTER_TASKS_TABLE_FILTERS_SERVER_KEYS_MAP[key];
      if(!serverFilterKey) {
        return acc;
      }

      const filterValue = _get(filterParams, [key, 'filterValue']);
      if(_isNil(filterValue)) {
        return acc;
      }

      acc[serverFilterKey] = filterValue;
      return acc;
    },
    {},
  );

/*
* Постоянные предопределенные фильтры для запроса "завершенных заданий":
*  - задания ТОЛЬКО со статусом "Завершена" (FILTER_TYPES.EQUALS). Другие фильтры тут не применяются, т.к. это уже
* фактически заврешенные задания в подразделении, т.е. "история работ", а её хочется видеть. Т.е. сюда могут попасть
* и заверешенные задания и по МЛ, которые ещё в работе, и по завершенным МЛ, и по приостановленным МЛ, и по архивным
* МЛ (если такие у нас когда-то появятся сценарно)
* */
const MASTER_DEPARTMENT_COMPLETED_TASKS_REQUEST_PREDEFINED_FILTERS = [
  {
    column: 'status',
    filterType: FILTER_TYPES.EQUALS,
    filterValue: SHEET_OPERATION_STATUS.FINISHED,
  },
];

export const fetchMasterDepartmentsCompletedTasksRemoteTableData = (departmentIds, tableParams, requestOptions) =>
  fetchSheetsOperationsRemoteTableEntities({
    mainFilters: [
      {
        column: 'departmentId',
        filterType: FILTER_TYPES.ONE_OF,
        filterValue: departmentIds,
      },
      ...MASTER_DEPARTMENT_COMPLETED_TASKS_REQUEST_PREDEFINED_FILTERS,
    ],
    mainSortParams: MASTER_DEPARTMENTS_TASKS_REQUEST_SORT_PARAMS,
    withModels: MASTER_DEPARTMENTS_TASKS_REQUEST_DEFAULT_WITH_PARAMS,
    withAssigneesData: true,
    withAggregatedData: false,
    tableParams,
    requestOptions,
  });
