import { HTTP_REQUEST_STATUS } from '../httpRequestsApi/constants';
import { setAppCriticalError } from '../../reducers/appState/actions';
import { APP_CRITICAL_ERROR } from '../../constants/app';
import { closeAllSocketConnections } from '../socketApi/index';


// requestAction - actions для запроса
// host - host запроса
// errorHandler - обработчик для ошибок запроса
export const createRequestHandler = (requestAction, host, errorHandler) =>
  (...handlerArguments) =>
    dispatch =>
      dispatch(requestAction(host, ...handlerArguments))
        .catch(({ status, response, options, ...rest }) => {

          // Если прервали запрос самостоятельно, то не обрабатываем и просто передаем дальше информацию
          if (status === HTTP_REQUEST_STATUS.ABORTED) {
            return Promise.reject({ status, response });
          }

          /*
          * Ошибка с таким статусом возникает, когда совсем нет доступа к серверу (неверный адрес сервера, т.е.
          * неверная конфигурация, или сервер "упал"). В этом случае считаем, что работать дальше не имеет смысла,
          * отключаемся от сокетов и устанавливаем критическую ошибку в state, которая отобразится на экране и
          * больше в приложении нельзя будет работать до перезагрузки страницы, при которой все проверки будут
          * проведены заново.
          * */
          if (status === HTTP_REQUEST_STATUS.CONNECTION_ERROR) {
            closeAllSocketConnections();
            dispatch(setAppCriticalError(APP_CRITICAL_ERROR.CONNECTION_ERROR));
            return Promise.reject({ status, response });
          }

          dispatch(errorHandler({ response, status, options }));
          return Promise.reject({ status, response, ...rest });
        });
