import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import _debounce from 'lodash/debounce';

import { FullScreenContext } from './constants';


/*
* В различных источниках довольно много дискуссий, как определять, что браузер в полноэкранном режиме. Кажется, что
* какого-то универсального решения (не только кроссбраузерного, но в зависимости от ОС), всё-таки, нет. Поэтому,
* здесь используется довольно "простой" способ детектирования, который подходит для нужных приложению кейсов:
*  - Пользователь нажимает f11, переходя, в полноэкранный режим
*  - Приложение запускается в режиме киоска (например, для chrome - google-chrome --kiosk -tab "https://нужный урл")
*  - Работает при нескольких мониторах
*
* outerHeight возвращает высоту всего браузера. В полноэкранном режиме в windows в chrome возвращает не 1080,
*   а 1063-1064 (для 1080p).
* innerHeight возвращает высоту внутренней части окна (т.е. без панелей браузера, но со скроллбаром, если есть).
* добавлена 1, т.к. система может зарезервировать себе эту высоту под различные взаимодействия.
*
* Свойство window.fullscreenElement использовать нельзя, т.к. оно не реагирует на f11, т.е. undefined.
*
* Если станет понятно, что какие-то кейсы ещё нужно будет закрыть, которые сейчас не закрыты, то будем расширять
* функции или продумывать новую. Например, данное решение не будет работать, если пользователь увеличит масштаб,
* т.к. в таком случае innerHeight будет всегда меньше высоты экрана. Для такого сценария необходимо изменить функцию.
* */
const checkIfFullScreen = () => 1 >= window.outerHeight - window.innerHeight;

/*
* Здесь не требуется каких-то маленьких таймаутов для детектирования остановки resize, поэтому берется, секунда, чтобы
* точно гарантировать остановку resize
* */
const ON_RESIZE_TIMEOUT_IN_MS = 1000;


/*
* Если понадобятся какая-то ещё функциональность по работе с fullscreen, например, функции, чтобы программно переходить
* в fullscreen и т.д., то Provider просто можно расширять, т.к. у него специально закладывается и довольно абстрактный
* нейминг и передаваемое значение в контекст сразу сделано объектом для возможности расширения.
* */
export const FullScreenProvider = ({ children } ) => {

  const [isFullScreen, setIsFullScreen] = useState(checkIfFullScreen);

  useEffect(
    () => {

      const debouncedOnResize = _debounce(
        () => {
          setIsFullScreen(checkIfFullScreen());
        },
        ON_RESIZE_TIMEOUT_IN_MS,
      );

      window.addEventListener('resize', debouncedOnResize);

      return () => window.removeEventListener('resize', debouncedOnResize);
    },
    //Нужен общий 1 обработчик на приложение, которые зависит только от параметров экрана, поэтому осознанно пустые зависимости
    //eslint-disable-next-line
    [],
  );

  const providerValue = useMemo(
    () => ({
      isFullScreen,
    }),
    [isFullScreen],
  );

  return (
    <FullScreenContext.Provider value={providerValue}>
      {children}
    </FullScreenContext.Provider>
  );
};

FullScreenProvider.propTypes = {
  children: PropTypes.node.isRequired,
};