export const HTTP_REQUEST_METHOD = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

export const HTTP_REQUEST_STATUS = {
  SUCCESS: 'SUCCESS',
  UNAUTHORIZED: 'UNAUTHORIZED',
  FORBIDDEN: 'FORBIDDEN',
  FAILED: 'FAILED',
  CONNECTION_ERROR: 'CONNECTION_ERROR',
  ABORTED: 'ABORTED',
};

export const DEFAULT_HTTP_REQUEST_HEADERS = {
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
};

export const DEFAULT_HTTP_REQUEST_WITH_JSON_BODY_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};
