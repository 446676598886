import _isEmpty from 'lodash/isEmpty';
import { FILTER_GROUP_TYPES, FILTER_TYPES } from '../../../api/restCollectionApi';
import { NAME_FIELD_DEFAULT_SORT_BY } from '../../../constants/entities';
import { USER_MODEL } from '../../../constants/models';
import { createCaEntitiesAutocompleteLoadOptionsActionCreator } from '../../../reducers/autocomplete/actions';

/**
 * Когда пользователь вводит имя, он может ввести:
 *  - Фамилия Имя Отчество
 *  - Отчество Фамилия Имя
 *  - Имя Фамилия Отчество
 *  - и т.д.
 * Поэтому для autocomplete-ов с вводом имен необходимо подготавливать фильтр, который будет искать каждую из
 * введенных частей имени в каждом из столбцов таблицы.
 *
 * Для простоты разбиваем строку на подстроки пробелами.
 *
 * Если введено одно слово, то оно должно быть найдено в любом из столбцов.
 * Если введено два слова, то оба этих слова должны быть найдены в любом из столбцов, но должны быть найдены оба слова.
 * Т.е.:
 * - >"Иван" будет искаться в столбцах: Фамилия Имя Отчество
 * - >"Иван Иванов" будет искаться: "Иван" в столбцах Фамилия Имя Отчество, "Иванов" в столбцах Фамилия Имя Отчество,
 *    если найдено только одно из слов, то результат не подходит.
 *
 * Возможные проблемы:
 *  Вводим "Иван Иванов", нам выдаст:
 *  - Иванов Иван Иванович
 *  - Петров Иван Иванович
 *  Петров будет выдан, т.к. в "Иванович" есть часть "Иванов". Возможны лишние результаты. Но в данном случае (когда
 *  разбиваем строку по пробелам, а не используем отдельные поля ввода для каждого столбца) такое вряд ли можно избежать.
 * */

export const USERS_AUTOCOMPLETE_FIELDS = {
  LAST_NAME: 'lastName',
  NAME: 'name',
  PATRONYMIC_NAME: 'patronymicName',
  NOTE: 'note',
};

export const USERS_AUTOCOMPLETE_STATUSES = {
  ACTIVE: 'active',
  DEACTIVATED: 'deactivated',
  ALL: 'all',
};

const getFilterForNamePart = (part, fields) => {
  const filters = fields.map(field => (
    {
      column: field,
      filterType: FILTER_TYPES.CONTAINS,
      filterValue: part,
    }
  ));

  return {
    filterGroupType: FILTER_GROUP_TYPES.OR,
    filters,
  };
};

const getUserAutocompleteFilters = (inputValue, fields) => {
  const inputParts = inputValue
    .trim()
    .split(' ')
    .filter(value => !!value);

  return inputParts && inputParts.length > 1 ?
  {
    filterGroupType: FILTER_GROUP_TYPES.AND,
    filters: inputParts.map(part => getFilterForNamePart(part, fields)),
  } :
    getFilterForNamePart(inputValue, fields);
};

const USER_AUTOCOMPLETE_LOAD_OPTIONS_SORT_BY = [
  {
    column: 'lastName',
    params: [
      {
        key: 'asc',
        value: true,
      },
    ],
  },
  ...NAME_FIELD_DEFAULT_SORT_BY,
];

export const USERS_AUTOCOMPLETE_PREDEFINED_FILTERS = {
  [USERS_AUTOCOMPLETE_STATUSES.ACTIVE]: {
    filterGroupType: FILTER_GROUP_TYPES.AND,
    filters: [
      {
        column: 'disabled',
        filterType: FILTER_TYPES.EQUALS,
        filterValue: false,
      },
    ],
  },
  [USERS_AUTOCOMPLETE_STATUSES.DEACTIVATED]: {
    filterGroupType: FILTER_GROUP_TYPES.AND,
    filters: [
      {
        column: 'disabled',
        filterType: FILTER_TYPES.EQUALS,
        filterValue: true,
      },
    ],
  },
  [USERS_AUTOCOMPLETE_STATUSES.ALL]: {},
};

const createUserAutocompleteGetRequestQuery = (
  fields,
  status,
) => inputValue => {
  const predefinedFilter = USERS_AUTOCOMPLETE_PREDEFINED_FILTERS[status];

  if(!inputValue) {
    const query = { sortBy: USER_AUTOCOMPLETE_LOAD_OPTIONS_SORT_BY };

    if(!_isEmpty(predefinedFilter)) {
      query.filter = predefinedFilter;
    }

    return query;
  }

  const inputFilters = getUserAutocompleteFilters(inputValue, fields);
  const filters = !_isEmpty(predefinedFilter) ?
    [predefinedFilter, inputFilters] : [inputFilters];

  return {
    filter: {
      filterGroupType: FILTER_GROUP_TYPES.AND,
      filters,
    },
    sortBy: USER_AUTOCOMPLETE_LOAD_OPTIONS_SORT_BY,
  };
};

export const createCaUsersAutocompleteLoadOptionsActionCreator = (
  {
    usersStatus,
    fields,
    getRequestOptions,
    prepareOptions,
    optionsModel,
  },
) => {
  const getRequestQuery = createUserAutocompleteGetRequestQuery(fields, usersStatus);

  return createCaEntitiesAutocompleteLoadOptionsActionCreator(
    {
      requestModel: USER_MODEL,
      getRequestQuery,
      getRequestOptions,
      prepareOptions,
      optionsModel,
    },
  );
};
