import { AutocompleteContainer } from '../../common/Autocomplete/AutocompleteContainer';
import { getEntityCombinedName } from '@bfg-frontend/utils/lib/stringBuilders/entity';
import { NoDataLabelTrans, NotSelectedLabelTrans } from '../../../utils/commonTransComponents';
import { createCaEntitiesAutocompleteLoadOptionsActionCreator } from '../../../reducers/autocomplete/actions';
import {
  ENTITY_MODEL,
} from '../../../constants/models';
import { FILTER_GROUP_TYPES, FILTER_TYPES } from '../../../api/restCollectionApi';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { CODE_FIELD_DEFAULT_SORT_BY, ENTITY_GROUP, NAME_FIELD_DEFAULT_SORT_BY } from '../../../constants/entities';
import _size from 'lodash/size';


const WITHOUT_MATERIALS_FILTER = {
  column: 'group',
  filterType: FILTER_TYPES.NOT_EQUALS,
  filterValue: ENTITY_GROUP.NOT_PRODUCIBLE_WITHOUT_SPECIFICATION,
};

/*
* Для опций автокомплита используется getEntityFullName, поэтому сортируем опции при запросе аналогично тому, как
* в лейбле комбинируются поля, сначала code, потом name. Сортировка множественная, потому что code неуникальное поле.
* По идее, имя у ДСЕ тоже, вроде бы, не уникальное согласно БД, но, кажется, что довольно редко у ДСЕ в реальности
* будут уникальные наименования и добавлять сортировку по третьему полю identity, кажется, излишним
* */
export const ENTITY_MODEL_AUTOCOMPLETE_LOAD_OPTIONS_SORT_BY =
  CODE_FIELD_DEFAULT_SORT_BY.concat(NAME_FIELD_DEFAULT_SORT_BY);

const _getEntityModelAutocompleteLoadOptionsActionCreator = (withMaterials, prepareOptions) =>
  createCaEntitiesAutocompleteLoadOptionsActionCreator({
    requestModel: ENTITY_MODEL,
    prepareOptions,
    getRequestQuery: inputValue => {

      let filters = [];

      if(!withMaterials) {
        filters.push(WITHOUT_MATERIALS_FILTER);
      }

      if (!inputValue) {

        let query = {
          sortBy: ENTITY_MODEL_AUTOCOMPLETE_LOAD_OPTIONS_SORT_BY,
        };

        if(_size(filters) > 0) {
          query.filter = {
            filterGroupType: FILTER_GROUP_TYPES.AND,
            filters,
          };
        }

        return query;
      }

      const entityFieldsFilterGroup = {
        filterGroupType: FILTER_GROUP_TYPES.OR,
        filters: [
          {
            column: 'identity',
            filterType: FILTER_TYPES.CONTAINS,
            filterValue: inputValue,
          },
          {
            column: 'name',
            filterType: FILTER_TYPES.CONTAINS,
            filterValue: inputValue,
          },
          {
            column: 'code',
            filterType: FILTER_TYPES.CONTAINS,
            filterValue: inputValue,
          },
        ],
      };

      return {
        filter: _size(filters) > 0 ?
          ({
            filterGroupType: FILTER_GROUP_TYPES.AND,
            filters: [
              ...filters,
              entityFieldsFilterGroup,
            ],
          }) :
          entityFieldsFilterGroup,
        sortBy: ENTITY_MODEL_AUTOCOMPLETE_LOAD_OPTIONS_SORT_BY,
      };
    },
  });

export const EntityModelAutocomplete = props =>  {

  const {
    shouldLoadOptions,
    prepareOptions,
    withMaterials,
  } = props;

  const loadOptionsActionCreator = useMemo(
    () => {
      if(!shouldLoadOptions) {
        return undefined;
      }

      return _getEntityModelAutocompleteLoadOptionsActionCreator(withMaterials, prepareOptions);
    },
    [shouldLoadOptions, withMaterials, prepareOptions],
  );

  return (
    <AutocompleteContainer
        {...props}
        getOptionLabel={getEntityCombinedName}
        loadOptionsActionCreator={loadOptionsActionCreator}
        placeholder={NotSelectedLabelTrans}
        noOptionsMessage={NoDataLabelTrans}
    />
  );
};

EntityModelAutocomplete.defaultProps = {
  withMaterials: false,
};

EntityModelAutocomplete.propTypes = {
  shouldLoadOptions: PropTypes.bool,
  prepareOptions: PropTypes.func,
  withMaterials: PropTypes.bool,
};