import { combineReducers } from 'redux';

import { connectRouter } from 'connected-react-router';

import { blockingAsyncAction } from './blockingAsyncAction/reducer';
import { appState } from './appState/reducer';
import { entities } from './entities/reducer';

import { plannerAppReducer } from './plannerApp/reducer';
import { masterAppReducer } from './masterApp/reducer';
import { workerAppReducer } from './workerApp/reducer';
import { workerTasksTableSettings } from './workerTasksTableSettings/reducer';
import { filters } from './filters/reducer';
import { storageManagementAppReducer } from './storageManagementApp/reducer';
import { schemaModelReducer } from './schemaModel/reducer';
import { tableReducer } from './table/reducer';
import { autocompleteReducer } from './autocomplete/reducer';
import { RE_INIT_REDUX_STATE } from './actions';
import { collapsibleReducer } from './collapsible/reducer';
import { sheetOperationReviewReducer } from './sheetOperationReview/reducer';

import _get from 'lodash/get';
import _set from 'lodash/set';


const crateAppReducer = history => {

  const combinedReducer = combineReducers({
    router: connectRouter(history),
    blockingAsyncAction,
    appState,
    entities,
    workerApp: workerAppReducer,
    plannerApp: plannerAppReducer,
    masterApp: masterAppReducer,
    storageManagementApp: storageManagementAppReducer,
    workerTasksTableSettings,
    filters,
    schemaModel: schemaModelReducer,
    tables: tableReducer,
    autocomplete: autocompleteReducer,
    collapsible: collapsibleReducer,
    sheetOperationReview: sheetOperationReviewReducer,
  });

  return (state, action) => {

    /*
    * Дополнительная обработка специального экшена для того, чтобы иметь возможность сбросить все данные в redux
    * state на дефолтные т.е. на те, которые были при начальной инициализации redux state. При помощи параметра
    * action.stateKeysToPreservePaths задаются пути до ключей state, по которым сбрасывать данные на дефолтные не нужно.
    * Логика в том, что первым параметром в общий редьюсер отправляется не текущий state, как это бывает обычно, а
    * объект, в котором присутствуют только данные по ключам action.stateKeysToPreservePaths или пустой объект, если
    * нужно сбросить все данные и action.stateKeysToPreservePaths не задан. Таким образом, каждый отдельный редьюсер,
    * который обрабатывает ключ в общем state, который нужно сбросить, получит в себя первым параметром undefined и
    * поэтому в своей обработке будет использовать дефолтное значение state, которое для него было определено. И т.к.
    * этот специальный тип экшена в самих отдельных редьюсерах не обрабатывается, то, в результате вызова такого экшена,
    * все такие редьюсеры для своих ключей вернут дефолтные значения. Принцип аналогичен тому, как redux state
    * заполняется дефолтными значениями при инициализации store в функции createStore самим redux'ом, за исключением,
    * что здесь даётся возможность определить ключи, сбрасывать которые ну нужно (в createStore у redux главный
    * редьюсер вызывается просто с undefined, даже не с объектом)
    *
    * ВАЖНО:
    *  в текущей реализации можно задавать пути только для сохранения ключей combineReducers, т.е. каких-то редъюсеров
    * целиком, но не для части ключей одного редъюсера.
    *
    * Например, верхний уровень хранилища приложения - ключи объекта, переданного в combineReducers, их можно указывать
    * в stateKeysToPreservePaths.
    * [workerApp, tasksMainFilters] - также ключ объекта, переданного в combineReducers у workerApp, поэтому также может
    * быть использован в stateKeysToPreservePaths
    * [workerApp, tasksMainFilters, equipmentClass] - equipmentClass уже поле в редъюсере, но не ключ в combineReducers,
    * поэтому такое использование уже недопустимо, т.к. остальная часть начального состояния редъюсера tasksMainFilters
    * в этом случае неизвестна
    *
    * TODO
    *  чтобы обеспечить необходимую гибкость сохранения ключей при логауте нужно дорабатывать логику используя данные
    *  о начальном состоянии приложения, чтобы формировать корректное итоговое состояние с учетом сохраняемых данных.
    *  Например, можно добавить колбэк, который принимает начальное состояние и "сохраняемое" состояние,
    *  и потом объединяет их по какой-то логике и возвращает необходимое итоговое состояние
    * */
    if(action.type === RE_INIT_REDUX_STATE) {
      const preservedState = action.stateKeysToPreservePaths
        .reduce(
          (acc, stateKeyToPreservePath) => {
            const stateValueToPreserve = _get(state, stateKeyToPreservePath);

            _set(acc, stateKeyToPreservePath, stateValueToPreserve);
            return acc;
          },
          {},
        );

      return combinedReducer(preservedState, action);
    }

    return combinedReducer(state, action);
  };

};


export default crateAppReducer;
