import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import _isNil from 'lodash/isNil';
import cn from 'classnames';
import { GRID_VIEW_INFO_ROW_GRID_PROPS_TYPE } from './GridViewInfoRow.types';
import { MATERIAL_UI_TOOLTIP_PLACEMENT } from '../../constants/materialUI';
import './style.css';
import { Tooltip } from '@mui/material';


export const GridViewInfoRow = ({
  wrapperClassName,
  labelClassName,
  label,
  valueClassName,
  value,
  emptyValue,
  delimiter = ':',
  tooltipContent = null,
  tooltipPlacement = MATERIAL_UI_TOOLTIP_PLACEMENT.TOP,
  gridProps = {},
}) => {

  const {
    containerGridSpacing,
    labelItemGridBreakpoints,
    valueItemGridBreakpoints,
  } = gridProps;

  const valueMarkup = (
    <Grid
        item
        className={
          cn(
            'grid-view-info-row__value',
            valueClassName,
          )
        }
        {...valueItemGridBreakpoints}
    >
      {_isNil(value) ? emptyValue : value}
    </Grid>
  );

  return (
    <Grid
        container
        className={
          cn(
            'grid-view-info-row',
            wrapperClassName,
          )
        }
        spacing={containerGridSpacing}
    >
      <Grid
          item
          className={
            cn(
              'grid-view-info-row__label',
              labelClassName,
            )
          }
          {...labelItemGridBreakpoints}
      >
        {label}
        {delimiter}
      </Grid>
      {
        tooltipContent ?
          <Tooltip
              className="grid-view-info-row__value-tooltip"
              title={tooltipContent}
              placement={tooltipPlacement}
              classes={{
                popper: 'grid-view-info-row__tooltip-popper',
                tooltip: 'grid-view-info-row__tooltip',
              }}
          >
            {valueMarkup}
          </Tooltip> :
          valueMarkup
      }
    </Grid>
  );
};

GridViewInfoRow.propTypes = {
  wrapperClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  label: PropTypes.node.isRequired,
  valueClassName: PropTypes.string,
  value: PropTypes.node.isRequired,
  emptyValue: PropTypes.node,
  delimiter: PropTypes.string,
  gridProps: GRID_VIEW_INFO_ROW_GRID_PROPS_TYPE.isRequired,
  tooltipContent: PropTypes.node,
  tooltipPlacement: PropTypes.oneOf(Object.values(MATERIAL_UI_TOOLTIP_PLACEMENT)),
};
