import {
  fetchEntitiesFromServer,
} from '../reducers/entities/actions';

import { FILTER_GROUP_TYPES, FILTER_TYPES, transformLimitParams } from '../api/restCollectionApi/index';

import {
  SHEET_TYPE_COMPLETED_REQUEST_FILTERS,
  SHEET_OPERATION_STATUS,
  SHEETS_DEFAULT_REQUEST_OPTIONS,
  SHEET_TYPE_IN_PRODUCTION_REQUEST_FILTERS,
  SHEET_TYPE_DEFAULT_WAITING_PARTS_AND_MATERIALS_REQUEST_FILTERS,
  SHEET_TYPE_PAUSED_REQUEST_FILTERS,
  ENTITY_BATCH_STATUS,
  SHEET_TYPE_INAPPROPRIATE_REQUEST_FILTERS,
  ENTITY_BATCH_TRANSACTION_TYPE,
  SHEET_TYPE_DEFECT_REQUEST_FILTERS,
} from '../constants/sheets';
import {
  DEPARTMENT_MODEL,
  SHEET_MODEL,
  EQUIPMENT_CLASS_MODEL,
  OPERATION_MODEL, ENTITY_BATCH_MODEL,
  ORDER_MODEL,
  ENTITY_MODEL,
  USER_MODEL, SHEET_OPERATION_MODEL,
} from '../constants/models';

import React from 'react';
import _partial from 'lodash/partial';
import _isNil from 'lodash/isNil';
import _get from 'lodash/get';
import { NOTIFICATION_LEVEL, sendNotification } from '../constants/notification';
import { getPartsAndMaterialsForAssemblyEntityBatchDataPoint, SERVER_ACTION_POINT } from '../constants/serverActions';
import { fetchDataFromServerDataPoint, sendActionToServer } from '../api/index';
import {
  SHEET_OPERATION_TO_PAUSE_ERRORS,
  SHEET_OPERATION_TO_START_ERRORS,
  SHEET_OPERATION_TO_CONTINUE_ERRORS,
  SHEET_OPERATION_TO_FINISH_ERRORS,
  SHEET_TO_FINISH_ERRORS,
  PAUSE_SHEET_ERRORS,
  RESUME_SHEET_ERRORS,
  SPLIT_ENTITY_BATCH_ERRORS,
  MARK_DEFECTIVE_ENTITIES_ERRORS,
  MARK_SHEET_INAPPROPRIATE_STATE_UNCONFIRMED_ERRORS,
  MARK_SHEET_FINAL_DEFECTIVE_STATE_CONFIRMED_ERRORS,
} from '../api/requestHandlers/errorHandlers/errorMaps';
import { getErrorMessage, showError } from '../api/requestHandlers/errorHandlers/errorHandlers';
import { broadcastEventMessage } from '../api/socketApi/broadcastApi/broadcast/broadcastEventMessage';
import {
  SHEET_FINISHED_EVENT_TYPE,
  PARTS_AND_MATERIALS_FOR_DEFAULT_SHEET_CONSUMED_EVENT_TYPE,
  SHEET_OPERATION_STATUS_CHANGED_EVENT_TYPE,
  SHEET_PAUSED_EVENT_TYPE,
  SHEET_RESUMED_EVENT_TYPE,
  ENTITY_BATCH_SPLIT_EVENT_TYPE,
  PARTS_AND_MATERIALS_FOR_ASSEMBLY_SHEET_RESERVED_EVENT_TYPE,
  ASSEMBLY_SHEET_WAS_SEND_TO_PRODUCTION_EVENT_TYPE,
  PARTS_AND_MATERIALS_FOR_ASSEMBLY_SHEET_CONSUMED_EVENT_TYPE,
  DEFECTIVE_ENTITIES_MARKED_EVENT_TYPE,
  SHEET_INAPPROPRIATE_STATE_UNCONFIRMED_EVENT_TYPE,
  SHEET_FINAL_DEFECTIVE_STATE_CONFIRMED_EVENT_TYPE,
} from '../constants/sockets';
import {
  setDefaultSheetPartsAndMaterialsToConsume,
  setDefaultSheetsIdsWithEnoughPartsAndMaterials,
} from '../reducers/storageManagementApp/defaultSheets/actions';
import { SERVER_DATA_POINT } from '../constants/serverDataPoints';
import { partsAndMaterialsToConsumeForDefaultSheetSelector } from '../reducers/storageManagementApp/defaultSheets/selectors';
import { RELATED_MODEL_FIELD_DELIMITER } from '../constants/magics';
import { fetchSheetsOperationsRemoteTableEntities } from './sheetOperations/index';
import { setAssemblySheetReserveData } from '../reducers/storageManagementApp/assemblySheets/reserveData/actions';
import { setAssemblySheetConsumeData } from '../reducers/workerApp/assemblySheets/consumeData/actions';
import { Trans } from '@lingui/macro';
import _keyBy from 'lodash/keyBy';
import {
  fetchSheetTypeToReviewAndPrepareReviewDataCbFactory,
} from '../components/Sheets/SheetsContainer';
import {
  getDefectiveSheetsTransactionsDataFromEntitiesById,
  getInappropriateSheetsTransactionsDataFromEntitiesById,
  getSheetDataFromEntitiesByIdWithTransactions,
} from '../selectors/sheets';
import _size from 'lodash/size';


const fetchSheetTypeEntities = (
  sheetTypeFilters,
  withCurrentSheetOperationDataRequest = false,
  queryParams = {},
  requestOptions = {},
) =>
  dispatch => {

    const query = {
      ...queryParams,
      filter: getQueryFilterWithSheetTypeFilters(
        queryParams.filter,
        sheetTypeFilters,
      ),
    };

    const options = {
      ...SHEETS_DEFAULT_REQUEST_OPTIONS,
      ...requestOptions,
    };

    return dispatch(fetchEntitiesFromServer(
      SHEET_MODEL,
      query,
      options,
    ))
      .then(response => {
        if(!response) return;

        if(!withCurrentSheetOperationDataRequest)
          return response;

        if(response.responseMeta.count === 0)
          return {
            ...response,
            currentSheetOperationDataBySheetId: {},
          };

        const {
          responseEntitiesIds,
          entities,
          responseMeta,
        } = response;

        const currentSheetOperationDataRequestSheetIds = responseEntitiesIds[SHEET_MODEL];

        const isBlockingRequest = options.isBlockingRequest;

        return dispatch(fetchDataFromServerDataPoint(
          SERVER_DATA_POINT.CURRENT_SHEET_OPERATION_DATA,
          {
            id: currentSheetOperationDataRequestSheetIds,
          },
          {
            ...CURRENT_SHEET_OPERATION_DATA_REQUEST_OPTIONS,
            isBlockingRequest,
          },
        ))
          .then(({ data }) => ({
            responseEntitiesIds,
            responseMeta,
            entities: {
              ...entities,
              currentSheetOperationDataBySheetId: data
                .reduce(
                  (acc, { id, data }) => {
                    acc[id] = data;
                    return acc;
                  },
                  {},
                ),
            },
          }));
      });
  };

const getQueryFilterWithSheetTypeFilters = (filterFromQueryParams, predefinedFilters) => ({
  filterGroupType: FILTER_GROUP_TYPES.AND,
  filters: filterFromQueryParams ?
    [...predefinedFilters, filterFromQueryParams] :
    predefinedFilters,
});

const CURRENT_SHEET_OPERATION_DATA_REQUEST_OPTIONS = {
  showServerError: false,
};

export const fetchSheetsInProduction =
  _partial(fetchSheetTypeEntities, SHEET_TYPE_IN_PRODUCTION_REQUEST_FILTERS, true);

export const fetchPausedSheets =
  _partial(fetchSheetTypeEntities, SHEET_TYPE_PAUSED_REQUEST_FILTERS, true);

export const fetchCompletedSheets =
  _partial(fetchSheetTypeEntities, SHEET_TYPE_COMPLETED_REQUEST_FILTERS, false);


const createQualityControlFetchSheetTypEntitiesActionCreator = ({
  sheetTypeRequestFilters,
  withCurrentSheetOperationDataRequest = false,
  transactionTypesToRequest = [],
}) =>
  (query, requestOptions) =>
    dispatch => {
      return dispatch(fetchSheetTypeEntities(sheetTypeRequestFilters, withCurrentSheetOperationDataRequest, query, requestOptions))
        .then(response => {
          if(!response || response.responseMeta.count === 0 || transactionTypesToRequest.length === 0) return response;

          const {
            responseEntitiesIds = {},
            entities = {},
            responseMeta,
          } = response;

          const entityBatchEntitiesIdsToRequestTransactionsData = responseEntitiesIds[ENTITY_BATCH_MODEL] || [];

          if (!_size(entityBatchEntitiesIdsToRequestTransactionsData) || !_size(transactionTypesToRequest)) {
            return response;
          }

          const isBlockingRequest = _get(requestOptions, 'isBlockingRequest');

          return dispatch(fetchDataFromServerDataPoint(
            SERVER_DATA_POINT.LAST_ENTITY_BATCH_TRANSACTION,
            {
              entityBatchId: entityBatchEntitiesIdsToRequestTransactionsData,
              type: transactionTypesToRequest,
            },
            {
              showServerError: false,
              isBlockingRequest,
            },
          ))
            .then(response => {
              const {
                data: entityBatchTransactionModelEntitiesArray,
                [USER_MODEL]: userModelEntitiesArray,
              } = response;

              return {
                responseEntitiesIds,
                responseMeta,
                entities: {
                  ...entities,
                  [USER_MODEL]: _keyBy(userModelEntitiesArray, 'id'),
                  entityBatchTransactionsByEntityBatchId: entityBatchTransactionModelEntitiesArray
                    .reduce(
                      (acc, transactionEntity) => {
                        const {
                          entityBatchId,
                          type,
                        } = transactionEntity;

                        acc[entityBatchId] = {
                          ...(acc[entityBatchId] || {}),
                          [type]: transactionEntity,
                        };

                        return acc;
                      },
                      {},
                    ),
                },
              };
            });
        });
    };

export const fetchInappropriateSheets = createQualityControlFetchSheetTypEntitiesActionCreator({
  sheetTypeRequestFilters: SHEET_TYPE_INAPPROPRIATE_REQUEST_FILTERS,
  transactionTypesToRequest: [ENTITY_BATCH_TRANSACTION_TYPE.MARKED_AS_INAPPROPRIATE],
});

export const fetchDefectiveSheets = createQualityControlFetchSheetTypEntitiesActionCreator({
  sheetTypeRequestFilters: SHEET_TYPE_DEFECT_REQUEST_FILTERS,
  transactionTypesToRequest: [
    ENTITY_BATCH_TRANSACTION_TYPE.MARKED_AS_DEFECTIVE,
    ENTITY_BATCH_TRANSACTION_TYPE.MARKED_AS_INAPPROPRIATE,
  ],
});

export const fetchInappropriateSheetData = (sheetId, fetchSheetTypeEntitiesActionCreator, dispatch) => {
  const fetchSheetTypeToReviewAndPrepareReviewDataCb = fetchSheetTypeToReviewAndPrepareReviewDataCbFactory(
    dispatch,
    fetchSheetTypeEntitiesActionCreator,
    getPrepareSheetTypeToReviewDataCb(getInappropriateSheetsTransactionsDataFromEntitiesById),
  );

  return fetchSheetTypeToReviewAndPrepareReviewDataCb(sheetId);
};

export const fetchDefectiveSheetData = (sheetId, fetchSheetTypeEntitiesActionCreator, dispatch) => {
  const fetchSheetTypeToReviewAndPrepareReviewDataCb = fetchSheetTypeToReviewAndPrepareReviewDataCbFactory(
    dispatch,
    fetchSheetTypeEntitiesActionCreator,
    getPrepareSheetTypeToReviewDataCb(getDefectiveSheetsTransactionsDataFromEntitiesById),
  );

  return fetchSheetTypeToReviewAndPrepareReviewDataCb(sheetId);
};

const getPrepareSheetTypeToReviewDataCb = getEntityBatchTransactionsDataFromEntities =>
  (sheetId, response) => {
    const {
      entities: {
        [SHEET_MODEL]: sheetEntities = {},
        [ENTITY_BATCH_MODEL]: entityBatchEntities = {},
        [SHEET_OPERATION_MODEL]: sheetOperationEntities = {},
        [ENTITY_MODEL]: entityModelEntities = {},
        [ORDER_MODEL]: orderEntities = {},
        [USER_MODEL]: userEntities = {},
        entityBatchTransactionsByEntityBatchId,
      },
    } = response;

    return getSheetDataFromEntitiesByIdWithTransactions({
      sheetId,
      sheetEntities,
      entityBatchEntities,
      sheetOperationEntities,
      entityModelEntities,
      orderEntities,
      userEntities,
      entityBatchTransactionsByEntityBatchId,
      getEntityBatchTransactionsDataFromEntities,
    });
  };

/*
* TODO
*  состыковать с бэкендом:
*  - адреса точек выполнения действий возврата МЛ в работу и списания в окончательный брак
*  - форматы отправляемых данных
*  - ошибки выполнения запросов на эти точки
*  - форматы броадкастинга событий и название этих событий
*  - тексты всплывающих уведомлений
* */
const MARK_SHEET_INAPPROPRIATE_STATE_UNCONFIRMED_REQUEST_OPTIONS = {
  showServerError: false,
  isBlockingRequest: true,
};

export const markSheetInappropriateStateUnconfirmed = entityBatchId =>
  dispatch =>
    dispatch(sendSheetInappropriateStateUnconfirmedActionOnServer(entityBatchId, MARK_SHEET_INAPPROPRIATE_STATE_UNCONFIRMED_REQUEST_OPTIONS))
      .catch(({ response, status }) => {
        if(!response) return Promise.reject(status);

        const errorMsg = getErrorMessage(response, MARK_SHEET_INAPPROPRIATE_STATE_UNCONFIRMED_ERRORS);
        showError(errorMsg);
        return Promise.reject({ response, status });
      });

const sendSheetInappropriateStateUnconfirmedActionOnServer = (entityBatchId, requestOptions = {}) =>
  dispatch =>
    dispatch(sendActionToServer(
      SERVER_ACTION_POINT.MARK_SHEET_INAPPROPRIATE_STATE_UNCONFIRMED,
      {
        data: {
          entityBatchId,
        },
      },
      { ...requestOptions },
    ));

export const broadcastSheetInappropriateStateUnconfirmed = ({
  isOrderCompleted,
  changedOrderId,
  orderName,
  sheetId,
  sheetIdentity,
  entityBatchWasFinished,
}) =>
  broadcastEventMessage(
    SHEET_INAPPROPRIATE_STATE_UNCONFIRMED_EVENT_TYPE,
    {
      isOrderCompleted,
      changedOrderId,
      orderName,
      sheetId,
      sheetIdentity,
      entityBatchWasFinished,
    },
  );

export const sendSheetInappropriateStateUnconfirmedNotification = sheetIdentity =>
  sendNotification(
    <Trans id="inappropriate_sheet.mark_sheet_inappropriate_state_unconfirmed@notification">
      Работа по маршрутному листу "{sheetIdentity}" была успешно возобновлена
    </Trans>,
    NOTIFICATION_LEVEL.INFO,
  );

const MARK_SHEET_FINAL_DEFECTIVE_STATE_CONFIRMED_REQUEST_OPTIONS = {
  showServerError: false,
  isBlockingRequest: true,
};

export const markSheetFinalDefectiveStateConfirmed = entityBatchId =>
  dispatch =>
    dispatch(sendSheetFinalDefectiveStateConfirmedActionOnServer(entityBatchId, MARK_SHEET_FINAL_DEFECTIVE_STATE_CONFIRMED_REQUEST_OPTIONS))
      .catch(({ response, status }) => {
        if(!response) return Promise.reject(status);

        const errorMsg = getErrorMessage(response, MARK_SHEET_FINAL_DEFECTIVE_STATE_CONFIRMED_ERRORS);
        showError(errorMsg);
        return Promise.reject({ response, status });
      });

const sendSheetFinalDefectiveStateConfirmedActionOnServer = (entityBatchId, requestOptions = {}) =>
  dispatch =>
    dispatch(sendActionToServer(
      SERVER_ACTION_POINT.MARK_SHEET_FINAL_DEFECTIVE_STATE_CONFIRMED,
      {
        data: {
          entityBatchId,
        },
      },
      { ...requestOptions },
    ));

export const broadcastSheetFinalDefectiveStateConfirmed = (sheetId, sheetIdentity) =>
  broadcastEventMessage(
    SHEET_FINAL_DEFECTIVE_STATE_CONFIRMED_EVENT_TYPE,
    {
      sheetId,
      sheetIdentity,
    },
  );

export const sendSheetFinalDefectiveStateConfirmedNotification = sheetIdentity =>
  sendNotification(
    <Trans id="inappropriate_sheet.mark_sheet_final_defective_state_confirmed@notification">
      Маршрутный лист "{sheetIdentity}" был отправлен в окончательный брак
    </Trans>,
    NOTIFICATION_LEVEL.INFO,
  );



export const sendSheetStartedNotification = sheetIdentity =>
  sendNotification(
    <Trans id="sheet_to_start@sheet_started">
      В производство запущен маршрутный лист "{sheetIdentity}"
    </Trans>,
    NOTIFICATION_LEVEL.SUCCESS,
  );


export const sendStartedSheetsAmountNotification = (
  startedSheetsAmount,
  sheetsToStartAmount,
  notificationLevel = NOTIFICATION_LEVEL.INFO,
) => {

  const notificationText = (
    <Trans id="sheet_to_start@started_sheets_amount">
      {startedSheetsAmount} из {sheetsToStartAmount} маршрутных листов запущено в производство
    </Trans>
  );

  return sendNotification(
    notificationText,
    notificationLevel,
  );
};

const FINISH_SHEET_DEFAULT_REQUEST_OPTIONS = {
  showServerError: false,
  isBlockingRequest: true,
};
export const finishSheet = (sheetId, sheetIdentity) =>
  dispatch =>
    dispatch(sendSheetFinishActionOnServer(sheetIdentity, FINISH_SHEET_DEFAULT_REQUEST_OPTIONS))
      .catch(({ response, status }) => {
        if(!response) return Promise.reject(status);

        const errorMsg = getErrorMessage(response, SHEET_TO_FINISH_ERRORS);
        showError(errorMsg);
        return Promise.reject({ response, status });
      });

export const sendSheetFinishActionOnServer = (sheetIdentity, requestOptions = {}) =>
  dispatch =>
    dispatch(sendActionToServer(
      SERVER_ACTION_POINT.FINISH_SHEET,
      {
        data: {
          entityRouteSheetIdentity: sheetIdentity,
        },
      },
      { ...requestOptions },
    ));


export const sendSheetFinishedNotification = sheetIdentity =>
  sendNotification(
    <Trans id="sheet_in_production.finish_sheet@sheet_finished">
      Успешно завершен маршрутный лист "{sheetIdentity}"
    </Trans>,
    NOTIFICATION_LEVEL.INFO,
  );

const CHANGE_SHEET_OPERATION_STATUS_ACTION_DEFAULT_REQUEST_OPTIONS = {
  showServerError: false,
  isBlockingRequest: true,
};

export const broadcastSheetOperationStatusChanged = sheetOperationDataAfterStatusChange =>
  broadcastEventMessage(
    SHEET_OPERATION_STATUS_CHANGED_EVENT_TYPE,
    { sheetOperationDataAfterStatusChange },
  );

export const broadcastSheetFinished = (sheetId, sheetIdentity, orderId, orderName, isOrderCompleted) =>
  broadcastEventMessage(
    SHEET_FINISHED_EVENT_TYPE,
    {
      sheetId,
      sheetIdentity,
      orderId,
      orderName,
      isOrderCompleted,
    },
  );

const sendSheetOperationStatusChangeActionOnServerCbFactory = (
  operationStatus,
  requestErrorsMap,
  setUserAsSheetOperationAssignee,
) =>
  (
    sheetIdentity,
    operationNumber,
    operationProgress,
    requestOptions = {},
  ) =>
    dispatch => {

      /*
      * Для запроса изменения статуса важно, чтобы прогресс по операции отправлялся только в определенных случаях.
      * Это важно, т.к. у самой точки есть своя внутренняя логика и если отправить прогресс, когда это не ожидается, то
      * эта логика может нарушиться. Внутренняя логика ориентируется именно на наличие ключа прогресса в теле запроса,
      * поэтому для случая, когда прогресс отправлять не требуется, нужно именно, чтобы в теле не было такого ключа.
      * В данный момент, отправлять прогресс нужно только для случая, когда статус меняется на "пауза", т.е. когда
      * операция приостанавливается. В теории, это может измениться, поэтому эта конкретная логика, когда нужно
      * отправлять прогресс, не реализуется в самом экшене. Вместо этого реализуется более общая логика, что если
      * аргумент operationProgress не задан (null или undefined), то мы его и не отправляем. Это даёт возможность
      * управлять тем, нужно отправлять прогресс или нет, в том месте, где этот экшен вызывается, что удобнее.
      * В теории, если operationProgress = undefined, то этот ключ вырежется в низкоуровневой абстракции при
      * JSON.stringify, но хотелось явно обработать этот момент, т.к. логика здесь неочевидная и нужно иметь её в виду.
      * Заодно с этим, на всякий случай, обработан и null
      * */
      let requestData = {
        entityRouteSheetIdentity: sheetIdentity,
        operationNop: operationNumber,
        operationStatus,
      };

      if(!_isNil(operationProgress)) {
        requestData.operationProgress = operationProgress;
      }

      if(setUserAsSheetOperationAssignee === true) {
        requestData.addToExecutor = setUserAsSheetOperationAssignee;
      }

      return dispatch(sendActionToServer(
        SERVER_ACTION_POINT.CHANGE_SHEET_OPERATION_STATUS,
        {
          data: requestData,
        },
        {
          ...CHANGE_SHEET_OPERATION_STATUS_ACTION_DEFAULT_REQUEST_OPTIONS,
          ...requestOptions,
        },
      ))
        .catch(({ response, status }) => {
          if (!response) return Promise.reject(status);

          const errorMsg = getErrorMessage(response, requestErrorsMap);
          showError(errorMsg);
          return Promise.reject({ response, status });
        });
    };

const { SET_USER_AS_ASSIGNEE_ON_OPERATION_STATUS_CHANGE } = window.config;

export const startSheetOperation =
  sendSheetOperationStatusChangeActionOnServerCbFactory(
    SHEET_OPERATION_STATUS.IN_PRODUCTION,
    SHEET_OPERATION_TO_START_ERRORS,
    SET_USER_AS_ASSIGNEE_ON_OPERATION_STATUS_CHANGE,
  );

export const pauseSheetOperation =
  sendSheetOperationStatusChangeActionOnServerCbFactory(
    SHEET_OPERATION_STATUS.PAUSED,
    SHEET_OPERATION_TO_PAUSE_ERRORS,
  );

export const continueSheetOperation =
  sendSheetOperationStatusChangeActionOnServerCbFactory(
    SHEET_OPERATION_STATUS.IN_PRODUCTION,
    SHEET_OPERATION_TO_CONTINUE_ERRORS,
    SET_USER_AS_ASSIGNEE_ON_OPERATION_STATUS_CHANGE,
  );

export const finishSheetOperation =
  sendSheetOperationStatusChangeActionOnServerCbFactory(
    SHEET_OPERATION_STATUS.FINISHED,
    SHEET_OPERATION_TO_FINISH_ERRORS,
  );

export const markDefectiveEntities = formData =>
  dispatch => {
    const {
      sheetOperationId,
      sheetOperationStatus,
      notDefectivePartOperationProgress,
      defectivePartAmount,
      defectivePartOperationProgress,
      defectReason,
    } = formData;

    const data = {
      entityRouteSheetOperationId: sheetOperationId,
      operationStatus: sheetOperationStatus,
      defectiveEntityBatchDescription: defectReason,
      quantity: defectivePartAmount,
      defectiveEntityBatchOperationProgress: defectivePartOperationProgress,
      entityBatchOperationProgress: notDefectivePartOperationProgress,
    };

    return dispatch(sendActionToServer(
      SERVER_ACTION_POINT.MARK_DEFECTIVE_ENTITIES,
      { data },
      CHANGE_SHEET_OPERATION_STATUS_ACTION_DEFAULT_REQUEST_OPTIONS,
    ))
      .catch(({ response, status }) => {
        if (!response) return Promise.reject(status);

        const errorMsg = getErrorMessage(response, MARK_DEFECTIVE_ENTITIES_ERRORS);
        showError(errorMsg);
        return Promise.reject({ response, status });
      });
  };

export const broadcastDefectiveEntitiesMarked = ({
  entityBatchId,
  sheetOperationId,
  sheetOperationStatus,
  sheetId,
  completedOrderId,
  completedOrderName,
  wasEntityBatchSplit,
  wasEntityBatchFinished,
  sheetIdentity,
}) =>
  broadcastEventMessage(
    DEFECTIVE_ENTITIES_MARKED_EVENT_TYPE,
    {
      entityBatchId,
      sheetOperationId,
      sheetOperationStatus,
      sheetId,
      completedOrderId,
      completedOrderName,
      wasEntityBatchSplit,
      wasEntityBatchFinished,
      sheetIdentity,
    },
  );

export const sendDefectiveEntitiesMarkedNotification = sheetIdentity => sendNotification(
    <Trans id="sheet_operation_review.mark_defective_entities@defective_entities_marked">
      Отметка брака по партии маршрутного листа "{sheetIdentity}" успешно зарегистрирована
    </Trans>,
    NOTIFICATION_LEVEL.SUCCESS,
  );

export const sendSheetOperationStartedNotification = (sheetIdentity, sheetOperationCombinedName) =>
  sendNotification(
    <Trans id="sheet_operation_review.start_operation@operation_started">
      Отметка о начале работы по операции "{sheetOperationCombinedName}" маршрутного
      листа "{sheetIdentity}" успешно зарегистрирована
    </Trans>,
    NOTIFICATION_LEVEL.SUCCESS,
  );

export const sendSheetOperationPausedNotification = (sheetIdentity, sheetOperationCombinedName) =>
  sendNotification(
    <Trans id="sheet_operation_review.pause_operation@operation_paused">
      Отметка об остановке работы по операции "{sheetOperationCombinedName}" маршрутного
      листа "{sheetIdentity}" успешно зарегистрирована
    </Trans>,
    NOTIFICATION_LEVEL.SUCCESS,
  );

export const sendSheetOperationContinuedNotification = (sheetIdentity, sheetOperationCombinedName) =>
  sendNotification(
    <Trans id="sheet_operation_review.continue_operation@operation_continued">
      Отметка о возобновлении работы по операции "{sheetOperationCombinedName}" маршрутного
      листа "{sheetIdentity}" успешно зарегистрирована
    </Trans>,
    NOTIFICATION_LEVEL.SUCCESS,
  );

export const sendSheetOperationFinishedNotification = (sheetIdentity, sheetOperationCombinedName) =>
  sendNotification(
    <Trans id="sheet_operation_review.finish_operation@operation_finished">
      Отметка о завершении работы по операции "{sheetOperationCombinedName}" маршрутного
      листа "{sheetIdentity}" успешно зарегистрирована
    </Trans>,
    NOTIFICATION_LEVEL.SUCCESS,
  );


export const fetchDefaultSheetsWaitingPartsAndMaterials =
  _partial(fetchSheetTypeEntities, SHEET_TYPE_DEFAULT_WAITING_PARTS_AND_MATERIALS_REQUEST_FILTERS, false);


const DEFAULT_SHEETS_WITH_ENOUGH_PARTS_AND_MATERIALS_REQUEST_OPTIONS = {
  showServerError: false,
};

export const fetchDefaultSheetsWithEnoughPartsAndMaterialsInStorage = (requestOptions = {}) =>
  dispatch =>
    dispatch(fetchDataFromServerDataPoint(
      SERVER_DATA_POINT.DEFAULT_SHEETS_WITH_ENOUGH_PARTS_AND_MATERIALS,
      undefined,
      {
        ...DEFAULT_SHEETS_WITH_ENOUGH_PARTS_AND_MATERIALS_REQUEST_OPTIONS,
        ...requestOptions,
      },
    ))
      .then(({ data = [] }) => {
        const sheetIds = data.map(({ sheetId }) => sheetId);
        dispatch(setDefaultSheetsIdsWithEnoughPartsAndMaterials(sheetIds));
      })
      .catch(({ response, status }) => {
        if(!response) return Promise.reject(status);

        showError(
          <Trans id="default_sheets_waiting_parts_and_materials@sheets_with_enough_parts_and_materials_request_common_error">
            При определении возможности комплектации маршрутных листов произошла ошибка, данные не были получены.
            Попробуйте перезагрузить страницу. Если ошибка повторяется, то обратитесь к системному администратору
          </Trans>,
        );
        return Promise.reject({ response, status });
      });

const ASSEMBLY_WAITING_PARTS_AND_MATERIALS_REQUEST_FILTERS_MAP = {
  id: 'entityRouteSheetId',
  entityBatch__entityId: 'entityId',
  entityBatch__orderId: 'orderId',
};

const assemblySheetsWaitingPartsAndMaterialsTableFilterTransformer = filter => {
  if (!filter) return {};

  const { filters } = filter;

  if (!filters.length) return {};

  return filters.reduce((acc, { filterValue, column }) => {
    const requestFilterKey = ASSEMBLY_WAITING_PARTS_AND_MATERIALS_REQUEST_FILTERS_MAP[column];
    if (!requestFilterKey) return acc;
    acc[requestFilterKey] = filterValue;
    return acc;
  },
  {});
};

/*
* Action-creator запроса используется для абстракции SheetsContainer, в которой логика запроса и подготовки данных
* исторически была абстрагирована под запросы к API коллекций, поэтому, есть и предопределенные параметры связанных
* моделей и сортировки МЛ для API коллекций и используется абстрактный селектор, который ожидает данные в табличном
* стор в определенном формате.
*
* Наверно, логичным развитием было бы реализовать возможность задать кастомные параметры, а прошлую логику оставить
* "дефолтной", но пока это единственный такой случай и было решено не корректировать абстракцию, а привести и логику
* запроса и данные, которые сохраняются в стор, к формату текущей абстракции. Поэтому:
*  - данные по сортировке и связанным моделям, которые приходят в queryParams игнорируются, для данного частного
* запроса они есть в логике самого запроса
*  - данные по фильтрации и пейджингу в формате API коллекций преобразуются к формату точки
*  - после получения данных в формате точки они перерабатываются к формату моделей БД или в логике клиентского приложения
* к формату этих моделей для entities, т.е. в том виде, в котором это нужно селектору в SheetsContainer, и только после
* этого сохраняются в табличный store.
* */
export const fetchAssemblySheetsWaitingPartsAndMaterials = (queryParams = {}, options = {}) =>
  dispatch => {

    const {
      filter,
    } = queryParams;

    const transformedFilters = assemblySheetsWaitingPartsAndMaterialsTableFilterTransformer(filter);

    /*
    * Для сборочных МЛ логика сейчас такова, что полностью укомплектованными они становятся только после завершения.
    * При этом, даже, если бы это было не так, то до конца непонятно, нужно ли было их запрашивать и отображать.
    * Поэтому, пока не заморачиваемся с этим и не добавляем никакой фильтр по providingState, достаточно фильтра по
    * status.
    * Отдельно стоит отметить и другой кейс: у партии нет спецификации, но для неё задана сборочная операция. В этом
    * случае она считается сборочной (fullProvidingRequired = false), но такую партию, очевидно, не нужно учитывать
    * в "сборочном флоу". Наверно, для этого случая, всё-таки, самым логичным бы было, чтобы при запуске, несмотря на
    * наличие сборочный операций, fullProvidingRequired было бы равно true, т.к. это кажется логичным, но сейчас это
    * не так. Но, например, в случае запроса на эту точку, все партии без спецификации игнорируются, что нам и требуется,
    * поэтому тоже пока не задумываемся над какими-то дополнительными фильтрами. Но следует иметь это в виду, при
    * изменении логики работы приложения, возможно, тут нужны будут корректировки.
    * */
    const query = {
      fullProvidingRequired: false,
      status: [
        ENTITY_BATCH_STATUS.PAUSED,
        ENTITY_BATCH_STATUS.IN_PRODUCTION,
      ],
      ...transformedFilters,
    };

    const {
      start,
      stop,
    } = transformLimitParams(queryParams);

    if (!_isNil(start)) {
      query.start = start;
      query.stop = stop;
    }

    return dispatch(fetchDataFromServerDataPoint(
      SERVER_DATA_POINT.ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS,
      query,
      options,
    ))
      .then(({ data, meta }) => {

        if (_get(meta, 'count') === 0) return Promise.resolve({
          responseEntitiesIds: {},
          entities: {},
          responseMeta: { count: 0 },
        });

        const transformed = data.reduce((acc, curr) => {

          /*
          * Здесь идёт обработка полей, которая возвращает сама точка. Было решено не создавать отдельный трансформер
          * полей, потому что требуется более сложная логика преобразования, описанная в комментарии к action-
          * creator и не хочется разносить логику трансформирования по разным файлам, т.к. иначе при возвращении к коду
          * было бы сложнее понять, что за поля тут используются и почему они отличаются от полей ответа с точки, при
          * этом здесь выполняется ещё одно трансформирование. Т.е., если нужно добавить какое-то поле, то пришлось бы
          * корректировать и стандартный трансформер и корректировать логику здесь.
          *
          * Важно отметить, что точка возвращает больше полей, чем сейчас описано в коде, просто они не нужны в данный
          * момент для текущего функционала, в случае чего их можно добавить.
          *
          * Как описывалось в комментарии к action-creator, по сути, тут мы берем поля партии и МЛ из точки и преобразуем
          * их к полям моделей БД, которые используются стандартно в entities для дальнейшего использования в
          * SheetsContainer
          * */
          const {
            entityBatchId,
            entityBatchStatus,
            entityBatchProvidingState,
            entityBatchQuantity,
            entityBatchFromState,
            entityBatchProvidable,
            entityBatchEntityId,
            entityBatchOrderId,

            entityRouteSheetId,
            entityRouteSheetIdentity,
            entityRouteSheetNote,
            entityRouteSheetStartDate,
            entityRouteSheetStopDate,
            entityRouteSheetFullProvidingRequired,
          } = curr;

          acc.entityBatchIds.push(entityBatchId);
          acc.entityRouteSheetIds.push(entityRouteSheetId);

          acc[ENTITY_BATCH_MODEL][entityBatchId] = {
            id: entityBatchId,
            status: entityBatchStatus,
            providingState: entityBatchProvidingState,
            amount: entityBatchQuantity,
            fromState: entityBatchFromState,
            providableState: entityBatchProvidable,
            entityId: entityBatchEntityId,
            orderId: entityBatchOrderId,
          };

          acc[SHEET_MODEL][entityRouteSheetId] = {
            id: entityRouteSheetId,
            identity: entityRouteSheetIdentity,
            note: entityRouteSheetNote,
            startDate: entityRouteSheetStartDate,
            stopDate: entityRouteSheetStopDate,
            isAssembly: !entityRouteSheetFullProvidingRequired,
            entityBatchId,
          };

          return acc;
        },
          {
            [ENTITY_BATCH_MODEL]: {},
            [SHEET_MODEL]: {},
            entityBatchIds: [],
            entityRouteSheetIds: [],
          });

        return {
          responseEntitiesIds: {
            [ENTITY_BATCH_MODEL]: transformed.entityBatchIds,
            [SHEET_MODEL]: transformed.entityRouteSheetIds,
          },
          entities: {
            [ENTITY_BATCH_MODEL]: transformed[ENTITY_BATCH_MODEL],
            [SHEET_MODEL]: transformed[SHEET_MODEL],
          },
          responseMeta: meta,
        };
      })
      .catch(({ response, status }) => {
        if(!response) return Promise.reject(status);

        showError(
          <Trans id="assembly_sheets_waiting_parts_and_materials@assembly_sheets_waiting_parts_and_materials_request_common_error">
            При попытке формирования списка сборочных МЛ произошла ошибка, данные не были получены. Попробуйте
            перезагрузить страницу. Если ошибка повторяется, то обратитесь к системному администратору
          </Trans>,
        );
        return Promise.reject({ response, status });
      });
  };

const SEND_ASSEMBLY_SHEET_TO_PRODUCTION_REQUEST_DEFAULT_OPTIONS = {
  showServerError: false,
  isBlockingRequest: true,
};

export const sendAssemblySheetToProduction = ({ entityBatchId, sheetIdentity }, options = {}) =>
  dispatch => dispatch(sendActionToServer(
    SERVER_ACTION_POINT.SEND_SHEET_TO_PRODUCTION,
    {
      data: { entityBatchId },
    },
    {
      ...SEND_ASSEMBLY_SHEET_TO_PRODUCTION_REQUEST_DEFAULT_OPTIONS,
      ...options,
    },
  ))
    .then(response => {
      broadcastEventMessage(
        ASSEMBLY_SHEET_WAS_SEND_TO_PRODUCTION_EVENT_TYPE,
        { entityBatchId, sheetIdentity },
      );

      return response;
    })
    .catch(({ response, status }) => {
      if (!response) return Promise.reject(status);

      showError(
        <Trans id="assembly_sheet_parts_and_materials_reserving@send_sheet_to_production_request_common_error">
          При попытке передать МЛ в работу произошла ошибка. Попробуйте перезагрузить страницу. Если ошибка
          повторяется, то обратитесь к системному администратору
        </Trans>,
      );
      return Promise.reject({ response, status });
    });

export const sendAssemblySheetWasSendToProductionNotification = sheetIdentity =>
  sendNotification(
    <Trans id="assembly_sheet_parts_and_materials_reserving@assembly_sheet_was_send_to_production">
      МЛ "{sheetIdentity}" был передан в работу
    </Trans>,
    NOTIFICATION_LEVEL.SUCCESS,
  );

const POSSIBLE_PARTS_AND_MATERIALS_TO_CONSUME_FOR_DEFAULT_SHEET_REQUEST_OPTIONS = {
  showServerError: false,
};
export const fetchPossiblePartsAndMaterialsToConsumeForDefaultSheet = (entityBatchId, sheetId, requestOptions = {}) =>
  (dispatch, getState) => {

    /*
    * Повторно не перезапрашиваем данные по возможной комплектации для стандартного МЛ, если они уже есть в сторе.
    * В логике везде должно учитываться, что стор должен очищаться, когда данные по возможной комплектации становятся
    * неактуальными, т.е. при наступлении различных событий, например, комплектации другого МЛ
    * */
    const partsAndMaterialsToConsumeForDefaultSheet =
      partsAndMaterialsToConsumeForDefaultSheetSelector(getState(), { sheetId });

    if(!!partsAndMaterialsToConsumeForDefaultSheet)
      return Promise.resolve(partsAndMaterialsToConsumeForDefaultSheet);

    return dispatch(fetchDataFromServerDataPoint(
      [SERVER_DATA_POINT.POSSIBLE_PARTS_AND_MATERIALS_TO_CONSUME_FOR_DEFAULT_SHEET, entityBatchId].join('/'),
      undefined,
      {
        ...POSSIBLE_PARTS_AND_MATERIALS_TO_CONSUME_FOR_DEFAULT_SHEET_REQUEST_OPTIONS,
        ...requestOptions,
      },
    ))
      .then(({ data }) => {
        const dataToConsume = data
          .map(({
            entityId,
            entityIdentity,
            entityCode,
            entityName,
            entityGroup,
            requiredQuantity,
            warehouseData,
          }) => ({
            entityId,
            entityIdentity,
            entityCode,
            entityName,
            entityGroup,
            requiredAmount: requiredQuantity,
            entityBatchesToConsume: warehouseData
              .map(({
                entityBatchId,
                entityBatchCalculationIdentity,
                entityRouteSheetId: sheetId,
                entityRouteSheetIdentity: sheetIdentity,
                consumedQuantity: amountToConsumeFromBatch,
                remainingQuantity: requiredEntityAmountAfterThisBatchConsuming,
              }) => ({
                entityBatchId,
                entityBatchCalculationIdentity,
                sheetId,
                sheetIdentity,
                amountToConsumeFromBatch,
                requiredEntityAmountAfterThisBatchConsuming,
              })),
          }));

        dispatch(setDefaultSheetPartsAndMaterialsToConsume(sheetId, dataToConsume));
        return dataToConsume;
      })
      .catch(({ response, status }) => {
        if(!response) return Promise.reject(status);

        showError(
          <Trans id="default_sheet_parts_and_materials_consuming@possible_parts_and_materials_to_consume_request_common_error">
            При определении возможности комплектации маршрутного листа произошла ошибка, данные не были получены.
            Попробуйте перезагрузить страницу. Если ошибка повторяется, то обратитесь к системному администратору
          </Trans>,
        );
        return Promise.reject({ response, status });
      });
  };



export const sendDefaultSheetPartsAndMaterialsConsumeActionOnServer = (dataToConsumeForRequest, requestOptions = {}) =>
  dispatch =>
    dispatch(sendActionToServer(
      SERVER_ACTION_POINT.CONSUME_PARTS_AND_MATERIALS_FOR_DEFAULT_SHEET,
      { data: dataToConsumeForRequest },
      { ...requestOptions },
    ));


const DEFAULT_SHEET_PARTS_AND_MATERIALS_CONSUME_ACTION_DEFAULT_REQUEST_OPTIONS = {
  showServerError: false,
  isBlockingRequest: true,
};
export const consumePartsAndMaterialsForDefaultSheet = (
  sheetId,
  sheetIdentity,
  entityBatchId,
  detailedDataToConsume,
) =>
  dispatch => {

    const dataToConsumeForRequest = {
      entityBatchId,
      consumeData: detailedDataToConsume.reduce(
        (entityBatchesToConsumeAcc, { entityBatchesToConsume }) =>
          entityBatchesToConsumeAcc.concat(
            entityBatchesToConsume
              .map(({ entityBatchId, amountToConsumeFromBatch }) => ({
                entityBatchId,
                consumedQuantity: amountToConsumeFromBatch,
              })),
          ),
        [],
      ),
    };

    return dispatch(sendDefaultSheetPartsAndMaterialsConsumeActionOnServer(
      dataToConsumeForRequest,
      DEFAULT_SHEET_PARTS_AND_MATERIALS_CONSUME_ACTION_DEFAULT_REQUEST_OPTIONS,
    ))
      .then(
        () => {
          broadcastEventMessage(
            PARTS_AND_MATERIALS_FOR_DEFAULT_SHEET_CONSUMED_EVENT_TYPE,
            { sheetId, sheetIdentity, entityBatchId },
          );
        },
        err => {
          showError(
            <Trans id="default_sheet_parts_and_materials_consuming@consume_request_common_error">
              При попытке укомплектовать маршрутный лист произошла ошибка, действие не было выполнено. Попробуйте
              перезагрузить страницу и попробовать снова. Если ошибка повторяется, то обратитесь к системному администратору
            </Trans>,
          );
          return Promise.reject(err);
        },
      );
  };


export const sendPartAndMaterialsForDefaultSheetConsumedNotification = sheetIdentity =>
  sendNotification(
    <Trans id="default_sheet_parts_and_materials_consuming@parts_and_materials_consumed">
      Был укомплектован маршрутный лист "{sheetIdentity}"
    </Trans>,
    NOTIFICATION_LEVEL.SUCCESS,
  );


const SHEET_OPERATION_REMOTE_TABLE_ENTITIES_REQUEST_WITH_PARAMS = [
  OPERATION_MODEL,
  DEPARTMENT_MODEL,
  EQUIPMENT_CLASS_MODEL,
];
const SHEET_OPERATION_REMOTE_TABLE_ENTITIES_REQUEST_SORT_PARAMS = [
  {
    column: [OPERATION_MODEL, 'nop'].join(RELATED_MODEL_FIELD_DELIMITER),
    params: [{ key: 'asc', value: true }],
  },
];
export const fetchReviewedSheetOperationsRemoteTableEntities = (sheetId, tableParams, requestOptions) =>
  fetchSheetsOperationsRemoteTableEntities({
    mainFilters: [
      {
        column: 'entityRouteSheetId',
        filterType: FILTER_TYPES.EQUALS,
        filterValue: sheetId,
      },
    ],
    mainSortParams: SHEET_OPERATION_REMOTE_TABLE_ENTITIES_REQUEST_SORT_PARAMS,
    withModels: SHEET_OPERATION_REMOTE_TABLE_ENTITIES_REQUEST_WITH_PARAMS,
    withAssigneesData: true,
    withAggregatedData: true,
    tableParams,
    requestOptions,
  });


const PAUSE_OR_RESUME_SHEET_DEFAULT_REQUEST_OPTIONS = {
  showServerError: false,
  isBlockingRequest: true,
};

export const pauseSheet = (entityBatchId, requestOptions = {}) =>
  dispatch => dispatch(sendActionToServer(
    SERVER_ACTION_POINT.PAUSE_SHEET,
    {
      data: {
        entityBatchId,
      },
    },
    {
      ...PAUSE_OR_RESUME_SHEET_DEFAULT_REQUEST_OPTIONS,
      ...requestOptions,
    },
  ))
    .catch(({ response, status }) => {
      if (!response) return Promise.reject(status);

      const errorMsg = getErrorMessage(response, PAUSE_SHEET_ERRORS);
      showError(errorMsg);
      return Promise.reject({ response, status });
    });

export const broadcastSheetPaused = (sheetId, sheetIdentity) =>
  broadcastEventMessage(
    SHEET_PAUSED_EVENT_TYPE,
    { sheetId, sheetIdentity },
  );

export const sendSheetPausedNotification = sheetIdentity =>
  sendNotification(
    <Trans id="sheet_operation_review.pause_sheet@sheet_paused">
      Маршрутный лист "{sheetIdentity}" остановлен, задания по нему стали недоступны для исполнения
    </Trans>,
    NOTIFICATION_LEVEL.INFO,
  );

export const resumeSheet = (entityBatchId, requestOptions = {}) =>
  dispatch => dispatch(sendActionToServer(
    SERVER_ACTION_POINT.RESUME_SHEET,
    {
      data: {
        entityBatchId,
      },
    },
    {
      ...PAUSE_OR_RESUME_SHEET_DEFAULT_REQUEST_OPTIONS,
      ...requestOptions,
    },
  ))
    .catch(({ response, status }) => {
      if (!response) return Promise.reject(status);

      const errorMsg = getErrorMessage(response, RESUME_SHEET_ERRORS);
      showError(errorMsg);
      return Promise.reject({ response, status });
    });

export const broadcastSheetResumed = (sheetId, sheetIdentity) =>
  broadcastEventMessage(
    SHEET_RESUMED_EVENT_TYPE,
    { sheetId, sheetIdentity },
  );

export const sendSheetResumedNotification = sheetIdentity =>
  sendNotification(
    <Trans id="paused_sheet.resume_sheet@sheet_resumed">
      Остановленный маршрутный лист "{sheetIdentity}" успешно переведен в статус 'В производстве', задания по нему
      доступны для исполнения
    </Trans>,
    NOTIFICATION_LEVEL.INFO,
  );

export const broadcastEntityBatchSplit = ({
  parentSheetId,
  parentEntityBatchId,
  childSheetId,
  childEntityBatchId,
  parentSheetOperationId,
  isParentEntityBatchWasFinished,
  isChildEntityBatchWasFinished,
  changedOrderId,
  sheetIdentity,
}) =>
  broadcastEventMessage(
    ENTITY_BATCH_SPLIT_EVENT_TYPE,
    {
      parentSheetId,
      parentEntityBatchId,
      childSheetId,
      childEntityBatchId,
      parentSheetOperationId,
      isParentEntityBatchWasFinished,
      isChildEntityBatchWasFinished,
      changedOrderId,
      sheetIdentity,
    },
  );

const SPLIT_ENTITY_BATCH_DEFAULT_REQUEST_OPTIONS = {
  showServerError: false,
  isBlockingRequest: true,
};

export const splitEntityBatch = (data, requestOptions = {}) => {
  const {
    entityBatchId,
    sheetOperationId,
    entitiesInChildBatchAmount,
    childBatchProgress,
    parentBatchProgress,
    reason,
  } = data;

  return dispatch =>
    dispatch(sendActionToServer(
      SERVER_ACTION_POINT.SPLIT_ENTITY_BATCH,
      {
        data: {
          parentEntityBatchId: entityBatchId,
          childEntityBatchQuantity: entitiesInChildBatchAmount,
          description: reason,
          entityRouteSheetOperationId: sheetOperationId,
          entityBatchOperationProgress: parentBatchProgress,
          dividedEntityBatchOperationProgress: childBatchProgress,
        },
      },
      {
        ...SPLIT_ENTITY_BATCH_DEFAULT_REQUEST_OPTIONS,
        ...requestOptions,
      },
    ))
      .catch(({ response, status }) => {
        if (!response) return Promise.reject(status);

        const errorMsg = getErrorMessage(response, SPLIT_ENTITY_BATCH_ERRORS);
        showError(errorMsg);
        return Promise.reject({ response, status });
      });
};

export const sendEntityBatchSplitNotification = sheetIdentity =>
  sendNotification(
    <Trans id="sheet_operation_review.split_entity_batch@related_entity_batch_was_split">
      Партия маршрутного листа "{sheetIdentity}" была разделена
    </Trans>,
    NOTIFICATION_LEVEL.INFO,
  );


const sendAssemblySheetPartsAndMaterialsReserveActionOnServer = (reserveData, requestOptions = {}) =>
  dispatch =>
    dispatch(sendActionToServer(
      SERVER_ACTION_POINT.RESERVE_PARTS_AND_MATERIALS_FOR_ASSEMBLY_SHEET,
      {
        data: reserveData,
      },
      { ...requestOptions },
    ));

const sendAssemblySheetPartsAndMaterialsConsumeActionOnServer = (consumeData, requestOptions = {}) =>
  dispatch =>
    dispatch(sendActionToServer(
      SERVER_ACTION_POINT.CONSUME_RESERVED_ENTITIES_FOR_ASSEMBLY_SHEET,
      {
        data: consumeData,
      },
      { ...requestOptions },
    ));


const partsAndMaterialsReserveDataTransformer = data =>
  data.map(item => {
    const {
      entityId,
      requiredQuantity: requiredAmount,
      warehouseData,
      entityIdentity,
      entityName,
      entityCode,
    } = item;

    const entityBatches = warehouseData.map((({
      warehouseQuantity: warehouseAmount,
      reservedQuantity: reservedAmount,
      consumedQuantity: consumedAmount,
      entityBatchIdentity: identity,
      entityBatchId: id,
    }) => ({
      warehouseAmount,
      reservedAmount,
      consumedAmount,
      identity,
      id,
    })));

    return {
      entityId,
      requiredAmount,
      entityIdentity,
      entityName,
      entityCode,
      entityBatches,
    };
  });

export const RESERVE_DATA_POINT_WAREHOUSE_FILTER = {
  WAREHOUSE_AND_RESERVE: 0,
  RESERVE_ONLY: 1,
  RESERVE_WITH_CONSUMED_ENTITIES_ONLY: 2,
};

const FETCH_PARTS_AND_MATERIALS_RESERVE_DATA_DEFAULT_REQUEST_OPTIONS = {
  showServerError: false,
  isBlockingRequest: true,
};

export const fetchPartAndMaterialsForAssemblyEntityBatch = (
  entityBatchId,
  queryParams = {},
  options = {},
) =>
    dispatch =>
      dispatch(fetchDataFromServerDataPoint(
        getPartsAndMaterialsForAssemblyEntityBatchDataPoint(entityBatchId),
        queryParams,
        {
          ...FETCH_PARTS_AND_MATERIALS_RESERVE_DATA_DEFAULT_REQUEST_OPTIONS,
          ...options,
        },
      ))
        .then(response => partsAndMaterialsReserveDataTransformer(response.data))
        .catch(({ response, status }) => {
          if(!response) return Promise.reject(status);

          showError(
            <Trans id="assembly_sheet_parts_and_materials_reserving@reserve_data_request_common_error">
              При попытке формирования списка ДСЕ для резервирования произошла ошибка. Попробуйте перезагрузить
              страницу и попробовать снова. Если ошибка повторяется, то обратитесь к системному администратору
            </Trans>,
          );
          return Promise.reject({ response, status });
        });

export const fetchPartAndMaterialsReserveDataForAssemblyEntityBatch = (
  entityBatchId,
  sheetId,
  queryParams = {},
  options = {},
) =>
  dispatch =>
     dispatch(fetchPartAndMaterialsForAssemblyEntityBatch(
       entityBatchId,
       {
         warehouseFilter: RESERVE_DATA_POINT_WAREHOUSE_FILTER.WAREHOUSE_AND_RESERVE,
         ...queryParams,
       },
       options,
     ))
        .then(data => dispatch(setAssemblySheetReserveData({ data, sheetId })));


export const fetchPartAndMaterialsConsumeDataForAssemblyEntityBatch = (
  entityBatchId,
  sheetId,
  queryParams = {},
  options = {},
) =>
  dispatch =>
    dispatch(fetchPartAndMaterialsForAssemblyEntityBatch(
      entityBatchId,
      {
        warehouseFilter: RESERVE_DATA_POINT_WAREHOUSE_FILTER.RESERVE_ONLY,
        ...queryParams,
      },
      options,
    ))
      .then(data => dispatch(setAssemblySheetConsumeData({ data, sheetId })));


const ASSEMBLY_SHEET_PARTS_AND_MATERIALS_RESERVE_ACTION_DEFAULT_REQUEST_OPTIONS = {
  showServerError: false,
  isBlockingRequest: true,
};
export const reservePartsAndMaterialsForAssemblySheet = (
  sheetId,
  sheetIdentity,
  entityBatchId,
  reserveDataUpdate,
) =>
  dispatch => {

    const reserveDataUpdateToSubmit = {
      entityBatchId,
      consumeData: Object
        .keys(reserveDataUpdate)
        .map(entityBatchId => ({
          entityBatchId,
          consumedQuantity: reserveDataUpdate[entityBatchId],
        })),
    };

    return dispatch(sendAssemblySheetPartsAndMaterialsReserveActionOnServer(
      reserveDataUpdateToSubmit,
      ASSEMBLY_SHEET_PARTS_AND_MATERIALS_RESERVE_ACTION_DEFAULT_REQUEST_OPTIONS,
    ))
      .then(
        () => {
          broadcastEventMessage(
            PARTS_AND_MATERIALS_FOR_ASSEMBLY_SHEET_RESERVED_EVENT_TYPE,
            { sheetId, sheetIdentity, entityBatchId },
          );
        },
        err => {
          showError(
            <Trans id="assembly_sheet_parts_and_materials_reserving@reserve_request_common_error">
              При попытке зарезервировать ДСЕ для МЛ произошла ошибка, действие не было выполнено. Попробуйте
              перезагрузить страницу и попробовать снова. Если ошибка повторяется, то обратитесь к системному
              администратору
            </Trans>,
          );
          return Promise.reject(err);
        },
      );
  };

const ASSEMBLY_SHEET_PARTS_AND_MATERIALS_CONSUME_ACTION_DEFAULT_REQUEST_OPTIONS = {
  showServerError: false,
  isBlockingRequest: true,
};

export const consumePartsAndMaterialsForAssemblySheet = (
  sheetId,
  sheetIdentity,
  entityBatchId,
  consumeData,
) =>
  dispatch => {

    const consumeDataToSubmit = {
      entityBatchId,
      consumeData: Object
        .keys(consumeData)
        .map(warehouseEntityBatchId => ({
          entityBatchId: warehouseEntityBatchId,
          consumedQuantity: consumeData[warehouseEntityBatchId],
        })),
    };

    return dispatch(sendAssemblySheetPartsAndMaterialsConsumeActionOnServer(
      consumeDataToSubmit,
      ASSEMBLY_SHEET_PARTS_AND_MATERIALS_CONSUME_ACTION_DEFAULT_REQUEST_OPTIONS,
    ))
      .then(response => {
        broadcastEventMessage(
          PARTS_AND_MATERIALS_FOR_ASSEMBLY_SHEET_CONSUMED_EVENT_TYPE,
          { sheetId, sheetIdentity, entityBatchId },
        );
        return response;
      })
      .catch(response => {
        showError(
          <Trans id="sheet_operation_consume_entities_dialog@consume_request_common_error">
            При попытке потребления ДСЕ произошла ошибка, действие не было выполнено. Попробуйте перезагрузить
            страницу и попробовать снова. Если ошибка повторяется, то обратитесь к системному администратору
          </Trans>,
        );
        return Promise.reject(response);
      });
  };

export const sendPartsAndMaterialsForAssemblySheetConsumedNotification = sheetIdentity =>
  sendNotification(
    <Trans id="sheet_operation_consume_entities_dialog@parts_and_materials_consumed">
      Для МЛ "{sheetIdentity}" были потреблены ДСЕ
    </Trans>,
    NOTIFICATION_LEVEL.SUCCESS,
  );

export const sendPartAndMaterialsForAssemblySheetReservedNotification = sheetIdentity =>
  sendNotification(
    <Trans id="assembly_sheet_parts_and_materials_reserving@parts_and_materials_reserved">
      Было выполнено резервирование ДСЕ для МЛ "{sheetIdentity}"
    </Trans>,
    NOTIFICATION_LEVEL.SUCCESS,
  );
