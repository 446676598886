import React from 'react';
import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import './style.css';


/*
* Данный компонент создан для того, чтобы передать реф из компонента Tooltip из material-ui и повесить на оборачиваемый
* кастомный компонент, который передается в children. Без этого, в Tooltip можно использовать только компоненты из
* material-ui или примитивные элементы
* */
export const TooltipWithForwardedRef = React.forwardRef(
  ({ refElementClassName, children, title, ...restProps }, ref) => {
    if (!title) return children;

    return (
      <Tooltip
          {...restProps}
          title={title}
          classes={{
            popper: 'tooltip-with-forwarded-ref__tooltip-popper',
            tooltip: 'tooltip-with-forwarded-ref__tooltip',
          }}
      >
      <span className={refElementClassName} ref={ref}>
        {children}
      </span>
      </Tooltip>
    );
  },
);

TooltipWithForwardedRef.propTypes = {
  title: PropTypes.oneOfType([PropTypes.element.isRequired, PropTypes.node.isRequired]),
  children: PropTypes.element.isRequired,
  refElementClassName: PropTypes.string,
};
