import DefaultErrorComponent from './DefaultErrorComponent';
import DefaultLoadingComponent from './DefaultLoadingComponent';
import { asyncComponent as _asyncComponent } from '@bfg-frontend/async-component/lib/asyncComponent/asyncComponent';


export const asyncComponent = props => _asyncComponent({
  loadingComponent: DefaultLoadingComponent,
  errorComponent: DefaultErrorComponent,
  ...props,
});
