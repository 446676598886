import { createSelector } from 'reselect';

import {
  DEPARTMENT_DATA_FROM_CA_ENTITY_TEMPLATE,
  ENTITY_MODEL_DATA_FROM_CA_ENTITY_TEMPLATE,
  EQUIPMENT_CLASS_DATA_FROM_CA_ENTITY_TEMPLATE,
  ORDER_DATA_FROM_CA_ENTITY_TEMPLATE,
  OPERATION_DATA_FROM_CA_ENTITY_TEMPLATE,
  ENTITY_BATCH_DATA_FROM_CA_ENTITY_TEMPLATE,
  SHEET_OPERATION_DATA_FROM_CA_ENTITY_TEMPLATE,
  SHEET_DATA_FROM_CA_ENTITY_TEMPLATE,
  EQUIPMENT_DATA_FROM_CA_ENTITY_TEMPLATE,
  ENTITY_BATCH_TRANSACTION_DATA_FROM_CA_TEMPLATE,
} from '../utils/entities';
import {
  complexComparatorFactory,
  dateComparator,
  fieldComparatorFactory,
  numberComparator,
} from '@bfg-frontend/utils/lib/array';

import _pickBy from 'lodash/pickBy';
import _get from 'lodash/get';
import _size from 'lodash/size';
import _last from 'lodash/last';
import {
  partsAndMaterialsToConsumeForDefaultSheetSelector,
  defaultSheetsIdsWithEnoughPartsAndMaterialsSelector,
} from '../reducers/storageManagementApp/defaultSheets/selectors';

import {
  sheetEntitiesSelector,
  entityBatchEntitiesSelector,
  sheetOperationEntitiesSelector,
  entityModelEntitiesSelector,
  orderEntitiesSelector,
} from '../reducers/entities/selectors';
import { tableCurrentRemoteDataSelector } from '../reducers/table/selectors';
import {
  DEPARTMENT_MODEL,
  ENTITY_BATCH_MODEL,
  ENTITY_MODEL,
  EQUIPMENT_CLASS_MODEL,
  EQUIPMENT_MODEL,
  OPERATION_MODEL,
  ORDER_MODEL,
  SHEET_MODEL,
  SHEET_OPERATION_AGGREGATED_MODEL,
  SHEET_OPERATION_ASSIGNEE_MODEL,
  SHEET_OPERATION_MODEL,
  SHEET_OPERATION_TRANSACTION_MODEL,
  USER_MODEL,
} from '../constants/models';
import { prepareAssigneesDataForSheetsOperationsRemoteTable, prepareOperationProdTimeAndLaborValues } from '../utils/tasks';
import { ENTITY_BATCH_STATUS, ENTITY_BATCH_PROVIDING_STATE, ENTITY_BATCH_TRANSACTION_TYPE } from '../constants/sheets';
import {
  getEntityCombinedName,
  getEntityDataByTemplate,
  getUserLastNameWithInitials,
} from '@bfg-frontend/utils/lib/stringBuilders/entity';
import _isNil from 'lodash/isNil';


export const getSheetDataFromEntitiesById = ({
  sheetId,
  sheetEntities,
  entityBatchEntities,
  entityModelEntities,
  orderEntities,
  currentSheetOperationDataBySheetId,
}) => {


  if(!_get(sheetEntities, sheetId)) return null;

  const sheetParams = getEntityDataByTemplate(
    sheetEntities[sheetId],
    SHEET_DATA_FROM_CA_ENTITY_TEMPLATE,
  );

  const {
    entityBatchId,
  } = sheetParams;

  const entityBatchParams = getEntityDataByTemplate(
    entityBatchEntities[entityBatchId],
    ENTITY_BATCH_DATA_FROM_CA_ENTITY_TEMPLATE,
  );

  const {
    entityId,
    orderId,
  } = entityBatchParams;

  const entityParams = getEntityDataByTemplate(
    entityModelEntities[entityId],
    ENTITY_MODEL_DATA_FROM_CA_ENTITY_TEMPLATE,
  );

  const orderParams = getSheetOrderParams(orderId, orderEntities[orderId]);

  return{
    sheetId,
    ...sheetParams,
    ...entityBatchParams,
    ...entityParams,
    ...orderParams,
    currentSheetOperationData: _get(currentSheetOperationDataBySheetId, [sheetId]),
    /*
    * Дублирующие поля, необходимые для фильтрации, если таблица не серверная, чтобы использовать одни схемы для
    * серверной и клиентской таблиц разных типов МЛ. Сейчас клиентская таблица, реализуемая через этот селектор, только
    * таблица МЛ в приложении комлектовщика. Надо удалить это, если таблица станет с серверной фильтрацией. Или
    * переопределить схему фильтрации, чтобы использовались обычные поля --> так не сделано, потому что непонятно, как
    * здесь в итоге будет реализовано.
    * */
    id: sheetId,
    entityBatch__entityId: entityId,
    entityBatch__orderId: orderId,
  };
};

export const getSheetDataFromEntitiesByIdWithTransactions = ({
  sheetId,
  sheetEntities,
  entityBatchEntities,
  sheetOperationEntities,
  entityModelEntities,
  orderEntities,
  userEntities,
  entityBatchTransactionsByEntityBatchId,
  getEntityBatchTransactionsDataFromEntities,
}) => {
  const entityBatchId = _get(sheetEntities, [sheetId, 'entityBatchId']);

  return {
    ...getSheetDataFromEntitiesById({
      sheetId,
      sheetEntities,
      entityBatchEntities,
      sheetOperationEntities,
      entityModelEntities,
      orderEntities,
    }),
    entityBatchTransactionsByType: getEntityBatchTransactionsDataFromEntities(
      entityBatchId,
      entityBatchTransactionsByEntityBatchId,
      userEntities,
    ),
  };
};

const createEntityBatchTransactionsDataFromEntitiesByIdGetter = transactionTypes =>
  (
    entityBatchId,
    entityBatchEntitiesByEntityBatchId,
    userEntities,
  ) => {

    if (_isNil(entityBatchId) || !_get(entityBatchEntitiesByEntityBatchId, entityBatchId)) return {};

    const entityBatchTransactionEntitiesByType = _get(entityBatchEntitiesByEntityBatchId, [entityBatchId], {});

    /*
    Для окна просмотра партии в таблице "Окончательный брак" требуются данные двух типов транзакций:
      1. Перевод в статус "Несоответствующая продукция"
      2. Подтверждение брака

    Поэтому записываем данные транзакций в разрезе их типов
    */

    return transactionTypes
      .reduce((acc, transactionType) => {
        const entityBatchTransactionEntity = entityBatchTransactionEntitiesByType[transactionType];

        if (!entityBatchTransactionEntity) return acc;

        const { userId } = entityBatchTransactionEntity;

        const entityBatchTransactionParams = getEntityDataByTemplate(
          entityBatchTransactionEntity,
          ENTITY_BATCH_TRANSACTION_DATA_FROM_CA_TEMPLATE,
        );
        const userName = getUserLastNameWithInitials(userEntities[userId]);

        acc[transactionType] =  {
          ...entityBatchTransactionParams,
          userName,
        };

        return acc;
      }, {});
  };

export const getSheetOrderParams = (orderId, orderEntity) => {

  if(orderId === null)
    return{
      orderName: null,
      orderPriority: null,
    };

  const {
    orderName,
    orderPriority,
  } = getEntityDataByTemplate(orderEntity, ORDER_DATA_FROM_CA_ENTITY_TEMPLATE);

  return{
    orderName,
    orderPriority,
  };
};

export const sheetsRemoteTableDataSelector = createSelector(
  (state, { sheetsIdentity }) => tableCurrentRemoteDataSelector(state, { tableId: sheetsIdentity }),
  currentRemoteData => {

    if(currentRemoteData === null) return [];

    const{
      currentRemoteItemsIds,
      currentRemoteItemsById,
    } = currentRemoteData;

    const sheetEntitiesIds = currentRemoteItemsIds[SHEET_MODEL];

    if(_size(sheetEntitiesIds) === 0) return [];

    const {
      [SHEET_MODEL]: sheetEntities = {},
      [ENTITY_BATCH_MODEL]: entityBatchEntities = {},
      [SHEET_OPERATION_MODEL]: sheetOperationEntities = {},
      [ENTITY_MODEL]: entityModelEntities = {},
      [ORDER_MODEL]: orderEntities = {},
      currentSheetOperationDataBySheetId = {},
    } = currentRemoteItemsById;

    return sheetEntitiesIds
      .map(sheetId =>
        getSheetDataFromEntitiesById({
          sheetId,
          sheetEntities,
          entityBatchEntities,
          sheetOperationEntities,
          entityModelEntities,
          orderEntities,
          currentSheetOperationDataBySheetId,
        }));
  },
);

const createQualityControlSheetsRemoteTableDataSelector = getEntityBatchTransactionsDataFromEntities => createSelector(
  tableCurrentRemoteDataSelector,
  currentRemoteData => {

    if(currentRemoteData === null) return [];

    const{
      currentRemoteItemsIds,
      currentRemoteItemsById,
    } = currentRemoteData;

    const sheetEntitiesIds = currentRemoteItemsIds[SHEET_MODEL];

    if(_size(sheetEntitiesIds) === 0) return [];

    const {
      [SHEET_MODEL]: sheetEntities = {},
      [ENTITY_BATCH_MODEL]: entityBatchEntities = {},
      [SHEET_OPERATION_MODEL]: sheetOperationEntities = {},
      [ENTITY_MODEL]: entityModelEntities = {},
      [ORDER_MODEL]: orderEntities = {},
      [USER_MODEL]: userEntities = {},
      entityBatchTransactionsByEntityBatchId,
    } = currentRemoteItemsById;

    return sheetEntitiesIds
      .map(sheetId => getSheetDataFromEntitiesByIdWithTransactions({
        sheetId,
        sheetEntities,
        entityBatchEntities,
        sheetOperationEntities,
        entityModelEntities,
        orderEntities,
        userEntities,
        entityBatchTransactionsByEntityBatchId,
        getEntityBatchTransactionsDataFromEntities,
      }));
  },
);

export const getInappropriateSheetsTransactionsDataFromEntitiesById = createEntityBatchTransactionsDataFromEntitiesByIdGetter(
  [ENTITY_BATCH_TRANSACTION_TYPE.MARKED_AS_INAPPROPRIATE],
);

export const inappropriateSheetsRemoteTableDataSelector = createQualityControlSheetsRemoteTableDataSelector(
  getInappropriateSheetsTransactionsDataFromEntitiesById,
);
/* TODO при стыковке сформировать необходимый колбэк обработки данных о транзакциях */

export const getDefectiveSheetsTransactionsDataFromEntitiesById = createEntityBatchTransactionsDataFromEntitiesByIdGetter([
  ENTITY_BATCH_TRANSACTION_TYPE.MARKED_AS_DEFECTIVE,
  ENTITY_BATCH_TRANSACTION_TYPE.MARKED_AS_INAPPROPRIATE,
]);
export const defectiveSheetsRemoteTableDataSelector = createQualityControlSheetsRemoteTableDataSelector(
  getDefectiveSheetsTransactionsDataFromEntitiesById,
);


//TODO Если переведем таблицу МЛ, ожидающих комплектациию, на серверную, то все фабрики ниже можно удалить. Ранее они
//TODO использовались для разных типов МЛ, а теперь их используют только для МЛ, ожидающие комплектацию. Селекторы
//TODO данных, получаемые, при помощи этих фабрик тоже можно будет удалить, т.к. можно будет использовать
//TODO абстрактные для всех типов МЛ sheetsRemoteTableDataSelector
/*
* TODO кажется, что с разделением листов на типы тут можно добавить первым параметром в фабрку селектор листов -
*  sheetEntitiesSelector, который будет принимать одно из двух значений: defaultSheetEntitiesSelector и assemblySheetEntitiesSelector
*  но все зависит от итоговой реализации списка сборочных мл на бэке, насколько я понимаю. Если там будет дата-точка c
*  логикой серверной таблицы, то эта фабрика для сборочных листов использоваться не будет
* */
const sheetsEntitiesByRelatedEntityBatchPropertiesSelectorFactory = (entityBatchPropertiesToSelect = {}) =>
  createSelector(
    sheetEntitiesSelector,
    entityBatchEntitiesSelector,
    (sheetEntities, entityBatchEntities) => _pickBy(
      sheetEntities,
      ({ entityBatchId }) => {
        const relatedEntityBatchEntity = entityBatchEntities[entityBatchId];

        /*
        * Если не нашли связанную с МЛ партию, то не селектим такой МЛ, т.к. с ним ничего не понятно. Такого, вообще,
        * не должно случаться, но на всякий случай проверяем
        * */
        if(!relatedEntityBatchEntity) return false;

        return Object
          .keys(entityBatchPropertiesToSelect)
          .every(
            entityBatchFieldToCompare =>
              relatedEntityBatchEntity[entityBatchFieldToCompare] === entityBatchPropertiesToSelect[entityBatchFieldToCompare],
          );
      },
    ),
  );

const sheetsDataSelectorFactory = sheetsEntitiesSelector =>
  createSelector(
    sheetsEntitiesSelector,
    entityBatchEntitiesSelector,
    sheetOperationEntitiesSelector,
    entityModelEntitiesSelector,
    orderEntitiesSelector,
    (
      sheetEntities = {},
      entityBatchEntities = {},
      sheetOperationEntities = {},
      entityModelEntities = {},
      orderEntities = {},
    ) => {
      const sheetEntitiesIds = Object.keys(sheetEntities);

      if(sheetEntitiesIds.length === 0) return [];

      const entitiesData = {
        sheetEntities,
        entityBatchEntities,
        sheetOperationEntities,
        entityModelEntities,
        orderEntities,
      };

      return sheetEntitiesIds
        .map(
          sheetEntityId =>
            /*
            * Функция getSheetDataFromEntitiesByIdCb в своей логике хочет принимать id БД в виде числа
            * (ну и, id БД это и есть всегда число), т.к. в ней осуществлюятся строгие сравнения и т.д. А ключи объекта, по
            * которым тут ведется пробег это всегда строки, поэтому преобразуем к числу
            * */
            getSheetDataFromEntitiesById({
              ...entitiesData,
              sheetId: Number(sheetEntityId),
            }),
        )
        .sort(complexComparatorFactory([
          fieldComparatorFactory(dateComparator, 'startDate'),
          fieldComparatorFactory(numberComparator, 'sheetId'),
        ]));
    },
  );

const sheetsWaitingPartsAndMaterialsEntitiesSelector =
  sheetsEntitiesByRelatedEntityBatchPropertiesSelectorFactory({
    status: ENTITY_BATCH_STATUS.IN_PRODUCTION,
    providingState: ENTITY_BATCH_PROVIDING_STATE.UNPROVIDED,
  });

export const sheetsWaitingPartsAndMaterialsSelector =
  sheetsDataSelectorFactory(sheetsWaitingPartsAndMaterialsEntitiesSelector);

/*
* С неймингом селекторов уже тяжеловато, поэтому оставлю здесь комментарий:
* Это результирующий селектор данных стандартных МЛ, ожидающих комплектации, которые будут отображены в интерфейсе с учетом
* пользовательских фильтров и специальной сортировки, когда сначала идут МЛ, которые, теоретически, можно укомплектовать,
* а за ними МЛ, ожидающие комлектации. Кроме того, информация о том, что МЛ может быть укомлектован добавляется в сам
* объект описания МЛ в поле canPartsAndMaterialsBeConsumed, чтобы можно было легко управлять отображением в компоненте, где
* это используется
* */
export const defaultSheetsWaitingPartsAndMaterialsToShowDataSelector = createSelector(
  sheetsWaitingPartsAndMaterialsSelector,
  defaultSheetsIdsWithEnoughPartsAndMaterialsSelector,
  (sheets, defaultSheetsIdsWithEnoughPartsAndMaterials) => {
    //для удобства доступа и чтобы избежать большого количества пробегов по массивам, предварительно преобразуем массив
    //идентификаторов defaultSheetsIdsWithEnoughPartsAndMaterials в set
    const defaultSheetsIdsWithEnoughPartsAndMaterialsSet = new Set(defaultSheetsIdsWithEnoughPartsAndMaterials);
    return sheets
      .map(sheetData => ({
        ...sheetData,
        canPartsAndMaterialsBeConsumed: defaultSheetsIdsWithEnoughPartsAndMaterialsSet.has(sheetData.sheetId),
      }))
      .sort(sheetsWaitingPartsAndMaterialsToShowComparator);
  },
);

/*
* Специальный компаратор, использующийся в селекторе sheetsWaitingPartsAndMaterialsToShowDataSelector (к нему есть
* отдельное пояснение), который должен сортировать МЛ, ожидающих комплектации таким образом, чтобы сначала отображались
* МЛ, которые теоретически могут быть укомплектованы (canPartsAndMaterialsBeConsumed === true), а за ними те, которые
* ещё ожидают окончания поступления комплектующих на склад. Среди эти двух групп МЛ должны располагаться по приортитету
* (сейчас пока приоритет определяется только временем начала первой операции по МЛ) и этот компаратор ПОДРАЗУМЕВАЕТ, ЧТО
* ОН БУДЕТ ВЫЗЫВАТЬСЯ ДЛЯ СПИСКА МЛ, УЖЕ ОТСОРТИРОВАННОГО ПО ПРИОРИТЕТАМ РАНЕЕ (т.к. для этого есть свои абстракции, это
* происходит раньше, здесь просто нужна дополнительная сортировка), поэтому логика такая:
*  - если поле canPartsAndMaterialsBeConsumed одинаково, то компаратор не изменяет порядок, т.е. сохраняет прошлую сортировку
*  - если поля canPartsAndMaterialsBeConsumed отличаются, то раньше располагается тот МЛ, у которого
*  canPartsAndMaterialsBeConsumed равен true
*
* */
const sheetsWaitingPartsAndMaterialsToShowComparator = (firstSheetData, secondSheetData) => {
  const canPartsAndMaterialsBeConsumedForFirstSheet = firstSheetData.canPartsAndMaterialsBeConsumed;
  const canPartsAndMaterialsBeConsumedForSecondSheet = secondSheetData.canPartsAndMaterialsBeConsumed;

  if(canPartsAndMaterialsBeConsumedForFirstSheet === canPartsAndMaterialsBeConsumedForSecondSheet)
    return 0;

  return canPartsAndMaterialsBeConsumedForFirstSheet ? -1 : 1;
};



export const sheetOperationsRemoteTableDataSelector = createSelector(
  (state, { sheetOperationsTableId })  => tableCurrentRemoteDataSelector(state, { tableId: sheetOperationsTableId }),
  (_, { entitiesInBatchAmount }) => entitiesInBatchAmount,
  (currentRemoteData, entitiesInBatchAmount) => {

    if (currentRemoteData === null) return [];

    const {
      currentRemoteItemsIds,
      currentRemoteItemsById,
    } = currentRemoteData;

    const sheetOperationEntitiesIds = currentRemoteItemsIds[SHEET_OPERATION_MODEL];

    if (_size(sheetOperationEntitiesIds) === 0) return [];

    const {
      [SHEET_OPERATION_MODEL]: sheetOperationEntities = {},
      [OPERATION_MODEL]: operationEntities = {},
      [DEPARTMENT_MODEL]: departmentEntities = {},
      [EQUIPMENT_CLASS_MODEL]: equipmentClassEntities = {},
      [EQUIPMENT_MODEL]: equipmentEntities = {},
      [SHEET_OPERATION_AGGREGATED_MODEL]: sheetOperationsAggregatedData = {},
      [SHEET_OPERATION_ASSIGNEE_MODEL]: sheetOperationAssigneeEntities = {},
      [USER_MODEL]: userEntities = {},
    } = currentRemoteItemsById;

    return sheetOperationEntitiesIds
      .map(sheetOperationId => {

        const sheetOperationEntity = sheetOperationEntities[sheetOperationId];

        const sheetOperationParams = getEntityDataByTemplate(
          sheetOperationEntity,
          SHEET_OPERATION_DATA_FROM_CA_ENTITY_TEMPLATE,
        );

        const {
          operationId,
          departmentId,
          equipmentClassId,
          equipmentId,
          prodTime,
        } = sheetOperationParams;

        const operationParams = getEntityDataByTemplate(
          operationEntities[operationId],
          OPERATION_DATA_FROM_CA_ENTITY_TEMPLATE,
        );

        const departmentParams = getEntityDataByTemplate(
          departmentEntities[departmentId],
          DEPARTMENT_DATA_FROM_CA_ENTITY_TEMPLATE,
        );

        const equipmentClassParams = getEntityDataByTemplate(
          equipmentClassEntities[equipmentClassId],
          EQUIPMENT_CLASS_DATA_FROM_CA_ENTITY_TEMPLATE,
        );

        const equipmentParams = getEntityDataByTemplate(
          equipmentEntities[equipmentId],
          EQUIPMENT_DATA_FROM_CA_ENTITY_TEMPLATE,
        );

        const assignees = prepareAssigneesDataForSheetsOperationsRemoteTable(sheetOperationId, sheetOperationAssigneeEntities, userEntities);

        const {
          operationProdTime,
          operationLabor,
        } = prepareOperationProdTimeAndLaborValues(prodTime, entitiesInBatchAmount);

        const {
          last: isLastSheetOperation,
          previousFinished: isPreviousSheetOperationFinished,
          lastAssembly: isLastAssemblySheetOperation,
        } = sheetOperationsAggregatedData[sheetOperationId];

        return{
          ...operationParams,
          ...departmentParams,
          ...equipmentClassParams,
          ...equipmentParams,
          ...sheetOperationParams,
          operationProdTime,
          operationLabor,
          assignees,
          operationId,
          equipmentId,
          departmentId,
          equipmentClassId,
          isLastSheetOperation,
          isPreviousSheetOperationFinished,
          isLastAssemblySheetOperation,
          entitiesInBatchAmount,
        };
      });
  },
);

export const sheetOperationTransactionsTableDataSelector = createSelector(
  (state, { tableId }) => tableCurrentRemoteDataSelector(state, { tableId }),
  currentRemoteData => {

    if (currentRemoteData === null) return [];

    const {
      currentRemoteItemsIds,
      currentRemoteItemsById,
    } = currentRemoteData;

    const sheetOperationTransactionEntitiesIds = currentRemoteItemsIds[SHEET_OPERATION_TRANSACTION_MODEL];

    if (_size(sheetOperationTransactionEntitiesIds) === 0) return [];

    return sheetOperationTransactionEntitiesIds
      .map(sheetOperationTransactionId => {

        const {
          id,
          startDate,
          stopDate,
          stopProgress,
          userId,
        } = currentRemoteItemsById[SHEET_OPERATION_TRANSACTION_MODEL][sheetOperationTransactionId];

        const {
          name,
          lastName,
          patronymicName,
          identity: userIdentity,
        } = currentRemoteItemsById[USER_MODEL][userId];

        return {
          id,
          startDate,
          stopDate,
          stopProgress,
          name,
          lastName,
          patronymicName,
          userIdentity,
        };
      });
  },
);

export const detailedDataToConsumeForDefaultSheetSelector = createSelector(
  partsAndMaterialsToConsumeForDefaultSheetSelector,
  partsAndMaterialsToConsumeForDefaultSheet => {
    if(!partsAndMaterialsToConsumeForDefaultSheet) return [];

    return partsAndMaterialsToConsumeForDefaultSheet
      .map(({
        entityId,
        entityIdentity,
        entityCode,
        entityName,
        requiredAmount: entityRequiredAmount,
        entityBatchesToConsume = [],
      }) => ({
        entityId,
        entityCombinedName: getEntityCombinedName({
          identity: entityIdentity,
          code: entityCode,
          name: entityName,
        }),
        entityRequiredAmount,
        entityMissingAmount: _getEntityMissingAmount(entityBatchesToConsume, entityRequiredAmount),
        entityBatchesToConsume,
      }))
      .sort(detailedDataToConsumeComparator);
  },
);

/*
* Высчитываем количество комлектующих, которых в данный момент не хватает для позиции в спецификации по следующей логике:
* - Если массив с детализацией комплектации entityBatchesToConsume пустой, значит партий ДСЕ на складе для этой позиции
* нет совсем, возвращаем полное требуемое количество комлектации для этой позиции - entityRequiredAmount
* - Если массив с детализацией комплектации entityBatchesToConsume не пустой, то в нём для каждого элемента, т.е.
* для каждой из партий, которая будет потребления, есть поле requiredEntityAmountAfterThisBatchConsuming, которое
* описывает сколько ещё нужно комплектации для позиции после потребления этой партии, с учетом того, что партии
* потребляются именно в том порядке, в котором они представлены в массиве. Т.е., в этом случае, нас интересует значения
* поля requiredEntityAmountAfterThisBatchConsuming для последней потребляемой партии в массиве (оно будет 0, если все
* комлпектующие для позиции спецификации есть на складе или будет равно какому-то значению, что будет означать, что даже
* после потребления всех доступных партий со склада для этой позиции, требуются ещё комплектующие)
* */
const _getEntityMissingAmount = (entityBatchesToConsume, entityRequiredAmount) => {
  if(_size(entityBatchesToConsume) === 0)
    return entityRequiredAmount;

  return _last(entityBatchesToConsume).requiredEntityAmountAfterThisBatchConsuming;
};

//Для детализированной комлектации сначала отображаем ДСЕ, для которых ещё нет готовой комплектации на складе
const detailedDataToConsumeComparator = (
  { entityMissingAmount: firstEntityMissingAmount },
  { entityMissingAmount: secondEntityMissingAmount },
) => {
  if(firstEntityMissingAmount > 0 && secondEntityMissingAmount === 0) return -1;
  if(firstEntityMissingAmount === 0 && secondEntityMissingAmount > 0) return 1;
  return 0;
};
