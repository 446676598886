import { connect } from 'react-redux';

import { push } from 'connected-react-router';

import {
  departmentTasksFilterSelector,
  departmentTasksFilterTitleSelector,
  equipmentClassTasksFilterSelector,
  equipmentClassTasksFilterTitleSelector,
} from '../../../reducers/workerApp/tasksMainFilters/selectors';

import { WorkerNavigationPanel } from './WorkerNavigationPanel';

import { WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE, WORKER_APP_MAIN_ROUTE } from '../../../constants/routes';
import { bindActionCreators } from 'redux';
import { saveUserSettings } from '../../../operations/userSettings';
import { USER_SETTINGS_ID } from '../constants';


const mapStateToProps = state => {
  const {
    id: departmentIdTasksFilter,
  } = departmentTasksFilterSelector(state);
  const {
    id: equipmentClassIdTasksFilter,
  } = equipmentClassTasksFilterSelector(state);
  return {
    departmentIdTasksFilter,
    equipmentClassIdTasksFilter,
    departmentTitle: departmentTasksFilterTitleSelector(state),
    equipmentClassTitle: equipmentClassTasksFilterTitleSelector(state),
  };
};

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      push,
    },
    dispatch,
  ),
  goBackToViewModeChoosingScreen: () =>
    dispatch(saveUserSettings({
      [USER_SETTINGS_ID.WORKER_APP_VIEW_MODE]: null,
      [USER_SETTINGS_ID.WORKER_APP_LAST_VISITED_ROUTE]: null,
    }))
      .then(() => dispatch(push(WORKER_APP_MAIN_ROUTE))),
});

const mergeProps = (stateProps, dispatchProps) => {
  const {
    departmentIdTasksFilter,
    equipmentClassIdTasksFilter,
    departmentTitle,
    equipmentClassTitle,
  } = stateProps;

  const {
    push,
    goBackToViewModeChoosingScreen,
  } = dispatchProps;

  const onGoBackButtonClick = () => {
    if(!departmentIdTasksFilter) {
      return goBackToViewModeChoosingScreen();
    }

    if (!equipmentClassIdTasksFilter) {
      return push(WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE);
    }

    return push([
      WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE,
      departmentIdTasksFilter,
    ].join('/'));
  };

  return {
    departmentTitle,
    equipmentClassTitle,
    onGoBackButtonClick,
  };
};

export const WorkerNavigationPanelContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(WorkerNavigationPanel);

WorkerNavigationPanelContainer.displayName = 'WorkerNavigationPanelContainer';
