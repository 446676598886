import React from 'react';

import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import { MATERIAL_UI_VARIANT } from './materialUI';


export const MATERIAL_UI_STYLED_REACT_SELECT_STYLES = {
  control: (_, { isDisabled }) => ({
    display: 'flex',
    height: 'unset',
    fontSize: '1.3rem',
    padding: 0,
    opacity: isDisabled ? 0.5 : 1,
  }),
  valueContainer: () => ({
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  }),
  placeholder: () => ({
    position: 'absolute',
    left: '0.2rem',
    fontSize: '1.3rem',
  }),
  indicatorsContainer: () => ({
    cursor: 'pointer',
    display: 'flex',
  }),
  singleValue: () => ({
    fontSize: '1.6rem',
  }),
  menu: () => ({
    position: 'absolute',
    zIndex: 10,
    marginTop: '0.8rem',
    left: 0,
    right: 0,
  }),
  option: (_, props) => ({
    paddingTop: '1.2rem',
    paddingBottom: '1.2rem',
    paddingLeft: '1.6rem',
    paddingRight: '1.6rem',
    minHeight: 'unset',
    fontWeight: props.isSelected ? 600 : 400,
    fontSize: '1.3rem',
    backgroundColor: getOptionBackgroundColor(props),
  }),
  noOptionsMessage: () => ({
    padding: '0.8rem 1.6rem',
    fontSize: '1.3rem',
  }),
};

const getOptionBackgroundColor = ({ isSelected, isFocused }) => {
  if(isSelected) return 'rgba(0, 0, 0, 0.08)';
  if(isFocused) return 'rgba(0, 0, 0, 0.04)';
  return 'transparent';
};

/*eslint-disable react/prop-types*/

/*
* ControlTextFieldInnerInputComponent используется, как inputComponent у TextField, поэтому по логике material ui
* должен быть компонентом, который принимает ref. Для функционального компонента это значит, что нужна обёртка в
* React.forwardRef.
* При этом, сам ref, который нужно передать на div в логике кастомизации автокомплита, передается через пропс inputRef
* и представляет собой controlRef из кода react-select. Поэтому, получается, что React.forwardRef используется больше,
* для того, чтобы mui не выдавал варнинг о том, что в качестве inputComponent у TextField используется функциональный
* компонент, который не может принимать ref. Т.е. сам ref из forwardRef не используется. Вероятно, в будущем станет
* понятно, что этот реф тоже обязательно нужно установить, тогда этот код нужно будет скорректировать
* */
const ControlTextFieldInnerInputComponent = React.forwardRef(
  // eslint-disable-next-line no-unused-vars
  (props, ref) => {

    // eslint-disable-next-line react/prop-types
    const { inputRef, style, children, ...rest } = props;
    return(
      <div ref={inputRef} style={style} {...rest}>
        {children}
      </div>
    );
  },
);

const Control = props => {

  const {
    innerRef,
    children,
    innerProps,
    getStyles,
    isDisabled,
    selectProps: {
      title,
      error,
      showError,
    },
  } = props;

  return (
    <TextField
        variant={MATERIAL_UI_VARIANT.STANDARD}
        fullWidth
        label={title}
        InputLabelProps={{
          shrink: true,
          style: {
            fontSize: '1.6rem',
          },
        }}
        error={!!error}
        helperText={showError ? error : undefined}
        FormHelperTextProps={{
          style: {
            fontSize: '1.1rem',
          },
        }}
        disabled={isDisabled}
        InputProps={{
          inputComponent: ControlTextFieldInnerInputComponent,
          inputProps: {
            style: getStyles('control', props),
            inputRef: innerRef,
            children,
            ...innerProps,
          },
        }}
    />
  );
};

const ValueContainer = props =>
  <div style={props.getStyles('valueContainer', props)} >
    {props.children}
  </div>;

const Placeholder = props =>
  <Typography
      style={props.getStyles('placeholder', props)}
      color="textSecondary"
      {...props.innerProps}
  >
    {props.children}
  </Typography>;


const IndicatorsContainer = props => (
  <span style={props.getStyles('indicatorsContainer', props)} >
    {props.children}
  </span>
);

const SingleValue = props =>
  <Typography
      style={props.getStyles('singleValue', props)}
      {...props.innerProps}
  >
    {props.children}
  </Typography>;

const Menu = props =>
  <Paper
      style={props.getStyles('menu', props)}
      square
      {...props.innerProps}
  >
    {props.children}
  </Paper>;

const Option = props =>
  <MenuItem
      ref={props.innerRef}
      selected={props.isSelected}
      component="div"
      style={props.getStyles('option', props)}
      {...props.innerProps}
  >
    {props.children}
  </MenuItem>;

const NoOptionsMessage = props =>
  <Typography
      style={props.getStyles('noOptionsMessage', props)}
      color="textSecondary"
      {...props.innerProps}
  >
    {props.children}
  </Typography>;
/*eslint-enable react/prop-types*/

export const MATERIAL_UI_STYLED_REACT_SELECT_COMPONENTS = {
  Control,
  ValueContainer,
  Placeholder,
  IndicatorsContainer,
  SingleValue,
  Menu,
  Option,
  NoOptionsMessage,
};
