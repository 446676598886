import React, { useCallback } from 'react';
import { SimpleItemChoosePanel } from '../../common/SimpleItemChoosePanel/SimpleItemChoosePanel';
import { NoDataLabelTrans } from '../../../utils/commonTransComponents';
import {
  WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE,
  WORKER_APP_TASKS_ROUTE,
} from '../../../constants/routes';
import {
  USER_SETTINGS_ID,
  WORKER_APP_VIEW_MODE,
} from '../constants';
import './style.css';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { Trans } from '@lingui/macro';
import { WorkerAppTypeTopBar } from '../WorkerAppTypeTopBar/WorkerAppTypeTopBar';
import { WorkerAppTasksViewModeTitleTrans } from './constants';
import { saveUserSettings } from '../../../operations/userSettings';


const WORKER_APP_VIEW_MODE_CHOOSING_SCREEN_GRID_PROPS = {
  direction: 'column',
  justify: 'center',
  alignItems: 'center',
};

const _renderViewModeItemContent = (title, description) =>
  <React.Fragment>
    <div className="view-mode-item-content__card-title">
      {title}
    </div>
    <div className="view-mode-item-content__card-description">
      {description}
    </div>
  </React.Fragment>;

const WORKER_APP_ITEMS = [
  {
    itemId: WORKER_APP_VIEW_MODE.TASKS,
    itemData: { appMainRouteLink: WORKER_APP_TASKS_ROUTE },
    itemContent: _renderViewModeItemContent(
      WorkerAppTasksViewModeTitleTrans,
      <Trans id="worker_app_view_mode_choosing.tasks@description">
        Список всех заданий с возможностью фильтрации
      </Trans>,
    ),
    itemClassName: 'worker-app-tasks-view-mode__card',
    gridItemWrapClassName: 'worker-app-tasks-view-mode__card-grid-wrap',
  },
  {
    itemId: WORKER_APP_VIEW_MODE.EQ_CLASS_IN_DEP_TASKS,
    itemData: { appMainRouteLink: WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE },
    itemContent: _renderViewModeItemContent(
      <Trans id="worker_app_view_mode_choosing.eq_class_in_dep_tasks@title">
        Задания на класс РЦ
      </Trans>,
      <Trans id="worker_app_view_mode_choosing.eq_class_in_dep_tasks@description">
        Список заданий для выбранного класса РЦ в подразделении
      </Trans>,
    ),
    itemClassName: 'worker-app-eq-class-in-dep-tasks-view-mode__card',
    gridItemWrapClassName: 'worker-app-eq-class-in-dep-tasks-view-mode__card-grid-wrap',
  },
];


export const WorkerAppViewModeChoosingScreen = () => {
  const dispatch = useDispatch();

  const onSelect = useCallback(
    (workerAppViewMode, { appMainRouteLink }) => {
      let settingsToSave = {};

      if (workerAppViewMode === WORKER_APP_VIEW_MODE.TASKS) {
        settingsToSave = {
          [USER_SETTINGS_ID.WORKER_APP_VIEW_MODE]: workerAppViewMode,
          [USER_SETTINGS_ID.WORKER_APP_LAST_VISITED_ROUTE]: appMainRouteLink,
        };
      }

      /*
      При выборе режима отображения "Задания для класса РЦ" на этом этапе сохраняем только viewMode,
      пока мы не знаем какое подразделение и класс РЦ выберет рабочий, ссылка на последний посещённый
      роут будет сохранена позже, после выбора класса РЦ
      */
      if (workerAppViewMode === WORKER_APP_VIEW_MODE.EQ_CLASS_IN_DEP_TASKS) {
        settingsToSave = {
          [USER_SETTINGS_ID.WORKER_APP_VIEW_MODE]: workerAppViewMode,
        };
      }
      
      return dispatch(saveUserSettings(settingsToSave))
        .then(() => dispatch(push(appMainRouteLink)));
    },
    [dispatch],
  );

  return (
    <div className="worker-app-view-mode-choosing-screen">
      <WorkerAppTypeTopBar />
      <SimpleItemChoosePanel
          itemsList={WORKER_APP_ITEMS}
          onSelectItem={onSelect}
          gridProps={WORKER_APP_VIEW_MODE_CHOOSING_SCREEN_GRID_PROPS}
          noItemsText={NoDataLabelTrans}
      />
    </div>
  );
};
