export const ENTITY_GROUP = {
  NOT_PRODUCIBLE_WITHOUT_SPECIFICATION: 0,
  PRODUCIBLE_WITHOUT_SPECIFICATION: 1,
  PRODUCIBLE_WITH_SPECIFICATION: 2,
  NOT_PRODUCIBLE_WITH_SPECIFICATION: 3,
};

export const NAME_FIELD_DEFAULT_SORT_BY = [
  {
    column: 'name',
    params: [{ key: 'asc', value: true }],
  },
];

export const IDENTITY_FIELD_DEFAULT_SORT_BY = [
  {
    column: 'identity',
    params: [{ key: 'asc', value: true }],
  },
];

export const CODE_FIELD_DEFAULT_SORT_BY = [
  {
    column: 'code',
    params: [{ key: 'asc', value: true }],
  },
];