import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import DialogTitle from '@mui/material/DialogTitle';
import { Alert, Button, Chip, Dialog, DialogContent, Link } from '@mui/material';
import { ClipLoader } from 'react-spinners';
import { Trans } from '@lingui/macro';
import { HTTP_REQUEST_STATUS } from '../../../api/httpRequestsApi/constants';

import {
  MATERIAL_UI_DIALOG_MAX_WIDTH,
  MATERIAL_UI_SEVERITY,
  MATERIAL_UI_STYLE_COLOR,
  MATERIAL_UI_VARIANT,
} from '../../../constants/materialUI';

import './style.css';
import { FUNC_IS_REQUIRED_TYPE } from '../../../constants/propTypes';
import { useBarcodeScanner } from './useBarcodeScanner';


export const SCAN_BARCODE_DIALOG_STATE = {
  DEFAULT: 'DEFAULT',
  SEARCHING: 'SEARCHING',
  NOT_FOUND: 'NOT_FOUND',
  CANCELLED: 'CANCELLED',
};

const LABELS = {
  DIALOG_TITLE:
    <Trans
        id="tasks.scan_barcode_dialog@title"
    >
      Отсканируйте штрих-код
    </Trans>,
  MAIN_CALL_TEXT:
    <Trans
        id="tasks.scan_barcode_dialog@call_to_scan_text_sheet"
    >
      Для поиска маршрутного листа отсканируйте штрих-код
    </Trans>,
  OR_CALL_TEXT:
    <>
      <Trans
          id="tasks.scan_barcode_dialog@call_to_scan_or_text_sheet"
      >
        или введите ИД МЛ
      </Trans>
      {' '}
    </>,
  MANUAL_CALL_TEXT:
    <Trans
        id="tasks.scan_barcode_dialog@call_to_scan_manual"
    >
      вручную
    </Trans>,
  SEARCHING_NAME_LABEL:
    <>
      <Trans
          id="tasks.scan_barcode_dialog@sheet_label"
      >
        Маршрутный лист
      </Trans>
      {': '}
    </>,
  SEARCHING_TEXT:
    <>
      <Trans
          id="tasks.scan_barcode_dialog@searching_label_sheet"
      >
        Идет поиск МЛ
      </Trans>
      {'...'}
    </>,
  NOT_FOUND_TEXT:
    <Trans
        id="tasks.scan_barcode_dialog@not_found_label_sheet"
    >
      Маршрутный лист не найден, попробуйте отсканировать штрих-код ещё раз
    </Trans>,
  CANCELLED_TEXT:
    <Trans
        id="tasks.scan_barcode_dialog@search_cancelled_label"
    >
      Поиск отменен. Отсканируйте штрих-код ещё раз
    </Trans>,
};

const CANCEL_SEARCH_LABEL = (
  <Trans
      id="tasks.scan_barcode_dialog@cancel_search_label"
  >
    Отмена
  </Trans>
);
const CLOSE_LABEL = (
  <Trans
      id="tasks.scan_barcode_dialog@close_label"
  >
    Выйти
  </Trans>
);

const START = _get(window, ['config', 'BARCODE_SCANNER', 'SEARCH_SHEET', 'START']);
const END = _get(window, ['config', 'BARCODE_SCANNER', 'SEARCH_SHEET', 'END']);
const DELAY = _get(window, ['config', 'BARCODE_SCANNER', 'SEARCH_SHEET', 'DELAY']);

export const ScanBarcodeDialog = ({
  onClose,
  onScan,
  onManualLinkClick,
  onCancel,
}) => {

  const [scannedBarcode, setScannedBarcode] = useState('');
  const [state, setState] = useState(SCAN_BARCODE_DIALOG_STATE.DEFAULT);

  const renderProps = {
    onManualLinkClick,
    scannedBarcode,
  };

  const handleScan = useCallback(
    barCode => {
      setState(SCAN_BARCODE_DIALOG_STATE.SEARCHING);
      setScannedBarcode(barCode);

      return onScan(barCode)
        .catch(e => {
          if (e?.status === HTTP_REQUEST_STATUS.ABORTED) {
            setState(SCAN_BARCODE_DIALOG_STATE.CANCELLED);
          } else {
            setState(SCAN_BARCODE_DIALOG_STATE.NOT_FOUND);
          }
        });
    },
    [onScan, setState, setScannedBarcode],
  );

  const useBarCodeScannerParams = useMemo(
    () => ({
      startKey: START,
      endKey: END,
      delay: DELAY,
      onScanned: handleScan,
    }),
    [handleScan],
  );

  useBarcodeScanner(useBarCodeScannerParams);

  return (
    <Dialog
        open
        maxWidth={MATERIAL_UI_DIALOG_MAX_WIDTH.SM}
        fullWidth
    >
      {_renderDialogTitle()}
      <DialogContent className="scan-barcode-dialog__content">
        {CONTENT_BY_STATE[state](renderProps)}
        {_renderCloseCancelButtons(state, onClose, onCancel)}
      </DialogContent>
    </Dialog>
  );
};
ScanBarcodeDialog.propTypes = {
  onClose: FUNC_IS_REQUIRED_TYPE,
  onScan: FUNC_IS_REQUIRED_TYPE,
  onManualLinkClick: FUNC_IS_REQUIRED_TYPE,
  onCancel: FUNC_IS_REQUIRED_TYPE,
};

const _renderCloseCancelButtons = (
  state,
  handleCloseClick,
  handleCancelClick,
) => {
  if (state === SCAN_BARCODE_DIALOG_STATE.SEARCHING) {
    return (
      <Button
          variant={MATERIAL_UI_VARIANT.OUTLINED}
          color={MATERIAL_UI_STYLE_COLOR.INHERIT}
          className="scan-barcode-dialog__call-to-scan-close-button"
          onClick={handleCancelClick}
      >
        {CANCEL_SEARCH_LABEL}
      </Button>
    );
  }

  return (
    <Button
        variant={MATERIAL_UI_VARIANT.OUTLINED}
        color={MATERIAL_UI_STYLE_COLOR.INHERIT}
        className="scan-barcode-dialog__call-to-scan-close-button"
        onClick={handleCloseClick}
    >
      {CLOSE_LABEL}
    </Button>
  );
};

const _renderDialogTitle = () => {
  return (
    <DialogTitle className="scan-barcode-dialog__title-wrapper">
      <div className="scan-barcode-dialog__title">
        {LABELS.DIALOG_TITLE}
      </div>
    </DialogTitle>
  );
};

const _renderSearching = ({
  scannedBarcode,
}) => {
  const chipLabel = (
    <div>
      <span className="scan-barcode-dialog__searching-name-label">{LABELS.SEARCHING_NAME_LABEL}</span>
      <span>{scannedBarcode}</span>
    </div>
  );

  return (
    <div className="scan-barcode-dialog__searching-wrapper">
      <Chip
          label={chipLabel}
          classes={{
            root: 'scan-barcode-dialog__searching-name',
          }}
      />
      <div className="scan-barcode-dialog__searching-text">
        {LABELS.SEARCHING_TEXT}
      </div>
      <ClipLoader
          size={80}
          loading
      />
    </div>
  );
};
_renderSearching.propTypes = {
  scannedBarcode: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
};

const _renderCallToScan = ({
  mainCallText = LABELS.MAIN_CALL_TEXT,
  onManualLinkClick,
}) => {
  return (
    <div className="scan-barcode-dialog__call-to-scan-wrapper">
      <div className="scan-barcode-dialog__call-to-scan-item">{mainCallText}</div>
      <div className="scan-barcode-dialog__call-to-scan-item">
        <span>{LABELS.OR_CALL_TEXT}</span>
        <Link
            onClick={onManualLinkClick}
            underline="always"
            className="scan-barcode-dialog__call-to-scan-link"
        >
          {LABELS.MANUAL_CALL_TEXT}
        </Link>
      </div>
    </div>
  );
};
_renderCallToScan.propTypes = {
  mainCallText: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  onManualLinkClick: FUNC_IS_REQUIRED_TYPE,
};

const _renderNotFound = ({ onManualLinkClick, scannedBarcode }) => {
  return _renderCallToScan({
    onManualLinkClick,
    mainCallText: (
      <Alert
          severity={MATERIAL_UI_SEVERITY.WARNING}
          className="scan-barcode-dialog__not-found-alert"
          icon={false}
      >
        <div>{scannedBarcode}</div>
        <div>{LABELS.NOT_FOUND_TEXT}</div>
      </Alert>
    ),
  });
};

const _renderCancelled = ({ onManualLinkClick }) => {
  return _renderCallToScan({
    onManualLinkClick,
    mainCallText: (
      <Alert
          severity={MATERIAL_UI_SEVERITY.WARNING}
          className="scan-barcode-dialog__not-found-alert"
          icon={false}
      >
        <div>{LABELS.CANCELLED_TEXT}</div>
      </Alert>
    ),
  });
};

const CONTENT_BY_STATE = {
  [SCAN_BARCODE_DIALOG_STATE.DEFAULT]: _renderCallToScan,
  [SCAN_BARCODE_DIALOG_STATE.SEARCHING]: _renderSearching,
  [SCAN_BARCODE_DIALOG_STATE.NOT_FOUND]: _renderNotFound,
  [SCAN_BARCODE_DIALOG_STATE.CANCELLED]: _renderCancelled,
};
