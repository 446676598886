import { createSelector } from 'reselect';
import { workerAppAssemblySheetsSelector } from '../../selectors';
import { addDecimals, subDecimals } from '@bfg-frontend/utils/lib/decimal';
import { getEntityCombinedName } from '@bfg-frontend/utils/lib/stringBuilders/entity';

export const assemblySheetConsumeDataSelector = createSelector(
  (state, { sheetId }) => workerAppAssemblySheetsSelector(state).consumeData[sheetId],
  consumeData => {
    if (consumeData === undefined) return undefined;

    return consumeData.reduce((acc, current) => {
      const {
        entityId,
        entityIdentity,
        entityName,
        entityCode,
        entityBatches,
        requiredAmount,
      } = current;

      let totalReservedAmount = 0;
      let totalConsumedAmount = 0;

      acc[entityId] = {
        entityId,
        entityIdentity,
        entityName,
        entityCode,
        entityCombinedName: getEntityCombinedName({
          identity: entityIdentity,
          code: entityCode,
          name: entityName,
        }),
        requiredAmount,
        entityBatches: entityBatches.reduce((batchesAcc, item) => {

          const {
              reservedAmount,
              consumedAmount,
              identity,
              id,
            } = item;

          // считаем суммарное количество зарезервированных и потреблённых ДСЕ
          totalReservedAmount = addDecimals(totalReservedAmount, reservedAmount);
          totalConsumedAmount = addDecimals(totalConsumedAmount, consumedAmount);

          batchesAcc[id] = {
            id,
            identity,
            reservedAmount,
            consumedAmount,
          };

          return batchesAcc;
        },
        {}),
      };

      acc[entityId].reservedAmount = totalReservedAmount;
      acc[entityId].consumedAmount = totalConsumedAmount;
      acc[entityId].remainingAmount = subDecimals(requiredAmount, totalConsumedAmount);

      return acc;
    }, {});
  },
);
