import {
  fetchPausedSheets,
  fetchSheetsInProduction,
  fetchDefaultSheetsWithEnoughPartsAndMaterialsInStorage,
} from '../../../../operations/sheets';

import { ENTITY_BATCH_MODEL, SHEET_MODEL } from '../../../../constants/models';
import {
  MASTER_APP_PAUSED_SHEETS_ROUTE,
  MASTER_APP_TASKS_TO_DO_ROUTE,
  PLANNER_APP_PAUSED_SHEETS_ROUTE,
  PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE,
  STORAGE_MANAGEMENT_APP_ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
  STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
  WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE,
  WORKER_APP_TASKS_ROUTE,
} from '../../../../constants/routes';
import { matchPath } from 'react-router-dom';
import {
  fetchEquipmentClassInDepartmentTasksRemoteTableData,
  fetchTasksRemoteTableData,
} from '../../../../operations/tasks';
import { routerPathnameSelector } from '../../../../reducers/router/selectors';
import { clearTableData, clearTableRemoteData, reFetchRemoteTableData } from '../../../../reducers/table/actions';
import { ENTITY_BATCH_PROVIDING_STATE, SHEET_TYPE } from '../../../../constants/sheets';
import {
  fetchSheetTypeRemoteTableDataCbFactory,
  fetchSheetTypeToReviewCbFactory,
} from '../../../../components/Sheets/SheetsContainer';

import { push } from 'connected-react-router';

import { addEntitiesToStore } from '../../../../reducers/entities/actions';
import { fetchDepartmentsWithTasks } from '../../../../operations/departments';
import { fetchEquipmentClassesForDepartmentWithTasks } from '../../../../operations/equipmentClasses';
import { createSheetTypeOperationsTableId, getEquipmentClassInDepartmentTasksTableId } from '../../../../utils/tables';
import {
  clearAllDepartmentsWithTasks,
  clearAllEquipmentClassesInDepartmentWithTask, clearEquipmentClassesInDepartmentWithTasks,
} from '../../../../reducers/workerApp/tasksOwners/actions';
import { allTasksTablesIdsSelector } from '../../../../selectors/taskView';
import { eqClInDepsWithTasksEntitiesSelector } from '../../../../reducers/workerApp/tasksOwners/selectors';
import { MASTER_TASKS_TO_DO_TABLE_ID } from '../../../../components/MasterApp/MasterTasksToDo/constants';
import { fetchMasterDepartmentsTasksToDoRemoteTableData } from '../../../../operations/masterWorkspace/index';
import { getMasterAppDepartmentIdsFromRouteParam } from '../../../../components/MasterApp/MasterWorkspace/masterAppDepartmentIdsRouteParam';
import { masterTasksToDoAdditionalFiltersSelector } from '../../../../reducers/masterApp/tasksToDoAdditionalFilters/selectors';
import {
  MASTER_APP_PAUSED_SHEETS_TABLE_ID,
  PAUSED_SHEETS_TABLES_IDS_ARRAY,
  PLANNER_APP_PAUSED_SHEETS_TABLE_ID,
} from '../../../../constants/table';
import { fetchFullAssemblySheetsPartsAndMaterials } from '../../../../components/StorageManagementApp/SheetsWaitingPartsAndMaterials/AssemblySheetsWaitingPartsAndMaterials/AssemblySheetsWaitingPartsAndMaterials';
import { TASKS_MODEL } from '../../../../reducers/schemaModel/models/tasksSchema';
import { MASTER_TASKS_TO_DO_TABLE_MODEL } from '../../../../reducers/schemaModel/models/masterTasksSchema';


/*
* Подробно о влиянии события возвращения МЛ в производство на типы приложения описано в комментарии к handleSheetResumed
* в PausedSheetReviewContentContainer. Т.к. handleSheetResumed выполняется для пользователя, который сам возвращает МЛ
* в производство из раздела приостановленных МЛ, то в нём для всех остальных разделов, где надо обновить данные, в
* основном, сбрасывается "клиентский кэш" (т.е. данные из стора), чтобы, при повторном входе в интерфейсы, запрос
* выполнился по новой.
* В случае с получением события возвращения МЛ в производство другими пользователями нужна похожая обработка, но, с
* учетом, того, что пользователи могут находиться в этот момент в разделах, которые сразу же должны быть обновлены - в
* этом случае, сбрасывать "кэш" не нужно, а нужен именно перезапрос \ обновление данных. Если перед перезапросом данных
* мы сначала очистим "кэш" (т.е. данные текущего раздела), а только потом перезапросим новые данные, то у пользователя
* в интерфейсе случится "скачок" (данные пропадут, кое-где мелькнет сообщение о пустых данных, а потом тут же появятся
* новые данные), что нежелательно.
* Для всех остальных разделов, где нужны обновления, кроме текущего, требуется, аналогично, очистить "кэш", чтобы при
* повторном входе в интерфейсы запросы выполнились заново
* */
export const handleSheetResume = message =>
  (dispatch, getState) => {
    const {
      sheetId,
    } = message;

    const state = getState();

    const allTasksTablesIds = allTasksTablesIdsSelector(state);

    const currentPathname = routerPathnameSelector(state);

    //Если находимся в разделе "Плановик. МЛ в производстве"
    const sheetsInProductionRouteMatch = matchPath(currentPathname, {
      path: PLANNER_APP_SHEETS_IN_PRODUCTION_ROUTE,
    });
    if(sheetsInProductionRouteMatch !== null)
      return dispatch(_updateIfOnSheetsInProductionScreen(sheetId, allTasksTablesIds));


    //Если находимся в разделе "Плановик. Приостановленные МЛ"
    const plannerAppPausedSheetsRouteMatch = matchPath(currentPathname, {
      path: PLANNER_APP_PAUSED_SHEETS_ROUTE,
    });
    if(plannerAppPausedSheetsRouteMatch !== null)
      return dispatch(_updateIfOnPlannerAppPausedSheets(
        sheetId,
        currentPathname,
        allTasksTablesIds,
      ));

    //Если находимся в разделе "Мастер. Приостановленные МЛ"
    const masterAppPausedSheetsRouteMatch = matchPath(currentPathname, {
      path: MASTER_APP_PAUSED_SHEETS_ROUTE,
    });
    if(masterAppPausedSheetsRouteMatch !== null)
      return dispatch(_updateIfOnMasterAppPausedSheets(
        sheetId,
        currentPathname,
        allTasksTablesIds,
      ));

    //Если находимся в разделе "Комплектование - "стандартные" неукомплектованные маршрутные листы"
    const defaultSheetsWaitingPartsAndMaterialsRouteMatch = matchPath(currentPathname, {
      path: STORAGE_MANAGEMENT_APP_DEFAULT_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
    });
    if(defaultSheetsWaitingPartsAndMaterialsRouteMatch !== null)
      return dispatch(_updateIfOnDefaultSheetsWaitingPartsAndMaterialsAppScreen(sheetId, allTasksTablesIds));

    //Если находимся в разделе "Комплектование - "сборочные" маршрутные листы"
    const assemblySheetsWaitingPartsAndMaterialsRouteMatch = matchPath(currentPathname, {
      path: STORAGE_MANAGEMENT_APP_ASSEMBLY_SHEETS_WAITING_PARTS_AND_MATERIALS_ROUTE,
    });
    if(assemblySheetsWaitingPartsAndMaterialsRouteMatch !== null)
      return dispatch(_updateIfOnAssemblySheetsWaitingPartsAndMaterialsScreen(sheetId, allTasksTablesIds));

    //Если находимся  в разделе "Мастер. Требуется выполнить"
    const masterTasksTodoRouteMatch = matchPath(currentPathname, {
      path: MASTER_APP_TASKS_TO_DO_ROUTE,
    });
    if(masterTasksTodoRouteMatch !== null)
      return dispatch(_updateIfOnMasterTasksTodoScreen(
        sheetId,
        getMasterAppDepartmentIdsFromRouteParam(masterTasksTodoRouteMatch.params.departmentIds),
        allTasksTablesIds,
      ));

    //Если находимся в разделе "Рабочий. Выбор подразделений", т.е. строго по роуту WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE
    const departmentChooseScreenRouteMatch = matchPath(currentPathname, {
      path: WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE,
    });
    if(departmentChooseScreenRouteMatch !== null && departmentChooseScreenRouteMatch.isExact)
      return dispatch(_updateIfOnDepartmentChooseScreen(sheetId, allTasksTablesIds));


    //Если находимся в разделе "Рабочий. Выбор класса РЦ в подразделении", т.е. строго по роуту
    //`${WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE}/:departmentId`
    const equipmentClassInDepartmentChooseRouteMatch = matchPath(currentPathname, {
      path: `${WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE}/:departmentId`,
    });
    if(equipmentClassInDepartmentChooseRouteMatch !== null && equipmentClassInDepartmentChooseRouteMatch.isExact)
      return dispatch(_updateIfOnEquipmentClassInDepartmentChooseScreen(
        sheetId,
        equipmentClassInDepartmentChooseRouteMatch.params.departmentId,
        allTasksTablesIds,
      ));


    //Если находимся в разделе "Рабочий. Просмотр задания для класса РЦ в подразделении"
    const tasksForEquipmentClassInDepartmentRouteMatch = matchPath(currentPathname, {
      path: `${WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE}/:departmentId/:equipmentClassId`,
    });
    if(tasksForEquipmentClassInDepartmentRouteMatch !== null) {
      const {
        departmentId,
        equipmentClassId,
      } = tasksForEquipmentClassInDepartmentRouteMatch.params;

      return dispatch(_updateIfOnTasksViewScreen(
        sheetId,
        departmentId,
        equipmentClassId,
        allTasksTablesIds,
      ));
    }

    //Если находимся в разделе "Рабочий. Просмотр ВСЕХ заданий"
    const workerAppTasksRouteMatch = matchPath(currentPathname, {
      path: WORKER_APP_TASKS_ROUTE,
    });
    if(workerAppTasksRouteMatch !== null) {
      return dispatch(_updateIfOnWorkerAppTasksScreen(
        sheetId,
        allTasksTablesIds,
      ));
    }

    /*
    * Для всех остальных разделов не нужна какая-то дополнительная обработка, но и для этого случая необходимо
    * очистить данные всех интерфейсов, где должны быть обновления из-за возвращения МЛ в производство, чтобы при
    * следующем входе в эти разделы данные были запрошены заново
    * */
    dispatch([
      clearTableData(
        PAUSED_SHEETS_TABLES_IDS_ARRAY
          .map(tableId => createSheetTypeOperationsTableId(tableId, sheetId)),
      ),
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        ...PAUSED_SHEETS_TABLES_IDS_ARRAY,
        MASTER_TASKS_TO_DO_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),
      clearAllDepartmentsWithTasks(),
      clearAllEquipmentClassesInDepartmentWithTask(),
    ]);
  };

/*
* Если находимся в разделе "Плановик. МЛ в производстве", то очищаем все данные других интерфейсов кроме этого, где
* должны быть обновления из-за возвращения МЛ в производство, а для этого раздела перезапрашиваем данные списка для
* текущей страницы
* */
const _updateIfOnSheetsInProductionScreen = (sheetId, allTasksTablesIds) =>
  dispatch => {

    dispatch([
      clearTableData(
        PAUSED_SHEETS_TABLES_IDS_ARRAY
          .map(tableId => createSheetTypeOperationsTableId(tableId, sheetId)),
      ),
      clearTableRemoteData([
        ...PAUSED_SHEETS_TABLES_IDS_ARRAY,
        MASTER_TASKS_TO_DO_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),
      clearAllDepartmentsWithTasks(),
      clearAllEquipmentClassesInDepartmentWithTask(),
    ]);

    dispatch(reFetchRemoteTableData(
      SHEET_TYPE.IN_PRODUCTION,
      SHEET_TYPE.IN_PRODUCTION,
      fetchSheetTypeRemoteTableDataCbFactory(
        dispatch,
        query => fetchSheetsInProduction(query, { isBlockingRequest: false }),
      ),
    ));
  };


/*
* Если находимся в разделе "Плановик. Приостановленные МЛ", то очищаем все данные других интерфейсов кроме этого, где
* должны быть обновления из-за возвращения МЛ в производство, а для этого раздела перезапрашиваем данные списка для
* текущей страницы. Дополнительно, если находимся в режиме просмотра МЛ, который только что вернули в производство,
* то редиректим пользователя на экран просмотра списка МЛ
*/
const _updateIfOnPlannerAppPausedSheets = (sheetId, currentPathname, allTasksTablesIds) =>
  dispatch => {

    const pausedSheetsTablesIdsWithoutCurrent = PAUSED_SHEETS_TABLES_IDS_ARRAY
      .filter(tableId => tableId !== PLANNER_APP_PAUSED_SHEETS_TABLE_ID);

    dispatch([
      clearTableData(
        PAUSED_SHEETS_TABLES_IDS_ARRAY
          .map(tableId => createSheetTypeOperationsTableId(tableId, sheetId)),
      ),
      clearTableRemoteData([
        ...pausedSheetsTablesIdsWithoutCurrent,
        SHEET_TYPE.IN_PRODUCTION,
        MASTER_TASKS_TO_DO_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),
      clearAllDepartmentsWithTasks(),
      clearAllEquipmentClassesInDepartmentWithTask(),
    ]);

    dispatch(reFetchRemoteTableData(
      PLANNER_APP_PAUSED_SHEETS_TABLE_ID,
      PLANNER_APP_PAUSED_SHEETS_TABLE_ID,
      fetchSheetTypeRemoteTableDataCbFactory(
        dispatch,
        query => fetchPausedSheets(query, { isBlockingRequest: false }),
      ),
    ));

    const plannerAppPausedSheetReviewRouteMatch = matchPath(currentPathname, {
      path: `${PLANNER_APP_PAUSED_SHEETS_ROUTE}/:sheetId`,
    });

    if(
      plannerAppPausedSheetReviewRouteMatch !== null &&
      plannerAppPausedSheetReviewRouteMatch.params.sheetId === sheetId.toString()
    ) {
      return dispatch(push(PLANNER_APP_PAUSED_SHEETS_ROUTE));
    }
  };

/*
* Если находимся в разделе "Мастер. Приостановленные МЛ", то очищаем все данные других интерфейсов кроме этого, где
* должны быть обновления из-за возвращения МЛ в производство, а для этого раздела перезапрашиваем данные списка для
* текущей страницы. Дополнительно, если находимся в режиме просмотра МЛ, который только что вернули в производство,
* то редиректим пользователя на экран просмотра списка МЛ
*/
const _updateIfOnMasterAppPausedSheets = (sheetId, currentPathname, allTasksTablesIds) =>
  dispatch => {

    const pausedSheetsTablesIdsWithoutCurrent = PAUSED_SHEETS_TABLES_IDS_ARRAY
      .filter(tableId => tableId !== MASTER_APP_PAUSED_SHEETS_TABLE_ID);

    dispatch([
      clearTableData(
        PAUSED_SHEETS_TABLES_IDS_ARRAY
          .map(tableId => createSheetTypeOperationsTableId(tableId, sheetId)),
      ),
      clearTableRemoteData([
        ...pausedSheetsTablesIdsWithoutCurrent,
        SHEET_TYPE.IN_PRODUCTION,
        MASTER_TASKS_TO_DO_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),
      clearAllDepartmentsWithTasks(),
      clearAllEquipmentClassesInDepartmentWithTask(),
    ]);

    dispatch(reFetchRemoteTableData(
      MASTER_APP_PAUSED_SHEETS_TABLE_ID,
      MASTER_APP_PAUSED_SHEETS_TABLE_ID,
      fetchSheetTypeRemoteTableDataCbFactory(
        dispatch,
        query => fetchPausedSheets(query, { isBlockingRequest: false }),
      ),
    ));

    const masterAppPausedSheetReviewRouteMatch = matchPath(currentPathname, {
      path: `${MASTER_APP_PAUSED_SHEETS_ROUTE}/:sheetId`,
    });

    if(
      masterAppPausedSheetReviewRouteMatch !== null &&
      masterAppPausedSheetReviewRouteMatch.params.sheetId === sheetId.toString()
    ) {
      return dispatch(push(MASTER_APP_PAUSED_SHEETS_ROUTE));
    }
  };

/*
* Если находимся в разделе "Комлектование - стандартные МЛ", то очищаем данные всех интерфейсов, где должны быть
* обновления из-за  возвращения МЛ в производство. После этого запрашиваем сущность возвращенного в производство МЛ,
* чтобы понять является ли он неукомплектованным. Если он является неукомплектованным, значит список МЛ, ожидающих
* комплектацию нужно обновить.
* С учетом всех описанных в комментарии к handleSheetResumed в PausedSheetReviewContentContainer особенностей про
* клиентскую таблицу МЛ, ожидающих комплектацию, требуется:
* - Записать сущность только что полученного в запросе МЛ в хранилище entities
* - Перезапросить информацию о МЛ, которые можно укомплектовать
*
* Когда таблица станет серверной, то эту обработку надо будет заменить на перезапрос текущей страницы просмотра,
* аналогично обрботке списка МЛ в производстве. (Возможно, в будущем и запрос о возможности комплектации можно будет
* сделать на 1 МЛ, а не на все МЛ, тогда тут можно будет подумать над новой логикой запроса и хранения кэшированной
* информации по МЛ, которые можно укомплектовать).
* */
const _updateIfOnDefaultSheetsWaitingPartsAndMaterialsAppScreen = (sheetId, allTasksTablesIds) =>
  dispatch => {

    dispatch([
      clearTableData(
        PAUSED_SHEETS_TABLES_IDS_ARRAY
          .map(tableId => createSheetTypeOperationsTableId(tableId, sheetId)),
      ),
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        ...PAUSED_SHEETS_TABLES_IDS_ARRAY,
        MASTER_TASKS_TO_DO_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),
      clearAllDepartmentsWithTasks(),
      clearAllEquipmentClassesInDepartmentWithTask(),
    ]);

    const fetchSheet = fetchSheetTypeToReviewCbFactory(
      dispatch,
      query => fetchSheetsInProduction(query, { isBlockingRequest: false }),
    );

    fetchSheet(sheetId)
      .then(response => {

        if(!response || response.responseMeta.count === 0) return;

        const {
          entities,
          responseEntitiesIds,
        } = response;

        const { isAssembly } = entities[SHEET_MODEL][sheetId];

        if(isAssembly) return;

        const entityBatchId = responseEntitiesIds[ENTITY_BATCH_MODEL][0];

        const { providingState } = entities[ENTITY_BATCH_MODEL][entityBatchId];

        if(providingState === ENTITY_BATCH_PROVIDING_STATE.UNPROVIDED) {
          dispatch(addEntitiesToStore(entities));

          //Это thunk, поэтому нет смысла объединять с прошлым dispatch
          return dispatch(fetchDefaultSheetsWithEnoughPartsAndMaterialsInStorage({ isBlockingRequest: false }));
        }
      });
  };

/*
 * Если находимся в разделе "Комплектование - сборочные", то очищаем все данные других интерфейсов кроме
 * этого, где должны быть обновления из-за возобновления работы над МЛ, а для этого раздела перезапрашиваем данные
 * списка сборочных МЛ для текущей страницы.
 * */
const _updateIfOnAssemblySheetsWaitingPartsAndMaterialsScreen = (sheetId, allTasksTablesIds) =>
  dispatch => {
    dispatch([
      clearTableData(
        PAUSED_SHEETS_TABLES_IDS_ARRAY
          .map(tableId => createSheetTypeOperationsTableId(tableId, sheetId)),
      ),
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        MASTER_TASKS_TO_DO_TABLE_ID,
        ...PAUSED_SHEETS_TABLES_IDS_ARRAY,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),
      clearAllDepartmentsWithTasks(),
      clearAllEquipmentClassesInDepartmentWithTask(),
    ]);

    dispatch(reFetchRemoteTableData(
      SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
      fetchSheetTypeRemoteTableDataCbFactory(
        dispatch,
        query => fetchFullAssemblySheetsPartsAndMaterials(query, { isBlockingRequest: false }),
      ),
    ));
  };

/*
* Если находимся в разделе "Мастер. Требуется выполнить", то очищаем все данные других интерфейсов кроме этого, где
* должны быть обновления из-за возвращения МЛ в производство, а для этого раздела перезапрашиваем данные для таблицы
* заданий, которые нужно выполнить в подразделениях из параметров роутинга, с учетом текущих параметров таблицы
* */
const _updateIfOnMasterTasksTodoScreen = (sheetId, departmentIds, allTasksTablesIds) =>
  (dispatch, getState) => {

    dispatch([
      clearTableData(
        PAUSED_SHEETS_TABLES_IDS_ARRAY
          .map(tableId => createSheetTypeOperationsTableId(tableId, sheetId)),
      ),
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        ...PAUSED_SHEETS_TABLES_IDS_ARRAY,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),
      clearAllDepartmentsWithTasks(),
      clearAllEquipmentClassesInDepartmentWithTask(),
    ]);

    const masterTasksToDoAdditionalFilters = masterTasksToDoAdditionalFiltersSelector(getState());

    dispatch(reFetchRemoteTableData(
      MASTER_TASKS_TO_DO_TABLE_ID,
      MASTER_TASKS_TO_DO_TABLE_MODEL,
      ({ tableParams }) =>
        dispatch(fetchMasterDepartmentsTasksToDoRemoteTableData(
          departmentIds,
          masterTasksToDoAdditionalFilters,
          tableParams,
          { isBlockingRequest: false },
        )),
    ));
  };


/*
* Если находимся в разделе "Рабочий. Выбор подразделений", т.е. строго по роуту WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE, то очищаем все
* данные других интерфейсов кроме этого, где должны быть обновления из-за возвращения МЛ в производство, а для этого
* раздела перезапрашиваем подразделения с заданиями, чтобы список обновился
* */
const _updateIfOnDepartmentChooseScreen = (sheetId, allTasksTablesIds) =>
  dispatch => {

    dispatch([
      clearTableData(
        PAUSED_SHEETS_TABLES_IDS_ARRAY
          .map(tableId => createSheetTypeOperationsTableId(tableId, sheetId)),
      ),
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        ...PAUSED_SHEETS_TABLES_IDS_ARRAY,
        MASTER_TASKS_TO_DO_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),
      clearAllEquipmentClassesInDepartmentWithTask(),
    ]);

    dispatch(fetchDepartmentsWithTasks({ isBlockingRequest: false }));
  };


/*
* Если находимся в разделе "Рабочий. Выбор класса РЦ подразделении", т.е. строго по роуту
* `${WORKER_APP_EQUIPMENT_CLASS_IN_DEPARTMENT_TASKS_ROUTE}/:departmentId`, то очищаем все данные других интерфейсов кроме этого, где должны быть
* обновления из-за возвращения МЛ в производство, а для этого раздела перезапрашиваем классы РЦ в подразделении с
* заданиями, чтобы список обновился.
* */
const _updateIfOnEquipmentClassInDepartmentChooseScreen = (
  sheetId,
  departmentIdFromRoute,
  allTasksTablesIds,
) =>
  (dispatch, getState) => {

    //список классов РЦ для текущего подразделения не очищаем, чтобы не было скачков в интерфейсе, данные в списка
    //обновятся при перезапросе данных
    const eqClInDepsWithTasksEntities = eqClInDepsWithTasksEntitiesSelector(getState());

    const departmentIdsToClear = Object
      .keys(eqClInDepsWithTasksEntities)
      .filter(departmentIdKey => Number(departmentIdKey) !== Number(departmentIdFromRoute));

    dispatch([
      clearTableData(
        PAUSED_SHEETS_TABLES_IDS_ARRAY
          .map(tableId => createSheetTypeOperationsTableId(tableId, sheetId)),
      ),
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        ...PAUSED_SHEETS_TABLES_IDS_ARRAY,
        MASTER_TASKS_TO_DO_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        TASKS_MODEL,
        ...allTasksTablesIds,
      ]),
      clearAllDepartmentsWithTasks(),
      clearEquipmentClassesInDepartmentWithTasks(departmentIdsToClear),
    ]);

    dispatch(fetchEquipmentClassesForDepartmentWithTasks(departmentIdFromRoute, { isBlockingRequest: false }));
  };


/*
* Если находимся в разделе "Рабочий. Просмотр задания для класса РЦ в подразделении", то очищаем все данные других
* интерфейсов кроме этого, где должны быть обновления из-за возвращения МЛ в производство, а для этого раздела
* перезапрашиваем задания для текущих параметров просматриваеомй таблицы, чтобы информация обновилась
* */
const _updateIfOnTasksViewScreen = (
  sheetId,
  departmentIdFromRoute,
  equipmentClassIdFromRoute,
  allTasksTablesIds,
) =>
  dispatch => {
    const currentTableId = getEquipmentClassInDepartmentTasksTableId(
      departmentIdFromRoute,
      equipmentClassIdFromRoute,
    );

    //Текущую таблицу не очищаем, чтобы не было скачков в интерфейсе, данные в ней обновятся при перезапросе данных
    const allTasksTablesIdsWithoutCurrentTableId = allTasksTablesIds
      .filter(tableId => tableId !== currentTableId);

    dispatch([
      clearTableData(
        PAUSED_SHEETS_TABLES_IDS_ARRAY
          .map(tableId => createSheetTypeOperationsTableId(tableId, sheetId)),
      ),
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        ...PAUSED_SHEETS_TABLES_IDS_ARRAY,
        MASTER_TASKS_TO_DO_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        TASKS_MODEL,
        ...allTasksTablesIdsWithoutCurrentTableId,
      ]),
      clearAllDepartmentsWithTasks(),
      clearAllEquipmentClassesInDepartmentWithTask(),
    ]);

    dispatch(reFetchRemoteTableData(
      currentTableId,
      currentTableId,
      ({ tableParams }) =>
        dispatch(fetchEquipmentClassInDepartmentTasksRemoteTableData(
          {
            departmentIdsArray: [departmentIdFromRoute],
            equipmentClassIdsArray: [equipmentClassIdFromRoute],
          },
          tableParams,
          { isBlockingRequest: false },
        )),
    ));
  };

/*
 * Если находимся в разделе "Рабочий. Просмотр ВСЕХ заданий", то очищаем все данные других интерфейсов кроме этого, где
 * должны быть обновления из-за возобновления МЛ, а для этого раздела перезапрашиваем данные для таблицы заданий,
 * с учетом текущих параметров таблицы и внешних фильтров
 * */
const _updateIfOnWorkerAppTasksScreen = (sheetId, allTasksTablesIds) =>
  dispatch => {

    dispatch([
      clearTableData(
        PAUSED_SHEETS_TABLES_IDS_ARRAY
          .map(tableId => createSheetTypeOperationsTableId(tableId, sheetId)),
      ),
      clearTableRemoteData([
        SHEET_TYPE.IN_PRODUCTION,
        ...PAUSED_SHEETS_TABLES_IDS_ARRAY,
        MASTER_TASKS_TO_DO_TABLE_ID,
        SHEET_TYPE.ASSEMBLY_WAITING_PARTS_AND_MATERIALS,
        ...allTasksTablesIds,
      ]),
      clearAllDepartmentsWithTasks(),
      clearAllEquipmentClassesInDepartmentWithTask(),
    ]);

    dispatch(reFetchRemoteTableData(
      TASKS_MODEL,
      TASKS_MODEL,
      ({ tableParams }) =>
        dispatch(fetchTasksRemoteTableData(tableParams, { isBlockingRequest: false })),
    ));
  };
