import _isNil from 'lodash/isNil';
import { getUserFullName } from '@bfg-frontend/utils/lib/stringBuilders/entity';


export const getSheetOperationCombinedName = (operationNumber, operationName, operationIdentity) =>
  `${operationNumber}_${operationName}(${operationIdentity})`;


export const getUserFullNameWithNote = ({ name, lastName, patronymicName, note }) => _isNil(note) || note === '' ?
  getUserFullName({ name, lastName, patronymicName }) :
  `${getUserFullName({ name, lastName, patronymicName })} (${note})`;

/*
* функция обрезает строку по достижении максимальной длины заменяя последний символ на unicode символ многоточия
* */
const DEFAULT_MAX_STRING_LENGTH_IN_SYMBOLS = 40;
export const truncateStringOnMaxLength = (str, maxlength = DEFAULT_MAX_STRING_LENGTH_IN_SYMBOLS) => {
  return (str.length > maxlength) ?
    str.slice(0, maxlength) + '…' : str;
};
