import React from 'react';
import Button from '@mui/material/Button';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { MATERIAL_UI_VARIANT } from '../../../constants/materialUI';
import './style.css';

export const Icon = props => {
  const {
    className: classNameFromProps,
    variant,
    children,
  } = props;

  const className = cn('common-icon', classNameFromProps);

  return (
    <Button
        className={className}
        variant={variant}
        disableRipple
        disableElevation
    >
      {children}
    </Button>
  );
};

Icon.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(MATERIAL_UI_VARIANT)),
  children: PropTypes.node,
};

Icon.defaultProps = {
  variant: MATERIAL_UI_VARIANT.CONTAINED,
};