import { numberComparator } from '@bfg-frontend/utils/lib/array';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';
import { MATERIAL_UI_STYLE_COLOR } from '../../constants/materialUI';
import {
  ENTITY_BATCH_DEFECT_STATE,
  ENTITY_BATCH_PROVIDABLE_STATE,
  ENTITY_BATCH_PROVIDING_STATE,
  ENTITY_BATCH_STATUS,
  SHEET_OPERATION_STATUS,
} from '../../constants/sheets';
import {
  DefectLabelTrans,
  HasPartsAndMaterialsLabelTrans,
  InProductionLabelTrans,
  PartsAndMaterialsAreAvailableForSheetLabelTrans,
  PartsPartsAndMaterialsAreNotAvailableForSheetLabelTrans,
  SheetDoesNotHavePartsAndMaterialsLabelTrans,
  SheetHasPartiallyPartsAndMaterialsLabelTrans,
  SinglePausedLabelTrans,
} from '../commonTransComponents';


const ENTITY_BATCH_PROVIDING_STATE_LABELS_MAP = {
  [ENTITY_BATCH_PROVIDING_STATE.UNPROVIDED]: {
    labelContent: SheetDoesNotHavePartsAndMaterialsLabelTrans,
    labelStyle: MATERIAL_UI_STYLE_COLOR.SECONDARY,
    labelKey: 'unprovided',
  },
  [ENTITY_BATCH_PROVIDING_STATE.PARTIALLY_PROVIDED]: {
    labelContent: SheetHasPartiallyPartsAndMaterialsLabelTrans,
    labelStyle: MATERIAL_UI_STYLE_COLOR.PRIMARY,
    labelKey: 'partiallyProvided',
  },
  [ENTITY_BATCH_PROVIDING_STATE.PROVIDED]: {
    labelContent: HasPartsAndMaterialsLabelTrans,
    labelStyle: MATERIAL_UI_STYLE_COLOR.PRIMARY,
    labelKey: 'provided',
  },
};

export const getSheetPartsAndMaterialsInfoLabels = ({ providingState }) => ([
  ENTITY_BATCH_PROVIDING_STATE_LABELS_MAP[providingState],
]);


const PROVIDABLE_INFO_LABEL_PROPS = {
  labelContent: PartsAndMaterialsAreAvailableForSheetLabelTrans,
  labelStyle: MATERIAL_UI_STYLE_COLOR.PRIMARY,
  labelKey: 'providable',
};
const UNPROVIDABLE_INFO_LABEL_PROPS = {
  labelContent: PartsPartsAndMaterialsAreNotAvailableForSheetLabelTrans,
  labelStyle: MATERIAL_UI_STYLE_COLOR.SECONDARY,
  labelKey: 'unprovidable',
};

const IN_PRODUCTION_INFO_LABEL_PROPS = {
  labelContent: InProductionLabelTrans,
  labelStyle: MATERIAL_UI_STYLE_COLOR.PRIMARY,
  labelKey: 'inProduction',
};

const PAUSED_INFO_LABEL_PROPS = {
  labelContent: SinglePausedLabelTrans,
  labelStyle: MATERIAL_UI_STYLE_COLOR.SECONDARY,
  labelKey: 'paused',
};

export const getAssemblySheetsWaitingPartsAndMaterialsInfoLabels = ({ providableState, entityBatchStatus, providingState }) => [
  providingState === ENTITY_BATCH_PROVIDING_STATE.PARTIALLY_PROVIDED ?
    IN_PRODUCTION_INFO_LABEL_PROPS :
    null,
  entityBatchStatus === ENTITY_BATCH_STATUS.PAUSED ?
    PAUSED_INFO_LABEL_PROPS :
    null,
  providableState === ENTITY_BATCH_PROVIDABLE_STATE.PROVIDABLE ?
    PROVIDABLE_INFO_LABEL_PROPS :
    UNPROVIDABLE_INFO_LABEL_PROPS,
];


/*
* Вспомогательная функция для определения сценария в рамках которого происходит фиксация брака по партии МЛ.
* Нас интересует следующие сценарии, для того, чтобы корректно различать обработку после фиксации брака:
*   1. В брак переводится вся партия при завершении операции.
*   2. В брак переводится вся партия при приостановке операции.
*   3. В брак переводится часть партии при завершении операции и это была не последняя операция.
*   4. В брак переводится часть партии при завершении операции и это была последняя операция.
*   5. В брак переводится часть партии при приостановке операции.
*/

export const DEFECT_MARKING_USE_CASES = {
  WHOLE_ENTITY_BATCH_ON_OPERATION_FINISH: 'WHOLE_ENTITY_BATCH_ON_OPERATION_FINISH',
  WHOLE_ENTITY_BATCH_ON_OPERATION_PAUSE: 'WHOLE_ENTITY_BATCH_ON_OPERATION_PAUSE',
  PART_OF_ENTITY_BATCH_ON_OPERATION_FINISH: 'PART_OF_ENTITY_BATCH_ON_OPERATION_FINISH',
  PART_OF_ENTITY_BATCH_ON_LAST_OPERATION_FINISH: 'PART_OF_ENTITY_BATCH_ON_LAST_OPERATION_FINISH',
  PART_OF_ENTITY_BATCH_ON_OPERATION_PAUSE: 'PART_OF_ENTITY_BATCH_ON_OPERATION_PAUSE',
};
export const getDefectMarkingUseCase = (sheetOperationStatus, wasEntityBatchSplit, wasEntityBatchFinished) => {

  if(!wasEntityBatchSplit) {
    // 1. В брак переводится вся партия при завершении операции
    if(sheetOperationStatus === SHEET_OPERATION_STATUS.FINISHED) {
      return DEFECT_MARKING_USE_CASES.WHOLE_ENTITY_BATCH_ON_OPERATION_FINISH;
    }

    // 2. В брак переводится вся партия при приостановке операции
    if(sheetOperationStatus === SHEET_OPERATION_STATUS.PAUSED) {
      return DEFECT_MARKING_USE_CASES.WHOLE_ENTITY_BATCH_ON_OPERATION_PAUSE;
    }
  }

  // 5. В брак переводится часть партии при приостановке операции
  if(sheetOperationStatus === SHEET_OPERATION_STATUS.PAUSED) {
    return DEFECT_MARKING_USE_CASES.PART_OF_ENTITY_BATCH_ON_OPERATION_PAUSE;
  }

  // 4. В брак переводится часть партии при завершении операции и это была последняя операция
  if(wasEntityBatchFinished) {
    return DEFECT_MARKING_USE_CASES.PART_OF_ENTITY_BATCH_ON_LAST_OPERATION_FINISH;
  }

  // 3. В брак переводится часть партии при завершении операции и это была не последняя операция
  return DEFECT_MARKING_USE_CASES.PART_OF_ENTITY_BATCH_ON_OPERATION_FINISH;
};

export const getSheetDefectInfoLabels = ({ defectState }) => ([
  defectState === ENTITY_BATCH_DEFECT_STATE.DEFECT ?
    ({
      labelContent: DefectLabelTrans,
      labelStyle: MATERIAL_UI_STYLE_COLOR.SECONDARY,
      labelKey: 'defect',
    }) :
    null,
]);

const SHEET_IDENTITY_PREFIX = _get(window, ['config', 'DEFAULT_SHEET_IDENTITY_PREFIX'], '');

export const getDefaultSheetToStartIdentity = (fromState, entityBatchIdentity, mainPlanningSessionId) => {
  return fromState ?
    entityBatchIdentity :
    `${SHEET_IDENTITY_PREFIX}${entityBatchIdentity}_${mainPlanningSessionId}`;
};

export const BATCHES_SIZES_FOR_REQUIRED_AMOUNT_STATUSES = {
  OK: 'OK',
  SMALLER: 'SMALLER',
  NOT_FOUND: 'NOT_FOUND',
};
/**
 * Функция для поиска размеров партий, которые в сумме дадут запрашиваемый размер.
 *
 * Принимает на вход размеры партий и их количества, которые есть в системе.
 * Например, в системе есть партии:
 *  - { entityBatchIdentity: 'a', entitiesInBatchAmount: 10 }
 *  - { entityBatchIdentity: 'b', entitiesInBatchAmount: 105 }
 *  - { entityBatchIdentity: 'c', entitiesInBatchAmount: 105 }
 * , тогда sizesAndCounts = { 10: 1, 105: 2 }.
 * Также принимает размер, который мы хотим найти.
 *
 * Алгоритм поиска:
 *  1. Готовим данные: сортируем размеры и их количества от больших размеров к меньшим, откидываем
 *    слишком большие значения, а также копируем эти размеры, т.к. в процессе поиска мы их будем менять
 *  2. В цикле проходим от большего размера к меньшему
 *  3. Определяем, подходит ли нам текущий размер, сколько партий текущего размера можно использовать
 *  4. Если размер подходит, то запоминаем это: уменьшаем оставшийся размер, который необходимо найти
 *    на [размер * количество, которое можно использовать], сохраняем размер и шаг на котором мы это сделали
 *  5. Если после уменьшения текущего оставшегося размера, он стал равен 0, значит работа сделана.
 *    Выходим с Успехом
 *  6. Если дошли до конца размеров (последний элемент шага 2), но шаг 5 так и не отработал, значит
 *    следует откатываться и проверять другое сочетание размеров (например, для 120, вместо 100 + 30, смотрим на
 *    30 * 4)
 *  7. Сохраняем неудачную попытку на всякий случай -- мы не ищем идеальный случай, а дальше можем
 *    всё ухудшить, поэтому при откатах запоминаем все неудачи и в конце анализируем
 *  8. Берем последний шаг, уменьшаем количество размеров, которые там были взяты и применяем.
 *    Т.е. если на последнем шаге мы взяли 3 раза по 10 размеров, то теперь берем 2 раза по 10 размеров.
 *    Т.к. напрямую применить его не можем, то мы меняем список размеров и их количеств, с которым
 *    работаем (т.е. тот, что скопировали на шаге 1) так, чтобы физически нельзя было взять больше
 *    2 раз по 10 размеров. Меняем счетчик в цикле (шага 2) и снова выполняем 3-8. Так, пока не уменьшим
 *    до 0 раз, после чего откатываемся ещё на шаг назад
 *  8.1. Перед каждым выполнением шага 8, следует сбрасывать текущий список к первоначальному варианту,
 *    т.к. иначе при поиске у нас всегда будут удалены самые мелкие размеры
 *  9. Если выполняя шаг 8 мы дошли до самого большого (из тех, которые подходят) размера и это всё ещё
 *    не работает, значит комбинации с этим размером нам не подходят. Поэтому "навсегда" удаляем это
 *    значение из списка. Т.е. при последующих попытках подобрать размеры из списка [100, 70, 60, 25, 5],
 *    мы пойдем уже не с 100, а с 70. Повторяем шаги 3-8
 *  10. Если все возможные размеры удалены на шаге 9, значит идеального размера не существует.
 *    Следует поискать наиболее подходящий размер из списка, который мы формировали на шаге 7.
 *    Чем меньше у размера разница с тем, который искал пользователь, тем он больше нам подходит.
 *  11. Если что-то нашли, то Выходим с почти успехом. Если нет, то выходим с неудачей.
 *
 *  Поскольку по условию было решено искать только идеальные и меньшие случаи, алгоритм не разрабатывался
 *  под нахождение размеров, которые будут чуть больше. При небольших изменениях, в выборку неудачных вариантов
 *  можно включить отброшенные на 1 шаге слишком большие размеры, но подходить они будут далеко не для всех
 *  данных.
 *
 * @param requiredAmount : number - размер партии, который нужен пользователю
 * @param sizesAndCounts: {sizeOfBatch: number} - объект, в котором ключи -- размеры партий,
 *    а значения -- количества партий этих размеров
 * @returns {{sizesAndAmounts: *, difference: number, status: string}|{sizesAndAmounts: {}, difference: number, status: string}}
 */
export const getBatchesSizesForRequiredAmount = (requiredAmount, sizesAndCounts) => {
  // Сюда попадают размеры, с недобором ~или перебором~ (пока только с недобором)
  const outOfAmountBatches = {};

  // Сортируем, достаем только размеры, с которыми можем работать, т.е. если они <= requiredAmount
  const sizes = Object.keys(sizesAndCounts)
    .map(Number)
    .filter(size => size <= requiredAmount)
    .sort((a, b) => numberComparator(b, a));

  let curAmount = requiredAmount;
  // Копируем, т.к. мы будем "портить" этот список
  let curSizesAndCounts = { ...sizesAndCounts };
  // Объект, в котором мы будем хранить наши значения
  const curValues = {};
  // Храним взятый размер, а также шаг, на котором взяли этот размер,
  // чтобы можно было откатиться на этот шаг
  const takenSizesWithSteps = [];

  // Здесь хранятся размеры, которые мы исключили (занулили) навсегда, от большего к меньшему.
  // Когда сюда попадут все значения из size, дальше считать нельзя
  const removedSizes = [];

  let found = false;

  for (let i = 0; i < sizes.length; i++) {
    const curSize = sizes[i];
    const availableSizes = curSizesAndCounts[curSize];
    const sizesNeeded = Math.floor(curAmount / curSize);
    const numberOfSizes = Math.min(availableSizes, sizesNeeded);

    // Если размер партии нам подходит, то сохраняем его
    if (numberOfSizes > 0) {
      curValues[curSize] = numberOfSizes;
      curAmount -= curSize * numberOfSizes;
      takenSizesWithSteps.push({
        size: curSize,
        step: i,
      });
    }

    // Если curAmount === 0, то мы нашли все наши размеры, можем дальше не искать
    if (curAmount === 0) {
      found = true;
      break;
    }

    // Если дошли до последнего, но curAmount всё ещё больше нуля, то необходимо откатиться на
    // шаг назад и уменьшить количество взятых размеров на этом шаге
    if (i === sizes.length - 1) {
      // Для начала сохраняем неудачный вариант, на случай, если он окажется самым близким к нужному
      if (!outOfAmountBatches[curAmount]) {
        outOfAmountBatches[curAmount] = { ...curValues };
      }

      const lastStep = takenSizesWithSteps.pop();

      // Если шагов не было, то нужно занулить самый первый элемент, а остальной список вернуть
      // к изначальному виду
      if (!lastStep) {
        // Если в removedSizes уже есть все размеры из sizes, значит мы почистили всё, что могли.
        // Можем дальше не проверять, свободных элементов всё равно уже нет
        if (removedSizes.length === sizes.length) {
          break;
        }

        for (let j = 0; j < sizes.length; j++) {
          if (!removedSizes.includes(sizes[j])) {
            removedSizes.push(sizes[j]);

            break;
          }
        }

        // Возвращаем значения к первоначальному виду, а потом обнуляем первые по порядку размеры
        _omit(curSizesAndCounts, removedSizes);

        // Сбрасываем текущий счетчик полностью
        curAmount = requiredAmount;
      } else {
        // Возвращаем всё как было
        curSizesAndCounts = { ...sizesAndCounts };
        // Уменьшаем количество возможных для взятия размеров на 1. Т.е. если там было 10, а мы
        // взяли 3, то нужно, чтобы осталось 2, чтобы мы физически не смогли взять больше
        curSizesAndCounts[lastStep.size] = curValues[lastStep.size] - 1;

        // Сбрасываем текущий счетчик только на откинутое значение
        curAmount += curValues[lastStep.size] * lastStep.size;

        // Удаляем размер из текущего списка и меняем шаг
        delete curValues[lastStep.size];
        i = lastStep.step - 1;
      }
    }
  }

  if (found) {
    return {
      status: BATCHES_SIZES_FOR_REQUIRED_AMOUNT_STATUSES.OK,
      difference: 0,
      sizesAndAmounts: curValues,
    };
  }

  // Т.к. 1) текущий алгоритм не подходит для нахождения большего значения
  // и 2) по условию, нужно выдавать только меньшие или равные
  // Если нет значений, то, соответственно, ничего не выдаем
  if (_isEmpty(outOfAmountBatches)) {
    return {
      status: BATCHES_SIZES_FOR_REQUIRED_AMOUNT_STATUSES.NOT_FOUND,
      difference: 0,
      sizesAndAmounts: {},
    };
  }

  // Берем самое ближайшее значение из неудачных
  const smallestOutOfAmountSize = Math.min(...Object.keys(outOfAmountBatches)
    .map(Number));
  const smallestOutOfAmountBatches = outOfAmountBatches[smallestOutOfAmountSize];

  const outOfAmountAmount = Object.keys(smallestOutOfAmountBatches)
    .reduce((acc, size) => {
      // eslint-disable-next-line
      acc += smallestOutOfAmountBatches[size] * size;
      return acc;
    }, 0);

  return {
    status: BATCHES_SIZES_FOR_REQUIRED_AMOUNT_STATUSES.SMALLER,
    difference: requiredAmount - outOfAmountAmount,
    sizesAndAmounts: outOfAmountBatches[smallestOutOfAmountSize],
  };
};

const getBatchSizesAndSheetsCounts = rowsData => {
  return rowsData.reduce((acc, row) => {
    acc[row.entitiesInBatchAmount] = acc[row.entitiesInBatchAmount] ?
      acc[row.entitiesInBatchAmount] + 1 :
      1;
    return acc;
  }, {});
};

export const filterSheetsForRequiredAmount = (requiredAmount, rowsData) => {
  const batchSizesAndSheetsCounts = getBatchSizesAndSheetsCounts(rowsData);

  const capableSheetsAmounts = getBatchesSizesForRequiredAmount(requiredAmount, batchSizesAndSheetsCounts);

  return capableSheetsAmounts;
};

export const getSheetsByFilteredAmount = (rowsData, batchSizesAndSheetsCounts) => {
  const sizes = { ...batchSizesAndSheetsCounts };

  return rowsData.reduce((acc, row) => {
    const { entitiesInBatchAmount } = row;

    if (sizes[entitiesInBatchAmount]) {
      acc.push(row);
      sizes[entitiesInBatchAmount] -= 1;
    }

    return acc;
  }, []);
};