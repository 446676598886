import React, { Component  } from 'react';
import { Trans } from '@lingui/macro';

import PropTypes from 'prop-types';

import { Route, Redirect, Switch } from 'react-router-dom';
import ToggleButton from '@mui/material/ToggleButton';

import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import './style.css';
import { DefaultDepartmentsAutocomplete } from '../../entitiesAutocomplete/DepartmentsAutocomplete/DepartmentsAutocomplete';
import { ENTITY_OR_RESOURCE_COMMON_FIELDS_BASED_TYPE, FUNC_IS_REQUIRED_TYPE } from '../../../constants/propTypes';
import { MASTER_DEPARTMENTS_AUTOCOMPLETE_ID } from './constants';
import {
  MASTER_APP_MAIN_ROUTE,
  MASTER_APP_SHEETS_ROUTE, MASTER_APP_SHEETS_SUB_ROUTE,
  MASTER_APP_TASKS_ROUTE, MASTER_APP_TASKS_SUB_ROUTE,
} from '../../../constants/routes';
import { MASTER_TASKS_TO_DO_TABLE_ID } from '../MasterTasksToDo/constants';
import { MASTER_COMPLETED_TASKS_TABLE_ID } from '../MasterCompletedTasks/constants';
import { MasterTasks } from '../MasterTasks/MasterTasks';
import { MasterSheets } from '../MasterSheets/MasterSheets';
import { SheetsLabelTrans, TasksLabelTrans } from '../../../utils/commonTransComponents';


export class MasterWorkspace extends Component {

  static propTypes = {
    viewMode: PropTypes.string,
    departmentIdsRouteParam: PropTypes.string,
    departmentIdsFromRoute: PropTypes.arrayOf(PropTypes.string),
    departmentsAutocompleteValue: PropTypes.arrayOf(ENTITY_OR_RESOURCE_COMMON_FIELDS_BASED_TYPE),
    onDepartmentsAutocompleteChange: FUNC_IS_REQUIRED_TYPE,
    clearTableRemoteData: FUNC_IS_REQUIRED_TYPE,
    handleMasterAppViewModeChange: FUNC_IS_REQUIRED_TYPE,
  };

  /*
  * Подробно об особенностях реализации таблиц мастера с "внешней" для таблицы фильтрацией по подразделениям описано
  * в комментарии к componentDidUpdate в MasterTasksToDo. В п.1. указанного комментария делается акцент на том, что
  * с учетом наличия внешенго для таблиц фильтра, очень важно правильно сбрасывать кэш таблиц, иначе могут быть
  * конфузы с тем, что в таблицах отображаются не те данные.
  * В самих компонентах таблиц, когда они на экране мы сбрасываем кэш при изменении выбранных подразделений, т.е.
  * этого внешнего фильтра, что и логично, т.к. данные  табличные его учитывают. Но в самих компонентах мы не можем
  * закрыть все кейсы:
  *  - Когда из фильтра удаляется последнее выбранное подразделение, т.е. departmentIdsFromRoute === null, то компоненты
  * с таблицами пропадают с экрана и их обработчики на дидапдейт не могут сработать. Но в этом случае, по сути, тоже
  * происходит изменение фильтра (он сбрасывается) и все данные в таблицах мастера становятся невалидными (теоретически,
  * если бы мы их отображали, а не скрывали с экрана, они должны были бы стать пустыми), поэтому нужно сбросить кэш
  * всех таблиц мастера, чтобы при выборе нового подразделения и повторного маунта запрос выполнился заново. Может
  * показаться, что можно было бы сделать очистку кэша таблицы при анмаунте самого компонента с таблицей, но при
  * переключении между вкладками таблиц мастера, тоже происходит анмаунт, но в этом случае сбрасывать кэш не надо, т.к.
  * не происходит изменение подразделений и кэш валидный, как раз в этом случае новые запросы не нужны и всё корректно
  * отрабатывает. Опять же, может возникнуть вопрос, а нельзя ли это сделатьв обработчике события изменения самого
  * подразделения в фильтре, а не в didUpdate этого компонента, но этот случай подробно расписан в упоминаемом ранее
  * комментарии к MasterTasksToDo - источник правды в этом случае, это парметры из роута, поэтому обработку нужно
  * делать в дидапдейт отслеживая их изменения.
  * - Когда мы совсем уходим из раздела Мастера (для пользователей с правами на просмотр нескольких типов приложения),
  * то это тоже, по сути означает, что параметр подразделений в роуте удаляется. Когда мы вернемся в раздел нужно будет
  * выбирать подразделение заново. А это значит, что кэш таблиц мастера в этом случае тоже "устаревает", нужно очистить
  * его на анмаунт этого корневого компонента (в котором и располагается сам "внешний" фильтр). Обработать это событие
  * где-то не в корневом компоненте раздела, которые всегда на экране мы не можем, поэтому эта обработка здесь
  *
  * */
  componentDidUpdate(prevProps) {
    const { departmentIdsFromRoute: prevDepartmentIdsFromRoute } = prevProps;

    const { departmentIdsFromRoute } = this.props;

    if(prevDepartmentIdsFromRoute !== null && departmentIdsFromRoute === null) {
      this._clearMasterTablesRemoteData();
    }
  }

  componentWillUnmount() {
    this._clearMasterTablesRemoteData();
  }

  //TODO добавить сюда в будущем и идентификатор списка приостановленных маршрутных листов, когда в нём будет учитываться
  //TODO подразделение, выбранное мастером, сейчас МЛ не учитывают этот фильтр
  _clearMasterTablesRemoteData = () =>
    this.props.clearTableRemoteData([MASTER_TASKS_TO_DO_TABLE_ID, MASTER_COMPLETED_TASKS_TABLE_ID]);

  _renderDepartmentsAutocomplete = (departmentsAutocompleteValue, onDepartmentsAutocompleteChange) => (
    <div className="master-workspace__departments-autocomplete">
      <div className="master-workspace__departments-autocomplete-title">
        <Trans id="master_tasks@departments_autocomplete">
          Подразделения для отображения заданий
        </Trans>
      </div>
      <DefaultDepartmentsAutocomplete
          wrapperClassName="master-workspace__departments-autocomplete-wrap"
          id={MASTER_DEPARTMENTS_AUTOCOMPLETE_ID}
          value={departmentsAutocompleteValue}
          onChange={onDepartmentsAutocompleteChange}
          shouldPreloadData
          isMulti
      />
    </div>
  );

  _renderWorkspaceContent = (departmentIdsFromRoute, viewMode) => {

    if(!departmentIdsFromRoute) return null;

    /*
    * Когда режим не выбран, проставляется значение по умолчанию - вкладка "задания"
    * */
    if(!viewMode)
      return (
        <Redirect to={[MASTER_APP_MAIN_ROUTE, departmentIdsFromRoute, MASTER_APP_TASKS_SUB_ROUTE].join('/')}/>
      );

    return (
      <Switch>
        <Route
            path={MASTER_APP_TASKS_ROUTE}
            render={this._masterAppTasksRouteRenderProp}
        />
        <Route
            path={MASTER_APP_SHEETS_ROUTE}
            render={this._masterAppSheetsRouteRenderProp}
        />
      </Switch>
    );
  };

  _masterAppTasksRouteRenderProp = routerProps => {
    const {
      departmentIdsRouteParam,
      departmentIdsFromRoute,
    } = this.props;

    return (
      <MasterTasks
          {...routerProps}
          departmentIdsRouteParam={departmentIdsRouteParam}
          departmentIdsFromRoute={departmentIdsFromRoute}
      />
    );
  };

  _masterAppSheetsRouteRenderProp = routerProps => {
    const {
      departmentIdsFromRoute,
      departmentIdsRouteParam,
    } = this.props;

    return (
      <MasterSheets
          {...routerProps}
          departmentIdsRouteParam={departmentIdsRouteParam}
          departmentIdsFromRoute={departmentIdsFromRoute}
      />
    );
  }

  _renderMasterAppViewModeControl = (viewMode, departmentIdsFromRoute) => (
      <ToggleButtonGroup
          className="master-workspace__view-mode-toggle-btn-group"
          value={viewMode}
          exclusive
          onChange={this.onViewModeChange}
      >
        {this._renderToggleButton(departmentIdsFromRoute, MASTER_APP_TASKS_SUB_ROUTE, TasksLabelTrans)}
        {this._renderToggleButton(departmentIdsFromRoute, MASTER_APP_SHEETS_SUB_ROUTE, SheetsLabelTrans)}
      </ToggleButtonGroup>
    );

  onViewModeChange = (e, viewMode) => {
    /*
    * Если повторно нажата кнопка уже выбранного режима то в обработчик приходит viewMode = null. В таком случае не
    * требуется вызывать обработчик handleMasterAppViewModeChange, т.к. значение не изменилось
    * */
    if(viewMode === null) return;

    this.props.handleMasterAppViewModeChange(viewMode);
  };

  _renderToggleButton = (departmentIdsFromRoute, value, content) => (
    <ToggleButton
        value={value}
        disabled={!departmentIdsFromRoute}
        classes={{
          selected: 'master-workspace__view-mode-toggle-btn--selected',
          disabled: 'master-workspace__view-mode-toggle-btn--disabled',
        }}
    >
      {content}
    </ToggleButton>
  ) ;

  render() {

    const {
      viewMode,
      departmentIdsFromRoute,
      departmentsAutocompleteValue,
      onDepartmentsAutocompleteChange,
    } = this.props;

    return (
      <div className="master-workspace app-container">
        <div className="master-workspace__control-panel-wrapper">
          {this._renderDepartmentsAutocomplete(departmentsAutocompleteValue, onDepartmentsAutocompleteChange)}
          {this._renderMasterAppViewModeControl(viewMode, departmentIdsFromRoute)}
        </div>
        {this._renderWorkspaceContent(departmentIdsFromRoute, viewMode)}
      </div>
    );
  }
}