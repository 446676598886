import _keyBy from 'lodash/keyBy';

import { FILTER_GROUP_TYPES, FILTER_TYPES } from '../api/restCollectionApi';
import {
  DEPARTMENT_MODEL,
  EQUIPMENT_CLASS_MODEL,
  SHEET_OPERATION_MODEL,
} from '../constants/models';
import { SERVER_DATA_POINT } from '../constants/serverDataPoints';
import {
  createCaEntitiesAutocompleteLoadOptionsFromDataPointActionCreator,
  DEFAULT_AUTOCOMPLETE_LOAD_OPTIONS_LIMIT,
} from '../reducers/autocomplete/actions';
import { fetchEntitiesFromServer } from '../reducers/entities/actions';
import { setTasksEquipmentClassFilter } from '../reducers/workerApp/tasksMainFilters/actions';
import { saveEquipmentClassesInDepartmentWithTasks } from '../reducers/workerApp/tasksOwners/actions';
import { isIdInUrlParamsValid } from '../utils/url';
import { fetchDataFromServerDataPoint } from '../api/index';
import humps from 'humps';


/*const EQUIPMENT_CLASSES_CHOOSE_SCREEN_REQUEST_MODEL_RELATIONS = {
  [SHEET_OPERATION_MODEL]: {
    level: 1,
  },
  [DEPARTMENT_MODEL]: {
    level: 2,
    relates: SHEET_OPERATION_MODEL,
  },
  [OPERATION_MODEL]: {
    level: 2,
    relates: SHEET_OPERATION_MODEL,
  },
  [SHEET_MODEL]: {
    level: 2,
    relates: SHEET_OPERATION_MODEL,
  },
  [ENTITY_BATCH_MODEL]: {
    level: 3,
    relates: SHEET_MODEL,
  },
};*/

/*
* См. комментарий к fetchDepartmentsWithTasks по поводу текущей логики этого запроса. Тут всё аналогично, фильтры
* очень сильно увеличивали размер получаемых данных и пока они отключены.
* Также, тут сама по себе логики поиска классов РЦ в подразделении предполагает стыковку их тоже через операции МЛ,
* даже без фильтрации по наличию заданий. Поэтому, тут не только убран этот фильтр, но и запрос переделан совсем
* с АПИ коллекций на специальную дата точку. В ней возможность фильтров наличия заданий никогда не предполагалась и
* не будет.
* Тут тоже ожидаем или корректировку апи коллекций или ещё одну data точку.
*
* */
export const fetchEquipmentClassesForDepartmentWithTasks = (departmentId, requestOptions = {}) =>
  dispatch => {

/*    const queryParams = {
      filter: {
        filterGroupType: FILTER_GROUP_TYPES.AND,
        filters: [
          {
            column: [DEPARTMENT_MODEL, 'id'].join(RELATED_MODEL_FIELD_DELIMITER),
            filterType: FILTER_TYPES.EQUALS,
            filterValue: departmentId,
          },
          {
            column: [ENTITY_BATCH_MODEL, 'status'].join(RELATED_MODEL_FIELD_DELIMITER),
            filterType: FILTER_TYPES.EQUALS,
            filterValue: ENTITY_BATCH_STATUS.IN_PRODUCTION,
          },
          {
            column: [SHEET_MODEL, 'type'].join(RELATED_MODEL_FIELD_DELIMITER),
            filterType: FILTER_TYPES.EQUALS,
            filterValue: SHEET_STATUS.ACTIVE,
          },
          {
            column: [SHEET_OPERATION_MODEL, 'status'].join(RELATED_MODEL_FIELD_DELIMITER),
            filterType: FILTER_TYPES.NOT_EQUALS,
            filterValue: SHEET_OPERATION_STATUS.FINISHED,
          },
        ],
      },
    };*/

    /*return dispatch(
      fetchEntitiesFromServer(
        EQUIPMENT_CLASS_MODEL,
        queryParams,
        {
          modelRelations: EQUIPMENT_CLASSES_CHOOSE_SCREEN_REQUEST_MODEL_RELATIONS,
          ...requestOptions,
        },
      ),
    )
      .then(response  => {
        if(!response || response.responseMeta.count === 0) return;

        const {
          entities: {
            [EQUIPMENT_CLASS_MODEL]: equipmentClassesInDepartmentEntities,
          },
        } = response;

        dispatch(saveEquipmentClassesInDepartmentWithTasks(departmentId, equipmentClassesInDepartmentEntities));
      });*/

    const query = {
      departmentId,
      with: [humps.decamelize(EQUIPMENT_CLASS_MODEL)],
      start: 0,

      //У временно используемой точки ограничение в 100. На всякий случай, перестраховываемся и берем в 2 раза больше.
      //Фактически, как правило, классов РЦ в подразделении гораздо меньше, и в данном случае мы хотим получить их все.
      stop: 200,
    };

    return dispatch(fetchDataFromServerDataPoint(
      SERVER_DATA_POINT.DEPARTMENT_EQUIPMENT_CLASS,
      query,
      requestOptions,
    ))
      .then(response => {
        const { [EQUIPMENT_CLASS_MODEL]: equipmentClassEntitiesArray } = response;

        const equipmentClassesInDepartmentEntities = _keyBy(equipmentClassEntitiesArray, 'id');

        dispatch(saveEquipmentClassesInDepartmentWithTasks(
          departmentId,
          equipmentClassesInDepartmentEntities,
        ));
      });
  };

/*
* В роутинге устанавливаются фильтр заданий по классу РЦ в подразделении в виде его id БД. В url, теоретически, может
* быть написано всё что угодно + данные для которые устанавливался ранее фильтр могут быть изменены.
* Поэтому в определенных случаях необходимо выполнить проверку, есть ли класс РЦ с таким id в БД, чтобы корректно
* продолжить работу.
*
* Если такой класс РЦ существует, то устанавливаем фильтр в state. Если нет, то реджектим
* */
export const initTasksEquipmentClassFilterFromRoute = equipmentClassIdFromRoute =>
  dispatch => {

    if(!isIdInUrlParamsValid(equipmentClassIdFromRoute))
      return Promise.reject(equipmentClassIdFromRoute);

    const queryParams = {
      filter: {
        filterGroupType: FILTER_GROUP_TYPES.AND,
        filters: [
          {
            column: 'id',
            filterType: FILTER_TYPES.EQUALS,
            filterValue: equipmentClassIdFromRoute,
          },
        ],
      },
    };

    return dispatch(fetchEntitiesFromServer(EQUIPMENT_CLASS_MODEL, queryParams))
      .then(response => {
        const {
          entities: {
            [EQUIPMENT_CLASS_MODEL]: equipmentClassEntities,
          } = {},
        } = response;

        const equipmentClassEntity = equipmentClassEntities[equipmentClassIdFromRoute];

        if(!!equipmentClassEntity) {
          dispatch(setTasksEquipmentClassFilter(equipmentClassEntity));
          return Promise.resolve(equipmentClassEntity);
        }
        return Promise.reject(equipmentClassIdFromRoute);
      });
  };

const EQUIPMENT_CLASSES_IN_DEPARTMENT_REQUEST_MODEL_RELATIONS = {
  [SHEET_OPERATION_MODEL]: {
    level: 1,
  },
  [DEPARTMENT_MODEL]: {
    level: 2,
    relates: SHEET_OPERATION_MODEL,
  },
};

export const fetchEquipmentClassesInDepartment = (departmentId, query = {}, requestOptions = {}) =>
  dispatch => dispatch(fetchEntitiesFromServer(
    EQUIPMENT_CLASS_MODEL,
    {
      filter: {
        filterGroupType: FILTER_GROUP_TYPES.AND,
        filters: [
          {
            column: [DEPARTMENT_MODEL, 'id'].join('__'),
            filterType: FILTER_TYPES.EQUALS,
            filterValue: departmentId,
          },
        ],
      },
      ...query,
    },
    {
      modelRelations: EQUIPMENT_CLASSES_IN_DEPARTMENT_REQUEST_MODEL_RELATIONS,
      ...requestOptions,
    },
  ));

const EQUIPMENT_CLASSES_IN_DEPARTMENT_AUTOCOMPLETE_ORDER_BY_OPTIONS = {
  EQUIPMENT_CLASS_IDENTITY: 'equipment_class_identity',
  EQUIPMENT_CLASS_NAME: 'equipment_class_name',
  DEPARTMENT_IDENTITY: 'department_identity',
  DEPARTMENT_NAME: 'department_name',
};
const getEquipmentClassesInDepartmentAutocompleteLoadOptionsActionCreator = ({
  departmentIds,
  prepareOptions,
  optionsRequestLimit = DEFAULT_AUTOCOMPLETE_LOAD_OPTIONS_LIMIT,
  /**
   * Точка может искать либо только по equipmentClass (name | identity),
   * либо и по equipmentClass и по department
   */
  onlyEquipmentClass,
  withEquipmentClass,
  withDepartment,
  optionsModel,
  /**
   * Массив полей, по которым будет производиться сортировка.
   * Возможные поля - EQUIPMENT_CLASSES_IN_DEPARTMENT_AUTOCOMPLETE_ORDER_BY_OPTIONS
   * Если связанных таблиц не будет в поле with, то будет выполняться сортировка по умолчанию.
   */
  orderBy = [],
  // Работает только при заданном orderBy
  asc = true,
}) => {
  return createCaEntitiesAutocompleteLoadOptionsFromDataPointActionCreator({
    dataPoint: SERVER_DATA_POINT.DEPARTMENT_EQUIPMENT_CLASS,
    getRequestQuery: inputValue => {
      const withParams = [];
      if (withEquipmentClass) {
        withParams.push(humps.decamelize(EQUIPMENT_CLASS_MODEL));
      }
      if (withDepartment) {
        withParams.push(DEPARTMENT_MODEL);
      }

      const params = {
        stop: optionsRequestLimit,
        with: withParams,
        orderBy,
        asc,
      };

      if (departmentIds) {
        params.departmentId = departmentIds;
      }

      if (inputValue) {
        if (onlyEquipmentClass) {
          params.equipmentClassIdentityName = inputValue;
        } else {
          params.identityName = inputValue;
        }
      }

      return params;
    },
    optionsModel,
    prepareOptions,
  });
};

export const getEquipmentClassesForDepartmentIdsAutocompleteLoadOptionsActionCreator = (departmentIds, prepareOptions) =>
  getEquipmentClassesInDepartmentAutocompleteLoadOptionsActionCreator(
    {
      departmentIds,
      prepareOptions,
      onlyEquipmentClass: true,
      withEquipmentClass: true,
      optionsModel: EQUIPMENT_CLASS_MODEL,
      orderBy: [
        EQUIPMENT_CLASSES_IN_DEPARTMENT_AUTOCOMPLETE_ORDER_BY_OPTIONS.EQUIPMENT_CLASS_NAME,
        EQUIPMENT_CLASSES_IN_DEPARTMENT_AUTOCOMPLETE_ORDER_BY_OPTIONS.EQUIPMENT_CLASS_IDENTITY,
      ],
    },
  );

const getEquipmentClassesInDepartmentsAutocompleteOptionId = (equipmentClassId, departmentId) =>
  [equipmentClassId, departmentId].join('/');

export const getEquipmentClassesInDepartmentsAutocompleteLoadOptionsActionCreator = optionsRequestLimit => {
  return getEquipmentClassesInDepartmentAutocompleteLoadOptionsActionCreator(
    {
      optionsRequestLimit,
      onlyEquipmentClass: false,
      withEquipmentClass: true,
      withDepartment: true,
      prepareOptions: response => {
        const {
          data,
          [EQUIPMENT_CLASS_MODEL]: equipmentClass,
          [DEPARTMENT_MODEL]: department,
        } = response;

        const equipmentClassEntities = _keyBy(equipmentClass, 'id');
        const departmentEntities = _keyBy(department, 'id');

        return data.map(({
            equipmentClassId,
            departmentId,
          }) => (
          {
            id: getEquipmentClassesInDepartmentsAutocompleteOptionId(equipmentClassId, departmentId),
            equipmentClassEntity: equipmentClassEntities[equipmentClassId],
            departmentEntity: departmentEntities[departmentId],
          }
          ));
      },
      orderBy: [
        EQUIPMENT_CLASSES_IN_DEPARTMENT_AUTOCOMPLETE_ORDER_BY_OPTIONS.EQUIPMENT_CLASS_NAME,
        EQUIPMENT_CLASSES_IN_DEPARTMENT_AUTOCOMPLETE_ORDER_BY_OPTIONS.EQUIPMENT_CLASS_IDENTITY,
        EQUIPMENT_CLASSES_IN_DEPARTMENT_AUTOCOMPLETE_ORDER_BY_OPTIONS.DEPARTMENT_NAME,
        EQUIPMENT_CLASSES_IN_DEPARTMENT_AUTOCOMPLETE_ORDER_BY_OPTIONS.DEPARTMENT_IDENTITY,
      ],
    },
  );
};
