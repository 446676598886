import { connect } from 'react-redux';

import { compose, bindActionCreators } from 'redux';

import {
  sheetOperationsRemoteTableDataSelector,
} from '../../../../selectors/sheets';
import {
  fetchReviewedSheetOperationsRemoteTableEntities,
} from '../../../../operations/sheets';
import { SHEET_TYPE } from '../../../../constants/sheets';
import { createSheetTypeOperationsTableId } from '../../../../utils/tables';
import { withPermissionsManager } from '../../../../hoc/withPermissionsManager/withPermissionsManager';
import { DefectiveSheetsReviewContent } from './DefectiveSheetsReviewContent';


const mapStateToProps = (state, ownProps) => {

  const {
    sheetId,
    entitiesInBatchAmount,
  } = ownProps.sheetToReviewData;

  const sheetOperationsTableId = createSheetTypeOperationsTableId(SHEET_TYPE.DEFECTIVE, sheetId);

  return {
    sheetOperationsTableId,
    sheetOperationsTableData: sheetOperationsRemoteTableDataSelector(
      state,
      { sheetOperationsTableId, entitiesInBatchAmount },
    ),
  };
};

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      fetchReviewedSheetOperationsRemoteTableEntities,
    },
    dispatch,
  ),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {
    sheetOperationsTableId,
    sheetOperationsTableData,
  } = stateProps;

  const {
    fetchReviewedSheetOperationsRemoteTableEntities,
  } = dispatchProps;

  const {
    sheetToReviewData,
    PermissionsManager,
  } = ownProps;

  const fetchSheetOperations = ({ tableParams }) =>
    fetchReviewedSheetOperationsRemoteTableEntities(sheetToReviewData.sheetId, tableParams);

  return {
    sheetOperationsTableId,
    sheetOperationsTableData,
    sheetToReviewData,
    PermissionsManager,
    fetchSheetOperations,
  };
};

export const DefectiveSheetsReviewContentContainer = compose(
  withPermissionsManager,
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
  ),
)(DefectiveSheetsReviewContent);

DefectiveSheetsReviewContentContainer.displayName = 'DefectiveSheetsReviewContentContainer';
