import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { AutocompleteContainer } from '../../common/Autocomplete/AutocompleteContainer';
import {
  DEPARTMENT_MODEL,
  EQUIPMENT_CLASS_MODEL,
  OPERATION_MODEL,
  SHEET_OPERATION_MODEL,
} from '../../../constants/models';
import { getSheetOperationCombinedName } from '../../../utils/stringBuilder';
import { NoDataLabelTrans, NotSelectedLabelTrans } from '../../../utils/commonTransComponents';
import {
  createCaEntitiesAutocompleteLoadOptionsActionCreator,
  DEFAULT_AUTOCOMPLETE_LOAD_OPTIONS_LIMIT,
} from '../../../reducers/autocomplete/actions';
import { FILTER_GROUP_TYPES, FILTER_TYPES } from '../../../api/restCollectionApi';
import { NUMBER_OR_STRING_TYPE } from '../../../constants/propTypes';
import { RELATED_MODEL_FIELD_DELIMITER } from '../../../constants/magics';
import _isNil from 'lodash/isNil';
import {
  IDENTITY_FIELD_DEFAULT_SORT_BY,
  NAME_FIELD_DEFAULT_SORT_BY,
} from '../../../constants/entities';
import _size from 'lodash/size';


export const OPERATIONS_AUTOCOMPLETE_LOAD_OPTIONS_SORT_BY = [
  {
    column: 'nop',
    params: [{ key: 'asc', value: true }],
  },
  ...NAME_FIELD_DEFAULT_SORT_BY,
  ...IDENTITY_FIELD_DEFAULT_SORT_BY,
];

export const getOperationsAutocompleteOptionLabel = ({ nop, name, identity }) =>
  getSheetOperationCombinedName(nop, name, identity);

export const OperationsAutocomplete = props => {

  const {
    departmentId,
    equipmentClassId,
    shouldLoadOptions,
    prepareOptions,
    optionsRequestLimit,
  } = props;

  const loadOptionsActionCreator = useMemo(() => {
    if(!shouldLoadOptions) {
      return undefined;
    }

    return _getOperationsLoadOptionsActionCreator(departmentId, equipmentClassId, prepareOptions, optionsRequestLimit);
  }, [departmentId, equipmentClassId, prepareOptions, shouldLoadOptions, optionsRequestLimit]);


  return (
    <AutocompleteContainer
        {...props}
        getOptionLabel={getOperationsAutocompleteOptionLabel}
        loadOptionsActionCreator={loadOptionsActionCreator}
        placeholder={NotSelectedLabelTrans}
        noOptionsMessage={NoDataLabelTrans}
    />
  );
};

const _getOperationsLoadOptionsActionCreator = (departmentId, equipmentClassId, prepareOptions, optionsRequestLimit = DEFAULT_AUTOCOMPLETE_LOAD_OPTIONS_LIMIT) =>
  createCaEntitiesAutocompleteLoadOptionsActionCreator({
    requestModel: OPERATION_MODEL,
    prepareOptions,
    getRequestQuery: inputValue => {

      let filters = [];

      if(!_isNil(departmentId)) {
        filters.push({
          column: [DEPARTMENT_MODEL, 'id'].join(RELATED_MODEL_FIELD_DELIMITER),
          filterType: FILTER_TYPES.EQUALS,
          filterValue: departmentId,
        });
      }

      if(!_isNil(equipmentClassId)) {
        filters.push({
          column: [EQUIPMENT_CLASS_MODEL, 'id'].join(RELATED_MODEL_FIELD_DELIMITER),
          filterType: FILTER_TYPES.EQUALS,
          filterValue: equipmentClassId,
        });
      }

      if(!inputValue) {

        let query = {
          sortBy: OPERATIONS_AUTOCOMPLETE_LOAD_OPTIONS_SORT_BY,
          limit: optionsRequestLimit,
        };

        if(_size(filters) > 0) {
          query.filter = {
            filterGroupType: FILTER_GROUP_TYPES.AND,
            filters,
          };
        }

        return query;
      }

      const operationFieldsFilterGroup = {
        filterGroupType: FILTER_GROUP_TYPES.OR,
        filters: [
          {
            column: 'name',
            filterType: FILTER_TYPES.CONTAINS,
            filterValue: inputValue,
          },
          {
            column: 'identity',
            filterType: FILTER_TYPES.CONTAINS,
            filterValue: inputValue,
          },
          {
            column: 'nop',
            filterType: FILTER_TYPES.CONTAINS,
            filterValue: inputValue,
          },
        ],
      };

      return {
        filter: _size(filters) > 0 ?
          ({
            filterGroupType: FILTER_GROUP_TYPES.AND,
            filters: [
              ...filters,
              operationFieldsFilterGroup,
            ],
          }) :
          operationFieldsFilterGroup,
        sortBy: OPERATIONS_AUTOCOMPLETE_LOAD_OPTIONS_SORT_BY,
        limit: optionsRequestLimit,
      };
    },
    getRequestOptions: () => ({
      modelRelations: {
        [SHEET_OPERATION_MODEL]: {
          level: 1,
        },
        [DEPARTMENT_MODEL]: {
          level: 2,
          relates: SHEET_OPERATION_MODEL,
        },
        [EQUIPMENT_CLASS_MODEL]: {
          level: 2,
          relates: SHEET_OPERATION_MODEL,
        },
      },
    }),
  });


OperationsAutocomplete.propTypes = {
  departmentId: NUMBER_OR_STRING_TYPE,
  equipmentClassId: NUMBER_OR_STRING_TYPE,
  shouldLoadOptions: PropTypes.bool,
  prepareOptions: PropTypes.func,
  optionsRequestLimit: PropTypes.number,
};