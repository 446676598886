
export const entitiesReviewTableComponentPropsAdapter = tableDataLayerProps => {
  const{
    filteredAndSortedRowsDataForCurrentPage,
    noDataContent,
    onRowCombinedClicksHandler,
    filterParams,
    tableId,
    tableModel,
    filteredAndSortedAllRowsData,
    pageSize,
  } = tableDataLayerProps;

  return {
    entitiesData: filteredAndSortedRowsDataForCurrentPage,
    entitiesDataForAllPages: filteredAndSortedAllRowsData,
    noDataText: noDataContent,
    onItemClick: onRowCombinedClicksHandler,
    filterParams,
    tableId,
    tableModel,
    pageSize,
  };
};
