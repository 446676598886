import { Trans } from '@lingui/macro';
import { Button } from '@mui/material';
import _get from 'lodash/get';
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { MATERIAL_UI_STYLE_COLOR, MATERIAL_UI_VARIANT } from '../../../constants/materialUI';
import CropFreeIcon from '@mui/icons-material/CropFree';

import {
  FUNC_IS_REQUIRED_TYPE,
  TASK_TABLE_ROW_DATA_TYPE,
} from '../../../constants/propTypes';
import {
  SheetOperationReviewDialogContainer,
} from '../../common/SheetOperationReviewDialog/SheetOperationReviewDialogContainer';
import {
  areTaskStartOrContinueActionButtonsHidden,
  getTaskCanNotStartOrContinueHelpInfoContent,
  isConsumeEntitiesOnTaskActionHidden,
} from '../../../utils/tasks';
import { Table } from '../../Table/Table';
import { FORMAT_SHORT_TIME } from '../../../constants/dateFormats';
import { NoDataLabelTrans } from '../../../utils/commonTransComponents';
import { CheckBoxFiltersPanel } from '../../common/CheckboxFiltersPanel/CheckboxFiltersPanel';
import { ScanBarcodeDialog } from '../ScanBarcodeDialog/ScanBarcodeDialog';
import { TASKS_VIEW_SCREEN_TABLE_ADDITIONAL_FILTERS_SCHEMA } from '../TasksViewScreen/constants';
import './style.css';
import {
  TasksTableFilters,
} from '../../Table/tableComponents/TasksTableFilters/TasksTableFilters';
import { useAbortRequest } from '../../../hooks/useAbortRequest';


const BARCODE_SCANNER_SEARCH_SHEET_ALLOWED = !!_get(window, ['config', 'BARCODE_SCANNER', 'SEARCH_SHEET', 'START'], false);

export const Tasks = props => {
  const {
    tasksTableId,
    tasksTableData,
    fetchTasksRemoteTableData,
    getTasksViewScreenTableRowStyle,
    handleSheetOperationDataChanged,
    handleTaskStatusChanged,
    handleSheetFinished,
    handleEntityBatchSplit,
    handleSheetPaused,
    handleDefectiveEntitiesMarked,

    workerTasksAdditionalFilters,
    setWorkerTasksAdditionalFilters,
    saveWorkerTasksTableFiltersAndSearchHistory,
    isWorkerTasksFiltersManagingByAdmin,

    updateFiltersWithScannedSheet,
  } = props;

  const [selectedTaskData, setSelectedTaskData] = useState(null);

  const [isScanBarcodeDialogOpen, setIsScanBarcodeDialogOpen] = useState(false);

  const [isTasksFiltersDialogOpen, setIsTasksFiltersDialogOpen] = useState(false);

  const [updateFiltersWithScannedSheetWithAbort, abortUpdateFiltersWithScannedSheet] = useAbortRequest(updateFiltersWithScannedSheet);

  const _handleTaskRowClick = useCallback((_, rowData) => setSelectedTaskData({ ...rowData }), []);
  const handleSelectedTaskReviewDialogClose = useCallback(() => setSelectedTaskData(null), []);

  const _handleTaskStatusChanged = useCallback(
    selectedTaskData => {
      handleTaskStatusChanged(selectedTaskData);
      handleSelectedTaskReviewDialogClose();
    },
    [handleSelectedTaskReviewDialogClose, handleTaskStatusChanged],
  );

  const _handleSheetFinished = useCallback(
    selectedTaskData => {
      handleSheetFinished(selectedTaskData);
      handleSelectedTaskReviewDialogClose();
    },
    [handleSelectedTaskReviewDialogClose, handleSheetFinished],
  );

  const _handleSheetPaused = useCallback(
    selectedTaskData => {
      handleSheetPaused(selectedTaskData);
      handleSelectedTaskReviewDialogClose();
    },
    [handleSelectedTaskReviewDialogClose, handleSheetPaused],
  );

  const _handleEntityBatchSplit = useCallback(
    selectedTaskData => {
      handleEntityBatchSplit(selectedTaskData);
      handleSelectedTaskReviewDialogClose();
    },
    [handleSelectedTaskReviewDialogClose, handleEntityBatchSplit],
  );

  const _handleDefectiveEntitiesMarked = useCallback(
    data => {
      handleDefectiveEntitiesMarked(data);
      handleSelectedTaskReviewDialogClose();
    },
    [handleSelectedTaskReviewDialogClose, handleDefectiveEntitiesMarked],
  );

  const handleScanBarcodeDialogOpen = useCallback(
    () => {
      setIsScanBarcodeDialogOpen(true);
    },
    [setIsScanBarcodeDialogOpen],
  );

  const handleScanBarcodeDialogClose = useCallback(
    () => {
      setIsScanBarcodeDialogOpen(false);
    },
    [setIsScanBarcodeDialogOpen],
  );

  const handleScanBarcodeDialogScan = useCallback(
    barcode => {
      return updateFiltersWithScannedSheetWithAbort({
        sheetIdentity: barcode,
      })
        .then(() => {
          handleScanBarcodeDialogClose();
        });
    },
    [updateFiltersWithScannedSheetWithAbort, handleScanBarcodeDialogClose],
  );

  const handleCancelScanBarcode = useCallback(() => {
    abortUpdateFiltersWithScannedSheet();
  }, [abortUpdateFiltersWithScannedSheet]);

  const handleScanBarcodeGoToSheetFilter = useCallback(
    () => {
      setIsTasksFiltersDialogOpen(true);
      handleScanBarcodeDialogClose();
    },
    [setIsTasksFiltersDialogOpen, handleScanBarcodeDialogClose],
  );

  const handleTasksFiltersDialogClose = useCallback(
    () => {
      setIsTasksFiltersDialogOpen(false);
    },
    [setIsTasksFiltersDialogOpen],
  );

  const state = {
    selectedTaskData,
  };

  const renderProps = {
    tasksTableId,
    tasksTableData,
    fetchTasksRemoteTableData,
    getTasksViewScreenTableRowStyle,

    workerTasksAdditionalFilters,
    setWorkerTasksAdditionalFilters,
    saveWorkerTasksTableFiltersAndSearchHistory,
    isWorkerTasksFiltersManagingByAdmin,

    _handleTaskRowClick,
    handleSheetOperationDataChanged,
    handleSelectedTaskReviewDialogClose,
    _handleTaskStatusChanged,
    _handleSheetFinished,
    _handleSheetPaused,
    _handleEntityBatchSplit,
    _handleDefectiveEntitiesMarked,

    isScanBarcodeDialogOpen,
    handleScanBarcodeDialogOpen,
    handleScanBarcodeDialogClose,
    handleScanBarcodeDialogScan,
    handleScanBarcodeGoToSheetFilter,

    isTasksFiltersDialogOpen,
    handleTasksFiltersDialogClose,
    handleCancelScanBarcode,
  };

  return (
    <div className="tasks">
      {_renderAdditionalTableFiltersAndBarcodeScanButton(state, renderProps)}
      {_renderSheetOperationReviewDialog(state, renderProps)}
      {_renderTasksTable(renderProps)}
      {
        BARCODE_SCANNER_SEARCH_SHEET_ALLOWED ?
          _renderScanBarcodeDialog(renderProps) :
          null
      }
    </div>
  );
};

const _renderTasksTable = renderProps => {
  const {
    tasksTableId,
    tasksTableData,
    fetchTasksRemoteTableData,
    getTasksViewScreenTableRowStyle,
    _handleTaskRowClick,
    isTasksFiltersDialogOpen,
    handleTasksFiltersDialogClose,
    saveWorkerTasksTableFiltersAndSearchHistory,
  } = renderProps;

  return (
    <Table
        tableId={tasksTableId}
        tableModel={tasksTableId}
        getRowStyle={getTasksViewScreenTableRowStyle}
        fetchRemoteTableData={({ tableParams }) => fetchTasksRemoteTableData({ tableParams })}
        rowsData={tasksTableData}
        noDataContent={NoDataLabelTrans}
        onRowClick={_handleTaskRowClick}
        tableMenu={{
          disableMenu: true,
        }}
        customCellRenderersProps={{
          operationStartDate: {
            dateFormat: FORMAT_SHORT_TIME,
          },
          operationStopDate: {
            dateFormat: FORMAT_SHORT_TIME,
          },
        }}
        disableSort
        customTableComponents={{
          filters: {
            component: TasksTableFilters,
            componentProps: {
              onFiltersModalClose: handleTasksFiltersDialogClose,
              saveWorkerTasksTableFiltersAndSearchHistory,

              /*
              * Флаг управления состоянием модальником фильтров нужен только в кейсе прямого перехода по
              * ссылке из интерфейса сканирования штрихкода. Если такого кейса не случилось, то и нет
              * особого смысла делать компонент фильтров "контролируемым". Поэтому, если isTasksFiltersDialogOpen = false,
              * то задаём для filtersModalOpened undefined, чтобы он оставался "неконтролируемым"
              * */
              filtersModalOpened: isTasksFiltersDialogOpen || undefined,
            },
          },
        }}
    />
  );
};

const _renderAdditionalTableFiltersAndBarcodeScanButton = (_, renderProps) => {
  const {
    workerTasksAdditionalFilters,
    setWorkerTasksAdditionalFilters,
    isWorkerTasksFiltersManagingByAdmin,
    handleScanBarcodeDialogOpen,
  } = renderProps;

  return (
    <div className="tasks__additional-table-filters-wrapper">
      {
        isWorkerTasksFiltersManagingByAdmin ?
          // возвращаем пустой div, чтобы корректно отрабатывал стиль display-flex с justify-content: space-between
          <div/> :
          <CheckBoxFiltersPanel
              className="tasks__additional-table-filters"
              schema={TASKS_VIEW_SCREEN_TABLE_ADDITIONAL_FILTERS_SCHEMA}
              filters={workerTasksAdditionalFilters}
              setFilters={setWorkerTasksAdditionalFilters}
          />
      }

      {
        BARCODE_SCANNER_SEARCH_SHEET_ALLOWED ?
          <Button
              className="tasks__scan-barcode_button"
              variant={MATERIAL_UI_VARIANT.CONTAINED}
              color={MATERIAL_UI_STYLE_COLOR.PRIMARY}
              startIcon={<CropFreeIcon/>}
              onClick={handleScanBarcodeDialogOpen}
          >
            <Trans
                id="tasks@scan_barcode_additional_filter"
            >
              Сканировать штрих-код
            </Trans>
          </Button> :
          null
      }
    </div>
  );
};

const _renderSheetOperationReviewDialog = (state, renderProps) => {
  const {
    selectedTaskData,
  } = state;

  if(selectedTaskData === null) return null;

  const {
    handleSheetOperationDataChanged,
    handleSelectedTaskReviewDialogClose,
    _handleTaskStatusChanged,
    _handleSheetFinished,
    _handleSheetPaused,
    _handleEntityBatchSplit,
    _handleDefectiveEntitiesMarked,
  } = renderProps;

  return (
    <SheetOperationReviewDialogContainer
        closeDialog={handleSelectedTaskReviewDialogClose}
        sheetOperationData={selectedTaskData}
        handleSheetOperationStatusChanged={_handleTaskStatusChanged}
        handleSheetOperationDataChanged={handleSheetOperationDataChanged}
        handleSheetFinished={_handleSheetFinished}
        handleSheetPaused={_handleSheetPaused}
        handleEntityBatchSplit={_handleEntityBatchSplit}
        handleDefectiveEntitiesMarked={_handleDefectiveEntitiesMarked}
        getHelpAlertContent={getTaskCanNotStartOrContinueHelpInfoContent}
        areStatusChangeButtonsHidden={areTaskStartOrContinueActionButtonsHidden}
        isConsumeEntitiesActionHidden={isConsumeEntitiesOnTaskActionHidden}
    />
  );
};

const _renderScanBarcodeDialog = renderProps => {
  const {
    isScanBarcodeDialogOpen,
    handleScanBarcodeDialogClose,
    handleScanBarcodeDialogScan,
    handleScanBarcodeGoToSheetFilter,
    handleCancelScanBarcode,
  } = renderProps;

  if (!isScanBarcodeDialogOpen) {
    return null;
  }

  return (
    <ScanBarcodeDialog
        onClose={handleScanBarcodeDialogClose}
        onScan={handleScanBarcodeDialogScan}
        onManualLinkClick={handleScanBarcodeGoToSheetFilter}
        onCancel={handleCancelScanBarcode}
    />
  );
};

Tasks.propTypes = {
  tasksTableId: PropTypes.string.isRequired,
  tasksTableData: PropTypes.arrayOf(TASK_TABLE_ROW_DATA_TYPE),
  fetchTasksRemoteTableData: FUNC_IS_REQUIRED_TYPE,
  getTasksViewScreenTableRowStyle: PropTypes.func,
  workerTasksAdditionalFilters: PropTypes.arrayOf(PropTypes.string),
  setWorkerTasksAdditionalFilters: FUNC_IS_REQUIRED_TYPE,
  saveWorkerTasksTableFiltersAndSearchHistory: FUNC_IS_REQUIRED_TYPE,
  isWorkerTasksFiltersManagingByAdmin: PropTypes.bool.isRequired,
  handleTaskStatusChanged: FUNC_IS_REQUIRED_TYPE,
  handleSheetOperationDataChanged: FUNC_IS_REQUIRED_TYPE,
  handleSheetFinished: FUNC_IS_REQUIRED_TYPE,
  handleEntityBatchSplit: FUNC_IS_REQUIRED_TYPE,
  handleSheetPaused: FUNC_IS_REQUIRED_TYPE,
  handleDefectiveEntitiesMarked: FUNC_IS_REQUIRED_TYPE,
  updateFiltersWithScannedSheet: FUNC_IS_REQUIRED_TYPE,
};
